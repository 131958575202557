import { useContext } from "react";
import { useMutation } from "react-query";

import { StoreContext } from "../../../context/Store";

const useFinishBook = (idBook) => {
    const { api } = useContext(StoreContext);

    const {
        mutate: finishBook,
        isSuccess: isFinishBookSuccess,
        isError: isFinishBookError,
    } = useMutation((finished) =>
        api.post("/multibooks/mark-as-finished", {
            idMultibook: idBook,
            finished,
        })
    );

    return { finishBook, isFinishBookSuccess, isFinishBookError };
};

export default useFinishBook;
