import { useRef, useEffect, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useQuery } from "react-query";

import { StoreContext } from "../../../context/Store";

import useCheckSubscription from "../../../hooks/useCheckSubscription";

import pathSlice from "../../../functions/pathSlice";
import routeLoadingState from "../../../functions/routeLoadingState";
import parentHasActiveSubscription from "../../../functions/parentHasActiveSubscription";
import { getStoreUrl } from "./Subscriptions";

import DashboardContainer from "../../../components/containers/dashboard/DashboardContainer";
import Title from "../../../components/headings/Title";
import NavTiles from "../../../components/containers/dashboard/NavTiles";
import ButtonNotify from "../../../components/buttons/ButtonNotify";

import notifyImg_1 from "../../../assets/images/dashboard/notify/notify-active.svg";
import notifyImg_2 from "../../../assets/images/dashboard/notify/notify-inactive.svg";
import notifyImg_3 from "../../../assets/images/dashboard/notify/notify-empty.svg";

import modalDoodleImage from "../../../assets/images/dashboard/how2doodle-subscription.svg";

const notifyThumbObjects = {
    active: {
        img: notifyImg_1,
        styles: {
            bottom: "-2px",
            left: "-2px",
            width: "116px",
            height: "106px",
        },
    },
    inactive: {
        img: notifyImg_2,
        styles: {
            bottom: "-1px",
            left: "25px",
            width: "83px",
            height: "93px",
        },
    },
    empty: {
        img: notifyImg_3,
        styles: {
            bottom: "6px",
            left: "16px",
            width: "95px",
            height: "92px",
        },
    },
};

const Home = ({ routeMatches, routeSetters, modalSetters }) => {
    const { setRouteData, setRouteIsLoading } = routeSetters;
    const { setModalData } = modalSetters;

    const history = useHistory();
    const location = useLocation();
    const [cookies] = useCookies(["active"]);

    const {
        isSubscriptionActive,
        isSubscriptionFetched,
        isSubscriptionLoading,
    } = useCheckSubscription();

    const { api } = useContext(StoreContext);

    /* GET CHILDREN LIST */
    const {
        data: childListData,
        isFetched: childListIsFetched,
        isLoading: childListIsLoading,
    } = useQuery("childList", () => api.get(`/account/childList`), {
        staleTime: Infinity,
        retry: false,
        refetchOnWindowFocus: false,
    });

    /* DO UPORZADKOWANIA / WYJEBANIA */
    const accountActive = Number(cookies.active) === 1;
    const parentHasAnyChildren = childListData?.data.childs.length > 0;
    const isChildrenSubscriptionActive = parentHasActiveSubscription(
        childListData?.data?.childs
    );

    const thumbnailForNotifyButton = () => {
        if (!parentHasAnyChildren) {
            return notifyThumbObjects.empty;
        }

        if (!isChildrenSubscriptionActive) {
            return notifyThumbObjects.inactive;
        }

        return notifyThumbObjects.active;
    };

    const messageForNotifyButton = () => {
        if (!parentHasAnyChildren) {
            return "Nie masz jeszcze dodanego dziecka";
        }

        if (!isChildrenSubscriptionActive) {
            return "Abonament jest nieaktywny";
        }

        return "Abonament jest aktywny";
    };

    const redirectForNotifyButton = () => {
        if (!parentHasAnyChildren) {
            return () =>
                history.push(`${pathSlice(location.pathname)}/add-children`);
        }

        if (!isChildrenSubscriptionActive) {
            return () =>
                history.push(`${pathSlice(location.pathname)}/subscriptions`);
        }

        return () =>
            history.push(`${pathSlice(location.pathname)}/subscriptions`);
    };

    /* ROUTE LOADING */
    const isRouteLoading = routeLoadingState({
        fetched: [childListIsFetched, isSubscriptionFetched],
        loading: [childListIsLoading, isSubscriptionLoading],
    });

    /* MODAL CHILDREN DATA */
    const modalSubscriptionData = useRef({});

    useEffect(() => {
        if (!isRouteLoading) {
            modalSubscriptionData.current = {
                data: [
                    {
                        title: {
                            obj: (
                                <p>
                                    Abonament Twojego dziecka
                                    <br />
                                    jest nieaktywny. Wybierz abonament,
                                    <br />
                                    aby w pełni cieszyć się światem How2 Edu.
                                </p>
                            ),
                        },
                        img: {
                            obj: modalDoodleImage,
                            size: "262px",
                        },
                        action: {
                            obj: [
                                {
                                    text: "Wybierz abonament",
                                    important: true,
                                    onClick: () =>
                                        window.open(getStoreUrl(), "_blank"),
                                },
                                {
                                    text: "Wróć",
                                },
                            ],
                            key: "subscriptionRedirect",
                        },
                    },
                ],
            };
        }
    }, [isRouteLoading]);

    /* ROUTE SETTERS */
    useEffect(() => {
        const routeData = {};

        if (routeMatches) {
            setRouteIsLoading(isRouteLoading);
            setRouteData(routeData);
        }
    }, [routeMatches, isRouteLoading, setRouteIsLoading, setRouteData]);

    return (
        <>
            <DashboardContainer className="small-title">
                {!isRouteLoading && (
                    <>
                        {accountActive && (
                            <Title className="text-center small">
                                Informacje o abonamentach
                            </Title>
                        )}
                        <ButtonNotify
                            accountActive={accountActive}
                            subscriptionActive={isChildrenSubscriptionActive}
                            hasChildren={parentHasAnyChildren}
                            thumbnail={thumbnailForNotifyButton()}
                            onClickFn={redirectForNotifyButton()}
                        >
                            {messageForNotifyButton()}
                        </ButtonNotify>
                        <NavTiles
                            disabled={!accountActive}
                            data={[
                                {
                                    text: "Moje konto",
                                    icon: "my-account",
                                    isActive: true,
                                    onClick: () =>
                                        history.push(
                                            `${pathSlice(
                                                location.pathname
                                            )}/account`
                                        ),
                                },
                                {
                                    text: "Moje dziecko",
                                    icon: "my-children",
                                    isActive: true,
                                    onClick: () =>
                                        history.push(
                                            `${pathSlice(
                                                location.pathname
                                            )}/children`
                                        ),
                                },
                                {
                                    text: "Moja półka",
                                    icon: "my-shelf",
                                    isActive: isSubscriptionActive,
                                    onClick: isSubscriptionActive
                                        ? () =>
                                              history.push(
                                                  `${pathSlice(
                                                      location.pathname
                                                  )}/mybookshelf`
                                              )
                                        : () =>
                                              setModalData(
                                                  modalSubscriptionData.current
                                              ),
                                },
                                {
                                    text: "Biblioteka",
                                    icon: "library",
                                    isActive: isSubscriptionActive,
                                    onClick: isSubscriptionActive
                                        ? () =>
                                              history.push(
                                                  `${pathSlice(
                                                      location.pathname
                                                  )}/library`
                                              )
                                        : () =>
                                              setModalData(
                                                  modalSubscriptionData.current
                                              ),
                                },
                                {
                                    text: "Abonamenty",
                                    icon: "subscriptions",
                                    isActive: true,
                                    onClick: () =>
                                        history.push(
                                            `${pathSlice(
                                                location.pathname
                                            )}/subscriptions`
                                        ),
                                },
                                {
                                    text: "Dodaj dziecko",
                                    icon: "add-children",
                                    isActive: true,
                                    onClick: () =>
                                        history.push(
                                            `${pathSlice(
                                                location.pathname
                                            )}/add-children`
                                        ),
                                },
                            ]}
                            keyName="dashboardParent"
                        />
                    </>
                )}
            </DashboardContainer>
        </>
    );
};

export default Home;
