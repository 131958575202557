import { useState, useEffect, useRef, useCallback, useContext } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { useCookies } from "react-cookie";

import { StoreContext } from "../../../../context/Store";

import useLibraryData from "../../../../hooks/useLibraryData";
import useCheckSubscription from "../../../../hooks/useCheckSubscription";

import routeLoadingState from "../../../../functions/routeLoadingState";

import LibraryFilters from "./LibraryFilters";
import LibrarySearch from "./LibrarySearch";
import LibraryList from "./LibraryList";
import SubscriptionError from "../SubscriptionError";

const LibraryGroup = ({ routePass, shelfData }) => {
    const { routeMatches, setRouteData, setRouteIsLoading } = routePass;

    const cookies = useCookies(["idRank"])[0];
    const params = useParams();
    const idCategory = useRef(params.idCategory);

    const { libraryStore, librarySave, libraryRemove } = useLibraryData();
    const {
        isSubscriptionActive,
        isSubscriptionFetched,
        isSubscriptionLoading,
    } = useCheckSubscription();

    /* DATA */
    const dataInitial = useRef({
        page: {
            number: 1,
            isFirst: true,
        },
        search:
            shelfData?.page === "favorite"
                ? libraryStore("favorite", "search") ?? {
                      prev: "",
                      next: "",
                      isEmpty: true,
                  }
                : libraryStore(
                      `${"category-" + idCategory.current}`,
                      "search"
                  ) ?? {
                      prev: "",
                      next: "",
                      isEmpty: true,
                  },
    });

    /* DATA LOADING */
    const [dataLoading, setDataLoading] = useState(false);

    /* DATA CHANGE */
    const dataChangeType = useRef(null);
    const [dataChange, setDataChange] = useState(false);

    /* DATA RESET */
    const [dataReset, setDataReset] = useState(false);
    const dataResetFlag = useRef(false);
    const [dataResetExecute, setDataResetExecute] = useState(false);

    /* SEARCH FORCE RESET */
    const forceSearchReset = useRef(false);

    /* SEARCH DISABLED */
    const [searchDisabled, setSearchDisabled] = useState(
        shelfData?.page === "favorite"
            ? libraryStore("favorite", "search")?.isEmpty
                ? false
                : true
            : libraryStore(`${"category-" + idCategory.current}`, "search")
                  ?.isEmpty
            ? false
            : true
    );

    /* FILTERS */
    const dataFilterActive = useRef(
        shelfData?.page === "favorite"
            ? libraryStore("favorite", "filter") ?? "all"
            : libraryStore(`${"category-" + idCategory.current}`, "filter") ??
                  "all"
    );

    /* ENDPOINT PAGE */
    const endpointPage = (type) => {
        switch (type) {
            case "category":
                return `?category=${shelfData?.id}&`;
            case "favorite":
                return "?favorite=true&";
            default:
                return "";
        }
    };

    /* ENDPOINT ADDRESS */
    const endpointAddress = (filter) => {
        switch (filter) {
            case "all":
                if (dataInitial.current.search.next) {
                    return `/library${endpointPage(shelfData?.page)}page=${
                        dataInitial.current.page.number
                    }&search=${dataInitial.current.search.next}`;
                } else {
                    return `/library${endpointPage(shelfData?.page)}page=${
                        dataInitial.current.page.number
                    }`;
                }
            case "audio":
                if (dataInitial.current.search.next) {
                    return `/library${endpointPage(
                        shelfData?.page
                    )}type=audio&page=${
                        dataInitial.current.page.number
                    }&search=${dataInitial.current.search.next}`;
                } else {
                    return `/library${endpointPage(
                        shelfData?.page
                    )}type=audio&page=${dataInitial.current.page.number}`;
                }
            case "multi":
                if (dataInitial.current.search.next) {
                    return `/library${endpointPage(
                        shelfData?.page
                    )}type=multi&page=${
                        dataInitial.current.page.number
                    }&search=${dataInitial.current.search.next}`;
                } else {
                    return `/library${endpointPage(
                        shelfData?.page
                    )}type=multi&page=${dataInitial.current.page.number}`;
                }
            default:
                if (dataInitial.current.search.next) {
                    return `/library${endpointPage(shelfData?.page)}page=${
                        dataInitial.current.page.number
                    }&search=${dataInitial.current.search.next}`;
                } else {
                    return `/library${endpointPage(shelfData?.page)}page=${
                        dataInitial.current.page.number
                    }`;
                }
        }
    };

    const { api } = useContext(StoreContext);

    /* QUERY KEYS BY TYPE */
    const queryKeys = (type) => {
        switch (type) {
            case "category":
                return ["libraryCategory", shelfData?.id];
            case "favorite":
                return "libraryFavorites";
            default:
                return "libraryDefault";
        }
    };

    /* GET LIBRARY DATA */
    const {
        data: libraryData,
        isFetched: libraryIsFetched,
        refetch: libraryRefetch,
        isRefetching: libraryIsRefetching,
        isLoading: libraryIsLoading,
        remove: libraryQueryRemove,
    } = useQuery(
        queryKeys(shelfData?.page),
        () => api.get(endpointAddress(dataFilterActive.current)),
        {
            staleTime: Infinity,
            retry: false,
            refetchOnWindowFocus: false,
        }
    );

    /* DATA CHANGE HANDLER */
    useEffect(() => {
        if (dataChange) {
            /* DATA CHANGE BY FILTER */
            if (dataChangeType.current === "filter") {
                if (
                    dataInitial.current.page.isFirst &&
                    dataInitial.current.search.isEmpty &&
                    !dataResetFlag.current
                ) {
                    setDataLoading(true);

                    dataChangeType.current = null;

                    libraryRefetch();
                } else {
                    setDataReset(true);
                }
            }

            /* DATA CHANGE BY SEARCH */
            if (!forceSearchReset.current) {
                if (dataChangeType.current === "search") {
                    if (
                        dataInitial.current.search.prev !==
                        dataInitial.current.search.next
                    ) {
                        /* SET LAST SEARCHED DATA */
                        dataInitial.current = {
                            ...dataInitial.current,
                            search: {
                                ...dataInitial.current.search,
                                prev: dataInitial.current.search.next,
                            },
                        };

                        if (
                            dataInitial.current.page.isFirst &&
                            !dataResetFlag.current
                        ) {
                            setDataLoading(true);

                            dataChangeType.current = null;

                            libraryRefetch();
                        } else {
                            setDataReset(true);
                        }
                    }
                }
            } else {
                setDataReset(true);
            }

            setDataChange(false);
        }
    }, [dataChange, libraryRefetch]);

    /* DATA RESET */
    useEffect(() => {
        if (dataReset) {
            dataInitial.current = {
                page: {
                    number: 1,
                    isFirst: true,
                },
                search: {
                    prev:
                        dataChangeType.current === "filter" ||
                        forceSearchReset.current
                            ? ""
                            : dataInitial.current.search.prev,
                    next:
                        dataChangeType.current === "filter" ||
                        forceSearchReset.current
                            ? ""
                            : dataInitial.current.search.next,
                    isEmpty:
                        dataChangeType.current === "filter" ||
                        forceSearchReset.current
                            ? true
                            : dataInitial.current.search.empty,
                },
            };

            /* EXECUTE RESET */
            setDataResetExecute(true);
            setDataReset(false);
        }
    }, [dataReset]);

    /* DATA RESET EXECUTE */
    useEffect(() => {
        if (dataResetExecute) {
            /* RESET ALL DATA TYPES AND RUN REFETCH */
            setDataResetExecute(false);

            dataChangeType.current = null;

            dataResetFlag.current = false;

            forceSearchReset.current = false;

            setDataLoading(true);

            libraryRefetch();
        }
    }, [dataResetExecute, libraryRefetch]);

    /* DISABLE LOADING AFTER REFETCH */
    useEffect(() => {
        if (!libraryIsRefetching && dataLoading) {
            setDataLoading(false);
        }
    }, [libraryIsRefetching, dataLoading]);

    /* SEARCH DATA FILL */
    const searchFill = useCallback((value) => {
        dataInitial.current = {
            ...dataInitial.current,
            search: {
                ...dataInitial.current.search,
                next: value,
                isEmpty: value.length > 0 ? false : true,
            },
        };

        if (dataInitial.current.search.prev === value) {
            setSearchDisabled(true);
        } else {
            setSearchDisabled(false);
        }
    }, []);

    /* SEARCH HANDLER */
    const searchHandler = useCallback(() => {
        dataChangeType.current = "search";

        if (shelfData?.page === "favorite") {
            librarySave("favorite", [
                "search",
                dataInitial.current.search.next,
            ]);
        } else {
            librarySave(`${"category-" + idCategory.current}`, [
                "search",
                dataInitial.current.search.next,
            ]);
        }

        setDataChange(true);
    }, [librarySave, shelfData?.page]);

    /* FILTER CHANGE HANDLER */
    const filterChangeHandler = (filter) => {
        dataFilterActive.current = filter;

        if (shelfData?.page === "favorite") {
            librarySave("favorite", ["filter", filter]);
            libraryRemove("favorite", "search");
        } else {
            librarySave(`${"category-" + idCategory.current}`, [
                "filter",
                filter,
            ]);
            libraryRemove(`${"category-" + idCategory.current}`, "search");
        }

        dataChangeType.current = "filter";

        setDataChange(true);
    };

    /* NEXT PAGE HANDLER */
    const nextPageHandler = useCallback((page) => {
        dataInitial.current = {
            ...dataInitial.current,
            page: {
                number: page,
                isFirst: page > 1 ? false : true,
            },
        };

        libraryRefetch();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    /* RESET DATA FLAG HANDLER */
    const resetDataFlagHandler = useCallback(() => {
        dataResetFlag.current = true;
    }, []);

    /* FORCE RESET DATA */
    const forceSearchResetHandler = useCallback(() => {
        forceSearchReset.current = true;

        if (shelfData?.page === "favorite") {
            libraryRemove("favorite", "search");
        } else {
            libraryRemove(`${"category-" + idCategory.current}`, "search");
        }

        setDataChange(true);
    }, [libraryRemove, shelfData?.page]);

    /* REMOVE FETCHED DATA */
    useEffect(() => {
        return () => {
            libraryQueryRemove();
        };
    }, [libraryQueryRemove]);

    /* ROUTE LOADING */
    const routeIsLoading = routeLoadingState({
        fetched: [libraryIsFetched, isSubscriptionFetched],
        loading: [libraryIsLoading, isSubscriptionLoading],
    });

    /* ROUTE SETTERS */
    useEffect(() => {
        const routeData = {
            title:
                shelfData.page === "category"
                    ? routeIsLoading
                        ? ""
                        : isSubscriptionActive
                        ? libraryData.data[0]?.category.name
                        : "BRAK DOSTĘPU"
                    : shelfData.title,
            action: isSubscriptionActive
                ? shelfData.action ?? undefined
                : undefined,
        };

        if (routeMatches) {
            setRouteIsLoading(routeIsLoading);
            setRouteData(routeData);
        }
    }, [
        routeIsLoading,
        routeMatches,
        setRouteIsLoading,
        setRouteData,
        libraryData?.data,
        isSubscriptionActive,
    ]);

    return (
        !routeIsLoading &&
        (isSubscriptionActive ? (
            <>
                <LibraryFilters
                    filters={[
                        {
                            name: "WSZYSTKO",
                            filter: "all",
                        },
                        {
                            name: "SMARTBOOKI",
                            filter: "multi",
                        },
                        {
                            name: "AUDIOBOOKI",
                            filter: "audio",
                        },
                    ]}
                    filtersRefetching={libraryIsRefetching}
                    filterCurrent={dataFilterActive.current}
                    onClickFn={(filter) => filterChangeHandler(filter)}
                />
                <LibrarySearch
                    searchDisabled={searchDisabled}
                    searchSaved={
                        shelfData?.page === "favorite"
                            ? libraryStore("favorite", "search")?.next
                            : libraryStore(
                                  `${"category-" + idCategory.current}`,
                                  "search"
                              )?.next
                    }
                    resetSearch={
                        (dataResetExecute &&
                            dataChangeType.current === "filter") ||
                        (dataResetExecute && forceSearchReset.current)
                    }
                    onChangeFn={searchFill}
                    onSearchFn={searchHandler}
                />
                <LibraryList
                    title={
                        shelfData?.page === "category"
                            ? libraryData.data[0]?.category.name.toUpperCase()
                            : shelfData?.title
                    }
                    data={
                        shelfData?.page === "category"
                            ? libraryData.data[0]?.books
                            : libraryData?.data
                    }
                    resetData={dataResetExecute}
                    resetDataFlag={resetDataFlagHandler}
                    resetSearchForce={forceSearchResetHandler}
                    nextPage={nextPageHandler}
                    inlineRemoving={
                        shelfData?.page === "favorite" ? true : false
                    }
                    isLoading={dataLoading}
                />
            </>
        ) : (
            <SubscriptionError rank={parseInt(cookies.idRank)} />
        ))
    );
};

export default LibraryGroup;
