import { useCallback, useContext } from "react";

import { LibraryContext } from "../context/LibStore";

const categoryExist = (data, category) => {
    let exist = false;

    for (const object of data) {
        if (object.category === category) {
            exist = true;
        }
    }

    return exist;
};

const contentExist = (content, type) => {
    let exist = false;

    for (const item of content) {
        if (item.type === type) {
            exist = true;
        }
    }

    return exist;
};

const setContentObject = (type, content) => {
    let object = {};

    if (type === "search") {
        object = {
            type,
            content: {
                prev: content,
                next: content,
                isEmpty: content.length > 0 ? false : true,
            },
        };
    } else {
        object = {
            type,
            content,
        };
    }

    return object;
};

const addLibraryData = (data, category, value) => {
    const [valueType, valueContent] = value;

    if (data?.length > 0) {
        const newData = [];

        if (categoryExist(data, category)) {
            for (const dataObject of data) {
                if (dataObject.category === category) {
                    const newContent = [];

                    if (contentExist(dataObject.content, valueType)) {
                        for (const contentObject of dataObject.content) {
                            if (contentObject.type === valueType) {
                                newContent.push(
                                    setContentObject(valueType, valueContent)
                                );
                            } else {
                                newContent.push(contentObject);
                            }
                        }
                    } else {
                        newContent.push(
                            ...dataObject.content,
                            setContentObject(valueType, valueContent)
                        );
                    }

                    newData.push({ category, content: newContent });
                } else {
                    newData.push(dataObject);
                }
            }
        } else {
            newData.push(...data, {
                category,
                content: [setContentObject(valueType, valueContent)],
            });
        }

        return newData;
    } else {
        return [
            {
                category,
                content: [setContentObject(valueType, valueContent)],
            },
        ];
    }
};

const removeLibraryData = (data, category, type) => {
    if (!data || data.length === 0) return null;

    if (!category) return data;

    if (type) {
        const newData = [];

        for (const dataObject of data) {
            if (dataObject.category === category) {
                newData.push({
                    category,
                    content: dataObject.content.filter(
                        (element) => element.type !== type
                    ),
                });
            } else {
                newData.push(dataObject);
            }
        }

        return newData;
    } else {
        return data.filter((element) => element.category !== category);
    }
};

const useLibraryData = () => {
    const { data, setData } = useContext(LibraryContext);

    const getData = (category, type) => {
        if (!data || data.length === 0) return null;

        if (!category || !type) return data;

        for (const dataObject of data) {
            if (dataObject.category === category) {
                for (const contentObject of dataObject.content) {
                    if (contentObject.type === type) {
                        return contentObject.content;
                    }
                }
            }
        }
    };

    const saveData = useCallback((category, value) => {
        setData((prevData) => addLibraryData(prevData, category, value));
    }, [setData]);

    const removeData = useCallback((category, type) => {
        setData((prevData) => removeLibraryData(prevData, category, type));
    }, [setData]);

    return {
        libraryStore: getData,
        librarySave: saveData,
        libraryRemove: removeData,
    };
};

export default useLibraryData;
