import { useContext } from "react";
import { useMutation } from "react-query";

import { ChapterContext } from "../context/ChapterStore";
import { StoreContext } from "../../../context/Store";

const validExamObjects = (type) => {
    if (type === "choice" || type === "slider" || type === "order") {
        return true;
    } else {
        return false;
    }
};

const getFilteredExamObjects = (object) => {
    if (!object || object.length === 0) return undefined;

    const filteredObjectsData = object?.data.filter((element) =>
        validExamObjects(element.type)
    );

    const examObject = { ...object, data: filteredObjectsData };

    return examObject;
};

const useSaveExamAnswers = (idChapter) => {
    const { getFullChapterData } = useContext(ChapterContext);

    const { api } = useContext(StoreContext);

    const { mutate: examAnswersSave, isSuccess: isExamAnswersSaveSuccess } =
        useMutation(() =>
            api.post(
                "/exams/save",
                getFilteredExamObjects(getFullChapterData(idChapter))
            )
        );

    const examAnswersSaveHandler = () => {
        if (getFilteredExamObjects(getFullChapterData(idChapter))) {
            examAnswersSave();
        }
    };

    return {
        examAnswersSave: examAnswersSaveHandler,
        isExamAnswersSaveSuccess,
    };
};

export default useSaveExamAnswers;
