import parse from "html-react-parser";

import parseFlashcardContent from "../functions/flashcard/parseFlashcardContent";
import parseMathTagConnection from "../functions/parseMathTagConnection";

const ReaderImportant = ({ content, flash, isGallup, isGibs }) => {
    return (
        <>
            <div
                className={
                    "ReaderImportant" +
                    (isGallup || isGibs ? " _color--secondary" : "")
                }
            >
                <div className="ReaderImportant__Box">
                    <div className="ReaderImportant__Content">
                        {parseFlashcardContent(
                            parse(parseMathTagConnection(content)),
                            flash
                        ).map((object) => {
                            return object;
                        })}
                    </div>
                    <div className="ReaderImportant__Icon">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            width="5.773"
                            height="15.627"
                            viewBox="0 0 5.773 15.627"
                        >
                            <defs>
                                <clipPath id="importantIconClipPath">
                                    <rect
                                        id="importantIconClipPath_1"
                                        dataname="importantIconClipPath 1"
                                        width="5.773"
                                        height="15.627"
                                        fill="none"
                                    />
                                </clipPath>
                            </defs>
                            <g
                                id="importantIconGroup_1"
                                dataname="importantIconGroup 1"
                                transform="translate(0 -2.109)"
                            >
                                <g
                                    id="importantIconGroup_2"
                                    dataname="importantIconGroup 2"
                                    transform="translate(0 2.109)"
                                    clipPath="url(#importantIconClipPath)"
                                >
                                    <path
                                        id="importantIconPath_1"
                                        dataname="importantIconPath 1"
                                        d="M1.846,11.909a1.859,1.859,0,1,1,0,3.718,1.859,1.859,0,0,1,0-3.718M.069,0H3.6L3.139,10.386H.552Z"
                                        transform="translate(0 0.001)"
                                        fill="#fff"
                                    />
                                </g>
                            </g>
                        </svg>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ReaderImportant;
