import { useEffect, useRef, useState, useContext } from "react";
import parse from "html-react-parser";

import { ChapterContext } from "../context/ChapterStore";

import useComponentTransition from "../hooks/useComponentTransition";

import parseFlashcardContent from "../functions/flashcard/parseFlashcardContent";
import parseMathTagConnection from "../functions/parseMathTagConnection";

const ReaderAnswer = ({ ids, content, flash, isLoading, isGallup, isGibs }) => {
    const { getChapterData, setChapterData } = useContext(ChapterContext);

    /* GET STORED ANSWER DATA */
    const answerOpenStored = getChapterData(ids, "answer") === "open";

    /* COMPONENT TRANSITION HOOK */
    const { show, hide, isMounted, isUnmounting, entering, entered, exiting } =
        useComponentTransition(500, answerOpenStored);

    const { question, answer } = content;

    /* COLLAPSE STATES */
    const [answerCollapseHeight, setAnswerCollapseHeight] = useState(
        answerOpenStored ? "auto" : 0
    );
    const answerBodyRef = useRef(null);

    const handleAnswerToggle = () => {
        if (isLoading) return;

        if (isMounted) {
            hide();
        } else {
            show();
        }
    };

    /* ANSWER TRANSITION EVENTS */
    useEffect(() => {
        if (entering && answerBodyRef.current) {
            setAnswerCollapseHeight(answerBodyRef.current.clientHeight);
        }
    }, [entering]);

    useEffect(() => {
        if (entered && !answerOpenStored) {
            setChapterData("open", ids, "answer");

            setAnswerCollapseHeight("auto");
        }
    }, [entered, answerOpenStored, setChapterData, ids]);

    useEffect(() => {
        if (isUnmounting && answerBodyRef.current) {
            setAnswerCollapseHeight(answerBodyRef.current.clientHeight);
        }
    }, [isUnmounting]);

    useEffect(() => {
        if (exiting) {
            setChapterData("closed", ids, "answer");

            setAnswerCollapseHeight(0);
        }
    }, [exiting, setChapterData, ids]);

    return (
        <>
            <div
                className={
                    "ReaderAnswer" +
                    (entering || entered ? " _open" : "") +
                    (isGallup || isGibs ? " _color--secondary" : "")
                }
            >
                <button
                    className="ReaderAnswer__Button"
                    type="button"
                    disabled={isLoading}
                    data-panel="false"
                    onClick={handleAnswerToggle}
                >
                    <span className="ReaderAnswer__Fill">
                        {parseFlashcardContent(
                            parse(parseMathTagConnection(question)),
                            flash
                        )}
                    </span>
                    <span className="ReaderAnswer__Icon"></span>
                </button>
                {isMounted ? (
                    <div
                        className="ReaderAnswer__Collapse"
                        style={{ height: answerCollapseHeight }}
                    >
                        <div className="ReaderAnswer__Body" ref={answerBodyRef}>
                            <div className="ReaderAnswer__Box">
                                {parseFlashcardContent(
                                    parse(parseMathTagConnection(answer)),
                                    flash
                                ).map((object) => {
                                    return object;
                                })}
                            </div>
                        </div>
                    </div>
                ) : (
                    ""
                )}
            </div>
        </>
    );
};

export default ReaderAnswer;
