import { useEffect, forwardRef } from "react";

const Modal = forwardRef((props, ref) => {
    const { className, children, isLoading, isRefetching, onClose } = props;

    useEffect(() => {
        document.body.classList.add("ModalOpen");

        return () => {
            document.body.classList.remove("ModalOpen");
        };
    }, []);

    return (
        <>
            <div
                className={"Modal" + (className ? " " + className : "")}
                ref={ref}
            >
                {onClose ? (
                    <button
                        className={
                            "Modal__Close" +
                            (isLoading ? " _hidden" : "")
                        }
                        type="button"
                        disabled={isLoading || isRefetching}
                        onClick={onClose}
                    ></button>
                ) : (
                    ""
                )}
                <div className="Modal__Container">
                    <div className="Modal__Dialog">
                        <div className="Modal__Content">{children}</div>
                    </div>
                </div>
                <div className="Modal__Backdrop"></div>
            </div>
        </>
    );
});

export default Modal;
