const checkArrayEquality = (a, b) => {
    if (!a || !b) return false;

    if (a.length !== b.length) return false;

    let r = true;

    for (let i = 0; i < a.length; i++) {
        if (a[i] !== b[i]) {
            r = false;
        }
    }

    return r;
};

const getFlashcards = (data) => {
    return data?.reduce((result, object) => {
        if (object.type === "flashcard") {
            const phrases = object.content
                .replaceAll(" ", "")
                .split(",")
                .filter((element) => element);

            if (
                !result.some((element) =>
                    checkArrayEquality(element.phrases, phrases)
                )
            ) {
                result.push({
                    id: object.idChapterContent,
                    phrases: phrases,
                    content: object.content2,
                });
            }
        }

        return result;
    }, []);
};

export default getFlashcards;
