import Button from "../../../buttons/Button";

import SubjectsButton from "./SubjectsButton";

const Subjects = ({ data, onClickSubject, onClickAdd }) => {
    return (
        <>
            <div className="Subjects">
                <div className="Subjects__Group">
                    {data?.length > 0 ? (
                        data?.map((object) => {
                            return (
                                <SubjectsButton
                                    key={"subject_" + object.idSubject}
                                    id={object.idSubject}
                                    text={object.name}
                                    onClick={onClickSubject}
                                />
                            );
                        })
                    ) : (
                        <div className="Subjects__Empty">
                            <p>
                                Brak przedmiotów.
                                <br />
                                Dodaj przedmiot do listy.
                            </p>
                        </div>
                    )}
                </div>
                <Button action="add" onClickFn={onClickAdd}>
                    Dodaj przedmiot
                </Button>
            </div>
        </>
    );
};

export default Subjects;
