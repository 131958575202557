const resetCodeFormErrorHandler = (statusCode, responseError, onInvalidCode) => {
    switch (statusCode) {
        case 400:
            switch (responseError) {
                case "Token not found":
                    onInvalidCode();
                    return;
                default:
                    return;
            }
        default:
            return;
    }
};

export default resetCodeFormErrorHandler