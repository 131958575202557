import { useContext } from "react";
import { useCookies } from "react-cookie";
import { useHistory } from "react-router-dom";
import { useQueryClient } from "react-query";

import { StoreContext } from "../context/Store";

import { getCookieTime } from "../App";

const useLogin = () => {
    const history = useHistory();
    const setCookie = useCookies([
        "token",
        "idRank",
        "active",
        "firstLogin",
    ])[1];
    const { setIsUserLogged } = useContext(StoreContext);

    const queryClient = useQueryClient();

    return (params, redirectPath, redirectState) => {
        setCookie("token", params.token, {
            path: "/",
            expires: getCookieTime(),
        });

        setCookie("idRank", params.idRank, {
            path: "/",
            expires: getCookieTime(),
        });

        setCookie("active", params.isAccountActive, {
            path: "/",
            expires: getCookieTime(),
        });

        if (params.firstLogin) {
            setCookie("firstLogin", params.firstLogin, {
                path: "/",
                expires: getCookieTime(),
            });
        }

        setIsUserLogged(true);
        queryClient.clear();

        if (redirectPath) {
            history.push(redirectPath, redirectState);
        }
    };
};

export default useLogin;
