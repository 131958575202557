const SigningTabsContainer = ({children}) => {
    const signingTab = (e, i) => <div className="signing-tab" key={'signingTab_' + i}>{e}</div>;

    return (
        <>
            <div className="app-signing-tabs">
                {
                    children.length>1
                    ? children.map((tab, index) => {
                        return signingTab(tab, index)
                    })
                    : signingTab(children, 0)
                }
            </div>
        </>
    )
}

export default SigningTabsContainer;