import AvatarButton from "./AvatarButton";

const AvatarPicker = ({ avatars, currentAvatar, isDisabled, onAvatarSelect }) => {
    const { avatar, setAvatar } = currentAvatar;

    const avatarSelectHandler = (id, url) => {
        setAvatar(url);
        onAvatarSelect(id);
    };

    return (
        <div className="AvatarPicker">
            <div
                className="AvatarPicker__Preview"
                style={{ backgroundImage: "url(" + avatar + ")" }}
            ></div>
            <div className="AvatarPicker__Title">WYBIERZ AVATARA</div>
            {avatars?.length > 0 ? (
                <div className="AvatarPicker__List">
                    {avatars?.map((object) => {
                        return (
                            <div
                                className="AvatarPicker__Item"
                                key={"avatar_" + object.id}
                            >
                                <AvatarButton
                                    avatar={object.url}
                                    currentAvatar={avatar}
                                    isDisabled={isDisabled}
                                    onClick={() =>
                                        avatarSelectHandler(
                                            object.id,
                                            object.url
                                        )
                                    }
                                />
                            </div>
                        );
                    })}
                </div>
            ) : (
                ""
            )}
        </div>
    );
};

export default AvatarPicker;
