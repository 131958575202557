import parse from "html-react-parser";

import parseFlashcardContent from "../functions/flashcard/parseFlashcardContent";
import parseMathTagConnection from "../functions/parseMathTagConnection";

const ReaderQuestion = ({ content, flash, isGallup, isGibs }) => {
    return (
        <>
            <div
                className={
                    "ReaderQuestion" +
                    (isGallup || isGibs ? " _color--secondary" : "")
                }
            >
                <div className="ReaderQuestion__Box">
                    <div className="ReaderQuestion__Content">
                        {parseFlashcardContent(
                            parse(parseMathTagConnection(content)),
                            flash
                        ).map((object) => {
                            return object;
                        })}
                    </div>
                    <div className="ReaderQuestion__Icon">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            width="9.74"
                            height="15.693"
                            viewBox="0 0 9.74 15.693"
                        >
                            <defs>
                                <clipPath id="questionIconClipPath">
                                    <rect
                                        id="questionIconRect_1"
                                        dataname="questionIconRect 1"
                                        width="9.74"
                                        height="15.693"
                                        fill="none"
                                    />
                                </clipPath>
                            </defs>
                            <g
                                id="questionIconGroup_1"
                                dataname="questionIconGroup 1"
                                transform="translate(0 0)"
                            >
                                <g
                                    id="questionIconGroup_2"
                                    dataname="questionIconGroup 2"
                                    transform="translate(0 0)"
                                    clipPath="url(#questionIconClipPath)"
                                >
                                    <path
                                        id="questionIconPath_1"
                                        dataname="questionIconPath 1"
                                        d="M5.109,5.8c.708-.5,1.324-.936,1.324-1.62,0-.706-.525-1.3-1.78-1.3A3.649,3.649,0,0,0,1.847,4.289L0,2.214A6.521,6.521,0,0,1,4.994,0C7.937,0,9.742,1.484,9.742,3.6c0,1.894-1.212,2.807-2.307,3.56-.8.592-1.5,1.094-1.5,1.938a1.377,1.377,0,0,0,.409.957l-2.486.73A2.908,2.908,0,0,1,3.1,8.783C3.1,7.208,4.2,6.455,5.109,5.8M4.949,12A1.848,1.848,0,1,1,3.1,13.848,1.871,1.871,0,0,1,4.949,12"
                                        transform="translate(0 -0.001)"
                                        fill="#fff"
                                    />
                                </g>
                            </g>
                        </svg>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ReaderQuestion;
