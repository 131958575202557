import { useContext } from "react";
import { useQuery } from "react-query";

import { StoreContext } from "../context/Store";

const useLearningProgressList = (idUser) => {
    const { api } = useContext(StoreContext);

    const {
        data: learningListData,
        isFetched: isLearningListFetched,
        isRefetching: isLearningListRefetching,
        isLoading: isLearningListLoading,
        remove: removeLearningList
    } = useQuery(
        "LearningProgressDetails",
        () => api.post("/learning-progress", { idUser }),
        {
            enabled: idUser ? true : false,
            staleTime: Infinity,
            retry: false,
            refetchOnWindowFocus: false,
        }
    );

    return {
        learningListData,
        isLearningListFetched,
        isLearningListRefetching,
        isLearningListLoading,
        removeLearningList
    };
};

export default useLearningProgressList;
