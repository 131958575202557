import { useState, useRef, forwardRef, useEffect } from "react";

import ReaderNavigation from "./navigation/ReaderNavigation";
import ReaderSettings from "./settings/ReaderSettings";

const getFilteredMenu = (isActive, array) => {
    if (!array) return;

    if (isActive) {
        return array.filter((element) => element.text !== "Rozdział");
    } else {
        return array;
    }
};

const ReaderDashboard = forwardRef((props, ref) => {
    const {
        chapters,
        pages,
        isMain,
        isChanging,
        isGallup,
        isGibs,
        isFinished,
    } = props;

    /* DASHBOARD CHANGING STATE */
    const [dashboardIsChanging, setDashboardIsChanging] = useState(false);

    /* DASHBOARD ANIMATION TIMEOUT VALUE */
    const dashboardTimeout = useRef(800);

    /* DASHBOARD CHANGE HANDLER */
    const changeDashboard = (dispatchSetter, reset) => {
        setDashboardIsChanging(true);

        setTimeout(() => {
            dispatchSetter(reset ? false : true);
        }, dashboardTimeout.current / 2);
    };

    /* RESET DASHBOARD CHANGING STATE */
    useEffect(() => {
        if (dashboardIsChanging) {
            setTimeout(() => {
                setDashboardIsChanging(false);
            }, dashboardTimeout.current);
        }
    }, [dashboardIsChanging]);

    /* SETTINGS STATE */
    const [settingsIsActive, setSettingsIsActive] = useState(false);

    /* RETURN DASHBOARD MAIN MENU STATE */
    useEffect(() => {
        if (typeof isMain === "function") {
            if (settingsIsActive) {
                isMain(false);
            } else {
                isMain(true);
            }
        }
    }, [settingsIsActive, isMain]);

    /* RETURN DASHBOARD CHANGING STATE */
    useEffect(() => {
        if (typeof isChanging === "function") {
            isChanging(dashboardIsChanging);
        }
    }, [dashboardIsChanging, isChanging]);

    return (
        <>
            <div
                className={
                    "ReaderDashboard" +
                    (dashboardIsChanging ? " _changing" : "")
                }
                data-panel="false"
            >
                {settingsIsActive ? (
                    <ReaderSettings
                        onRequestClose={() =>
                            changeDashboard(setSettingsIsActive, true)
                        }
                        isGallup={isGallup}
                        isGibs={isGibs}
                    />
                ) : (
                    <ReaderNavigation
                        chapters={chapters}
                        pages={pages}
                        menu={getFilteredMenu(isGallup || isGibs, [
                            {
                                text: "Rozdział",
                                icon: "chapter",
                                refObject: {
                                    ref,
                                    keyName: "chapter",
                                },
                            },
                            {
                                text: "Style",
                                icon: "style",
                                onClick: () =>
                                    changeDashboard(setSettingsIsActive),
                            },
                        ])}
                        isGallup={isGallup}
                        isGibs={isGibs}
                        isFinished={isFinished}
                    />
                )}
            </div>
        </>
    );
});

export default ReaderDashboard;
