const checkIfIdOccurs = (id, array) => {
    const idOccurs = {}

    for(const item of array) {
        if(item === id) {
            idOccurs.response = true;
        }
    }

    return idOccurs.response ? true : false;
}

export default checkIfIdOccurs;