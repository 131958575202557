const AvatarButton = ({ avatar, currentAvatar, isDisabled, onClick }) => {
    return (
        <button
            className={
                "AvatarButton" + (avatar === currentAvatar ? " _selected" : "")
            }
            type="button"
            style={{ backgroundImage: "url(" + avatar + ")" }}
            disabled={isDisabled}
            onClick={onClick}
        ></button>
    );
};

export default AvatarButton;
