import { useState, createContext } from "react";

export const SettingsContext = createContext();

const SettingsProvider = ({ children }) => {
    const [themeColor, setThemeColor] = useState(null);
    const [fontSize, setFontSize] = useState(null);
    const [fontFamily, setFontFamily] = useState(null);

    return (
        <SettingsContext.Provider
            value={{
                themeColor,
                setThemeColor,
                fontSize,
                setFontSize,
                fontFamily,
                setFontFamily,
            }}
        >
            {children}
        </SettingsContext.Provider>
    );
};

export default SettingsProvider;
