import { useContext } from "react";
import { useQuery } from "react-query";

import { StoreContext } from "../../../context/Store";

import getLoadingState from "../functions/getLoadingState";

const useChapter = (id, isFinished) => {
    const { api } = useContext(StoreContext);

    const {
        data: chapterData,
        isFetched: isChapterFetched,
        isRefetching: isChapterRefetching,
        isLoading: isChapterLoading,
        isSuccess: isChapterSuccess,
        refetch: chapterRefetch,
    } = useQuery(
        ["multibookChapter", id],
        () =>
            api.post("/multibooks/chapter", {
                idChapter: id,
            }),
        {
            enabled: isFinished ? false : id ? true : false,
            staleTime: Infinity,
            retry: false,
            refetchOnWindowFocus: false,
        }
    );

    const isHookLoading = isFinished
        ? false
        : getLoadingState([isChapterFetched], [isChapterLoading]);

    return !isHookLoading
        ? {
              chapter: isFinished ? "finished" : chapterData,
              chapterRefetch: isFinished ? false : chapterRefetch,
              isChapterLoading: false,
              isChapterRefetching: isFinished ? false : isChapterRefetching,
              isChapterSuccess: isFinished ? false : isChapterSuccess,
          }
        : {
              isChapterLoading: true,
              isChapterRefetching: isChapterRefetching,
              isChapterSuccess: false,
          };
};

export default useChapter;
