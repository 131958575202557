import { useState, useRef, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import pathSlice from "../../../../functions/pathSlice";
import Loader from "../../Loader";

import LibraryCarousel from "./LibraryCarousel";
import LibraryEmpty from "./LibraryEmpty";

const LibraryCarousels = ({
    data,
    dataOnMount,
    dataOnMountReset,
    searchForceReset,
    isLoading,
}) => {
    const history = useHistory();
    const location = useLocation();

    /* CAROUSEL STATES */
    const [carouselInit, setCarouselInit] = useState(false);
    const [carouselInitExecute, setCarouselInitExecute] = useState(false);
    const carouselData = useRef({
        prev: [],
        next: [],
    });
    const dataMountUpdated = useRef(false);

    /* UPDATE DATA */
    useEffect(() => {
        /* DISABLE CAROUSEL */
        setCarouselInit(false);

        carouselData.current = {
            ...carouselData.current,
            prev: carouselData.current.next,
        };

        if (dataOnMount.length > 0) {
            /* UPDATE DATA ON PAGE MOUNT WHEN THRERE IS NO LOADING */
            carouselData.current = {
                ...carouselData.current,
                next: dataOnMount,
            };

            dataOnMountReset();

            dataMountUpdated.current = true;

            setCarouselInitExecute(true);
        } else if (!dataMountUpdated.current) {
            /* UPDATE DATA STANDARD */
            carouselData.current = {
                ...carouselData.current,
                next: data,
            };

            setCarouselInitExecute(true);
        }
    }, [data, dataOnMount, dataOnMountReset]);

    /* EXECUTE INIT */
    useEffect(() => {
        if (carouselInitExecute) {
            if (dataMountUpdated.current) {
                dataMountUpdated.current = false;
            }

            setCarouselInit(true);

            setCarouselInitExecute(false);
        }
    }, [carouselInitExecute]);

    const carouselLink = (id) => {
        history.push(`${pathSlice(location.pathname)}/category-${id}`);
    };

    return (
        <>
            <div className="LibraryCarousels">
                {isLoading ? (
                    <div className="text-center">
                        <Loader type="ring" />
                    </div>
                ) : carouselInit ? (
                    <Carousels
                        data={carouselData.current.next}
                        searchForceReset={searchForceReset}
                        onClick={(id) => carouselLink(id)}
                    />
                ) : (
                    <Carousels
                        data={carouselData.current.next}
                        searchForceReset={searchForceReset}
                        isPrevious={true}
                        onClick={(id) => carouselLink(id)}
                    />
                )}
            </div>
        </>
    );
};

const Carousels = ({ data, searchForceReset, isPrevious, onClick }) => {
    return data?.length > 0 ? (
        data.map((object) => {
            if (parseInt(object.category.id) !== 1) {
                return (
                    <LibraryCarousel
                        key={
                            object.category.name +
                            "_" +
                            object.category.id +
                            (isPrevious ? "_previous" : "")
                        }
                        heading={{
                            title: object.category.name,
                            onClickFn: () => onClick(object.category.id),
                        }}
                        data={object.books}
                    />
                );
            } else {
                return "";
            }
        })
    ) : (
        <LibraryEmpty message="Brak wyników" onClickFn={searchForceReset} />
    );
};

export default LibraryCarousels;
