import { useRef, useEffect, useState, useContext } from "react";
import { useQuery, useMutation } from "react-query";

import { StoreContext } from "../../context/Store";

import MoodReader from "../../components/containers/dashboard/MoodReader";

const ChildrenMoodCheck = ({ modal }) => {
  const { api } = useContext(StoreContext);
  const { setModalActive, setModalData } = modal;
  const [image, setImage] = useState();
  const [question, setQuestion] = useState();
  const [sliderSuccess, setSliderSuccess] = useState(false);
  const [fileType, setFileType] = useState("");

  const answer = useRef(null);
  const [value, setValue] = useState(3);
  const [loaded, setLoaded] = useState(true);

  const {
    isLoading,
    data,
    refetch: refetchMoodQuestion,
  } = useQuery("question", () => api.get(`/mood/question`), {
    retry: false,
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      setImage(data?.data.question.image);
      setQuestion(data?.data.question.text);
      setFileType(data?.data.question.image);

      setLoaded(false);
    },
    onError: (error) => {},
  });

  const questionAnswer = useMutation(
    () =>
      api.post(`/mood/answer`, {
        answer: answer,
      }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      onMutate: () => {
        setValue(answer.current);
      },
      onSuccess: (data) => {
        refetchMoodQuestion();
        setModalActive(false);
      },
      onError: (error) => {},
    }
  );

  useEffect(() => {
    setSliderSuccess(sliderSuccess);
  }, [value]);

  const isChildrenMoodPending = useRef(false);

  useEffect(() => {
    if (!data?.data.answered && !isLoading && !isChildrenMoodPending.current) {
      isChildrenMoodPending.current = true;

      const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: data?.data.question.image?.content,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
        },
      };

      setModalData({
        data: [
          {
            title: {
              obj: <p>{question}</p>,
            },
            img: {
              obj: data?.data.question.image,
              lottie:
                data?.data.question.image?.content?.nm !== undefined
                  ? true
                  : false,
              lottieSettings: defaultOptions,
              size: "210px",
              marginTop: "30px",
            },
            component: (
              <MoodReader
                answer={answer}
                value={value}
                setSliderSuccess={setSliderSuccess}
                sliderSuccess={sliderSuccess}
              />
            ),
            action: {
              obj: [
                {
                  text: "Zapisz",
                  style: { backgroundColor: "#85D6A6" },
                  onClick: () => {
                    setValue(answer.current);

                    questionAnswer.mutate();
                  },
                },
              ],
            },
          },
        ],
      });
    }
  }, [isLoading, questionAnswer.isLoading, image, data]);
};

export default ChildrenMoodCheck;
