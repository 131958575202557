import Button from "../../../buttons/Button";

const AccountAvatar = ({ avatar, onClick }) => {
    return (
        <div className="AccountAvatar">
            <div className="AccountAvatar__Preview">
                <div className="AccountAvatar__Text">AVATAR</div>
                <div
                    className="AccountAvatar__Emote"
                    style={{ backgroundImage: "url(" + avatar + ")" }}
                ></div>
            </div>
            <div className="AccountAvatar__Link">
                <Button className="blank color" onClickFn={onClick}>
                    Edytuj avatara
                </Button>
            </div>
        </div>
    );
};

export default AccountAvatar;
