const NotifyBlock = ({img, contentBelow, children}) => {
    return (
        <>
            <div className="app-notifyBlock">
                <div className="app-notifyBlock-wrapper">
                    <div className={"app-notifyBlock-content" + (img ? ' has-img' : '')}>
                        <div className="notifyBlock-text text-center">{children}</div>
                        {img && (
                            <div className="notifyBlock-img">
                                <img src={img} alt="" />
                            </div>
                        )}
                    </div>
                    {
                        contentBelow &&
                        <div className="app-notifyBlock-contentBelow">{contentBelow}</div>
                    }
                </div>
            </div>
        </>
    );
}

export default NotifyBlock;