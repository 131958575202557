import PropTypes from "prop-types";
import React from "react";
import clsx from "clsx";
import { navigate } from "./utils/constants";
import add from "./assets/Group.svg";
import CalendarModal from "./CalendarModal";
import forward from "./assets/Path 3273.svg";
import backward from "./assets/Path 3272.svg";

class Toolbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isModalActive: false };
  }
  render() {
    let {
      localizer: { messages },
      label,
    } = this.props;

    const modalActiveHandler = () => {
      this.setState({ isModalActive: !this.state.isModalActive });
    };

    return (
      <div className="rbc-toolbar" style={{ marginTop: 0, paddingTop: 15 }}>
        <div className="rbc-btn-group ">
          <div className="rbc-btn-navigation" style={{ position: "relative" }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <button
                className="rbc-btn-naviagtion-back"
                onClick={this.navigate.bind(null, navigate.PREVIOUS)}
              >
                <img src={backward}></img>
              </button>
              <button
                type="button"
                className="rbc-btn-today"
                onClick={this.navigate.bind(null, navigate.TODAY)}
              >
                {messages.today}
              </button>

              <button
                className="rbc-btn-naviagtion-foward"
                onClick={this.navigate.bind(null, navigate.NEXT)}
              >
                <img src={forward}></img>
              </button>
            </div>
            <div
              style={{
                position: "absolute",
                right: "10px",
              }}
            >
              <button
                onClick={() => this.setState({ isModalActive: true })}
                className="rbc-addEventButton"
                style={{
                  backgroundColor: "none",
                  height: "25px",
                }}
              >
                <img src={add} />
              </button>
            </div>
          </div>
        </div>

        {/* <span className="rbc-toolbar-label">{label}</span> */}

        <div className="rbc-btn-group rbc-btn-messages">
          {this.viewNamesGroup(messages)}
        </div>
        {this.state.isModalActive ? (
          <CalendarModal isModalActiveHandler={modalActiveHandler} />
        ) : (
          ""
        )}
      </div>
    );
  }

  navigate = (action) => {
    this.props.onNavigate(action);
  };

  view = (view) => {
    this.props.onView(view);
  };

  viewNamesGroup(messages) {
    let viewNames = this.props.views;
    const view = this.props.view;

    if (viewNames.length > 1) {
      return viewNames.map((name) => (
        <button
          type="button"
          // className="rbc-button"
          key={name}
          className={clsx({ "rbc-active": view === name })}
          onClick={this.view.bind(null, name)}
        >
          <span className="rbc-button-name">{messages[name]}</span>
        </button>
      ));
    }
  }
}

Toolbar.propTypes = {
  view: PropTypes.string.isRequired,
  views: PropTypes.arrayOf(PropTypes.string).isRequired,
  label: PropTypes.node.isRequired,
  localizer: PropTypes.object,
  onNavigate: PropTypes.func.isRequired,
  onView: PropTypes.func.isRequired,
};

export default Toolbar;
