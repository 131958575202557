import { useRef, useContext } from "react";
import { useQuery } from "react-query";

import { StoreContext } from "../context/Store";

const useLearningProgressDetails = (idUser, idMultibook) => {
    const { api } = useContext(StoreContext);

    const idMultibookMemo = useRef(idMultibook);

    const {
        data: learningDetailsData,
        isFetched: isLearningDetailsFetched,
        isRefetching: isLearningDetailsRefetching,
        isLoading: isLearningLDetailsLoading,
        remove: removeLearningDetails,
    } = useQuery(
        "LearningProgress_" + idMultibookMemo.current,
        () =>
            api.post("/learning-progress/more", {
                idUser,
                idMultibook: idMultibookMemo.current,
            }),
        {
            enabled: idUser && idMultibook ? true : false,
            staleTime: Infinity,
            retry: false,
            refetchOnWindowFocus: false,
        }
    );

    return {
        learningDetailsData,
        isLearningDetailsFetched,
        isLearningDetailsRefetching,
        isLearningLDetailsLoading,
        removeLearningDetails,
    };
};

export default useLearningProgressDetails;
