import { useRef } from "react";

import Title from "../../headings/Title";
import Input from "../../inputs/Input";
import Textarea from "../../inputs/Textarea";
import Button from "../../buttons/Button";

import SchoolGroupFormField from "../../containers/dashboard/school-groups/SchoolGroupFormField";
import Repeater from "../../containers/dashboard/Repeater";

const SchoolGroupForm = ({
    value,
    validator,
    onChangeFn,
    onSubmitFn,
    onCancelFn,
    modalSetters,
    isDisabled,
}) => {
    /* INPUT RESPONSE */
    const inputResponse = useRef("");

    /* TAGS REPEATER ON CHANGE HANDLER */
    const tagsOnChangeHandler = (e) => {
        if (e.length > 0) {
            inputResponse.current = "success";
        } else {
            inputResponse.current = "";
        }

        onChangeFn({
            value: { tags: e },
            validator: { tags: { response: inputResponse.current } },
        });
    };

    /* INPUT ON CHANGE HANDLER */
    const inputOnChangeHandler = (val, key, minLength) => {
        if (minLength) {
            if (val.length >= minLength) {
                inputResponse.current = "success";
            } else {
                if (val.length === 0) {
                    inputResponse.current = "";
                } else {
                    inputResponse.current = "error";
                }
            }
        } else {
            if (val.length > 0) {
                inputResponse.current = "success";
            } else {
                inputResponse.current = "";
            }
        }

        onChangeFn({
            value: { [key]: val },
            validator: { [key]: { response: inputResponse.current } },
        });
    };

    /* ENABLE SUBMIT IF VALIDATION IS CORRECT */
    const formSubmitDisable =
        validator.name.response === "success" &&
        validator.tags.response === "success" &&
        validator.description.response === "success"
            ? false
            : true;

    return (
        <>
            <SchoolGroupFormField>
                <Title className="medium text-center" type={2}>
                    WPISZ NAZWĘ GRUPY
                </Title>
                <Input
                    placeholder="wpisz nazwę grupy"
                    value={value.name}
                    isDisabled={isDisabled}
                    isValid={{ response: validator.name.response }}
                    onChangeFn={(e) =>
                        inputOnChangeHandler(e.currentTarget.value, "name", 3)
                    }
                />
            </SchoolGroupFormField>
            <SchoolGroupFormField>
                <Repeater
                    heading={{
                        title: "WYBIERZ TAGI",
                        subtitle: "(Możesz wybrać maksymalnie 5 tagów)",
                    }}
                    selectedData={value.tags}
                    query={{
                        url: "/tags",
                        queryKey: "formGroupTags",
                        selectedData: value.tags,
                        dataKey: {
                            id: "idTag",
                            name: "name",
                        },
                    }}
                    placeholder={{
                        initial: "dodaj tagi",
                    }}
                    max={5}
                    modalLarge
                    onChange={(data) => tagsOnChangeHandler(data)}
                    modalSetters={modalSetters}
                    isDisabled={isDisabled}
                />
            </SchoolGroupFormField>
            <SchoolGroupFormField>
                <Title className="medium text-center" type={2}>
                    DODAJ OPIS
                </Title>
                <Textarea
                    placeholder="dodaj opis"
                    value={value.description}
                    style={{
                        height: "142px",
                        minHeight: "142px",
                        textAlign: "left",
                    }}
                    isDisabled={isDisabled}
                    isValid={{
                        response: validator.description.response,
                    }}
                    onChangeFn={(e) =>
                        inputOnChangeHandler(
                            e.currentTarget.value,
                            "description",
                            3
                        )
                    }
                />
            </SchoolGroupFormField>
            <div className="school-group-buttons">
                <Button
                    className="disabled-alt"
                    isLoading={isDisabled}
                    isDisabled={isDisabled || formSubmitDisable}
                    onClickFn={onSubmitFn}
                >
                    Zapisz
                </Button>
                {onCancelFn && (
                    <Button
                        className="gray"
                        isDisabled={isDisabled}
                        onClickFn={onCancelFn}
                    >
                        Anuluj
                    </Button>
                )}
            </div>
        </>
    );
};

export default SchoolGroupForm;
