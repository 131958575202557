import { useState } from "react";

import Loader from "../containers/Loader";
import Wrapper from "../containers/Wrapper";
import ButtonChoice from "./ButtonChoice";

const getIcon = (action, setIsActionMissing) => {
    switch (action) {
        case "link":
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="8.7"
                    height="14.571"
                    viewBox="0 0 8.7 14.571"
                >
                    <path
                        id="Path_50"
                        dataname="Path 50"
                        d="M-296.347,1986.372l5.871,5.871-5.871,5.871"
                        transform="translate(297.761 -1984.958)"
                        fill="none"
                        stroke="#FFFFFF"
                        strokeLinecap="round"
                        strokeWidth="2"
                    />
                </svg>
            );
        case "add":
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                >
                    <g
                        id="Group_11"
                        dataname="Group 11"
                        transform="translate(-250.5 -477)"
                    >
                        <line
                            id="Line_2"
                            dataname="Line 2"
                            x2="13"
                            transform="translate(251.5 484.5)"
                            fill="none"
                            stroke="#fff"
                            strokeLinecap="round"
                            strokeWidth="2"
                        />
                        <line
                            id="Line_3"
                            dataname="Line 3"
                            y2="13"
                            transform="translate(258 478)"
                            fill="none"
                            stroke="#fff"
                            strokeLinecap="round"
                            strokeWidth="2"
                        />
                    </g>
                </svg>
            );
        case "remove":
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width="11"
                    height="11"
                    viewBox="0 0 11 11"
                >
                    <defs>
                        <clipPath id="clip-path">
                            <rect
                                id="Rectangle_1265"
                                dataname="Rectangle 1265"
                                width="11"
                                height="11"
                                fill="none"
                                stroke="#707070"
                                strokeWidth="2"
                            />
                        </clipPath>
                    </defs>
                    <g
                        id="Group_722"
                        dataname="Group 722"
                        transform="translate(-83 5)"
                    >
                        <g
                            id="Group_633"
                            dataname="Group 633"
                            transform="translate(83 -5)"
                            clipPath="url(#clip-path)"
                        >
                            <line
                                id="Line_63"
                                dataname="Line 63"
                                y1="9"
                                x2="9"
                                transform="translate(1 1)"
                                fill="none"
                                stroke="#414043"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                            />
                            <line
                                id="Line_64"
                                dataname="Line 64"
                                x2="9"
                                y2="9"
                                transform="translate(1 1)"
                                fill="none"
                                stroke="#414043"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                            />
                        </g>
                    </g>
                </svg>
            );
        default:
            setIsActionMissing(true);

            break;
    }
};

const wrapperRoute = (className, choices) => {
    if (choices) {
        return (children) => (
            <div
                className={
                    "btn-choices-field" + (className ? " " + className : "")
                }
            >
                {children}
            </div>
        );
    } else {
        return (children) => <div className={className}>{children}</div>;
    }
};

const Button = ({
    className,
    classNameWrapper,
    thumbnail,
    choices,
    action,
    disableDimm,
    isLoading,
    isDisabled,
    isChoiceDisabled,
    onClickFn,
    acceptOnClickFn,
    declineOnClickFn,
    children,
}) => {
    const [isActionMissing, setIsActionMissing] = useState(false);

    return (
        <>
            <Wrapper
                condition={classNameWrapper || choices}
                wrapper={wrapperRoute(classNameWrapper, choices)}
            >
                <button
                    className={
                        "btn-default" +
                        (thumbnail ? " thumbnail" : "") +
                        (action && !isActionMissing && !isLoading ? " action" : "") +
                        (action && !isActionMissing && isLoading ? " action-loading" : "") +
                        (className ? " " + className : "") +
                        (choices ? " choices" : "") +
                        (disableDimm ? " disable-dimm" : "")
                    }
                    type="button"
                    disabled={isDisabled}
                    onClick={onClickFn}
                >
                    {thumbnail && (
                        <span
                            className="thumbnail-holder"
                            style={{
                                backgroundImage: "url(" + thumbnail + ")",
                            }}
                        ></span>
                    )}
                    {isLoading ? <Loader type="ellipsis" /> : children}
                    {action && !isActionMissing && !isLoading && (
                        <span className="icon-holder">
                            {getIcon(action, setIsActionMissing)}
                        </span>
                    )}
                </button>
                {choices && isDisabled && (
                    <div className="btn-choices-group">
                        <ButtonChoice
                            isDisabled={isChoiceDisabled}
                            onClickFn={acceptOnClickFn}
                        />
                        <ButtonChoice
                            decline
                            isDisabled={isChoiceDisabled}
                            onClickFn={declineOnClickFn}
                        />
                    </div>
                )}
            </Wrapper>
        </>
    );
};

export default Button;
