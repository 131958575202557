import React from "react";

const Subscriber = ({ avatar, status, date, children }) => {
    const statusConditions = (conditionType, statusValues) => {
        if (conditionType === "badge") {
            return statusValues.badge;
        }
        if (conditionType === "class") {
            return statusValues.class;
        }
        if (conditionType === "text") {
            return statusValues.text;
        }
    };

    const statusValue = (type, value) => {
        switch (value) {
            case "active":
                return statusConditions(type, {
                    badge: "Aktywny",
                    class: "active",
                    text: "Odnowienie",
                });
            case "expiring":
                return statusConditions(type, {
                    badge: "Wygasający",
                    class: "expiring",
                    text: "Ważny do",
                });
            case "waiting":
                return statusConditions(type, {
                    badge: "Oczekujący",
                    class: "waiting",
                    text: "Oczekujący na płatność",
                });
            case "inactive":
            case null:
                return statusConditions(type, {
                    badge: "Nieaktywny",
                    class: "inactive",
                    text: "Wygasł",
                });
            default:
                return "";
        }
    };

    return (
        <>
            <div
                className={
                    "subscriber" +
                    (statusValue("class", status)
                        ? " " + statusValue("class", status)
                        : "")
                }
            >
                <div className="subscriber-data">
                    {avatar ? (
                        <div className="subscriber-avatar">
                            <div
                                className="avatar-inner"
                                style={{
                                    backgroundImage: "url(" + avatar + ")",
                                }}
                            ></div>
                        </div>
                    ) : (
                        ""
                    )}
                    <div className="subscriber-name">{children}</div>
                </div>
                <div className="subscriber-status">
                    <div className="subscriber-status-badge">
                        {statusValue("badge", status)}
                    </div>
                    {date && (
                        <div className="subscriber-status-time">
                            {status === "waiting"
                                ? statusValue("text", status)
                                : statusValue("text", status) + ": " + date}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default React.memo(Subscriber);
