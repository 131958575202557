import { useState, useRef, useEffect, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useMutation, useQuery } from "react-query";

import { StoreContext } from "../../../context/Store";

import useLogout from "../../../hooks/useLogout";

import routeLoadingState from "../../../functions/routeLoadingState";
import pathSlice from "../../../functions/pathSlice";

import Title from "../../../components/headings/Title";
import Button from "../../../components/buttons/Button";
import CheckboxGroup from "../../../components/containers/CheckboxGroup";

import DashboardContainer from "../../../components/containers/dashboard/DashboardContainer";
import DashboardGroup from "../../../components/containers/dashboard/DashboardGroup";

import AccountData from "../../../components/containers/dashboard/account/AccountData";

import modalDoodleImage_1 from "../../../assets/images/dashboard/modal-doodles/questionmark_4.svg";
import modalDoodleImage_2 from "../../../assets/images/dashboard/modal-doodles/sad_2.svg";
import modalDoodleImage_3 from "../../../assets/images/dashboard/modal-doodles/faint.svg";
import modalDoodleImage_4 from "../../../assets/images/dashboard/modal-doodles/crying.svg";

const ParentAccount = ({ routeMatches, routeSetters, modalSetters }) => {
    const { setRouteData, setRouteIsLoading } = routeSetters;
    const {
        setModalData,
        setModalActive,
        setModalApiResponse,
        setModalApiLoading,
    } = modalSetters;

    const history = useHistory();
    const location = useLocation();

    const logout = useLogout();

    const { api } = useContext(StoreContext);

    const [billingTab, setBillingTab] = useState("private"); // "private" | "company"

    /* GET ACCOUNT DATA */
    const {
        data: accountData,
        isFetched: accountIsFetched,
        isRefetching: accountIsRefetching,
        isLoading: accountIsLoading,
    } = useQuery("account", () => api.get(`/account`), {
        staleTime: Infinity,
        retry: false,
        refetchOnWindowFocus: false,
    });

    /* ACCOUNT CHANGE INVOICE TYPE */
    const { mutate: accountChangeInvoiceMutate } = useMutation(
        (data) => api.post(`/account/invoice/type`, data),
        {
            onError: () => alert("*Placeholder* Error with sending data"),
        }
    );

    /* ACCOUNT DELETE */
    const { mutate: parentDeleteAccountMutate } = useMutation(
        () => api.post(`/account/delete`),
        {
            onMutate: () => setModalApiLoading(true),
            onSuccess: () => {
                setModalApiResponse(true);
            },
            onError: () => alert("*Placeholder* Error with sending data"),
        }
    );

    /* SET BILLING TAB BY ACCOUNT TYPE */
    useEffect(() => {
        if (accountData) {
            setBillingTab(accountData.data.invoice?.account_type ?? "");
        }
    }, [accountData]);

    /* ROUTE LOADING */
    const routeIsLoading = routeLoadingState({
        fetched: [accountIsFetched],
        loading: [accountIsLoading],
        refetching: [accountIsRefetching],
    });

    /* ROUTE SETTERS */
    useEffect(() => {
        const routeData = {
            title: "MOJE KONTO",
        };

        if (routeMatches) {
            setRouteIsLoading(routeIsLoading);
            setRouteData(routeData);
        }
    }, [routeIsLoading, routeMatches, setRouteIsLoading, setRouteData]);

    /* MODAL LOGOUT DATA */
    const modalLogoutData = useRef({});
    const modalDeleteAccountData = useRef({});

    useEffect(() => {
        modalLogoutData.current = {
            data: [
                {
                    title: {
                        obj: (
                            <p>
                                Czy na pewno
                                <br />
                                chcesz się wylogować?
                            </p>
                        ),
                    },
                    img: {
                        obj: modalDoodleImage_1,
                        size: "185px",
                    },
                    action: {
                        obj: [
                            {
                                text: "Tak, chcę",
                                important: true,
                                onClick: () => {
                                    setModalApiLoading(true);
                                    setModalApiResponse(true);
                                },
                            },
                            {
                                text: "Nie, nie chcę",
                                onClick: () => setModalActive(false),
                            },
                        ],
                        key: "userLogoutStart",
                    },
                },
                {
                    title: {
                        obj: (
                            <p>
                                Za chwilę zostaniesz
                                <br />
                                wylogowany/na z aplikacji
                            </p>
                        ),
                        isCenter: true,
                    },
                    img: {
                        obj: modalDoodleImage_2,
                        size: "185px",
                    },
                    action: {
                        obj: {
                            text: "Wyloguj natychmiast",
                            onClick: () => logout("/"),
                        },
                        auto: true,
                        autoTimeout: 5000,
                    },
                },
            ],
        };

        modalDeleteAccountData.current = {
            data: [
                {
                    title: {
                        obj: (
                            <p>
                                Jeśli usuniesz konto stracisz dostęp do
                                wszystkich materiałów oraz utracisz dane
                                zapisane w aplikacji
                            </p>
                        ),
                    },
                    img: {
                        obj: modalDoodleImage_3,
                        size: "187px",
                    },
                    action: {
                        obj: [
                            {
                                text: "Rozumiem i chcę usunąć konto",
                                important: true,
                                onClick: () => parentDeleteAccountMutate(),
                            },
                            {
                                text: "O nie! Zostaję w How2 Edu",
                                onClick: () => setModalActive(false),
                            },
                        ],
                        key: "parentAccountDelete",
                    },
                },
                {
                    special: "accountDelete",
                    title: {
                        obj: (
                            <p>
                                Twoje konto zostało
                                <br />
                                usunięte!
                            </p>
                        ),
                    },
                    img: {
                        obj: modalDoodleImage_4,
                        size: "123px",
                    },
                    action: {
                        obj: {
                            text: "Wróć do ekranu początkowego",
                            onClick: () => logout("/"),
                        },
                        auto: true,
                        autoTimeout: 5000,
                    },
                },
            ],
        };
    }, [
        setModalActive,
        setModalApiLoading,
        setModalApiResponse,
        parentDeleteAccountMutate,
        logout,
    ]);

    return (
        <>
            <DashboardContainer className="account">
                {!routeIsLoading && (
                    <>
                        <AccountData
                            data={[
                                {
                                    title: "Imię",
                                    text: accountData.data.user.name,
                                    button: {
                                        text: "Edytuj dane",
                                        onClickFn: () =>
                                            history.push(
                                                `${pathSlice(
                                                    location.pathname
                                                )}/edit-account`,
                                                {
                                                    billingTab,
                                                }
                                            ),
                                    },
                                },
                                {
                                    title: "Nazwisko",
                                    text: accountData.data.user.surname,
                                },
                                {
                                    title: "E-mail",
                                    text: accountData.data.user.email,
                                },
                                {
                                    title: "Telefon",
                                    text: accountData.data.user.phone,
                                },
                                {
                                    title: "Kod pocztowy",
                                    text: accountData.data.user.zipCode,
                                },
                                {
                                    title: "Data urodzenia",
                                    text: accountData.data.user.birthdate,
                                },
                                {
                                    title: "Hasło",
                                    text: "•••••••••••••••",
                                    button: {
                                        text: "Edytuj hasło",
                                        onClickFn: () =>
                                            history.push(
                                                `${pathSlice(
                                                    location.pathname
                                                )}/edit-password`
                                            ),
                                    },
                                },
                            ]}
                            dataKey="parentData"
                        />
                        <Button
                            classNameWrapper="AccountData__Delete"
                            className="blank thin"
                            onClickFn={() =>
                                setModalData(modalDeleteAccountData.current)
                            }
                        >
                            Usuń konto
                        </Button>
                        <DashboardGroup className="billing">
                            <Title className="medium" type={2}>
                                DANE DO FAKTURY
                            </Title>
                            <CheckboxGroup
                                className="_billing"
                                data={[
                                    {
                                        text: "Osoba prywatna",
                                        isChecked: billingTab === "private",
                                        onChangeFn: () => {
                                            setBillingTab("private");
                                            accountChangeInvoiceMutate({
                                                account_type: "private",
                                            });
                                        },
                                    },
                                    {
                                        text: "Firma",
                                        isChecked: billingTab === "company",
                                        onChangeFn: () => {
                                            setBillingTab("company");
                                            accountChangeInvoiceMutate({
                                                account_type: "company",
                                            });
                                        },
                                    },
                                ]}
                            />
                            {billingTab === "private" && (
                                <AccountData
                                    className="_billing"
                                    data={[
                                        {
                                            title: "Imię",
                                            text: accountData.data.invoice.name,
                                            vertical: true,
                                        },
                                        {
                                            title: "Naziwsko",
                                            text: accountData.data.invoice
                                                .surname,
                                            vertical: true,
                                        },
                                        {
                                            title: "Kod pocztowy",
                                            text: accountData.data.invoice
                                                .zipCode,
                                            vertical: true,
                                        },
                                        {
                                            title: "Miasto",
                                            text: accountData.data.invoice
                                                .city,
                                            vertical: true,
                                        },
                                        {
                                            title: "Ulica",
                                            text: accountData.data.invoice
                                                .address,
                                            vertical: true,
                                        },
                                    ]}
                                    dataKey="privateBillingData"
                                />
                            )}
                            {billingTab === "company" && (
                                <AccountData
                                    className="_billing"
                                    data={[
                                        {
                                            title: "Nazwa firmy",
                                            text: accountData.data.invoice
                                                .company_name,
                                            vertical: true,
                                        },
                                        {
                                            title: "NIP",
                                            text: accountData.data.invoice.nip,
                                            vertical: true,
                                        },
                                        {
                                            title: "Kod pocztowy",
                                            text: accountData.data.invoice.company_zipCode,
                                            vertical: true,
                                        },
                                        {
                                            title: "Miasto",
                                            text: accountData.data.invoice.company_city,
                                            vertical: true,
                                        },
                                        {
                                            title: "Ulica",
                                            text: accountData.data.invoice
                                                .company_address,
                                            vertical: true,
                                        },
                                    ]}
                                    dataKey="companyBillingData"
                                />
                            )}
                        </DashboardGroup>
                        <div className="AccountData__ButtonGroup">
                            <Button
                                className="medium-2 inverse hover-yellow"
                                onClickFn={() =>
                                    setModalData(modalLogoutData.current)
                                }
                            >
                                Wyloguj się
                            </Button>
                        </div>
                    </>
                )}
            </DashboardContainer>
        </>
    );
};

export default ParentAccount;
