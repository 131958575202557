import { useEffect, useContext } from "react";
import { useQuery } from "react-query";

import { StoreContext } from "../../../context/Store";
import { ChapterContext } from "../context/ChapterStore";
import { ExamContext } from "../context/ExamStore";

import getLoadingState from "../functions/getLoadingState";

const equalizeExamAnswers = (answers) => {
    if (!answers || answers.data[0] === null) return;

    const answersData = answers.data[0];

    return {
        idChapter: answersData.idChapter,
        data: answersData.answer,
    };
};

const useGetExamAnswers = (idChapter, isExam, isDisabled) => {
    const { addChapterData } = useContext(ChapterContext);
    const { isExamLoading, setIsExamLoading } = useContext(ExamContext);

    const { api } = useContext(StoreContext);

    const {
        data: examAnswersData,
        refetch: examAnswersRefetch,
        isFetched: isExamAnswersFetched,
        isLoading: isExamAnswersLoading,
    } = useQuery(
        ["examAnswers", idChapter],
        () => api.post("/exams/get", { idChapter }),
        {
            enabled: false,
            staleTime: Infinity,
            retry: false,
            refetchOnWindowFocus: false,
        }
    );

    const isHookLoading = idChapter
        ? getLoadingState([isExamAnswersFetched], [isExamAnswersLoading])
        : false;

    useEffect(() => {
        if (idChapter && isExam && !isDisabled && !isExamAnswersFetched) {
            setIsExamLoading(true);

            examAnswersRefetch();
        }
    }, [
        idChapter,
        isExam,
        isDisabled,
        isExamAnswersFetched,
        examAnswersRefetch,
        setIsExamLoading,
    ]);

    useEffect(() => {
        if (examAnswersData && !isHookLoading && isExamLoading) {
            if (examAnswersData.data[0] !== null) {
                addChapterData(equalizeExamAnswers(examAnswersData));
            }

            setTimeout(() => {
                setIsExamLoading(false);
            }, 2000);
        }
    }, [
        examAnswersData,
        isHookLoading,
        isExamLoading,
        addChapterData,
        setIsExamLoading,
    ]);

    return !isHookLoading
        ? {
              examAnswersData: equalizeExamAnswers(examAnswersData),
              isExamAnswersLoading: isHookLoading || isExamLoading,
          }
        : { isExamAnswersLoading: true };
};

export default useGetExamAnswers;
