import { useEffect, useRef, useContext } from "react";
import { useQuery } from "react-query";
import { useParams, useHistory, useLocation } from "react-router-dom";

import { StoreContext } from "../../../context/Store";

import routeLoadingState from "../../../functions/routeLoadingState";
import pathSlice from "../../../functions/pathSlice";

import Button from "../../../components/buttons/Button";

import DashboardContainer from "../../../components/containers/dashboard/DashboardContainer";
import DashboardGroup from "../../../components/containers/dashboard/DashboardGroup";
import SubscriptionStatus from "../../../components/containers/dashboard/subscriptions/SubscribtionStatus";

const Children = ({ routeMatches, routeSetters }) => {
    const { setRouteData, setRouteIsLoading } = routeSetters;

    const params = useParams();
    const history = useHistory();
    const location = useLocation();

    const { api } = useContext(StoreContext);

    /* PARAMS ID REF */
    const idChildRef = useRef(params.idChild);

    /* GET CHILDREN DATA */
    const {
        data: childData,
        isFetched: childIsFetched,
        isLoading: childIsLoading,
    } = useQuery(
        ["child", idChildRef.current],
        () => api.post(`/account/child`, { idChild: idChildRef.current }),
        {
            staleTime: Infinity,
            retry: false,
            refetchOnWindowFocus: false,
        }
    );

    const subscriptionStatus = childData?.data.child[0].status;
    const isDisabled =
        subscriptionStatus === "inactive" ||
        subscriptionStatus === "waiting" ||
        subscriptionStatus === null;

    const returnDate = () => {
        if (subscriptionStatus === "waiting") {
            return undefined;
        }
        if (
            subscriptionStatus === "active" ||
            subscriptionStatus === "expiring" ||
            subscriptionStatus === "inactive" //inactive can still return valid date OR null
        ) {
            return childData.data.child[0].active_to?.substring(0, 10);
        }
    };

    /* ROUTE LOADING */
    const routeIsLoading = routeLoadingState({
        fetched: [childIsFetched],
        loading: [childIsLoading],
    });

    /* ROUTE SETTERS */
    useEffect(() => {
        const routeData = {
            title: routeIsLoading ? '' : childData?.data.child[0].name.toUpperCase(),
        };

        if (routeMatches) {
            setRouteIsLoading(routeIsLoading);
            setRouteData(routeData);
        }
    }, [routeIsLoading, routeMatches, setRouteIsLoading, setRouteData, childData]);

    return (
        <>
            <DashboardContainer>
                {!routeIsLoading && (
                    <DashboardGroup>
                        <SubscriptionStatus
                            status={subscriptionStatus}
                            date={returnDate()}
                        />
                        <Button
                            className="disabled-alt creamy"
                            action="link"
                            onClickFn={() =>
                                history.push(
                                    `${pathSlice(location.pathname)}/account`
                                )
                            }
                        >
                            Konto dziecka
                        </Button>
                        <Button
                            className="disabled-alt creamy"
                            action="link"
                            isDisabled={isDisabled}
                            onClickFn={() =>
                                history.push(
                                    `${pathSlice(
                                        location.pathname
                                    )}/learning-progress`
                                )
                            }
                        >
                            Postępy w nauce
                        </Button>
                        <Button
                            className="disabled-alt creamy"
                            action="link"
                            onClickFn={() =>
                                history.push(
                                    `${pathSlice(location.pathname)}/school`
                                )
                            }
                        >
                            Szkoła
                        </Button>
                    </DashboardGroup>
                )}
            </DashboardContainer>
        </>
    );
};

export default Children;
