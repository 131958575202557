import { useState } from "react";
import { Range } from "react-range";

import mood from "../../../assets/images/dashboard/mood-check/mood-doodle_1.svg";
import mood2 from "../../../assets/images/dashboard/mood-check/mood-doodle_2.svg";
import mood3 from "../../../assets/images/dashboard/mood-check/mood-doodle_3.svg";
import mood4 from "../../../assets/images/dashboard/mood-check/mood-doodle_4.svg";
import mood5 from "../../../assets/images/dashboard/mood-check/mood-doodle_5.svg";

const MoodReader = ({ title, themeColor, answer, value, setSliderSuccess }) => {
  const [sliderValue, setSliderValue] = useState(value ?? 3);

  //SLIDER CHANGE HANDLER
  const sliderChangeHandler = (value) => {
    answer.current = value;
    setSliderSuccess(true);
  };

  return (
    <div className="ReaderSlider" style={{ marginTop: "50px" }}>
      {title ? <div className="ReaderSlider__Title"></div> : ""}

      <>
        <Range
          step={1}
          min={1}
          max={5}
          values={[sliderValue]}
          renderMark={({ props }) => (
            <div
              {...props}
              style={{
                ...props.style,
                width: "2px",
                height: "24px",
                background: themeColor === "dark" ? "#85D6A6" : "#85D6A6",
                opacity: themeColor === "dark" ? "0.46" : "0.32",
                marginTop: "-7px",
              }}
            ></div>
          )}
          renderTrack={({ props, children }) => (
            <div
              {...props}
              style={{
                ...props.style,
                height: "10px",
                width: "100%",

                backgroundColor: themeColor === "dark" ? "#E1F5E9" : "#E1F5E9",
                borderRadius: "0",
              }}
            >
              {children}
            </div>
          )}
          renderThumb={({ props }) => (
            <div
              {...props}
              style={{
                ...props.style,
                height: "48px",
                width: "28px",
                backgroundColor: "transparent",
                borderRadius: "10px",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  height: "41px",
                  width: "41px",
                  backgroundImage:
                    sliderValue == 1
                      ? `url(${mood})`
                      : sliderValue == 2
                      ? `url(${mood2})`
                      : sliderValue == 3
                      ? `url(${mood3})`
                      : sliderValue == 4
                      ? `url(${mood5})`
                      : `url(${mood4})`,
                  borderRadius: "10px",
                  marginTop: "-21px",
                  marginLeft: "-21px",
                  transition: "background .2s ease",
                }}
              />
            </div>
          )}
          onChange={(values) => setSliderValue(...values)}
          onFinalChange={(value) => sliderChangeHandler(...value)}
        />
      </>
    </div>
  );
};

export default MoodReader;
