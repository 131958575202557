import { createPortal } from "react-dom";
import { useState, useContext } from "react";
import { useMutation, useQueryClient } from "react-query";

import { StoreContext } from "./../../../context/Store";
import imageHasBeenDeleted from "./../ReactBigCalendar/assets/Group 2279.svg";

const ModalDeleteQuestion = ({ title, img, id, setIsDeleted }) => {
  const { api } = useContext(StoreContext);
  const queryClient = useQueryClient();
  const [image, setImage] = useState(img);
  const [success, setSuccess] = useState(false);
  const [modalTitle, setModalTitle] = useState(title);

  const removeEvent = useMutation(
    () =>
      api.post(`/calendar/delete`, {
        idCalendar: id,
      }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setImage(imageHasBeenDeleted);
        setIsDeleted(false);
        setSuccess(true);
        setModalTitle("Twoje wydarzenie zostało usunięte");
        queryClient.invalidateQueries("calendar");
      },
      onError: (error) => {},
    }
  );

  const removeEventandModal = () => {
    removeEvent.mutate();
    setIsDeleted(true);
  };

  return createPortal(
    <div className="rbg-modal">
      <div className="rbg-modal-content-small">
        <h3 className="rbg-modal-newEvent" style={{ marginTop: "35px" }}>
          {modalTitle}
        </h3>
        <div className="rbg-modal-body">
          <div className="rbg-modal-image">
            <img src={image}></img>
          </div>
          <div className="rbg-modal-buttons">
            {success ? (
              <button
                className="rbg-modal-button1"
                onClick={() => setIsDeleted(false)}
              >
                Zakończ
              </button>
            ) : (
              <>
                <button
                  className="rbg-modal-button1"
                  onClick={removeEventandModal}
                  disabled={removeEvent.isLoading}
                  style={{ opacity: removeEvent.isLoading ? "50%" : "100%" }}
                >
                  Tak, chcę
                </button>
                <button
                  className="rbg-modal-button2"
                  style={{
                    marginTop: "15px",
                    opacity: removeEvent.isLoading ? "50%" : "100%",
                  }}
                  onClick={() => setIsDeleted(false)}
                  disabled={removeEvent.isLoading}
                >
                  Nie, nie chcę
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>,
    document.getElementById("modal_portal")
  );
};

export default ModalDeleteQuestion;
