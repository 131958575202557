import { useEffect, useContext } from "react";
import { useQuery } from "react-query";

import { ExamContext } from "../context/ExamStore";
import { StoreContext } from "../../../context/Store";

import getLoadingState from "../functions/getLoadingState";

const useGetExamSolved = (idChapter, isExam, isDisabled) => {
    const { isExamLoading, setIsExamLoading } = useContext(ExamContext);

    const { api } = useContext(StoreContext);

    const {
        data: examSolvedData,
        refetch: examSolvedRefetch,
        isFetched: isExamSolvedFetched,
        isLoading: isExamSolvedLoading,
    } = useQuery(
        ["examSolved", idChapter],
        () => api.post("/exams/result", { idChapter }),
        {
            enabled: false,
            staleTime: Infinity,
            retry: false,
            refetchOnWindowFocus: false,
        }
    );

    const isHookLoading = idChapter
        ? getLoadingState([isExamSolvedFetched], [isExamSolvedLoading])
        : false;

    useEffect(() => {
        if (idChapter && isExam && !isDisabled && !isExamSolvedFetched) {
            setIsExamLoading(true);

            examSolvedRefetch();
        }
    }, [
        idChapter,
        isExam,
        isDisabled,
        isExamSolvedFetched,
        examSolvedRefetch,
        setIsExamLoading,
    ]);

    useEffect(() => {
        if (examSolvedData && !isHookLoading && isExamLoading) {
            setTimeout(() => {
                setIsExamLoading(false);
            }, 2000);
        }
    }, [examSolvedData, isHookLoading, isExamLoading, setIsExamLoading]);

    return !isHookLoading
        ? {
              examSolvedData: examSolvedData?.data,
              isExamSolvedLoading: isHookLoading || isExamLoading,
          }
        : { isExamSolvedLoading: true };
};

export default useGetExamSolved;
