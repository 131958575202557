const DashboardContainer = ({ className, children }) => {
    return (
        <>
            <div
                className={"app-dashboard" + (className ? " " + className : "")}
            >
                <div className="app-dashboard-wrapper">{children}</div>
            </div>
        </>
    );
};

export default DashboardContainer;
