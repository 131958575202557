import { useState, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";

import ButtonTabSigning from "../components/buttons/signing/ButtonTabSigning";
import SigningTabsContainer from "../components/containers/signing/SigningTabsContainer";

import LoginForm from "../components/forms/signing/LoginForm";
import RegisterForm from "../components/forms/signing/RegisterForm";
import ResetCodeForm from "../components/forms/signing/ResetCodeForm";
import ResetForm from "../components/forms/signing/ResetForm";
import ModalAction from "../components/modal-bodies/ModalAction";
import ModalTransition from "../components/modal-bodies/ModalTransition";
import useProtectedRoute from "../hooks/useProtetedRoute";

const SigningForm = () => {
    useProtectedRoute("/dashboard", true);

    const location = useLocation();

    const [signingFormActive, setSigningFormActive] = useState({
        login: location?.state?.tab === "login",
        register: location?.state?.tab === "register",
        code: location?.state?.tab === "code",
        reset: false,
    });

    // case: user enters url directly, not from "/"
    useEffect(() => {
        if (!location?.state?.tab) {
            setSigningFormActive({
                login: true,
                register: false,
                code: false,
                reset: false,
            });
        }
    }, [location?.state?.tab]);

    /* MODAL ACTIVE */
    const [modalActive, setModalActive] = useState(false);

    /* MODAL DATA */
    const [modalData, setModalData] = useState({});
    const modalDataClear = useRef(false);

    /* MODAL API */
    const [modalApiResponse, setModalApiResponse] = useState(false);
    const [modalApiLoading, setModalApiLoading] = useState(false);
    const [modalApiRefetching, setModalApiRefetching] = useState(false);
    const modalApiClear = useRef(false);

    /* MODAL OPEN */
    useEffect(() => {
        if (modalData.data && !modalDataClear.current) {
            modalDataClear.current = true;

            setModalActive(true);
        }
    }, [modalData]);

    /* LOGIN */
    const [loginInputValue, setLoginInputValue] = useState({
        email: "",
        password: "",
    });

    const [loginInputValidator, setLoginInputValidator] = useState({
        email: "",
        password: "",
    });

    /* REGISTER */
    const [registerInputValue, setRegisterInputValue] = useState({
        name: "",
        surname: "",
        email: "",
        phone: "",
        postcode: "",
        birthdate: "",
        studentSchool: [],
        studentClass: "",
        teacherSchools: [],
        teacherSubjects: [],
    });

    const [registerInputValidator, setRegisterInputValidator] = useState({
        name: {
            response: "",
            errorMessage: "",
        },
        surname: {
            response: "",
            errorMessage: "",
        },
        email: {
            response: "",
            errorMessage: "",
        },
        phone: {
            response: "",
            errorMessage: "",
        },
        postcode: {
            response: "",
            errorMessage: "",
        },
        birthdate: {
            response: "",
            errorMessage: "",
        },
        studentClass: {
            response: "",
            errorMessage: ""
        }
    });

    /* REGISTER PASSWORD */
    const [registerPasswordValue, setRegisterPasswordValue] = useState({
        origin: "",
        repeated: "",
    });

    const [registerPasswordValidator, setRegisterPasswordValidator] = useState({
        uppercase: false,
        lowercase: false,
        length: false,
        special: false,
    });

    const [registerPasswordComparator, setRegisterPasswordComparator] =
        useState({
            origin: "",
            repeated: "",
        });

    /* REGISTER ROLES */
    const [registerRoleValue, setRegisterRoleValue] = useState({
        value: "",
        text: "",
    });

    /* REGISTER AGREEMENTS */
    const [registerAgreements, setRegisterAgreements] = useState({
        agreement_1: false,
        agreement_2: false,
    });

    const [registerAgreementsValidator, setRegisterAgreementsValidator] =
        useState({
            agreement_1: false,
        });

    /* MODAL PARENT */
    const [registerParentValue, setRegisterParentValue] = useState({
        parentEmail: "",
    });

    const [registerParentValidator, setRegisterParentValidator] = useState({
        response: "",
        errorMessage: "",
    });

    /* RESET CODE */
    const [resetCodeInputValue, setResetCodeInputValue] = useState({
        email: "",
        code: "",
    });

    const [resetCodeInputValidator, setResetCodeInputValidator] = useState({
        email: {
            response: "",
            errorMessage: "",
        },
        code: {
            response: "",
            errorMessage: "",
        },
    });

    /* SIGNING LOADING */
    const [isSigningLoading, setIsSigningLoading] = useState(false);
    const [isSigningChildrenLoading, setIsSigningChildrenLoading] = useState(false);

    return (
        <>
            <div className="app-signing">
                <SigningTabsContainer>
                    <ButtonTabSigning
                        isDisabled={
                            signingFormActive.code || signingFormActive.reset || isSigningLoading || isSigningChildrenLoading
                        }
                        isActive={
                            signingFormActive.login ||
                            signingFormActive.code ||
                            signingFormActive.reset
                        }
                        onTabClick={() =>
                            setSigningFormActive({
                                ...signingFormActive,
                                login: true,
                                register: false,
                            })
                        }
                    >
                        Logowanie
                    </ButtonTabSigning>
                    <ButtonTabSigning
                        isDisabled={
                            signingFormActive.code || signingFormActive.reset || isSigningLoading || isSigningChildrenLoading
                        }
                        isActive={
                            signingFormActive.register &&
                            !signingFormActive.code &&
                            !signingFormActive.reset
                        }
                        onTabClick={() =>
                            setSigningFormActive({
                                ...signingFormActive,
                                login: false,
                                register: true,
                            })
                        }
                    >
                        Rejestracja
                    </ButtonTabSigning>
                </SigningTabsContainer>
                {signingFormActive.login && (
                    <LoginForm
                        inputData={loginInputValue}
                        inputValidatorData={loginInputValidator}
                        inputUpdate={(e) =>
                            setLoginInputValue({ ...loginInputValue, ...e })
                        }
                        inputValidatorUpdate={(e) =>
                            setLoginInputValidator({
                                ...loginInputValidator,
                                ...e,
                            })
                        }
                        isSigningLoading={isSigningLoading}
                        setIsSigningLoading={setIsSigningLoading}
                    />
                )}
                {signingFormActive.code && (
                    <ResetCodeForm
                        inputData={resetCodeInputValue}
                        inputValidatorData={resetCodeInputValidator}
                        inputUpdate={(e) =>
                            setResetCodeInputValue({
                                ...resetCodeInputValue,
                                ...e,
                            })
                        }
                        inputValidatorUpdate={(e) =>
                            setResetCodeInputValidator({
                                ...resetCodeInputValidator,
                                ...e,
                            })
                        }
                        setSigningFormActive={setSigningFormActive}
                    />
                )}
                {signingFormActive.reset && !signingFormActive.code && (
                    <ResetForm codeData={resetCodeInputValue} />
                )}
                {signingFormActive.register &&
                    !signingFormActive.code &&
                    !signingFormActive.reset && (
                        <RegisterForm
                            inputData={registerInputValue}
                            inputValidatorData={registerInputValidator}
                            passwordData={registerPasswordValue}
                            passwordValidatorData={registerPasswordValidator}
                            passwordComparatorData={registerPasswordComparator}
                            roleData={registerRoleValue}
                            agreementData={registerAgreements}
                            agreementValidatorData={registerAgreementsValidator}
                            modalParentData={registerParentValue}
                            modalParentValidatorData={registerParentValidator}
                            inputUpdate={(e) =>
                                setRegisterInputValue({
                                    ...registerInputValue,
                                    ...e,
                                })
                            }
                            inputValidatorUpdate={(e) =>
                                setRegisterInputValidator({
                                    ...registerInputValidator,
                                    ...e,
                                })
                            }
                            passwordUpdate={(e) =>
                                setRegisterPasswordValue({
                                    ...registerPasswordValue,
                                    ...e,
                                })
                            }
                            passwordValidatorUpdate={(e) =>
                                setRegisterPasswordValidator(e)
                            }
                            passwordComparatorUpdate={(e) =>
                                setRegisterPasswordComparator(e)
                            }
                            roleUpdate={(e) => setRegisterRoleValue(e)}
                            agreementUpdate={(e) =>
                                setRegisterAgreements({
                                    ...registerAgreements,
                                    ...e,
                                })
                            }
                            agreementValidatorUpdate={(e) =>
                                setRegisterAgreementsValidator({ ...e })
                            }
                            modalParentUpdate={(e) =>
                                setRegisterParentValue({ ...e })
                            }
                            modalParentValidatorUpdate={(e) =>
                                setRegisterParentValidator({ ...e })
                            }
                            modalSetters={{
                                setModalData,
                                setModalActive,
                                setModalApiResponse,
                                setModalApiLoading,
                            }}
                            isSigningLoading={isSigningLoading}
                            setIsSigningLoading={setIsSigningLoading}
                            isSigningChildrenLoading={isSigningChildrenLoading}
                            setIsSigningChildrenLoading={setIsSigningChildrenLoading}
                        />
                    )}

                <ModalTransition
                    isActive={modalActive}
                    children={
                        <ModalAction
                            data={modalData.data}
                            response={modalApiResponse}
                            select={modalData.select}
                            isLoading={modalApiLoading}
                            isRefetching={setModalApiRefetching}
                            onFinish={() => {
                                if (modalApiLoading || modalApiResponse) {
                                    modalApiClear.current = true;
                                }

                                setModalActive(false);
                            }}
                        />
                    }
                    isLoading={modalApiLoading}
                    isRefetching={modalApiRefetching}
                    onExited={() => {
                        modalDataClear.current = false;

                        setModalData({});

                        if (modalApiClear.current) {
                            modalApiClear.current = false;

                            setModalApiResponse(false);
                            setModalApiLoading(false);
                        }
                    }}
                    onClose={
                        modalData.close
                            ? () => setModalActive(false)
                            : undefined
                    }
                />
            </div>
        </>
    );
};

export default SigningForm;
