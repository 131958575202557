import ShelfSwitch from "./ShelfSwitch";

const ShelfState = ({ states, stateSelected, stateDropdown, onClick }) => {
    const { dropdownActive, setDropdownActive } = stateDropdown;

    const toggleDropdown = () => {
        setDropdownActive(dropdownActive ? false : true);
    };
    return (
        <>
            <div className="ShelfState">
                <div className="ShelfState__Menu">
                    <button
                        className="ShelfState__Button"
                        type="button"
                        onClick={() => toggleDropdown()}
                    >
                        <span className="ShelfState__Icon"></span>
                        <span className="ShelfState__Icon"></span>
                        <span className="ShelfState__Icon"></span>
                    </button>
                    {dropdownActive ? (
                        <div className="ShelfState__Dropdown">
                            {
                                states.map((object, index) => {
                                    return <ShelfSwitch text={object.name} state={object.state} stateSelected={stateSelected} key={"shelfState_" + index} onClick={onClick}/>
                                })
                            }
                        </div>
                    ) : (
                        ""
                    )}
                </div>
            </div>
        </>
    );
};

export default ShelfState;
