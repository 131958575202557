import { useHistory } from "react-router-dom";
import { useState } from "react";

import Wrapper from "../containers/Wrapper";
import ButtonMenu from "../buttons/ButtonMenu";
import ButtonBackwards from "../buttons/ButtonBackwards";

const Title = ({ className, type, button, menu, favorite, children }) => {
    const history = useHistory();

    const [menuActive, setMenuActive] = useState(false);

    const HeadingClasses =
        "title-default" +
        (className ? " " + className : "") +
        ((type && className !== "medium") || (type && className !== "small")
            ? " h1"
            : "") +
        (button || menu?.length > 0 ? " action" : "");

    const HeadingType = ({ children }) => {
        switch (type) {
            case 1:
                return <h1 className={HeadingClasses}>{children}</h1>;
            case 2:
                return <h2 className={HeadingClasses}>{children}</h2>;
            case 3:
                return <h3 className={HeadingClasses}>{children}</h3>;
            case 4:
                return <h4 className={HeadingClasses}>{children}</h4>;
            default:
                return <h1 className={HeadingClasses}>{children}</h1>;
        }
    };

    return (
        <HeadingType>
            <Wrapper
                condition={button ? true : false}
                wrapper={(children) => (
                    <div className={"title-default-wrapper"}>{children}</div>
                )}
            >
                {button && (
                    <ButtonBackwards
                        className="_color--inverse"
                        onClickFn={() => history.goBack()}
                    />
                )}
                {menu?.length > 0 && (
                    <>
                        <button
                            className={
                                "btn-menu" + (menuActive ? " active" : "")
                            }
                            type="button"
                            onClick={
                                menuActive
                                    ? () => setMenuActive(false)
                                    : () => setMenuActive(true)
                            }
                        >
                            <span className="icon-wrapper">
                                <span className="icon"></span>
                                <span className="icon"></span>
                                <span className="icon"></span>
                            </span>
                        </button>
                        {menuActive && (
                            <div className="menu-dropdown">
                                {menu.map((object, index) => {
                                    return (
                                        <ButtonMenu
                                            key={"button-menu_" + index}
                                            action={object.action}
                                            isDisabled={object.isDisabled}
                                            onClickFn={object.onClickFn}
                                        >
                                            {object.text}
                                        </ButtonMenu>
                                    );
                                })}
                            </div>
                        )}
                    </>
                )}
                {favorite && !menu && (
                    <button
                        className="btn-favorite"
                        type="button"
                        onClick={favorite.onClickFn}
                    >
                        <span className="icon">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                width="21.18"
                                height="18.614"
                                viewBox="0 0 21.18 18.614"
                            >
                                <defs>
                                    <clipPath id="clip-path">
                                        <rect
                                            id="Rectangle_1375"
                                            dataname="Rectangle 1375"
                                            width="21.18"
                                            height="18.614"
                                            transform="translate(0 0)"
                                            fill="#fff"
                                        />
                                    </clipPath>
                                </defs>
                                <g
                                    id="Group_905"
                                    dataname="Group 905"
                                    transform="translate(0 0)"
                                >
                                    <g
                                        id="Group_890"
                                        dataname="Group 890"
                                        transform="translate(0 0)"
                                        clipPath="url(#clip-path)"
                                    >
                                        <path
                                            id="Path_1522"
                                            dataname="Path 1522"
                                            d="M15.736,3.938a6.687,6.687,0,0,1-1.179,3.6,22.554,22.554,0,0,1-5.819,5.81c-.869.643-.875.638-1.76-.006a24.732,24.732,0,0,1-5-4.652A8.016,8.016,0,0,1,.016,4.423,4.01,4.01,0,0,1,2.136.459a3.745,3.745,0,0,1,4.369.6,13.523,13.523,0,0,1,1.1,1.089c.2.218.314.2.506,0A15.208,15.208,0,0,1,9.322.978a3.61,3.61,0,0,1,4.15-.562,3.886,3.886,0,0,1,2.265,3.522"
                                            transform="translate(2.722 2.392)"
                                            fill="#fff"
                                        />
                                    </g>
                                </g>
                            </svg>
                        </span>
                        <span className="text">{favorite.text}</span>
                    </button>
                )}
                {children}
            </Wrapper>
        </HeadingType>
    );
};

export default Title;
