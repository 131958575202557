const StudentsListItem = ({
    id,
    name,
    surname,
    schoolClass,
    isDisabled,
    isSelected,
    onClickFn,
}) => {
    return (
        <>
            <button
                className={
                    "students-list-item" + (schoolClass ? '' : ' no-class') + (isSelected ? " selected" : "")
                }
                type="button"
                disabled={isDisabled}
                onClick={() => onClickFn(id)}
            >
                <span className="checker">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14.265"
                        height="12.648"
                        viewBox="0 0 14.265 12.648"
                    >
                        <path
                            id="Path_1518"
                            dataname="Path 1518"
                            d="M229.119,401.372l5.519,5.3,5.743-9.445"
                            transform="matrix(1, 0.017, -0.017, 1, -220.545, -399.771)"
                            fill="none"
                            stroke="#0c6ffa"
                            strokeLinecap="round"
                            strokeWidth="2"
                        />
                    </svg>
                </span>
                <span className="name">
                    {name} {surname}
                </span>
                {schoolClass && (
                    <span className="class">Klasa: {schoolClass}</span>
                )}
            </button>
        </>
    );
};

export default StudentsListItem;
