const parseMathTagConnection = (string) => {
    if (!string || typeof string !== "string") return "";

    const startTag = "[math]";
    const endTag = "[/math]";

    let newString = string;

    let startTagPosition = newString.indexOf(startTag);
    let endTagPosition = newString.indexOf(endTag);

    while (startTagPosition > -1 && endTagPosition > -1) {
        let isStartTagParsed = false;

        if (newString.charAt(startTagPosition - 1) !== " ") {
            newString =
                newString.slice(0, startTagPosition) +
                " " +
                newString.slice(startTagPosition);

            isStartTagParsed = true;
        }

        startTagPosition = newString.indexOf(startTag, ++startTagPosition);

        if (newString.charAt(endTagPosition + endTag.length) !== " ") {
            newString =
                newString.slice(
                    0,
                    endTagPosition + endTag.length + (isStartTagParsed ? 1 : 0)
                ) +
                " " +
                newString.slice(
                    endTagPosition + endTag.length + (isStartTagParsed ? 1 : 0)
                );
        }

        endTagPosition = newString.indexOf(endTag, ++endTagPosition);
    }

    return newString;
};

export default parseMathTagConnection;
