function polishCharsReplace(value) {
    const charset = {
        ą: "a",
        ć: "c",
        ę: "e",
        ł: "l",
        ń: "n",
        ó: "o",
        ś: "s",
        ź: "z",
        ż: "z",
    };

    for (const character in charset) {
        value = value.replace(new RegExp(character, "g"), charset[character]);
    }

    return value;
}

export default polishCharsReplace;