import downloadIcon from "../assets/images/components/download/download-icon.svg";

const ReaderDownload = ({ content }) => {
    return content ? (
        <div className="ReaderDownload">
            <a
                className="ReaderDownload__Link"
                href={content}
                download={true}
                target="_blank"
                rel="noreferrer"
                data-panel="false"
            >
                <span className="ReaderDownload__Icon">
                    <img src={downloadIcon} alt="" />
                </span>
                <span className="ReaderDownload__Text">Pobierz tutaj</span>
            </a>
        </div>
    ) : (
        ""
    );
};

export default ReaderDownload;
