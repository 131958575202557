import { useEffect, useRef, useContext } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useParams, useHistory, useLocation } from "react-router-dom";

import { StoreContext } from "../../../context/Store";

import routeLoadingState from "../../../functions/routeLoadingState";
import pathSlice from "../../../functions/pathSlice";

import Button from "../../../components/buttons/Button";

import DashboardContainer from "../../../components/containers/dashboard/DashboardContainer";
import DashboardGroup from "../../../components/containers/dashboard/DashboardGroup";

import modalDoodleImage_1 from "../../../assets/images/dashboard/modal-doodles/questionmark_2.svg";
import modalDoodleImage_2 from "../../../assets/images/dashboard/modal-doodles/sad_3.svg";

const SchoolGroupStudent = ({ routeMatches, routeSetters, modalSetters }) => {
    const { setRouteData, setRouteIsLoading } = routeSetters;
    const {
        setModalData,
        setModalActive,
        setModalApiResponse,
        setModalApiLoading,
    } = modalSetters;

    const history = useHistory();
    const params = useParams();
    const location = useLocation();

    const queryClient = useQueryClient();
    const { api } = useContext(StoreContext);

    /* PARAMS ID REF */
    const idStudentRef = useRef(params.idStudent);
    const idGroupRef = useRef(params.idGroup);

    /* GET STUDENT DATA */
    const {
        data: schoolGroupStudentData,
        isFetched: schoolGroupIsFetched,
        isLoading: schoolGroupStudentIsLoading,
    } = useQuery(
        ["schoolGroupStudent", idStudentRef.current],
        () =>
            api.post(`/teacher/get_student`, {
                idGroup: idGroupRef.current,
                idUser: idStudentRef.current,
            }),
        {
            staleTime: Infinity,
            retry: false,
            refetchOnWindowFocus: false,
        }
    );

    /* DELETE STUDENT FROM GROUP */
    const { mutate: deleteStudentMutate } = useMutation(
        () =>
            api.post(`/teacher/delete_student`, {
                idGroup: idGroupRef.current,
                idUser: idStudentRef.current,
            }),
        {
            onMutate: () => setModalApiLoading(true),
            onSuccess: () => {
                queryClient.invalidateQueries([
                    "schoolGroup",
                    idGroupRef.current,
                ]);

                setModalApiResponse(true);
            },
            onError: () => {
                alert("Error");
            },
        }
    );

    /* ROUTE LOADING */
    const routeIsLoading = routeLoadingState({
        fetched: [schoolGroupIsFetched],
        loading: [schoolGroupStudentIsLoading],
    });

    /* ROUTE SETTERS */
    useEffect(() => {
        const routeData = {
            title: routeIsLoading
                ? ""
                : schoolGroupStudentData.data.name +
                  " " +
                  schoolGroupStudentData.data.surname,
        };

        if (routeMatches) {
            setRouteIsLoading(routeIsLoading);
            setRouteData(routeData);
        }
    }, [
        routeIsLoading,
        routeMatches,
        setRouteIsLoading,
        setRouteData,
        schoolGroupStudentData?.data,
    ]);

    /* MODAL DATA */
    const modalData = useRef({});

    useEffect(() => {
        if (!routeIsLoading) {
            modalData.current = {
                data: [
                    {
                        title: {
                            obj: (
                                <p>
                                    Czy na pewno chcesz usunąć
                                    <br />
                                    ucznia{" "}
                                    {schoolGroupStudentData.data.name +
                                        " " +
                                        schoolGroupStudentData.data.surname}
                                </p>
                            ),
                        },
                        img: {
                            obj: modalDoodleImage_1,
                            size: "185px",
                        },
                        action: {
                            obj: [
                                {
                                    text: "Tak, chcę usunąć",
                                    important: true,
                                    onClick: () => deleteStudentMutate(),
                                },
                                {
                                    text: "Nie, nie chcę usuwać",
                                    onClick: () => setModalActive(false),
                                },
                            ],
                            key: "deleteStudent",
                        },
                    },
                    {
                        title: {
                            obj: (
                                <p>
                                    Uczeń{" "}
                                    {schoolGroupStudentData.data.name +
                                        " " +
                                        schoolGroupStudentData.data.surname}
                                    <br /> został usunięty
                                </p>
                            ),
                            isCenter: true,
                        },
                        img: {
                            obj: modalDoodleImage_2,
                            size: "219px",
                        },
                        action: {
                            obj: {
                                text: "Przejdź dalej",
                                onClick: () => history.goBack(),
                            },
                        },
                    },
                ],
            };
        }
    }, [
        routeIsLoading,
        setModalActive,
        deleteStudentMutate,
        schoolGroupStudentData?.data,
    ]);

    return (
        <>
            <DashboardContainer>
                {!routeIsLoading && (
                    <>
                        <DashboardGroup className="group-student">
                            <Button
                                className="creamy"
                                action="link"
                                onClickFn={() =>
                                    history.push(
                                        `${pathSlice(
                                            location.pathname
                                        )}/learning-progress`
                                    )
                                }
                            >
                                Postępy w nauce
                            </Button>
                            <Button
                                className="gray"
                                action="remove"
                                onClickFn={() =>
                                    setModalData(modalData.current)
                                }
                            >
                                Usuń ucznia z grupy
                            </Button>
                        </DashboardGroup>
                    </>
                )}
            </DashboardContainer>
        </>
    );
};

export default SchoolGroupStudent;
