import { Range } from "react-range";

const setThumbMarginLeft = (fontSize) => {
    switch (fontSize) {
        case 1:
            return "-0px";
        case 4:
            return "-11px";
        default:
            return "-5.5px";
    }
};

const ReaderSize = ({ color, themeColor, fontSize, setFontSize }) => {
    return (
        <div className="ReaderSize">
            <div className="ReaderSize__Title">Tekst</div>
            <div className="ReaderSize__Legend">
                <div className="ReaderSize__Description _font--small">aA</div>
                <div className="ReaderSize__Description _font--large">aA</div>
            </div>
            <Range
                step={1}
                min={1}
                max={4}
                values={[fontSize]}
                onChange={(values) => setFontSize(...values)}
                renderTrack={({ props, children }) => (
                    <div
                        {...props}
                        style={{
                            ...props.style,
                            height: "3px",
                            width: "100%",
                            backgroundColor:
                                themeColor === "dark"
                                    ? "#dbd7d4"
                                    : color === "secondary"
                                    ? "#ADE5C3"
                                    : "#D9E9FF",
                            borderRadius: "20px",
                            transition: "background .3s ease"
                        }}
                    >
                        {children}
                    </div>
                )}
                renderThumb={({ props }) => (
                    <div
                        {...props}
                        style={{
                            ...props.style,
                            height: "33px",
                            width: "33px",
                            backgroundColor: "transparent",
                            borderRadius: "50%",
                        }}
                    >
                        <div
                            style={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                height: "11px",
                                width: "11px",
                                backgroundColor:
                                    color === "secondary"
                                        ? "#3DAF6B"
                                        : "#0C6FFA",
                                borderRadius: "50%",
                                marginTop: "-5.5px",
                                marginLeft: setThumbMarginLeft(fontSize),
                            }}
                        />
                    </div>
                )}
            />
        </div>
    );
};

export default ReaderSize;
