import { useContext } from "react";

import { SettingsContext } from "../../context/SettingsStore";

import useSettingsUpdate from "../../hooks/useSettingsUpdate";

import ReaderTheme from "./ReaderTheme";
import ReaderSize from "./ReaderSize";
import ReaderFont from "./ReaderFont";

const ReaderSettings = ({ onRequestClose, isGallup, isGibs }) => {
    const {
        themeColor,
        setThemeColor,
        fontSize,
        setFontSize,
        fontFamily,
        setFontFamily,
    } = useContext(SettingsContext);

    useSettingsUpdate({
        themeColor,
        fontSize,
        fontFamily,
    });

    return (
        <>
            <div className="ReaderSettings">
                <button
                    className="ReaderSettings__Close"
                    onClick={onRequestClose}
                ></button>
                <ReaderTheme
                    color={isGallup || isGibs ? "secondary" : ""}
                    themeColor={themeColor}
                    setThemeColor={setThemeColor}
                />
                <ReaderSize
                    color={isGallup || isGibs ? "secondary" : ""}
                    themeColor={themeColor}
                    fontSize={fontSize}
                    setFontSize={setFontSize}
                />
                <ReaderFont
                    color={isGallup || isGibs ? "secondary" : ""}
                    fontFamily={fontFamily}
                    setFontFamily={setFontFamily}
                />
            </div>
        </>
    );
};

export default ReaderSettings;
