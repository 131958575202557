import { useState, createContext } from "react";

export const FlashcardContext = createContext();

const FlashcardProvider = ({ children }) => {
    const [flashElement, setFlashElement] = useState(null);
    const [flashIndex, setFlashIndex] = useState(10);

    return (
        <FlashcardContext.Provider
            value={{ flashElement, setFlashElement, flashIndex, setFlashIndex }}
        >
            {children}
        </FlashcardContext.Provider>
    );
};

export default FlashcardProvider;
