import { useEffect } from "react";
import { useParams } from "react-router-dom";

import useLearningProgressDetails from "../../../hooks/useLearningProgressDetails";

import routeLoadingState from "../../../functions/routeLoadingState";

import Frame from "../../../components/containers/dashboard/Frame";

import LearningCard from "../../../components/containers/dashboard/learning-progress/LearningCard";
import LearningPreview from "../../../components/containers/dashboard/learning-progress/LearningPreview";

const ChildrenLearningProgressDetails = ({ routeMatches, routeSetters }) => {
    const { setRouteData, setRouteIsLoading } = routeSetters;

    const params = useParams();

    const {
        learningDetailsData,
        isLearningDetailsFetched,
        isLearningDetailsRefetching,
        isLearningDetailsLoading,
        removeLearningDetails,
    } = useLearningProgressDetails(params?.idStudent, params?.idMultibook);

    /* ROUTE LOADING */
    const isRouteLoading = routeLoadingState({
        fetched: [isLearningDetailsFetched],
        loading: [isLearningDetailsRefetching],
        refetching: [isLearningDetailsLoading],
    });

    /* ROUTE SETTERS */
    useEffect(() => {
        const routeData = {
            title: "SZCZEGÓŁY POSTĘPÓW NAUKI",
        };

        if (routeMatches) {
            setRouteIsLoading(isRouteLoading);
            setRouteData(routeData);
        }
    }, [routeMatches, setRouteData, isRouteLoading, setRouteIsLoading]);

    /* REMOVE LEARNING DETAILS DATA ON UNMOUNT */
    useEffect(() => {
        return () => {
            removeLearningDetails();
        };
    }, [removeLearningDetails]);

    return (
        <>
            <Frame className="_bg--creamy" width={370}>
                {!isRouteLoading && (
                    <>
                        <LearningCard
                            type={learningDetailsData.data[0].multibook.type}
                            title={learningDetailsData.data[0].multibook.title}
                            content={
                                learningDetailsData.data[0].multibook.subtitle
                            }
                            thumbnail={
                                learningDetailsData.data[0].multibook.cover
                            }
                            progressBar={{
                                chapter: {
                                    isDone:
                                        learningDetailsData.data[0].multibook
                                            .progress >=
                                        learningDetailsData.data[0].multibook
                                            .total,
                                    caption:
                                        "Rozdział " +
                                        learningDetailsData.data[0].multibook
                                            .progress +
                                        " z " +
                                        learningDetailsData.data[0].multibook
                                            .total,
                                    percent:
                                        (learningDetailsData.data[0].multibook
                                            .progress *
                                            100) /
                                        learningDetailsData.data[0].multibook
                                            .total,
                                },
                            }}
                            isDetailed={true}
                        />
                        {learningDetailsData.data[0].chapters.length > 0 && (
                            <LearningPreview
                                data={learningDetailsData.data[0].chapters}
                            />
                        )}
                    </>
                )}
            </Frame>
        </>
    );
};

export default ChildrenLearningProgressDetails;
