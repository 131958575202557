const LibraryFilters = ({
    filters,
    filtersRefetching,
    filterCurrent,
    onClickFn,
}) => {
    return (
        <>
            <div className="LibraryFilters">
                {filters?.map((object) => (
                    <div className="LibraryFilters__Item" key={object.name}>
                        <button
                            className={
                                "LibraryFilters__Button" +
                                (filterCurrent === object.filter
                                    ? " _active"
                                    : "")
                            }
                            type="button"
                            disabled={
                                filterCurrent === object.filter ||
                                filtersRefetching
                            }
                            onClick={() => {
                                onClickFn(object.filter);
                            }}
                        >
                            {object.name}
                        </button>
                    </div>
                ))}
            </div>
        </>
    );
};

export default LibraryFilters;
