import { useState, useRef, useEffect, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { StoreContext } from "../../../context/Store";

import useActionLoading from "../../../hooks/useActionLoading";

import validator from "../../../functions/validator";
import routeLoadingState from "../../../functions/routeLoadingState";

import Input from "../../../components/inputs/Input";
import InputMasked from "../../../components/inputs/InputMasked";
import Button from "../../../components/buttons/Button";

import DashboardContainer from "../../../components/containers/dashboard/DashboardContainer";
import AccountData from "../../../components/containers/dashboard/account/AccountData";

const ChildrenAccountEdit = ({ routeMatches, routeSetters }) => {
    const { setRouteData, setRouteIsLoading } = routeSetters;

    const { isActionLoading, setIsActionLoading } = useActionLoading();

    const history = useHistory();
    const params = useParams();

    const queryClient = useQueryClient();
    const { api } = useContext(StoreContext);

    /* PARAMS ID REF */
    const idChildRef = useRef(params.idChild);

    /* GET CHILD DATA */
    const {
        data: childData,
        isFetched: childIsFetched,
        isLoading: childIsLoading,
    } = useQuery(
        ["childData", idChildRef.current],
        () => api.post(`/account/child_data`, { idChild: idChildRef.current }),
        {
            staleTime: Infinity,
            retry: false,
            refetchOnWindowFocus: false,
        }
    );

    /* EDIT CHILD DATA */
    const { mutate: childDataEditMutate } = useMutation(
        () =>
            api.post(`/account/child_data/edit`, {
                idChild: idChildRef.current,
                ...inputValue,
            }),
        {
            onMutate: () => setIsActionLoading(true),
            onSuccess: () => {
                queryClient.invalidateQueries([
                    "childData",
                    idChildRef.current,
                ]);
                history.goBack();
            },
            onError: () => setIsActionLoading(false),
        }
    );

    /* BIRTHMAXDATE CURRENT - 1 */
    const birthMaxDate = new Date(
        new Date().setFullYear(new Date().getFullYear() - 1)
    ).getFullYear();

    /* INPUT VALUES */
    const [inputValue, setInputValue] = useState({
        name: "",
        surname: "",
        email: "",
        phone: "",
        zipCode: "",
        birthdate: "",
        class: "",
    });

    /* INPUT VALIDATORS */
    const [inputValidator, setInputValidator] = useState({
        name: {
            response: "success",
        },
        surname: {
            response: "success",
        },
        email: {
            response: "success",
        },
        phone: {
            response: "success",
        },
        zipCode: {
            response: "success",
        },
        birthdate: {
            response: "success",
        },
    });

    /* INPUT ON CHANGE HANDLER */
    const inputOnChangeHandler = (e, key, minLength) => {
        setInputValue({ ...inputValue, [key]: e });

        if (minLength) {
            if (minLength <= e.length) {
                setInputValidator({
                    ...inputValidator,
                    [key]: {
                        response: "success",
                        errorMessage: "",
                    },
                });
            } else {
                setInputValidator({
                    ...inputValidator,
                    [key]: {
                        response: "error",
                        errorMessage: "",
                    },
                });
            }
        } else {
            setInputValidator({
                ...inputValidator,
                [key]: {
                    response: "success",
                    errorMessage: "",
                },
            });
        }
    };

    /* INPUT MASKED ON CHANGE HANDLER */
    const inputMaskedOnChangeHandler = (e, key) => {
        setInputValue({ ...inputValue, [key]: e.value });

        setInputValidator({
            ...inputValidator,
            [key]: {
                response: e.response,
                errorMessage: "",
            },
        });
    };

    /* E-MAIL ON CHANGE HANDLER */
    const emailOnChangeHandler = (e) => {
        if (e.length > 0) {
            validator("email", e)
                ? setInputValidator({
                      ...inputValidator,
                      email: {
                          response: "success",
                      },
                  })
                : setInputValidator({
                      ...inputValidator,
                      email: {
                          response: "error",
                      },
                  });
        } else {
            setInputValidator({
                ...inputValidator,
                email: {
                    response: "error",
                },
            });
        }

        setInputValue({ ...inputValue, email: e });
    };

    /* SET CHILDREN ACCOUNT DATA */
    useEffect(() => {
        if (childData) {
            setInputValue({
                name: childData.data.child.name,
                surname: childData.data.child.surname,
                email: childData.data.child.email,
                phone: childData.data.child.phone,
                zipCode: childData.data.child.zipCode,
                birthdate: childData.data.child.birthdate,
                class: childData.data.child.className,
            });
        }
    }, [childData]);

    /* ENABLE SUBMIT IF VALIDATION IS CORRECT */
    const formSubmitDisable =
        inputValue.name &&
        inputValidator.name.response === "success" &&
        inputValue.surname &&
        inputValidator.surname.response === "success" &&
        inputValue.email &&
        inputValidator.email.response === "success" &&
        inputValue.phone &&
        inputValidator.phone.response === "success" &&
        inputValue.zipCode &&
        inputValidator.zipCode.response === "success" &&
        inputValue.birthdate &&
        inputValidator.birthdate.response === "success"
            ? false
            : true;

    /* ROUTE LOADING */
    const routeIsLoading = routeLoadingState({
        fetched: [childIsFetched],
        loading: [childIsLoading],
    });

    /* ROUTE SETTERS */
    useEffect(() => {
        const routeData = {
            title: "EDYTUJ DANE DZIECKA",
        };

        if (routeMatches) {
            setRouteIsLoading(routeIsLoading);
            setRouteData(routeData);
        }
    }, [routeIsLoading, routeMatches, setRouteIsLoading, setRouteData]);

    return (
        <>
            <DashboardContainer className="account">
                {" "}
                {!routeIsLoading && (
                    <>
                        <AccountData
                            data={[
                                {
                                    title: "Imię",
                                    children: (
                                        <Input
                                            type="text"
                                            value={inputValue.name}
                                            classNameControl="thin font-left"
                                            isDisabled={isActionLoading}
                                            isValid={{
                                                response:
                                                    inputValidator.name
                                                        .response,
                                            }}
                                            onChangeFn={(e) =>
                                                inputOnChangeHandler(
                                                    e.currentTarget.value,
                                                    "name",
                                                    3
                                                )
                                            }
                                        />
                                    ),
                                },
                                {
                                    title: "Nazwisko",
                                    children: (
                                        <Input
                                            type="text"
                                            value={inputValue.surname}
                                            classNameControl="thin font-left"
                                            isDisabled={isActionLoading}
                                            isValid={{
                                                response:
                                                    inputValidator.surname
                                                        .response,
                                            }}
                                            onChangeFn={(e) =>
                                                inputOnChangeHandler(
                                                    e.currentTarget.value,
                                                    "surname",
                                                    3
                                                )
                                            }
                                        />
                                    ),
                                },
                                {
                                    title: "E-mail",
                                    children: (
                                        <Input
                                            type="email"
                                            value={inputValue.email}
                                            classNameControl="thin font-left"
                                            isDisabled={isActionLoading}
                                            isValid={{
                                                response:
                                                    inputValidator.email
                                                        .response,
                                            }}
                                            onChangeFn={(e) =>
                                                emailOnChangeHandler(
                                                    e.currentTarget.value
                                                )
                                            }
                                        />
                                    ),
                                },
                                {
                                    title: "Telefon",
                                    children: (
                                        <Input
                                            type="tel"
                                            value={inputValue.phone}
                                            maxLength={12}
                                            classNameControl="thin font-left"
                                            isDisabled={isActionLoading}
                                            isValid={{
                                                response:
                                                    inputValidator.phone
                                                        .response,
                                            }}
                                            onChangeFn={(e) =>
                                                inputOnChangeHandler(
                                                    e.currentTarget.value,
                                                    "phone",
                                                    9
                                                )
                                            }
                                        />
                                    ),
                                },
                                {
                                    title: "Kod pocztowy",
                                    children: (
                                        <InputMasked
                                            key="InputMasked"
                                            classNameControl="thin font-left"
                                            value={inputValue.zipCode}
                                            placeholder="wpisz kod pocztowy"
                                            placeholderFocus="00-000"
                                            fields={[{ rows: 2 }, { rows: 3 }]}
                                            separator="-"
                                            isDisabled={isActionLoading}
                                            isValid={
                                                inputValidator.zipCode.response
                                            }
                                            onChangeFn={(e) =>
                                                inputMaskedOnChangeHandler(
                                                    e,
                                                    "zipCode"
                                                )
                                            }
                                        />
                                    ),
                                },
                                {
                                    title: "Data urodzenia",
                                    children: (
                                        <InputMasked
                                            key="InputMasked"
                                            classNameControl="thin font-left"
                                            value={inputValue.birthdate}
                                            placeholder="dodaj datę urodzenia"
                                            placeholderFocus="dd-mm-rrrr"
                                            fields={[
                                                { rows: 2, min: 1, max: 31 },
                                                { rows: 2, min: 1, max: 12 },
                                                {
                                                    rows: 4,
                                                    min: 1900,
                                                    max: birthMaxDate,
                                                },
                                            ]}
                                            separator="-"
                                            isDisabled={isActionLoading}
                                            isValid={
                                                inputValidator.birthdate
                                                    .response
                                            }
                                            onChangeFn={(e) =>
                                                inputMaskedOnChangeHandler(
                                                    e,
                                                    "birthdate"
                                                )
                                            }
                                        />
                                    ),
                                },
                                {
                                    title: "Klasa w szkole",
                                    children: (
                                        <Input
                                            type="text"
                                            placeholder="wpisz klasę"
                                            placeholderFocus="1A"
                                            value={inputValue.class}
                                            maxLength={5}
                                            classNameControl="thin font-left"
                                            isDisabled={isActionLoading}
                                            isValid={{
                                                response:
                                                    inputValue.class.length > 0
                                                        ? "success"
                                                        : "",
                                            }}
                                            onChangeFn={(e) =>
                                                setInputValue({
                                                    ...inputValue,
                                                    class: e.currentTarget
                                                        .value,
                                                })
                                            }
                                        />
                                    ),
                                },
                            ]}
                            dataKey={"childrenDataEdit_" + idChildRef.current}
                        />
                        <div className="AccountData__ButtonGroup">
                            <Button
                                className="medium-2 disabled-alt"
                                isLoading={isActionLoading}
                                isDisabled={
                                    formSubmitDisable || isActionLoading
                                }
                                onClickFn={() => childDataEditMutate()}
                            >
                                Zapisz
                            </Button>
                            <Button
                                className="medium-2 gray disabled-alt"
                                isDisabled={isActionLoading}
                                onClickFn={() => history.goBack()}
                            >
                                Anuluj
                            </Button>
                        </div>
                    </>
                )}
            </DashboardContainer>
        </>
    );
};

export default ChildrenAccountEdit;
