import { views } from "./utils/constants";
import Miesiąc from "./Month";
import Dzień from "./Day";
import Tydzień from "./Week";
import WorkWeek from "./WorkWeek";
import Agenda from "./Agenda";

const VIEWS = {
  [views.MONTH]: Miesiąc,
  [views.WEEK]: Tydzień,
  [views.WORK_WEEK]: WorkWeek,
  [views.DAY]: Dzień,
  [views.AGENDA]: Agenda,
};

export default VIEWS;
