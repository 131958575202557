import ChapterIcon from "../../icons/ChapterIcon";
import StyleIcon from "../../icons/StyleIcon";

const getMenuIcon = (string) => {
    switch (string) {
        case "chapter":
            return <ChapterIcon />;
        case "style":
            return <StyleIcon />;
        default:
            return undefined;
    }
};

const ReaderMenu = ({ data }) => {
    return (
        <>
            {data ? (
                <div className="ReaderMenu">
                    {data.map((object, index) => {
                        return (
                            <div
                                className="ReaderMenu__Item"
                                key={"ReaderMenu_" + index}
                            >
                                <button
                                    ref={
                                        object.refObject?.ref
                                            ? (e) =>
                                                  e
                                                      ? (object.refObject.ref.current[
                                                            index
                                                        ] = {
                                                            [object.refObject
                                                                .keyName]: e,
                                                        })
                                                      : undefined
                                            : undefined
                                    }
                                    className="ReaderMenu__Button"
                                    type="button"
                                    onClick={
                                        object.onClick
                                            ? object.onClick
                                            : undefined
                                    }
                                >
                                    <span className="ReaderMenu__Icon">
                                        {getMenuIcon(object.icon)}
                                    </span>
                                    <span className="ReaderMenu__Text">
                                        {object.text}
                                    </span>
                                </button>
                            </div>
                        );
                    })}
                </div>
            ) : (
                ""
            )}
        </>
    );
};

export default ReaderMenu;
