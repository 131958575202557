import PropTypes from "prop-types";
import React from "react";

const Header = ({ label }) => {
  const returnTitle = (label) => {
    switch (label) {
      case "poniedziałek":
        return "P";
      case "wtorek":
        return "W";
      case "środa":
        return "Ś";
      case "czwartek":
        return "C";
      case "piątek":
        return "P";
      case "sobota":
        return "S";
      case "niedziela":
        return "N";

      default:
        return label;
    }
  };

  return (
    <span role="columnheader" aria-sort="none">
      {returnTitle(label)}
    </span>
  );
};

Header.propTypes = {
  label: PropTypes.node,
};

export default Header;
