import {useEffect, useCallback} from 'react';

const GroupProjects = ({routeMatches, routeSetters}) => {
    const { setRouteData } = routeSetters;

    /* ROUTE SETTERS */
    useEffect(() => {
        const routeData = {
            title: "PROJEKTY GRUPOWE",
        };

        if (routeMatches) {
            setRouteData(routeData);
        }
    }, [routeMatches, setRouteData]);

    return (
        <>
            <p className="text-center" style={{marginTop: 40}}>Projekty grupowe w trakcie budowy...</p>
        </>
    )
}

export default GroupProjects;