import { useState, useEffect, useRef, useContext, useCallback } from "react";
import { useQuery } from "react-query";

import { StoreContext } from "../../../context/Store";
import { useCookies } from "react-cookie";

import useLogout from "../../../hooks/useLogout";

import Loader from "../Loader";

import userAvatar1 from "../../../assets/images/dashboard/user-avatar/avatar-1.svg";
import userAvatar2 from "../../../assets/images/dashboard/user-avatar/avatar-2.svg";

import modalDoodleImage_1 from "../../../assets/images/dashboard/modal-doodles/questionmark_4.svg";
import modalDoodleImage_2 from "../../../assets/images/dashboard/modal-doodles/sad_2.svg";

const Topbar = ({ modalSetters }) => {
    const {
        // setModalData,
        setModalActive,
        setModalApiResponse,
        setModalApiLoading,
    } = modalSetters;

    const { api } = useContext(StoreContext);

    const [cookies] = useCookies(["idRank"]);

    const logout = useLogout();

    /* GET ACCOUNT DATA */
    const {
        data: accountData,
        isFetched: accountIsFetched,
        isRefetching: accountIsRefetching,
        isLoading: accountIsLoading,
    } = useQuery("account", () => api.get(`/account`), {
        staleTime: Infinity,
        retry: false,
        refetchOnWindowFocus: false,
    });

    /* TOPBAR LOADING STATE */
    const [topbarIsLoading, setTopbarIsLoading] = useState(true);

    /* COMPONENT LOADING */
    const componentIsLoading = accountIsLoading ? true : false;

    /* COMPONENT LOADING STATE */
    useEffect(() => {
        setTopbarIsLoading(componentIsLoading);
    }, [componentIsLoading]);

    /* USER DATA */
    const userDataFirstAssign = useRef(false);
    const userData = useRef({
        name: "",
        surname: "",
    });

    /* USER REFETCH */
    const [userDataRefetch, setUserDataRefetch] = useState(false);
    const userDataRefetchingFlag = useRef(false);
    const userDataRefetchTimeout = useRef(null);

    /* USER DATA FIRST ASSIGN */
    useEffect(() => {
        if (!accountIsLoading && !userDataFirstAssign.current) {
            userData.current = {
                name: accountData.data.user.name,
                surname: accountData.data.user.surname,
            };

            userDataFirstAssign.current = true;
        }
    }, [accountIsLoading, accountData]);

    /* COMPARE IF DATA CHANGED AND SHOW LOADER */
    useEffect(() => {
        if (accountIsRefetching) {
            userDataRefetchingFlag.current = true;
        } else if (userDataRefetchingFlag.current) {
            userDataRefetchingFlag.current = false;

            if (
                userData.current.name !== accountData.data.user.name ||
                userData.current.surname !== accountData.data.user.surname
            ) {
                userData.current = {
                    name: accountData.data.user.name,
                    surname: accountData.data.user.surname,
                };

                setUserDataRefetch(true);
            }
        }
    }, [accountIsRefetching, accountData]);

    /* HIDE LOADER AFTER TIME */
    useEffect(() => {
        if (userDataRefetch) {
            clearTimeout(userDataRefetchTimeout.current);

            userDataRefetchTimeout.current = setTimeout(() => {
                setUserDataRefetch(false);
            }, 1000);
        }
    }, [userDataRefetch]);

    /* AVATAR REFETCH */
    const [avatarIsChanging, setAvatarIsChanging] = useState(false);
    const initialAvatarIsSet = useRef(false);
    const initialAvatar = useRef(null);
    const avatarRefetchingFlag = useRef(false);

    /* SET INITIAL AVATAR */
    useEffect(() => {
        if (!accountIsLoading && !initialAvatarIsSet.current) {
            initialAvatar.current = accountData.data.user.avatar;

            initialAvatarIsSet.current = true;
        }
    }, [accountIsLoading, accountData]);

    /* COMPARE IF AVATAR CHANGED AND SHOW LOADER */
    useEffect(() => {
        if (accountIsFetched && accountIsRefetching) {
            avatarRefetchingFlag.current = true;
        } else if (avatarRefetchingFlag.current) {
            avatarRefetchingFlag.current = false;

            if (initialAvatar.current !== accountData.data.user.avatar) {
                initialAvatar.current = accountData.data.user.avatar;

                setAvatarIsChanging(true);
            }
        }
    }, [accountIsFetched, accountIsRefetching, accountData]);

    /* HIDE LOADER AFTER TIME */
    useEffect(() => {
        if (avatarIsChanging) {
            setTimeout(() => {
                setAvatarIsChanging(false);
            }, 1000);
        }
    }, [avatarIsChanging]);

    const getAccountAvatar = useCallback(() => {
        const idRank = parseInt(cookies.idRank);
        const avatarIsLoading = accountIsLoading || accountIsRefetching;

        switch (idRank) {
            case 2:
                return userAvatar1;
            case 3:
                return !avatarIsLoading
                    ? accountData.data.user.avatar
                    : undefined;
            case 4:
                return userAvatar2;
            default:
                return userAvatar1;
        }
    }, [cookies, accountIsLoading, accountData, accountIsRefetching]);

    /* MODAL LOGOUT DATA */
    const modalLogoutData = useRef({});

    useEffect(() => {
        modalLogoutData.current = {
            data: [
                {
                    title: {
                        obj: (
                            <p>
                                Czy na pewno
                                <br />
                                chcesz się wylogować?
                            </p>
                        ),
                    },
                    img: {
                        obj: modalDoodleImage_1,
                        size: "185px",
                    },
                    action: {
                        obj: [
                            {
                                text: "Tak, chcę",
                                important: true,
                                onClick: () => {
                                    setModalApiLoading(true);
                                    setModalApiResponse(true);
                                },
                            },
                            {
                                text: "Nie, nie chcę",
                                onClick: () => setModalActive(false),
                            },
                        ],
                        key: "userLogoutStart",
                    },
                },
                {
                    title: {
                        obj: (
                            <p>
                                Za chwilę zostaniesz
                                <br />
                                wylogowany/na z aplikacji
                            </p>
                        ),
                        isCenter: true,
                    },
                    img: {
                        obj: modalDoodleImage_2,
                        size: "185px",
                    },
                    action: {
                        obj: {
                            text: "Wyloguj natychmiast",
                            onClick: () => logout("/"),
                        },
                        auto: true,
                        autoTimeout: 5000,
                    },
                },
            ],
        };
    }, [setModalActive, setModalApiLoading, setModalApiResponse, logout]);

    return (
        <>
            <div className={"Topbar" + (topbarIsLoading ? "" : " _loaded")}>
                <div className="Topbar__Wrapper">
                    <div className="Topbar__Left">
                        <div
                            className={
                                "Topbar__Avatar" +
                                (avatarIsChanging ? " _loading" : "")
                            }
                            style={{
                                backgroundImage:
                                    "url(" + getAccountAvatar() + ")",
                            }}
                        >
                            {avatarIsChanging ? (
                                <Loader
                                    className="_color--default _size--small"
                                    type="ring"
                                />
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                    <div className="Topbar__Center">
                        <p className="Topbar__User">
                            {!componentIsLoading &&
                                (userDataRefetch ? (
                                    <Loader
                                        className="_color--default"
                                        type="ellipsis"
                                    />
                                ) : (
                                    userData.current.name +
                                    " " +
                                    userData.current.surname
                                ))}
                        </p>
                    </div>
                    <div className="Topbar__Right">
                        {/* BUTTON PLACEHOLDER */}
                        <div style={{ width: "20px", height: "1px" }} />
                        {/* <button
                            className="Topbar__Logout"
                            type="button"
                            onClick={() =>
                                setModalData(modalLogoutData.current)
                            }
                        >
                            <svg
                                className="Topbar__LogoutSvg"
                                xmlns="http://www.w3.org/2000/svg"
                                width="16.419"
                                height="17"
                                viewBox="0 0 16.419 17"
                            >
                                <g
                                    id="Settings"
                                    transform="translate(0.609 0.5)"
                                >
                                    <path
                                        id="settingsIconPath_1"
                                        dataname="settingsIconPath 1"
                                        d="M267.5,10a2,2,0,1,0-2-2A2.006,2.006,0,0,0,267.5,10Zm-4.1-6.4a5.64,5.64,0,0,1,2.4-1.3l.8-2.3h2l.8,2.3a6.983,6.983,0,0,1,2.4,1.3l2.4-.5,1,1.8-1.6,1.8a5.7,5.7,0,0,1,.1,1.3c0,.4-.1.9-.1,1.3l1.6,1.8-1,1.8-2.4-.5a5.64,5.64,0,0,1-2.4,1.3l-.8,2.3h-2l-.8-2.3a6.983,6.983,0,0,1-2.4-1.3l-2.4.5-1-1.8,1.6-1.8a5.7,5.7,0,0,1-.1-1.3c0-.4.1-.9.1-1.3L260,4.9l1-1.8Z"
                                        transform="translate(-260)"
                                        fill="none"
                                        stroke="#2e2a26"
                                        strokeWidth="1"
                                    />
                                </g>
                            </svg>
                        </button> */}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Topbar;
