import { useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";

import useLearningProgressList from "../../../hooks/useLearningProgressList";

import routeLoadingState from "../../../functions/routeLoadingState";
import pathSlice from "../../../functions/pathSlice";

import Frame from "../../../components/containers/dashboard/Frame";
import LearningCard from "../../../components/containers/dashboard/learning-progress/LearningCard";
import LearningEmpty from "../../../components/containers/dashboard/learning-progress/LearningEmpty";

const ChildrenLearningProgress = ({ routeMatches, routeSetters }) => {
    const { setRouteData, setRouteIsLoading } = routeSetters;

    const history = useHistory();
    const location = useLocation();
    const params = useParams();

    const {
        learningListData,
        isLearningListFetched,
        isLearningListRefetching,
        isLearningListLoading,
        removeLearningList,
    } = useLearningProgressList(params.idChild);

    /* ROUTE LOADING */
    const isRouteLoading = routeLoadingState({
        fetched: [isLearningListFetched],
        loading: [isLearningListRefetching],
        refetching: [isLearningListLoading],
    });

    /* ROUTE SETTERS */
    useEffect(() => {
        const routeData = {
            title: "POSTĘPY NAUKI",
        };

        if (routeMatches) {
            setRouteIsLoading(isRouteLoading);
            setRouteData(routeData);
        }
    }, [routeMatches, setRouteData, isRouteLoading, setRouteIsLoading]);

    /* REMOVE LEARNING LIST DATA ON UNMOUNT */
    useEffect(() => {
        return () => {
            removeLearningList();
        };
    }, [removeLearningList]);

    return (
        <>
            <Frame className="_bg--creamy" width={370}>
                {!isRouteLoading && learningListData.data.length > 0 ? (
                    learningListData.data.map((element) => (
                        <LearningCard
                            key={"LearningCard_" + element.idMultibook}
                            type={element.type}
                            title={element.title}
                            content={element.subtitle}
                            thumbnail={element.cover}
                            progressBar={{
                                chapter: {
                                    isDone: element.progress >= element.total,
                                    caption:
                                        "Rozdział " +
                                        element.progress +
                                        " z " +
                                        element.total,
                                    percent:
                                        (element.progress * 100) /
                                        element.total,
                                },
                                test: {
                                    caption:
                                        "Średnia z testów: " +
                                        element.avr +
                                        "%",
                                    percent: element.avr,
                                },
                            }}
                            onClick={() =>
                                history.push(
                                    `${pathSlice(location.pathname)}/details-${
                                        element.idMultibook
                                    }`
                                )
                            }
                        />
                    ))
                ) : (
                    <LearningEmpty
                        text="Brak postępów nauki."
                        onClick={() => history.goBack()}
                    />
                )}
            </Frame>
        </>
    );
};

export default ChildrenLearningProgress;
