import { useContext } from "react";
import { useQuery } from "react-query";

import { StoreContext } from "../../../context/Store";
import getLoadingState from "../functions/getLoadingState";

const useGetExamResult = (idChapter) => {
    const { api } = useContext(StoreContext);

    const {
        data: examResultData,
        refetch: examResultRefetch,
        isFetched: isExamResultFetched,
        isLoading: isExamResultLoading,
        remove: examResultRemove,
    } = useQuery(
        ["examResult", idChapter],
        () => api.post("/exams/result", { idChapter }),
        {
            enabled: false,
            staleTime: Infinity,
            retry: false,
            refetchOnWindowFocus: false,
        }
    );

    const isHookLoading = idChapter
        ? getLoadingState([isExamResultFetched], [isExamResultLoading])
        : true;

    return {
        examResultData: examResultData?.data,
        examResultRefetch,
        examResultRemove,
        isExamResultLoading: isHookLoading,
    };
};

export default useGetExamResult;
