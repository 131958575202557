const addChildErrorHandler = (statusCode, responseError, onEmailTaken) => {
    switch (statusCode) {
        case 400:
            switch (responseError.email) {
                case "EMAIL_TAKEN":
                    onEmailTaken();
                    return;
                default:
                    alert("*Placeholder* Add child - failure");
                    return;
            }

        default:
            alert("*Placeholder* Add child - failure");
    }
};

export default addChildErrorHandler;
