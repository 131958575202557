import React from "react";

import getFlashcards from "../functions/flashcard/getFlashcards";

import ReaderFact from "../components/ReaderFact";
import ReaderQuestion from "../components/ReaderQuestion";
import ReaderImportant from "../components/ReaderImportant";
import ReaderAnswer from "../components/ReaderAnswer";
import ReaderText from "../components/ReaderText";
import ReaderImage from "../components/ReaderImage";
import ReaderNote from "../components/ReaderNote";
import ReaderVideo from "../components/ReaderVideo";
import ReaderAudio from "../components/ReaderAudio";
import ReaderChoice from "../components/ReaderChoice";
import ReaderSlider from "../components/ReaderSlider";
import ReaderOrder from "../components/ReaderOrder";
import ReaderMood from "../components/ReaderMood";
import ReaderAnimation from "../components/ReaderAnimation";
import ReaderMath from "../components/ReaderMath";
import ReaderPoints from "../components/ReaderPoints";
import ReaderDownload from "../components/ReaderDownload";

const componentKey = (object) => {
    return (
        "chapter-" + object.idChapter + "_content-" + object.idChapterContent
    );
};

const getLastComponent = (content) => {
    if (!content) return "";

    const pureContent = content.filter((object) => object.type !== "flashcard");

    return pureContent[pureContent.length - 1];
};

const ComponentsClone = ({
    children,
    examContent,
    answersSolved,
    userAvatar,
    setExamProgress,
    isLoading,
    isExam,
    isGallup,
    isGibs,
    isAnswered,
}) => {
    const examComponentsFilter =
        children?.length > 0
            ? children.filter((element) => element !== "")
            : [];
    const examComponentsMap =
        examComponentsFilter.length > 0
            ? examComponentsFilter.map((element) =>
                  React.cloneElement(
                      element,
                      isExam
                          ? {
                                examContent,
                                answersSolved,
                                userAvatar,
                                setExamProgress,
                                isLoading,
                                isExam: true,
                                isGallup,
                                isGibs,
                                isAnswered,
                            }
                          : {
                                isLoading,
                            }
                  )
              )
            : [];

    return examComponentsMap;
};

const ReaderComponents = ({
    content,
    examContent,
    answersSolved,
    note,
    userAvatar,
    setExamProgress,
    isLoading,
    isExam,
    isGallup,
    isGibs,
    isAnswered,
}) => {
    const flashcards = getFlashcards(content);

    return (
        <>
            <ComponentsClone
                examContent={examContent}
                answersSolved={answersSolved}
                userAvatar={userAvatar}
                setExamProgress={setExamProgress}
                isLoading={isLoading}
                isExam={isExam}
                isGallup={isGallup}
                isGibs={isGibs}
                isAnswered={isAnswered}
            >
                {content?.length > 0 &&
                    content.map((object) => {
                        if (object.type === "text") {
                            return (
                                <ReaderText
                                    key={componentKey(object)}
                                    content={object.content}
                                    flash={{
                                        data: flashcards,
                                        key: componentKey(object),
                                        props: {
                                            isGallup,
                                            isGibs,
                                        },
                                    }}
                                    isLast={
                                        getLastComponent(content)
                                            .idChapterContent ===
                                        object.idChapterContent
                                    }
                                />
                            );
                        } else if (object.type === "question") {
                            return (
                                <ReaderQuestion
                                    key={componentKey(object)}
                                    content={object.content}
                                    flash={{
                                        data: flashcards,
                                        key: componentKey(object),
                                        props: {
                                            isGallup,
                                            isGibs,
                                        },
                                    }}
                                />
                            );
                        } else if (object.type === "answer") {
                            return (
                                <ReaderAnswer
                                    key={componentKey(object)}
                                    ids={{
                                        idChapter: object.idChapter,
                                        idContent: object.idChapterContent,
                                    }}
                                    content={{
                                        question: object.content,
                                        answer: object.content2,
                                    }}
                                    flash={{
                                        data: flashcards,
                                        key: componentKey(object),
                                        props: {
                                            isGallup,
                                            isGibs,
                                        },
                                    }}
                                />
                            );
                        } else if (object.type === "important") {
                            return (
                                <ReaderImportant
                                    key={componentKey(object)}
                                    content={object.content}
                                    flash={{
                                        data: flashcards,
                                        key: componentKey(object),
                                        props: {
                                            isGallup,
                                            isGibs,
                                        },
                                    }}
                                />
                            );
                        } else if (object.type === "note") {
                            return (
                                <ReaderNote
                                    key={componentKey(object)}
                                    idContent={object.idChapterContent}
                                    title={object.content?.header}
                                    placeholder={object.content?.placeholder}
                                    content={object.content2}
                                    flash={{
                                        data: flashcards,
                                        key: componentKey(object),
                                        props: {
                                            isGallup,
                                            isGibs,
                                        },
                                    }}
                                    note={note}
                                />
                            );
                        } else if (object.type === "fact") {
                            return (
                                <ReaderFact
                                    key={componentKey(object)}
                                    content={object.content}
                                    flash={{
                                        data: flashcards,
                                        key: componentKey(object),
                                        props: {
                                            isGallup,
                                            isGibs,
                                        },
                                    }}
                                />
                            );
                        } else if (object.type === "image") {
                            return (
                                <ReaderImage
                                    key={componentKey(object)}
                                    url={object.content}
                                />
                            );
                        } else if (object.type === "video") {
                            return (
                                <ReaderVideo
                                    key={componentKey(object)}
                                    url={object.content}
                                    thumbnail={object.content2}
                                />
                            );
                        } else if (object.type === "audio") {
                            return (
                                <ReaderAudio
                                    key={componentKey(object)}
                                    url={object.content}
                                />
                            );
                        } else if (object.type === "question_choice") {
                            return (
                                <ReaderChoice
                                    key={componentKey(object)}
                                    ids={{
                                        idChapter: object.idChapter,
                                        idContent: object.idChapterContent,
                                    }}
                                    title={object.content.question}
                                    content={object.content.options}
                                    flash={{
                                        data: flashcards,
                                        key: componentKey(object),
                                        props: {
                                            isGallup,
                                            isGibs,
                                        },
                                    }}
                                />
                            );
                        } else if (object.type === "question_slider") {
                            return (
                                <ReaderSlider
                                    key={componentKey(object)}
                                    ids={{
                                        idChapter: object.idChapter,
                                        idContent: object.idChapterContent,
                                    }}
                                    title={object.content.question}
                                    avatar={
                                        isGallup ? object.content.avatar : ""
                                    }
                                    content={object.content.options}
                                    correct={object.content2}
                                    flash={{
                                        data: flashcards,
                                        key: componentKey(object),
                                        props: {
                                            isGallup,
                                            isGibs,
                                        },
                                    }}
                                />
                            );
                        } else if (object.type === "question_order") {
                            return (
                                <ReaderOrder
                                    key={componentKey(object)}
                                    ids={{
                                        idChapter: object.idChapter,
                                        idContent: object.idChapterContent,
                                    }}
                                    title={object.content3}
                                    content={object.content2}
                                    correct={object.content}
                                    flash={{
                                        data: flashcards,
                                        key: componentKey(object),
                                        props: {
                                            isGallup,
                                            isGibs,
                                        },
                                    }}
                                />
                            );
                        } else if (object.type === "question_mood") {
                            return (
                                <ReaderMood
                                    key={componentKey(object)}
                                    ids={{
                                        idChapter: object.idChapter,
                                        idContent: object.idChapterContent,
                                    }}
                                    title={object.content}
                                    content={object.content2}
                                />
                            );
                        } else if (object.type === "animation_component") {
                            return (
                                <ReaderAnimation
                                    key={componentKey(object)}
                                    content={object.content.content}
                                    isLooped={object.content2}
                                />
                            );
                        } else if (object.type === "math_equation") {
                            return (
                                <ReaderMath
                                    key={componentKey(object)}
                                    content={object.content}
                                />
                            );
                        } else if (object.type === "download_materials") {
                            return (
                                <ReaderDownload
                                    key={componentKey(object)}
                                    content={object.content}
                                />
                            );
                        } else if (object.type === "points") {
                            return (
                                <ReaderPoints
                                    key={componentKey(object)}
                                    category={object.content.category}
                                    title={object.content.title}
                                    color={isGallup ? "secondary" : ""}
                                    numbers={{
                                        current: object.content2.user_score,
                                        total: object.content2.total_score,
                                        percent:
                                            (object.content2.user_score * 100) /
                                            object.content2.total_score,
                                    }}
                                />
                            );
                        } else {
                            return "";
                        }
                    })}
            </ComponentsClone>
        </>
    );
};

export default ReaderComponents;
