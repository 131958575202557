import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import useAccount from "../../../hooks/useAccount";
import useLearningProgressList from "../../../hooks/useLearningProgressList";
import useCheckSubscription from "../../../hooks/useCheckSubscription";

import routeLoadingState from "../../../functions/routeLoadingState";
import pathSlice from "../../../functions/pathSlice";

import Frame from "../../../components/containers/dashboard/Frame";
import LearningCard from "../../../components/containers/dashboard/learning-progress/LearningCard";
import LearningEmpty from "../../../components/containers/dashboard/learning-progress/LearningEmpty";
import SubscriptionError from "../../../components/containers/dashboard/SubscriptionError";

const ChildrenLearningProgress = ({ routeMatches, routeSetters }) => {
    const { setRouteData, setRouteIsLoading } = routeSetters;

    const history = useHistory();
    const location = useLocation();

    const {
        accountData,
        isAccountFetched,
        isAccountRefetching,
        isAccountLoading,
    } = useAccount();
    const {
        learningListData,
        isLearningListFetched,
        isLearningListRefetching,
        isLearningListLoading,
        removeLearningList,
    } = useLearningProgressList(accountData?.data.user.idUser);
    const {
        isSubscriptionActive,
        isSubscriptionFetched,
        isSubscriptionLoading,
    } = useCheckSubscription();

    /* ROUTE LOADING */
    const isRouteLoading = routeLoadingState({
        fetched: [
            isAccountFetched,
            isLearningListFetched,
            isSubscriptionFetched,
        ],
        loading: [
            isAccountLoading,
            isLearningListRefetching,
            isSubscriptionLoading,
        ],
        refetching: [isAccountRefetching, isLearningListLoading],
    });

    /* ROUTE SETTERS */
    useEffect(() => {
        const routeData = {
            title: "POSTĘPY NAUKI",
        };

        if (routeMatches) {
            setRouteIsLoading(isRouteLoading);
            setRouteData(routeData);
        }
    }, [routeMatches, setRouteData, isRouteLoading, setRouteIsLoading]);

    /* REMOVE LEARNING LIST DATA ON UNMOUNT */
    useEffect(() => {
        return () => {
            removeLearningList();
        };
    }, [removeLearningList]);

    return (
        <>
            <Frame className="_bg--creamy" width={370}>
                {!isRouteLoading &&
                    (isSubscriptionActive ? (
                        learningListData.data.length > 0 ? (
                            learningListData.data.map((element) => (
                                <LearningCard
                                    key={"LearningCard_" + element.idMultibook}
                                    type={element.type}
                                    title={element.title}
                                    content={element.subtitle}
                                    thumbnail={element.cover}
                                    progressBar={{
                                        chapter: {
                                            isDone:
                                                element.progress >=
                                                element.total,
                                            caption:
                                                "Rozdział " +
                                                element.progress +
                                                " z " +
                                                element.total,
                                            percent:
                                                (element.progress * 100) /
                                                element.total,
                                        },
                                        test: {
                                            caption:
                                                "Średnia z testów: " +
                                                element.avr +
                                                "%",
                                            percent: element.avr,
                                        },
                                    }}
                                    onClick={() =>
                                        history.push(
                                            `${pathSlice(
                                                location.pathname
                                            )}/details-${element.idMultibook}`
                                        )
                                    }
                                />
                            ))
                        ) : (
                            <LearningEmpty
                                text="Brak postępów nauki."
                                onClick={() => history.goBack()}
                            />
                        )
                    ) : (
                        <SubscriptionError />
                    ))}
                {}
            </Frame>
        </>
    );
};

export default ChildrenLearningProgress;
