import { useContext } from "react";
import { useQuery } from "react-query";

import { StoreContext } from "../context/Store";

const useAccount = () => {
    const { api } = useContext(StoreContext);

    const {
        data: accountData,
        isFetched: isAccountFetched,
        isRefetching: isAccountRefetching,
        isLoading: isAccountLoading,
    } = useQuery("account", () => api.get(`/account`), {
        staleTime: Infinity,
        retry: false,
        refetchOnWindowFocus: false,
    });

    return {
        accountData,
        isAccountFetched,
        isAccountRefetching,
        isAccountLoading,
    };
};

export default useAccount;
