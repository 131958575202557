import doodle from "../assets/images/utils/doodle-quizify.svg";

const ReaderQuizify = ({ content }) => {
    return (
        <>
            <div className="ReaderQuizify">
                <div className="ReaderQuizify__Content">{content}</div>
                <div className="ReaderQuizify__Doodle">
                    <img src={doodle} alt="" />
                </div>
            </div>
        </>
    );
};

export default ReaderQuizify;
