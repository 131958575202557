import { createContext } from "react";

import useNotificationRead from "../hooks/useNotificationRead";

export const HooksContext = createContext();

const HooksProvider = ({ children }) => {
    const { readNotification, isNotificationReadSuccess } = useNotificationRead();

    return (
        <HooksContext.Provider value={{ readNotification, isNotificationReadSuccess }}>
            {children}
        </HooksContext.Provider>
    );
};

export default HooksProvider;
