import { useEffect, useRef, useContext } from "react";
import { useQuery, useQueryClient, useMutation } from "react-query";
import { useParams, useHistory, useLocation } from "react-router-dom";

import { StoreContext } from "../../../context/Store";

import pathSlice from "../../../functions/pathSlice";
import parseTagsFromString from "../../../functions/parseTagsFromString";
import routeLoadingState from "../../../functions/routeLoadingState";

import Title from "../../../components/headings/Title";
import Button from "../../../components/buttons/Button";

import DashboardContainer from "../../../components/containers/dashboard/DashboardContainer";
import DashboardGroup from "../../../components/containers/dashboard/DashboardGroup";
import SchoolTextbox from "../../../components/containers/dashboard/school-groups/SchoolTextbox";

import modalDoodleImage_1 from "../../../assets/images/dashboard/modal-doodles/questionmark_3.svg";
import modalDoodleImage_2 from "../../../assets/images/dashboard/modal-doodles/sad_2.svg";
import teacherDoodleImage from "../../../assets/images/dashboard/modal-doodles/teacherEmote.svg";
const ChildrenGroupsDetails = ({
  routeMatches,
  routeSetters,
  modalSetters,
}) => {
  const { api } = useContext(StoreContext);
  const { setRouteData, setRouteIsLoading } = routeSetters;
  const queryClient = useQueryClient();
  const {
    setModalData,
    setModalActive,
    setModalApiResponse,
    setModalApiLoading,
  } = modalSetters;

  const params = useParams();
  const history = useHistory();

  const {
    data: schoolGroupData,
    isFetched: schoolGroupIsFetched,
    isLoading: schoolGroupIsLoading,
    isRefetching: schoolGroupIsRefetching,
  } = useQuery(
    "groups-data",
    () =>
      api.post(`/student/groups/data`, {
        idGroup: params?.idGroup,
      }),

    {
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {},
      onError: (error) => {},
    }
  );

  const { mutate: deleteSchoolGroupMutate } = useMutation(
    () =>
      api.post(`/student/groups/leave`, {
        idGroup: params?.idGroup,
      }),
    {
      onMutate: () => setModalApiLoading(true),
      onSuccess: () => {
        queryClient.invalidateQueries(["groups", params.Group]);

        setModalApiResponse(true);
      },
    }
  );
  
  /* ROUTE LOADING */
  const routeIsLoading = routeLoadingState({
    fetched: [schoolGroupIsFetched],
    loading: [schoolGroupIsLoading],
    refetching: [schoolGroupIsRefetching],
  });

  /* ROUTE SETTERS */
  useEffect(() => {
    const routeData = {
      title: schoolGroupData?.data.group.name,
      action: {
        type: "dropdown",
        props: [
          {
            text: "Usuń grupę",
            icon: "remove",
            onClick: () => setModalData(modalData.current),
          },
        ],
      },
    };

    if (routeMatches) {
      setRouteIsLoading(routeIsLoading);
      setRouteData(routeData);
    }
  }, [
    routeMatches,
    routeIsLoading,
    setRouteIsLoading,
    setRouteData,
    schoolGroupData?.data.name,
    setModalData,
  ]);
  /* MODAL DATA */
  const modalData = useRef({});

  useEffect(() => {
    if (!routeIsLoading) {
      modalData.current = {
        data: [
          {
            title: {
              obj: (
                <p>
                  Czy na pewno chcesz usunąć
                  <br />
                  {schoolGroupData?.data.group.name}
                </p>
              ),
            },
            img: {
              obj: modalDoodleImage_1,
              size: "219px",
            },
            action: {
              obj: [
                {
                  text: "Tak, chcę usunąć",
                  important: true,
                  onClick: () => deleteSchoolGroupMutate(),
                },
                {
                  text: "Nie, nie chcę usuwać",
                  onClick: () => setModalActive(false),
                },
              ],
              key: "schoolGroupDelete",
            },
          },
          {
            title: {
              obj: (
                <p>
                  {schoolGroupData?.data.group.name}
                  <br />
                  została usunięta
                </p>
              ),
              isCenter: true,
            },
            img: {
              obj: modalDoodleImage_2,
              size: "219px",
            },
            action: {
              obj: {
                text: "Przejdź dalej",
                onClick: () => history.goBack(),
              },
            },
          },
        ],
      };
    }
  }, [routeIsLoading, setModalActive, schoolGroupData?.data.name]);

  return (
    <DashboardContainer>
      {!routeIsLoading && (
        <>
          <DashboardGroup className="group-details">
            <Title className="medium text-center" type={2}>
              OPIS
            </Title>
            <SchoolTextbox>
              {schoolGroupData.data.group.description}
            </SchoolTextbox>
          </DashboardGroup>
          <DashboardGroup className="group-tags">
            <Title className="medium text-center" type={2}>
              TAGI
            </Title>
            <div
              className="school-group-tags text-center"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              {schoolGroupData.data.tags.map((tags) => tags.name).join(", ")}
            </div>
          </DashboardGroup>
          <DashboardGroup>
            <Title className="medium text-center" type={2}>
              NAUCZYCIEL
            </Title>
            <Button
              key={"schoolGroupStudent_" + schoolGroupData?.data.teacher.name}
              className="creamy"
              thumbnail={teacherDoodleImage}
              //   onClickFn={() =>
              //     history.push(
              //       `${pathSlice(location.pathname)}/student-${
              //         object.idUser
              //       }`
              //     )
              //   }
            >
              {schoolGroupData?.data.teacher.name}{" "}
              {schoolGroupData?.data.teacher.surname}
            </Button>
          </DashboardGroup>
          <DashboardGroup className="group-students">
            <Title className="medium text-center" type={2}>
              UCZNIOWIE W GRUPIE
            </Title>
            {schoolGroupData?.data.students.length > 0 ? (
              schoolGroupData?.data.students?.map((object) => {
                return (
                  <Button
                    key={"schoolGroupStudent_" + object.idUser}
                    className="creamy"
                    thumbnail={object.avatar}
                  >
                    {object.name} {object.surname}
                  </Button>
                );
              })
            ) : (
              <SchoolTextbox className="text-center bold">
                Brak uczniów w grupie
              </SchoolTextbox>
            )}
          </DashboardGroup>
        </>
      )}
    </DashboardContainer>
  );
};
export default ChildrenGroupsDetails;
