const IconDone = () => {
    return (
        <svg
            className="Done__Svg"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="13"
            height="13"
            viewBox="0 0 13 13"
        >
            <defs>
                <linearGradient
                    id="linear-gradient"
                    x1="-0.141"
                    y1="0.134"
                    x2="1.236"
                    y2="0.824"
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset="0" stopColor="#9ac8c8" />
                    <stop offset="1" stopColor="#99efef" />
                </linearGradient>
            </defs>
            <g
                id="Group_200"
                dataname="Group 200"
                transform="translate(-131 -551)"
            >
                <g id="pole-zaznaczone" transform="translate(131 551)">
                    <circle
                        id="Ellipse_18"
                        dataname="Ellipse 18"
                        cx="6.5"
                        cy="6.5"
                        r="6.5"
                        fill="url(#linear-gradient)"
                    />
                    <path
                        id="Path_11"
                        dataname="Path 11"
                        d="M-75.029-2761.634l2.43,2.854,1.1-1.485,1.664-2.249"
                        transform="translate(78.933 2767.146)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeWidth="1"
                    />
                </g>
            </g>
        </svg>
    );
};

export default IconDone;
