import { useState, useRef, useEffect } from "react";

import parseMathTag from "../functions/parseMathTag";
import parseMathTagConnection from "../functions/parseMathTagConnection";

import ReaderLoader from "../utils/ReaderLoader";

const ReaderNote = ({
    idContent,
    title,
    placeholder,
    content,
    note,
    isExam,
    isGallup,
    isGibs,
}) => {
    const { saveNote, isNoteUpdating, setIsNoteUpdating } = note;

    const [value, setValue] = useState(content ? content : "");
    const lastValue = useRef(content ? content : "");
    const isNoteCurrent = useRef(true);

    const handleSaveNote = () => {
        if (lastValue.current !== value && !isNoteUpdating && !isExam) {
            isNoteCurrent.current = true;

            setIsNoteUpdating(true);

            saveNote({ idContent, text: value });

            lastValue.current = value;
        }
    };

    useEffect(() => {
        if (!isNoteUpdating && isNoteCurrent.current) {
            isNoteCurrent.current = false;
        }
    }, [isNoteUpdating]);

    return (
        <div
            className={
                "ReaderNote" +
                (title ? " _has--title" : "") +
                (isGallup || isGibs ? " _color--secondary" : "") +
                (isNoteUpdating ? " _is--disabled" : "")
            }
        >
            {title ? (
                <div className="ReaderNote__Title">
                    {parseMathTag(parseMathTagConnection(title))}:
                </div>
            ) : (
                ""
            )}
            <div className="ReaderNote__Box">
                <div className="ReaderNote__Edge"></div>
                <textarea
                    className="ReaderNote__Area"
                    placeholder={placeholder + (isExam ? "" : "")}
                    value={value}
                    data-panel="false"
                    disabled={isNoteUpdating}
                    onChange={(event) => setValue(event.currentTarget.value)}
                    onBlur={() => handleSaveNote()}
                />
                {isNoteUpdating && isNoteCurrent.current && (
                    <div className="ReaderNote__Loading">
                        <ReaderLoader />
                    </div>
                )}
            </div>
        </div>
    );
};

export default ReaderNote;
