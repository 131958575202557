import ReaderPager from "../utils/ReaderPager";
import ReaderComponents from "../utils/ReaderComponents";
import ReaderPage from "../utils/ReaderPage";

const ReaderBasic = ({
    title,
    pages,
    content,
    pager,
    note,
    cover,
    isPending,
    isLoading,
}) => {
    return (
        <>
            <ReaderPager
                pages={pages}
                pager={pager}
                cover={cover}
                isPending={isPending}
                isLoading={isLoading}
            />
            <ReaderPage title={title}>
                <div className="ReaderBasic">
                    <ReaderComponents
                        content={content}
                        note={note}
                        isLoading={isLoading || isPending}
                    />
                </div>
            </ReaderPage>
            <ReaderPager
                className="_sticky--bottom"
                pages={pages}
                pager={pager}
                cover={cover}
                isPending={isPending}
                isLoading={isLoading}
            />
        </>
    );
};

export default ReaderBasic;
