const LibraryCarouselHeading = ({ title, onClick }) => {
    return (
        <>
            <div className="LibraryCarousel__Heading">
                <div className="LibraryCarousel__Title">
                    <h2>{title}</h2>
                </div>
                <div className="LibraryCarousel__Link">
                    <button
                        className="LibraryCarousel__Button"
                        type="button"
                        onClick={onClick}
                    >
                        <span className="LibraryCarousel__ButtonText">
                            Zobacz
                            <br />
                            wszystko
                        </span>
                        <span className="LibraryCarousel__ButtonIcon">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="26"
                                height="26"
                                viewBox="0 0 26 26"
                                className="LibraryCarousel__ButtonSvg"
                            >
                                <g
                                    id="Group_904"
                                    dataname="Group 904"
                                    transform="translate(71 115.093) rotate(180)"
                                >
                                    <circle
                                        id="Ellipse_27"
                                        className="LibraryCarousel__ButtonCircle"
                                        dataname="Ellipse 27"
                                        cx="13"
                                        cy="13"
                                        r="13"
                                        transform="translate(45 89.093)"
                                        fill="#0c6ffa"
                                    />
                                    <path
                                        id="Path_50"
                                        className="LibraryCarousel__ButtonPath"
                                        dataname="Path 50"
                                        d="M0,0,5.853,5.853,0,11.7"
                                        transform="translate(60.386 107.988) rotate(180)"
                                        fill="none"
                                        stroke="#fff"
                                        strokeLinecap="round"
                                        strokeWidth="2"
                                    />
                                </g>
                            </svg>
                        </span>
                    </button>
                </div>
            </div>
        </>
    );
};

export default LibraryCarouselHeading;
