import { useContext, useState } from "react";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";

import { StoreContext } from "../../../context/Store";

import Title from "../../headings/Title";
import Input from "../../inputs/Input";
import Button from "../../buttons/Button";

import validator from "../../../functions/validator";

import SigningValidationContainer from "../../containers/signing/SigningValidationContainer";
import SigningFormContainer from "../../containers/signing/SigningFormContainer";
import SigningRulesContainer from "../../containers/signing/SigningRulesContainer";
import ButtonRuleSigning from "../../buttons/signing/ButtonRuleSigning";

import useLogin from "../../../hooks/useLogin";
import loginErrorHandler from "../../../functions/errorHandlers/loginErrorHandler";
import { getCookieTime } from "../../../App";

const LoginForm = ({
    inputData,
    inputValidatorData,
    inputUpdate,
    inputValidatorUpdate,
    isSigningLoading,
    setIsSigningLoading,
}) => {
    const history = useHistory();

    const login = useLogin();
    const { api } = useContext(StoreContext);

    const setCookie = useCookies(["schoolsEmail"])[1];
    const removeCookie = useCookies(["schoolsEmail"])[2];

    const { mutate: handleLogin } = useMutation(
        () =>
            api.post(`/login`, {
                email: inputData.email,
                password: inputData.password,
            }),
        {
            onMutate: () => setIsSigningLoading(true),
            onSuccess: (data) => {
                removeCookie("schoolsEmail", {
                    path: "/",
                });

                setTimeout(() => {
                    if (data.data.schools?.length > 0) {
                        setCookie("schoolsEmail", data.data.schools, {
                            path: "/",
                            expires: getCookieTime(),
                        });
                    }

                    login(
                        {
                            token: data.data.token,
                            idRank: data.data.rank.idRanks,
                            isAccountActive: data.data.active,
                            firstLogin: data.data.reset_required,
                        },
                        "/dashboard"
                    );
                }, 0);
            },
            onError: (error) => {
                setIsSigningLoading(false);

                loginErrorHandler(
                    error.response.status,
                    error.response.data.errors,
                    () => setLoginValidation(true)
                );
            },
        }
    );

    /* VALIDATION ERROR STATE */
    const [loginValidation, setLoginValidation] = useState(false);

    /* E-MAIL INPUT HANDLER */
    const emailOnChangeHandler = (e) => {
        /* E-MAIL VALIDATION */
        if (e.length > 0) {
            validator("email", e)
                ? inputValidatorUpdate({
                      email: "success",
                  })
                : inputValidatorUpdate({
                      email: "error",
                  });
        } else {
            inputValidatorUpdate({
                email: "",
            });
        }

        /* RESET LOGIN VALIDATION */
        setLoginValidation(false);

        /* E-MAIL VALUE SETTER */
        inputUpdate({ email: e });
    };

    /* PASSWORD INPUT HANDLER */
    const passwordOnChangeHandler = (e) => {
        /* PASSWORD VALIDATION */
        if (e.currentTarget.value.length === 0) {
            inputValidatorUpdate({
                password: "",
            });
        } else {
            if (
                e.currentTarget.value.length >= 8 &&
                e.currentTarget.value.length <= 50
            ) {
                inputValidatorUpdate({
                    password: "success",
                });
            } else {
                inputValidatorUpdate({
                    password: "error",
                });
            }
        }

        /* RESET LOGIN VALIDATION */
        setLoginValidation(false);

        /* PASSOWRD VALUE SETTER */
        inputUpdate({
            password: e.currentTarget.value,
        });
    };

    const onEnterKeyHandler = (e) => {
        const enterKey = e.charCode === 13 || e.keyCode === 13;

        if (!formSubmitDisable && enterKey) {
            e.currentTarget.blur();
            handleLogin();
        }
    };

    /* ENABLE SUBMIT IF VALIDATION IS CORRECT */
    const formSubmitDisable =
        inputValidatorData.email === "success" &&
        inputValidatorData.password === "success"
            ? false
            : true;

    return (
        <>
            <div className="app-signing-form">
                <Title className="text-center">Zaloguj się do How2 edu</Title>
                <SigningValidationContainer
                    isActive={loginValidation}
                    text="Nazwa użytkownika lub hasło są niepoprawne"
                >
                    <Button
                        onClickFn={() => history.push("/remind-password")}
                        className="blank small"
                    >
                        Zapomniałeś hasło?
                    </Button>
                </SigningValidationContainer>
                <SigningFormContainer classNameWrapper="signing">
                    <Input
                        key="Input"
                        type="email"
                        value={inputData.email}
                        placeholder="wpisz e-mail"
                        isDisabled={isSigningLoading}
                        isValid={{ response: inputValidatorData.email }}
                        onChangeFn={(e) =>
                            emailOnChangeHandler(e.currentTarget.value)
                        }
                        onKeyPressFn={(e) => onEnterKeyHandler(e)}
                    />
                    <Input
                        key="Input"
                        type="password"
                        value={inputData.password}
                        placeholder="wpisz hasło"
                        isDisabled={isSigningLoading}
                        isValid={{ response: inputValidatorData.password }}
                        onChangeFn={(e) => passwordOnChangeHandler(e)}
                        onKeyPressFn={(e) => onEnterKeyHandler(e)}
                    />
                    <Button
                        key="Button"
                        isLoading={isSigningLoading}
                        isDisabled={formSubmitDisable || isSigningLoading}
                        onClickFn={handleLogin}
                    >
                        Zaloguj się
                    </Button>
                    <Button
                        key="Button"
                        classNameWrapper="text-center"
                        className="blank"
                        isDisabled={isSigningLoading}
                        onClickFn={() => history.push("/remind-password")}
                    >
                        Nie pamiętasz hasła?
                    </Button>
                </SigningFormContainer>
                <SigningRulesContainer
                    text="Klikając „Zaloguj się” akceptujesz regulamin"
                    separator="i"
                >
                    <ButtonRuleSigning>How2 Edu</ButtonRuleSigning>
                    <ButtonRuleSigning>how2edu.app</ButtonRuleSigning>
                </SigningRulesContainer>
            </div>
        </>
    );
};

export default LoginForm;
