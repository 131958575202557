import { useEffect, useRef, useContext } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { StoreContext } from "../../../context/Store";

import routeLoadingState from "../../../functions/routeLoadingState";
import pathSlice from "../../../functions/pathSlice";
import pathPrev from "../../../functions/pathPrev";

import Button from "../../../components/buttons/Button";

import DashboardContainer from "../../../components/containers/dashboard/DashboardContainer";
import AccountData from "../../../components/containers/dashboard/account/AccountData";

import modalDoodleImage_1 from "../../../assets/images/dashboard/modal-doodles/faint.svg";
import modalDoodleImage_2 from "../../../assets/images/dashboard/modal-doodles/sad.svg";

const ChildrenAccount = ({ routeMatches, routeSetters, modalSetters }) => {
    const { setRouteData, setRouteIsLoading } = routeSetters;
    const {
        setModalData,
        setModalActive,
        setModalApiResponse,
        setModalApiLoading,
    } = modalSetters;

    const params = useParams();
    const history = useHistory();
    const location = useLocation();

    const queryClient = useQueryClient();
    const { api } = useContext(StoreContext);

    /* PARAMS ID REF */
    const idChildRef = useRef(params.idChild);

    /* GET CHILDREN */
    const {
        data: childData,
        isFetched: childIsFetched,
        isRefetching: childIsRefetching,
        isLoading: childIsLoading,
    } = useQuery(
        ["childData", idChildRef.current],
        () => api.post(`/account/child_data`, { idChild: idChildRef.current }),
        {
            staleTime: Infinity,
            retry: false,
            refetchOnWindowFocus: false,
        }
    );

    /* DELETE CHILDREN ACCOUNT */
    const { mutate: childDeleteAccountMutate } = useMutation(
        () =>
            api.post(`/account/child/remove_account`, {
                idChild: idChildRef.current,
            }),
        {
            onMutate: () => setModalApiLoading(true),
            onSuccess: () => {
                queryClient.invalidateQueries("childList");

                setModalApiResponse(true);
            },
            onError: () => alert("*Placeholder* Error with sending data"),
        }
    );

    /* ROUTE LOADING */
    const routeIsLoading = routeLoadingState({
        fetched: [childIsFetched],
        loading: [childIsLoading],
        refetching: [childIsRefetching],
    });

    /* ROUTE SETTERS */
    useEffect(() => {
        const routeData = {
            title: "KONTO DZIECKA",
        };

        if (routeMatches) {
            setRouteIsLoading(routeIsLoading);
            setRouteData(routeData);
        }
    }, [routeIsLoading, routeMatches, setRouteIsLoading, setRouteData]);

    /* MODAL DATA */
    const modalData = useRef({});

    useEffect(() => {
        if (!routeIsLoading) {
            modalData.current = {
                data: [
                    {
                        title: {
                            obj: (
                                <p>
                                    Jeśli usuniesz konto dziecka{" "}
                                    {childData.data.child.name} straci dostęp do
                                    wszystkich materiałów oraz utraci dane
                                    zapisane w aplikacji
                                </p>
                            ),
                        },
                        img: {
                            obj: modalDoodleImage_1,
                            size: "187px",
                        },
                        action: {
                            obj: [
                                {
                                    text: "Rozumiem i chcę usunąć konto",
                                    important: true,
                                    onClick: childDeleteAccountMutate,
                                },
                                {
                                    text: "O nie! Zostaję w How2 Edu",
                                    onClick: () => setModalActive(false),
                                },
                            ],
                            key: "childrenAccountDelete",
                        },
                    },
                    {
                        title: {
                            obj: (
                                <p>
                                    Konto dziecka {childData.data.child.name}
                                    <br />
                                    zostało usunięte!
                                </p>
                            ),
                            isCenter: true,
                        },
                        img: {
                            obj: modalDoodleImage_2,
                            size: "184px",
                        },
                        action: {
                            obj: {
                                text: "Przejdź dalej",
                                onClick: () =>
                                    history.push(
                                        `${pathPrev(location.pathname, 2)}`
                                    ),
                            },
                        },
                    },
                ],
            };
        }
    }, [
        routeIsLoading,
        setModalActive,
        setModalApiLoading,
        setModalApiResponse,
        childData,
        childDeleteAccountMutate,
    ]);

    return (
        <>
            <DashboardContainer className="account">
                {" "}
                {!routeIsLoading && (
                    <>
                        <AccountData
                            data={[
                                {
                                    title: "Imię",
                                    text: childData.data.child.name,
                                    button: {
                                        text: "Edytuj dane",
                                        onClickFn: () =>
                                            history.push(
                                                `${pathSlice(
                                                    location.pathname
                                                )}/edit-account`
                                            ),
                                    },
                                },
                                {
                                    title: "Nazwisko",
                                    text: childData.data.child.surname,
                                },
                                {
                                    title: "E-mail",
                                    text: childData.data.child.email,
                                },
                                {
                                    title: "Telefon",
                                    text: childData.data.child.phone,
                                },
                                {
                                    title: "Kod pocztowy",
                                    text: childData.data.child.zipCode,
                                },
                                {
                                    title: "Data urodzenia",
                                    text: childData.data.child.birthdate,
                                },
                                {
                                    title: "Klasa w szkole",
                                    text: childData.data.child.className,
                                },
                            ]}
                            dataKey={"childrenData_" + idChildRef.current}
                        />
                        <Button
                            classNameWrapper="AccountData__Delete"
                            className="blank thin"
                            onClickFn={() => setModalData(modalData.current)}
                        >
                            Usuń konto
                        </Button>
                    </>
                )}
            </DashboardContainer>
        </>
    );
};

export default ChildrenAccount;
