import { useState, useEffect, useRef, useContext } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "react-query";

import { StoreContext } from "../../../context/Store";

import pathSlice from "../../../functions/pathSlice";
import routeLoadingState from "../../../functions/routeLoadingState";
import getSchoolName from "../../../functions/getSchoolName";

import RouteAccess from "../../../components/route/RouteAccess";

import Title from "../../../components/headings/Title";
import Button from "../../../components/buttons/Button";
import TextSmall from "../../../components/containers/TextSmall";

import DashboardContainer from "../../../components/containers/dashboard/DashboardContainer";
import DashboardGroup from "../../../components/containers/dashboard/DashboardGroup";
import SchoolTextbox from "../../../components/containers/dashboard/school-groups/SchoolTextbox";

import modalDoodleImage_1 from "../../../assets/images/dashboard/modal-doodles/questionmark.svg";
import modalDoodleImage_2 from "../../../assets/images/dashboard/modal-doodles/sad.svg";

const SchoolGroups = ({ routeMatches, routeSetters, modalSetters }) => {
    const { setRouteData, setRouteIsLoading } = routeSetters;
    const {
        setModalData,
        setModalActive,
        setModalApiResponse,
        setModalApiLoading,
    } = modalSetters;

    const history = useHistory();
    const location = useLocation();
    const params = useParams();

    const queryClient = useQueryClient();
    const { api } = useContext(StoreContext);

    /* PARAMS ID REF */
    const idSchoolRef = useRef(params.idSchool);

    /* NO ACCESS TO SCHOOL GROUP */
    const [schoolGroupNoAccess, setSchoolGroupNoAccess] = useState(false);

    /* GET TEACHER SCHOOLS */
    const {
        data: teacherSchoolsData,
        isFetched: teacherSchoolsIsFetched,
        isLoading: teacherSchoolsIsLoading,
        isRefetching: teacherSchoolsIsRefetching,
    } = useQuery(
        ["teacherSchools", "schoolsGroup"],
        () =>
            api.get(`/teacher/schools`, {
                idSchool: idSchoolRef.current,
            }),
        {
            staleTime: Infinity,
            retry: false,
            refetchOnWindowFocus: false,
        }
    );

    /* GET SCHOOL GROUPS */
    const {
        data: teacherSchoolGroupsData,
        isFetched: teacherSchoolGroupsIsFetched,
        isLoading: teacherSchoolGroupsIsLoading,
        isRefetching: teacherSchoolGroupsIsRefetching,
    } = useQuery(
        ["schoolGroups", idSchoolRef.current],
        () => api.get(`/teacher/groups?idSchool=${idSchoolRef.current}`),
        {
            staleTime: Infinity,
            retry: false,
            refetchOnWindowFocus: false,
            onError: () => setSchoolGroupNoAccess(true),
        }
    );

    /* DELETE SCHOOL MUTATE */
    const { mutate: deleteSchoolMutate } = useMutation(
        () =>
            api.post(`/teacher/delete_school`, {
                idSchool: idSchoolRef.current,
            }),
        {
            onMutate: () => setModalApiLoading(true),
            onSuccess: () => {
                queryClient.invalidateQueries(["teacherSchools", "schools"]);

                setModalApiResponse(true);
            },
            onError: () => alert("*Placeholder* Error with sending data"),
        }
    );

    /* ROUTE LOADING */
    const routeIsLoading = routeLoadingState({
        fetched: [teacherSchoolsIsFetched, teacherSchoolGroupsIsFetched],
        loading: [teacherSchoolsIsLoading, teacherSchoolGroupsIsLoading],
        refetching: [
            teacherSchoolsIsRefetching,
            teacherSchoolGroupsIsRefetching,
        ],
    });

    /* ROUTE SETTERS */
    useEffect(() => {
        const routeData = {
            title: "SZKOŁA - MOJE GRUPY",
            action: {
                type: "dropdown",
                key: "schoolGroups",
                props: [
                    {
                        text: "Utwórz nową grupę",
                        icon: "add",
                        disabled: schoolGroupNoAccess,
                        onClick: () =>
                            history.push(
                                `${pathSlice(location.pathname)}/add-group`
                            ),
                    },
                    {
                        text: "Usuń szkołę",
                        icon: "remove",
                        disabled: schoolGroupNoAccess,
                        onClick: () => setModalData(modalData.current),
                    },
                ],
            },
        };

        if (routeMatches) {
            setRouteIsLoading(routeIsLoading);
            setRouteData(routeData);
        }
    }, [
        routeIsLoading,
        routeMatches,
        setRouteIsLoading,
        setRouteData,
        setModalData,
        schoolGroupNoAccess
    ]);

    /* MODAL DATA */
    const modalData = useRef({});

    useEffect(() => {
        if (!routeIsLoading) {
            modalData.current = {
                data: [
                    {
                        title: {
                            obj: (
                                <p>
                                    Czy na pewno chcesz usunąć szkołę
                                    <br />
                                    {getSchoolName(
                                        teacherSchoolsData.data,
                                        idSchoolRef.current
                                    )}
                                </p>
                            ),
                        },
                        img: {
                            obj: modalDoodleImage_1,
                            size: "161px",
                        },
                        action: {
                            obj: [
                                {
                                    text: "Tak, chcę usunąć",
                                    important: true,
                                    onClick: () => deleteSchoolMutate(),
                                },
                                {
                                    text: "Nie, nie chcę usuwać",
                                    onClick: () => setModalActive(false),
                                },
                            ],
                            key: "schoolDelete",
                        },
                    },
                    {
                        title: {
                            obj: (
                                <p>
                                    {getSchoolName(
                                        teacherSchoolsData.data,
                                        idSchoolRef.current
                                    )}
                                    <br />
                                    została usunięta
                                </p>
                            ),
                            isCenter: true,
                        },
                        img: {
                            obj: modalDoodleImage_2,
                            size: "184px",
                        },
                        action: {
                            obj: {
                                text: "Przejdź dalej",
                                onClick: () => history.goBack(),
                            },
                        },
                    },
                ],
            };
        }
    }, [
        routeIsLoading,
        setModalActive,
        teacherSchoolsData?.data,
        deleteSchoolMutate,
    ]);

    return (
        <>
            {schoolGroupNoAccess ? (
                <RouteAccess
                    caption={
                        <p>
                            Brak dostępu
                            <br />
                            do wybranej szkoły
                        </p>
                    }
                    button={{
                        text: "Powrót do listy szkół",
                        onClick: () => history.goBack(),
                    }}
                />
            ) : (
                <DashboardContainer className="school-groups">
                    <>
                        {!routeIsLoading && (
                            <>
                                <SchoolTextbox className="text-center bold">
                                    {getSchoolName(
                                        teacherSchoolsData.data,
                                        idSchoolRef.current
                                    )}
                                </SchoolTextbox>
                                <DashboardGroup>
                                    <Title className="medium text-center">
                                        MOJE GRUPY
                                    </Title>
                                    <DashboardGroup className="school-groups">
                                        {teacherSchoolGroupsData?.data.length >
                                        0 ? (
                                            teacherSchoolGroupsData?.data.map(
                                                (object) => {
                                                    return (
                                                        <Button
                                                            key={
                                                                "schoolGroup_" +
                                                                object.idGroup
                                                            }
                                                            className="creamy hover-red"
                                                            action="link"
                                                            onClickFn={() =>
                                                                history.push(
                                                                    `${pathSlice(
                                                                        location.pathname
                                                                    )}/group-${
                                                                        object.idGroup
                                                                    }`
                                                                )
                                                            }
                                                        >
                                                            {object.name}
                                                        </Button>
                                                    );
                                                }
                                            )
                                        ) : (
                                            <TextSmall className="text-center">
                                                Brak grup do wyświetlenia.
                                            </TextSmall>
                                        )}
                                    </DashboardGroup>
                                </DashboardGroup>
                            </>
                        )}
                    </>
                </DashboardContainer>
            )}
        </>
    );
};

export default SchoolGroups;
