import InputSelect from "../../inputs/InputSelect";

const Repeater = ({
    heading,
    selectedData,
    query,
    placeholder,
    max,
    modalLarge,
    onChange,
    modalSetters,
    isDisabled
}) => {
    /* ADD ITEMS */
    const addRepeaterElements = (newObject, currentObject) => {
        const repeaterIncludes = currentObject
            ? selectedData.some(
                  (element) =>
                      element[query.dataKey.id] ===
                      currentObject[query.dataKey.id]
              )
            : false;

        if (!repeaterIncludes) {
            onChange([...selectedData, newObject]);
        } else {
            const repeaterElements = selectedData.map((element) =>
                element[query.dataKey.id] === currentObject[query.dataKey.id]
                    ? newObject
                    : element
            );

            onChange(repeaterElements);
        }
    };

    /* REMOVE ITEMS */
    const removeRepeaterElement = (object) => {
        const repeaterElements = selectedData.filter(
            (element) => element[query.dataKey.id] !== object[query.dataKey.id]
        );

        onChange(repeaterElements);
    };

    return (
        <>
            <div className="Repeater">
                <div className="Repeater__Heading">
                    <p className="Repeater__Title">{heading?.title}</p>
                    <p className="Repeater__SubTitle">{heading?.subtitle}</p>
                </div>
                <div className="Repeater__Group">
                    {selectedData.map((object) => {
                        return (
                            <div
                                className="Repeater__Item"
                                key={
                                    object[query.dataKey.name] +
                                    "_" +
                                    object[query.dataKey.id]
                                }
                            >
                                <InputSelect
                                    value={{
                                        [query.dataKey.id]:
                                            object[query.dataKey.id],
                                        [query.dataKey.name]:
                                            object[query.dataKey.name],
                                    }}
                                    query={query}
                                    placeholder={placeholder.initial}
                                    modalLarge={modalLarge}
                                    isDisabled={isDisabled}
                                    onChange={(data) =>
                                        addRepeaterElements(data, {
                                            [query.dataKey.id]:
                                                object[query.dataKey.id],
                                            [query.dataKey.name]:
                                                object[query.dataKey.name],
                                        })
                                    }
                                    onClear={(data) =>
                                        removeRepeaterElement(data)
                                    }
                                    modalSetters={modalSetters}
                                />
                            </div>
                        );
                    })}
                </div>
                {!max || max > selectedData.length ? (
                    <div className="Repeater__Initial">
                        <InputSelect
                            query={query}
                            placeholder={placeholder.initial}
                            modalLarge={modalLarge}
                            isDisabled={isDisabled}
                            onChange={(data) => addRepeaterElements(data)}
                            onClear={(data) => removeRepeaterElement(data)}
                            modalSetters={modalSetters}
                        />
                    </div>
                ) : (
                    ""
                )}
            </div>
        </>
    );
};

export default Repeater;
