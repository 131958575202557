import { useState, useRef, useEffect } from "react";

const LibrarySearch = ({
    debounce,
    searchSaved,
    searchDisabled,
    resetSearch,
    onChangeFn,
    onSearchFn,
}) => {
    /* SEARCH INIT FLAG */
    const searchInitFlag = useRef(true);

    /* SEARCH DEFAULTS */
    const [searchValue, setSearchValue] = useState(searchSaved ?? "");
    const searchDebounce = useRef(null);
    const searchDebounceFlag = useRef(true);

    /* SEARCH PROGRESS BAR */
    const searchProgressInterval = useRef(null);
    const searchProgressFadeTimeout = useRef(null);
    const searchProgressResetTimeout = useRef(null);

    const [searchProgress, setSearchProgress] = useState(false);
    const searchBarRef = useRef(null);
    const searchProgressValue = useRef(0);
    const searchProgressSteps = debounce
        ? 100 / ((debounce - 200) / 100)
        : 100 / ((1500 - 200) / 100);

    /* SEARCH RESET */
    useEffect(() => {
        if (resetSearch) {
            setSearchProgress(false);

            searchDebounceFlag.current = true;

            setSearchValue("");
        }
    }, [resetSearch]);

    /* SEARCH HANDLER */
    useEffect(() => {
        if (searchInitFlag.current) return;

        if (typeof onChangeFn === "function") {
            onChangeFn(searchValue);
        }

        clearTimeout(searchDebounce.current);
        clearInterval(searchProgressInterval.current);

        if (!searchDebounceFlag.current && !searchDisabled) {
            setSearchProgress(true);

            searchProgressValue.current = 0;
            searchBarRef.current.style.width = 0;

            searchProgressInterval.current = setInterval(() => {
                if (searchProgressValue.current < 100) {
                    searchProgressValue.current += searchProgressSteps;

                    searchBarRef.current.style.width =
                        searchProgressValue.current + "%";
                } else {
                    clearInterval(searchProgressInterval.current);

                    finishSearchProgress();
                }
            }, 100);

            searchDebounce.current = setTimeout(
                () => {
                    onSearchFn();
                },
                debounce ? debounce : 1500
            );
        } else if (searchValue.length > 1) {
            searchDebounceFlag.current = false;
        }
    }, [
        searchValue,
        debounce,
        searchProgressSteps,
        searchDisabled,
        onChangeFn,
        onSearchFn,
    ]);

    /* ENTER KEY HANDLER */
    const onEnterKeyHandler = (e) => {
        const enterKey = e.charCode === 13 || e.keyCode === 13;

        if (enterKey) {
            clearTimeout(searchDebounce.current);
            clearInterval(searchProgressInterval.current);

            if (!searchDisabled) {
                finishSearchProgress();

                onSearchFn();
            }
        }
    };

    /* SEARCH BUTTON HANDLER */
    const searchButtonHandler = () => {
        clearTimeout(searchDebounce.current);
        clearInterval(searchProgressInterval.current);

        if (!searchDisabled) {
            finishSearchProgress();

            onSearchFn();
        }
    };

    /* FINISH SEARCH PROGRESS */
    const finishSearchProgress = () => {
        searchBarRef.current.style.width = "100%";

        clearTimeout(searchProgressFadeTimeout.current);
        clearTimeout(searchProgressResetTimeout.current);

        searchProgressFadeTimeout.current = setTimeout(() => {
            setSearchProgress(false);

            searchProgressResetTimeout.current = setTimeout(() => {
                searchBarRef.current.style.width = 0;
            }, 200);
        }, 200);
    };

    return (
        <div className="LibrarySearch">
            <div className="LibrarySearch__Field">
                <div
                    className={
                        "LibrarySearch__Progress" +
                        (searchProgress ? " _visible" : "")
                    }
                >
                    <div
                        className="LibrarySearch__Bar"
                        ref={searchBarRef}
                    ></div>
                </div>
                <input
                    className="LibrarySearch__Input"
                    placeholder="Wyszukaj"
                    value={searchValue}
                    onChange={(e) => {
                        if (searchInitFlag.current) {
                            searchInitFlag.current = false;
                        }
                        
                        setSearchValue(e.currentTarget.value);
                    }}
                    onKeyPress={(e) => onEnterKeyHandler(e)}
                />
                <button
                    className="LibrarySearch__Button"
                    type="button"
                    onClick={() => searchButtonHandler()}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        className="LibrarySearch__Svg"
                        width="20.069"
                        height="19.364"
                        viewBox="0 0 20.069 19.364"
                    >
                        <defs>
                            <clipPath id="clip-path">
                                <rect
                                    id="Rectangle_1244"
                                    dataname="Rectangle 1244"
                                    width="20.069"
                                    height="19.364"
                                    fill="none"
                                />
                            </clipPath>
                        </defs>
                        <g
                            id="Group_559"
                            dataname="Group 559"
                            clipPath="url(#clip-path)"
                        >
                            <path
                                id="Path_1051"
                                dataname="Path 1051"
                                d="M7.906,15.257A7.78,7.78,0,0,1,0,7.628,7.78,7.78,0,0,1,7.906,0a7.78,7.78,0,0,1,7.906,7.628,7.78,7.78,0,0,1-7.906,7.628M7.906.8A6.961,6.961,0,0,0,.832,7.628a6.961,6.961,0,0,0,7.074,6.825A6.961,6.961,0,0,0,14.98,7.628,6.961,6.961,0,0,0,7.906.8"
                                transform="translate(0 0)"
                                fill="#414043"
                            />
                            <path
                                id="Path_1052"
                                dataname="Path 1052"
                                d="M37.6,37.756a.423.423,0,0,1-.294-.118l-6.45-6.223a.391.391,0,0,1,0-.568.426.426,0,0,1,.588,0l6.45,6.223a.391.391,0,0,1,0,.568.423.423,0,0,1-.294.118"
                                transform="translate(-17.943 -18.392)"
                                fill="#414043"
                            />
                        </g>
                    </svg>
                </button>
            </div>
        </div>
    );
};

export default LibrarySearch;
