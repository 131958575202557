const validExamComponent = (type) => {
    const componentTypes = [
        "question_choice",
        "question_order",
        "question_slider",
        "question_mood"
    ];

    let valid = false;

    for (const componentType of componentTypes) {
        if (componentType === type) {
            valid = true;
        }
    }

    return valid;
};

const getExamComponents = (content) => {
    let idChapter;
    const examSections = [];

    for (const section of content) {
        if (section.type === "section") {
            if (!idChapter) idChapter = section.idChapter;

            const idSection = section.idChapterContent;
            const sectionOrder = section.order;
            const sectionQuestions = [];

            if (section.childs.length > 0) {
                for (const question of section.childs) {
                    if (validExamComponent(question.type)) {
                        sectionQuestions.push(question.idChapterContent);
                    }
                }
            }

            examSections.push({
                idSection,
                page: sectionOrder,
                questions: sectionQuestions,
            });
        }
    }

    return { idChapter, sections: examSections };
};

const getExamProgress = (answers, content, preventExcess, isGibs) => {
    if (!answers) return;

    if (!isGibs) {
        const examSections = [];
        const examComponents = getExamComponents(content);

        let examProgressNext = 0;
        let examProgressUndo = true;

        if (!Array.isArray(answers) && typeof answers === "object") {
            answers = [answers];
        }

        for (const answer of answers) {
            if (answer.idChapter === examComponents.idChapter) {
                examSections.push(...examComponents.sections);

                for (const section of examSections) {
                    const questionsArray = section.questions;
                    const questionComponents = answer.data;
                    const questionComponentsFilled = [];

                    for (const questionComponent of questionComponents) {
                        if (
                            questionComponent.type === "slider" &&
                            questionComponent.content.option
                        ) {
                            questionComponentsFilled.push(
                                parseInt(questionComponent.idContent)
                            );
                        } else if (questionComponent.content.length > 0) {
                            questionComponentsFilled.push(
                                parseInt(questionComponent.idContent)
                            );
                        }
                    }

                    const sectionCompleted = questionsArray.every((element) =>
                        questionComponentsFilled.includes(element)
                    );

                    if (!sectionCompleted) {
                        if (examProgressUndo) {
                            examProgressNext = section.page;
                        }

                        break;
                    } else {
                        examProgressUndo = false;

                        examProgressNext = section.page + 1;
                    }
                }
            }
        }

        return preventExcess
            ? examSections.length < examProgressNext
                ? examSections.length
                : examProgressNext
            : examProgressNext;
    } else {
        let examQuestionsDone = 0;

        const examComponents = content.filter((element) =>
            validExamComponent(element.type)
        );

        for (const examComponent of examComponents) {
            for (const answer of answers[0].data) {
                if (
                    examComponent.idChapterContent ===
                    parseInt(answer.idContent)
                ) {
                    examQuestionsDone++;
                }
            }
        }

        return examQuestionsDone === examComponents.length ? 2 : 1;
    }
};

export default getExamProgress;
