import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import "./assets/css/normalize.css";
import "./assets/css/design.css";

import "./conf/azureInsights";

import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

import StoreProvider from "./context/Store";
import ActionLoadingProvider from "./context/ActionLoadingStore";

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <StoreProvider>
                <ActionLoadingProvider>
                    <App />
                    {/* <div
                        style={{
                            position: "fixed",
                            bottom: "5px",
                            right: "5px",
                            fontSize: "10px",
                            lineHeight: "12px",
                            background: "#ff4154",
                            fontWeight: "900",
                            border: "1px solid #002c4b",
                            borderRadius: '4px',
                            color: "#f0ce4b",
                            opacity: '.9',
                            padding: '1px 3px 0',
                            pointerEvents: 'none',
                            zIndex: '9999999'
                        }}
                    >
                        v. 0.9.4
                    </div> */}
                </ActionLoadingProvider>
            </StoreProvider>
            <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
        </QueryClientProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
