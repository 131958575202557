const parseMathTagGap = (string) => {
    if (!string || typeof string !== "string") return "";

    const startTag = "[math]";
    const endTag = "[/math]";

    let newString = string;

    let startTagPosition = newString.indexOf(startTag);
    let endTagPosition = newString.indexOf(endTag);

    while (startTagPosition > -1 && endTagPosition > -1) {
        newString =
            newString.slice(0, startTagPosition) +
            newString
                .slice(startTagPosition, endTagPosition + endTag.length)
                .replace(/\s/g, "") +
            newString.slice(endTagPosition + endTag.length);

        startTagPosition = newString.indexOf(startTag, ++startTagPosition);

        endTagPosition = newString.indexOf(endTag, ++endTagPosition);
    }

    return newString;
};

export default parseMathTagGap;
