const ModalHeading = ({className, subText, children}) => {
    return (
        <>
            <div className={'app-modal-heading' + (className ? ' ' + className : '')}>
                <h3 className="app-modal-title">{children}</h3>
                {
                    subText 
                    && <p className="app-modal-subtitle">{subText}</p>
                }
            </div>
        </>
    )
}

export default ModalHeading;