import { useState, useRef, useEffect, useCallback, useContext } from "react";

import { ExamContext } from "./context/ExamStore";

import useChapter from "./hooks/useChapter";
import useChapterSavePage from "./hooks/useChapterSavePage";
import useSaveNote from "./hooks/useSaveNote";
import useFinishBook from "./hooks/useFinishBook";

import getChapter from "./functions/getChapter";

import ReaderChapter from "./ReaderChapter";
import ReaderScore from "./modals/ReaderScore";

const getChapterIsExam = (type) => {
    if (
        type === "stage_test" ||
        type === "gallup_test" ||
        type === "gibs_test"
    ) {
        return true;
    } else {
        return false;
    }
};

const ReaderBook = ({
    idBook,
    type,
    title,
    pages,
    chapters,
    pager,
    initialChapterLoading,
    cover,
    shutdown,
    isDisabled,
    isGallup,
    isGibs,
    isFinished,
}) => {
    const { page, pagesProgress, pagesTotal } = pages;
    const { data: chaptersData, update: chaptersUpdate } = chapters;
    const { setPagerDirection, isPagerDisabled } = pager;

    /* INITIAL CHAPTER LOADING STATE */
    const { isInitialChapterLoading, setIsInitialChapterLoading } =
        initialChapterLoading;

    /* GET EXAM RELOADING STATE */
    const { getExamPagesStore, setIsExamReloading } = useContext(ExamContext);

    /* GET CHAPTER DATA */
    const {
        chapter,
        chapterRefetch,
        isChapterLoading,
        isChapterRefetching,
        isChapterSuccess,
    } = useChapter(getChapter(chaptersData, page, true), page > pagesTotal);

    /* SAVE PAGE HANDLER */
    const { savePage } = useChapterSavePage(idBook, page);

    /* SAVE NOTE */
    const { saveNote, isSaveNoteSuccess } = useSaveNote();

    /* SET FINISH FLAG HOOK */
    const { finishBook } = useFinishBook(idBook);

    /* STORE NEW AND OLD CHAPTER DATA */
    const chapterData = useRef({
        old: null,
        new: chapter ? chapter.data : null,
    });

    const chapterFinishedData = useRef({
        old: false,
        new: isFinished,
    });

    const pagesData = useRef({
        old: null,
        new: page,
    });

    /* CHAPTERS UPDATE BY PROGRESS */
    const progressStore = useRef(pagesProgress);

    const handleUpdateChapters = useCallback(() => {
        if (page >= progressStore.current && page !== pagesTotal) {
            chaptersUpdate();

            progressStore.current = page;
        }
    }, [page, pagesTotal, chaptersUpdate]);

    /* CHAPTER UPDATE [NOTE COMPONENT] HANDLER TO REBUILD */
    const isNoteUpdatePending = useRef(false);
    const [isNoteUpdating, setIsNoteUpdating] = useState(false);

    useEffect(() => {
        if (
            isNoteUpdating &&
            isSaveNoteSuccess &&
            !isNoteUpdatePending.current
        ) {
            isNoteUpdatePending.current = true;

            chapterRefetch();
        }
    }, [isNoteUpdating, isSaveNoteSuccess, chapterRefetch]);

    useEffect(() => {
        if (isChapterSuccess && isNoteUpdatePending.current) {
            isNoteUpdatePending.current = false;

            chapterData.current.new = chapter.data;

            setIsNoteUpdating(false);
        }
    }, [isChapterSuccess, chapter]);

    /* CHAPTER UPDATE [EXAM ANSWERS] HANDLER */
    const isExamUpdatePending = useRef(false);
    const [isExamUpdating, setIsExamUpdating] = useState(false);

    useEffect(() => {
        if (isExamUpdating && !isExamUpdatePending.current) {
            isExamUpdatePending.current = true;

            chapterRefetch();
        }

        if (!isExamUpdating && isExamUpdatePending.current) {
            isExamUpdatePending.current = false;

            setIsExamReloading(true);
        }
    }, [isExamUpdating, chapterRefetch, setIsExamReloading]);

    useEffect(() => {
        if (isChapterSuccess && isExamUpdatePending.current) {
            chapterData.current.new = chapter.data;

            setIsExamUpdating(false);
        }
    }, [isChapterSuccess, chapter, setIsExamUpdating]);

    /* SET INITIAL CHAPTER READY IF LOADING IS FINISHED */
    const isChapterInitialReady = useRef(false);

    useEffect(() => {
        if (!isChapterLoading && !isChapterInitialReady.current) {
            isChapterInitialReady.current = true;

            chapterData.current.new = chapter.data;

            /* UPDATE CHAPTERS TO UNLOCK NEXT CHAPTER */
            chaptersUpdate();

            setTimeout(() => {
                setIsInitialChapterLoading(false);
            }, 500);
        }
    }, [isChapterLoading, setIsInitialChapterLoading, chapter, chaptersUpdate]);

    /* SLIDE DEFAULTS */
    const [slideIsMount, setSlideIsMount] = useState(false);
    const [slideIsPending, setSlideIsPending] = useState(false);

    /* SLIDE FLAGS */
    const slideWasLoading = useRef(false);
    const slideMountFlag = useRef(false);
    const slidePendingFlag = useRef(false);
    const slideIsReverse = useRef(false);

    /* MULTIBOOK ELEMENT REFS */
    const bookRef = useRef(null);
    const sliderRef = useRef(null);
    const slideTrackRef = useRef(null);

    /* MULTIBOOK VARIABLES */
    const bookTop = useRef(0);

    /* SET SLIDE MOUNT */
    useEffect(() => {
        if (!isChapterLoading && !slideMountFlag.current) {
            pagesData.current.old = pagesData.current.new;

            bookTop.current = bookRef.current ? bookRef.current.scrollTop : 0;

            if (pagesData.current.old !== page) {
                slideMountFlag.current = true;

                if (pagesData.current.old > page) {
                    slideIsReverse.current = true;
                } else {
                    slideIsReverse.current = false;
                }

                if (!slideWasLoading.current && page <= pagesTotal) {
                    savePage();
                }

                chapterData.current.old = chapterData.current.new;
                chapterFinishedData.current.old =
                    chapterFinishedData.current.new;

                setSlideIsMount(true);
            }
        } else {
            slideWasLoading.current = true;
        }
    }, [page, pagesTotal, isChapterLoading, savePage]);

    /* MOUNT NEW SLIDE AND ANIMATE */
    useEffect(() => {
        if (slideIsMount) {
            pagesData.current.new = page;

            /* CHAPTER CUSTOM STRING TO INDICATE THAT'S FINAL PAGE OUT OF API */
            if (chapter === "finished" && page > pagesTotal) {
                chapterFinishedData.current.new = true;

                if (!isGallup && !isGibs) {
                    finishBook(1);
                }
            } else if (chapterFinishedData.current.new) {
                chapterFinishedData.current.new = false;

                if (!isGallup && !isGibs) {
                    finishBook(0);
                }
            }

            if (typeof chapter !== "string" && chapter !== "final") {
                chapterData.current.new = chapter
                    ? chapter.data
                    : {
                          chapters: {
                              title: "Błąd - Rozdział nie istnieje",
                          },
                          content: [
                              {
                                  type: "fact",
                                  content:
                                      "<p>Czy wiedziałeś, że aplikacje od czasu do czasu może wylecieć w kosmos? Kiedy wyskakuje błąd DEV się tym zajmuje od ręki!</p>",
                                  idChapter: 2115,
                                  idChapterContent: 2137,
                              },
                          ],
                      };
            }

            setSlideIsPending(true);
        }
    }, [slideIsMount, chapter, page, pagesTotal, isGallup, isGibs, finishBook]);

    /* CLEAR SLIDE TRACK */
    useEffect(() => {
        if (slideIsPending && !slidePendingFlag.current) {
            const animationTime =
                parseFloat(
                    window
                        .getComputedStyle(slideTrackRef.current)
                        .getPropertyValue("animation-duration")
                ) * 1000;

            slidePendingFlag.current = true;

            setTimeout(() => {
                sliderRef.current.scrollTop = 0;

                isPagerDisabled.current = false;

                setSlideIsMount(false);
            }, animationTime);
        }
    }, [slideIsPending, isPagerDisabled]);

    /* CLEAR SLIDE FLAGS */
    useEffect(() => {
        if (!slideIsMount && slidePendingFlag.current) {
            slideWasLoading.current = false;
            slideMountFlag.current = false;
            slidePendingFlag.current = false;

            handleUpdateChapters();

            setSlideIsPending(false);

            if (
                !getChapterIsExam(chapterData.current.new.chapters.type) ||
                getExamPagesStore(chapterData.current.new.chapters.idChapter)
            ) {
                setPagerDirection(null);
            }
        }
    }, [
        slideIsMount,
        handleUpdateChapters,
        getExamPagesStore,
        setPagerDirection,
    ]);

    return (
        <>
            {!isInitialChapterLoading ? (
                <>
                    {slideIsMount ? (
                        <div
                            className="ReaderBook__Slider"
                            data-panel="false"
                            ref={sliderRef}
                        >
                            <div
                                className={
                                    "ReaderBook__Track" +
                                    (slideIsPending
                                        ? slideIsReverse.current
                                            ? " _animating--reverse"
                                            : " _animating"
                                        : "")
                                }
                                ref={slideTrackRef}
                            >
                                <div className="ReaderBook__Content">
                                    <ReaderChapter
                                        idBook={idBook}
                                        idChapter={
                                            !chapterFinishedData.current.old
                                                ? chapterData.current.old
                                                      .chapters.idChapter
                                                : null
                                        }
                                        type={type}
                                        titles={{
                                            titleBook: title,
                                            titleChapter: !chapterFinishedData
                                                .current.old
                                                ? chapterData.current.old
                                                      .chapters.title
                                                : "",
                                        }}
                                        pages={{
                                            ...pages,
                                            page: pagesData.current.old,
                                        }}
                                        content={
                                            !chapterFinishedData.current.old
                                                ? chapterData.current.old
                                                      .content
                                                : undefined
                                        }
                                        pager={pager}
                                        note={{
                                            saveNote,
                                            isNoteUpdating,
                                            setIsNoteUpdating,
                                        }}
                                        cover={cover}
                                        shutdown={shutdown}
                                        isPending={
                                            isDisabled ||
                                            slideIsMount ||
                                            slideIsPending
                                        }
                                        isLoading={
                                            isChapterLoading ||
                                            isChapterRefetching ||
                                            isNoteUpdating
                                        }
                                        isExam={
                                            !chapterFinishedData.current.old
                                                ? getChapterIsExam(
                                                      chapterData.current.old
                                                          .chapters.type
                                                  )
                                                : false
                                        }
                                        isGallup={isGallup}
                                        isGibs={isGibs}
                                        isAnswered={
                                            !chapterFinishedData.current.old
                                                ? chapterData.current.old
                                                      .answered
                                                : false
                                        }
                                        isFinished={
                                            chapterFinishedData.current.old
                                        }
                                    />
                                </div>
                                {slideIsPending ? (
                                    <div
                                        className="ReaderBook__Content _content--next"
                                        style={{
                                            top: bookTop.current,
                                        }}
                                    >
                                        <ReaderChapter
                                            idBook={idBook}
                                            idChapter={
                                                !chapterFinishedData.current.new
                                                    ? chapterData.current.new
                                                          .chapters.idChapter
                                                    : null
                                            }
                                            type={type}
                                            titles={{
                                                titleBook: title,
                                                titleChapter:
                                                    !chapterFinishedData.current
                                                        .new
                                                        ? chapterData.current
                                                              .new.chapters
                                                              .title
                                                        : "",
                                            }}
                                            pages={{
                                                ...pages,
                                                page: pagesData.current.new,
                                            }}
                                            content={
                                                !chapterFinishedData.current.new
                                                    ? chapterData.current.new
                                                          .content
                                                    : undefined
                                            }
                                            pager={pager}
                                            note={{
                                                saveNote,
                                                isNoteUpdating,
                                                setIsNoteUpdating,
                                            }}
                                            cover={cover}
                                            shutdown={shutdown}
                                            isPending={
                                                isDisabled ||
                                                slideIsMount ||
                                                slideIsPending
                                            }
                                            isLoading={
                                                isChapterLoading ||
                                                isChapterRefetching ||
                                                isNoteUpdating
                                            }
                                            isExam={
                                                !chapterFinishedData.current.new
                                                    ? getChapterIsExam(
                                                          chapterData.current
                                                              .new.chapters.type
                                                      )
                                                    : false
                                            }
                                            isGallup={isGallup}
                                            isGibs={isGibs}
                                            isAnswered={
                                                !chapterFinishedData.current.new
                                                    ? chapterData.current.new
                                                          .answered
                                                    : false
                                            }
                                            isFinished={
                                                chapterFinishedData.current.new
                                            }
                                        />
                                    </div>
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>
                    ) : !isExamUpdating ? (
                        <div
                            className="ReaderBook"
                            ref={bookRef}
                            data-panel={
                                slideIsMount ||
                                slideIsPending ||
                                isChapterLoading ||
                                isChapterRefetching ||
                                isNoteUpdating
                                    ? false
                                    : ""
                            }
                        >
                            <ReaderChapter
                                idBook={idBook}
                                idChapter={
                                    !chapterFinishedData.current.new
                                        ? chapterData.current.new.chapters
                                              .idChapter
                                        : null
                                }
                                type={type}
                                titles={{
                                    titleBook: title,
                                    titleChapter: !chapterFinishedData.current
                                        .new
                                        ? chapterData.current.new.chapters.title
                                        : "",
                                }}
                                pages={{
                                    ...pages,
                                    page: pagesData.current.new,
                                }}
                                content={
                                    !chapterFinishedData.current.new
                                        ? chapterData.current.new.content
                                        : undefined
                                }
                                pager={pager}
                                note={{
                                    saveNote,
                                    isNoteUpdating,
                                    setIsNoteUpdating,
                                }}
                                cover={cover}
                                shutdown={shutdown}
                                isPending={
                                    isDisabled || slideIsMount || slideIsPending
                                }
                                isLoading={
                                    isChapterLoading ||
                                    isChapterRefetching ||
                                    isNoteUpdating
                                }
                                isExam={
                                    !chapterFinishedData.current.new
                                        ? getChapterIsExam(
                                              chapterData.current.new.chapters
                                                  .type
                                          )
                                        : false
                                }
                                isGallup={isGallup}
                                isGibs={isGibs}
                                isAnswered={
                                    !chapterFinishedData.current.new
                                        ? chapterData.current.new.answered
                                        : false
                                }
                                isFinished={chapterFinishedData.current.new}
                            />
                        </div>
                    ) : (
                        ""
                    )}
                    <ReaderScore
                        title={title}
                        pages={pages}
                        pager={pager}
                        chapterRefetch={chapterRefetch}
                        shutdown={shutdown}
                        setIsExamUpdating={setIsExamUpdating}
                        isChapterLoading={isChapterLoading}
                        isChapterRefetching={isChapterRefetching}
                        isChapterSuccess={isChapterSuccess}
                    />
                </>
            ) : (
                ""
            )}
        </>
    );
};

export default ReaderBook;
