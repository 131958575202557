import { useState, useRef, useEffect, useCallback } from "react";

const useComponentTransition = (timeout, openOnStart) => {
    const toggleDisabled = useRef(false);
    const mountedOnStart = useRef(openOnStart);

    const [isMounted, setIsMounted] = useState(openOnStart ? true : false);
    const [isUnmounting, setIsUnmounting] = useState(false);

    const [entering, setEntering] = useState(false);
    const [entered, setEntered] = useState(openOnStart ? true : false);

    const [exiting, setExiting] = useState(false);
    const [exited, setExited] = useState(false);

    const showComponent = useCallback(() => {
        if (!toggleDisabled.current && !isMounted) {
            toggleDisabled.current = true;

            setIsMounted(true);
        }
    }, [isMounted]);

    const hideComponent = useCallback(() => {
        if (!toggleDisabled.current && isMounted) {
            toggleDisabled.current = true;

            setIsUnmounting(true);
        }
    }, [isMounted]);

    useEffect(() => {
        if (isMounted && !mountedOnStart.current) {
            setTimeout(() => {
                setEntering(true);
            }, 10);
        }
    }, [isMounted]);

    useEffect(() => {
        if (entering) {
            setTimeout(
                () => {
                    toggleDisabled.current = false;

                    setEntering(false);
                    setEntered(true);
                },
                timeout ? timeout + 10 : 510
            );
        }
    }, [entering, timeout]);

    useEffect(() => {
        if (isUnmounting) {
            setEntered(false);

            setTimeout(() => {
                setExiting(true);
            }, 10);
        }
    }, [isUnmounting]);

    useEffect(() => {
        if (exiting) {
            setTimeout(
                () => {
                    setExiting(false);
                    setExited(true);
                },
                timeout ? timeout + 10 : 510
            );
        }
    }, [exiting, timeout]);

    useEffect(() => {
        if (exited) {
            setIsMounted(false);

            setTimeout(() => {
                toggleDisabled.current = false;
                mountedOnStart.current = false;

                setExited(false);
                setIsUnmounting(false);
            }, 10);
        }
    }, [exited]);

    return {
        show: showComponent,
        hide: hideComponent,
        isMounted: isMounted,
        isUnmounting: isUnmounting,
        entering: entering,
        entered: entered,
        exiting: exiting,
        exited: exited,
    };
};

export default useComponentTransition;
