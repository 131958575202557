import { useState, useRef, useEffect } from "react";
import { Router, Route, useHistory, useRouteMatch } from "react-router-dom";
import { CSSTransition } from "react-transition-group";

import useComponentWillMount from "../../hooks/useComponentWillMount";

import pathSlice from "../../functions/pathSlice";

import Topbar from "../../components/containers/dashboard/Topbar";
import RouteDelay from "../../components/route/RouteDelay";
import RouteNavigation from "../../components/route/navigation/RouteNavigation";
import Navigation from "../../components/containers/dashboard/Navigation";
import ModalTransition from "../../components/modal-bodies/ModalTransition";
import ModalAction from "../../components/modal-bodies/ModalAction";

import Home from "./teacher/Home";

import TeacherAccount from "./teacher/TeacherAccount";
import TeacherAccountEdit from "./teacher/TeacherAccountEdit";
import PasswordEdit from "./PasswordEdit";

import Schools from "./teacher/Schools";
import SchoolGroups from "./teacher/SchoolGroups";
import SchoolGroup from "./teacher/SchoolGroup";
import SchoolGroupAdd from "./teacher/SchoolGroupAdd";
import SchoolGroupEdit from "./teacher/SchoolGroupEdit";
import SchoolGroupStudent from "./teacher/SchoolGroupStudent";
import SchoolGroupStudentAdd from "./teacher/SchoolGroupStudentAdd";
import StudentLearningProgress from "./teacher/StudentLearningProgress";
import StudentLearningProgressDetails from "./teacher/StudentLearningProgressDetails";

import Library from "./Library";
import LibraryCategory from "./LibraryCategory";
import LibraryFavorites from "./LibraryFavorites";

import MyShelf from "./MyShelf";

import GroupProjects from "./teacher/GroupProjects";

import MySubjects from "./teacher/MySubjects";

import Notifications from "./Notifications";

const DashboardTeacher = () => {
    const { path } = useRouteMatch();
    const history = useHistory();

    /* ROUTE LOADING STATE */
    const [routeIsLoading, setRouteIsLoading] = useState(false);

    /* ROUTE BACKWARD STATE */
    const [routeIsBackward, setRouteIsBackward] = useState(false);

    /* ROUTE NAVIGATION DATA */
    const [routeData, setRouteData] = useState();

    /* ROUTE DELAY TIMEOUT */
    const routeTimeout = 500;

    /* ROUTE TRANSITION CLASSNAME */
    const routeClassNames = routeIsBackward
        ? routeIsLoading
            ? "SlideRightIn"
            : "SlideRight"
        : routeIsLoading
        ? "SlideLeftIn"
        : "SlideLeft";

    /* ROUTE REFS */
    const routeHomeRef = useRef(null);
    const routeAccountRef = useRef(null);
    const routeAccountEditRef = useRef(null);
    const routePasswordEditRef = useRef(null);
    const routeSchoolsRef = useRef(null);
    const routeSchoolGroupsRef = useRef(null);
    const routeSchoolGroupAddRef = useRef(null);
    const routeSchoolGroupRef = useRef(null);
    const routeSchoolGroupEditRef = useRef(null);
    const routeSchoolGroupStudentRef = useRef(null);
    const routeSchoolGroupStudentAddRef = useRef(null);
    const routeStudentLearningProgressRef = useRef(null);
    const routeStudentLearningDetailsRef = useRef(null);
    const routeLibraryRef = useRef(null);
    const routeLibraryCategoryRef = useRef(null);
    const routeLibraryFavoritesRef = useRef(null);
    const routeMyShelfRef = useRef(null);
    const routeGroupProjectsRef = useRef(null);
    const routeMySubjectsRef = useRef(null);
    const routeNotificationsRef = useRef(null);

    /* ROUTE PREV URL */
    const routePrevPath = useRef(null);

    /* CHECK IF ROUTE IS BACKWARD */
    useComponentWillMount(() => {
        history.listen((location, action) => {
            const routeNextPath = pathSlice(location.pathname);
            const routePrevPathLength = routePrevPath.current
                ? routePrevPath.current.substr(1).split("/").length
                : 0;
            const routeNextPathLength = routeNextPath
                .substr(1)
                .split("/").length;

            if (action === "POP" || routePrevPathLength > routeNextPathLength) {
                setRouteIsBackward(true);
            } else {
                setRouteIsBackward(false);
            }

            routePrevPath.current = pathSlice(location.pathname);
        });
    });

    /* MODAL ACTIVE */
    const [modalActive, setModalActive] = useState(false);

    /* MODAL DATA */
    const [modalData, setModalData] = useState({});
    const modalDataClear = useRef(false);

    /* MODAL API */
    const [modalApiResponse, setModalApiResponse] = useState(false);
    const [modalApiLoading, setModalApiLoading] = useState(false);
    const [modalApiRefetching, setModalApiRefetching] = useState(false);
    const modalApiClear = useRef(false);

    /* MODAL OPEN */
    useEffect(() => {
        if (modalData.data && !modalDataClear.current) {
            modalDataClear.current = true;

            setModalActive(true);
        }
    }, [modalData]);

    const routes = [
        {
            ref: routeHomeRef,
            path: path,
            children: <Home />,
        },
        {
            ref: routeAccountRef,
            path: `${path}/account`,
            children: <TeacherAccount />,
        },
        {
            ref: routeAccountEditRef,
            path: `${path}/account/edit-account`,
            children: <TeacherAccountEdit />,
        },
        {
            ref: routePasswordEditRef,
            path: `${path}/account/edit-password`,
            children: <PasswordEdit />,
        },
        {
            ref: routeSchoolsRef,
            path: `${path}/myschools`,
            children: <Schools />,
        },
        {
            ref: routeSchoolGroupsRef,
            path: `${path}/myschools/school-:idSchool`,
            children: <SchoolGroups />,
        },
        {
            ref: routeSchoolGroupAddRef,
            path: `${path}/myschools/school-:idSchool/add-group`,
            children: <SchoolGroupAdd />,
        },
        {
            ref: routeSchoolGroupRef,
            path: `${path}/myschools/school-:idSchool/group-:idGroup`,
            children: <SchoolGroup />,
        },
        {
            ref: routeSchoolGroupEditRef,
            path: `${path}/myschools/school-:idSchool/group-:idGroup/edit-group`,
            children: <SchoolGroupEdit />,
        },
        {
            ref: routeSchoolGroupStudentRef,
            path: `${path}/myschools/school-:idSchool/group-:idGroup/student-:idStudent`,
            children: <SchoolGroupStudent />,
        },
        {
            ref: routeSchoolGroupStudentAddRef,
            path: `${path}/myschools/school-:idSchool/group-:idGroup/add-student`,
            children: <SchoolGroupStudentAdd />,
        },
        {
            ref: routeStudentLearningProgressRef,
            path: `${path}/myschools/school-:idSchool/group-:idGroup/student-:idStudent/learning-progress`,
            children: <StudentLearningProgress />,
        },
        {
            ref: routeStudentLearningDetailsRef,
            path: `${path}/myschools/school-:idSchool/group-:idGroup/student-:idStudent/learning-progress/details-:idMultibook`,
            children: <StudentLearningProgressDetails />,
        },
        {
            ref: routeLibraryRef,
            path: `${path}/library`,
            children: <Library />,
        },
        {
            ref: routeLibraryCategoryRef,
            path: `${path}/library/category-:idCategory`,
            children: <LibraryCategory />,
        },
        {
            ref: routeLibraryFavoritesRef,
            path: `${path}/library/favorites`,
            children: <LibraryFavorites />,
        },
        {
            ref: routeMyShelfRef,
            path: `${path}/mybookshelf`,
            children: <MyShelf />,
        },
        {
            ref: routeGroupProjectsRef,
            path: `${path}/group-projects`,
            children: <GroupProjects />,
        },
        {
            ref: routeMySubjectsRef,
            path: `${path}/mysubjects`,
            children: <MySubjects />,
        },
        {
            ref: routeNotificationsRef,
            path: `${path}/notifications`,
            children: <Notifications />,
        },
    ];

    return (
        <>
            <Topbar
                modalSetters={{
                    setModalData: setModalData,
                    setModalActive: setModalActive,
                    setModalApiResponse: setModalApiResponse,
                    setModalApiLoading: setModalApiLoading,
                }}
            />

            <div className="RouteGroup">
                <RouteNavigation
                    routeData={routeData}
                    routeTimeout={routeTimeout}
                    routeIsLoading={routeIsLoading}
                    routeIsBackward={routeIsBackward}
                />
                {routes?.length > 0 ? (
                    <Router history={history}>
                        {routes.map((route) => {
                            return (
                                <Route key={route.path} exact path={route.path}>
                                    {({ match }) => (
                                        <CSSTransition
                                            nodeRef={route.ref}
                                            in={match !== null}
                                            classNames={routeClassNames}
                                            timeout={500}
                                            unmountOnExit
                                            appear
                                        >
                                            <RouteDelay
                                                ref={route.ref}
                                                routeMatch={{
                                                    match: match,
                                                    path: route.path,
                                                }}
                                                routeTimeout={routeTimeout}
                                                routeIsLoading={routeIsLoading}
                                                routeIsBackward={
                                                    routeIsBackward
                                                }
                                                children={route.children}
                                                routeSetters={{
                                                    setRouteData: setRouteData,
                                                    setRouteIsLoading:
                                                        setRouteIsLoading,
                                                }}
                                                modalSetters={{
                                                    setModalData: setModalData,
                                                    setModalActive:
                                                        setModalActive,
                                                    setModalApiResponse:
                                                        setModalApiResponse,
                                                    setModalApiLoading:
                                                        setModalApiLoading,
                                                }}
                                            />
                                        </CSSTransition>
                                    )}
                                </Route>
                            );
                        })}
                    </Router>
                ) : (
                    ""
                )}
            </div>

            <Navigation
                navs={[
                    {
                        text: "Home",
                        icon: "home",
                        onClick: () => history.push(path),
                    },
                    {
                        text: "Moja półka",
                        icon: "my-shelf",
                        onClick: () => history.push(`${path}/mybookshelf`),
                    },
                    {
                        type: "notifications",
                        text: "Powiadomienia",
                        icon: "notifications",
                        onClick: () => history.push(`${path}/notifications`),
                    },
                    {
                        text: "Moje szkoły",
                        icon: "my-schools",
                        onClick: () => history.push(`${path}/myschools`),
                    },
                ]}
            />

            <ModalTransition
                isActive={modalActive}
                children={
                    <ModalAction
                        data={modalData.data}
                        response={modalApiResponse}
                        select={modalData.select}
                        isLoading={modalApiLoading}
                        isRefetching={setModalApiRefetching}
                        onFinish={() => {
                            if (modalApiLoading || modalApiResponse) {
                                modalApiClear.current = true;
                            }

                            setModalActive(false);
                        }}
                    />
                }
                isLoading={modalApiLoading}
                isRefetching={modalApiRefetching}
                onExited={() => {
                    modalDataClear.current = false;

                    setModalData({});

                    if (modalApiClear.current) {
                        modalApiClear.current = false;

                        setModalApiResponse(false);
                        setModalApiLoading(false);
                    }
                }}
                onClose={
                    modalData.close ? () => setModalActive(false) : undefined
                }
            />
        </>
    );
};

export default DashboardTeacher;
