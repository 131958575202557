import { BrowserRouter, Switch, Route } from "react-router-dom";

// import useChat from "./hooks/useChat";

import Authorization from "./Authorization";
import HooksProvider from "./context/HooksStore";
import LibraryProvider from "./context/LibStore";

import Home from "./pages/Home";
import SigningForm from "./pages/SigningForm";
import ActivateAccountForm from "./components/forms/signing/ActivateAccountForm";
import RemindPassword from "./pages/RemindPassword";
import RemindPasswordFinish from "./pages/RemindPasswordFinish";
import Dashboard from "./pages/dashboard/Dashboard";
import AccountDelete from "./pages/dashboard/AccountDelete";
import SuccessfulAddChildren from "./pages/dashboard/parent/SuccessfulAddChildren";
import MultibookReader from "./pages/dashboard/MultibookReader";
import Health from "./pages/Health";

import "./pages/dashboard/ReactBigCalendar/sass/styles.scss";

export const handleNavigationRoute = () => {
    const defaultButton = document.querySelector("#NavigationDefault");
    const bookButton = document.querySelector("#NavigationBook");

    if (defaultButton) {
        defaultButton.click();
    } else if (bookButton) {
        bookButton.click();
    }
};

export const getCookieTime = () => {
    const date = new Date();
    
    return new Date(date.setFullYear(date.getFullYear() + 1));
}

function App() {
    // useChat();

    return (
        <Authorization>
            <HooksProvider>
                <LibraryProvider>
                    <BrowserRouter>
                        <Switch>
                            <Route exact path="/" component={Home} />
                            <Route
                                path="/signing-form"
                                component={SigningForm}
                            />
                            <Route
                                path="/remind-password"
                                component={RemindPassword}
                            />
                            <Route
                                path="/remind-password-finish"
                                component={RemindPasswordFinish}
                            />
                            <Route
                                path="/activate-account"
                                component={ActivateAccountForm}
                            />
                            <Route path="/dashboard" component={Dashboard} />
                            <Route
                                path="/account-delete"
                                component={AccountDelete}
                            />
                            <Route
                                exact
                                path="/successful-add-children"
                                component={SuccessfulAddChildren}
                            />
                            <Route
                                exact
                                path="/multibook-:idMultibook"
                                component={MultibookReader}
                            ></Route>
                            <Route exact path="/health" component={Health} />
                        </Switch>
                    </BrowserRouter>
                </LibraryProvider>
            </HooksProvider>
        </Authorization>
    );
}

export default App;
