const SubjectsButton = ({id, text, onClick}) => {
    return (
        <button className="SubjectsButton" type="button" onClick={() => onClick({
            id: id,
            name: text
        })}>
            {text}
            <span className="SubjectsButton__Icon">
                <svg
                    className="SubjectsButton__Svg"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width="13"
                    height="13"
                    viewBox="0 0 13 13"
                >
                    <defs>
                        <clipPath id="subjectsIconClipPath_1">
                            <rect
                                id="subjectsIconRect_1"
                                dataname="subjectsIconRect 1"
                                width="13"
                                height="13"
                                fill="none"
                                stroke="#2e2a26"
                                strokeWidth="2"
                            />
                        </clipPath>
                    </defs>
                    <g
                        id="subjectsIconGroup_1"
                        dataname="subjectsIconGroup 1"
                        transform="translate(-83 5)"
                    >
                        <g
                            id="subjectsIconGroup_2"
                            dataname="subjectsIconGroup 2"
                            transform="translate(83 -5)"
                            clipPath="url(#subjectsIconClipPath_1)"
                        >
                            <line
                                className="SubjectsButton__Line"
                                id="subjectsIconLine_1"
                                dataname="subjectsIconLine 1"
                                y1="11"
                                x2="11"
                                transform="translate(1 1)"
                                fill="none"
                                stroke="#2e2a26"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                            />
                            <line
                                className="SubjectsButton__Line"
                                id="subjectsIconLine_2"
                                dataname="subjectsIconLine 2"
                                x2="11"
                                y2="11"
                                transform="translate(1 1)"
                                fill="none"
                                stroke="#2e2a26"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                            />
                        </g>
                    </g>
                </svg>
            </span>
        </button>
    );
};

export default SubjectsButton;
