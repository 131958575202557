import { useHistory } from "react-router-dom";

import useProtectedRoute from "../hooks/useProtetedRoute";

import Title from '../components/headings/Title';
import Button from '../components/buttons/Button';
import TextSmall from '../components/containers/TextSmall';

import DoodleBanner from "../components/containers/doodle/DoodleBanner";
import DoodleContent from "../components/containers/doodle/DoodleContent";

import doodles_1 from "../assets/images/home/how2doodles_1.svg";
import doodles_2 from "../assets/images/home/how2doodles_2.svg";

const Home = () => {
    useProtectedRoute("/dashboard", true)

    const history = useHistory();

    return (
        <>
            <div className="app-home">
                <DoodleBanner
                    wrapperClass="home"
                    imgs={{ doodles_1, doodles_2 }}
                />
                <DoodleContent wrapperClass="home">
                    <Title>Witamy w How2 Edu</Title>
                    <Button
                        classNameWrapper="home-button"
                        className="inverse"
                        onClickFn={() =>
                            history.push("/signing-form", { tab: "register" })
                        }
                    >
                        Załóż konto
                    </Button>
                    <Button
                        classNameWrapper="home-button"
                        className="inverse"
                        onClickFn={() =>
                            history.push("/signing-form", { tab: "login" })
                        }
                    >
                        Zaloguj się
                    </Button>
                    <TextSmall className="home-text">
                        <p
                            style={{cursor: 'pointer'}}
                            onClick={() =>
                                (window.location = "mailto:pomoc@how2edu.pl")
                            }
                        >
                            Jeśli jesteś jednostką publiczną lub nauczycielem
                            napisz na <strong>pomoc@how2edu.pl</strong>
                        </p>
                    </TextSmall>
                </DoodleContent>
            </div>
        </>
    );
};

export default Home;
