import { useRef } from "react";
import { CSSTransition } from "react-transition-group";

import Modal from "./Modal";

const ModalTransition = ({
    isActive,
    children,
    isLoading,
    isRefetching,
    onExited,
    onClose,
}) => {
    const modalRef = useRef(null);

    return (
        <>
            <CSSTransition
                nodeRef={modalRef}
                in={isActive}
                classNames="ModalFade"
                timeout={300}
                onExited={onExited}
                unmountOnExit
                appear
            >
                <Modal
                    ref={modalRef}
                    children={children}
                    isLoading={isLoading}
                    isRefetching={isRefetching}
                    onClose={onClose}
                />
            </CSSTransition>
        </>
    );
};

export default ModalTransition;
