import {
    useState,
    useRef,
    forwardRef,
    useEffect,
    useCallback,
    useContext,
} from "react";
import { FlashcardContext } from "../context/FlashcardStore";

import useComponentTransition from "../hooks/useComponentTransition";

const ReaderFlashcard = ({ content, children, isGallup, isGibs }) => {
    /* COMPONENT TRANSITION HOOK */
    const { show, hide, isMounted, entering, entered } =
        useComponentTransition(300);

    /* FLASHCARD SETTERS */
    const { flashElement, setFlashElement, flashIndex, setFlashIndex } =
        useContext(FlashcardContext);

    /* FLASHCARD UTILS */
    const flashIndexMemo = useRef(1);
    const isFlashOpen = useRef(false);
    const [isFlashTop, setIsFlashTop] = useState(false);

    /* ARROW UTILS */
    const [arrowLeftPosition, setArrowLeftPosition] = useState(10);
    const flashcardButtonRef = useRef(null);
    const flashcardArrowRef = useRef(null);

    /* SET FLASHCARD ARROW POSITION */
    const setArrowPosition = useCallback(() => {
        if (flashcardArrowRef.current) {
            const buttonOffset = flashcardButtonRef.current.offsetLeft;
            const buttonWidth = flashcardButtonRef.current.clientWidth;
            const arrowWidth = flashcardArrowRef.current.offsetWidth;

            setArrowLeftPosition(
                buttonOffset + buttonWidth / 2 - arrowWidth / 2
            );
        }
    }, []);

    /* TOGGLE FLASHCARD */
    const handleToggleFlash = (event) => {
        event.stopPropagation();
        
        if (isMounted) {
            isFlashOpen.current = false;

            hide();
        } else {
            isFlashOpen.current = true;

            flashIndexMemo.current = flashIndex;

            show();
        }

        setFlashElement(event.currentTarget);
    };

    useEffect(() => {
        if (isMounted) {
            const chapterHeight =
                document.querySelector(".ReaderChapter").offsetHeight;
            const buttonPosition = flashcardButtonRef.current.offsetTop;

            if (chapterHeight - buttonPosition <= 500) {
                setIsFlashTop(true);
            }
        }
    }, [isMounted]);

    /* HIDE FLASHCARD */
    const handleFlashHide = () => {
        if (isMounted) {
            hide();

            setFlashElement(null);
        }
    };

    /* CLOSE OTHER FLASHCARDS */
    useEffect(() => {
        if (
            flashElement !== flashcardButtonRef.current &&
            isMounted &&
            isFlashOpen.current
        ) {
            isFlashOpen.current = false;

            hide();
        }
    }, [flashElement, isMounted, hide]);

    /* SET ARROW POSITION AND SET INDEX */
    useEffect(() => {
        if (entering) {
            setArrowPosition();

            setFlashIndex((prevState) => prevState + 1);
        }
    }, [entering, setArrowPosition, setFlashIndex]);

    /* SET ARROW POSITION ON RESIZE */
    useEffect(() => {
        window.addEventListener("resize", setArrowPosition);

        return () => {
            window.removeEventListener("resize", setArrowPosition);
        };
    }, [setArrowPosition]);

    return (
        <>
            <button
                ref={flashcardButtonRef}
                className={
                    "ReaderFlashcard__Button" +
                    (isGallup || isGibs ? " _color--secondary" : "")
                }
                type="button"
                data-panel="false"
                onClick={(event) => handleToggleFlash(event)}
            >
                {children}
            </button>
            {isMounted ? (
                <span
                    className={
                        "ReaderFlashcard" +
                        (isFlashTop ? " _position--top" : "") +
                        (entering || entered ? " _open" : "") +
                        (isGallup || isGibs ? " _color--secondary" : "")
                    }
                    style={{ zIndex: flashIndexMemo.current }}
                    data-panel="false"
                    onClick={handleFlashHide}
                >
                    {!isFlashTop && (
                        <FlashcardArrow
                            position={arrowLeftPosition}
                            ref={flashcardArrowRef}
                        />
                    )}
                    <span
                        className="ReaderFlashcard__Box"
                        dangerouslySetInnerHTML={{ __html: content }}
                    ></span>
                    {isFlashTop && (
                        <FlashcardArrow
                            position={arrowLeftPosition}
                            ref={flashcardArrowRef}
                        />
                    )}
                </span>
            ) : (
                ""
            )}
        </>
    );
};

const FlashcardArrow = forwardRef((props, ref) => {
    const { position } = props;

    return (
        <span
            className="ReaderFlashcard__Arrow"
            style={{ left: position > 0 ? position : 0 }}
            ref={ref}
        ></span>
    );
});

export default ReaderFlashcard;
