import { useContext } from "react";
import { useMutation } from "react-query";

import { StoreContext } from "../../../context/Store";

const useFinishExam = (idChapter) => {
    const { api } = useContext(StoreContext);

    const {
        mutate: examFinish,
        isSuccess: isExamFinishSuccess,
        isError: isExamFinishError,
    } = useMutation(() => api.post("/exams/finish", { idChapter }));

    return {
        examFinish,
        isExamFinishSuccess,
        isExamFinishError,
    };
};

export default useFinishExam;
