const IconMultibook = ({ type }) => {
    const icon = (type) => {
        switch (type) {
            case "multi":
                return (
                    <svg
                        id="Ikona_multibook_01"
                        dataname="Ikona multibook 01"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        width="14.316"
                        height="13.597"
                        viewBox="0 0 14.316 13.597"
                    >
                        <defs>
                            <clipPath id="clip-path">
                                <rect
                                    id="Rectangle_1568"
                                    dataname="Rectangle 1568"
                                    width="14.316"
                                    height="13.597"
                                    fill="none"
                                />
                            </clipPath>
                        </defs>
                        <g
                            id="Group_1071"
                            dataname="Group 1071"
                            clipPath="url(#clip-path)"
                        >
                            <path
                                id="Path_1907"
                                dataname="Path 1907"
                                d="M12.637,2.423A6.566,6.566,0,0,1,11.765,12,7.422,7.422,0,0,1,1.68,11.174,6.565,6.565,0,0,1,2.552,1.6a7.422,7.422,0,0,1,10.085.828"
                                transform="translate(0 0)"
                                fill="#0b6ffa"
                            />
                            <path
                                id="Path_1908"
                                dataname="Path 1908"
                                d="M17.714,15.769v5.7a.129.129,0,0,0,.126.13,8.788,8.788,0,0,1,1.711.194,6.6,6.6,0,0,1,1.5.526.129.129,0,0,0,.185-.119V16.7a.132.132,0,0,0-.068-.116,6.274,6.274,0,0,0-1.728-.643,8.635,8.635,0,0,0-1.731-.175"
                                transform="translate(-14.269 -12.702)"
                                fill="#d8e7fe"
                                fillRule="evenodd"
                            />
                            <path
                                id="Path_1909"
                                dataname="Path 1909"
                                d="M15.846,26.108a9.621,9.621,0,0,0-2.079-.194.055.055,0,0,1-.054-.054q0-2.576,0-5.153a.054.054,0,0,0-.054-.054h-.388a.054.054,0,0,0-.054.054v5.845a.054.054,0,0,0,.054.054h3.782a.055.055,0,0,0,.021-.105,6.635,6.635,0,0,0-1.227-.394"
                                transform="translate(-10.645 -16.636)"
                                fill="#d8e7fe"
                                fillRule="evenodd"
                            />
                            <path
                                id="Path_1910"
                                dataname="Path 1910"
                                d="M41.7,26.108a6.648,6.648,0,0,0-1.212.387.058.058,0,0,0,.022.112H44.27a.058.058,0,0,0,.058-.058V20.711a.058.058,0,0,0-.058-.058h-.382a.058.058,0,0,0-.058.058v5.146a.058.058,0,0,1-.058.058,9.617,9.617,0,0,0-2.076.194"
                                transform="translate(-32.582 -16.636)"
                                fill="#d8e7fe"
                                fillRule="evenodd"
                            />
                            <path
                                id="Path_1911"
                                dataname="Path 1911"
                                d="M41.352,15.769v5.7a.129.129,0,0,1-.126.13,8.755,8.755,0,0,0-1.708.194,6.588,6.588,0,0,0-1.5.526.129.129,0,0,1-.184-.119V16.7a.133.133,0,0,1,.067-.116,6.256,6.256,0,0,1,1.725-.643,8.6,8.6,0,0,1,1.727-.175"
                                transform="translate(-30.474 -12.702)"
                                fill="#d8e7fe"
                                fillRule="evenodd"
                            />
                            <path
                                id="Path_1912"
                                dataname="Path 1912"
                                d="M21.445,23.451a4.41,4.41,0,0,1,1.8.422c.194.091.364-.2.169-.289a4.833,4.833,0,0,0-1.97-.468.168.168,0,0,0,0,.335"
                                transform="translate(-17.144 -18.62)"
                                fill="#0b6ffa"
                            />
                            <path
                                id="Path_1913"
                                dataname="Path 1913"
                                d="M21.445,29.627a4.41,4.41,0,0,1,1.8.422c.194.091.364-.2.169-.289a4.834,4.834,0,0,0-1.97-.468.168.168,0,0,0,0,.335"
                                transform="translate(-17.144 -23.594)"
                                fill="#0b6ffa"
                            />
                            <path
                                id="Path_1914"
                                dataname="Path 1914"
                                d="M21.445,35.8a4.41,4.41,0,0,1,1.8.422c.194.091.364-.2.169-.289a4.833,4.833,0,0,0-1.97-.468.168.168,0,0,0,0,.335"
                                transform="translate(-17.144 -28.569)"
                                fill="#0b6ffa"
                            />
                            <path
                                id="Path_1915"
                                dataname="Path 1915"
                                d="M42.892,23.116a4.833,4.833,0,0,0-1.97.468c-.195.092-.025.381.169.289a4.41,4.41,0,0,1,1.8-.422.168.168,0,0,0,0-.335"
                                transform="translate(-32.893 -18.62)"
                                fill="#0b6ffa"
                            />
                            <path
                                id="Path_1916"
                                dataname="Path 1916"
                                d="M42.892,29.292a4.833,4.833,0,0,0-1.97.468c-.195.092-.025.381.169.289a4.41,4.41,0,0,1,1.8-.422.168.168,0,0,0,0-.335"
                                transform="translate(-32.893 -23.594)"
                                fill="#0b6ffa"
                            />
                            <path
                                id="Path_1917"
                                dataname="Path 1917"
                                d="M42.892,35.468a4.833,4.833,0,0,0-1.97.468c-.195.092-.025.381.169.289a4.41,4.41,0,0,1,1.8-.422.168.168,0,0,0,0-.335"
                                transform="translate(-32.893 -28.569)"
                                fill="#0b6ffa"
                            />
                        </g>
                    </svg>
                );
            case "audio":
                return (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        width="14.316"
                        height="13.597"
                        viewBox="0 0 14.316 13.597"
                    >
                        <defs>
                            <clipPath id="clip-path">
                                <rect
                                    id="Rectangle_1567"
                                    dataname="Rectangle 1567"
                                    width="14.316"
                                    height="13.597"
                                    transform="translate(0 0)"
                                    fill="none"
                                />
                            </clipPath>
                        </defs>
                        <g
                            id="Ikona_audiobook_01"
                            dataname="Ikona audiobook 01"
                            transform="translate(0 0)"
                        >
                            <g
                                id="Group_1069"
                                dataname="Group 1069"
                                transform="translate(0 0)"
                                clipPath="url(#clip-path)"
                            >
                                <path
                                    id="Path_1902"
                                    dataname="Path 1902"
                                    d="M12.636,2.423A6.566,6.566,0,0,1,11.765,12a7.422,7.422,0,0,1-10.085-.828A6.565,6.565,0,0,1,2.551,1.6a7.422,7.422,0,0,1,10.085.828"
                                    transform="translate(0 0)"
                                    fill="#0b6ffa"
                                />
                                <path
                                    id="Path_1903"
                                    dataname="Path 1903"
                                    d="M31.457,21.3v-.018q0-.057,0-.114a.135.135,0,0,0-.171-.124,3.787,3.787,0,0,0-.843.439q-.423.254-.851.5-.869.5-1.757.972a.13.13,0,0,0-.045.038.11.11,0,0,0-.056.1v2.671a.118.118,0,0,0,.1.119,18.9,18.9,0,0,0,1.831.917.143.143,0,0,0,.163-.033l.311.19a.127.127,0,0,0,.017.006.126.126,0,0,0,.057.1l.888.565a.119.119,0,0,0,.086.017v0a.111.111,0,0,0-.025.005.1.1,0,0,0-.043.026.1.1,0,0,0-.028.041.092.092,0,0,0-.011.049l0,.034a.126.126,0,0,0,.035.056l.027.02a.139.139,0,0,0,.054.014.108.108,0,0,0,.017.012.158.158,0,0,0,.073.019.149.149,0,0,0,.042,0h0l.032-.013h0a.15.15,0,0,0,.032-.024.165.165,0,0,0,.042-.071.083.083,0,0,0,0-.009c0-.011,0-.023,0-.034a.11.11,0,0,0,.005-.024V21.73a.122.122,0,0,0,.009-.045l-.009-.39"
                                    transform="translate(-23.307 -17.677)"
                                    fill="#d8e7fe"
                                />
                                <rect
                                    id="Rectangle_1565"
                                    dataname="Rectangle 1565"
                                    width="3.662"
                                    height="0.263"
                                    transform="matrix(0.87, -0.494, 0.494, 0.87, 4.63, 5.296)"
                                    fill="#d8e7fe"
                                />
                                <rect
                                    id="Rectangle_1566"
                                    dataname="Rectangle 1566"
                                    width="0.263"
                                    height="3.748"
                                    transform="matrix(0.527, -0.85, 0.85, 0.527, 4.626, 8.193)"
                                    fill="#d8e7fe"
                                />
                                <path
                                    id="Path_1904"
                                    dataname="Path 1904"
                                    d="M15.355,36.01H14.235a.22.22,0,0,1-.22-.22V33.382a.22.22,0,0,1,.22-.22h1.119a.22.22,0,0,1,.22.22V35.79a.22.22,0,0,1-.22.22"
                                    transform="translate(-11.778 -27.87)"
                                    fill="#d8e7fe"
                                />
                                <path
                                    id="Path_1905"
                                    dataname="Path 1905"
                                    d="M67.827,23.721a3.339,3.339,0,0,1,.824,2.261,4.047,4.047,0,0,1-.535,2.543c-.232.356.337.685.568.332a4.671,4.671,0,0,0,.62-2.941,3.962,3.962,0,0,0-1.012-2.661c-.308-.289-.774.175-.465.465"
                                    transform="translate(-56.917 -19.468)"
                                    fill="#d8e7fe"
                                />
                                <path
                                    id="Path_1906"
                                    dataname="Path 1906"
                                    d="M60.484,30.449a3.709,3.709,0,0,1,.04,2.969.232.232,0,0,0,.161.283.236.236,0,0,0,.283-.161,4.153,4.153,0,0,0-.086-3.324c-.124-.269-.521-.035-.4.232"
                                    transform="translate(-50.812 -25.294)"
                                    fill="#d8e7fe"
                                />
                            </g>
                        </g>
                    </svg>
                );
            default:
                return "";
        }
    };

    return icon(type);
};

export default IconMultibook;
