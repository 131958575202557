import { useState, useEffect, useRef, useContext } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";

import { StoreContext } from "../../../context/Store";

import useActionLoading from "../../../hooks/useActionLoading";

import validator from "../../../functions/validator";
import scrollTo from "../../../functions/scrollTo";
import routeLoadingState from "../../../functions/routeLoadingState";

import Title from "../../../components/headings/Title";
import Input from "../../../components/inputs/Input";
import InputMasked from "../../../components/inputs/InputMasked";
import Button from "../../../components/buttons/Button";

import DashboardContainer from "../../../components/containers/dashboard/DashboardContainer";
import DashboardGroup from "../../../components/containers/dashboard/DashboardGroup";
import AccountData from "../../../components/containers/dashboard/account/AccountData";

const ParentAccountEdit = ({ routeMatches, routeSetters }) => {
    const { setRouteData, setRouteIsLoading } = routeSetters;

    const { isActionLoading, setIsActionLoading } = useActionLoading();

    const history = useHistory();

    const queryClient = useQueryClient();
    const { api } = useContext(StoreContext);

    /* CACHE HISTORY STATE TO PREVENT CRITICAL ERROR */
    const historyStateCache = useRef(history.location?.state);

    /* GET ACCOUNT DATA */
    const {
        data: accountData,
        isFetched: accountIsFetched,
        isLoading: accountIsLoading,
    } = useQuery("account", () => api.get(`/account`), {
        staleTime: Infinity,
        retry: false,
        refetchOnWindowFocus: false,
    });

    const { mutate: accountUpdateMutate } = useMutation(
        (data) =>
            api.post(`/account/update`, {
                account_type: historyStateCache.current.billingTab,
                ...data,
            }),
        {
            onMutate: () => setIsActionLoading(true),
            onSuccess: () => {
                queryClient.invalidateQueries("account");
                history.goBack();
            },
            onError: () => setIsActionLoading(false),
        }
    );

    /* BIRTHMAXDATE CURRENT - 1 */
    const birthMaxDate = new Date(
        new Date().setFullYear(new Date().getFullYear() - 1)
    ).getFullYear();

    /* INPUT VALUES */
    const [inputValue, setInputValue] = useState({
        name: "",
        surname: "",
        email: "",
        phone: "",
        zipCode: "",
        birthdate: "",
    });

    /* INPUT VALIDATORS */
    const [inputValidator, setInputValidator] = useState({
        name: {
            response: "",
        },
        surname: {
            response: "",
        },
        email: {
            response: "",
        },
        phone: {
            response: "",
        },
        zipCode: {
            response: "",
        },
        birthdate: {
            response: "",
        },
    });

    /* BILLING INPUT VALUES */
    const [billingInputValue, setBillingInputValue] = useState({
        private_name: "",
        private_surname: "",
        private_zipCode: "",
        private_city: "",
        private_address: "",

        company_name: "",
        nip: "",
        company_zipCode: "",
        company_city: "",
        company_address: "",
    });

    /* BILLING INPUT VALIDATORS */
    const [billingInputValidator, setBillingInputValidator] = useState({
        private_name: {
            response: "",
        },
        private_surname: {
            response: "",
        },
        private_zipCode: {
            response: "",
        },
        private_city: {
            response: "",
        },
        private_address: {
            response: "",
        },

        company_name: {
            response: "",
        },
        nip: {
            response: "",
        },
        company_zipCode: {
            response: "",
        },
        company_city: {
            response: "",
        },
        company_address: {
            response: "",
        },
    });

    /* INPUT ON CHANGE HANDLER */
    const inputOnChangeHandler = (e, key, minLength) => {
        setInputValue({ ...inputValue, [key]: e });

        if (minLength) {
            if (minLength <= e.length) {
                setInputValidator({
                    ...inputValidator,
                    [key]: {
                        response: "success",
                        errorMessage: "",
                    },
                });
            } else {
                setInputValidator({
                    ...inputValidator,
                    [key]: {
                        response: "error",
                        errorMessage: "",
                    },
                });
            }
        } else {
            setInputValidator({
                ...inputValidator,
                [key]: {
                    response: "success",
                    errorMessage: "",
                },
            });
        }
    };

    /* BILLING INPUT ON CHANGE HANDLER */
    const billingInputOnChangeHandler = (e, key, minLength) => {
        setBillingInputValue({ ...billingInputValue, [key]: e });

        if (minLength) {
            if (minLength <= e.length) {
                setBillingInputValidator({
                    ...billingInputValidator,
                    [key]: {
                        response: "success",
                        errorMessage: "",
                    },
                });
            } else {
                setBillingInputValidator({
                    ...billingInputValidator,
                    [key]: {
                        response: "error",
                        errorMessage: "",
                    },
                });
            }
        } else {
            setBillingInputValidator({
                ...billingInputValidator,
                [key]: {
                    response: "success",
                    errorMessage: "",
                },
            });
        }
    };

    /* INPUT MASKED ON CHANGE HANDLER */
    const inputMaskedOnChangeHandler = (e, key) => {
        setInputValue({ ...inputValue, [key]: e.value });

        setInputValidator({
            ...inputValidator,
            [key]: {
                response: e.response,
                errorMessage: "",
            },
        });
    };

    /* E-MAIL ON CHANGE HANDLER */
    const emailOnChangeHandler = (e) => {
        if (e.length > 0) {
            validator("email", e)
                ? setInputValidator({
                      ...inputValidator,
                      email: {
                          response: "success",
                      },
                  })
                : setInputValidator({
                      ...inputValidator,
                      email: {
                          response: "error",
                      },
                  });
        } else {
            setInputValidator({
                ...inputValidator,
                email: {
                    response: "error",
                },
            });
        }

        setInputValue({ ...inputValue, email: e });
    };

    // VALIDATE LENGTH OF INPUTS ONCLICK

    const saveDataOnClickHandler = () => {
        if (
            historyStateCache.current?.billingTab === "private" &&
            billingInputValue.private_name.length === 0
        ) {
            setBillingInputValidator({
                ...billingInputValidator,
                private_name: { response: "error" },
            });
            scrollTo("#private_name");
            return;
        }
        if (
            historyStateCache.current?.billingTab === "private" &&
            billingInputValue.private_surname.length === 0
        ) {
            setBillingInputValidator({
                ...billingInputValidator,
                private_surname: { response: "error" },
            });
            scrollTo("#private_surname");
            return;
        }
        if (
            historyStateCache.current?.billingTab === "private" &&
            billingInputValue.private_zipCode.length === 0
        ) {
            setBillingInputValidator({
                ...billingInputValidator,
                private_zipCode: { response: "error" },
            });
            scrollTo("#private_zipCode");
            return;
        }
        if (
            historyStateCache.current?.billingTab === "private" &&
            billingInputValue.private_city.length === 0
        ) {
            setBillingInputValidator({
                ...billingInputValidator,
                private_city: { response: "error" },
            });
            scrollTo("#private_city");
            return;
        }
        if (
            historyStateCache.current?.billingTab === "private" &&
            billingInputValue.private_address.length === 0
        ) {
            setBillingInputValidator({
                ...billingInputValidator,
                private_address: { response: "error" },
            });
            scrollTo("#private_address");
            return;
        }

        if (
            historyStateCache.current?.billingTab === "company" &&
            billingInputValue.company_name.length === 0
        ) {
            setBillingInputValidator({
                ...billingInputValidator,
                company_name: { response: "error" },
            });
            scrollTo("#company_name");
            return;
        }
        if (
            historyStateCache.current?.billingTab === "company" &&
            billingInputValue.nip.length === 0
        ) {
            setBillingInputValidator({
                ...billingInputValidator,
                nip: { response: "error" },
            });
            scrollTo("#nip");
            return;
        }
        if (
            historyStateCache.current?.billingTab === "company" &&
            billingInputValue.company_zipCode.length === 0
        ) {
            setBillingInputValidator({
                ...billingInputValidator,
                company_zipCode: { response: "error" },
            });
            scrollTo("#company_zipCode");
            return;
        }
        if (
            historyStateCache.current?.billingTab === "company" &&
            billingInputValue.company_city.length === 0
        ) {
            setBillingInputValidator({
                ...billingInputValidator,
                company_city: { response: "error" },
            });
            scrollTo("#company_city");
            return;
        }
        if (
            historyStateCache.current?.billingTab === "company" &&
            billingInputValue.company_address.length === 0
        ) {
            setBillingInputValidator({
                ...billingInputValidator,
                company_address: { response: "error" },
            });
            scrollTo("#company_address");
            return;
        }

        accountUpdateMutate({
            ...inputValue,
            ...billingInputValue,
        });
    };

    /* SET ACCOUNT DATA */
    const isFormDataSet = useRef(false);

    useEffect(() => {
        if (accountData && !accountIsLoading && !isFormDataSet.current) {
            isFormDataSet.current = true;

            /* ACCOUNT DATA */
            const name = accountData.data.user.name;
            const surname = accountData.data.user.surname;
            const email = accountData.data.user.email;
            const phone = accountData.data.user.phone;
            const zipCode = accountData.data.user.zipCode;
            const birthdate = accountData.data.user.birthdate;

            setInputValue({
                name,
                surname,
                email,
                phone,
                zipCode,
                birthdate,
            });

            setInputValidator({
                name: {
                    response: name ? "success" : "error",
                },
                surname: {
                    response: surname ? "success" : "error",
                },
                email: {
                    response: email ? "success" : "error",
                },
                phone: {
                    response: phone ? "success" : "error",
                },
                zipCode: {
                    response: zipCode ? "success" : "error",
                },
                birthdate: {
                    response: birthdate ? "success" : "error",
                },
            });

            /* BILLING DATA */
            const private_name = accountData.data.invoice.name;
            const private_surname = accountData.data.invoice.surname;
            const private_zipCode = accountData.data.invoice.zipCode;
            const private_city = accountData.data.invoice.city;
            const private_address = accountData.data.invoice.address;

            const company_name = accountData.data.invoice.company_name;
            const nip = accountData.data.invoice.nip;
            const company_zipCode = accountData.data.invoice.company_zipCode;
            const company_city = accountData.data.invoice.company_city;
            const company_address = accountData.data.invoice.company_address;

            setBillingInputValue({
                private_name: private_name ?? "",
                private_surname: private_surname ?? "",
                private_zipCode: private_zipCode ?? "",
                private_city: private_city ?? "",
                private_address: private_address ?? "",

                company_name: company_name ?? "",
                nip: nip ?? "",
                company_zipCode: company_zipCode ?? "",
                company_city: company_city ?? "",
                company_address: company_address ?? "",
            });

            setBillingInputValidator({
                private_name: {
                    response: private_name ? "success" : "error",
                },
                private_surname: {
                    response: private_surname ? "success" : "error",
                },
                private_zipCode: {
                    response: private_zipCode ? "success" : "error",
                },
                private_city: {
                    response: private_city ? "success" : "error",
                },
                private_address: {
                    response: private_address ? "success" : "error",
                },

                company_name: {
                    response: company_name ? "success" : "error",
                },
                nip: {
                    response: nip ? "success" : "error",
                },
                company_zipCode: {
                    response: company_zipCode ? "success" : "error",
                },
                company_city: {
                    response: company_city ? "success" : "error",
                },
                company_address: {
                    response: company_address ? "success" : "error",
                },
            });
        }
    }, [accountData, accountIsLoading]);

    /* ENABLE SUBMIT IF VALIDATION IS CORRECT */
    const formValidation =
        inputValidator.name.response === "success" &&
        inputValidator.surname.response === "success" &&
        inputValidator.email.response === "success" &&
        inputValidator.phone.response === "success" &&
        inputValidator.zipCode.response === "success" &&
        inputValidator.birthdate.response === "success"
            ? true
            : false;

    const formBillingValidation =
        (historyStateCache.current.billingTab === "private" &&
            billingInputValidator.private_name.response === "success" &&
            billingInputValidator.private_surname.response === "success" &&
            billingInputValidator.private_zipCode.response === "success" &&
            billingInputValidator.private_city.response === "success" &&
            billingInputValidator.private_address.response === "success") ||
        (historyStateCache.current.billingTab === "company" &&
            billingInputValidator.company_name.response === "success" &&
            billingInputValidator.nip.response === "success" &&
            billingInputValidator.company_zipCode.response === "success" &&
            billingInputValidator.company_city.response === "success" &&
            billingInputValidator.company_address.response === "success")
            ? true
            : false;

    const formSubmitDisable =
        formValidation && formBillingValidation ? false : true;

    /* ROUTE LOADING */
    const routeIsLoading = routeLoadingState({
        fetched: [accountIsFetched],
        loading: [accountIsLoading],
    });

    /* ROUTE SETTERS */
    useEffect(() => {
        const routeData = {
            title: "EDYTUJ DANE",
        };

        if (routeMatches) {
            setRouteIsLoading(routeIsLoading);
            setRouteData(routeData);
        }
    }, [routeIsLoading, routeMatches, setRouteIsLoading, setRouteData]);

    return (
        <>
            <DashboardContainer className="accountEdit">
                {!routeIsLoading && (
                    <>
                        <AccountData
                            data={[
                                {
                                    title: "Imię",
                                    children: (
                                        <Input
                                            id="name"
                                            type="text"
                                            value={inputValue.name}
                                            classNameControl="thin font-left"
                                            isDisabled={isActionLoading}
                                            isValid={{
                                                response:
                                                    inputValidator.name
                                                        .response,
                                            }}
                                            onChangeFn={(e) =>
                                                inputOnChangeHandler(
                                                    e.currentTarget.value,
                                                    "name",
                                                    3
                                                )
                                            }
                                        />
                                    ),
                                },
                                {
                                    title: "Nazwisko",
                                    children: (
                                        <Input
                                            id="surname"
                                            type="text"
                                            value={inputValue.surname}
                                            classNameControl="thin font-left"
                                            isDisabled={isActionLoading}
                                            isValid={{
                                                response:
                                                    inputValidator.surname
                                                        .response,
                                            }}
                                            onChangeFn={(e) =>
                                                inputOnChangeHandler(
                                                    e.currentTarget.value,
                                                    "surname",
                                                    3
                                                )
                                            }
                                        />
                                    ),
                                },
                                {
                                    title: "E-mail",
                                    children: (
                                        <Input
                                            id="email"
                                            type="email"
                                            value={inputValue.email}
                                            classNameControl="thin font-left"
                                            isDisabled={isActionLoading}
                                            isValid={{
                                                response:
                                                    inputValidator.email
                                                        .response,
                                            }}
                                            onChangeFn={(e) =>
                                                emailOnChangeHandler(
                                                    e.currentTarget.value
                                                )
                                            }
                                        />
                                    ),
                                },
                                {
                                    title: "Telefon",
                                    children: (
                                        <Input
                                            id="phone"
                                            type="tel"
                                            value={inputValue.phone}
                                            maxLength={12}
                                            classNameControl="thin font-left"
                                            isDisabled={isActionLoading}
                                            isValid={{
                                                response:
                                                    inputValidator.phone
                                                        .response,
                                            }}
                                            onChangeFn={(e) =>
                                                inputOnChangeHandler(
                                                    e.currentTarget.value,
                                                    "phone",
                                                    9
                                                )
                                            }
                                        />
                                    ),
                                },
                                {
                                    title: "Kod pocztowy",
                                    children: (
                                        <InputMasked
                                            id="zipCode"
                                            key="InputMasked"
                                            classNameControl="thin font-left"
                                            value={inputValue.zipCode}
                                            placeholder="wpisz kod pocztowy"
                                            placeholderFocus="00-000"
                                            fields={[{ rows: 2 }, { rows: 3 }]}
                                            separator="-"
                                            isDisabled={isActionLoading}
                                            isValid={
                                                inputValidator.zipCode.response
                                            }
                                            onChangeFn={(e) =>
                                                inputMaskedOnChangeHandler(
                                                    e,
                                                    "zipCode"
                                                )
                                            }
                                        />
                                    ),
                                },
                                {
                                    title: "Data urodzenia",
                                    children: (
                                        <InputMasked
                                            id="birthdate"
                                            key="InputMasked"
                                            classNameControl="thin font-left"
                                            value={inputValue.birthdate}
                                            placeholder="dodaj datę urodzenia"
                                            placeholderFocus="dd-mm-rrrr"
                                            fields={[
                                                { rows: 2, min: 1, max: 31 },
                                                { rows: 2, min: 1, max: 12 },
                                                {
                                                    rows: 4,
                                                    min: 1900,
                                                    max: birthMaxDate,
                                                },
                                            ]}
                                            separator="-"
                                            isDisabled={isActionLoading}
                                            isValid={
                                                inputValidator.birthdate
                                                    .response
                                            }
                                            onChangeFn={(e) =>
                                                inputMaskedOnChangeHandler(
                                                    e,
                                                    "birthdate"
                                                )
                                            }
                                        />
                                    ),
                                },
                            ]}
                            dataKey="parentDataEdit"
                        />
                        <DashboardGroup className="billing">
                            <Title className="medium" type={2}>
                                DANE DO FAKTURY
                            </Title>

                            {historyStateCache.current.billingTab ===
                                "private" && (
                                <AccountData
                                    data={[
                                        {
                                            title: "Imię",
                                            children: (
                                                <Input
                                                    id="private_name"
                                                    type="text"
                                                    value={
                                                        billingInputValue.private_name
                                                    }
                                                    classNameControl="thin font-left"
                                                    isDisabled={isActionLoading}
                                                    isValid={{
                                                        response:
                                                            billingInputValidator
                                                                .private_name
                                                                .response,
                                                    }}
                                                    onChangeFn={(e) =>
                                                        billingInputOnChangeHandler(
                                                            e.currentTarget
                                                                .value,
                                                            "private_name",
                                                            3
                                                        )
                                                    }
                                                />
                                            ),
                                        },
                                        {
                                            title: "Nazwisko",
                                            children: (
                                                <Input
                                                    id="private_surname"
                                                    type="text"
                                                    value={
                                                        billingInputValue.private_surname
                                                    }
                                                    classNameControl="thin font-left"
                                                    isDisabled={isActionLoading}
                                                    isValid={{
                                                        response:
                                                            billingInputValidator
                                                                .private_surname
                                                                .response,
                                                    }}
                                                    onChangeFn={(e) =>
                                                        billingInputOnChangeHandler(
                                                            e.currentTarget
                                                                .value,
                                                            "private_surname",
                                                            3
                                                        )
                                                    }
                                                />
                                            ),
                                        },
                                        {
                                            title: "Kod pocztowy",
                                            children: (
                                                <InputMasked
                                                    id="private_zipCode"
                                                    key="InputMasked"
                                                    classNameControl="thin font-left"
                                                    value={
                                                        billingInputValue.private_zipCode
                                                    }
                                                    placeholder="wpisz kod pocztowy"
                                                    placeholderFocus="00-000"
                                                    fields={[
                                                        { rows: 2 },
                                                        { rows: 3 },
                                                    ]}
                                                    separator="-"
                                                    isDisabled={isActionLoading}
                                                    isValid={
                                                        billingInputValidator
                                                            .private_zipCode
                                                            .response
                                                    }
                                                    onChangeFn={(e) => {
                                                        setBillingInputValue(
                                                            (values) => ({
                                                                ...values,
                                                                private_zipCode:
                                                                    e.value,
                                                            })
                                                        );

                                                        setBillingInputValidator(
                                                            (validators) => ({
                                                                ...validators,
                                                                private_zipCode:
                                                                    {
                                                                        response:
                                                                            e.response,
                                                                    },
                                                            })
                                                        );
                                                    }}
                                                />
                                            ),
                                        },
                                        {
                                            title: "Miasto",
                                            children: (
                                                <Input
                                                    id="private_city"
                                                    type="text"
                                                    value={
                                                        billingInputValue.private_city
                                                    }
                                                    classNameControl="thin font-left"
                                                    isDisabled={isActionLoading}
                                                    isValid={{
                                                        response:
                                                            billingInputValidator
                                                                .private_city
                                                                .response,
                                                    }}
                                                    onChangeFn={(e) =>
                                                        billingInputOnChangeHandler(
                                                            e.currentTarget
                                                                .value,
                                                            "private_city",
                                                            3
                                                        )
                                                    }
                                                />
                                            ),
                                        },
                                        {
                                            title: "Ulica",
                                            children: (
                                                <Input
                                                    id="private_address"
                                                    type="text"
                                                    value={
                                                        billingInputValue.private_address
                                                    }
                                                    classNameControl="thin font-left"
                                                    isDisabled={isActionLoading}
                                                    isValid={{
                                                        response:
                                                            billingInputValidator
                                                                .private_address
                                                                .response,
                                                    }}
                                                    onChangeFn={(e) =>
                                                        billingInputOnChangeHandler(
                                                            e.currentTarget
                                                                .value,
                                                            "private_address",
                                                            3
                                                        )
                                                    }
                                                />
                                            ),
                                        },
                                    ]}
                                    dataKey="privateBillingDataEdit"
                                />
                            )}
                            {historyStateCache.current.billingTab ===
                                "company" && (
                                <AccountData
                                    data={[
                                        {
                                            title: "Nazwa firmy",
                                            children: (
                                                <Input
                                                    id="company_name"
                                                    type="text"
                                                    value={
                                                        billingInputValue.company_name
                                                    }
                                                    classNameControl="thin font-left"
                                                    isDisabled={isActionLoading}
                                                    isValid={{
                                                        response:
                                                            billingInputValidator
                                                                .company_name
                                                                .response,
                                                    }}
                                                    onChangeFn={(e) =>
                                                        billingInputOnChangeHandler(
                                                            e.currentTarget
                                                                .value,
                                                            "company_name",
                                                            3
                                                        )
                                                    }
                                                />
                                            ),
                                        },
                                        {
                                            title: "NIP",
                                            children: (
                                                <Input
                                                    id="nip"
                                                    type="text"
                                                    value={
                                                        billingInputValue.nip
                                                    }
                                                    classNameControl="thin font-left"
                                                    isDisabled={isActionLoading}
                                                    isValid={{
                                                        response:
                                                            billingInputValidator
                                                                .nip.response,
                                                    }}
                                                    onChangeFn={(e) =>
                                                        billingInputOnChangeHandler(
                                                            e.currentTarget
                                                                .value,
                                                            "nip",
                                                            10
                                                        )
                                                    }
                                                />
                                            ),
                                        },
                                        {
                                            title: "Kod pocztowy",
                                            children: (
                                                <InputMasked
                                                    id="company_zipCode"
                                                    key="InputMasked"
                                                    classNameControl="thin font-left"
                                                    value={
                                                        billingInputValue.company_zipCode
                                                    }
                                                    placeholder="wpisz kod pocztowy"
                                                    placeholderFocus="00-000"
                                                    fields={[
                                                        { rows: 2 },
                                                        { rows: 3 },
                                                    ]}
                                                    separator="-"
                                                    isDisabled={isActionLoading}
                                                    isValid={
                                                        billingInputValidator
                                                            .company_zipCode
                                                            .response
                                                    }
                                                    onChangeFn={(e) => {
                                                        setBillingInputValue(
                                                            (values) => ({
                                                                ...values,
                                                                company_zipCode:
                                                                    e.value,
                                                            })
                                                        );

                                                        setBillingInputValidator(
                                                            (validators) => ({
                                                                ...validators,
                                                                company_zipCode:
                                                                    {
                                                                        response:
                                                                            e.response,
                                                                    },
                                                            })
                                                        );
                                                    }}
                                                />
                                            ),
                                        },
                                        {
                                            title: "Miasto",
                                            children: (
                                                <Input
                                                    id="company_city"
                                                    type="text"
                                                    value={
                                                        billingInputValue.company_city
                                                    }
                                                    classNameControl="thin font-left"
                                                    isDisabled={isActionLoading}
                                                    isValid={{
                                                        response:
                                                            billingInputValidator
                                                                .company_city
                                                                .response,
                                                    }}
                                                    onChangeFn={(e) =>
                                                        billingInputOnChangeHandler(
                                                            e.currentTarget
                                                                .value,
                                                            "company_city",
                                                            3
                                                        )
                                                    }
                                                />
                                            ),
                                        },
                                        {
                                            title: "Ulica",
                                            children: (
                                                <Input
                                                    id="company_address"
                                                    type="text"
                                                    value={
                                                        billingInputValue.company_address
                                                    }
                                                    classNameControl="thin font-left"
                                                    isDisabled={isActionLoading}
                                                    isValid={{
                                                        response:
                                                            billingInputValidator
                                                                .company_address
                                                                .response,
                                                    }}
                                                    onChangeFn={(e) =>
                                                        billingInputOnChangeHandler(
                                                            e.currentTarget
                                                                .value,
                                                            "company_address",
                                                            3
                                                        )
                                                    }
                                                />
                                            ),
                                        },
                                    ]}
                                    dataKey="companyBillingDataEdit"
                                />
                            )}
                        </DashboardGroup>
                        <div className="AccountData__ButtonGroup">
                            <Button
                                className="medium-2 disabled-alt"
                                isDisabled={
                                    formSubmitDisable || isActionLoading
                                }
                                isLoading={isActionLoading}
                                onClickFn={saveDataOnClickHandler}
                            >
                                Zapisz
                            </Button>
                            <Button
                                className="medium-2 gray disabled-alt"
                                isDisabled={isActionLoading}
                                onClickFn={() => history.goBack()}
                            >
                                Anuluj
                            </Button>
                        </div>
                    </>
                )}
            </DashboardContainer>
        </>
    );
};

export default ParentAccountEdit;
