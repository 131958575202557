import Button from "../../../buttons/Button";

const AccountDataItem = ({ title, text, button, vertical, children }) => {
    return (
        <>
            <div
                className={
                    "AccountData__Item" +
                    (children ? " _input" : "") +
                    (vertical ? " _vertical" : "")
                }
            >
                <div className="AccountData__Title">{title}:</div>
                {children || text || button ? (
                    <div
                        className={
                            "AccountData__Content" +
                            (button && !children ? " _button" : "")
                        }
                    >
                        {children ? (
                            children
                        ) : button ? (
                            <>
                                <div className="AccountData__Text">{text}</div>
                                <div className="AccountData__Button">
                                    <Button
                                        className="blank color"
                                        onClickFn={button.onClickFn}
                                    >
                                        {button.text}
                                    </Button>
                                </div>
                            </>
                        ) : (
                            text
                        )}
                    </div>
                ) : (
                    ""
                )}
            </div>
        </>
    );
};

export default AccountDataItem;
