import getPhrasesOccurrences from "./getPhrasesOccurrences";
import checkIfNextCharIsValid from "./checkIfNextCharIsValid";
import parseMathTagGap from "../parseMathTagGap";
import parseMathText from "../parseMathText";

import ReaderFlashcard from "../../components/ReaderFlashcard";
import ReaderMath from "../../components/ReaderMath";

const getMapFromString = (string, phrase) => {
    const stringArray = parseMathTagGap(string).split(" ");
    const stringArrayLength = stringArray.length;

    const stringMap = [];
    const phrasesMap = [];

    let stringIndex = 1;
    let stringTemp = "";

    const addToStringTemp = (string, isLast) => {
        if (isLast) {
            stringTemp += string;
        } else {
            stringTemp += string + " ";
        }
    };

    for (const stringItem of stringArray) {
        if (
            (stringItem.toLowerCase().indexOf(phrase.toLowerCase()) !== -1 &&
                stringItem.toLowerCase().indexOf(phrase.toLowerCase()) === 0) ||
            stringItem.trim().indexOf("[math]") !== -1
        ) {
            if (
                checkIfNextCharIsValid(stringItem.charAt(phrase.length)) ||
                stringItem.toLowerCase() === phrase.toLowerCase() ||
                stringItem.trim().indexOf("[math]") !== -1
            ) {
                stringMap.push(stringTemp);

                stringTemp = "";

                if (
                    stringItem.length !== phrase.length &&
                    stringItem.trim().indexOf("[math]") === -1
                ) {
                    addToStringTemp(
                        stringItem.substr(phrase.length),
                        stringArrayLength === stringIndex
                    );

                    phrasesMap.push(stringItem.substr(0, phrase.length));
                } else {
                    phrasesMap.push(stringItem);
                }
            } else {
                addToStringTemp(stringItem, stringArrayLength === stringIndex);
            }
        } else {
            addToStringTemp(stringItem, stringArrayLength === stringIndex);
        }

        stringIndex++;
    }

    if (stringTemp) {
        stringMap.push(stringTemp);
    }

    return [stringMap, phrasesMap];
};

const checkFirstSpecialChar = (string) => {
    const chars = [".", ",", "?", ":", ";", "/", "!"];

    let isSpecial = false;

    for (const char of chars) {
        if (char === string.charAt(0)) {
            isSpecial = true;
        }
    }

    return isSpecial;
};

const getMathOccurrences = (string) => {
    let count = 0;
    let position = string.indexOf("[math]");

    while (position > -1) {
        count++;

        position = string.indexOf("[math]", ++position);
    }

    return count;
};

const parseFlashcardPhrase = (string, phrase, data) => {
    const {
        content: flashcardContent,
        key: flashcardKey,
        index: flashcardIndex,
        props: flashcardProps,
    } = data;

    let map = [];
    let index = 0;
    let position = 1;
    let occurrences = getPhrasesOccurrences(
        string.toLowerCase(),
        phrase.toLowerCase()
    );
    let occurrencesMath = getMathOccurrences(string.toLowerCase());

    if (occurrences > 0 || occurrencesMath > 0) {
        map = getMapFromString(string, phrase)[0];

        const phrases = getMapFromString(string, phrase)[1];

        if (map.length > 1) {
            const newMap = [];
            let mapIndex = 0;

            for (const mapItem of map) {
                if (mapIndex !== 0) {
                    if (!checkFirstSpecialChar(mapItem)) {
                        newMap.push(" " + mapItem);
                    } else {
                        newMap.push(mapItem);
                    }
                } else {
                    newMap.push(mapItem);
                }

                mapIndex++;
            }

            map = newMap;
        }

        if (occurrencesMath === 0) {
            for (let i = 0; i < occurrences; i++) {
                map.splice(
                    position,
                    0,
                    <ReaderFlashcard
                        key={
                            flashcardKey +
                            "_" +
                            phrase +
                            "-" +
                            flashcardIndex +
                            index.toString()
                        }
                        content={flashcardContent}
                        {...flashcardProps}
                    >
                        {phrases[i]}
                    </ReaderFlashcard>
                );

                index++;
                position = position + 2;
            }
        } else {
            for (let i = 0; i < occurrencesMath; i++) {
                const math = parseMathText(phrases[i])
                    .replaceAll("[math]", "")
                    .replaceAll("[/math]", "");

                map.splice(
                    position,
                    0,
                    <ReaderMath
                        key={
                            flashcardKey +
                            "_" +
                            math +
                            "-" +
                            flashcardIndex +
                            index.toString()
                        }
                        content={math}
                        inline={true}
                    />
                );

                index++;
                position = position + 2;
            }
        }
    } else {
        map.push(string);
    }

    return map;
};

export default parseFlashcardPhrase;
