import { useRef, useEffect, useContext } from "react";
import { useMutation, useQuery } from "react-query";

import { StoreContext } from "../../../context/Store";

import routeLoadingState from "../../../functions/routeLoadingState";

import DashboardContainer from "../../../components/containers/dashboard/DashboardContainer";
import Subjects from "../../../components/containers/dashboard/subjects/Subjects";

import modalDoodleImage_1 from "../../../assets/images/dashboard/modal-doodles/questionmark.svg";
import modalDoodleImage_2 from "../../../assets/images/dashboard/modal-doodles/sad.svg";
import modalDoodleImage_3 from "../../../assets/images/dashboard/modal-doodles/happy_3.svg";

const MySubjects = ({ routeMatches, routeSetters, modalSetters }) => {
    const { setRouteData, setRouteIsLoading } = routeSetters;
    const {
        setModalData,
        setModalActive,
        setModalApiResponse,
        setModalApiLoading,
    } = modalSetters;

    const { api } = useContext(StoreContext);

    /* GET TEACHER SUBJECTS */
    const {
        data: teacherSubjectsData,
        isFetched: teacherSubjectsIsFetched,
        isLoading: teacherSubjectsIsLoading,
        isRefetching: teacherSubjectsIsRefetching,
        refetch: teacherSubjectsRefetch,
    } = useQuery("teacherSubjects", () => api.get(`/teacher/subjects`), {
        staleTime: Infinity,
        retry: false,
        refetchOnWindowFocus: false,
    });

    /* ADD TEACHER SUBJECT */
    const { mutate: addTeacherSubjectMutate } = useMutation(
        (data) => api.post(`/teacher/add_subject`, data),
        {
            onMutate: () => setModalApiLoading(true),
            onSuccess: () => {
                teacherSubjectsRefetch();

                setModalApiResponse(true);
            },
        }
    );

    /* DELETE TEACHER SUBJECT */
    const { mutate: deleteTeacherSubjectMutate } = useMutation(
        (data) => api.post(`/teacher/delete_subject`, data),
        {
            onMutate: () => setModalApiLoading(true),
            onSuccess: () => {
                teacherSubjectsRefetch();

                setModalApiResponse(true);
            },
        }
    );

    /* ROUTE LOADING */
    const routeIsLoading = routeLoadingState({
        fetched: [teacherSubjectsIsFetched],
        loading: [teacherSubjectsIsLoading],
    });

    /* ROUTE SETTERS */
    useEffect(() => {
        const routeData = {
            title: "MOJE PRZEDMIOTY",
        };

        if (routeMatches) {
            setRouteIsLoading(routeIsLoading);
            setRouteData(routeData);
        }
    }, [routeIsLoading, routeMatches, setRouteIsLoading, setRouteData]);

    /* MODAL SUBJECTS DATA */
    const modalSubjectsAddData = useRef({});
    const modalSubjectsRemoveData = useRef({});

    useEffect(() => {
        if (!routeIsLoading) {
            modalSubjectsAddData.current = {
                select: true,
                close: true,
                data: [
                    {
                        className: "_font--large",
                        query: {
                            url: "/subject_list",
                            queryKey: "modalSubjectlList",
                            selectedData: teacherSubjectsData.data,
                            dataKey: {
                                id: "idSubject",
                                name: "name",
                            },
                        },
                        actionDispatch: {
                            dispatch: addTeacherSubjectMutate,
                        },
                    },
                    {
                        title: {
                            obj: (
                                <p>
                                    Przedmiot [subject_inject]
                                    <br />
                                    został dodany
                                </p>
                            ),
                            injectKey: "[subject_inject]",
                            isCenter: true,
                        },
                        img: {
                            obj: modalDoodleImage_3,
                            size: "245px",
                        },
                        action: {
                            obj: {
                                text: "Przejdź dalej",
                            },
                        },
                    },
                ],
            };

            modalSubjectsRemoveData.current = {
                data: [
                    {
                        title: {
                            obj: undefined,
                        },
                        img: {
                            obj: modalDoodleImage_1,
                            size: "161px",
                        },
                        action: {
                            obj: [
                                {
                                    text: "Tak, chcę wypisać",
                                    important: true,
                                    onClick: undefined,
                                },
                                {
                                    text: "Nie, nie chcę wypisać",
                                    onClick: () => setModalActive(false),
                                },
                            ],
                            key: "childrenSchoolRemove",
                        },
                    },
                    {
                        title: {
                            obj: undefined,
                            isCenter: true,
                        },
                        img: {
                            obj: modalDoodleImage_2,
                            size: "184px",
                        },
                        action: {
                            obj: {
                                text: "Przejdź dalej",
                            },
                        },
                    },
                ],
            };
        }
    }, [
        routeIsLoading,
        setModalActive,
        setModalApiLoading,
        setModalApiResponse,
        addTeacherSubjectMutate,
        teacherSubjectsData,
    ]);

    return (
        <>
            <DashboardContainer className="subjects">
                {!routeIsLoading && (
                    <>
                        <Subjects
                            data={teacherSubjectsData.data}
                            onClickSubject={(data) => {
                                modalSubjectsRemoveData.current.data[0].action.obj[0].onClick =
                                    () =>
                                        deleteTeacherSubjectMutate({
                                            idSubject: data.id,
                                        });
                                modalSubjectsRemoveData.current.data[0].title.obj =
                                    (
                                        <p>
                                            Czy na pewno chcesz usunąć
                                            <br />
                                            przedmiot {data.name}?
                                        </p>
                                    );
                                modalSubjectsRemoveData.current.data[1].title.obj =
                                    (
                                        <p>
                                            Przedmiot {data.name}
                                            <br />
                                            został usunięty
                                        </p>
                                    );

                                setModalData(modalSubjectsRemoveData.current);
                            }}
                            onClickAdd={() =>
                                setModalData(modalSubjectsAddData.current)
                            }
                        />
                    </>
                )}
            </DashboardContainer>
        </>
    );
};

export default MySubjects;
