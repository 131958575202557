import RouteMenu from "./RouteMenu";
import RouteButton from "./RouteButton";

const RouteAction = ({action, dropdown, isDisabled, isShown, isAnimating}) => {
    return (
        <>
            <div
                className={
                    "RouteAction" +
                    (isShown ? " _shown" : "") +
                    (isAnimating ? " _animating" : "")
                }
            >
                <div
                    className={
                        "RouteAction__Track" +
                        (isAnimating ? " _animating" : "")
                    }
                >
                    {isAnimating && (
                        <div className="RouteAction__Item _prev">
                            {action.prev.type ===
                                "dropdown" && (
                                <RouteMenu
                                    data={action.prev}
                                    dropdown={{
                                        state: dropdown.state,
                                        setState: dropdown.setState
                                    }}
                                    isDisabled={isDisabled}
                                />
                            )}
                            {action.prev.type ===
                                "button" && (
                                <RouteButton
                                    props={
                                        action.prev.props
                                    }
                                    isDisabled={isDisabled}
                                />
                            )}
                        </div>
                    )}
                    <div
                        className={
                            "RouteAction__Item" +
                            (isAnimating ? " _next" : "")
                        }
                    >
                        {action.next?.type ===
                            "dropdown" && (
                            <RouteMenu
                                data={action.next}
                                dropdown={{
                                    state: dropdown.state,
                                    setState: dropdown.setState
                                }}
                                isDisabled={isDisabled}
                            />
                        )}
                        {action.next?.type ===
                            "button" && (
                            <RouteButton
                                props={action.next.props}
                                isDisabled={isDisabled}
                            />
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default RouteAction;
