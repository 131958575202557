import { useState, useRef, useEffect, useContext, forwardRef } from "react";
import { arrayMoveImmutable } from "array-move";
import parse from "html-react-parser";

import { SettingsContext } from "../context/SettingsStore";
import { ChapterContext } from "../context/ChapterStore";
import { ExamContext } from "../context/ExamStore";
import { ScoreContext } from "../context/ScoreStore";

import { isMobileCheck } from "../panels/ReaderPanel";
import getExamProgress from "../functions/getExamProgress";
import getExamAnswers from "../functions/getExamAnswers";
import parseFlashcardContent from "../functions/flashcard/parseFlashcardContent";
import parseMathTag from "../functions/parseMathTag";
import parseMathTagConnection from "../functions/parseMathTagConnection";

import ReaderQuizify from "../utils/ReaderQuizify";
import ReaderUser from "../utils/ReaderUser";

/* STYLES DEFAULT AND DARK */
const styles = {
    drag: {
        default: {
            background: "#D9E9FF",
            border: "#64A2F8",
        },
        secondary: {
            background: "#ade5c3",
            border: "#85d6a6",
        },
        error: {
            background: "#FFD4B7",
            border: "#FF6801",
        },
        success: {
            background: "#D8EFEF",
            border: "#9AC8C8",
        },
    },
};

const stylesDark = {
    drag: {
        default: {
            background: "#777472",
            border: "#DBD7D4",
        },
        error: {
            background: "#9D4000",
            border: "#CC5300",
        },
        success: {
            background: "#3D7956",
            border: "#4EAD75",
        },
    },
};

const deepCopy = (inObject) => {
    let outObject;
    let value;
    let key;

    if (typeof inObject !== "object" || inObject === null) {
        return inObject;
    }

    outObject = Array.isArray(inObject) ? [] : {};

    for (key in inObject) {
        value = inObject[key];

        outObject[key] = deepCopy(value);
    }

    return outObject;
};

const getOrderIsDone = (order) => {
    let isDone = false;
    let correctCount = 0;

    if (!order) return false;

    for (const item of order) {
        if (item.response === "success") {
            correctCount++;
        }
    }

    if (correctCount === order.length) {
        isDone = true;
    }

    return isDone;
};

const setIsChosenByUser = (id, answersSolved, index) => {
    if (!answersSolved || !Array.isArray(answersSolved)) return false;

    if (answersSolved[index]) {
        if (parseInt(answersSolved[index].id) === id) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
};

const ReaderOrder = ({
    ids,
    title,
    content,
    examContent,
    correct,
    flash,
    answersSolved,
    setExamProgress,
    userAvatar,
    isLoading,
    isExam,
    isGibs,
    isGallup,
    isAnswered,
}) => {
    const { themeColor } = useContext(SettingsContext);
    const { setChapterData, getChapterData, getFullChapterData } =
        useContext(ChapterContext);
    const { setExamPagesStore } = useContext(ExamContext);

    /* SAVED ORDER */
    const orderStored = getChapterData(ids, "order");
    const orderIsDoneStored = getOrderIsDone(orderStored);

    /* ORDER DATA */
    const [orderData, setOrderData] = useState(
        orderStored && !isAnswered
            ? orderStored
            : deepCopy(isAnswered ? correct : content)
    );

    /* ORDER DONE STATE AND HANDLER */
    const [orderIsDone, setOrderIsDone] = useState(false);

    useEffect(() => {
        if (getOrderIsDone(orderData)) {
            setOrderIsDone(true);
        }
    }, [orderData]);

    /* DEFAULTS */
    const isDragging = useRef(false);
    const isDropping = useRef(false);

    /* REFS */
    const bookRef = useRef(null);
    const areaRef = useRef(null);
    const dragRefs = useRef([]);

    /* DRAG/DROP ELEMENTS */
    const dragElement = useRef(null);
    const dropElement = useRef(null);

    /* DRAG START POSITION */
    const dragStartPosition = useRef(null);

    /* HANDLE DRAG START */
    const handleDragStart = (event, id) => {
        event.stopPropagation();

        if (isDragging.current || isDropping.current || isLoading) return;

        /* SET BOOK REF */
        bookRef.current = document.querySelector(".ReaderBook");

        /* DISABLE VIEW SCROLLING */
        bookRef.current.style.overflowY = "hidden";

        /* SET DRAG ELEMENT */
        dragElement.current = dragRefs.current.find(
            (element) => element.id === id
        );

        /* RESET DROP ELEMENT */
        dropElement.current = null;

        /* SET START POSITION BY TOUCH OR MOUSE */
        dragStartPosition.current =
            event.type === "mousedown" ? event.pageY : event.touches[0].pageY;

        /* SET DRAGGING FLAG TRUE */
        isDragging.current = true;
    };

    /* HANDLE DRAG */
    const handleDrag = (event) => {
        event.stopPropagation();

        if (!isDragging.current || isDropping.current) return;

        /* DRAG ELEMENT */
        const dragElementTarget = dragElement.current.target;
        const dragElementPosition = dragElement.current.pos;
        const dragElementId = dragElement.current.id;

        /* AREA HEIGHT */
        const areaHeight = areaRef.current.offsetHeight;

        /* POSITION */
        const axisY =
            event.type === "mousemove" ? event.pageY : event.touches[0].pageY;
        const position = axisY - dragStartPosition.current;

        /* MIN & MAX BREAKPOINTS */
        const minPosition =
            position + dragElementPosition.start <= 0 ? true : false;
        const maxPosition =
            position + dragElementPosition.end >= areaHeight ? true : false;

        /* DRAGGING POSITION */
        const setDragPosition = () => {
            if (!minPosition && !maxPosition) {
                return position;
            } else if (minPosition) {
                return dragElementPosition.start * -1;
            } else if (maxPosition) {
                return (
                    areaHeight -
                    dragElementPosition.start -
                    dragElementTarget.offsetHeight
                );
            }
        };

        /* DRAG ELEMENT STYLES */
        dragElementTarget.style.background =
            themeColor === "dark"
                ? stylesDark.drag.default.background
                : isGibs || isGallup
                ? styles.drag.secondary.background
                : styles.drag.default.background;
        dragElementTarget.style.borderColor =
            themeColor === "dark"
                ? stylesDark.drag.default.border
                : isGibs || isGallup
                ? styles.drag.secondary.border
                : styles.drag.default.border;
        dragElementTarget.style.transform =
            "translateY(" + setDragPosition() + "px)";
        dragElementTarget.style.zIndex = 10;

        /* GET RELATIVE POSITION TO CONTAINER */
        const getDragRelativePosition = () => {
            const relativeStartPosition = dragElementPosition.start + position;
            const relativeEndPosition = dragElementPosition.end + position;

            if (!minPosition && !maxPosition) {
                return {
                    start: relativeStartPosition,
                    end: relativeEndPosition,
                };
            } else if (minPosition) {
                return { start: 0, end: dragElementTarget.offsetHeight };
            } else if (maxPosition) {
                return {
                    start: areaHeight - dragElementTarget.offsetHeight,
                    end: areaHeight,
                };
            }
        };

        /* GET DROP TARGET */
        const getDropElement = () => {
            /* DEFAULTS DROP ELEMENT */
            const relativePosition = getDragRelativePosition();
            const dropElements = [];

            let dropElement = undefined;

            /* GET DROP ELEMENTS BY DRAG ELEMENT POSITION */
            for (const dragRef of dragRefs.current) {
                if (dragElementId !== dragRef.id) {
                    dragRef.target.style.background = "";
                    dragRef.target.style.borderColor = "";

                    if (
                        (dragRef.pos.start <= relativePosition.start &&
                            dragRef.pos.end >= relativePosition.start) ||
                        (dragRef.pos.start <= relativePosition.end &&
                            dragRef.pos.end >= relativePosition.end)
                    ) {
                        if (dragRef?.response !== "success") {
                            dropElements.push(dragRef);
                        }
                    }
                }
            }

            /* GET CLOSEST DROP ELEMENT */
            if (dropElements.length > 0) {
                if (dropElements.length > 1) {
                    const positionSubtractionFirst =
                        dropElements[0].pos.end - relativePosition.start;
                    const positionSubtractionSecond =
                        relativePosition.end - dropElements[1].pos.start;

                    if (positionSubtractionFirst > positionSubtractionSecond) {
                        dropElement = dropElements[0];
                    } else {
                        dropElement = dropElements[1];
                    }
                } else {
                    dropElement = dropElements[0];
                }
            } else {
                dropElement = undefined;
            }

            /* HIGHLIGHT DROP ELEMENT */
            if (dropElement) {
                dropElement.target.style.background =
                    themeColor === "dark"
                        ? stylesDark.drag.default.background
                        : isGibs || isGallup
                        ? styles.drag.secondary.background
                        : styles.drag.default.background;
                dropElement.target.style.borderColor =
                    themeColor === "dark"
                        ? stylesDark.drag.default.border
                        : isGibs || isGallup
                        ? styles.drag.secondary.border
                        : styles.drag.default.border;
            }

            return dropElement;
        };

        dropElement.current = getDropElement();
    };

    /* HANDLE DRAG END */
    const handleDragEnd = () => {
        if (!isDragging.current || isDropping.current) return;

        /* SET FLAGS */
        isDragging.current = false;
        isDropping.current = true;

        /* DRAG ELEMENT */
        const dragElementTarget = dragElement.current.target;
        const dragElementPosition = dragElement.current.pos;
        const dragElementHeight = dragElement.current.height;
        const dragElementId = dragElement.current.id;
        const dragElementIndex = dragElement.current.index;

        /* DROP ELEMENT */
        let dropElementTarget;
        let dropElementPosition;
        let dropElementHeight;
        let dropElementId;
        let dropElementIndex;

        /* DRAG ELEMENTS BETWEEN */
        const dragElementsBetweenArray = [];
        let dragElementBetweenTarget;
        let dragElementIndexFirst;
        let dragElementIndexLast;

        /* ENABLE VIEW SCROLLING */
        document.querySelector(".ReaderBook").style.overflowY = "";

        /* EXECUTION TIME */
        let executionTime;

        /* CLONED ORDER DATA */
        let clonedOrderData;

        if (dropElement.current) {
            /* SET EXECUTION TIME EQUAL TO TRANSFORM THEN BACKGROUND CHANGE */
            executionTime = 800;

            /* SET DROP ELEMENT IF EXIST */
            dropElementTarget = dropElement.current.target;
            dropElementPosition = dropElement.current.pos;
            dropElementHeight = dropElement.current.height;
            dropElementId = dropElement.current.id;
            dropElementIndex = dropElement.current.index;

            /* CHECK IF DRAG ELEMENT IS GOING UP */
            const dragIsGoingUp = () => dragElementIndex > dropElementIndex;

            /* TARGET POSITIONS TO SWAP ELEMENTS */
            const dragElementMovePosition =
                dropElementPosition.start -
                dragElementPosition.start -
                (dragIsGoingUp() ? 0 : dragElementHeight - dropElementHeight);
            const dropElementMovePosition =
                dragElementPosition.start -
                dropElementPosition.start -
                (dragIsGoingUp() ? dropElementHeight - dragElementHeight : 0);

            /* DRAG ELEMENT STYLE */
            dragElementTarget.style.transition =
                "background .3s ease, border .3s ease, transform .5s ease";
            dragElementTarget.style.transform =
                "translateY(" + dragElementMovePosition + "px)";

            /* DROP ELEMENT STYLE */
            dropElementTarget.style.transition =
                "background .3s ease, border .3s ease, transform .5s ease";
            dropElementTarget.style.transform =
                "translateY(" + dropElementMovePosition + "px)";
            dropElementTarget.style.zIndex = 9;

            /* DROP ELEMENT STYLE BETWEEN */
            if (dragElementHeight !== dropElementHeight) {
                const dragIndexSubtraction = dragIsGoingUp()
                    ? dragElementIndex - dropElementIndex
                    : dropElementIndex - dragElementIndex;

                if (dragIndexSubtraction > 1) {
                    const dragElementBetweenMovePosition = dragIsGoingUp()
                        ? dragElementHeight - dropElementHeight
                        : dropElementHeight - dragElementHeight;

                    dragElementIndexFirst =
                        (dragIsGoingUp()
                            ? dropElementIndex
                            : dragElementIndex) + 1;
                    dragElementIndexLast =
                        (dragIsGoingUp()
                            ? dragElementIndex
                            : dropElementIndex) - 1;

                    if (dragElementIndexFirst === dragElementIndexLast) {
                        dragElementBetweenTarget =
                            dragRefs.current[dragElementIndexFirst].target;

                        dragElementBetweenTarget.style.transition =
                            "background .3s ease, border .3s ease, transform .5s ease";
                        dragElementBetweenTarget.style.transform =
                            "translateY(" +
                            dragElementBetweenMovePosition +
                            "px)";
                    } else {
                        const dragElementIndexSubtraction =
                            dragElementIndexLast - dragElementIndexFirst + 1;

                        for (
                            let i = dragElementIndexFirst;
                            i <
                            dragElementIndexFirst + dragElementIndexSubtraction;
                            i++
                        ) {
                            dragElementsBetweenArray.push(
                                dragRefs.current[i].target
                            );
                        }

                        for (const dragElementBetweenItem of dragElementsBetweenArray) {
                            dragElementBetweenItem.style.transition =
                                "background .3s ease, border .3s ease, transform .5s ease";
                            dragElementBetweenItem.style.transform =
                                "translateY(" +
                                dragElementBetweenMovePosition +
                                "px)";
                        }
                    }
                }
            }

            /* EXECUTE SWAP */
            setTimeout(() => {
                const arrayIndexDirection = dragIsGoingUp() ? 1 : -1;

                /* SORT ORDER DATA */
                clonedOrderData = arrayMoveImmutable(
                    arrayMoveImmutable(
                        orderData,
                        dragElementIndex,
                        dropElementIndex
                    ),
                    dropElementIndex + arrayIndexDirection,
                    dragElementIndex
                );

                /* GET IF OBJECT HAS CORRECT ORDER */
                const getIfObjectHasCorrectOrder = (id, index) => {
                    if (!id || (!index && typeof index !== "number")) return;

                    return correct[index].id === id;
                };

                /* SET OBJECT RESPONSE BY SORTED DATA */
                const setObjectResponse = (id, index, target, data) => {
                    if (!isExam) {
                        if (getIfObjectHasCorrectOrder(id, index)) {
                            target.style.background =
                                themeColor === "dark"
                                    ? stylesDark.drag.success.background
                                    : styles.drag.success.background;
                            target.style.borderColor =
                                themeColor === "dark"
                                    ? stylesDark.drag.success.border
                                    : styles.drag.success.border;

                            data[index].response = "success";
                        } else {
                            target.style.background =
                                themeColor === "dark"
                                    ? stylesDark.drag.error.background
                                    : styles.drag.error.background;
                            target.style.borderColor =
                                themeColor === "dark"
                                    ? stylesDark.drag.error.border
                                    : styles.drag.error.border;

                            data[index].response = "error";
                        }
                    } else {
                        data[index].response = "selected";
                    }
                };

                setObjectResponse(
                    dragElementId,
                    dropElementIndex,
                    dragElementTarget,
                    clonedOrderData
                );

                setObjectResponse(
                    dropElementId,
                    dragElementIndex,
                    dropElementTarget,
                    clonedOrderData
                );
            }, 500);
        } else {
            /* SET EXECUTION TIME EQUAL TO TRANSITION */
            executionTime = 300;

            /* RESET DRAG ELEMENT IF DROP IS UNAVAILABLE */
            dragElementTarget.style.background = "";
            dragElementTarget.style.borderColor = "";
            dragElementTarget.style.transition =
                "background .3s ease, border .3s ease, transform .3s ease";
            dragElementTarget.style.transform = "translateY(0px)";
        }

        setTimeout(() => {
            /* DROPPING FINISHED */
            isDropping.current = false;

            /* SET NEW ORDER DATA */
            if (clonedOrderData) {
                setChapterData(clonedOrderData, ids, "order");

                if (isExam) {
                    /* GET EXAM PROGRESS */
                    const examProgress = getExamProgress(
                        getFullChapterData(),
                        examContent,
                        false,
                        isGibs || isGallup
                    );

                    /* STORE EXAM PROGRESS AND SET NEW STATE */
                    setExamPagesStore(ids.idChapter, examProgress, "progress");
                    setExamProgress(examProgress);
                }

                setOrderData(clonedOrderData);
            }

            /* RESET STYLES */
            dragElementTarget.style.background = "";
            dragElementTarget.style.borderColor = "";
            dragElementTarget.style.transition = "";
            dragElementTarget.style.transform = "";
            dragElementTarget.style.zIndex = "";

            if (dropElementTarget) {
                dropElementTarget.style.background = "";
                dropElementTarget.style.borderColor = "";
                dropElementTarget.style.transition = "";
                dropElementTarget.style.transform = "";
                dropElementTarget.style.zIndex = "";
            }

            if (dragElementBetweenTarget) {
                dragElementBetweenTarget.style.transition = "";
                dragElementBetweenTarget.style.transform = "";
            }

            if (dragElementsBetweenArray.length > 0) {
                for (const dragElementBetweenItem of dragElementsBetweenArray) {
                    dragElementBetweenItem.style.transition = "";
                    dragElementBetweenItem.style.transform = "";
                }
            }
        }, executionTime);
    };

    return (
        <>
            <div
                className={
                    "ReaderOrder" +
                    (isGibs || isGallup ? " _color--secondary" : "")
                }
            >
                {title ? (
                    <div className="ReaderOrder__Title">
                        {parseFlashcardContent(
                            parse(parseMathTagConnection(title)),
                            flash
                        ).map((object) => {
                            return object;
                        })}
                    </div>
                ) : (
                    ""
                )}
                <div className="ReaderOrder__Area" ref={areaRef}>
                    {orderData.map(({ id, name, response }, index) => {
                        return (
                            <OrderDrag
                                key={id}
                                ref={dragRefs}
                                name={name}
                                id={id}
                                index={index}
                                response={response}
                                handleDragStart={handleDragStart}
                                handleDrag={handleDrag}
                                handleDragEnd={handleDragEnd}
                                userAvatar={userAvatar}
                                isExam={isExam}
                                isAnswered={isAnswered}
                                isChosen={setIsChosenByUser(
                                    id,
                                    getExamAnswers(
                                        ids.idContent,
                                        answersSolved
                                    ),
                                    index
                                )}
                            />
                        );
                    })}
                </div>
                {(!isExam && orderIsDone) || (!isExam && orderIsDoneStored) ? (
                    <ReaderQuizify content="Gratulacje! Ułożyłeś/aś w prawidłowej kolejności" />
                ) : (
                    ""
                )}
            </div>
        </>
    );
};

const OrderDrag = forwardRef((props, ref) => {
    const {
        name,
        id,
        index,
        response,
        handleDragStart,
        handleDrag,
        handleDragEnd,
        userAvatar,
        isExam,
        isAnswered,
        isChosen,
    } = props;

    const {
        isGallupResultLoading,
        isGallupResultDone,
        isGibsResultLoading,
        isGibsResultDone,
    } = useContext(ExamContext);
    const { isExamScoreLoading, isExamScoreInit } = useContext(ScoreContext);

    const isMobile = isMobileCheck();

    const isSelected = response === "selected" && !isAnswered ? true : false;
    const responseSuccess = response === "success" ? true : false;
    const responseError = response === "error" ? true : false;

    return (
        <button
            className={
                "ReaderOrder__Drag" +
                (responseSuccess || (isExam && isAnswered && isChosen)
                    ? " _response--success"
                    : "") +
                (responseError ? " _response--error" : "") +
                (isSelected ? " _is--selected" : "") +
                " DisableSelect"
            }
            type="button"
            data-index={index + 1}
            data-panel="false"
            disabled={
                responseSuccess ||
                isAnswered ||
                isExamScoreLoading ||
                isExamScoreInit ||
                isGallupResultLoading ||
                isGallupResultDone ||
                isGibsResultLoading ||
                isGibsResultDone
            }
            onMouseDown={
                !isMobile ? (event) => handleDragStart(event, id) : undefined
            }
            onMouseMove={!isMobile ? handleDrag : undefined}
            onMouseUp={!isMobile ? handleDragEnd : undefined}
            onTouchStart={
                isMobile ? (event) => handleDragStart(event, id) : undefined
            }
            onTouchMove={isMobile ? handleDrag : undefined}
            onTouchEnd={isMobile ? handleDragEnd : undefined}
            ref={(r) =>
                (ref.current[index] = {
                    id: id,
                    index: index,
                    target: r,
                    height: r?.offsetHeight,
                    response: response ? response : "not_validated",
                    pos: {
                        start: r?.offsetTop,
                        end: r?.offsetTop + r?.offsetHeight,
                    },
                })
            }
        >
            {parseMathTag(parseMathTagConnection(name.option))}
            {isChosen && <ReaderUser avatar={userAvatar} />}
        </button>
    );
});

export default ReaderOrder;
