const SigningFormContainer = ({
    classNameWrapper,
    classNameGroup,
    children,
}) => {
    return (
        <>
            <div
                className={
                    (classNameWrapper ? classNameWrapper : "default") +
                    "-form" +
                    (classNameGroup ? "-" + classNameGroup : "-group")
                }
            >
                {children.length > 1
                    ? children.map((object, index) => {
                          if (object.type === undefined) {
                              return false;
                          }

                          if (
                              object.key === "Input" ||
                              object.key === "InputMasked" ||
                              object.key === "Button" ||
                              object.key === "RepeaterSelect" ||
                              object.key === "InputSelect"
                          ) {
                              return (
                                  <div
                                      className={
                                          classNameWrapper
                                              ? classNameWrapper + "-form-field"
                                              : "default-form-field"
                                      }
                                      key={"form-input_" + index}
                                  >
                                      {object}
                                  </div>
                              );
                          } else if (object.type.name === "Checkbox") {
                              return (
                                  <div
                                      className={
                                          classNameWrapper
                                              ? classNameWrapper +
                                                "-form-checkbox"
                                              : "default-form-checkbox"
                                      }
                                      key={"form-checkbox_" + index}
                                  >
                                      {object}
                                  </div>
                              );
                          } else {
                              return object;
                          }
                      })
                    : children}
            </div>
        </>
    );
};

export default SigningFormContainer;
