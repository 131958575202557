const editPasswordHandler = (
    statusCode,
    responseError,
    onIncorrectPassword
) => {
    switch (statusCode) {
        case 400:
            switch (responseError.errors) {
                case "INCORRECT_PASSWORD":
                    onIncorrectPassword();
                default:
                    return;
            }

        default:
            return;
    }
};

export default editPasswordHandler;
