import Button from "../buttons/Button";

const ModalEmpty = ({message, onResetClick}) => {
    return (
        <>
            <div className="ModalEmpty">
                <div className="ModalEmpty__Message">
                    {message}
                </div>
                <div className="ModalEmpty__Button">
                    <Button className="medium" onClickFn={onResetClick}>Wyczyść</Button>
                </div>
            </div>
        </>
    )
}

export default ModalEmpty;