const routeButtonIcon = (type) => {
    switch (type) {
        case "heart":
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    className="RouteButton__Svg"
                    width="21.18"
                    height="18.614"
                    viewBox="0 0 21.18 18.614"
                >
                    <defs>
                        <clipPath id="clip-path">
                            <rect
                                id="Rectangle_1375"
                                dataname="Rectangle 1375"
                                width="21.18"
                                height="18.614"
                                transform="translate(0 0)"
                                fill="#fff"
                            />
                        </clipPath>
                    </defs>
                    <g
                        id="Group_905"
                        dataname="Group 905"
                        transform="translate(0 0)"
                    >
                        <g
                            id="Group_890"
                            dataname="Group 890"
                            transform="translate(0 0)"
                            clipPath="url(#clip-path)"
                        >
                            <path
                                id="Path_1522"
                                dataname="Path 1522"
                                d="M15.736,3.938a6.687,6.687,0,0,1-1.179,3.6,22.554,22.554,0,0,1-5.819,5.81c-.869.643-.875.638-1.76-.006a24.732,24.732,0,0,1-5-4.652A8.016,8.016,0,0,1,.016,4.423,4.01,4.01,0,0,1,2.136.459a3.745,3.745,0,0,1,4.369.6,13.523,13.523,0,0,1,1.1,1.089c.2.218.314.2.506,0A15.208,15.208,0,0,1,9.322.978a3.61,3.61,0,0,1,4.15-.562,3.886,3.886,0,0,1,2.265,3.522"
                                transform="translate(2.722 2.392)"
                                fill="#fff"
                            />
                        </g>
                    </g>
                </svg>
            );
        default:
            return <span className="RouteButton__Circle"></span>;
    }
};

const RouteButton = ({ props, isDisabled }) => {
    return (
        <>
            <button
                className="RouteButton"
                type="button"
                disabled={isDisabled}
                onClick={props?.onClick}
            >
                <span className="RouteButton__Icon">
                    {routeButtonIcon(props?.icon)}
                </span>
                <span className="RouteButton__Text">{props?.text}</span>
            </button>
        </>
    );
};

export default RouteButton;
