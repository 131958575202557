import { forwardRef } from "react";
import { useHistory } from "react-router-dom";

import Title from "../../headings/Title";
import Input from "../../inputs/Input";
import Button from "../../buttons/Button";

import validator from "../../../functions/validator";

import DoodleBanner from "../../containers/doodle/DoodleBanner";
import DoodleContent from "../../containers/doodle/DoodleContent";
import doodles from "../../../assets/images/remindpassword/how2doodles.svg";

const RemindPasswordForm = forwardRef(
    ({ data, isLoading, onChangeFn, onClickFn }, ref) => {
        const history = useHistory();

        /* E-MAIL INPUT HANDLER */
        const emailOnChangeHandler = (e) => {
            /* E-MAIL VALIDATION */
            if (e.length > 0) {
                validator("email", e)
                    ? onChangeFn({
                          value: e,
                          valid: {
                              response: "success",
                              errorMessage: "",
                          },
                      })
                    : onChangeFn({
                          value: e,
                          valid: {
                              response: "error",
                              errorMessage: "",
                          },
                      });
            } else {
                onChangeFn({
                    value: "",
                    valid: {
                        response: "",
                        errorMessage: "",
                    },
                });
            }
        };

        /* ON ENTER KEY */
        const onEnterKeyHandler = (e) => {
            const enterKey = e.charCode === 13 || e.keyCode === 13;

            if (!formSubmitDisable && enterKey) {
                e.currentTarget.blur();
                onClickFn();
            }
        };

        /* ENABLE SUBMIT IF VALIDATION IS CORRECT */
        const formSubmitDisable =
            data.value && data.valid.response === "success" ? false : true;

        return (
            <>
                <div className="app-remindPassword-wrapper" ref={ref}>
                    <DoodleContent
                        wrapperClass="remindPassword"
                        isDisabled={isLoading}
                        onBackFn={() => history.goBack()}
                    >
                        <Title className="text-center">
                            Zapomniałeś hasła?
                        </Title>
                        <div className="remindPassword-text text-center">
                            <p>
                                Wpisz swój adres e-mail,
                                <br />
                                wyślemy Ci link do zresetowania hasła
                            </p>
                        </div>
                        <div className="remindPassword-form">
                            <Input
                                type="email"
                                placeholder="wpisz e-mail"
                                classNameControl="font-thick"
                                value={data.value}
                                isDisabled={isLoading}
                                isValid={{
                                    response: data.valid.response,
                                    text: data.valid.errorMessage,
                                }}
                                onChangeFn={(e) =>
                                    emailOnChangeHandler(e.currentTarget.value)
                                }
                                onKeyPressFn={(e) => onEnterKeyHandler(e)}
                            />
                            <Button
                                classNameWrapper="remindPassword-button"
                                className="medium"
                                isDisabled={formSubmitDisable || isLoading}
                                isLoading={isLoading}
                                onClickFn={onClickFn}
                            >
                                Wyślij
                            </Button>
                        </div>
                    </DoodleContent>
                    <DoodleBanner
                        wrapperClass="remindPassword"
                        imgs={doodles}
                    />
                </div>
            </>
        );
    }
);

export default RemindPasswordForm;
