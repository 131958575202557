const routeLoadingState = (queryState) => {
    let isFetched = true;
    let isLoaded = true;
    let isRefetching = false;

    if(queryState.fetched && Array.isArray(queryState.fetched)) {
        for(const fetched of queryState.fetched) {
            if(!fetched) {
                isFetched = false;
                break;
            }
        }
    }

    if(queryState.loading && Array.isArray(queryState.loading)) {
        for(const loading of queryState.loading) {
            if(loading) {
                isLoaded = false;
                break;
            }
        }
    }

    if(queryState.refetching && Array.isArray(queryState.refetching)) {
        for(const refetching of queryState.refetching) {
            if(refetching) {
                isRefetching = true;
                break;
            }
        }
    }

    return isRefetching ? true : isFetched ? isLoaded ? false : true : true;
}

export default routeLoadingState;