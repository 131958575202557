import ReaderProgress from "../utils/ReaderProgress";

import scoreLow_doodle from "../assets/images/score/score-low.svg";
import scoreMedium_doodle from "../assets/images/score/score-medium.svg";
import scoreHigh_doodle from "../assets/images/score/score-high.svg";

const getScoreDoodle = (percent) => {
    percent = Math.round(percent);

    if (percent <= 39.99) {
        return scoreLow_doodle;
    } else if (percent >= 40 && percent <= 79.99) {
        return scoreMedium_doodle;
    } else if (percent >= 80) {
        return scoreHigh_doodle;
    } else {
        return scoreMedium_doodle;
    }
};

const ReaderPoints = ({
    category,
    title,
    color,
    text,
    numbers = {},
    scopes,
    isContentSplit,
}) => {
    const { percent } = numbers;

    return (
        <div className="ReaderPoints">
            {category && <h2 className="ReaderPoints__Category">{category}</h2>}
            <div className="ReaderPoints__Doodle">
                <img src={getScoreDoodle(percent)} alt="Points Doodle" />
            </div>
            <div className="ReaderPoints__Heading">
                {title && <h3 className="ReaderPoints__Title">{title}</h3>}
                {!isContentSplit ? (text && text !== "" ? text : "") : ""}
            </div>
            <ReaderProgress
                type="points"
                color={color}
                numbers={numbers}
                scopes={scopes}
            />
            {isContentSplit && text && text !== "" && (
                <div className="ReaderPoints__Content">{text}</div>
            )}
        </div>
    );
};

export default ReaderPoints;
