import { useState, useRef, useContext } from "react";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";

import { StoreContext } from "../../../../context/Store";

import IconFavorite from "../../../icons/IconFavorite";
import IconMultibook from "../../../icons/IconMultibook";
import IconTrash from "../../../icons/IconTrash";
import IconDone from "../../../icons/IconDone";
import Loader from "../../Loader";

const ShelfBook = ({ data, onRequestDelete }) => {
    const history = useHistory();

    const { api } = useContext(StoreContext);

    /* FAVORITE STATE */
    const [favoriteIsActive, setFavoriteIsActive] = useState(data.favorite);

    /* ID'S */
    const idMultibook = useRef(data.id);

    /* FAVORITE DISABLE STATE */
    const [favoriteOnMutate, setFavoriteOnMutate] = useState(false);

    /* SET BOOK TO FAVORITE */
    const { mutate: favoriteMutate } = useMutation(
        () =>
            api.post(`/library/add_favorite`, {
                idMultibook: idMultibook.current,
            }),
        {
            onMutate: () => setFavoriteOnMutate(true),
            onSuccess: (data) => {
                const message = data.data.message;

                if (message === "ADDED_TO_FAVORITE") {
                    setFavoriteIsActive("Y");
                }

                if (message === "DELETED_FROM_FAVORITE") {
                    setFavoriteIsActive("N");
                }

                setFavoriteOnMutate(false);
            },
        }
    );

    const progressDone = data.pages.progress === data.pages.total;

    return (
        <>
            <div className="ShelfBook">
                <button
                    className="ShelfBook__Thumbnail"
                    type="button"
                    style={{ backgroundImage: "url(" + data.cover + ")" }}
                    onClick={() => history.push(`/multibook-${data.id}`)}
                ></button>
                <div className="ShelfBook__Body">
                    <button
                        className="ShelfBook__Title"
                        type="button"
                        onClick={() => history.push(`/multibook-${data.id}`)}
                    >
                        <span className="ShelfBook__Icon _icon--title">
                            <IconMultibook type={data.type} />
                        </span>
                        {data.title}
                    </button>
                    <button
                        className="ShelfBook__Favorite"
                        type="button"
                        disabled={favoriteOnMutate}
                        onClick={favoriteMutate}
                    >
                        <span className="ShelfBook__Icon _icon--favorite">
                            <IconFavorite isActive={favoriteIsActive} />
                        </span>
                        Dodaj do ulubionych
                    </button>
                    <div className="ShelfBook__Content">
                        <p>{data.content}</p>
                    </div>
                </div>
                <div className="ShelfBook__Footer">
                    <div className="ShelfBook__Progress">
                        <div className="ShelfBook__Chapter">
                            {progressDone ? (
                                <div className="ShelfBook__Icon _icon--done">
                                    <IconDone />
                                </div>
                            ) : (
                                ""
                            )}
                            {progressDone
                                ? "Skończony"
                                : "Rozdział " +
                                  data.pages.progress +
                                  " z " +
                                  data.pages.total}
                        </div>
                        <button
                            className="ShelfBook__Delete"
                            type="button"
                            onClick={() =>
                                onRequestDelete(idMultibook.current, data.title)
                            }
                        >
                            <IconTrash />
                        </button>
                        <div
                            className={
                                "ShelfBook__ProgressBar" +
                                (progressDone ? " _bar--green" : "")
                            }
                        >
                            <div
                                className="ShelfBook__ProgressLine"
                                style={{
                                    width: progressDone
                                        ? "100%"
                                        : (data.pages.progress * 100) /
                                              data.pages.total +
                                          "%",
                                }}
                            ></div>
                        </div>
                    </div>
                </div>
                {favoriteOnMutate && (
                    <div className="ShelfBook__Action">
                        <div className="ShelfBook__Loader">
                            <Loader type="ring" />
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default ShelfBook;
