import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";

import useProtectedRoute from "../hooks/useProtetedRoute";

import Title from "../components/headings/Title";
import Button from "../components/buttons/Button";

import DoodleBanner from "../components/containers/doodle/DoodleBanner";
import DoodleContent from "../components/containers/doodle/DoodleContent";
import doodles from "../assets/images/beginning/how2doodles.svg";
import BeginningText from "../components/containers/dashboard/BeginningText";

const Beginning = ({ userRank }) => {
    const history = useHistory();

    const [cookies] = useCookies(["schoolsEmail"]);

    useProtectedRoute("/", false);

    return (
        <>
            <div className="app-beginning">
                <DoodleBanner wrapperClass="beginning" imgs={doodles} />
                <DoodleContent wrapperClass="beginning">
                    <Title className="thick">
                        Zacznij przygodę
                        <br />z How2 Edu
                    </Title>
                    <BeginningText
                        userRank={userRank}
                        list={cookies.schoolsEmail}
                    />
                    <Button
                        classNameWrapper="beginning-button"
                        className="inverse"
                        onClickFn={() => history.push("/activate-account")}
                    >
                        Przejdź dalej
                    </Button>
                </DoodleContent>
            </div>
        </>
    );
};

export default Beginning;
