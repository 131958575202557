import parseFlashcardPhrase from "./parseFlashcardPhrase";

const parseFlashcardPhrases = (string, flash, index) => {
    const { data, key, props } = flash;

    let map = [];
    let indexMap = 0;

    if (data?.length > 0) {
        for (const flashcard of data) {
            const flashcardPhrasesSorted = flashcard.phrases.sort(
                (a, b) => b.length > a.length
            );

            for (const phrase of flashcardPhrasesSorted) {
                if (map.length > 0) {
                    let position = 0;

                    for (const item of map) {
                        if (typeof item === "string") {
                            const parsedPhrase = parseFlashcardPhrase(
                                item,
                                phrase,
                                {
                                    key,
                                    index: index + indexMap.toString(),
                                    content: flashcard.content,
                                    props
                                }
                            );

                            if (parsedPhrase.length > 0) {
                                map.splice(position, 1, ...parsedPhrase);
                            }
                        }

                        indexMap++;
                        position++;
                    }
                } else {
                    map = parseFlashcardPhrase(string, phrase, {
                        key,
                        index: index + indexMap.toString(),
                        content: flashcard.content,
                        props
                    });

                    indexMap++;
                }
            }
        }
    } else {
        map = parseFlashcardPhrase(string, '__UNDEFINED__', {
            key,
            index: index + indexMap.toString(),
            content: '',
            props
        });
    }

    return map;
};

export default parseFlashcardPhrases;
