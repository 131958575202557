import useProtectedRoute from "../../hooks/useProtetedRoute";

import ReaderApp from "../../components/reader/ReaderApp";

const MultibookReader = () => {
    useProtectedRoute("/", false);

    return (
        <>
            <ReaderApp />
        </>
    );
};

export default MultibookReader;
