import IconDone from "../../../icons/IconDone";

const LearningBar = ({ caption, percent, range, isDone }) => {
    const scoreClassName = (int) => {
        if (typeof int !== "number" || !int) {
            return "";
        }

        if (int >= 75) {
            return " _bar--green";
        }

        if (int < 75 && int >= 30) {
            return " _bar--yellow";
        }

        if (int < 30) {
            return " _bar--orange";
        }
    };

    return (
        <>
            <span className="LearningBar">
                <span className="LearningBar__Caption">
                    {" "}
                    {isDone ? (
                        <div className="LearningBar__Icon">
                            <IconDone />
                        </div>
                    ) : (
                        ""
                    )}
                    {isDone ? "Skończony" : caption}
                </span>
                <span
                    className={
                        "LearningBar__Progress" +
                        (isDone ? " _bar--green" : range ? scoreClassName(percent) : '')
                    }
                >
                    <span
                        className="LearningBar__Line"
                        style={{
                            width: isDone
                                ? "100%"
                                : percent
                                ? percent + "%"
                                : "0",
                        }}
                    ></span>
                </span>
            </span>
        </>
    );
};

export default LearningBar;
