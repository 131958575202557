import ShelfFilters from "./ShelfFilters";
import ShelfState from "./ShelfState";

const ShelfBar = ({
    filters,
    filterSelected,
    onClickFilter,
    states,
    stateSelected,
    stateDropdown,
    onClickSwitch
}) => {
    return (
        <>
            <div className="ShelfBar">
                <div className="ShelfBar__Filters">
                    <ShelfFilters
                        filters={filters}
                        filterSelected={filterSelected}
                        onClick={onClickFilter}
                    />
                </div>
                <div className="ShelfBar__State">
                    <ShelfState
                        states={states}
                        stateSelected={stateSelected}
                        stateDropdown={stateDropdown}
                        onClick={onClickSwitch}
                    />
                </div>
            </div>
        </>
    );
};

export default ShelfBar;
