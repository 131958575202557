import { useEffect, useRef, useContext } from "react";
import { useQuery, useQueryClient, useMutation } from "react-query";
import { useParams, useHistory, useLocation } from "react-router-dom";

import { StoreContext } from "../../../context/Store";

import pathSlice from "../../../functions/pathSlice";
import parseTagsFromString from "../../../functions/parseTagsFromString";
import routeLoadingState from "../../../functions/routeLoadingState";

import Title from "../../../components/headings/Title";
import Button from "../../../components/buttons/Button";

import DashboardContainer from "../../../components/containers/dashboard/DashboardContainer";
import DashboardGroup from "../../../components/containers/dashboard/DashboardGroup";
import SchoolTextbox from "../../../components/containers/dashboard/school-groups/SchoolTextbox";

import modalDoodleImage_1 from "../../../assets/images/dashboard/modal-doodles/questionmark_3.svg";
import modalDoodleImage_2 from "../../../assets/images/dashboard/modal-doodles/sad_2.svg";

const SchoolGroup = ({ routeMatches, routeSetters, modalSetters }) => {
    const { setRouteData, setRouteIsLoading } = routeSetters;
    const {
        setModalData,
        setModalActive,
        setModalApiResponse,
        setModalApiLoading,
    } = modalSetters;

    const history = useHistory();
    const location = useLocation();
    const params = useParams();

    const queryClient = useQueryClient();
    const { api } = useContext(StoreContext);

    /* PARAMS ID REF */
    const idGroupRef = useRef(params.idGroup);

    /* GET SCHOOL GROUP */
    const {
        data: schoolGroupData,
        isFetched: schoolGroupIsFetched,
        isLoading: schoolGroupIsLoading,
        isRefetching: schoolGroupIsRefetching,
    } = useQuery(
        ["schoolGroup", idGroupRef.current],
        () => api.post(`/teacher/group`, { idGroup: idGroupRef.current }),
        {
            staleTime: Infinity,
            retry: false,
            refetchOnWindowFocus: false,
        }
    );

    /* GET TAGS */
    const {
        data: schoolTagsData,
        isFetched: schoolTagsIsFetched,
        isLoading: schoolTagsIsLoading,
        isRefetching: schoolTagsIsRefetching,
    } = useQuery("tagsList", () => api.get(`/tags`), {
        staleTime: Infinity,
        retry: false,
        refetchOnWindowFocus: false,
    });

    /* GET TAG NAME FROM TAGS */
    const getTagsNames = () => {
        const tagsArray = parseTagsFromString(
            schoolTagsData.data,
            schoolGroupData.data.tags
        );

        let str = "";

        for (let i = 0; i < tagsArray.length; i++) {
            str += tagsArray[i].name;

            if (i !== tagsArray.length - 1) {
                str += ", ";
            }
        }

        return str;
    };

    /* DELETE SCHOOL GROUP */
    const { mutate: deleteSchoolGroupMutate } = useMutation(
        () =>
            api.post(`/teacher/delete_group`, {
                idGroup: params.idGroup,
            }),
        {
            onMutate: () => setModalApiLoading(true),
            onSuccess: () => {
                queryClient.invalidateQueries([
                    "schoolGroups",
                    params.idSchool,
                ]);

                setModalApiResponse(true);
            },
        }
    );

    /* ROUTE LOADING */
    const routeIsLoading = routeLoadingState({
        fetched: [schoolGroupIsFetched, schoolTagsIsFetched],
        loading: [schoolGroupIsLoading, schoolTagsIsLoading],
        refetching: [schoolGroupIsRefetching, schoolTagsIsRefetching],
    });

    /* ROUTE SETTERS */
    useEffect(() => {
        const routeData = {
            title: routeIsLoading ? "" : schoolGroupData.data.name,
            action: {
                type: "dropdown",
                props: [
                    {
                        text: "Dołącz uczniów do grupy",
                        icon: "add",
                        onClick: () =>
                            history.push(
                                `${pathSlice(location.pathname)}/add-student`
                            ),
                    },
                    {
                        text: "Edytuj informacje grupy",
                        icon: "edit",
                        onClick: () =>
                            history.push(
                                `${pathSlice(location.pathname)}/edit-group`
                            ),
                    },
                    {
                        text: "Usuń grupę",
                        icon: "remove",
                        onClick: () => setModalData(modalData.current),
                    },
                ],
            },
        };

        if (routeMatches) {
            setRouteIsLoading(routeIsLoading);
            setRouteData(routeData);
        }
    }, [
        routeMatches,
        routeIsLoading,
        setRouteIsLoading,
        setRouteData,
        schoolGroupData?.data.name,
        setModalData,
    ]);

    /* MODAL DATA */
    const modalData = useRef({});

    useEffect(() => {
        if (!routeIsLoading) {
            modalData.current = {
                data: [
                    {
                        title: {
                            obj: (
                                <p>
                                    Czy na pewno chcesz usunąć
                                    <br />
                                    {schoolGroupData.data.name}
                                </p>
                            ),
                        },
                        img: {
                            obj: modalDoodleImage_1,
                            size: "219px",
                        },
                        action: {
                            obj: [
                                {
                                    text: "Tak, chcę usunąć",
                                    important: true,
                                    onClick: () => deleteSchoolGroupMutate(),
                                },
                                {
                                    text: "Nie, nie chcę usuwać",
                                    onClick: () => setModalActive(false),
                                },
                            ],
                            key: "schoolGroupDelete",
                        },
                    },
                    {
                        title: {
                            obj: (
                                <p>
                                    {schoolGroupData.data.name}
                                    <br />
                                    została usunięta
                                </p>
                            ),
                            isCenter: true,
                        },
                        img: {
                            obj: modalDoodleImage_2,
                            size: "219px",
                        },
                        action: {
                            obj: {
                                text: "Przejdź dalej",
                                onClick: () => history.goBack(),
                            },
                        },
                    },
                ],
            };
        }
    }, [
        routeIsLoading,
        setModalActive,
        schoolGroupData?.data.name,
        deleteSchoolGroupMutate,
    ]);

    return (
        <>
            <DashboardContainer>
                {!routeIsLoading && (
                    <>
                        {" "}
                        <DashboardGroup className="group-description">
                            <Title className="medium text-center" type={2}>
                                OPIS
                            </Title>
                            <SchoolTextbox>
                                {schoolGroupData.data.description}
                            </SchoolTextbox>
                        </DashboardGroup>
                        <DashboardGroup className="group-tags">
                            <Title className="medium text-center" type={2}>
                                TAGI
                            </Title>
                            <div className="school-group-tags text-center">
                                {getTagsNames()}
                            </div>
                        </DashboardGroup>
                        <DashboardGroup className="group-students">
                            <Title className="medium text-center" type={2}>
                                UCZNIOWIE W GRUPIE
                            </Title>
                            {schoolGroupData.data.students.length > 0 ? (
                                schoolGroupData.data.students?.map((object) => {
                                    return (
                                        <Button
                                            key={
                                                "schoolGroupStudent_" +
                                                object.idUser
                                            }
                                            className="creamy"
                                            action="link"
                                            thumbnail={object.avatar}
                                            onClickFn={() =>
                                                history.push(
                                                    `${pathSlice(
                                                        location.pathname
                                                    )}/student-${object.idUser}`
                                                )
                                            }
                                        >
                                            {object.name} {object.surname}
                                        </Button>
                                    );
                                })
                            ) : (
                                <SchoolTextbox className="text-center bold">
                                    Brak uczniów w grupie
                                </SchoolTextbox>
                            )}
                        </DashboardGroup>
                    </>
                )}
            </DashboardContainer>
        </>
    );
};

export default SchoolGroup;
