import { useState, useRef, useEffect, useContext } from "react";

import { ExamContext } from "../context/ExamStore";
import { ScoreContext } from "../context/ScoreStore";

import getExamProgress from "../functions/getExamProgress";

import ReaderProgress from "../utils/ReaderProgress";
import ReaderComponents from "../utils/ReaderComponents";
import ReaderPager from "../utils/ReaderPager";
import ReaderPage from "../utils/ReaderPage";

import ReaderGallup from "./ReaderGallup";
import ReaderGibs from "./ReaderGibs";

const getExamSections = (content) => {
    if (!content || !Array.isArray(content)) return [];

    return content.filter((element) => element.type === "section");
};

const ReaderExam = ({
    idChapter,
    pages,
    content,
    pager,
    answers,
    answersSolved,
    userAvatar,
    note,
    cover,
    isPending,
    isLoading,
    isGallup,
    isGibs,
    isAnswered,
    isShared,
}) => {
    const { setPage } = pages;
    const { isPagerDisabled, pagerDirection, setPagerDirection } = pager;

    const {
        setExamPagesStore,
        getExamPagesStore,
        getExamResultStore,
        isGallupResultMount,
        setIsGallupResultLoading,
        isGallupResultDone,
        setIsGallupResultDone,
        isGibsResultDone,
    } = useContext(ExamContext);
    const { isExamAnswersStale } = useContext(ScoreContext);

    /* EXAM REFERENCE */
    const examRef = useRef(null);

    /* EXAM DATA */
    const examSections = !isGibs ? getExamSections(content) : null;
    const examSectionsLength = examSections ? examSections.length : 0;
    const examPagesStore = getExamPagesStore(idChapter);
    const examProgressStore = getExamPagesStore(idChapter, true);

    /* EXAM PAGINATION */
    const [examPage, setExamPage] = useState(
        examPagesStore
            ? examPagesStore
            : isGibs
            ? 1
            : isAnswered
            ? pagerDirection === "backwards"
                ? examSectionsLength
                : 1
            : getExamProgress(answers, content, true, isGibs) ?? 1
    );

    const [examProgress, setExamProgress] = useState(
        examProgressStore
            ? examProgressStore
            : isGibs
            ? 1
            : isAnswered
            ? examSectionsLength
            : getExamProgress(answers, content, false, isGibs) ?? 1
    );

    const examPages = useRef({
        old: null,
        new: examPage,
    });

    /* SET EXAM PAGES TO STORE WHEN EXAM IS ANSWERED */
    useEffect(() => {
        if (isAnswered && !examPagesStore && examSectionsLength > 0) {
            if (pagerDirection === "backwards") {
                /* SET LAST PAGE WHEN COMING FROM NEXT CHAPTER */
                setExamPagesStore(idChapter, examSectionsLength);
            } else {
                /* SET FIRST PAGE WHEN COMING FROM PREVIOUS OR REFRESHING CURRENT CHAPTER */
                setExamPagesStore(idChapter, 1);
            }

            /* CLEAR PAGER DIRECTION AFTER SETTING PAGES STORE */
            setPagerDirection(null);
        }
    }, [
        isAnswered,
        examPagesStore,
        pagerDirection,
        setExamPagesStore,
        idChapter,
        examSectionsLength,
        setPagerDirection,
    ]);

    /* EXAM PENDING STATE */
    const examPendingFlag = useRef(false);
    const [isExamPending, setIsExamPending] = useState(false);

    /* EXAM PAGE PENDING START */
    useEffect(() => {
        if (!examPendingFlag.current && examRef.current) {
            examPages.current.old = examPages.current.new;

            if (examPages.current.old !== examPage) {
                examPendingFlag.current = true;

                setIsExamPending(true);
            }
        }
    }, [examPage]);

    /* EXAM PAGE PENDING FINISH */
    useEffect(() => {
        if (isExamPending) {
            const pendingDuration =
                parseFloat(
                    window
                        .getComputedStyle(examRef.current)
                        .getPropertyValue("transition-duration")
                ) * 1000;

            if (isGallup) {
                if (examSectionsLength < examPage) {
                    isGallupResultMount.current = true;
                } else {
                    isGallupResultMount.current = false;
                }
            }

            examPages.current.new = examPage;

            setExamPagesStore(idChapter, examPage);

            setTimeout(() => {
                examPendingFlag.current = false;

                isPagerDisabled.current = false;

                setPagerDirection(null);

                setIsExamPending(false);
            }, pendingDuration);
        }
    }, [
        isExamPending,
        examPage,
        examSectionsLength,
        isGallup,
        isGallupResultMount,
        getExamResultStore,
        setExamPagesStore,
        idChapter,
        isPagerDisabled,
        setPagerDirection,
    ]);

    /* GALLUP SET FINISH STATE AFTER PENDING */
    useEffect(() => {
        if (
            !isExamPending &&
            isGallup &&
            isGallupResultMount.current &&
            !isGallupResultDone
        ) {
            setIsGallupResultDone(true);
            setIsGallupResultLoading(false);
        }
    }, [
        isExamPending,
        isGallup,
        isGallupResultMount,
        isGallupResultDone,
        setIsGallupResultDone,
        setIsGallupResultLoading,
    ]);

    return (
        <>
            {content.length > 0 ? (
                !isExamAnswersStale ? (
                    <>
                        {!isGallup && !isGibs && examSectionsLength > 1 && (
                            <ReaderProgress
                                type="test"
                                numbers={{
                                    current: examPage,
                                    total: examSectionsLength,
                                }}
                            />
                        )}
                        <div
                            className={
                                "ReaderExam" +
                                (isExamPending ? " _is--pending" : "")
                            }
                            ref={examRef}
                        >
                            {isGallup && (
                                <ReaderPager
                                    idChapter={idChapter}
                                    pages={pages}
                                    examPages={{
                                        examPage: examPages.current.new,
                                        setExamPage,
                                        examTotalPages: examSectionsLength,
                                    }}
                                    examProgress={examProgress}
                                    setExamProgress={setExamProgress}
                                    pager={pager}
                                    cover={cover}
                                    isPending={isPending || isExamPending}
                                    isLoading={isLoading}
                                    isExam={true}
                                    isGallup={isGallup}
                                    isGibs={isGibs}
                                    isAnswered={isAnswered}
                                    isShared={isShared === 1 ? true : false}
                                />
                            )}
                            <ReaderPage isExam={true}>
                                <div className="ReaderExam__Content">
                                    <ReaderComponents
                                        content={
                                            isGibs
                                                ? content
                                                : isGallupResultMount.current
                                                ? examPages.current.new -
                                                      examSectionsLength >=
                                                      0 &&
                                                  examPages.current.new -
                                                      examSectionsLength <=
                                                      examSectionsLength
                                                    ? getExamResultStore()[
                                                          examPages.current
                                                              .new -
                                                              examSectionsLength -
                                                              1
                                                      ].childs
                                                    : undefined
                                                : examSectionsLength >=
                                                  examPages.current.new
                                                ? examSections[
                                                      examPages.current.new - 1
                                                  ].childs
                                                : undefined
                                        }
                                        examContent={content}
                                        answersSolved={answersSolved}
                                        note={note}
                                        setExamProgress={setExamProgress}
                                        userAvatar={userAvatar}
                                        isPending={isPending || isExamPending}
                                        isExam={true}
                                        isGallup={isGallup}
                                        isGibs={isGibs}
                                        isAnswered={isAnswered}
                                    />
                                    {isGallup &&
                                        examPages.current.new ===
                                            examSectionsLength &&
                                        !isGallupResultDone && (
                                            <ReaderGallup
                                                idChapter={idChapter}
                                                examPage={examPages.current.new}
                                                examProgress={examProgress}
                                                onFinish={() =>
                                                    setExamPage(
                                                        examSectionsLength + 1
                                                    )
                                                }
                                            />
                                        )}
                                    {isGibs && !isGibsResultDone && (
                                        <ReaderGibs
                                            idChapter={idChapter}
                                            examPage={examPages.current.new}
                                            examProgress={examProgress}
                                            onFinish={() =>
                                                setPage(
                                                    (prevPage) => prevPage + 1
                                                )
                                            }
                                        />
                                    )}
                                </div>
                            </ReaderPage>
                            {!isGibs && (
                                <ReaderPager
                                    idChapter={idChapter}
                                    className={
                                        "_sticky--bottom" +
                                        (isGibs ? " _solo--bottom" : "")
                                    }
                                    pages={pages}
                                    examPages={{
                                        examPage: examPages.current.new,
                                        setExamPage,
                                        examTotalPages: examSectionsLength,
                                    }}
                                    examProgress={examProgress}
                                    setExamProgress={setExamProgress}
                                    cover={cover}
                                    pager={pager}
                                    isPending={isPending || isExamPending}
                                    isLoading={isLoading}
                                    isExam
                                    isGallup={isGallup}
                                    isGibs={isGibs}
                                    isAnswered={isAnswered}
                                    isShared={isShared === 1 ? true : false}
                                />
                            )}
                        </div>
                        {isGallup && (
                            <ReaderProgress
                                className="_progress--page"
                                color={isGallup ? "secondary" : ""}
                                numbers={{
                                    current: examPage,
                                    total: examSectionsLength * 2,
                                }}
                            />
                        )}
                    </>
                ) : (
                    ""
                )
            ) : (
                <>
                    <ReaderPager
                        pages={pages}
                        pager={pager}
                        cover={cover}
                        isPending={isPending || isExamPending}
                        isLoading={isLoading}
                    />
                    <ReaderPage title="Błąd - Test nie został uzupełniony w panelu administracyjnym">
                        <ReaderComponents
                            content={[
                                {
                                    type: "fact",
                                    content:
                                        "<p>Czy wiedziałeś, że aplikacje od czasu do czasu może wylecieć w kosmos? Kiedy wyskakuje błąd DEV się tym zajmuje od ręki!</p>",
                                    idChapter: 2115,
                                    idChapterContent: 2137,
                                },
                            ]}
                        ></ReaderComponents>
                    </ReaderPage>
                    <ReaderPager
                        className="_sticky--bottom"
                        pages={pages}
                        pager={pager}
                        cover={cover}
                        isPending={isPending || isExamPending}
                        isLoading={isLoading}
                    />
                </>
            )}
        </>
    );
};

export default ReaderExam;
