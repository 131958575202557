const getColor = (color) => {
    switch (color) {
        case "secondary":
            return "#85D6A6";
        case "secondary-dark":
            return "#3DAF6B";
        default:
            return "#0c6ffa";
    }
};

const PlayIcon = ({ color }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="47"
            height="47"
            viewBox="0 0 47 47"
        >
            <g
                id="play_video_2"
                data-name="play video 2"
                transform="translate(-164 -1326)"
            >
                <circle
                    id="Ellipse_126"
                    data-name="Ellipse 126"
                    cx="23.5"
                    cy="23.5"
                    r="23.5"
                    transform="translate(164 1326)"
                    fill={getColor(color)}
                />
                <path
                    id="Polygon_2"
                    data-name="Polygon 2"
                    d="M11.052,0,22.1,19.736H0Z"
                    transform="translate(200.708 1338.266) rotate(90)"
                    fill="#fff"
                />
            </g>
        </svg>
    );
};

export default PlayIcon;
