import { useRef, useEffect } from "react";

import Title from "../headings/Title";

const RepeaterSelect = ({
    heading,
    placeholder,
    data,
    returnedData,
    query,
    modalFontLarge,
    max,
    modalSetters,
    isDisabled
}) => {
    const { setModalData } = modalSetters;

    const idSelected = useRef(null);

    const handleRepeaterClick = (id) => {
        idSelected.current = id;

        setModalData(modalData.current);
    };

    const handleRepeaterAdd = (element) => {
        const idCurrent = idSelected.current;
        const tempData = [];

        if (idCurrent) {
            for (const object of data) {
                if (object[query.dataKey.id] === idCurrent) {
                    tempData.push(element);
                } else {
                    tempData.push(object);
                }
            }

            returnedData(tempData);
        } else {
            returnedData([...data, element]);
        }
    };

    const handleRepeaterRemove = (id) => {
        const tempData = data.filter(
            (object) => object[query.dataKey.id] !== id
        );

        returnedData(tempData);
    };

    /* MODAL DATA */
    const modalData = useRef({});

    useEffect(() => {
        modalData.current = {
            select: true,
            close: true,
            data: [
                {
                    className: modalFontLarge ? "_font--large" : "",
                    query: {
                        url: query.url,
                        queryKey: query.queryKey,
                        selectedData: data,
                        dataKey: query.dataKey,
                        backendSearch: query?.backendSearch,
                    },
                    actionDispatch: {
                        dispatch: (element) => handleRepeaterAdd(element),
                    },
                },
            ],
        };
    });

    return (
        <div className="RepeaterSelect">
            {heading && data.length > 0 && (
                <div className="RepeaterSelect__Heading">
                    <Title className="medium" type={2}>
                        {heading.title}
                    </Title>
                    <p>{heading.text.replace("[counter]", max ? max : "")}</p>
                </div>
            )}
            <div className="RepeaterSelect__List">
                {data.map((object) => {
                    return (
                        <RepeaterField
                            key={
                                query.dataKey.name +
                                "-repeater_" +
                                object[query.dataKey.id]
                            }
                            id={object[query.dataKey.id]}
                            name={object.name}
                            isDisabled={isDisabled}
                            onClick={(id) => handleRepeaterClick(id)}
                            onRequestRemove={(id) => handleRepeaterRemove(id)}
                        />
                    );
                })}
                {max ? (
                    max > data.length ? (
                        <RepeaterField
                            placeholder={placeholder}
                            isDisabled={isDisabled}
                            isFirst={data.length === 0}
                            onClick={(id) => handleRepeaterClick(id)}
                        />
                    ) : (
                        ""
                    )
                ) : (
                    <RepeaterField
                        placeholder={placeholder}
                        isDisabled={isDisabled}
                        isFirst={data.length === 0}
                        onClick={(id) => handleRepeaterClick(id)}
                    />
                )}
            </div>
        </div>
    );
};

const RepeaterField = ({
    id,
    name,
    placeholder = {},
    isDisabled,
    isFirst,
    onClick,
    onRequestRemove,
}) => {
    const { origin, repeated } = placeholder;

    return (
        <div className="RepeaterField">
            <button
                className={"RepeaterField__Button" + (id ? " _selected" : "")}
                type="button"
                disabled={isDisabled}
                onClick={() => onClick(id)}
            >
                {id ? name : isFirst ? origin : repeated ?? origin}
                {!id && (
                    <span className="RepeaterField__Plus">
                        <span className="RepeaterField__PlusInner"></span>
                    </span>
                )}
            </button>
            {id && (
                <button
                    className="ReapeterField__Remove"
                    type="button"
                    disabled={isDisabled}
                    onClick={() => onRequestRemove(id)}
                ></button>
            )}
        </div>
    );
};

export default RepeaterSelect;
