import parseFlashcardObject from "./parseFlashcardObject";
import setObjectKey from "./setObjectKey";

const parseFlashcardContent = (object, flash) => {
    let map = [];
    let index = 0;

    if (Array.isArray(object)) {
        for (const item of object) {
            if (
                item.type === "h1" ||
                item.type === "h2" ||
                item.type === "h3" ||
                item.type === "h4" ||
                item.type === "h5" ||
                item.type === "h6"
            ) {
                map.push(item);
            } else {
                map.push(parseFlashcardObject(item, flash, index));

                index++;
            }
        }
    } else if (typeof object === "object") {
        map.push(parseFlashcardObject(setObjectKey(object), flash, index));
    }

    return map;
};

export default parseFlashcardContent;
