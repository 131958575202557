import React, { useState, useEffect, useRef } from "react";

import Title from "../../../headings/Title";
import Loader from "../../Loader";

import LibraryBook from "./LibraryBook";
import LibraryEmpty from "./LibraryEmpty";

const LibraryList = ({
    title,
    data,
    resetData,
    resetDataFlag,
    resetSearchForce,
    nextPage,
    inlineRemoving,
    isLoading,
}) => {
    /* DATA UPDATE FLAG */
    const dataIsUpdated = useRef(false);

    /* PAGE STATES */
    const [page, setPage] = useState(1);
    const isLastPage = useRef(false);
    const [pageLoading, setPageLoading] = useState(false);

    /* BOOK STATES */
    const [books, setBooks] = useState([]);
    const [lastBook, setLastBook] = useState(null);
    const booksEmpty = useRef(true);
    const [booksLoading, setBooksLoading] = useState(false);

    /* BOOK OBSERVER */
    const bookObserver = useRef(
        new IntersectionObserver((entry) => {
            const inView = entry[0].isIntersecting;

            if (inView && !isLastPage.current && dataIsUpdated.current) {
                setPage((prevPage) => prevPage + 1);
                setPageLoading(true);
            }
        })
    );

    /* RESET BOOKS ON FILTER CHANGE */
    useEffect(() => {
        if (isLoading) {
            setBooks([]);

            dataIsUpdated.current = false;
            booksEmpty.current = true;

            setBooksLoading(true);
        }
    }, [isLoading]);

    /* SET NEW BOOKS IF DATA CHANGES */
    useEffect(() => {
        if (!isLoading) {
            if (data?.length < 11) {
                isLastPage.current = true;

                /* RESET FLAG FOR LAST PAGE */
                resetDataFlag();
            }

            if (!dataIsUpdated.current) {
                if (data) {
                    setBooks((prevBooks) => [...prevBooks, ...data]);

                    dataIsUpdated.current = true;

                    setPageLoading(false);
                }
            }
        }
    }, [data, isLoading, resetDataFlag]);

    /* SET EMPTY BOOKS */
    useEffect(() => {
        if (dataIsUpdated.current) {
            if (books.length > 0) {
                booksEmpty.current = false;
            } else {
                booksEmpty.current = true;
            }

            setBooksLoading(false);
        }
    }, [books]);

    /* SET NEXT PAGE */
    useEffect(() => {
        if (page > 1) {
            dataIsUpdated.current = false;

            nextPage(page);
        }
    }, [page, nextPage]);

    /* RESET PAGE ON FILTER CHANGE */
    useEffect(() => {
        if (resetData) {
            isLastPage.current = false;

            setPage(1);
        }
    }, [resetData]);

    /* OBSERVE LAST BOOK */
    useEffect(() => {
        const currentBook = lastBook;
        const currentObserver = bookObserver.current;

        if (currentBook) {
            currentObserver.observe(currentBook);
        }

        return () => {
            if (currentBook) {
                currentObserver.unobserve(currentBook);
            }
        };
    }, [lastBook]);

    /* INLINE REMOVE HANDLER */
    const inlineBookRemoveHandler = (id) => {
        const newBooks = books.filter((e) => {
            if (e.idMultibook === id) {
                return false;
            } else {
                return true;
            }
        });

        setBooks(newBooks);
    };

    return (
        <>
            <div className="LibraryList">
                <Title className="medium text-center">{title}</Title>
                {isLoading ? (
                    <div className="LibraryList__Loader">
                        <Loader type="ring" />
                    </div>
                ) : (
                    !booksLoading &&
                    (booksEmpty.current ? (
                        <LibraryEmpty
                            message="Brak wyników"
                            onClickFn={() => resetSearchForce()}
                        />
                    ) : (
                        <>
                            <div className="LibraryList__Wrapper">
                                {books.map((object, index) => {
                                    return (
                                        <div
                                            ref={
                                                index === books.length - 1
                                                    ? setLastBook
                                                    : undefined
                                            }
                                            className="LibraryList__Item"
                                            key={
                                                "multibook" +
                                                "_" +
                                                object.idMultibook
                                            }
                                        >
                                            <LibraryBook
                                                data={{
                                                    id: {
                                                        category:
                                                            object.idCategory,
                                                        multibook:
                                                            object.idMultibook,
                                                    },
                                                    type: object.type,
                                                    cover: object.cover,
                                                    title: object.title,
                                                    content: object.subtitle,
                                                    favorite: object.favorite,
                                                }}
                                                remove={
                                                    inlineRemoving
                                                        ? (id) =>
                                                              inlineBookRemoveHandler(
                                                                  id
                                                              )
                                                        : undefined
                                                }
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                            {pageLoading ? (
                                <div className="LibraryList__Loader _pagination">
                                    <Loader
                                        className="_size--small"
                                        type="ring"
                                    />
                                </div>
                            ) : (
                                ""
                            )}
                        </>
                    ))
                )}
            </div>
        </>
    );
};

export default LibraryList;
