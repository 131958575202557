import { useState, useRef, useEffect } from "react";
import { useCookies } from "react-cookie";
import { useHistory } from "react-router-dom";
import { useQueryClient } from "react-query";

import { getCookieTime } from "../../App";
import getRedirectPath from "./functions/getRedirectPath";

import ReaderLoader from "./utils/ReaderLoader";

import doodle from "./assets/images/doodle-loader.svg";

export const removeBookData = (queryClient) => {
    queryClient.removeQueries("multibookShow");
    queryClient.removeQueries("multibookChapters");
    queryClient.removeQueries("multibookChapter");
    queryClient.removeQueries("examAnswers");
    queryClient.removeQueries("examSolved");
    queryClient.removeQueries("examResult");
};

const ReaderLoading = ({
    theme,
    isLoading,
    isShutdown,
    isShutting,
    isGibs,
    isGallup,
}) => {
    const [cookies, setCookie, removeCookie] = useCookies(["userPath"]);
    const history = useHistory();
    const queryClient = useQueryClient();

    /* MULTIBOOK LOADING DEFAULTS */
    const isLoaderUnmouting = useRef(false);
    const isLoaderMounting = useRef(false);
    const isLoaderShutting = useRef(false);

    const [isLoaderMount, setIsLoaderMount] = useState(true);
    const [isBookReady, setIsBookReady] = useState(false);
    const [isBookExiting, setIsBookExiting] = useState(false);

    /* MULTIBOOK ENTER HANDLER */
    useEffect(() => {
        if (!isLoading && !isShutdown && !isLoaderUnmouting.current) {
            isLoaderUnmouting.current = true;

            setTimeout(() => {
                setIsBookReady(true);
            }, 500);
        }
    }, [isLoading, isShutdown]);

    useEffect(() => {
        if (isBookReady && isLoaderMount && !isShutdown) {
            setTimeout(() => {
                setIsLoaderMount(false);
            }, 300);
        }
    }, [isBookReady, isLoaderMount, isShutdown]);

    /* MULTIBOOK SHUTDOWN HANDLER */
    useEffect(() => {
        if (isShutdown && !isLoaderMount) {
            setIsLoaderMount(true);
        }

        if (isShutdown && isLoaderMount && !isLoaderMounting.current) {
            isLoaderMounting.current = true;

            setTimeout(() => {
                setIsBookExiting(true);
            }, 10);
        }
    }, [isShutdown, isLoaderMount]);

    /* MULTIBOOK SHUTDOWN FINISH */
    useEffect(() => {
        if (isShutting && !isLoaderShutting.current) {
            isLoaderShutting.current = true;

            setTimeout(() => {
                setCookie("userPathClone", cookies?.userPath, {
                    path: "/",
                    expires: getCookieTime(),
                });
                removeCookie("chapterRedirect", { path: "/" });

                document.body.classList.remove("ReaderPanel--Open");

                removeBookData(queryClient);

                history.push(getRedirectPath(cookies?.userPath?.current));
            }, 500);
        }
    }, [isShutting, cookies, history, queryClient, removeCookie, setCookie]);

    return (
        isLoaderMount && (
            <div
                className={
                    "ReaderLoading" +
                    (theme === "dark" ? " _theme--dark" : "") +
                    (isGibs || isGallup ? " _color--secondary" : "") +
                    ((isBookReady && !isBookExiting) || isShutting
                        ? " _is--ready"
                        : "")
                }
                data-panel="false"
            >
                <div className="ReaderLoading__Wrapper">
                    <div className="ReaderLoading__Container">
                        <div className="ReaderLoading__Message">
                            <div className="ReaderLoading__Text">
                                {isShutdown
                                    ? "Trwa wyłączanie Smartbooka"
                                    : "Trwa wczytywanie Smartbooka"}
                            </div>
                        </div>
                        <div className="ReaderLoading__Loader">
                            <ReaderLoader
                                color={isGibs || isGallup ? "secondary" : ""}
                            />
                        </div>
                        <div className="ReaderLoading__Doodle">
                            <img
                                className="ReaderLoading__Img"
                                src={doodle}
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    );
};

export default ReaderLoading;
