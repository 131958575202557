const validChars = [' ', '.', ',', '?', ':', ';', '/', '!'];

const checkIfNextCharIsValid = (char) => {
    let isValid = false;

    if(char) {
        for(const validChar of validChars) {
            if(char === validChar) {
                isValid = true;
            }
        }
    } else {
        isValid = true;
    }

    return isValid;
}

export default checkIfNextCharIsValid;