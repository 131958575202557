import { useState, useEffect } from "react";

import useActionLoading from "../../../hooks/useActionLoading";
import useNotifications from "../../../hooks/useNotifications";
import useCheckSubscription from "../../../hooks/useCheckSubscription";

import calendarIcon from "../../../assets/images/dashboard/navigation/calendar.svg";
import groupProjectsIcon from "../../../assets/images/dashboard/navigation/group-projects.svg";
import homeIcon from "../../../assets/images/dashboard/navigation/home.svg";
import libraryIcon from "../../../assets/images/dashboard/navigation/library.svg";
import myChildrenIcon from "../../../assets/images/dashboard/navigation/my-children.svg";
import mySchoolsIcon from "../../../assets/images/dashboard/navigation/my-schools.svg";
import myShelfIcon from "../../../assets/images/dashboard/navigation/my-shelf.svg";
import notificationsIcon from "../../../assets/images/dashboard/navigation/notifications.svg";

const getIcon = (icon) => {
    switch (icon) {
        case "calendar":
            return calendarIcon;
        case "group-projects":
            return groupProjectsIcon;
        case "home":
            return homeIcon;
        case "library":
            return libraryIcon;
        case "my-children":
            return myChildrenIcon;
        case "my-schools":
            return mySchoolsIcon;
        case "my-shelf":
            return myShelfIcon;
        case "notifications":
            return notificationsIcon;
        default:
            return "";
    }
};

const Navigation = ({ navs }) => {
    const { isActionLoading } = useActionLoading();

    /* NOTIFICATIONS BADGE */
    const [isNotificationsBadgeActive, setIsNotificationsBadgeActive] =
        useState(false);

    const {
        notificationsData,
        isNotificationsFetched,
        isNotificationsLoading,
    } = useNotifications("notifications-counter");

    const isNotificationsReady =
        isNotificationsFetched && !isNotificationsLoading;

    useEffect(() => {
        if (
            isNotificationsReady &&
            notificationsData?.length > 0 &&
            !isNotificationsBadgeActive
        ) {
            setIsNotificationsBadgeActive(true);
        } else if (
            notificationsData?.length === 0 &&
            isNotificationsBadgeActive
        ) {
            setTimeout(() => {
                setIsNotificationsBadgeActive(false);
            }, 1000);
        }
    }, [isNotificationsReady, notificationsData, isNotificationsBadgeActive]);

    /* CHECK USER SUBSCRIPTION AND UNLOCK NAVIGATION */
    const {
        isSubscriptionActive,
        isSubscriptionFetched,
        isSubscriptionLoading,
    } = useCheckSubscription();

    const isSubscriptionReady = isSubscriptionFetched && !isSubscriptionLoading;

    return (
        <>
            {navs?.length > 0 && (
                <div className="Navigation">
                    <div className="Navigation__Wrapper">
                        {navs.map((object, index) => {
                            return (
                                <div
                                    className="Navigation__Item"
                                    key={"Navigation__Item_" + index}
                                >
                                    <button
                                        className={"Navigation__Button" + ((object.type === 'my-shelf' || object.type === 'library') && !isSubscriptionActive ? ' _is--inactive' : '')}
                                        type="button"
                                        disabled={
                                            object.disabled ||
                                            isActionLoading || !isSubscriptionReady || ((object.type === 'my-shelf' || object.type === 'library') && !isSubscriptionActive)
                                        }
                                        onClick={() => {
                                            if (
                                                typeof object.onClick ===
                                                "function"
                                            ) {
                                                object.onClick();
                                            }
                                        }}
                                    >
                                        <span className="Navigation__Icon">
                                            <img
                                                src={getIcon(object.icon)}
                                                alt=""
                                            />
                                            {object.type ===
                                                "notifications" && (
                                                <span
                                                    className={
                                                        "Navigation__Badge" +
                                                        (isNotificationsBadgeActive
                                                            ? " _is--active"
                                                            : "")
                                                    }
                                                >
                                                    {notificationsData?.length}
                                                </span>
                                            )}
                                        </span>
                                        <span className="Navigation__Text">
                                            {object.text}
                                        </span>
                                    </button>
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
        </>
    );
};

export default Navigation;
