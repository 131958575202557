const getExamAnswers = (idContent, answers) => {
    if (!answers) return;

    const answer = answers.filter(
        (element) => parseInt(element.idContent) === idContent
    );

    return answer.length > 0 ? answer[0].content : undefined;
};

export default getExamAnswers;
