import { useContext, useState } from "react";
import { useQuery } from "react-query";

import { StoreContext } from "../../../context/Store";

import Title from "../../headings/Title";
import Input from "../../inputs/Input";
import Button from "../../buttons/Button";

import validator from "../../../functions/validator";

import SigningFormContainer from "../../containers/signing/SigningFormContainer";
import resetCodeFormErrorHandler from "../../../functions/errorHandlers/resetCodeFormErrorHandler";

const ResetCodeForm = ({
    inputData,
    inputValidatorData,
    inputUpdate,
    inputValidatorUpdate,
    setSigningFormActive,
}) => {
    const { api } = useContext(StoreContext);

    const { refetch: resetCodeFormRefetch } = useQuery(
        "reset_password_confirm",
        () =>
            api.post(`/reset_password_confirm`, {
                token: inputData.code,
                email: inputData.email,
            }),
        {
            retry: false,
            enabled: false,
            refetchOnWindowFocus: false,
            onSuccess: () =>
                setSigningFormActive((prevState) => ({
                    ...prevState,
                    code: false,
                    reset: true,
                })),
            onError: (error) =>
                resetCodeFormErrorHandler(
                    error.response.status,
                    error.response.data.errors,
                    inputValidatorUpdate({
                        ...inputValidatorData,
                        code: {
                            response: "error",
                            errorMessage: "Nieprawidłowy kod",
                        },
                    })
                ),
        }
    );

    /* E-MAIL ON CHANGE HANDLER */
    const emailOnChangeHandler = (e) => {
        if (e.length > 0) {
            validator("email", e)
                ? inputValidatorUpdate({
                      email: {
                          response: "success",
                          errorMessage: "",
                      },
                  })
                : inputValidatorUpdate({
                      email: {
                          response: "error",
                          errorMessage: "",
                      },
                  });
        } else {
            inputValidatorUpdate({
                email: {
                    response: "",
                    errorMessage: "",
                },
            });
        }

        inputUpdate({ email: e });
    };

    /* CODE ON CHANGE HANDLER */
    const codeOnChangeHandler = (e) => {
        if (e.length > 0) {
            if (e.length >= 4) {
                inputValidatorUpdate({
                    code: {
                        response: "success",
                        errorMessage: "",
                    },
                });
            } else {
                inputValidatorUpdate({
                    code: {
                        response: "error",
                        errorMessage: "",
                    },
                });
            }
        } else {
            inputValidatorUpdate({
                code: {
                    response: "",
                    errorMessage: "",
                },
            });
        }

        inputUpdate({ code: e });
    };

    /* ON ENTER KEY */
    const onEnterKeyHandler = (e) => {
        const enterKey = e.charCode === 13 || e.keyCode === 13;

        if (!formSubmitDisable && enterKey) {
            e.currentTarget.blur();
            resetCodeFormRefetch();
        }
    };

    /* ENABLE SUBMIT IF VALIDATION IS CORRECT */
    const formSubmitDisable =
        inputValidatorData.email.response === "success" &&
        inputValidatorData.code.response === "success"
            ? false
            : true;

    return (
        <>
            <div className="app-signing-form">
                <Title className="text-center">
                    Wpisz kod, który otrzymałeś na e-mail
                </Title>
                <SigningFormContainer classNameWrapper="signing">
                    <Input
                        key="Input"
                        type="email"
                        placeholder="wpisz e-mail"
                        onChangeFn={(e) =>
                            emailOnChangeHandler(e.currentTarget.value)
                        }
                        onKeyPressFn={(e) => onEnterKeyHandler(e)}
                        isValid={{
                            response: inputValidatorData.email.response,
                            text: inputValidatorData.email.errorMessage,
                        }}
                    />
                    <Input
                        key="Input"
                        type="text"
                        placeholder="wpisz kod"
                        onChangeFn={(e) =>
                            codeOnChangeHandler(e.currentTarget.value)
                        }
                        onKeyPressFn={(e) => onEnterKeyHandler(e)}
                        isValid={{
                            response: inputValidatorData.code.response,
                            text: inputValidatorData.code.errorMessage,
                        }}
                    />
                    <Button
                        key="Button"
                        isDisabled={formSubmitDisable}
                        onClickFn={() => resetCodeFormRefetch()}
                    >
                        Wyślij
                    </Button>
                </SigningFormContainer>
            </div>
        </>
    );
};

export default ResetCodeForm;
