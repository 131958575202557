const parseTagsFromString = (data, str) => {
    let tempArray = [];
    let arrayFromStr = str.split(",");

    for (const item of data) {
        for (const idStr of arrayFromStr) {
            if (parseInt(idStr) === item.idTag) {
                tempArray.push({ idTag: item.idTag, name: item.name });
            }
        }
    }

    return tempArray;
};

export default parseTagsFromString;
