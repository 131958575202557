import { useState, useRef, useContext } from "react";
import { useCookies } from "react-cookie";

import { ExamContext } from "../context/ExamStore";
import { ScoreContext } from "../context/ScoreStore";
import { FlashcardContext } from "../context/FlashcardStore";

import useSaveExamAnswers from "../hooks/useSaveExamAnswers";

import ReaderRedirect from "../buttons/ReaderRedirect";

/* VENDOR PREFIXES OF REQUESTANIMATIONFRAME */
(() => {
    const browser = ["ms", "moz", "webkit", "o"];

    for (let i = 0; i < browser.length && !window.requestAnimationFrame; i++) {
        window.requestAnimationFrame =
            window[browser[i] + "RequestAnimationFrame"];
        window.cancelAnimationFrame =
            window[browser[i] + "CancelAnimationFrame"] ||
            window[browser[i] + "CancelRequestAnimationFrame"];
    }
})();

/* EXAM PAGE SCROLL TIME */
export const examPageScrollTime = 500;

/* EXAM PAGE SCROLL TOP */
export const examPageScrollTop = (animationTime, callback) => {
    let requestAnimation = null;
    let startTime = null;

    const pageElement = document.querySelector(".ReaderBook");
    const startPosition = pageElement.scrollTop;

    let animationTimeFixed =
        animationTime * +((200 * 100) / startPosition / 100).toFixed(2);

    if (animationTimeFixed > animationTime) {
        animationTimeFixed = animationTime;
    }

    if (animationTimeFixed <= 200) {
        animationTimeFixed = 200;
    }

    if (startPosition > 0) {
        pageElement.style.overflowY = "hidden";

        const easeInOutQuad = function (x) {
            return x * (2 - x);
        };

        const stepAnimation = (time) => {
            if (!startTime) startTime = time;

            const elapsedTime = time - startTime;
            const progress = elapsedTime / animationTimeFixed;

            const easePercentage = +easeInOutQuad(progress).toFixed(2);

            if (easePercentage >= 1 || pageElement.scrollTop === 0) {
                pageElement.scrollTop = 0;

                pageElement.style.overflowY = "";

                window.cancelAnimationFrame(requestAnimation);
            } else {
                pageElement.scrollTop =
                    startPosition - startPosition * easePercentage;

                requestAnimation = window.requestAnimationFrame(stepAnimation);
            }
        };

        requestAnimation = window.requestAnimationFrame(stepAnimation);
    }

    if (typeof callback === "function") {
        setTimeout(() => {
            callback();
        }, Math.floor(animationTimeFixed * 0.33));
    }
};

const ReaderPager = ({
    idChapter,
    className,
    pages = {},
    examPages = {},
    examProgress,
    setExamProgress,
    pager,
    cover = {},
    isPending,
    isLoading,
    isExam,
    isGallup,
    isGibs,
    isAnswered,
    isShared,
    isShutdown,
}) => {
    const { page, setPage, pagesTotal } = pages;
    const { isPagerDisabled, pagerDirection, setPagerDirection } = pager;
    const { examPage, setExamPage, examTotalPages } = examPages;
    const { setIsCoverPending } = cover;

    const {
        isGallupResultLoading,
        isGallupResultDone,
        isGibsResultLoading,
        isGibsResultDone,
    } = useContext(ExamContext);
    const { setExamScoreMount, isExamScoreLoading, isExamScoreInit } =
        useContext(ScoreContext);
    const { flashElement, setFlashElement } = useContext(FlashcardContext);

    const { examAnswersSave } = useSaveExamAnswers(idChapter);

    /* COOKIE HOOK */
    const cookies = useCookies(["idRank"])[0];

    /* QUEUE DEFAULTS */
    const [queueUp, setQueueUp] = useState(false);
    const queueTimeout = useRef(0);

    /* SET QUEUE IF FLASHCARD IS OPEN */
    const queueHandler = () => {
        if (flashElement) {
            setFlashElement(null);
            setQueueUp(true);

            queueTimeout.current = 300;
        } else {
            queueTimeout.current = 0;
        }
    };

    /* BACK HANDLER */
    const goBackHandler = () => {
        if (!isPagerDisabled.current) {
            isPagerDisabled.current = true;

            queueHandler();

            setTimeout(() => {
                setPagerDirection("backwards");

                if (isExam) {
                    if (!isAnswered && !isGallupResultDone && !isGibs) {
                        examAnswersSave();
                    }

                    if (examPage === 1) {
                        if (page > 1) {
                            setPage((prevPage) => prevPage - 1);
                        } else {
                            setIsCoverPending(true);
                        }
                    } else {
                        examPageScrollTop(examPageScrollTime, () =>
                            setExamPage((prevPage) => prevPage - 1)
                        );
                    }
                } else {
                    if (page > 1) {
                        setPage((prevPage) => prevPage - 1);
                    } else {
                        setIsCoverPending(true);
                    }
                }
            }, queueTimeout.current);
        }
    };

    /* FORWARD HANDLER */
    const goForwardHandler = () => {
        if (!isPagerDisabled.current) {
            isPagerDisabled.current = true;

            queueHandler();

            setTimeout(() => {
                setPagerDirection("forward");

                if (isExam) {
                    if (examPage >= examTotalPages) {
                        if (isAnswered || isGibsResultDone) {
                            if (!isGibs && !isShared && parseInt(cookies.idRank) === 3) {
                                setExamScoreMount({
                                    idChapter,
                                    examTotalPages,
                                    setExamPage,
                                    setExamProgress,
                                    isAnswered,
                                });
                            } else {
                                setPage((prevPage) => prevPage + 1);
                            }
                        } else if (isGallupResultDone) {
                            if (examPage >= examTotalPages * 2) {
                                setPage((prevPage) => prevPage + 1);
                            } else {
                                examPageScrollTop(examPageScrollTime, () =>
                                    setExamPage((prevPage) => prevPage + 1)
                                );
                            }
                        } else {
                            setExamScoreMount({
                                idChapter,
                                examTotalPages,
                                setExamPage,
                                setExamProgress,
                                isAnswered: false,
                            });
                        }
                    } else {
                        if (!isAnswered && !isGallupResultDone && !isGibs) {
                            examAnswersSave();
                        }

                        examPageScrollTop(examPageScrollTime, () =>
                            setExamPage((prevPage) => prevPage + 1)
                        );
                    }
                } else {
                    setPage((prevPage) => prevPage + 1);
                }
            }, queueTimeout.current);
        }
    };

    return (
        <>
            <div className={"ReaderPager" + (className ? " " + className : "")}>
                <div className="ReaderPager__Item _pager--prev">
                    <ReaderRedirect
                        text="Wróć"
                        color={isGallup || isGibs ? "secondary" : undefined}
                        inverted
                        isDisabled={
                            isPending ||
                            isLoading ||
                            queueUp ||
                            isExamScoreLoading ||
                            isExamScoreInit ||
                            isGallupResultLoading ||
                            isGibsResultLoading ||
                            isShutdown
                        }
                        isLoading={pagerDirection === "backwards" && isLoading}
                        onClick={goBackHandler}
                    />
                </div>
                {(!isExam && page < pagesTotal + 1) || isExam ? (
                    <div className="ReaderPager__Item _pager--next">
                        <ReaderRedirect
                            text={
                                isExam
                                    ? (examPage >= examTotalPages &&
                                          !isAnswered &&
                                          !isGallup &&
                                          !isGibs) ||
                                      (examPage >= examTotalPages &&
                                          isAnswered &&
                                          !isShared && parseInt(cookies.idRank) === 3)
                                        ? "Podsumowanie"
                                        : "Dalej"
                                    : "Dalej"
                            }
                            color={isGallup || isGibs ? "secondary" : undefined}
                            isDisabled={
                                isPending ||
                                isLoading ||
                                queueUp ||
                                (isExam &&
                                    !isAnswered &&
                                    !isGallupResultDone &&
                                    examProgress <= examPage) ||
                                (isGallup &&
                                    !isGallupResultDone &&
                                    examPage >= examTotalPages) ||
                                isGallupResultLoading ||
                                (isGibs && !isGibsResultDone) ||
                                isGibsResultLoading ||
                                isExamScoreLoading ||
                                isExamScoreInit ||
                                isShutdown
                            }
                            isDimmed={
                                (isExam &&
                                    !isAnswered &&
                                    !isGallupResultDone &&
                                    examProgress <= examPage) ||
                                (isGallup &&
                                    !isGallupResultDone &&
                                    examPage >= examTotalPages) ||
                                (isGibs && !isGibsResultDone)
                            }
                            isLoading={
                                (pagerDirection === "forward" && isLoading) ||
                                (pagerDirection === "forward" &&
                                    isExamScoreLoading) ||
                                (pagerDirection === "forward" &&
                                    isExamScoreInit)
                            }
                            onClick={goForwardHandler}
                        />
                    </div>
                ) : (
                    ""
                )}
            </div>
        </>
    );
};

export default ReaderPager;
