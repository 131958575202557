import { useRef, useEffect } from "react";
import Lottie from "lottie-react";

const ReaderAnimation = ({ content, isLooped }) => {
    const isLoopEnabled = isLooped === "true" ? true : false;

    const animationRef = useRef(null);
    const animationObserver = useRef(
        !isLoopEnabled
            ? new IntersectionObserver(
                  (entry) => {
                      if (entry[0].isIntersecting) {
                          animationRef.current.play();
                      } else {
                          animationRef.current.stop();
                      }
                  },
                  {
                      threshold: 0.9,
                  }
              )
            : undefined
    );

    useEffect(() => {
        const animation = animationRef.current.animationContainerRef.current;
        const observer = !isLoopEnabled ? animationObserver.current : undefined;

        if (animation && observer) {
            observer.observe(animation);
        }

        return () => {
            if (animation && observer) {
                observer.unobserve(animation);
            }
        };
    }, [isLoopEnabled]);

    return (
        <div className="ReaderAnimation">
            <Lottie
                lottieRef={animationRef}
                animationData={content}
                loop={isLoopEnabled}
                autoplay={isLoopEnabled}
            />
        </div>
    );
};

export default ReaderAnimation;
