import { useContext } from "react";
import { useQuery } from "react-query";

import { StoreContext } from "../context/Store";

const useChildList = (isDisabled) => {
    const { api } = useContext(StoreContext);

    const {
        data: childList,
        isFetched: isChildListFetched,
        isLoading: isChildListLoading,
    } = useQuery("childList", () => api.get(`/account/childList`), {
        enabled: isDisabled ? false : true,
        staleTime: Infinity,
        retry: false,
        refetchOnWindowFocus: false,
    });

    return {
        childList: isDisabled
            ? undefined
            : isChildListFetched && !isChildListLoading
            ? childList.data.childs
            : undefined,
        isChildListFetched: isDisabled ? true : isChildListFetched,
        isChildListLoading: isDisabled ? false : isChildListLoading,
    };
};

export default useChildList;
