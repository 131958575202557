import { useEffect, useRef, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useMutation, useQuery } from "react-query";

import { StoreContext } from "../../../context/Store";

import useLogout from "../../../hooks/useLogout";

import pathSlice from "../../../functions/pathSlice";
import routeLoadingState from "../../../functions/routeLoadingState";

import Button from "../../../components/buttons/Button";

import DashboardContainer from "../../../components/containers/dashboard/DashboardContainer";
import AccountData from "../../../components/containers/dashboard/account/AccountData";

import modalDoodleImage_1 from "../../../assets/images/dashboard/modal-doodles/questionmark_4.svg";
import modalDoodleImage_2 from "../../../assets/images/dashboard/modal-doodles/sad_2.svg";
import modalDoodleImage_3 from "../../../assets/images/dashboard/modal-doodles/faint.svg";
import modalDoodleImage_4 from "../../../assets/images/dashboard/modal-doodles/crying.svg";

const TeacherAccount = ({ routeMatches, routeSetters, modalSetters }) => {
    const { setRouteData, setRouteIsLoading } = routeSetters;
    const {
        setModalData,
        setModalActive,
        setModalApiResponse,
        setModalApiLoading,
    } = modalSetters;

    const history = useHistory();
    const location = useLocation();

    const logout = useLogout();

    const { api } = useContext(StoreContext);

    /* LOADING ACCOUNT DATA */
    const {
        data: accountData,
        isFetched: accountIsFetched,
        isRefetching: accountIsRefetching,
        isLoading: accountIsLoading,
    } = useQuery("account", () => api.get(`/account`), {
        staleTime: Infinity,
        retry: false,
        refetchOnWindowFocus: false,
    });

    /* ACCOUNT DELETE MUTATION */
    const { mutate: teacherDeleteAccountMutate } = useMutation(
        () => api.post(`/teacher/delete`),
        {
            onMutate: () => setModalApiLoading(true),
            onSuccess: () => {
                setModalApiResponse(true);
            },
            onError: () => alert("*Placeholder* Error with sending data"),
        }
    );

    /* ROUTE LOADING */
    const routeIsLoading = routeLoadingState({
        fetched: [accountIsFetched],
        loading: [accountIsLoading],
        refetching: [accountIsRefetching],
    });

    /* ROUTE SETTERS */
    useEffect(() => {
        const routeData = {
            title: "MOJE KONTO",
        };

        if (routeMatches) {
            setRouteIsLoading(routeIsLoading);
            setRouteData(routeData);
        }
    }, [routeIsLoading, routeMatches, setRouteIsLoading, setRouteData]);

    /* MODAL LOGOUT/DELETE DATA */
    const modalLogoutData = useRef({});
    const modalDeleteAccountData = useRef({});

    useEffect(() => {
        modalLogoutData.current = {
            data: [
                {
                    title: {
                        obj: (
                            <p>
                                Czy na pewno
                                <br />
                                chcesz się wylogować?
                            </p>
                        ),
                    },
                    img: {
                        obj: modalDoodleImage_1,
                        size: "185px",
                    },
                    action: {
                        obj: [
                            {
                                text: "Tak, chcę",
                                important: true,
                                onClick: () => {
                                    setModalApiLoading(true);
                                    setModalApiResponse(true);
                                },
                            },
                            {
                                text: "Nie, nie chcę",
                                onClick: () => setModalActive(false),
                            },
                        ],
                        key: "userLogoutStart",
                    },
                },
                {
                    title: {
                        obj: (
                            <p>
                                Za chwilę zostaniesz
                                <br />
                                wylogowany/na z aplikacji
                            </p>
                        ),
                        isCenter: true,
                    },
                    img: {
                        obj: modalDoodleImage_2,
                        size: "185px",
                    },
                    action: {
                        obj: {
                            text: "Wyloguj natychmiast",
                            onClick: () => logout("/"),
                        },
                        auto: true,
                        autoTimeout: 5000,
                    },
                },
            ],
        };

        modalDeleteAccountData.current = {
            data: [
                {
                    title: {
                        obj: (
                            <p>
                                Jeśli usuniesz konto stracisz dostęp do
                                wszystkich materiałów oraz utracisz dane
                                zapisane w aplikacji
                            </p>
                        ),
                    },
                    img: {
                        obj: modalDoodleImage_3,
                        size: "187px",
                    },
                    action: {
                        obj: [
                            {
                                text: "Rozumiem i chcę usunąć konto",
                                important: true,
                                onClick: () => teacherDeleteAccountMutate(),
                            },
                            {
                                text: "O nie! Zostaję w How2 Edu",
                                onClick: () => setModalActive(false),
                            },
                        ],
                        key: "teacherAccountDelete",
                    },
                },
                {
                    special: "accountDelete",
                    title: {
                        obj: (
                            <p>
                                Twoje konto zostało
                                <br />
                                usunięte!
                            </p>
                        ),
                    },
                    img: {
                        obj: modalDoodleImage_4,
                        size: "123px",
                    },
                    action: {
                        obj: {
                            text: "Wróć do ekranu początkowego",
                            onClick: () => logout("/"),
                        },
                        auto: true,
                        autoTimeout: 5000,
                    },
                },
            ],
        };
    }, [
        setModalActive,
        setModalApiLoading,
        setModalApiResponse,
        teacherDeleteAccountMutate,
        logout,
    ]);

    return (
        <>
            <DashboardContainer className="account">
                {!routeIsLoading && (
                    <>
                        <AccountData
                            data={[
                                {
                                    title: "Imię",
                                    text: accountData.data.user.name,
                                    button: {
                                        text: "Edytuj dane",
                                        onClickFn: () =>
                                            history.push(
                                                `${pathSlice(
                                                    location.pathname
                                                )}/edit-account`
                                            ),
                                    },
                                },
                                {
                                    title: "Nazwisko",
                                    text: accountData.data.user.surname,
                                },
                                {
                                    title: "E-mail",
                                    text: accountData.data.user.email,
                                },
                                {
                                    title: "Telefon",
                                    text: accountData.data.user.phone,
                                },
                                {
                                    title: "Kod pocztowy",
                                    text: accountData.data.user.zipCode,
                                },
                                {
                                    title: "Data urodzenia",
                                    text: accountData.data.user.birthdate,
                                },
                                {
                                    title: "Hasło",
                                    text: "•••••••••••••••",
                                    button: {
                                        text: "Edytuj hasło",
                                        onClickFn: () =>
                                            history.push(
                                                `${pathSlice(
                                                    location.pathname
                                                )}/edit-password`
                                            ),
                                    },
                                },
                            ]}
                            dataKey="teacherData"
                        />
                        <Button
                            classNameWrapper="AccountData__Delete"
                            className="blank thin"
                            onClickFn={() =>
                                setModalData(modalDeleteAccountData.current)
                            }
                        >
                            Usuń konto
                        </Button>
                        <div className="AccountData__ButtonGroup">
                            <Button
                                className="medium-2 inverse hover-yellow"
                                onClickFn={() =>
                                    setModalData(modalLogoutData.current)
                                }
                            >
                                Wyloguj się
                            </Button>
                        </div>
                    </>
                )}
            </DashboardContainer>
        </>
    );
};

export default TeacherAccount;
