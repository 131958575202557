import Loader from "../containers/Loader";

import ModalEmpty from "./ModalEmpty";

const ModalResults = ({
    data,
    dataKey,
    queryKey,
    itemSelected,
    disabled,
    isRefetching,
    isLast,
    actionDispatch,
    onResetClick,
    onCloseSearchReset,
    onFinish,
}) => {
    const { dispatch, props } = actionDispatch;

    return (
        <>
            <div className="ModalResults">
                {isRefetching ? (
                    <div className="ModalResults__Loader">
                        <Loader type="ring" />
                    </div>
                ) : data.length > 0 ? (
                    data?.map((object) => {
                        return (
                            <div
                                key={queryKey + "_" + object[dataKey.id]}
                                className="ModalResults__Item"
                            >
                                <button
                                    className="ModalResults__Button"
                                    type="button"
                                    disabled={disabled}
                                    onClick={() => {
                                        if(typeof itemSelected === 'function') {
                                            itemSelected(object[dataKey.name]);
                                        }

                                        if(typeof onCloseSearchReset === 'function') {
                                            onCloseSearchReset(true);
                                        }

                                        dispatch({
                                            ...props,
                                            [dataKey.id]: object[dataKey.id],
                                            [dataKey.name]: object[dataKey.name]
                                        });

                                        if(isLast) {
                                            onFinish();
                                        }
                                    }}
                                >
                                    {object.name}
                                </button>
                            </div>
                        );
                    })
                ) : (
                    <ModalEmpty
                        message="Brak wyników"
                        onResetClick={onResetClick}
                    />
                )}
            </div>
        </>
    );
};

export default ModalResults;
