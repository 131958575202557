import { forwardRef } from "react";
import { useHistory } from "react-router-dom";

import Title from "../../headings/Title";

import DoodleBanner from "../../containers/doodle/DoodleBanner";
import DoodleContent from "../../containers/doodle/DoodleContent";
import doodles from "../../../assets/images/remindpassword/how2doodles_2.png";
import Button from "../../buttons/Button";

const RemindPasswordSuccessForm = forwardRef(({ email }, ref) => {
    const history = useHistory();

    return (
        <>
            <div className="app-remindPassword-wrapper" ref={ref}>
                <DoodleContent wrapperClass="remindPassword">
                    <Title className="text-center">
                        Instrukcja została wysłana
                    </Title>
                    <div className="remindPassword-text text-center">
                        <p>
                            Instrukcja do zresetowania Twojego hasła
                            <br />
                            została wysłana na mail <strong>{email}</strong>
                        </p>
                        <p>
                            Za kilka minut otrzymasz e-mail.
                            <br />
                            Upewnij się, że sprawdziłeś skrzynkę
                            <br />
                            ze spamem również.
                        </p>
                    </div>
                    <Button
                        onClickFn={() =>
                            history.push("/signing-form", { tab: "code" })
                        }
                        classNameWrapper="remindPassword-redirectButton"
                    >
                        Przejdź dalej
                    </Button>
                </DoodleContent>
                <DoodleBanner
                    wrapperClass="remindPassword"
                    additionalClass="success"
                    imgs={doodles}
                />
            </div>
        </>
    );
});

export default RemindPasswordSuccessForm;
