import { useContext } from "react";

import { ActionLoadingContext } from "../context/ActionLoadingStore";

const useActionLoading = () => {
    const { isActionLoading, setIsActionLoading } =
        useContext(ActionLoadingContext);

    return { isActionLoading, setIsActionLoading };
};

export default useActionLoading;