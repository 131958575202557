import { useState, useRef, useContext } from "react";
import { useHistory } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";

import { StoreContext } from "../../../../context/Store";

import Loader from "../../Loader";

import IconFavorite from "../../../icons/IconFavorite";
import IconMultibook from "../../../icons/IconMultibook";

const LibraryBook = ({ className, data, remove }) => {
    const history = useHistory();

    const queryClient = useQueryClient();
    const { api } = useContext(StoreContext);

    /* FAVORITE STATE */
    const [favoriteIsActive, setFavoriteIsActive] = useState(data.favorite);

    /* ID'S */
    const idMultibook = useRef(data.id.multibook);
    const idCategory = useRef(data.id.category);

    /* FAVORITE DISABLE STATE */
    const [favoriteOnMutate, setFavoriteOnMutate] = useState(false);

    /* SET BOOK TO FAVORITE */
    const { mutate: favoriteMutate } = useMutation(
        () =>
            api.post(`/library/add_favorite`, {
                idMultibook: data.id.multibook,
            }),
        {
            onMutate: () => setFavoriteOnMutate(true),
            onSuccess: (data) => {
                const message = data.data.message;

                if (message === "ADDED_TO_FAVORITE") {
                    setFavoriteIsActive("Y");
                }

                if (message === "DELETED_FROM_FAVORITE") {
                    setFavoriteIsActive("N");

                    if (typeof remove === "function") {
                        remove(idMultibook.current);
                    }
                }

                queryClient.invalidateQueries("library");
                queryClient.invalidateQueries([
                    "libraryCategory",
                    idCategory.current,
                ]);

                setFavoriteOnMutate(false);
            },
        }
    );

    return (
        <>
            <div className={"LibraryBook" + (className ? " " + className : "")}>
                <button
                    className="LibraryBook__Thumbnail"
                    type="button"
                    style={{ backgroundImage: "url(" + data.cover + ")" }}
                    onClick={() =>
                        history.push(`/multibook-${data.id.multibook}`)
                    }
                ></button>
                <div className="LibraryBook__Body">
                    <button
                        className="LibraryBook__Title"
                        type="button"
                        onClick={() => history.push(`/multibook-${data.id.multibook}`)}
                    >
                        <span className="LibraryBook__Icon _icon--title">
                            <IconMultibook type={data.type} />
                        </span>
                        {data.title}
                    </button>
                    <button
                        className="LibraryBook__Favorite"
                        type="button"
                        disabled={favoriteOnMutate}
                        onClick={favoriteMutate}
                    >
                        <span className="LibraryBook__Icon _icon--favorite">
                            <IconFavorite isActive={favoriteIsActive} />
                        </span>
                        Dodaj do ulubionych
                    </button>
                    <div className="LibraryBook__Content">
                        <p>{data.content}</p>
                    </div>
                </div>
                {favoriteOnMutate && (
                    <div className="LibraryBook__Action">
                        <div className="LibraryBook__Loader">
                            <Loader type="ring" />
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default LibraryBook;
