import { useContext } from "react";

import { SettingsContext } from "../context/SettingsStore";
import { PlayerContext } from "../context/PlayerStore";

import PlayIcon from "../icons/PlayIcon";

const checkVideoAvailability = (url) => {
    const urlPath = "portalfiles/multibooks/video/";

    return url.substr(url.indexOf(urlPath) + urlPath.length).length > 0;
};

const ReaderVideo = ({ url, thumbnail, isLoading, isGallup, isGibs }) => {
    const { themeColor } = useContext(SettingsContext);
    const { setPlayerData } = useContext(PlayerContext);

    const isVideoUploaded = checkVideoAvailability(url);

    return (
        <>
            <button
                className="ReaderVideo"
                data-panel="false"
                disabled={isLoading || !isVideoUploaded}
                onClick={() => setPlayerData(url)}
            >
                <span
                    className="ReaderVideo__Background"
                    style={{ backgroundImage: "url(" + thumbnail + ")" }}
                >
                    {isVideoUploaded ? (
                        <PlayIcon
                            color={
                                isGallup || isGibs
                                    ? themeColor === "dark"
                                        ? "secondary-dark"
                                        : "secondary"
                                    : ""
                            }
                        />
                    ) : (
                        ""
                    )}
                </span>
                {!isVideoUploaded ? (
                    <span className="ReaderVideo__Mask">
                        <span className="ReaderVideo__Alert">
                            Plik wideo nie został dodany.
                        </span>
                    </span>
                ) : (
                    ""
                )}
            </button>
        </>
    );
};

export default ReaderVideo;
