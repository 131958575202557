import { useContext } from "react";
import { useMutation } from "react-query";

import { ChapterContext } from "../context/ChapterStore";
import { ExamContext } from "../context/ExamStore";
import { StoreContext } from "../../../context/Store";

const useClearExamAnswers = (idChapter) => {
    const { removeChapterData } = useContext(ChapterContext);
    const { setExamPagesStore } = useContext(ExamContext);

    const { api } = useContext(StoreContext);

    const {
        mutate: examAnswersClearMutate,
        isSuccess: isExamAnswersClearSuccess,
        isError: isExamAnswersClearError
    } = useMutation(() => api.post("/exams/clear", { idChapter }));

    const examAnswersClear = () => {
        removeChapterData(idChapter);

        setExamPagesStore(idChapter, 1, "both");

        examAnswersClearMutate(idChapter);
    };

    return {
        examAnswersClear,
        isExamAnswersClearSuccess,
        isExamAnswersClearError
    };
};

export default useClearExamAnswers;
