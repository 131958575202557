import React, { useState, useRef, useEffect } from "react";

import ModalCard from "./ModalCard";
import ModalList from "./ModalList";

const ModalAction = ({
    data,
    response,
    select,
    isLoading,
    isRefetching,
    onFinish,
}) => {
    /* MODAL TYPE AND DATA */
    const [modalType, setModalType] = useState(null);
    const modalData = useRef([]);

    /* SET MODAL TYPE AND DATA */
    useEffect(() => {
        if (data && data.length > 0 && data.length <= 2) {
            if (data.length === 2) {
                modalData.current = {
                    start: { ...data[0] },
                    end: { ...data[1] },
                };

                setModalType("combo");
            }

            if (data.length === 1) {
                modalData.current = data[0];

                setModalType("single");
            }
        }
    }, [data, setModalType]);

    /* MODALSTEPS */
    const [modalNext, setModalNext] = useState(false);
    const [modalFinish, setModalFinish] = useState(false);
    const responseTimeout = useRef(null);

    /* DELAY TRANSITION AFTER RESPONSE */
    useEffect(() => {
        if (response) {
            clearTimeout(responseTimeout.current);

            responseTimeout.current = setTimeout(() => {
                setModalNext(true);
            }, 500);
        }
    }, [response]);

    /* REMOVE LAST CARD AFTER FINISH */
    useEffect(() => {
        if (modalNext) {
            setTimeout(() => {
                setModalFinish(true);
            }, 500);
        }
    }, [modalNext]);

    /* NEW TITLE INJECT AFTER ADDING FROM LIST */
    const injectNewTitle = (data) => {
        const array = modalData.current.end.title.obj.props.children;
        const key = modalData.current.end.title.injectKey;
        const newArray = [];

        for (const item of array) {
            if (typeof item === "string") {
                if (item.includes(key)) {
                    newArray.push(item.replace(key, data));
                } else {
                    newArray.push(item);
                }
            } else {
                newArray.push(item);
            }
        }

        modalData.current.end.title.obj = React.cloneElement(
            modalData.current.end.title.obj,
            { children: newArray }
        );
    };

    return (
        <>
            {modalType === "combo" ? (
                <div
                    className={
                        "ModalAction__Track" +
                        (modalNext && !modalFinish ? " _slide" : "")
                    }
                >
                    {!modalFinish ? (
                        <div className="ModalAction__Item">
                            {select ? (
                                <ModalList
                                    data={modalData.current.start}
                                    itemSelected={(name) =>
                                        injectNewTitle(name)
                                    }
                                    isRefetching={isRefetching}
                                    isLoading={isLoading}
                                />
                            ) : (
                                <ModalCard
                                    data={modalData.current.start}
                                    isLoading={isLoading}
                                />
                            )}
                        </div>
                    ) : (
                        ""
                    )}
                    {modalNext ? (
                        <div className="ModalAction__Item">
                            <ModalCard
                                data={modalData.current.end}
                                isLoading={isLoading}
                                isLast
                                onFinish={onFinish}
                            />
                        </div>
                    ) : (
                        ""
                    )}
                </div>
            ) : (
                ""
            )}
            {modalType === "single" ? (
                select ? (
                    <ModalList
                        data={modalData.current}
                        isRefetching={isRefetching}
                        isLast
                        onFinish={onFinish}
                    />
                ) : (
                    <ModalCard
                        data={modalData.current}
                        isLast
                        onFinish={onFinish}
                    />
                )
            ) : (
                ""
            )}
        </>
    );
};

export default ModalAction;
