const colorsData = [
    {
        name: "light",
        hex: "#ffffff",
    },
    {
        name: "dark",
        hex: "#2E2A26",
    },
];

const ReaderTheme = ({ color, themeColor, setThemeColor }) => {
    return (
        <>
            <div className={"ReaderTheme" + (color ? " _color--" + color : "")}>
                <div className="ReaderTheme__Title">Kolor tła</div>
                {colorsData ? (
                    <div className="ReaderTheme__List">
                        {colorsData.map((object) => {
                            return (
                                <div
                                    className="ReaderTheme__Item"
                                    key={object.name}
                                >
                                    <button
                                        className={
                                            "ReaderTheme__Button" +
                                            (themeColor === object.name
                                                ? " _selected"
                                                : "")
                                        }
                                        style={{ background: object.hex }}
                                        disabled={themeColor === object.name}
                                        onClick={() =>
                                            setThemeColor(object.name)
                                        }
                                    />
                                </div>
                            );
                        })}
                    </div>
                ) : (
                    ""
                )}
            </div>
        </>
    );
};

export default ReaderTheme;
