import React from "react";

import randomRouteLoader from "../../functions/randomRouteLoader";

const RouteLoader = () => {
    return (
        <>
            <div className="RouteLoader">
                <div className="RouteLoader__Header">
                    <div className="RouteLoader__Logo">
                        <svg
                            className="RouteLoader__LogoSvg"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            width="76.031"
                            height="76.031"
                            viewBox="0 0 76.031 76.031"
                        >
                            <defs>
                                <clipPath id="loaderLogoClipPath_1">
                                    <rect
                                        id="loaderLogoRect_1"
                                        dataname="loaderLogoRect 1"
                                        width="76.031"
                                        height="76.031"
                                        fill="none"
                                    />
                                </clipPath>
                            </defs>
                            <g
                                id="loaderLogoGroup_1"
                                dataname="loaderLogoGroup 1"
                                clipPath="url(#loaderLogoClipPath_1)"
                            >
                                <path
                                    id="loaderLogoPath_1"
                                    dataname="loaderLogoPath 1"
                                    d="M53.129,76.031H22.9A22.969,22.969,0,0,1,0,53.129V22.9A22.969,22.969,0,0,1,22.9,0H53.129a22.969,22.969,0,0,1,22.9,22.9V53.129a22.969,22.969,0,0,1-22.9,22.9"
                                    fill="#0c6ff9"
                                />
                                <path
                                    id="loaderLogoPath_2"
                                    dataname="loaderLogoPath 2"
                                    d="M537.927,512.541a17.091,17.091,0,0,0-13.774,31.085h.092a22.754,22.754,0,0,0,4.94-.546c-.36-.088-.719-.184-1.077-.3a14.7,14.7,0,1,1,18.673-18.019,22.786,22.786,0,0,0,.366-4.036v-.957a17.011,17.011,0,0,0-9.22-7.226"
                                    transform="translate(-471.116 -467.595)"
                                    fill="#fff"
                                />
                                <path
                                    id="loaderLogoPath_3"
                                    dataname="loaderLogoPath 3"
                                    d="M215.731,286.951v4.658h-2v-4.534a1.691,1.691,0,0,0-1.739-1.941,2.022,2.022,0,0,0-1.941,1.708v4.767h-2V280.632h2v4.487a2.907,2.907,0,0,1,2.67-1.739c1.475,0,3.012,1.04,3.012,3.571"
                                    transform="translate(-190.112 -256.442)"
                                    fill="#fff"
                                />
                                <path
                                    id="loaderLogoPath_4"
                                    dataname="loaderLogoPath 4"
                                    d="M319.693,316.722a4.223,4.223,0,1,1-4.223-4.207,4.156,4.156,0,0,1,4.223,4.207m-6.505,0a2.295,2.295,0,1,0,2.282-2.437,2.293,2.293,0,0,0-2.282,2.437"
                                    transform="translate(-284.418 -285.576)"
                                    fill="#fff"
                                />
                                <path
                                    id="loaderLogoPath_5"
                                    dataname="loaderLogoPath 5"
                                    d="M426.546,314.315l-2.966,8.073h-1.894l-2.1-5.7-2.1,5.7h-1.879l-2.966-8.073h1.987l1.941,5.636,2.143-5.636h1.754l2.127,5.636,1.941-5.636Z"
                                    transform="translate(-377.08 -287.221)"
                                    fill="#fff"
                                />
                                <path
                                    id="loaderLogoPath_6"
                                    dataname="loaderLogoPath 6"
                                    d="M593.634,283.414c0,1.521-.823,2.267-2.748,4.627l-1.056,1.3h3.8v1.77h-7.359v-.3l2.779-3.369c2-2.453,2.5-3,2.5-3.943a1.5,1.5,0,0,0-1.584-1.615,1.551,1.551,0,0,0-1.583,1.754v.279h-2.08v-.279a3.434,3.434,0,0,1,3.664-3.555,3.4,3.4,0,0,1,3.664,3.323"
                                    transform="translate(-535.738 -255.947)"
                                    fill="#fff"
                                />
                                <path
                                    id="loaderLogoPath_7"
                                    dataname="loaderLogoPath 7"
                                    d="M209.9,473.179h-5.149a1.817,1.817,0,0,0,1.909,1.346,3,3,0,0,0,1.744-.481l1.085,1.058a3.768,3.768,0,0,1-2.9,1.085,3.708,3.708,0,1,1-.137-7.415c1.621,0,3.872,1.222,3.447,4.408m-5.135-1.4h3.364a1.661,1.661,0,0,0-1.675-1.332,1.761,1.761,0,0,0-1.689,1.332"
                                    transform="translate(-185.324 -428.363)"
                                    fill="#fff"
                                />
                                <path
                                    id="loaderLogoPath_8"
                                    dataname="loaderLogoPath 8"
                                    d="M299.994,440.581v9.707h-1.964v-.906h-.1a2.372,2.372,0,0,1-2.073,1.044,3.713,3.713,0,0,1,0-7.415,2.372,2.372,0,0,1,2.073,1.044h.1v-3.474Zm-1.923,6.138a1.868,1.868,0,0,0-1.922-1.963,1.965,1.965,0,0,0,0,3.927,1.868,1.868,0,0,0,1.922-1.963"
                                    transform="translate(-267.146 -402.603)"
                                    fill="#fff"
                                />
                                <path
                                    id="loaderLogoPath_9"
                                    dataname="loaderLogoPath 9"
                                    d="M397.02,474.472v-4.105h1.964v3.968c0,1.167.673,1.593,1.414,1.593a1.648,1.648,0,0,0,1.565-1.387v-4.174h1.964v7.14h-1.964v-1.345a2.513,2.513,0,0,1-2.293,1.483c-1.291,0-2.65-.92-2.65-3.172"
                                    transform="translate(-362.797 -429.821)"
                                    fill="#fff"
                                />
                                <path
                                    id="loaderLogoPath_10"
                                    dataname="loaderLogoPath 10"
                                    d="M666.988,620.562c0,3.063-3.216,2.948-3.216,5.207v.325h-2.508v-.325c0-3.235,3.217-2.833,3.217-5.073a1.754,1.754,0,0,0-1.9-1.9,1.961,1.961,0,0,0-2.01,2.183h-2.508c0-2.776,1.742-4.345,4.5-4.345,2.6,0,4.422,1.512,4.422,3.924M661.111,629a1.416,1.416,0,1,1,1.416,1.416A1.39,1.39,0,0,1,661.111,629"
                                    transform="translate(-601.342 -563.484)"
                                    fill="#fff"
                                />
                            </g>
                        </svg>
                    </div>
                </div>
                <div className="RouteLoader__Body">
                    <div className="RouteLoader__Doodle">
                        <div className="RouteLoader__Banner">
                            <img
                                className="RouteLoader__Image"
                                src={randomRouteLoader()}
                                alt=""
                            />
                        </div>
                        <div className="RouteLoader__Caption">
                            Daj nam chwilkę
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default React.memo(RouteLoader);
