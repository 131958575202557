const DoodleBanner = ({ wrapperClass, additionalClass, imgs }) => {
    return (
        <>
            <div
                className={
                    wrapperClass +
                    "-doodleBanner" +
                    (additionalClass ? " " + additionalClass : "")
                }
            >
                <div className={wrapperClass + "-doodleBanner-wrapper"}>
                    {typeof imgs === "object" ? (
                        Object.values(imgs).map((object, index) => {
                            return (
                                <img
                                    key={
                                        (wrapperClass
                                            ? wrapperClass
                                            : "default") +
                                        "-doodleBanner" +
                                        index
                                    }
                                    className={"doodleImg-" + (index + 1)}
                                    src={object}
                                    alt={"How2doodle_" + (index + 1)}
                                />
                            );
                        })
                    ) : (
                        <img src={imgs} alt="How2doodle" />
                    )}
                </div>
                <div className={wrapperClass + "-doodleBanner-elipse"}></div>
            </div>
        </>
    );
};

export default DoodleBanner;
