import { useEffect } from "react";
import { useCookies } from "react-cookie";

import doodle_teal from "../assets/images/topbar/doodle-teal.svg";
import doodle_yellow from "../assets/images/topbar/doodle-yellow.svg";
import doodle_red from "../assets/images/topbar/doodle-red.svg";

const setDoodleByAccountRank = (rank) => {
    switch (rank) {
        case 2:
            return doodle_teal;
        case 3:
            return doodle_yellow;
        case 4:
            return doodle_red;
        default:
            return doodle_teal;
    }
};

const ReaderTopbar = ({ title, shutdown }) => {
    const { isBookShutdown, setIsBookShutdown, setFinishBookShutdown } =
        shutdown;

    const [cookies] = useCookies(["idRank"]);

    useEffect(() => {
        if (isBookShutdown) {
            setTimeout(() => {
                setFinishBookShutdown(true);
            }, 1500);
        }
    }, [isBookShutdown, setFinishBookShutdown]);

    return (
        <>
            <div className="ReaderTopbar" data-panel="false">
                <button
                    className="ReaderTopbar__Exit"
                    type="button"
                    onClick={() => setIsBookShutdown(true)}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="13.766"
                        height="23.289"
                        viewBox="0 0 13.766 23.289"
                    >
                        <path
                            id="Path_50"
                            dataname="Path 50"
                            d="M0,0,9.523,9.524,0,19.047"
                            transform="translate(11.645 21.168) rotate(180)"
                            fill="none"
                            stroke="#000"
                            strokeLinecap="round"
                            strokeWidth="3"
                        />
                    </svg>
                </button>
                <div className="ReaderTopbar__Title">{title}</div>
                <div className="ReaderTopbar__Doodle">
                    <img
                        src={setDoodleByAccountRank(parseInt(cookies.idRank))}
                        alt=""
                    />
                </div>
            </div>
        </>
    );
};

export default ReaderTopbar;
