import ReaderButton from "../buttons/ReaderButton";
import ReaderLoader from "./ReaderLoader";

const ReaderSummary = ({
    className,
    title,
    page,
    progress,
    isLoading,
    onClick,
}) => {
    return (
        <div className={"ReaderSummary" + (className ? " " + className : "")}>
            {title && <p className="ReaderSummary__Title">{title}</p>}
            <div className="ReaderSummary__Save">
                <ReaderButton
                    className={isLoading ? "_is--loading" : ""}
                    color="green"
                    size="large"
                    isDimmed={page >= progress || isLoading}
                    isDisabled={page >= progress || isLoading}
                    onClick={onClick}
                >
                    {!isLoading ? (
                        "Zapisz"
                    ) : (
                        <ReaderLoader type="ellipsis" color="white" />
                    )}
                </ReaderButton>
            </div>
        </div>
    );
};

export default ReaderSummary;
