import { useState, useEffect } from "react";

import ReaderRedirect from "./buttons/ReaderRedirect";
import FavoriteIcon from "./icons/FavoriteIcon";

import doodle from "./assets/images/doodle-error.svg";
import { useCookies } from "react-cookie";

const ReaderCover = ({
    data,
    bookMount,
    error,
    shutdown,
    isMissingError,
    isSubscriptionActive,
    isFirstInit,
    isPending,
    isLoading,
    isGibs,
    isGallup,
}) => {
    const { isBookMount, setIsBookMount } = bookMount;
    const { isBookShutdown, setIsBookShutdown, setFinishBookShutdown } =
        shutdown;

    /* GET ID RANK FROM COOKIES */
    const cookies = useCookies(["idRank"])[0];

    /* MULTIBOOK LOADING FINISH */
    const [isBookReady, setIsBookReady] = useState(false);

    useEffect(() => {
        if (isBookMount && !isBookReady && !isLoading && !isPending) {
            setIsBookReady(true);
        }
    }, [isBookMount, isLoading, isBookReady, isPending]);

    /* HANDLE MULTIBOOK SHUTDOWN */
    useEffect(() => {
        if (isBookShutdown) {
            setTimeout(() => {
                setFinishBookShutdown(true);
            }, 1500);
        }
    }, [isBookShutdown, setFinishBookShutdown]);

    return (
        <>
            <div className="ReaderCover" data-panel="false">
                <div className="ReaderCover__Container">
                    <div className="ReaderCover__Header">
                        <div className="ReaderCover__Banner">
                            <div className="ReaderCover__Close">
                                <ReaderRedirect
                                    large
                                    color={
                                        isGibs || isGallup ? "secondary" : ""
                                    }
                                    bordered
                                    inverted
                                    disabled={isBookMount}
                                    onClick={() => setIsBookShutdown(true)}
                                />
                            </div>
                            <div
                                className={
                                    "ReaderCover__Thumbnail" +
                                    (!isSubscriptionActive
                                        ? " _is--placeholder"
                                        : "")
                                }
                                style={{
                                    backgroundImage: isSubscriptionActive
                                        ? "url(" + data.cover + ")"
                                        : "",
                                }}
                            >
                                {!isSubscriptionActive && (
                                    <img src={doodle} alt="" />
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="ReaderCover__Body">
                        {isSubscriptionActive ? (
                            data.isFavorite &&
                            data.isFavorite !== "disabled" ? (
                                <div className="ReaderCover__Favorite">
                                    <FavoriteIcon
                                        color={
                                            isGibs || isGallup
                                                ? "secondary"
                                                : ""
                                        }
                                        isFavorite={data.isFavorite}
                                    />
                                </div>
                            ) : (
                                ""
                            )
                        ) : (
                            ""
                        )}
                        <h1 className="ReaderCover__Title">
                            {isSubscriptionActive && !isMissingError
                                ? data.title
                                : !isMissingError
                                ? "Brak dostępu"
                                : "Smartbook nie istnieje"}
                        </h1>
                        <div className="ReaderCover__Content">
                            {isSubscriptionActive && !isMissingError ? (
                                data.subtitle
                            ) : !isMissingError ? (
                                parseInt(cookies.idRank) === 2 ? (
                                    <>
                                        Abonament Twojego dziecka
                                        <br />
                                        jest nieaktywny.
                                        <br />
                                        Wybierz abonament, aby w pełni cieszyć
                                        się światem How2 Edu.
                                    </>
                                ) : (
                                    <>
                                        Twój abonament jest nieaktywny.
                                        <br />
                                        Poinformuj o tym swojego rodzica,
                                        <br />
                                        abyś w pełni mógł cieszyć się
                                        <br />
                                        światem How2 Edu.
                                    </>
                                )
                            ) : (
                                <>Smartbook nie został odnaleziony.</>
                            )}
                        </div>
                    </div>
                    <div
                        className={
                            "ReaderCover__Footer" +
                            (isBookReady && !isPending && isFirstInit.current
                                ? " _book--ready"
                                : "") +
                            (error ? " _book--empty" : "")
                        }
                    >
                        {isSubscriptionActive ? (
                            error ? (
                                <div className="ReaderCover__Empty">
                                    Multibook nie posiada rozdziałów
                                    <br />
                                    Przepraszamy
                                </div>
                            ) : (
                                <div className="ReaderCover__Open">
                                    <ReaderRedirect
                                        large
                                        color={
                                            isGibs || isGallup
                                                ? "secondary"
                                                : ""
                                        }
                                        isDisabled={isBookMount || isPending}
                                        isLoading={
                                            isBookMount &&
                                            !isPending &&
                                            isFirstInit.current
                                        }
                                        onClick={() => setIsBookMount(true)}
                                    />
                                </div>
                            )
                        ) : !isMissingError ? (
                            <div className="ReaderCover__Open">
                                <ReaderRedirect
                                    large
                                    isDisabled={true}
                                    isDimmed={true}
                                />
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default ReaderCover;
