import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import pathSlice from "../../../functions/pathSlice";

import DashboardContainer from "../../../components/containers/dashboard/DashboardContainer";
import NavTiles from "../../../components/containers/dashboard/NavTiles";

const Home = ({ routeMatches, routeSetters }) => {
    const { setRouteData } = routeSetters;

    const history = useHistory();
    const location = useLocation();

    const accountActive = true;

    /* ROUTE SETTERS */
    useEffect(() => {
        const routeData = {};

        if (routeMatches) {
            setRouteData(routeData);
        }
    }, [routeMatches, setRouteData]);

    return (
        <DashboardContainer className="teacher">
            <NavTiles
                disabled={!accountActive}
                data={[
                    {
                        text: "Moje konto",
                        icon: "my-account",
                        isActive: true,
                        onClick: () =>
                            history.push(
                                `${pathSlice(location.pathname)}/account`
                            ),
                    },
                    {
                        text: "Moje szkoły",
                        icon: "my-schools",
                        isActive: true,
                        onClick: () =>
                            history.push(
                                `${pathSlice(location.pathname)}/myschools`
                            ),
                    },
                    {
                        text: "Biblioteka",
                        icon: "library",
                        isActive: true,
                        onClick: () =>
                            history.push(
                                `${pathSlice(location.pathname)}/library`
                            ),
                    },
                    {
                        text: "Moja półka",
                        icon: "my-shelf",
                        isActive: true,
                        onClick: () =>
                            history.push(
                                `${pathSlice(location.pathname)}/mybookshelf`
                            ),
                    },
                    {
                        text: "Moje przedmioty",
                        icon: "subjects",
                        isActive: true,
                        onClick: () =>
                            history.push(
                                `${pathSlice(location.pathname)}/mysubjects`
                            ),
                    },
                ]}
                keyName="dashboardTeacher"
            />
        </DashboardContainer>
    );
};

export default Home;
