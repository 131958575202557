import { useRef, useEffect, useContext } from "react";
import Lottie from "lottie-react";

import { ExamContext } from "../context/ExamStore";

import useFinishBook from "../hooks/useFinishBook";
import useChapterSavePage from "../hooks/useChapterSavePage";

import ReaderButton from "../buttons/ReaderButton";
import ReaderPage from "../utils/ReaderPage";
import ReaderPager from "../utils/ReaderPager";

import doodle_final from "../assets/images/utils/doodle-final.svg";
import doodle_animation from "../assets/animations/gibs-finish.json";

const getBookNameByType = (type) => {
    switch (type) {
        case "multi":
            return "smartbookiem";
        case "audio":
            return "audiobookiem";
        default:
            return "smartbookiem";
    }
};

const ReaderFinish = ({
    idBook,
    type,
    pages,
    pager,
    shutdown,
    isPending,
    isLoading,
    isGallup,
    isGibs,
}) => {
    const {
        isBookShutdown,
        setIsBookShutdown,
        finishBookShutdown,
        setFinishBookShutdown,
    } = shutdown;

    const {
        getExamResultStore,
        isGibsResultLoading,
        setIsGibsResultLoading,
        isGibsResultDone,
        setIsGibsResultDone,
    } = useContext(ExamContext);

    /* FINISH/SAVE HOOKS */
    const { finishBook, isFinishBookSuccess, isFinishBookError } =
        useFinishBook(idBook);
    const { savePage, isSavePageSuccess } = useChapterSavePage(idBook, 1);

    /* READER EXIT FLAG */
    const isExitPending = useRef(false);

    /* HANDLE READER EXIT */
    const handleExit = () => {
        setIsBookShutdown(true);

        finishBook(0);
    };

    useEffect(() => {
        if (isFinishBookSuccess && !isExitPending.current) {
            isExitPending.current = true;

            savePage();
        }
    }, [isFinishBookSuccess, savePage]);

    useEffect(() => {
        if (
            (isSavePageSuccess &&
                !finishBookShutdown &&
                isExitPending.current) ||
            isFinishBookError
        ) {
            setTimeout(
                () => {
                    setFinishBookShutdown(true);
                },
                isFinishBookError ? 1000 : 500
            );
        }
    }, [
        isSavePageSuccess,
        finishBookShutdown,
        setFinishBookShutdown,
        isFinishBookError,
    ]);

    /* GIBS SET LOADING AND DONE STATE */
    const isGibsResultFinished = useRef(false);

    useEffect(() => {
        if (isGibs && isGibsResultLoading && !isGibsResultFinished.current) {
            isGibsResultFinished.current = true;

            setTimeout(() => {
                setIsGibsResultLoading(false);
                setIsGibsResultDone(true);
            }, 500);
        }
    }, [
        isGibs,
        isGibsResultLoading,
        setIsGibsResultLoading,
        setIsGibsResultDone,
    ]);

    return (
        <>
            {!isGibs && (
                <ReaderPager
                    pages={pages}
                    pager={pager}
                    isPending={isPending}
                    isLoading={isLoading}
                    isGallup={isGallup}
                    isGibs={isGibs}
                    isShutdown={isBookShutdown}
                />
            )}
            <ReaderPage isFinished={true}>
                <div className={"ReaderFinish" + (isGibs ? " _is--gibs" : "")}>
                    <div className="ReaderFinish__Doodle">
                        {!isGibs ? (
                            <img src={doodle_final} alt="" />
                        ) : (
                            <Lottie
                                className={
                                    "ReaderFinish__Lottie" +
                                    (isGibsResultDone ? " _is--init" : "")
                                }
                                animationData={doodle_animation}
                                loop={true}
                            />
                        )}
                    </div>
                    <h3 className="ReaderFinish__Title">
                        {!isGibs ? (
                            <>
                                Właśnie zakończyłeś przygodę
                                <br />z tym niezwykłym {getBookNameByType(type)}
                                .
                                <br />
                                Wiesz, że zawsze możesz tu wrócić?
                            </>
                        ) : (
                            <>
                                Super, właśnie uzupełniłeś kolejny
                                <br />
                                cykl autorefleksji i jesteś bogatszy
                                <br />
                                o nowe doświadczenie i być może
                                <br />
                                już wiesz jak poradzić sobie z tą
                                <br />
                                trudną sytuacją.
                            </>
                        )}
                    </h3>
                    {isGibs && (
                        <div className="ReaderFinish__Highlight">
                            <p>
                                Gratulacje!
                                <br />
                                To jest twoja{" "}
                                <strong>{getExamResultStore()}</strong>{" "}
                                autorefleksja
                                <br />w tym roku
                            </p>
                        </div>
                    )}
                    <div className="ReaderFinish__Button">
                        <ReaderButton
                            color={isGallup || isGibs ? "green" : ""}
                            size="large"
                            isDimmed={isBookShutdown}
                            isDisabled={isBookShutdown}
                            onClick={handleExit}
                        >
                            Zakończ
                        </ReaderButton>
                    </div>
                </div>
            </ReaderPage>
            {!isGibs && (
                <ReaderPager
                    className={
                        "_sticky--bottom" + (isGibs ? " _solo--bottom" : "")
                    }
                    pages={pages}
                    pager={pager}
                    isPending={isPending}
                    isLoading={isLoading}
                    isGallup={isGallup}
                    isGibs={isGibs}
                    isShutdown={isBookShutdown}
                />
            )}
        </>
    );
};

export default ReaderFinish;
