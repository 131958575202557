const ArrowIcon = ({ direction }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="13.766"
            height="23.289"
            viewBox="0 0 13.766 23.289"
        >
            <path
                id="Path_50"
                dataname="Path 50"
                d="M0,0,9.523,9.524,0,19.047"
                transform={
                    direction === "left"
                        ? "translate(11.645 21.168) rotate(180)"
                        : ""
                }
                fill="none"
                stroke="#000"
                strokeLinecap="round"
                strokeWidth="3"
            />
        </svg>
    );
};

export default ArrowIcon;
