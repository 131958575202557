const ReaderImage = ({ url }) => {
    return (
        <>
            <div className="ReaderImage">
                {typeof url === "string" && <img src={url} alt="" />}
                {typeof url === "object" && <img src={url.url} alt="" />}
            </div>
        </>
    );
};

export default ReaderImage;
