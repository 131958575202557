const SchoolTextbox = ({ className, children }) => (
    <div
        className={
            "dashboard-school-textbox" + (className ? " " + className : "")
        }
    >
        {children}
    </div>
);

export default SchoolTextbox;
