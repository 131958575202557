import { useRef, createContext } from "react";

export const ChapterContext = createContext();

const setChapterObject = (data, idChapter) => {
    return {
        idChapter: idChapter,
        data: [data],
    };
};

const setDataByType = (data, type) => {
    if (type === "slider" || type === "order" || type === "answer" || type === "mood") {
        return data;
    } else {
        return [data];
    }
};

const setDataObject = (data, idContent, type) => {
    return {
        type: type,
        idContent: idContent,
        content: setDataByType(data, type),
    };
};

const setFullObject = (idChapter, idContent, type, data) => {
    return [setChapterObject(setDataObject(data, idContent, type), idChapter)];
};

const getIfChapterExist = (data, idChapter) => {
    let exist = false;

    for (const item of data) {
        if (item.idChapter === idChapter) {
            exist = true;
        }
    }

    return exist;
};

const getIfContentExist = (data, idContent) => {
    let exist = false;

    for (const item of data) {
        if (parseInt(item.idContent) === idContent) {
            exist = true;
        }
    }

    return exist;
};

const ChapterProvider = ({ children }) => {
    const chapterDataStore = useRef([]);

    /* SET CHAPTER DATA TO STORE */
    const setChapterData = (data, ids, type, remove) => {
        const { idChapter, idContent } = ids;

        if (
            !chapterDataStore.current ||
            !Array.isArray(chapterDataStore.current)
        )
            return;

        if (chapterDataStore.current.length > 0) {
            const chapterExist = getIfChapterExist(
                chapterDataStore.current,
                idChapter
            );

            if (chapterExist) {
                const tempChapters = [];

                for (const chapterObject of chapterDataStore.current) {
                    if (chapterObject.idChapter === idChapter) {
                        const tempData = [];

                        const contentExist = getIfContentExist(
                            chapterObject.data,
                            idContent
                        );

                        if (contentExist) {
                            for (const dataObject of chapterObject.data) {
                                if (
                                    parseInt(dataObject.idContent) === idContent
                                ) {
                                    const type = dataObject.type;
                                    const content = dataObject.content;

                                    let tempContent = [];

                                    if (type === "choice") {
                                        const isFound = content.some(
                                            (element) =>
                                                element.content === data.content
                                        );

                                        if (remove) {
                                            tempContent = content.filter(
                                                (element) =>
                                                    element.content !==
                                                    data.content
                                            );
                                        } else {
                                            if (!isFound) {
                                                tempContent = [
                                                    ...content,
                                                    data,
                                                ];
                                            } else {
                                                tempContent = [...content];
                                            }
                                        }
                                    }

                                    if (
                                        type === "slider" ||
                                        type === "order" ||
                                        type === "answer" ||
                                        type === "mood"
                                    ) {
                                        tempContent = data;
                                    }

                                    if (
                                        tempContent.length > 0 ||
                                        !Array.isArray(tempContent)
                                    ) {
                                        tempData.push({
                                            ...dataObject,
                                            content: tempContent,
                                        });
                                    }
                                } else {
                                    tempData.push(dataObject);
                                }
                            }
                        } else {
                            const newDataObject = setDataObject(
                                data,
                                idContent,
                                type
                            );

                            tempData.push(...chapterObject.data, newDataObject);
                        }

                        tempChapters.push({
                            idChapter: idChapter,
                            data: tempData,
                        });
                    } else {
                        tempChapters.push(chapterObject);
                    }
                }

                chapterDataStore.current = tempChapters;
            } else {
                const newChapterObject = setChapterObject(
                    setDataObject(data, idContent, type),
                    idChapter
                );

                chapterDataStore.current = [
                    ...chapterDataStore.current,
                    newChapterObject,
                ];
            }
        } else {
            chapterDataStore.current = setFullObject(
                idChapter,
                idContent,
                type,
                data
            );
        }
    };

    /* REMOVE CHAPTER DATA FROM STORE */
    const removeChapterData = (idChapter) => {
        chapterDataStore.current = chapterDataStore.current.filter(
            (element) => element.idChapter !== idChapter
        );
    };

    /* ADD/REPLACE CHAPTER DATA TO STORE */
    const addChapterData = (data) => {
        if (chapterDataStore.current.length > 0) {
            const filteredData = chapterDataStore.current.filter(
                (element) => parseInt(element.idChapter) !== data.idChapter
            );

            chapterDataStore.current = [...filteredData, data];
        } else {
            chapterDataStore.current = [data];
        }
    };

    /* GET CHAPTER DATA FROM STORE */
    const getChapterData = (ids = {}, type, objectData) => {
        const { idChapter, idContent } = ids;

        if (
            !chapterDataStore.current ||
            !Array.isArray(chapterDataStore.current)
        )
            return undefined;

        let newData;
        let specialData;

        if (chapterDataStore.current.length > 0) {
            for (const chapterObject of chapterDataStore.current) {
                if (chapterObject.idChapter === idChapter) {
                    for (const dataObject of chapterObject.data) {
                        if (parseInt(dataObject.idContent) === idContent) {
                            if (objectData) {
                                if (type === "choice") {
                                    for (const contentObject of dataObject.content) {
                                        if (
                                            contentObject.content ===
                                            objectData.content
                                        ) {
                                            specialData = true;
                                        }
                                    }
                                }

                                if (type === "slider") {
                                    specialData =
                                        objectData
                                            .map((object) => object.option)
                                            .indexOf(
                                                dataObject.content.option
                                            ) + 1;
                                }
                            } else {
                                newData = dataObject.content;
                            }
                        }
                    }
                }
            }
        }

        return objectData ? specialData : newData;
    };

    /* GET ALL CHAPTER DATA FROM STORE */
    const getFullChapterData = (idChapter) => {
        if (idChapter) {
            const filteredData = chapterDataStore.current.filter(
                (element) => parseInt(element.idChapter) === idChapter
            );

            return filteredData.length > 0 ? filteredData[0] : [];
        } else {
            return chapterDataStore.current;
        }
    };

    return (
        <ChapterContext.Provider
            value={{
                setChapterData,
                addChapterData,
                removeChapterData,
                getChapterData,
                getFullChapterData,
            }}
        >
            {children}
        </ChapterContext.Provider>
    );
};

export default ChapterProvider;
