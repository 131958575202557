import { useState, useRef, useEffect } from "react";
import { Router, Route, useHistory, useRouteMatch } from "react-router-dom";
import { CSSTransition } from "react-transition-group";

import useComponentWillMount from "../../hooks/useComponentWillMount";

import pathSlice from "../../functions/pathSlice";

import Topbar from "../../components/containers/dashboard/Topbar";
import RouteDelay from "../../components/route/RouteDelay";
import RouteNavigation from "../../components/route/navigation/RouteNavigation";
import Navigation from "../../components/containers/dashboard/Navigation";
import ModalTransition from "../../components/modal-bodies/ModalTransition";
import ModalAction from "../../components/modal-bodies/ModalAction";

import Home from "./parent/Home";

import ParentAccount from "./parent/ParentAccount";
import ParentAccountEdit from "./parent/ParentAccountEdit";
import PasswordEdit from "./PasswordEdit";

import ChildrenList from "./parent/ChildrenList";
import Children from "./parent/Children";
import ChildrenAccount from "./parent/ChildrenAccount";
import ChildrenAccountEdit from "./parent/ChildrenAccountEdit";
import ChildrenLearningProgress from "./parent/ChildrenLearningProgress";
import ChildrenSchool from "./parent/ChildrenSchool";
import Subscriptions from "./parent/Subscriptions";

import MyShelf from "./MyShelf";

import Library from "./Library";
import LibraryFavorites from "./LibraryFavorites";
import LibraryCategory from "./LibraryCategory";

import RegisterChildrenForm from "../../components/forms/dashboard/RegisterChildrenForm";
import ChildrenLearningProgressDetails from "./parent/ChildrenLearningProgressDetails";

import Notifications from "./Notifications";

const DashboardParent = () => {
    const { path } = useRouteMatch();
    const history = useHistory();

    /* ROUTE LOADING STATE */
    const [routeIsLoading, setRouteIsLoading] = useState(false);

    /* ROUTE BACKWARD STATE */
    const [routeIsBackward, setRouteIsBackward] = useState(false);

    /* ROUTE NAVIGATION DATA */
    const [routeData, setRouteData] = useState();

    /* ROUTE DELAY TIMEOUT */
    const routeTimeout = 500;

    /* ROUTE TRANSITION CLASSNAME */
    const routeClassNames = routeIsBackward
        ? routeIsLoading
            ? "SlideRightIn"
            : "SlideRight"
        : routeIsLoading
        ? "SlideLeftIn"
        : "SlideLeft";

    /* ROUTE REFS */
    const routeHomeRef = useRef(null);
    const routeAccountRef = useRef(null);
    const routeAccountEditRef = useRef(null);
    const routePasswordEditRef = useRef(null);
    const routeChildrenListRef = useRef(null);
    const routeChildrenRef = useRef(null);
    const routeChildrenAccountRef = useRef(null);
    const routeChildrenAccountEditRef = useRef(null);
    const routeChildrenLearningProgressRef = useRef(null);
    const routeChildrenLearningProgressDetailsRef = useRef(null);
    const routeChildrenSchoolRef = useRef(null);
    const routeLibraryRef = useRef(null);
    const routeLibraryCategoryRef = useRef(null);
    const routeLibraryFavoritesRef = useRef(null);
    const routeMyShelfRef = useRef(null);
    const routeSubscriptionsRef = useRef(null);
    const routeChildRegisterRef = useRef(null);
    const routeNotificationsRef = useRef(null);

    /* ROUTE PREV URL */
    const routePrevPath = useRef(null);

    /* CHECK IF ROUTE IS BACKWARD */
    useComponentWillMount(() => {
        history.listen((location, action) => {
            const routeNextPath = pathSlice(location.pathname);
            const routePrevPathLength = routePrevPath.current
                ? routePrevPath.current.substr(1).split("/").length
                : 0;
            const routeNextPathLength = routeNextPath
                .substr(1)
                .split("/").length;

            if (action === "POP" || routePrevPathLength > routeNextPathLength) {
                setRouteIsBackward(true);
            } else {
                setRouteIsBackward(false);
            }

            routePrevPath.current = pathSlice(location.pathname);
        });
    });

    /* MODAL ACTIVE */
    const [modalActive, setModalActive] = useState(false);

    /* MODAL DATA */
    const [modalData, setModalData] = useState({});
    const modalDataClear = useRef(false);

    /* MODAL API */
    const [modalApiResponse, setModalApiResponse] = useState(false);
    const [modalApiLoading, setModalApiLoading] = useState(false);
    const [modalApiRefetching, setModalApiRefetching] = useState(false);
    const modalApiClear = useRef(false);

    /* MODAL OPEN */
    useEffect(() => {
        if (modalData.data && !modalDataClear.current) {
            modalDataClear.current = true;

            setModalActive(true);
        }
    }, [modalData]);

    const routes = [
        {
            ref: routeHomeRef,
            path: path,
            children: <Home />,
        },
        {
            ref: routeAccountRef,
            path: `${path}/account`,
            children: <ParentAccount />,
        },
        {
            ref: routeAccountEditRef,
            path: `${path}/account/edit-account`,
            children: <ParentAccountEdit />,
        },
        {
            ref: routePasswordEditRef,
            path: `${path}/account/edit-password`,
            children: <PasswordEdit />,
        },
        {
            ref: routeChildrenListRef,
            path: `${path}/children`,
            children: <ChildrenList />,
        },
        {
            ref: routeChildrenRef,
            path: `${path}/children/:idChild`,
            children: <Children />,
        },
        {
            ref: routeChildrenAccountRef,
            path: `${path}/children/:idChild/account`,
            children: <ChildrenAccount />,
        },
        {
            ref: routeChildrenAccountEditRef,
            path: `${path}/children/:idChild/account/edit-account`,
            children: <ChildrenAccountEdit />,
        },
        {
            ref: routeChildrenLearningProgressRef,
            path: `${path}/children/:idChild/learning-progress`,
            children: <ChildrenLearningProgress />,
        },
        {
            ref: routeChildrenLearningProgressDetailsRef,
            path: `${path}/children/:idChild/learning-progress/details-:idMultibook`,
            children: <ChildrenLearningProgressDetails />,
        },
        {
            ref: routeChildrenSchoolRef,
            path: `${path}/children/:idChild/school`,
            children: <ChildrenSchool />,
        },
        {
            ref: routeMyShelfRef,
            path: `${path}/mybookshelf`,
            children: <MyShelf />,
        },
        {
            ref: routeLibraryRef,
            path: `${path}/library`,
            children: <Library />,
        },
        {
            ref: routeLibraryCategoryRef,
            path: `${path}/library/category-:idCategory`,
            children: <LibraryCategory />,
        },
        {
            ref: routeLibraryFavoritesRef,
            path: `${path}/library/favorites`,
            children: <LibraryFavorites />,
        },
        {
            ref: routeSubscriptionsRef,
            path: `${path}/subscriptions`,
            children: <Subscriptions />,
        },
        {
            ref: routeChildRegisterRef,
            path: `${path}/add-children`,
            children: <RegisterChildrenForm />,
        },
        {
            ref: routeNotificationsRef,
            path: `${path}/notifications`,
            children: <Notifications />,
        },
    ];

    return (
        <>
            <Topbar
                modalSetters={{
                    setModalData: setModalData,
                    setModalActive: setModalActive,
                    setModalApiResponse: setModalApiResponse,
                    setModalApiLoading: setModalApiLoading,
                }}
            />

            <div className="RouteGroup">
                <RouteNavigation
                    routeData={routeData}
                    routeTimeout={routeTimeout}
                    routeIsLoading={routeIsLoading}
                    routeIsBackward={routeIsBackward}
                />
                {routes?.length > 0 ? (
                    <Router history={history}>
                        {routes.map((route) => {
                            return (
                                <Route key={route.path} exact path={route.path}>
                                    {({ match }) => (
                                        <CSSTransition
                                            nodeRef={route.ref}
                                            in={match !== null}
                                            classNames={routeClassNames}
                                            timeout={500}
                                            unmountOnExit
                                            appear
                                        >
                                            <RouteDelay
                                                ref={route.ref}
                                                routeMatch={{
                                                    match: match,
                                                    path: route.path,
                                                }}
                                                routeTimeout={routeTimeout}
                                                routeIsLoading={routeIsLoading}
                                                routeIsBackward={
                                                    routeIsBackward
                                                }
                                                children={route.children}
                                                routeSetters={{
                                                    setRouteData: setRouteData,
                                                    setRouteIsLoading:
                                                        setRouteIsLoading,
                                                }}
                                                modalSetters={{
                                                    setModalData: setModalData,
                                                    setModalActive:
                                                        setModalActive,
                                                    setModalApiResponse:
                                                        setModalApiResponse,
                                                    setModalApiLoading:
                                                        setModalApiLoading,
                                                }}
                                            />
                                        </CSSTransition>
                                    )}
                                </Route>
                            );
                        })}
                    </Router>
                ) : (
                    ""
                )}
            </div>

            <Navigation
                navs={[
                    {
                        text: "Home",
                        icon: "home",
                        onClick: () => history.push(path),
                    },
                    {
                        text: "Moje dziecko",
                        icon: "my-children",
                        onClick: () => history.push(`${path}/children`),
                    },
                    {
                        type: "my-shelf",
                        text: "Moja półka",
                        icon: "my-shelf",
                        onClick: () => history.push(`${path}/mybookshelf`),
                    },
                    {
                        type: "notifications",
                        text: "Powiadomienia",
                        icon: "notifications",
                        onClick: () => history.push(`${path}/notifications`),
                    },
                    {
                        type: "library",
                        text: "Biblioteka",
                        icon: "library",
                        onClick: () => history.push(`${path}/library`),
                    },
                ]}
                history={history}
            />

            <ModalTransition
                isActive={modalActive}
                children={
                    <ModalAction
                        data={modalData.data}
                        response={modalApiResponse}
                        select={modalData.select}
                        isLoading={modalApiLoading}
                        isRefetching={setModalApiRefetching}
                        onFinish={() => {
                            if (modalApiLoading || modalApiResponse) {
                                modalApiClear.current = true;
                            }

                            setModalActive(false);
                        }}
                    />
                }
                isLoading={modalApiLoading}
                isRefetching={modalApiRefetching}
                onExited={() => {
                    modalDataClear.current = false;

                    setModalData({});

                    if (modalApiClear.current) {
                        modalApiClear.current = false;

                        setModalApiResponse(false);
                        setModalApiLoading(false);
                    }
                }}
                onClose={
                    modalData.close ? () => setModalActive(false) : undefined
                }
            />
        </>
    );
};

export default DashboardParent;
