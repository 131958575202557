const ShelfFilters = ({ filters, filterSelected, onClick }) => {
    return (
        <>
            <div className="ShelfFilters">
                {filters?.map((object) => {
                    return (
                        <div className="ShelfFilters__Item" key={object.name}>
                            <button
                                className={
                                    "ShelfFilters__Button" +
                                    (filterSelected === object.filter
                                        ? " _active"
                                        : "")
                                }
                                type="button"
                                disabled={filterSelected === object.filter}
                                onClick={() => onClick(object.filter)}
                            >
                                {object.name}
                            </button>
                        </div>
                    );
                })}
            </div>
        </>
    );
};

export default ShelfFilters;
