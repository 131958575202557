import { useState, useEffect, useRef, useContext } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useHistory, useParams } from "react-router-dom";

import { StoreContext } from "../../../context/Store";

import useActionLoading from "../../../hooks/useActionLoading";

import routeLoadingState from "../../../functions/routeLoadingState";

import DashboardContainer from "../../../components/containers/dashboard/DashboardContainer";
import SchoolGroupForm from "../../../components/forms/dashboard/SchoolGroupForm";
import parseTagsFromString from "../../../functions/parseTagsFromString";

import modalDoodleImage_1 from "../../../assets/images/dashboard/modal-doodles/happy_2.svg";

const SchoolGroupEdit = ({ routeMatches, routeSetters, modalSetters }) => {
    const { setRouteData, setRouteIsLoading } = routeSetters;
    const { setModalData } = modalSetters;

    const {isActionLoading, setIsActionLoading} = useActionLoading();

    const history = useHistory();
    const params = useParams();

    const queryClient = useQueryClient();
    const { api } = useContext(StoreContext);

    /* PARAMS ID REF */
    const idSchoolRef = useRef(params.idSchool);
    const idGroupRef = useRef(params.idGroup);

    /* INPUT VALUE */
    const [inputValue, setInputValue] = useState({
        name: "",
        tags: [],
        description: "",
    });

    /* INPUT VALIDATOR */
    const [inputValidator, setInputValidator] = useState({
        name: {
            response: "success",
        },
        tags: {
            response: "success",
        },
        description: {
            response: "success",
        },
    });

    /* ON CHANGE HANDLER */
    const onChangeHandler = (e) => {
        setInputValue({ ...inputValue, ...e.value });
        setInputValidator({ ...inputValidator, ...e.validator });
    };

    /* GET TAGS */
    const {
        data: schoolTagsData,
        isFetched: schoolTagsIsFetched,
        isLoading: schoolTagsIsLoading,
    } = useQuery("tagsList", () => api.get(`/tags`), {
        staleTime: Infinity,
        retry: false,
        refetchOnWindowFocus: false,
    });

    /* EDIT GROUP */
    const { mutate: editSchoolGroupMutate } = useMutation(
        ["schoolGroups", idSchoolRef.current],
        () =>
            api.post(`/teacher/edit_group`, {
                idSchool: idSchoolRef.current,
                idTeacherGroup: idGroupRef.current,
                name: inputValue.name,
                tags: parseTags(inputValue.tags),
                description: inputValue.description,
            }),
        {
            onMutate: () => setIsActionLoading(true),
            onSuccess: () => {
                queryClient.invalidateQueries([
                    "schoolGroup",
                    idGroupRef.current,
                ]);

                setModalData(modalData.current);
            },
            onError: () => setIsActionLoading(false),
        }
    );

    /* PARSE TAGS OBJECTS TO STRING */
    const parseTags = (array) => {
        let str = "";

        for (let i = 0; i < array.length; i++) {
            str += array[i].idTag;

            if (i !== array.length - 1) {
                str += ",";
            }
        }

        return str;
    };

    /* GET SCHOOL GROUP DATA */
    const {
        data: schoolGroupData,
        isFetched: schoolGroupIsFetched,
        isLoading: schoolGroupIsLoading,
    } = useQuery(
        ["schoolGroup", idGroupRef.current],
        () => api.post(`/teacher/group`, { idGroup: idGroupRef.current }),
        {
            staleTime: Infinity,
            retry: false,
            refetchOnWindowFocus: false,
        }
    );

    /* ROUTE LOADING */
    const routeIsLoading = routeLoadingState({
        fetched: [schoolTagsIsFetched, schoolGroupIsFetched],
        loading: [schoolTagsIsLoading, schoolGroupIsLoading],
    });

    /* ROUTE SETTERS */
    useEffect(() => {
        const routeData = {
            title: routeIsLoading ? '' : schoolGroupData.data.name,
        };

        if (routeMatches) {
            setRouteIsLoading(routeIsLoading);
            setRouteData(routeData);
        }
    }, [routeIsLoading, routeMatches, setRouteIsLoading, setRouteData]);

    /* FILL CURRENT VALUES */
    useEffect(() => {
        if (!routeIsLoading) {
            setInputValue({
                name: schoolGroupData.data.name,
                tags: parseTagsFromString(
                    schoolTagsData.data,
                    schoolGroupData.data.tags
                ),
                description: schoolGroupData.data.description,
            });

            setInputValidator({
                name: {
                    response: "success",
                },
                tags: {
                    response: "success",
                },
                description: {
                    response: "success",
                },
            });
        }
    }, [
        routeIsLoading,
        schoolGroupData,
        schoolTagsData,
    ]);

    /* MODAL DATA */
    const modalData = useRef({});

    useEffect(() => {
        if (!schoolGroupIsLoading) {
            modalData.current = {
                data: [
                    {
                        title: {
                            obj: (
                                <p>
                                    {schoolGroupData.data.name}
                                    <br />
                                    została edytowana
                                </p>
                            ),
                            isCenter: true,
                        },
                        img: {
                            obj: modalDoodleImage_1,
                            size: "219px",
                        },
                        action: {
                            obj: {
                                text: "Przejdź dalej",
                                onClick: () => history.goBack(),
                            },
                        },
                    },
                ],
            };
        }
    }, [schoolGroupIsLoading, schoolGroupData, inputValue.name]);

    return (
        <>
            <DashboardContainer>
                <SchoolGroupForm
                    value={inputValue}
                    validator={inputValidator}
                    modalSetters={modalSetters}
                    isDisabled={isActionLoading}
                    onChangeFn={(e) => onChangeHandler(e)}
                    onSubmitFn={editSchoolGroupMutate}
                    onCancelFn={() => history.goBack()}
                />
            </DashboardContainer>
        </>
    );
};

export default SchoolGroupEdit;
