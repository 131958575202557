import { useRef, useEffect, useContext } from "react";
import { useMutation } from "react-query";

import { StoreContext } from "../../../context/Store";

const useSettingsUpdate = (data) => {
    const { themeColor, fontSize, fontFamily } = data;

    const themeColorLast = useRef(themeColor);
    const fontSizeLast = useRef(fontSize);
    const fontFamilyLast = useRef(fontFamily.family);

    const { api } = useContext(StoreContext);

    const { mutate: settingsMutate } = useMutation(
        (data) => api.post("/multibooks/update-settings", data),
        {
            onSuccess: () => {},
            onError: () => {},
        }
    );

    const compareData = (oldData, newData) => {
        const comparedData = oldData.map((element) =>
            newData.includes(element)
        );

        let needsUpdate = false;

        for (const comparedItem of comparedData) {
            if (!comparedItem) {
                needsUpdate = true;
            }
        }

        return needsUpdate;
    };

    useEffect(() => {
        if (
            compareData(
                [
                    themeColorLast.current,
                    fontSizeLast.current,
                    fontFamilyLast.current,
                ],
                [themeColor, fontSize, fontFamily.family]
            )
        ) {
            themeColorLast.current = themeColor;
            fontSizeLast.current = fontSize;
            fontFamilyLast.current = fontFamily.family;

            settingsMutate({
                color: themeColor,
                fontSize,
                fontFamily,
            });
        }
    }, [themeColor, fontSize, fontFamily, settingsMutate]);
};

export default useSettingsUpdate;
