import { useContext } from "react";
import { useMutation } from "react-query";

import { StoreContext } from "../../../context/Store";

const useSaveNote = () => {
    const { api } = useContext(StoreContext);

    const { mutate: saveNote, isSuccess: isSaveNoteSuccess } = useMutation(
        (data) => 
            api.post(`/multibooks/save-note`, {
                ...data
            }),
    );

    return { saveNote, isSaveNoteSuccess };
};

export default useSaveNote;