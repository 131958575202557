const Textarea = ({
    id,
    placeholder,
    value,
    classNameWrapper,
    classNameControl,
    style,
    isDisabled,
    isValid,
    onChangeFn,
    onFocusFn,
}) => {
    return (
        <>
            <div
                className={
                    "form-control-wrapper" +
                    (classNameWrapper ? " " + classNameWrapper : "") +
                    (isValid && isValid.response === "error"
                        ? " error"
                        : "") +
                    (isValid && isValid.response === "success"
                        ? " success"
                        : "")
                }
            >
                <textarea
                    id={id}
                    className={
                        "form-control" +
                        (classNameControl ? " " + classNameControl : "")
                    }
                    placeholder={placeholder}
                    value={value}
                    style={style}
                    disabled={isDisabled}
                    onChange={
                        onChangeFn !== undefined
                            ? (e) => onChangeFn(e)
                            : undefined
                    }
                    onFocus={
                        onFocusFn !== undefined
                            ? (e) => onFocusFn(e)
                            : undefined
                    }
                ></textarea>
            </div>
            {isValid &&
            isValid.response === "error" &&
            isValid.text ? (
                <p className="form-control-message text-center">
                    {isValid.text}
                </p>
            ) : (
                ""
            )}
        </>
    );
};

export default Textarea;
