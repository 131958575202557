const Frame = ({ className, width, children }) => {
    return (
        <>
            <div className={"Frame" + (className ? " " + className : "")}>
                <div
                    className="Frame__Wrapper"
                    style={width ? { maxWidth: width } : undefined}
                >
                    {children}
                </div>
            </div>
        </>
    );
};

export default Frame;
