import "react-big-calendar/lib/css/react-big-calendar.css";
import { Calendar, dateFnsLocalizer } from "./../../dashboard/ReactBigCalendar";
import format from "date-fns/format";
import parse from "date-fns/parse";
import parseJSON from "date-fns/parseJSON";
import startOfWeek from "date-fns/startOfWeek";
import getDay from "date-fns/getDay";
import pl from "date-fns/locale/pl";
import { useState, useContext } from "react";
import { useQuery, useMutation } from "react-query";
import { add, getHours } from "date-fns";
import { StoreContext } from "./../../../context/Store";
import CalendarModal from "../ReactBigCalendar/CalendarModal";
import ModalDeleteQuestion from "./../ReactBigCalendar/ModalDeleteQuestion";
import imgDeleted from "../ReactBigCalendar/assets/Group 2299.svg";

import DashboardContainer from "../../../components/containers/dashboard/DashboardContainer";
import { useEffect } from "react";
import routeLoadingState from "../../../functions/routeLoadingState";
import compareAsc from "date-fns/compareAsc";
import parseISO from "date-fns/parseISO";
import hoursToMinutes from "date-fns/hoursToMinutes";

const locales = {
  pl: pl,
};
const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});
const ChildrenCalendar = ({ routeMatches, routeSetters }) => {
  const { setRouteData, setRouteIsLoading } = routeSetters;

  const { api } = useContext(StoreContext);
  const [id, setId] = useState(null);
  const [title, setTitle] = useState("");
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [desc, setDesc] = useState("");
  const [isModalActive, setIsModalActive] = useState(false);
  const [events, setEvents] = useState("");
  const [deleted, setIsDeleted] = useState(false);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [isnotValid, setIsNotValid] = useState(false);
  const [error, setError] = useState(false);
  const [errorContent, setErrorContent] = useState("");
  const [editing, setEditing] = useState(false);

  const changeEvent = useMutation(
    () =>
      api.post(`/calendar/update`, {
        title: title,
        date_from: start
          .replace("T", " ")
          .slice(0, 11)

          .concat(" " + startTime),
        date_to: end
          .replace("T", " ")
          .slice(0, 11)

          .concat(" " + endTime),
        description: desc,
        idCalendar: id,
      }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setIsModalActive(false);
        setEditing(false);
        refetchCalendar();
        // isModalActiveHandler();
        // queryClient.invalidateQueries("calendar");
      },
      onError: (error) => {},
    }
  );

  const {
    refetch: refetchCalendar,
    data,
    isFetched,
    isRefetching,
    isLoading,
  } = useQuery("calendar", () => api.get(`/calendar`), {
    retry: false,
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      setEvents(
        data.data.map((element) => {
          return {
            id: element.idCalendar,
            title: element.title,
            start: parseJSON(element.date_from.date),
            end: parseJSON(element.date_to.date),
          };
        })
      );
    },
    onError: (error) => {},
  });

  function titleHandler(e) {
    setTitle(e.target.value);
  }
  function startDateHandler(e) {
    setStart(e.target.value);

    if (
      compareAsc(parseISO(e.target.value), parseISO(end.slice(0, 10))) === 1
    ) {
      setIsNotValid(true);
      setError(true);
      setErrorContent("Nieprawidłowa Data");
    } else {
      setIsNotValid(false);
      setError(false);
    }

    if (
      compareAsc(parseISO(e.target.value), parseISO(end.slice(0, 10))) === 0
    ) {
      if (
        hoursToMinutes(startTime.slice(0, 2)) + Number(startTime.slice(3, 5)) >
        hoursToMinutes(endTime.slice(0, 2)) + Number(endTime.slice(3, 5))
      ) {
        setIsNotValid(true);
        setError(true);
        setErrorContent("Nieprawidłowa  godzina");
      }
    }
    if (e.target.value === "") {
      setIsNotValid(true);
      setError(true);
      setErrorContent("Nieprawidłowa  data");
    }
  }

  function endDateHandler(e) {
    setEnd(e.target.value);

    if (
      compareAsc(parseISO(start.slice(0, 10)), parseISO(e.target.value)) === 1
    ) {
      setIsNotValid(true);
      setError(true);
      setErrorContent("Nieprawidłowa Data");
    } else {
      setIsNotValid(false);
      setError(false);
    }

    if (
      compareAsc(parseISO(start.slice(0, 10)), parseISO(e.target.value)) === 0
    ) {
      if (
        hoursToMinutes(startTime.slice(0, 2)) + Number(startTime.slice(3, 5)) >
        hoursToMinutes(endTime.slice(0, 2)) + Number(endTime.slice(3, 5))
      ) {
        setIsNotValid(true);
        setError(true);
        setErrorContent("Nieprawidłowa  godzina");
      }
    }
    if (e.target.value === "") {
      setIsNotValid(true);
      setError(true);
      setErrorContent("Nieprawidłowa  data");
    }
  }
  function descriptionHandler(e) {
    setDesc(e.target.value);
  }
  function setEndTimeCalendar(e) {
    setEndTime(e);

    if (
      compareAsc(parseISO(start.slice(0, 10)), parseISO(end.slice(0, 10))) === 0
    ) {
      if (
        hoursToMinutes(startTime.slice(0, 2)) + Number(startTime.slice(3, 5)) >
        hoursToMinutes(e.target.value.slice(0, 2)) +
          Number(e.target.value.slice(3, 5))
      ) {
        setIsNotValid(true);
        setError(true);
        setErrorContent("Nieprawidłowa  godzina");
      } else {
        setIsNotValid(false);
        setError(false);
      }
    }
  }
  function setStartTimeCalendar(e) {
    setStartTime(e);

    if (
      compareAsc(parseISO(start.slice(0, 10)), parseISO(end.slice(0, 10))) === 0
    ) {
      if (
        hoursToMinutes(e.target.value.slice(0, 2)) +
          Number(e.target.value.slice(3, 5)) >
        hoursToMinutes(endTime.slice(0, 2)) + Number(endTime.slice(3, 5))
      ) {
        setIsNotValid(true);
        setError(true);
        setErrorContent("Nieprawidłowa  godzina");
      } else {
        setIsNotValid(false);
        setError(false);
      }
    }
  }
  const handleEventSelection = (e) => {
    setEditing(true);
    setTitle(e.title);
    setStart(e.start.toJSON().slice(0, 16));

    setStartTime(
      String(e.start).slice(16, 18) + ":" + String(e.start).slice(19, 21)
    );
    setEndTime(String(e.end).slice(16, 18) + ":" + String(e.end).slice(19, 21));

    setEnd(e.end.toJSON().slice(0, 16));
    setId(e.id);

    data.data.map((data) => {
      if (data.idCalendar === e.id) {
        setDesc(data.description);
      }
    });
    setIsModalActive(true);
  };

  /* ROUTE LOADING */
  const routeIsLoading = routeLoadingState({
    fetched: [isFetched],
    loading: [isLoading],
    refetching: [isRefetching],
  });

  /* ROUTE SETTERS */
  useEffect(() => {
    const routeData = {
      title: "Kalendarz",
    };

    if (routeMatches) {
      setRouteIsLoading(routeIsLoading);
      setRouteData(routeData);
    }
  }, [routeIsLoading, routeMatches, setRouteIsLoading, setRouteData]);

  return (
    <DashboardContainer className="calendar">
      {!routeIsLoading && (
        <>
          <Calendar
            localizer={localizer}
            startAccessor="start"
            endAccessor="end"
            style={{ height: 600 }}
            events={events}
            culture="pl"
            onSelectEvent={handleEventSelection}
            messages={{
              month: "Miesiąc",
              day: "Dzień",
              today: "Dzisiaj",
              week: "Tydzień",
              date: "Data",
              time: "Godziny",
              event: "Nazwa wydarzenia",
            }}
          />

          {isModalActive ? (
            <CalendarModal
              setEditing={setEditing}
              editing={editing}
              id={id}
              setTitle={titleHandler}
              title={title}
              start={start}
              end={end}
              desc={desc}
              setStart={startDateHandler}
              setEnd={endDateHandler}
              setIsModalActive={setIsModalActive}
              setDesc={descriptionHandler}
              changeEvent={changeEvent}
              deleted={deleted}
              setIsDeleted={setIsDeleted}
              setStartTimeCalendar={setStartTime}
              setEndTimeCalendar={setEndTime}
              setStartTimeCalendar1={setStartTimeCalendar}
              setEndTimeCalendar1={setEndTimeCalendar}
              startTimeCalendar={startTime}
              endTimeCalendar={endTime}
              incorrect={isnotValid}
              setIncorrect={setIsNotValid}
              errorContent={errorContent}
            />
          ) : (
            ""
          )}
          {deleted ? (
            <ModalDeleteQuestion
              setIsDeleted={setIsDeleted}
              title={"Czy na pewno chcesz" + "\n usunąć wydarzenie?"}
              img={imgDeleted}
              id={id}
            />
          ) : (
            ""
          )}
        </>
      )}
    </DashboardContainer>
  );
};
export default ChildrenCalendar;
