import { useState, useCallback, useEffect, useContext } from "react";
import { Range } from "react-range";
import parse from "html-react-parser";

import { SettingsContext } from "../context/SettingsStore";
import { ChapterContext } from "../context/ChapterStore";
import { ExamContext } from "../context/ExamStore";
import { ScoreContext } from "../context/ScoreStore";

import getExamProgress from "../functions/getExamProgress";
import getExamAnswers from "../functions/getExamAnswers";
import parseFlashcardContent from "../functions/flashcard/parseFlashcardContent";
import parseMathTag from "../functions/parseMathTag";
import parseMathTagConnection from "../functions/parseMathTagConnection";

import ReaderQuizify from "../utils/ReaderQuizify";
import ReaderUser from "../utils/ReaderUser";

const ReaderSlider = ({
    ids,
    title,
    avatar,
    content,
    examContent,
    answersSolved,
    correct,
    flash,
    setExamProgress,
    userAvatar,
    isLoading,
    isExam,
    isGallup,
    isGibs,
    isAnswered,
}) => {
    const { themeColor } = useContext(SettingsContext);
    const { setChapterData, getChapterData, getFullChapterData } =
        useContext(ChapterContext);
    const {
        setExamPagesStore,
        isGallupResultLoading,
        isGallupResultDone,
        isGibsResultLoading,
        isGibsResultDone,
    } = useContext(ExamContext);
    const { isExamScoreLoading, isExamScoreInit } = useContext(ScoreContext);

    /* CORRECT INDEX */
    const sliderIndexCorrect =
        content.map((object) => object.option).indexOf(correct) + 1;

    /* GET STORED SLIDER DATA */
    const sliderValueStored = getChapterData(ids, "slider", content);
    const responseSuccessStored = isExam
        ? undefined
        : sliderIndexCorrect === sliderValueStored;

    /* SLIDER VALUE */
    const [sliderValue, setSliderValue] = useState(sliderValueStored ?? 1);

    /* SLIDER DEFAULTS - BASIC */
    const [responseSuccess, setResponseSuccess] = useState(false);
    const [responseError, setResponseError] = useState(false);

    /* SLIDER CHANGE HANDLER */
    const sliderChangeHandler = (value) => {
        setChapterData(content[value - 1], ids, "slider");

        if (!isExam) {
            if (value === sliderIndexCorrect) {
                setResponseSuccess(true);
            } else {
                setResponseError(true);
            }
        } else {
            /* GET EXAM PROGRESS */
            const examProgress = getExamProgress(
                getFullChapterData(),
                examContent,
                false,
                isGibs
            );

            /* STORE EXAM PROGRESS AND SET NEW STATE */
            setExamPagesStore(ids.idChapter, examProgress, "progress");
            setExamProgress(examProgress);
        }
    };

    /* SET INITIAL VALUE TO DATA STORE TO PREVENT FORCING USER TO RESELECT FIRST VALUE - EXAM */
    useEffect(() => {
        if (!sliderValueStored && isExam) {
            setChapterData(content[0], ids, "slider");

            /* GET EXAM PROGRESS */
            const examProgress = getExamProgress(
                getFullChapterData(),
                examContent,
                false,
                isGibs
            );

            /* STORE EXAM PROGRESS AND SET NEW STATE */
            setExamPagesStore(ids.idChapter, examProgress, "progress");
            setExamProgress(examProgress);
        }
    }, [
        sliderValueStored,
        setChapterData,
        content,
        ids,
        examContent,
        getFullChapterData,
        setExamPagesStore,
        setExamProgress,
        isExam,
        isGibs,
    ]);

    /* ENABLE NEXT SLIDE AFTER TIMEOUT - BASIC */
    useEffect(() => {
        if (responseError) {
            setTimeout(() => {
                setResponseError(false);
            }, 1000);
        }
    }, [responseError]);

    /* GET RANGE THUMB COLOR */
    const getThumbColor = useCallback(() => {
        if (
            (responseSuccess && !isExam) ||
            (responseSuccessStored && !isExam) ||
            (responseError && !isExam)
        ) {
            if (responseSuccess || responseSuccessStored) {
                return themeColor === "dark" ? "#4EAD75" : "#9AC8C8";
            }

            if (responseError) {
                return themeColor === "dark" ? "#FF6801" : "#FF6801";
            }
        } else {
            if (isGallup || isGibs) {
                return "#85D6A6";
            } else {
                return "#0C6FFA";
            }
        }
    }, [
        responseSuccess,
        responseSuccessStored,
        responseError,
        themeColor,
        isExam,
        isGallup,
        isGibs,
    ]);

    return (
        <div className={"ReaderSlider" + (isAnswered ? " _is--answered" : "")}>
            {title ? (
                <div className="ReaderSlider__Title">
                    {parseFlashcardContent(
                        parse(parseMathTagConnection(title)),
                        flash
                    ).map((object) => {
                        return object;
                    })}
                </div>
            ) : (
                ""
            )}
            {content?.length > 1 ? (
                <>
                    <div className="ReaderSlider__Legend">
                        {content.map((object, index) => {
                            return (
                                <div
                                    className={
                                        "ReaderSlider__Tooltip" +
                                        (index === 0 ? " _is--first" : "") +
                                        (index === content.length - 1
                                            ? " _is--last"
                                            : "")
                                    }
                                    key={"rangeSlider_" + index}
                                >
                                    <div className="ReaderSlider__Text">
                                        {typeof object === "object"
                                            ? parseMathTag(parseMathTagConnection(object.option))
                                            : parseMathTag(parseMathTagConnection(object))}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    {isAnswered ? (
                        <ReaderRange
                            ids={ids}
                            content={content}
                            userAvatar={userAvatar}
                            isCorrect={correct}
                            isChosen={getExamAnswers(
                                ids.idContent,
                                answersSolved
                            )}
                        />
                    ) : (
                        <Range
                            step={1}
                            min={1}
                            max={content.length}
                            values={[sliderValue]}
                            renderMark={({ props }) => (
                                <div
                                    {...props}
                                    style={{
                                        ...props.style,
                                        width: "2px",
                                        height: "24px",
                                        background:
                                            themeColor === "dark"
                                                ? "#FFFFFF"
                                                : !isGallup && !isGibs
                                                ? "#0c6ffa"
                                                : "#85D6A6",

                                        opacity:
                                            themeColor === "dark"
                                                ? "0.46"
                                                : !isGallup && !isGibs
                                                ? "0.32"
                                                : "0.5",

                                        marginTop: "-7px",
                                        transition:
                                            "background .3s ease, opacity .3s ease",
                                    }}
                                ></div>
                            )}
                            renderTrack={({ props, children }) => (
                                <div
                                    {...props}
                                    style={{
                                        ...props.style,
                                        height: "10px",
                                        width: "100%",
                                        backgroundColor:
                                            themeColor === "dark"
                                                ? "#625E5D"
                                                : !isGallup && !isGibs
                                                ? "#D9E9FF"
                                                : "#E1F5E9",
                                        borderRadius: "0",
                                        transition: "background .3s ease",
                                    }}
                                    data-panel="false"
                                >
                                    {children}
                                </div>
                            )}
                            renderThumb={({ props }) => (
                                <div
                                    {...props}
                                    style={{
                                        ...props.style,
                                        height: "48px",
                                        width: "28px",
                                        backgroundColor: "transparent",
                                        borderRadius: "10px",
                                    }}
                                >
                                    {isGallup && avatar ? (
                                        <div
                                            style={{
                                                position: "absolute",
                                                top: "50%",
                                                left: "50%",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                height: "30px",
                                                width: "30px",
                                                backgroundColor: "transparent",
                                                borderRadius: "50%",
                                                marginTop: "-15px",
                                                marginLeft: "-15px",
                                            }}
                                        >
                                            <img
                                                src={avatar}
                                                style={{
                                                    width: "100%",
                                                    maxWidth: "100%",
                                                    height: "auto",
                                                    maxHeight: "30px",
                                                }}
                                                alt=""
                                            />
                                        </div>
                                    ) : (
                                        <div
                                            style={{
                                                position: "absolute",
                                                top: "50%",
                                                left: "50%",
                                                height: "34px",
                                                width: "14px",
                                                backgroundColor:
                                                    themeColor === "dark" &&
                                                    isGallup
                                                        ? "#3DAF6B"
                                                        : getThumbColor(),
                                                borderRadius: "10px",
                                                marginTop: "-17px",
                                                marginLeft: "-7px",
                                                transition:
                                                    "background .2s ease",
                                            }}
                                        />
                                    )}
                                </div>
                            )}
                            disabled={
                                responseError ||
                                responseSuccess ||
                                responseSuccessStored ||
                                isLoading ||
                                isExamScoreLoading ||
                                isExamScoreInit ||
                                isGallupResultLoading ||
                                isGallupResultDone ||
                                isGibsResultLoading ||
                                isGibsResultDone
                            }
                            onChange={(values) => setSliderValue(...values)}
                            onFinalChange={(value) =>
                                sliderChangeHandler(...value)
                            }
                        />
                    )}

                    {(!isExam && responseSuccess) ||
                    (!isExam && responseSuccessStored) ? (
                        <ReaderQuizify content="Gratulacje! Udzieliłeś/aś dobrej odpowiedzi" />
                    ) : (
                        ""
                    )}
                </>
            ) : (
                <div className="ReaderSlider__Error">
                    Nie można wyświetlić komponentu suwaka z powodu braku lub
                    jednej zadeklarowanej opcji.
                </div>
            )}
        </div>
    );
};

const ReaderRange = ({ ids, content, userAvatar, isChosen, isCorrect }) => {
    return (
        <div className="ReaderRange">
            <div className="ReaderRange__Marks">
                {content.map((object, index) => {
                    return (
                        <div
                            className={
                                "ReaderRange__Mark" +
                                (index === 0 ? " _is--first" : "") +
                                (index === content.length - 1
                                    ? " _is--last"
                                    : "")
                            }
                            key={
                                "chapter-" +
                                ids.idChapter +
                                "_content-" +
                                ids.idContent +
                                "_mark-" +
                                index
                            }
                        ></div>
                    );
                })}
            </div>
            <div className="ReaderRange__Thumbs">
                {content.map((object, index) => {
                    return (
                        <div
                            className={
                                "ReaderRange__Thumb" +
                                (index === 0 ? " _is--first" : "") +
                                (index === content.length - 1
                                    ? " _is--last"
                                    : "") +
                                (object.option === isChosen?.option ||
                                object.option === isCorrect
                                    ? " _is--shown"
                                    : "") +
                                (object.option === isCorrect
                                    ? " _is--correct"
                                    : "")
                            }
                            key={
                                "chapter-" +
                                ids.idChapter +
                                "_content-" +
                                ids.idContent +
                                "_thumb-" +
                                index
                            }
                        >
                            {object.option === isChosen?.option && (
                                <ReaderUser avatar={userAvatar} type="slider" />
                            )}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default ReaderSlider;
