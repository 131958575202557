import React, { useState, useEffect, useRef, forwardRef } from "react";

import useActionLoading from "../../hooks/useActionLoading";

import RouteLoader from "./RouteLoader";

const RouteDelay = forwardRef(
    (
        {
            routeMatch,
            routeTimeout,
            routeIsLoading,
            routeIsBackward,
            children,
            routeSetters,
            modalSetters,
        },
        ref
    ) => {
        const { setRouteData, setRouteIsLoading } = routeSetters;
        const {
            setModalData,
            setModalActive,
            setModalApiResponse,
            setModalApiLoading,
        } = modalSetters;

        const { isActionLoading, setIsActionLoading } = useActionLoading();

        /* ROUTE MATCH */
        const routeMatches =
            routeMatch.match?.path === routeMatch.path ? true : false;

        /* ROUTE PATH */
        const routePath = useRef(routeMatch.path);
        const routePathLast = useRef(null);

        /* ROUTE MOUNT */
        const routeMounted = useRef(false);

        /* ROUTE INIT/LOADING */
        const [routeIsInit, setRouteIsInit] = useState(false);
        const [routeLoading, setRouteLoading] = useState(false);
        const routeLoadingFlag = useRef(false);

        /* ROUTE ANIMATION INIT */
        const [routeAnimationInit, setRouteAnimationInit] = useState(false);
        const routeAnimationInitFlag = useRef(false);

        /* ROUTE ANIMATION START */
        const [routeAnimationStart, setRouteAnimationStart] = useState(false);

        /* ROUTE TIMEOUTS */
        const routeDelayTimeout = useRef(null);
        const routeAnimationTimeout = useRef(null);

        /* MOUNT COMPONENT IF ROUTE MATCHES TO PREVENT RERENDERING OLD COMPONENT */
        useEffect(() => {
            routeMounted.current = routeMatches ? true : false;

            return () => {
                routeMounted.current = false;
            };
        }, [routeMatches]);

        /* SET ROUTE INIT AND LOADING */
        useEffect(() => {
            if (routeMounted.current) {
                if (routeIsLoading && !routeLoadingFlag.current) {
                    routeLoadingFlag.current = true;
                    setRouteIsInit(true);
                    setRouteLoading(true);
                }

                if (!routeIsLoading && routeLoadingFlag.current) {
                    routeLoadingFlag.current = false;
                    setRouteLoading(false);
                }
            }
        }, [routeIsLoading]);

        useEffect(() => {
            if (
                routeMounted.current &&
                routePath.current !== routePathLast.current
            ) {
                routePathLast.current = routePath.current;

                if (
                    !routeIsLoading &&
                    isActionLoading &&
                    !routeLoadingFlag.current
                ) {
                    setTimeout(() => {
                        setIsActionLoading(false);
                    }, 500);
                }

                routePath.current = routeMatch.path;
            }
        }, [
            routeIsLoading,
            routeLoading,
            isActionLoading,
            setIsActionLoading,
            routeMatch.path,
        ]);

        /* ANIMATE ROUTE IF THERE IS LOADING */
        useEffect(() => {
            if (routeLoading) {
                setRouteAnimationInit(true);
                routeAnimationInitFlag.current = true;
            }

            if (!routeLoading && routeAnimationInitFlag.current) {
                clearTimeout(routeDelayTimeout.current);

                routeDelayTimeout.current = setTimeout(
                    () => {
                        setRouteAnimationStart(true);

                        clearTimeout(routeAnimationTimeout.current);

                        routeAnimationTimeout.current = setTimeout(() => {
                            setRouteAnimationStart(false);
                            setRouteAnimationInit(false);
                            setRouteIsInit(false);
                        }, 500); /* TIMEOUT VALUE MUST BE THE SAME AS FINISHING TRANSITION IN CSS */
                    },
                    routeTimeout ? routeTimeout : 0
                );
            }
        }, [routeLoading, routeTimeout]);

        return (
            <>
                <div className="RouteDelay" ref={ref}>
                    <div className="RouteDelay__List">
                        <div
                            className={
                                "RouteDelay__Track" +
                                (routeIsBackward ? " _inverse" : "") +
                                (routeAnimationInit
                                    ? " _animating" +
                                      (routeAnimationStart ? " _in" : "")
                                    : "")
                            }
                        >
                            {routeIsInit && (
                                <div className="RouteDelay__Item _loader">
                                    <RouteLoader />
                                </div>
                            )}

                            <div className="RouteDelay__Item _content">
                                <div className="RouteDelay__Content">
                                    {children
                                        ? React.cloneElement(children, {
                                              routeMatches: routeMatches,
                                              routeSetters: {
                                                  setRouteData: setRouteData,
                                                  setRouteIsLoading:
                                                      setRouteIsLoading,
                                              },
                                              modalSetters: {
                                                  setModalData: setModalData,
                                                  setModalActive:
                                                      setModalActive,
                                                  setModalApiResponse:
                                                      setModalApiResponse,
                                                  setModalApiLoading:
                                                      setModalApiLoading,
                                              },
                                          })
                                        : "Route is missing children to display"}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
);

export default RouteDelay;
