import { useEffect, useContext } from "react";

import FlashcardProvider from "./context/FlashcardStore";
import { ExamContext } from "./context/ExamStore";
import { ScoreContext } from "./context/ScoreStore";

import useGetExamAnswers from "./hooks/useGetExamAnswers";
import useGetExamSolved from "./hooks/useGetExamSolved";

import getRandomInt from "./functions/getRandomInt";

import ReaderBasic from "./page-types/ReaderBasic";
import ReaderExam from "./page-types/ReaderExam";
import ReaderFinish from "./page-types/ReaderFinish";

import ReaderProgress from "./utils/ReaderProgress";
import ReaderFiller from "./utils/ReaderFiller";

const ReaderChapter = ({
    idBook,
    idChapter,
    type,
    titles,
    pages,
    content,
    pager,
    note,
    cover,
    shutdown,
    isPending,
    isLoading,
    isExam,
    isGallup,
    isGibs,
    isAnswered,
    isFinished,
}) => {
    const { titleBook, titleChapter } = titles;
    const { page, pagesTotal } = pages;

    const { getShareMarkStore, isExamReloading, setIsExamReloading } =
        useContext(ExamContext);
    const { isExamScoreInit, setIsExamScoreInit } = useContext(ScoreContext);

    /* EXAM ANSWERS HOOK */
    const { examAnswersData, isExamAnswersLoading } = useGetExamAnswers(
        idChapter,
        isExam,
        isAnswered
    );

    /* EXAM SOLVED HOOK */
    const { examSolvedData, isExamSolvedLoading } = useGetExamSolved(
        idChapter,
        isExam,
        isAnswered ? false : true
    );

    /* EXAM SCORE CLOSE HANDLE AFTER RELOADING COMPONENT */
    useEffect(() => {
        if (
            isAnswered &&
            !isExamSolvedLoading &&
            isExamReloading &&
            isExamScoreInit
        ) {
            setIsExamReloading(false);

            setTimeout(() => {
                setIsExamScoreInit(false);
            }, getRandomInt(500, 1000));
        }
    }, [
        isAnswered,
        isExamSolvedLoading,
        isExamReloading,
        isExamScoreInit,
        setIsExamReloading,
        setIsExamScoreInit,
    ]);

    return (
        <>
            <FlashcardProvider>
                <div className="ReaderChapter">
                    <div className="ReaderChapter__Heading">
                        <p>{titleBook}</p>
                    </div>
                    {isFinished ? (
                        <ReaderFinish
                            idBook={idBook}
                            type={type}
                            pages={pages}
                            pager={pager}
                            shutdown={shutdown}
                            isPedning={isPending}
                            isLoading={isLoading}
                            isGallup={isGallup}
                            isGibs={isGibs}
                        />
                    ) : isExam ? (
                        <>
                            {(!isExamAnswersLoading && !isAnswered) ||
                            (!isExamSolvedLoading && isAnswered) ? (
                                <ReaderExam
                                    idChapter={idChapter}
                                    pages={pages}
                                    content={
                                        !isAnswered
                                            ? content
                                            : examSolvedData.questions
                                    }
                                    pager={pager}
                                    answers={examAnswersData}
                                    answersSolved={
                                        isAnswered
                                            ? examSolvedData.answers.answer
                                            : undefined
                                    }
                                    note={note}
                                    userAvatar={
                                        isAnswered
                                            ? examSolvedData.user.avatar
                                            : undefined
                                    }
                                    cover={cover}
                                    isPending={isPending}
                                    isLoading={isLoading}
                                    isGallup={isGallup}
                                    isGibs={isGibs}
                                    isAnswered={isAnswered}
                                    isShared={
                                        getShareMarkStore(idChapter) === 1
                                            ? 1
                                            : examSolvedData
                                            ? examSolvedData.answers
                                                  .display_share
                                            : 0
                                    }
                                />
                            ) : (
                                !isExamReloading && (
                                    <ReaderFiller
                                        isGallup={isGallup}
                                        isGibs={isGibs}
                                    />
                                )
                            )}
                        </>
                    ) : (
                        <ReaderBasic
                            title={titleChapter}
                            pages={pages}
                            content={content}
                            pager={pager}
                            note={note}
                            cover={cover}
                            isPending={isPending}
                            isLoading={isLoading}
                        />
                    )}
                    {((!isGallup && !isGibs) || (isGallup && isFinished)) && (
                        <ReaderProgress
                            className="_progress--page"
                            color={isGallup ? "secondary" : ""}
                            numbers={{ current: page, total: pagesTotal }}
                        />
                    )}
                </div>
            </FlashcardProvider>
        </>
    );
};

export default ReaderChapter;
