import { useState, useRef, useContext } from "react";
import { useQuery } from "react-query";
import { CSSTransition } from "react-transition-group";

import { StoreContext } from "../context/Store";

import RemindPasswordForm from "../components/forms/remindpassword/RemindPasswordForm";
import RemindPasswordSuccessForm from "../components/forms/remindpassword/RemindPasswordSuccessForm";
import useProtectedRoute from "../hooks/useProtetedRoute";

const RemindPassword = () => {
    useProtectedRoute("/dashboard", true);

    const [isRemindLoading, setIsRemindLoading] = useState(false);

    const { api } = useContext(StoreContext);

    const { refetch: resetPasswordRefetch } = useQuery(
        "resetPassword",
        () =>
            api.post(`/reset_password`, {
                email: data.value,
            }),
        {
            retry: false,
            enabled: false,
            refetchOnWindowFocus: false,
            onSuccess: () => setRemindPasswordSuccess(true),
            onError: () => {
                setData({
                    ...data,
                    valid: {
                        response: "error",
                        errorMessage: "Nieprawidłowy adres e-mail",
                    },
                });

                setIsRemindLoading(false);
            },
        }
    );

    /* REMIND PASSWORD SUCCESS STATE */
    const [remindPasswordSuccess, setRemindPasswordSuccess] = useState(false);

    /* COMPONENTS REFS */
    const remindPasswordRef = useRef(null);
    const remindPasswordSuccessRef = useRef(null);

    /* REMIND PASSWORD DATA */
    const [data, setData] = useState({
        value: "",
        valid: {
            response: "",
            errorMessage: "",
        },
    });

    return (
        <>
            <div className="app-remindPassword">
                <CSSTransition
                    nodeRef={remindPasswordRef}
                    in={!remindPasswordSuccess}
                    classNames="remindPassword"
                    timeout={300}
                    unmountOnExit
                    appear
                >
                    <RemindPasswordForm
                        ref={remindPasswordRef}
                        data={data}
                        isLoading={isRemindLoading}
                        onChangeFn={(e) => setData(e)}
                        onClickFn={() => {
                            setIsRemindLoading(true);

                            resetPasswordRefetch();
                        }}
                    />
                </CSSTransition>
                <CSSTransition
                    nodeRef={remindPasswordSuccessRef}
                    in={remindPasswordSuccess}
                    classNames="remindPassword-success"
                    timeout={300}
                    unmountOnExit
                    appear
                >
                    <RemindPasswordSuccessForm
                        ref={remindPasswordSuccessRef}
                        email={data.value}
                    />
                </CSSTransition>
            </div>
        </>
    );
};

export default RemindPassword;
