import { useState, useRef } from "react";

const InputMasked = ({
    id,
    classNameWrapper,
    classNameControl,
    value,
    errorOnEmpty,
    placeholder,
    placeholderFocus,
    separator,
    fields,
    errorMessage,
    isDisabled,
    isValid,
    onChangeFn,
}) => {
    /* PLACEHOLDER STATE */
    const [placeholderState, setPlaceholderState] = useState(false);

    /* FLAGS */
    const backspaceKey = useRef(false);
    const validationParser = useRef(false);

    /* VARS */
    const valueTemp = useRef(value);
    const responseTemp = useRef("");

    const getMaxLength = (objArray) => {
        let intTemp = 0;

        for (const obj of objArray) {
            intTemp += obj.rows;
        }

        return intTemp;
    };

    const padValue = (str) => {
        let strTemp = "";

        strTemp =
            str.toString().length < 2 ? "0" + str.toString() : str.toString();

        return strTemp;
    };

    const setResponse = (v) => {
        if (v.length > 0) {
            if (v.length === getMaxLength(fields)) {
                responseTemp.current = "success";
            } else {
                responseTemp.current = "error";
            }
        } else {
            if(errorOnEmpty) {
                responseTemp.current = "error";
            } else {
                responseTemp.current = "";
            }
        }
    };

    const parseValue = (v) => {
        let val = v;
        let shift = 0;
        let charsRow = 0;

        for (let i = 0; i < v.length; i++) {
            if (i !== 0 && charsRow / fields[shift].rows === 1) {
                charsRow = 0;

                val =
                    val.slice(0, i + shift) +
                    (separator ? separator : " ") +
                    val.slice(i + shift);

                shift++;
            }

            charsRow++;
        }

        return val;
    };

    const setValue = (v) => {
        checkForValidation(v);

        if (validationParser.current) {
            valueTemp.current = validateValue(v);
        } else {
            valueTemp.current = v;
        }
    };

    const checkForValidation = (v) => {
        let valSplit = v.split(separator ? separator : " ");
        let rows = fields.length;

        for (let i = 0; i < rows; i++) {
            if (
                (fields[i].min || fields[i].max) &&
                valSplit[i] &&
                valSplit[i].length === fields[i].rows
            ) {
                let int = parseInt(valSplit[i]);

                if (int < fields[i].min || int > fields[i].max) {
                    validationParser.current = true;
                }
            }
        }
    };

    const validateValue = (v) => {
        let val = "";
        let valSplit = v.split(separator ? separator : " ");
        let rows = fields.length;

        for (let i = 0; i < rows; i++) {
            if (
                (fields[i].min || fields[i].max) &&
                valSplit[i] &&
                valSplit[i].length === fields[i].rows
            ) {
                let int = parseInt(valSplit[i]);

                if (int < fields[i].min) {
                    int = fields[i].min;
                }

                if (int > fields[i].max) {
                    int = fields[i].max;
                }

                val += padValue(int);
            } else if (valSplit[i] && valSplit[i].length === fields[i].rows) {
                val += valSplit[i];
            }
        }

        if (validationParser.current) {
            validationParser.current = false;
        }

        return parseValue(val);
    };

    const onChangeHandler = (e) => {
        const valueClean = e.replaceAll(separator ? separator : " ", "");

        if (fields) {
            if (valueClean.length <= getMaxLength(fields)) {
                setValue(parseValue(valueClean));
                setResponse(valueClean);

                onChangeFn({
                    value: valueTemp.current,
                    response: responseTemp.current,
                });
            }
        } else {
            onChangeFn(e);
        }
    };

    const numTest = (e) => {
        return (/[a-zA-Z!@#$%^&*)(+=_\-|\\}\]{["':;?/>.<,]/g.test(e.key) &&
            e.key !== "Backspace" &&
            e.key !== "ArrowLeft" &&
            e.key !== "ArrowRight" &&
            e.key !== "Tab") ||
            e.key === " "
            ? true
            : false;
    };

    const onKeyHandler = (e) => {
        if (e.key === "Backspace") {
            backspaceKey.current = true;
        } else {
            backspaceKey.current = false;
        }

        if (numTest(e)) {
            e.preventDefault();

            return false;
        }
    };

    return (
        <>
            <div
                className={
                    "form-control-wrapper" +
                    (classNameWrapper ? " " + classNameWrapper : "") +
                    (isValid === "success" ? " success" : "") +
                    (isValid === "error" ? " error" : "")
                }
            >
                <input
                    id={id}
                    className={
                        "form-control" +
                        (classNameControl ? " " + classNameControl : "")
                    }
                    type="text"
                    value={value}
                    placeholder={
                        placeholderState ? placeholderFocus : placeholder
                    }
                    disabled={isDisabled}
                    pattern="\d*"
                    onKeyDown={(e) => onKeyHandler(e)}
                    onKeyUp={(e) => onKeyHandler(e)}
                    onChange={(e) => onChangeHandler(e.currentTarget.value)}
                    onFocus={
                        placeholderFocus
                            ? () => setPlaceholderState(true)
                            : undefined
                    }
                />
            </div>
            {isValid === "error" && errorMessage ? (
                <p className="form-control-message text-center">
                    {errorMessage}
                </p>
            ) : (
                ""
            )}
        </>
    );
};

export default InputMasked;
