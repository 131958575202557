import ReaderLoader from "./ReaderLoader";

import doodle from "../assets/images/doodle-loader.svg";

const ReaderFiller = ({ isGibs, isGallup }) => {
    return (
        <div className="ReaderFiller">
            <div className="ReaderFiller__Wrapper">
                <div className="ReaderFiller__Loader">
                    <ReaderLoader
                        color={isGibs || isGallup ? "secondary" : ""}
                    />
                </div>
                <div className="ReaderFiller__Doodle">
                    <img src={doodle} alt="" />
                </div>
            </div>
        </div>
    );
};

export default ReaderFiller;
