import { useRef } from 'react';
import { useHistory, useLocation, useParams } from "react-router-dom";

import pathPrev from '../../functions/pathPrev';

import DashboardContainer from "../../components/containers/dashboard/DashboardContainer";
import LibraryGroup from "../../components/containers/dashboard/library/LibraryGroup";

const LibraryCategory = ({ routeMatches, routeSetters }) => {
    const { setRouteData, setRouteIsLoading } = routeSetters;

    const history = useHistory();
    const location = useLocation();
    const params = useParams();

    /* PARAMS ID REF */
    const idCategoryRef = useRef(parseInt(params.idCategory));

    return (
        <>
            <DashboardContainer className="library">
                <LibraryGroup
                    shelfData={{
                        title: ' ',
                        page: 'category',
                        id: idCategoryRef.current,
                        action: {
                            type: "button",
                            props: {
                                text: "ULUBIONE",
                                icon: "heart",
                                onClick: () =>
                                    history.replace(
                                        `${pathPrev(location.pathname, 1)}/favorites`
                                    ),
                            },
                        }
                    }}
                    routePass={{
                        routeMatches: routeMatches,
                        setRouteData: setRouteData,
                        setRouteIsLoading: setRouteIsLoading
                    }}
                />
            </DashboardContainer>
        </>
    );
};

export default LibraryCategory;
