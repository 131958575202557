const parseMathText = (string) => {
    if (!string || typeof string !== "string") return "";

    let newString = string;

    const startTag = "\\text{";
    const endTag = "}";

    let startTagPosition = newString.indexOf(startTag);
    let endTagPosition = newString.indexOf(endTag, startTag);

    while (startTagPosition > -1 && endTagPosition > -1) {
        newString =
            newString.slice(0, startTagPosition) +
            newString
                .slice(startTagPosition, endTagPosition + 1)
                .replaceAll(".", " ") +
            newString.slice(endTagPosition + 1);

        startTagPosition = newString.indexOf(startTag, ++startTagPosition);

        endTagPosition = newString.indexOf(endTag, startTagPosition);
    }

    return newString;
};

export default parseMathText;
