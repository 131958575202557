const ChapterIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="23.783"
            height="14.057"
            viewBox="0 0 23.783 14.057"
        >
            <g
                id="Group_1287"
                dataname="Group 1287"
                transform="translate(-32.5 -626.5)"
            >
                <line
                    id="Line_20"
                    dataname="Line 20"
                    x2="1.206"
                    transform="translate(33.5 627.5)"
                    fill="none"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeWidth="2"
                />
                <line
                    id="Line_21"
                    dataname="Line 21"
                    x2="1.206"
                    transform="translate(33.5 633.529)"
                    fill="none"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeWidth="2"
                />
                <line
                    id="Line_22"
                    dataname="Line 22"
                    x2="1.206"
                    transform="translate(33.5 639.557)"
                    fill="none"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeWidth="2"
                />
                <line
                    id="Line_23"
                    dataname="Line 23"
                    x2="14.549"
                    transform="translate(40.734 627.5)"
                    fill="none"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeWidth="2"
                />
                <line
                    id="Line_24"
                    dataname="Line 24"
                    x2="14.549"
                    transform="translate(40.734 633.529)"
                    fill="none"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeWidth="2"
                />
                <line
                    id="Line_25"
                    dataname="Line 25"
                    x2="14.549"
                    transform="translate(40.734 639.557)"
                    fill="none"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeWidth="2"
                />
            </g>
        </svg>
    );
};

export default ChapterIcon;
