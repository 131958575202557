const ButtonNotify = ({
    className,
    accountActive,
    subscriptionActive,
    hasChildren,
    thumbnail,
    children,
    onClickFn
}) => {
    return (
        <>
            <div className="btn-notify-wrapper">
                <button
                    className={
                        "btn-notify" +
                        (className ? " " + className : "") +
                        (accountActive ? " acc-active" : "") +
                        (!subscriptionActive && hasChildren ? " sub-inactive" : "")
                    }
                    type="button"
                    onClick={onClickFn !== undefined ? onClickFn : undefined}
                >
                    <span className="notify-title">{children}</span>
                    {accountActive && (
                        <span className="notify-arrow">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10.114"
                                height="15.985"
                                viewBox="0 0 10.114 15.985"
                            >
                                <path
                                    id="Path_50"
                                    data-name="Path 50"
                                    d="M-296.347,1986.372l5.871,5.871-5.871,5.871"
                                    transform="translate(298.468 -1984.251)"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeWidth="3"
                                />
                            </svg>
                        </span>
                    )}
                    {thumbnail && accountActive && (
                        <span
                            className="notify-thumbnail"
                            style={thumbnail.styles}
                        >
                            <img
                                src={thumbnail.img}
                                alt=""
                                style={{
                                    maxWidth: thumbnail.styles.width,
                                    maxHeight: thumbnail.styles.height,
                                }}
                            />
                        </span>
                    )}
                </button>
            </div>
        </>
    );
};

export default ButtonNotify;
