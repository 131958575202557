import LearningBar from "./LearningBar";

const LearningPreview = ({ data }) => {
    return (
        <>
            <div className="LearningPreview">
                {data?.length > 0 &&
                    data.map((object) => {
                        return (
                            <div
                                className="LearningPreview__Item"
                                key={"LearningPreview_" + object[0].idChapter}
                            >
                                <div className="LearningPreview__Main">
                                    <div className="LearningPreview__Title">
                                        {object[0].subject_name ??
                                            object[0].chapter_title}
                                    </div>
                                    <LearningBar
                                        caption={
                                            <>
                                                Ostatni wynik z testu:&nbsp;
                                                <strong>
                                                    {object[0].score_pct}%
                                                </strong>
                                            </>
                                        }
                                        percent={object[0].score_pct}
                                        range
                                    />
                                </div>
                                <div className="LearningPreview__Aside">
                                    <div
                                        className="LearningPreview__Thumbnail"
                                        style={{
                                            backgroundImage:
                                                "url(" +
                                                object[0].subject_img +
                                                ")",
                                        }}
                                    />
                                </div>
                            </div>
                        );
                    })}
            </div>
        </>
    );
};

export default LearningPreview;
