const activateAccountErrorHandler = (
    statusCode,
    responseError,
    onInvalidCode
) => {
    switch (statusCode) {
        case 400:
            switch (responseError) {
                // case "ACCOUNT_ACTIVE":
                case "INVALID_TOKEN":
                    onInvalidCode('Nieprawidłowy kod');
                    return;
                case "INCORRECT_ACCOUNT":
                    onInvalidCode('Wystąpił błąd proszę sprawdzić e-mail')
                default:
                    console.log('status - unhandled error')
                    return;
            }

        default:
            console.log('status' + statusCode);
    }
};

export default activateAccountErrorHandler;
