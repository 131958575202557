import { useState, createContext } from "react";

export const PlayerContext = createContext();

const PlayerProvider = ({ children }) => {
    const [playerData, setPlayerData] = useState(null);

    return (
        <PlayerContext.Provider value={{ playerData, setPlayerData }}>
            {children}
        </PlayerContext.Provider>
    );
};

export default PlayerProvider;
