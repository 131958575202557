const SchoolPanel = ({ id, text, disabled, onClick }) => {
    return (
        <button
            className="SchoolPanel"
            type="button"
            disabled={disabled}
            onClick={() => onClick(id)}
        >
            <span className="SchoolPanel__Title">{text?.title ? text.title : 'Title is undefined'}</span>
            <span className="SchoolPanel__Action">
                {text?.action ? text.action : 'Action text is undefined'}
                <span className="SchoolPanel__Icon">
                    <svg
                        className="SchoolPanel__IconSvg"
                        xmlns="http://www.w3.org/2000/svg"
                        width="8.681"
                        height="14.533"
                        viewBox="0 0 8.681 14.533"
                    >
                        <path
                            className="SchoolPanel__IconPath"
                            id="schoolPanelIconPath_1"
                            dataname="schoolPanelIconPath 1"
                            d="M0,0,5.853,5.853,0,11.7"
                            transform="translate(1.414 1.414)"
                            fill="none"
                            stroke="#2e2a26"
                            strokeLinecap="round"
                            strokeWidth="2"
                        />
                    </svg>
                </span>
            </span>
        </button>
    );
};

export default SchoolPanel;
