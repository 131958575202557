import accept_icon from "../../assets/images/dashboard/button-confirm/accept-icon.svg";
import decline_icon from "../../assets/images/dashboard/button-confirm/decline-icon.svg";

const ButtonChoice = ({ className, decline, isDisabled, onClickFn }) => {
    return (
        <>
            <button
                className={"btn-choice" + (className ? " " + className : "")}
                type="button"
                disabled={isDisabled}
                onClick={onClickFn}
            >
                <img src={decline ? decline_icon : accept_icon} alt="" />
            </button>
        </>
    );
};

export default ButtonChoice;
