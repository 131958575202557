const ReaderAudio = ({url}) => {
    return (
        <div className="ReaderAudio" data-panel="false">
             <audio className="ReaderAudio__Player" controls>
                <source src={url} type="audio/mp3"/>
            </audio> 
        </div>
    )
}

export default ReaderAudio;