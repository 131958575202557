import { useContext } from "react";
import { useQuery } from "react-query";

import { StoreContext } from "../context/Store";

const notificationsRefetchTime = 60 * 1000;

const useNotifications = (key) => {
    const { api } = useContext(StoreContext);

    const {
        data: notificationsData,
        isFetched: isNotificationsFetched,
        isRefetching: isNotificationsRefetching,
        isLoading: isNotificationsLoading,
        remove: removeNotifications,
    } = useQuery(key, () => api.get(`/notifications/get${window.sessionStorage.getItem("expoPushToken") ? "?token=" + window.sessionStorage.getItem("expoPushToken") : ""}`), {
        staleTime: notificationsRefetchTime,
        retry: 3,
        refetchOnWindowFocus: false,
        refetchInterval: notificationsRefetchTime,
        token: window.sessionStorage.getItem("expoPushToken")

    });

    return {
        notificationsData: notificationsData?.data,
        isNotificationsFetched,
        isNotificationsRefetching,
        isNotificationsLoading,
        removeNotifications,
    };
};

export default useNotifications;
