import { getStoreUrl } from "../../../../pages/dashboard/parent/Subscriptions";
import Button from "../../../buttons/Button";
import TextSmall from "../../TextSmall";

const statusConditions = (conditionType, statusValues) => {
    if (conditionType === "badge") {
        return statusValues.badge;
    }
    if (conditionType === "class") {
        return statusValues.class;
    }
    if (conditionType === "text") {
        return statusValues.text;
    }
};

const statusValue = (type, value) => {
    switch (value) {
        case "active":
            return statusConditions(type, {
                badge: "Aktywny",
                class: "active",
                text: "Odnowienie",
            });
        case "expiring":
            return statusConditions(type, {
                badge: "Wygasający",
                class: "expiring",
                text: "Ważny do",
            });
        case "waiting":
            return statusConditions(type, {
                badge: "Oczekujący",
                class: "waiting",
                text: "Oczekujący na płatność",
            });
        case "inactive":
        case null:
            return statusConditions(type, {
                badge: "Nieaktywny",
                class: "inactive",
                text: "Wygasł",
            });
        default:
            return "";
    }
};

const SubscriptionStatus = ({ status, date }) => {
    return (
        <>
            <div
                className={
                    "dashboard-account-status text-center" +
                    (statusValue("class", status)
                        ? " " + statusValue("class", status)
                        : "")
                }
            >
                <p className="status-title">Status abonamentu</p>
                <div className="status-badge">
                    {statusValue("badge", status)}
                </div>
                {date ? (
                    <div className="status-time">
                        {statusValue("text", status) + ": " + date}
                    </div>
                ) : (
                    ""
                )}
            </div>
            {(!status || status === "inactive") && (
                <>
                    <div className="dashboard-account-redirect text-center">
                        <TextSmall>
                            Aby zarządzać abonamentami wejdź na swoje konto na
                            how2edu.pl
                        </TextSmall>
                        <Button
                            onClickFn={() =>
                                window.open(getStoreUrl(), "_blank")
                            }
                            className="medium"
                        >
                            Dowiedz się więcej
                        </Button>
                    </div>
                </>
            )}
        </>
    );
};

export default SubscriptionStatus;
