import { useEffect, forwardRef } from "react";

const Modal = forwardRef((props, ref) => {
    const { className, onCloseClickFn, fixedHeight, children, isCloseDisabled } = props;

    useEffect(() => {
        document.body.classList.add("modal-open");

        return () => {
            document.body.classList.remove("modal-open");
        };
    });

    return (
        <>
            <div
                className={
                    "app-modal" + (className ? " " + className : "")
                }
                ref={ref}
            >
                {onCloseClickFn && (
                    <button
                        className="app-modal-close"
                        type="button"
                        disabled={isCloseDisabled}
                        onClick={onCloseClickFn}
                    ></button>
                )}

                <div className="app-modal-container">
                    <div className="app-modal-dialog">
                        <div
                            className={
                                "app-modal-content" +
                                (fixedHeight ? " fixed" : "")
                            }
                        >
                            {children}
                        </div>
                    </div>
                </div>
                <div className="app-modal-backdrop"></div>
            </div>
        </>
    );
});

export default Modal;
