import { useState, useEffect, useRef, useCallback, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useQuery } from "react-query";
import { useCookies } from "react-cookie";

import { StoreContext } from "../../context/Store";

import useLibraryData from "../../hooks/useLibraryData";
import useCheckSubscription from "../../hooks/useCheckSubscription";

import pathSlice from "../../functions/pathSlice";
import routeLoadingState from "../../functions/routeLoadingState";

import DashboardContainer from "../../components/containers/dashboard/DashboardContainer";

import LibraryFilters from "../../components/containers/dashboard/library/LibraryFilters";
import LibrarySearch from "../../components/containers/dashboard/library/LibrarySearch";
import LibraryCarousels from "../../components/containers/dashboard/library/LibraryCarousels";
import SubscriptionError from "../../components/containers/dashboard/SubscriptionError";

const Library = ({ routeMatches, routeSetters }) => {
    const { setRouteData, setRouteIsLoading } = routeSetters;

    const history = useHistory();
    const location = useLocation();
    const cookies = useCookies(["idRank"])[0];

    const { libraryStore, librarySave, libraryRemove } = useLibraryData();
    const {
        isSubscriptionActive,
        isSubscriptionFetched,
        isSubscriptionLoading,
    } = useCheckSubscription();

    /* FILTERS */
    const dataFilterActive = useRef(libraryStore("main", "filter") ?? "all");

    /* SEARCH */
    const dataSearch = useRef(
        libraryStore("main", "search") ?? {
            prev: "",
            next: "",
            isEmpty: true,
        }
    );

    /* DATA LOADING */
    const [dataLoading, setDataLoading] = useState(false);

    /* DATA CHANGE */
    const dataChangeType = useRef(null);
    const [dataChange, setDataChange] = useState(false);

    /* DATA RESET */
    const [dataReset, setDataReset] = useState(false);
    const [dataResetExecute, setDataResetExecute] = useState(false);

    /* SEARCH FORCE RESET */
    const forceSearchReset = useRef(false);

    /* SEARCH DISABLED */
    const [searchDisabled, setSearchDisabled] = useState(
        libraryStore("main", "search")?.isEmpty ? false : true
    );

    const endpointAddress = (filter) => {
        switch (filter) {
            case "all":
                if (dataSearch.current.next) {
                    return `/library?search=${dataSearch.current.next}`;
                } else {
                    return `/library`;
                }
            case "audio":
                if (dataSearch.current.next) {
                    return `/library?type=audio&search=${dataSearch.current.next}`;
                } else {
                    return `/library?type=audio`;
                }
            case "multi":
                if (dataSearch.current.next) {
                    return `/library?type=multi&search=${dataSearch.current.next}`;
                } else {
                    return `/library?type=multi`;
                }
            default:
                if (dataSearch.current.next) {
                    return `/library?search=${dataSearch.current.next}`;
                } else {
                    return `/library`;
                }
        }
    };

    const { api } = useContext(StoreContext);

    /* GET LIBRARY DATA */
    const {
        data: libraryData,
        isFetched: libraryIsFetched,
        refetch: libraryRefetch,
        isRefetching: libraryIsRefetching,
        isLoading: libraryIsLoading,
        isFetchedAfterMount: libraryIsFetchedAfterMount,
        remove: libraryQueryRemove,
    } = useQuery(
        "library",
        () => api.get(endpointAddress(dataFilterActive.current)),
        {
            staleTime: Infinity,
            retry: false,
            refetchOnWindowFocus: false,
        }
    );

    /* DATA CHANGE HANDLER */
    useEffect(() => {
        if (dataChange) {
            if (dataChangeType.current === "filter") {
                if (dataSearch.current.isEmpty) {
                    setDataLoading(true);

                    dataChangeType.current = null;

                    libraryRefetch();
                } else {
                    setDataReset(true);
                }
            }

            if (!forceSearchReset.current) {
                if (dataChangeType.current === "search") {
                    if (dataSearch.current.prev !== dataSearch.current.next) {
                        dataSearch.current = {
                            ...dataSearch.current,
                            prev: dataSearch.current.next,
                        };

                        setDataLoading(true);

                        dataChangeType.current = null;

                        libraryRefetch();
                    }
                }
            } else {
                setDataReset(true);
            }

            setDataChange(false);
        }
    }, [dataChange, libraryRefetch]);

    /* DATA RESET */
    useEffect(() => {
        if (dataReset) {
            dataSearch.current = {
                prev: "",
                next: "",
                isEmpty: true,
            };

            /* EXECUTE RESET */
            setDataResetExecute(true);
            setDataReset(false);
        }
    }, [dataReset]);

    /* DATA RESET EXECUTE */
    useEffect(() => {
        if (dataResetExecute) {
            /* RESET ALL DATA TYPES AND RUN REFETCH */
            setDataResetExecute(false);

            dataChangeType.current = null;

            forceSearchReset.current = false;

            setDataLoading(true);

            libraryRefetch();
        }
    }, [dataResetExecute, libraryRefetch]);

    /* DISABLE LOADING AFTER REFETCH */
    useEffect(() => {
        if (!libraryIsRefetching && dataLoading) {
            setDataLoading(false);
        }
    }, [libraryIsRefetching, dataLoading]);

    /* FILTER CHANGE HANDLER */
    const filterChangeHandler = (filter) => {
        dataFilterActive.current = filter;

        librarySave("main", ["filter", filter]);
        libraryRemove("main", "search");

        dataChangeType.current = "filter";

        setDataChange(true);
    };

    const dataOnMountFlag = useRef(false);
    const dataOnMountReady = useRef(false);
    const [dataOnMount, setDataOnMount] = useState([]);

    /* REFETCH AFTER MOUNT */
    useEffect(() => {
        if (!libraryIsLoading && !libraryIsFetchedAfterMount) {
            dataOnMountFlag.current = true;

            libraryRefetch();
        }
    }, [libraryIsLoading, libraryIsFetchedAfterMount, libraryRefetch]);

    useEffect(() => {
        if (!libraryIsRefetching && dataOnMountFlag.current) {
            dataOnMountFlag.current = false;
            dataOnMountReady.current = true;

            setDataOnMount(libraryData.data);
        }
    }, [libraryIsRefetching, libraryData?.data]);

    /* REMOVE FETCHED DATA */
    useEffect(() => {
        return () => {
            libraryQueryRemove();
        };
    }, [libraryQueryRemove]);

    /* ROUTE LOADING */
    const routeIsLoading = routeLoadingState({
        fetched: [libraryIsFetched, isSubscriptionFetched],
        loading: [libraryIsLoading, isSubscriptionLoading],
    });

    useEffect(() => {
        const routeData = {
            title: "BIBLIOTEKA",
            action: isSubscriptionActive
                ? {
                      type: "button",
                      props: {
                          text: "ULUBIONE",
                          icon: "heart",
                          onClick: () =>
                              history.push(
                                  `${pathSlice(location.pathname)}/favorites`
                              ),
                      },
                  }
                : undefined,
        };

        if (routeMatches) {
            setRouteIsLoading(routeIsLoading);
            setRouteData(routeData);
        }
    }, [
        routeIsLoading,
        routeMatches,
        setRouteIsLoading,
        setRouteData,
        isSubscriptionActive,
    ]);

    /* SEARCH DATA FILL */
    const searchFill = useCallback((value) => {
        dataSearch.current = {
            ...dataSearch.current,
            next: value,
            isEmpty: value.length > 0 ? false : true,
        };

        if (dataSearch.current.prev === value) {
            setSearchDisabled(true);
        } else {
            setSearchDisabled(false);
        }
    }, []);

    /* SEARCH HANDLER */
    const searchHandler = useCallback(() => {
        dataChangeType.current = "search";

        librarySave("main", ["search", dataSearch.current.next]);

        setDataChange(true);
    }, [librarySave]);

    /* SEARCH FORCE RESET */
    const searchForceResetHandler = useCallback(() => {
        forceSearchReset.current = true;

        libraryRemove("main", "search");

        setDataChange(true);
    }, [libraryRemove]);

    /* RESET DATA ON MOUNT */
    const resetDataOnMountHandler = useCallback(() => {
        setDataOnMount([]);
    }, []);

    /* INIT LIBRARY SLIDERS TO PREVENT ANIMATION BUG */
    const [libraryInit, setLibraryInit] = useState(false);
    const libraryInitTimeout = useRef(null);

    useEffect(() => {
        if (!routeIsLoading) {
            clearTimeout(libraryInitTimeout.current);

            libraryInitTimeout.current = setTimeout(() => {
                setLibraryInit(true);
            }, 0);
        }
    }, [routeIsLoading]);

    return (
        <>
            <DashboardContainer className="library">
                {!routeIsLoading &&
                    libraryInit &&
                    (isSubscriptionActive ? (
                        <>
                            <LibraryFilters
                                filters={[
                                    {
                                        name: "WSZYSTKO",
                                        filter: "all",
                                    },
                                    {
                                        name: "SMARTBOOKI",
                                        filter: "multi",
                                    },
                                    {
                                        name: "AUDIOBOOKI",
                                        filter: "audio",
                                    },
                                ]}
                                filtersRefetching={libraryIsRefetching}
                                filterCurrent={dataFilterActive.current}
                                onClickFn={(filter) =>
                                    filterChangeHandler(filter)
                                }
                            />
                            <LibrarySearch
                                searchDisabled={searchDisabled}
                                searchSaved={
                                    libraryStore("main", "search")?.next
                                }
                                resetSearch={
                                    (dataResetExecute &&
                                        dataChangeType.current) === "filter" ||
                                    (dataResetExecute &&
                                        forceSearchReset.current)
                                }
                                onChangeFn={searchFill}
                                onSearchFn={searchHandler}
                            />
                            <LibraryCarousels
                                data={libraryData.data}
                                dataOnMount={dataOnMount}
                                dataOnMountReset={resetDataOnMountHandler}
                                searchForceReset={searchForceResetHandler}
                                isLoading={dataLoading}
                            />
                        </>
                    ) : (
                        <SubscriptionError rank={parseInt(cookies.idRank)} />
                    ))}
            </DashboardContainer>
        </>
    );
};

export default Library;
