const ReaderUser = ({ avatar, type }) => {
    return (
        <span
            className={
                "ReaderUser" + (type === "slider" ? " _type--slider" : "")
            }
        >
            <img src={avatar} alt="" />
        </span>
    );
};

export default ReaderUser;
