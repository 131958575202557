import { useContext, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "react-query";

import { StoreContext } from "../../../context/Store";

import routeLoadingState from "../../../functions/routeLoadingState";

import DashboardContainer from "../../../components/containers/dashboard/DashboardContainer";
import SchoolPanels from "../../../components/containers/dashboard/schools/SchoolPanels";

import modalDoodleImage_1 from "../../../assets/images/dashboard/modal-doodles/questionmark.svg";
import modalDoodleImage_2 from "../../../assets/images/dashboard/modal-doodles/sad.svg";
import modalDoodleImage_3 from "../../../assets/images/dashboard/modal-doodles/happy_3.svg";

const ChildrenSchool = ({ routeMatches, routeSetters, modalSetters }) => {
    const { setRouteData, setRouteIsLoading } = routeSetters;
    const {
        setModalData,
        setModalActive,
        setModalApiResponse,
        setModalApiLoading,
    } = modalSetters;

    const params = useParams();

    const { api } = useContext(StoreContext);

    /* PARAMS ID REF */
    const idChildRef = useRef(params.idChild);

    /* GET CHILD DATA */
    const {
        data: childData,
        isFetched: childIsFetched,
        isLoading: childIsLoading,
        isRefetching: childIsRefetching,
        refetch: childRefetch,
    } = useQuery(
        ["childData", idChildRef.current],
        () => api.post(`/account/child_data`, { idChild: idChildRef.current }),
        {
            staleTime: Infinity,
            retry: false,
            refetchOnWindowFocus: false,
        }
    );

    /* ADD CHILD SCHOOL */
    const { mutate: addChildSchoolMutate } = useMutation(
        (data) =>
            api.post(`/account/child/add_school`, {
                idChild: data.idChild,
                idSchool: data.idSchool,
            }),
        {
            onMutate: () => setModalApiLoading(true),
            onSuccess: () => {
                childRefetch();

                setModalApiResponse(true);
            },
            onError: () => alert("*Placeholder* Error with sending data"),
        }
    );

    /* REMOVE CHILD SCHOOL */
    const { mutate: removeChildSchoolMutate } = useMutation(
        (data) => api.post(`/account/child/remove_school`, data),
        {
            onMutate: () => setModalApiLoading(true),
            onSuccess: () => {
                childRefetch();

                setModalApiResponse(true);
            },
            onError: () => alert("*Placeholder* Error with sending data"),
        }
    );

    /* ROUTE LOADING */
    const routeIsLoading = routeLoadingState({
        fetched: [childIsFetched],
        loading: [childIsLoading],
    });

    /* ROUTE SETTERS */
    useEffect(() => {
        const routeData = {
            title: routeIsLoading
                ? ""
                : childData.data.child.name + " (SZKOŁA)",
        };

        if (routeMatches) {
            setRouteIsLoading(routeIsLoading);
            setRouteData(routeData);
        }
    }, [
        routeIsLoading,
        routeMatches,
        setRouteIsLoading,
        setRouteData,
        childData,
    ]);

    /* MODAL SCHOOL DATA */
    const modalSchoolAddData = useRef({});
    const modalSchoolRemoveData = useRef({});

    useEffect(() => {
        if (!routeIsLoading) {
            modalSchoolAddData.current = {
                select: true,
                close: true,
                data: [
                    {
                        query: {
                            url: "/school_list?search=",
                            queryKey: "modalSchoolList",
                            selectedData: childData.data.child.school
                                ? [
                                      {
                                          idSchool:
                                              childData.data.child.school
                                                  .idSchool,
                                          name: childData.data.child.school
                                              .name,
                                      },
                                  ]
                                : [],
                            dataKey: {
                                id: "idSchool",
                                name: "name",
                            },
                            backendSearch: true,
                        },
                        actionDispatch: {
                            dispatch: addChildSchoolMutate,
                            props: {
                                idChild: childData.data.child.idUser,
                            },
                        },
                    },
                    {
                        title: {
                            obj: (
                                <p>
                                    Dziecko {childData.data.child.name}
                                    <br />
                                    zostało zapisane do szkoły
                                    <br />
                                    [school_inject]
                                </p>
                            ),
                            injectKey: "[school_inject]",
                            isCenter: true,
                        },
                        img: {
                            obj: modalDoodleImage_3,
                            size: "245px",
                        },
                        action: {
                            obj: {
                                text: "Przejdź dalej",
                            },
                        },
                    },
                ],
            };

            modalSchoolRemoveData.current = {
                data: [
                    {
                        title: {
                            obj: (
                                <p>
                                    Czy na pewno chcesz wypisać dziecko{" "}
                                    {childData.data.child.name} ze szkoły{" "}
                                    {childData.data.child.school?.name}?
                                </p>
                            ),
                        },
                        img: {
                            obj: modalDoodleImage_1,
                            size: "161px",
                        },
                        action: {
                            obj: [
                                {
                                    text: "Tak, chcę wypisać",
                                    important: true,
                                    onClick: () =>
                                        removeChildSchoolMutate({
                                            idChild:
                                                childData.data.child.idUser,
                                        }),
                                },
                                {
                                    text: "Nie, nie chcę wypisać",
                                    onClick: () => setModalActive(false),
                                },
                            ],
                            key: "childrenSchoolRemove",
                        },
                    },
                    {
                        title: {
                            obj: (
                                <p>
                                    Dziecko {childData.data.child.name} zostało
                                    wypisane ze szkoły{" "}
                                    {childData.data.child.school?.name}
                                </p>
                            ),
                            isCenter: true,
                        },
                        img: {
                            obj: modalDoodleImage_2,
                            size: "184px",
                        },
                        action: {
                            obj: {
                                text: "Przejdź dalej",
                            },
                        },
                    },
                ],
            };
        }
    }, [
        routeIsLoading,
        setModalActive,
        setModalApiLoading,
        setModalApiResponse,
        childData,
        addChildSchoolMutate,
        removeChildSchoolMutate,
    ]);

    return (
        <>
            <DashboardContainer className="schools">
                {" "}
                {!routeIsLoading && (
                    <>
                        <SchoolPanels
                            text={{
                                action: "Wypisz ze szkoły",
                                alert: (
                                    <p>
                                        Osiągnąłeś maksymalną liczbę szkół.
                                        <br />
                                        Nie możesz dodać kolejnej.
                                    </p>
                                ),
                                button: "Dodaj szkołę",
                            }}
                            max={1}
                            data={
                                childData.data.child.school
                                    ? [
                                          {
                                              idSchool:
                                                  childData.data.child.school
                                                      .idSchool,
                                              name: childData.data.child.school
                                                  .name,
                                          },
                                      ]
                                    : []
                            }
                            keyName="childrenSchool"
                            isRefetching={childIsRefetching}
                            onClick={{
                                panel: () =>
                                    setModalData(modalSchoolRemoveData.current),
                                button: () =>
                                    setModalData(modalSchoolAddData.current),
                            }}
                        />
                    </>
                )}
            </DashboardContainer>
        </>
    );
};

export default ChildrenSchool;
