import { useState, useEffect, useRef, useReducer } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";

import useActionLoading from "../../../hooks/useActionLoading";

import ButtonBackwards from "../../buttons/ButtonBackwards";

import RouteTitle from "./RouteTitle";
import RouteAction from "./RouteAction";
import pathPrev from "../../../functions/pathPrev";

const routeDataInitial = {
    title: {
        props: {
            prev: "",
            next: "",
        },
        exist: {
            prev: false,
            next: false,
        },
    },
    action: {
        props: {
            prev: undefined,
            next: undefined,
        },
        exist: {
            prev: false,
            next: false,
        },
    },
};

const routeDataReducer = (state, props) => {
    switch (props.type) {
        case "props":
            return {
                title: {
                    ...state.title,
                    props: {
                        prev: state.title.props.next,
                        next: props.data?.title ? props.data.title : "",
                    },
                },
                action: {
                    ...state.action,
                    props: {
                        prev: state.action.props.next,
                        next: props.data?.action
                            ? props.data.action
                            : undefined,
                    },
                },
            };
        case "exist":
            return {
                title: {
                    ...state.title,
                    exist: {
                        prev: state.title.props.prev ? true : false,
                        next: state.title.props.next ? true : false,
                    },
                },
                action: {
                    ...state.action,
                    exist: {
                        prev: state.action.props.prev ? true : false,
                        next: state.action.props.next ? true : false,
                    },
                },
            };
        default:
            return state;
    }
};

const RouteNavigation = ({
    routeData,
    routeTimeout,
    routeIsLoading,
    routeIsBackward,
}) => {
    const { isActionLoading } = useActionLoading();

    const history = useHistory();
    const location = useLocation();

    const cookieObject = useCookies(["userPath", "userPathClone"]);
    const cookies = cookieObject[0];
    const removeCookie = cookieObject[2];

    /* ROUTE DATA */
    const [routeDataState, routeDataDispatch] = useReducer(
        routeDataReducer,
        routeDataInitial
    );
    const routeDataIsDispatched = useRef(false);

    /* ROUTE PAGE CHANGE */
    const [routePageEnter, setRoutePageEnter] = useState(false);
    const [routePageIsLoading, setRoutePageIsLoading] = useState(false);

    /* ROUTE LOADING FLAGS */
    const routeLoading = useRef(false);
    const routeLoadingFlag = useRef(false);

    /* ROUTE NAVIGATION STATES */
    const [routeNavigationIsShown, setRouteNavigationIsShown] = useState(false);
    const [routeTitleIsAnimating, setRouteTitleIsAnimating] = useState(false);

    /* ROUTE DATA CACHE */
    const routeDataCache = useRef({
        title: {
            prev: "",
            next: "",
        },
        action: {
            prev: undefined,
            next: undefined,
        },
    });

    /* ROUTE ACTIONS STATES */
    const [routeActionIsShown, setRouteActionIsShown] = useState(null);
    const [routeActionIsAnimating, setRouteActionIsAnimating] = useState(false);

    /* ROUTE DROPDOWN STATES */
    const [routeDropdownIsActive, setRouteDropdownIsActive] = useState(false);

    /* ROUTE TIMEOUTS */
    const routePageEnterTimeout = useRef(null);
    const routeAnimationTimeout = useRef(null);
    const routeActionAnimationTimeout = useRef(false);

    /* SET LOADING REF TO PREVENT ANIMATION WHEN LOADING IS ACTIVE */
    useEffect(() => {
        if (routeIsLoading) {
            routeLoading.current = true;
            routeLoadingFlag.current = true; /* SET ROUTE LOADING FLAG TO PREVENT ANIMATION */
        } else {
            routeLoading.current = false;
        }
    }, [routeIsLoading]);

    /* SET NEW DATA ON PAGE ENTER */
    useEffect(() => {
        setRoutePageEnter(true); /* SET PAGE ENTER */
        setRouteDropdownIsActive(false);

        if (!routeLoading.current) {
            if (!routeDataIsDispatched.current) {
                routeDataDispatch({
                    type: "props",
                    data: routeData,
                });

                routeDataDispatch({
                    type: "exist",
                });
            }

            routeDataIsDispatched.current = true; /* FLAG IF DATA IS UPDATED */
        }
    }, [routeData]);

    /* SET PAGE ENTER */
    useEffect(() => {
        /* ENABLE PAGE LOADING FLAG TO RERENDER TITLE LATER */
        if (routeIsLoading) {
            setRoutePageIsLoading(true);
        }

        clearTimeout(routePageEnterTimeout.current);

        if (!routeIsLoading && routePageEnter) {
            routePageEnterTimeout.current = setTimeout(() => {
                setRoutePageEnter(false);
            }, 500 + (routeLoadingFlag.current ? (routeTimeout ? routeTimeout : 0) : 0));
        }
    }, [routeIsLoading, routePageEnter, routeTimeout]);

    /* CACHE DATA TO PREVENT UNNECESSARY RERENDERING */
    useEffect(() => {
        /* CACHE DATA */
        routeDataCache.current = {
            title: {
                prev: routeDataState.title.props.prev,
                next: routeDataState.title.props.next,
            },
            action: {
                prev: routeDataState.action.props.prev,
                next: routeDataState.action.props.next,
            },
        };

        /* COPY PREVIOUS DATA IF NEXT TITLE DOESN'T EXIST TO PREVENT COMPONENT HIDING */
        if (!routeDataState.title.exist.next) {
            routeDataCache.current.title.next =
                routeDataCache.current.title.prev;
        }

        if (!routeDataState.action.exist.next) {
            routeDataCache.current.action.next =
                routeDataCache.current.action.prev;
        }
    }, [routeDataState]);

    /* ROUTE TITLE/ACTION SHOW */
    useEffect(() => {
        if (routeDataState.title.exist.next) {
            setRouteNavigationIsShown(true);

            if (routeDataState.action.exist.next) {
                setRouteActionIsShown(true);

                if (
                    routeDataState.action.props.prev &&
                    routeDataState.action.props.prev.type !==
                        routeDataState.action.props.next.type
                ) {
                    setRouteActionIsAnimating(true);
                }
            } else {
                setRouteActionIsShown(false);
            }
        } else {
            setRouteNavigationIsShown(false);
        }
    }, [routeDataState]);

    /* ROUTE ACTION ANIMATION HANDLER */
    useEffect(() => {
        if (routeActionIsAnimating) {
            clearTimeout(routeActionAnimationTimeout.current);

            routeActionAnimationTimeout.current = setTimeout(() => {
                setRouteActionIsAnimating(false);
            }, 500);
        }
    }, [routeActionIsAnimating]);

    /* ROUTE TITLE ANIMATION HANDLER */
    useEffect(() => {
        /* DISABLE PAGE LOADING FLAG TO RERENDER TITLE */
        setRoutePageIsLoading(false);

        if (!routeLoadingFlag.current) {
            if (
                routeDataState.title.exist.prev &&
                routeDataState.title.exist.next &&
                routeDataState.title.props.prev !==
                    routeDataState.title.props.next
            ) {
                setRouteTitleIsAnimating(true);

                clearTimeout(routeAnimationTimeout.current);

                routeAnimationTimeout.current = setTimeout(() => {
                    setRouteTitleIsAnimating(false);
                }, 500); /* TIMEOUT VALUE MUST BE THE SAME AS FINISHING TRANSITION FROM ROUTE DELAY IN CSS */
            }
        }

        routeDataIsDispatched.current = false;
        routeLoadingFlag.current = false;
    }, [routeDataState]);

    /* BACKWARD CLICK HANDLER */
    const backwardClickDisable = useRef(false);
    const backwardClickTimeout = useRef(null);

    const backwardClickHandler = () => {
        if (!backwardClickDisable.current) {
            const userPath = cookies.userPathClone ?? cookies.userPath;

            backwardClickDisable.current = true;

            const specialRedirect = () => {
                if (userPath.last && userPath.current) {
                    if (
                        userPath.last.indexOf("/mybookshelf") !== -1 &&
                        userPath.current.indexOf("/library/favorites") !== -1
                    ) {
                        history.push(
                            `${pathPrev(location.pathname, "last")}/mybookshelf`
                        );
                    } else if (
                        userPath.last.indexOf("/library/category") !== -1 &&
                        userPath.current.indexOf("/library/favorites") !== -1
                    ) {
                        history.push(
                            `${pathPrev(
                                location.pathname,
                                "last"
                            )}/library/category-${userPath.idLibrary}`
                        );
                    } else if (
                        userPath.current.indexOf("/library/category-1") !== -1
                    ) {
                        history.push(`${pathPrev(location.pathname, "last")}`);
                    } else {
                        history.push(`${pathPrev(location.pathname, 1)}`);
                    }
                } else {
                    history.push(`${pathPrev(location.pathname, 1)}`);
                }

                removeCookie("userPathClone", { path: "/" });
            };

            if (routeDropdownIsActive) {
                setRouteDropdownIsActive(false);

                clearTimeout(backwardClickTimeout.current);

                backwardClickTimeout.current = setTimeout(() => {
                    specialRedirect();

                    backwardClickDisable.current = false;
                }, 300);
            } else {
                specialRedirect();

                backwardClickDisable.current = false;
            }
        }
    };

    return (
        <>
            {routePageEnter && <div className="RouteMask"></div>}
            <div
                className={
                    "RouteNavigation" +
                    (routeNavigationIsShown ? " _shown" : "") +
                    (routePageIsLoading ? " _loading" : "") +
                    (!routePageEnter ? " _entered" : "")
                }
            >
                <ButtonBackwards
                    id="NavigationDefault"
                    className="_color--inverse"
                    isDisabled={
                        routePageEnter ||
                        !routeNavigationIsShown ||
                        isActionLoading
                    }
                    onClickFn={() => backwardClickHandler()}
                />
                <RouteTitle
                    title={{
                        prev: routeDataCache.current.title.prev,
                        next: routeDataCache.current.title.next,
                    }}
                    isBackward={routeIsBackward}
                    isAnimating={routeTitleIsAnimating}
                />
                <RouteAction
                    action={{
                        prev: routeDataCache.current.action.prev,
                        next: routeDataCache.current.action.next,
                    }}
                    dropdown={{
                        state: routeDropdownIsActive,
                        setState: setRouteDropdownIsActive,
                    }}
                    isDisabled={isActionLoading}
                    isShown={routeActionIsShown}
                    isAnimating={routeActionIsAnimating}
                />
            </div>
            <div
                className={
                    "RouteNavigation__Backdrop" +
                    (routeDropdownIsActive ? " _active" : "")
                }
            ></div>
        </>
    );
};

export default RouteNavigation;
