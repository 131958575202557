import { useState, useRef, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useQueryClient } from "react-query";

import { HooksContext } from "../../context/HooksStore";
import useNotifications from "../../hooks/useNotifications";
import useCheckSubscription from "../../hooks/useCheckSubscription";
import useChildList from "../../hooks/useChildList";

import { getCookieTime } from "../../App";
import routeLoadingState from "../../functions/routeLoadingState";
import { isMobileCheck } from "../../components/reader/panels/ReaderPanel";

import Button from "../../components/buttons/Button";

import modalDoodleImage_1 from "../../assets/images/dashboard/how2doodle-subscription.svg";
import modalDoodleImage_2 from "../../assets/images/dashboard/modal-doodles/sad_2.svg";

const getNotificationDay = (days) => {
    if (days > 1) {
        return "za " + days + " dni";
    } else if (days === 1) {
        return "jutro";
    } else if (days === 0) {
        return "dzisiaj";
    } else if (days === -1) {
        return "wczoraj";
    } else {
        return Math.abs(days) + " dni temu";
    }
};

const Notifications = ({ routeMatches, routeSetters, modalSetters }) => {
    const { setRouteData, setRouteIsLoading } = routeSetters;
    const { setModalData } = modalSetters;

    const { readNotification, isNotificationReadSuccess } =
        useContext(HooksContext);

    const {
        notificationsData,
        isNotificationsFetched,
        isNotificationsLoading,
        removeNotifications,
    } = useNotifications("notifications");

    const {
        isSubscriptionActive,
        isSubscriptionFetched,
        isSubscriptionLoading,
    } = useCheckSubscription();

    /* NOFITICATIONS STALE FLAG */
    const isNotificationsStale = useRef(false);

    const history = useHistory();
    const cookies = useCookies(["idRank"])[0];
    const setCookie = useCookies(["chapterRedirect"])[1];
    const queryClient = useQueryClient();

    const { childList, isChildListFetched, isChildListLoading } = useChildList(
        parseInt(cookies.idRank) !== 2
    );

    /* INVALIDATE QUERIES ON NOTIFICATION READ SUCCESS */
    useEffect(() => {
        if (isNotificationReadSuccess) {
            queryClient.invalidateQueries("notifications-counter");
        }
    }, [isNotificationReadSuccess, queryClient]);

    /* ROUTE LOADING */
    const isRouteLoading = routeLoadingState({
        fetched: [
            isNotificationsFetched,
            isSubscriptionFetched,
            isChildListFetched,
        ],
        loading: [
            isNotificationsLoading,
            isSubscriptionLoading,
            isChildListLoading,
        ],
    });

    /* MODAL SUBSCRIPTION DATA */
    const modalSubscriptionData = useRef({});
    const modalChildData = useRef({});

    useEffect(() => {
        if (!isRouteLoading) {
            modalSubscriptionData.current = {
                data: [
                    {
                        title: {
                            obj:
                                parseInt(cookies.idRank) === 2 ? (
                                    <p>
                                        Abonament Twojego dziecka
                                        <br />
                                        jest nieaktywny. Wybierz abonament,
                                        <br />
                                        aby w pełni cieszyć się światem How2
                                        Edu.
                                    </p>
                                ) : (
                                    <p>
                                        Twój abonament jest nieaktywny.
                                        Poinformuj o tym rodzica, abyś w pełni
                                        mógł cieszyć się światem How2 Edu.
                                    </p>
                                ),
                        },
                        img: {
                            obj: modalDoodleImage_1,
                            size: "262px",
                        },
                        action: {
                            obj: {
                                text: "Wróć",
                            },
                        },
                    },
                ],
            };

            modalChildData.current = {
                data: [
                    {
                        title: {
                            obj: (
                                <p>
                                    Wystąpił błąd.
                                    <br />
                                    Dziecko nie zostało
                                    <br />
                                    odnalezione.
                                </p>
                            ),
                            isCenter: true,
                        },
                        img: {
                            obj: modalDoodleImage_2,
                            size: "185px",
                        },
                        action: {
                            obj: {
                                text: "Zamknij",
                            },
                        },
                    },
                ],
            };
        }
    }, [isRouteLoading, cookies.idRank]);

    /* ROUTE SETTERS */
    useEffect(() => {
        const routeData = {
            title: "POWIADOMIENIA",
        };

        if (routeMatches) {
            setRouteIsLoading(isRouteLoading);
            setRouteData(routeData);
        }
    }, [routeMatches, setRouteData, setRouteIsLoading, isRouteLoading]);

    /* INVALIDATE NOTIFICATIONS COUNTER ON PAGE ENTER */
    useEffect(() => {
        if (!isNotificationsStale.current) {
            isNotificationsStale.current = true;

            queryClient.invalidateQueries("notifications-counter");
        }
    }, [queryClient]);

    /* REMOVE NOTIFICATIONS ON EXIT */
    useEffect(() => {
        return () => {
            removeNotifications();
        };
    }, [removeNotifications]);

    /* HANDLE NOTIFICATION CLICK */
    const handleNotificationClick = (payload, id) => {
        if (isSubscriptionActive) {
            if (
                payload.redirect_type === "gibbs" ||
                payload.redirect_type === "stage"
            ) {
                readNotification(id);

                setCookie(
                    "chapterRedirect",
                    {
                        idMultibook: payload.idMultibook,
                        idChapter: payload.idChapter,
                    },
                    {
                        path: "/",
                        expires: getCookieTime(),
                    }
                );

                history.push(`/multibook-${payload.idMultibook}`);
            } else if (payload.redirect_type === "learning_progress") {
                let isChildAvailable = false;

                for (const child of childList) {
                    if (child.idUser === payload.idChild) {
                        if (
                            child.subscription.status !== "inactive" &&
                            child.subscription.status !== "waiting"
                        ) {
                            readNotification(id);

                            history.push(
                                `children/${payload.idChild}/learning-progress/details-${payload.idMultibook}`
                            );
                        } else {
                            history.push("subscriptions");
                        }

                        isChildAvailable = true;
                    }
                }

                if (!isChildAvailable) {
                    readNotification(id);

                    setModalData(modalChildData.current);
                }
            }
        } else {
            setModalData(modalSubscriptionData.current);
        }
    };

    return (
        <>
            <div className="Notifications">
                {!isRouteLoading && (
                    <>
                        {notificationsData?.length > 0 ? (
                            notificationsData?.map((object) => {
                                return (
                                    <Notification
                                        key={
                                            "Notification_" +
                                            object.idNotification
                                        }
                                        id={object.idNotification}
                                        icon={object.type}
                                        text={object.content}
                                        days={object.days}
                                        payload={object.payload}
                                        isRead={object.readed}
                                        onClick={() =>
                                            handleNotificationClick(
                                                object.payload,
                                                object.idNotification
                                            )
                                        }
                                        onDrag={() =>
                                            readNotification(
                                                object.idNotification
                                            )
                                        }
                                    />
                                );
                            })
                        ) : (
                            <div className="NotificationsEmpty">
                                <div className="NotificationsEmpty__Message">
                                    Brak powiadomień do wyświetlenia.
                                </div>
                                <Button
                                    classNameWrapper="NotificationsEmpty__Button"
                                    className="medium"
                                    onClickFn={() => history.goBack()}
                                >
                                    Wróć
                                </Button>
                            </div>
                        )}
                    </>
                )}
            </div>
        </>
    );
};

const Notification = ({
    id,
    icon,
    text,
    days,
    payload,
    isRead,
    onClick,
    onDrag,
}) => {
    const isMobile = isMobileCheck();

    /* NOTIFICATION READ INSTANCE STATE */
    const [isNotificationRead, setIsNotificationRead] = useState(false);

    /* NOTIFICATION ELEMENTS */
    const notificationDrag = useRef(null);
    const notificationBody = useRef(null);

    /* DRAG POSITION */
    const dragPositionStart = useRef(null);
    const [dragPosition, setDragPosition] = useState(0);

    /* DRAG FLAGS */
    const isDragging = useRef(false);
    const isDropping = useRef(false);

    /* CLICK FLAG */
    const isClickDisabled = useRef(false);

    /* HANDLE DRAG START */
    const handleDragStart = (event) => {
        if (isDragging.current || isDropping.current) return;

        dragPositionStart.current =
            event.type === "mousedown" ? event.pageX : event.touches[0].pageX;

        isDragging.current = true;
    };

    /* HANDLE DRAG MOVE */
    const handleDrag = (event) => {
        if (!isDragging.current || isDropping.current) return;

        const dragPositionCurrent =
            event.type === "mousemove" ? event.pageX : event.touches[0].pageX;

        const dragPositionTarget =
            dragPositionCurrent - dragPositionStart.current;

        if (dragPositionTarget <= -5) {
            isClickDisabled.current = true;
        }

        setDragPosition(
            dragPositionTarget < 0
                ? dragPositionTarget >= -100
                    ? dragPositionTarget
                    : -100
                : 0
        );
    };

    /* HANDLE DRAG END */
    const handleDragEnd = () => {
        if (
            !isDragging.current ||
            isDropping.current ||
            !isClickDisabled.current
        )
            return;

        isDragging.current = false;
        isDropping.current = true;

        if (dragPosition <= -90) {
            onDrag();

            setIsNotificationRead(true);
        }

        notificationBody.current.style.transition =
            "background .3s ease, transform .3s ease";

        setDragPosition(0);

        setTimeout(() => {
            notificationBody.current.style.transition = "";

            isClickDisabled.current = false;

            isDropping.current = false;
        }, 300);
    };

    /* HANDLE CLICK */
    const handleClick = () => {
        if (isClickDisabled.current) return;

        if (payload.redirect_type === "learning_progress") {
            setIsNotificationRead(true);
        }

        onClick();
    };

    return (
        <button
            ref={notificationDrag}
            className={
                "Notification" +
                (isRead || isNotificationRead ? " _is--read" : "")
            }
            type="button"
            disabled={isNotificationRead}
            onMouseDown={
                !isMobile ? (event) => handleDragStart(event) : undefined
            }
            onMouseMove={!isMobile ? (event) => handleDrag(event) : undefined}
            onMouseUp={!isMobile ? handleDragEnd : undefined}
            onTouchStart={
                isMobile ? (event) => handleDragStart(event, id) : undefined
            }
            onTouchMove={isMobile ? handleDrag : undefined}
            onTouchEnd={isMobile ? handleDragEnd : undefined}
            onClick={handleClick}
        >
            <span
                className="Notification__Body"
                style={{ transform: "translateX(" + dragPosition + "px)" }}
                ref={notificationBody}
            >
                <span className="Notification__Content">
                    <span className="Notification__Icon">
                        <img src={icon} alt="" />
                    </span>
                    <span className="Notification__Text">{text}</span>
                </span>
                <div className="Notification__Meta">
                    {getNotificationDay(days)}
                </div>
            </span>
            <span className="Notification__Action">
                oznacz jako przeczytane
            </span>
        </button>
    );
};

export default Notifications;
