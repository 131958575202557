import { useState, useRef, useEffect, useCallback, useContext } from "react";
import { CSSTransition } from "react-transition-group";

import { ExamContext } from "../context/ExamStore";
import { ScoreContext } from "../context/ScoreStore";

import ReaderTopbar from "./ReaderTopbar";
import ReaderDashboard from "./ReaderDashboard";
import ReaderChapters from "../modals/ReaderChapters";
import useChapter from "../hooks/useChapter";
import getChapter from "../functions/getChapter";

/* eslint-disable */
export const isMobileCheck = () => {
    let isMobile = false;

    (function (agent) {
        if (
            /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
                agent
            ) ||
            /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
                agent.substr(0, 4)
            )
        ) {
            isMobile = true;
        }
    })(navigator.userAgent || navigator.vendor || window.opera);

    return isMobile;
};
/* eslint-enable */

const getScreenVerticalPosition = (event) => {
    const type = event.type;

    if (type === "touchstart" || type === "touchmove") {
        return event.touches[0].screenY;
    } else if (type === "mousedown" || type === "mousemove") {
        return event.clientY;
    } else {
        return undefined;
    }
};

const ReaderPanel = ({
    title,
    chapters,
    pages,
    shutdown,
    isDisabled,
    isGallup,
    isGibs,
    isFinished,
}) => {
    const { data: chaptersData } = chapters;
    const { page, setPage } = pages;
    const { isBookShutdown } = shutdown;

    const { isExamLoading, isGallupResultLoading, isGibsResultLoading } =
        useContext(ExamContext);
    const { isExamScoreLoading, isExamScoreInit } = useContext(ScoreContext);

    /* GET CHAPTER DATA */
    const { isChapterLoading } = useChapter(
        getChapter(chaptersData, page, true)
    );

    const isMobile = isMobileCheck();

    /* PANEL DEFAULTS */
    const panelRef = useRef(null);
    const [isPanelOpen, setIsPanelOpen] = useState(false);

    const isPanelDisabled = useRef(false);
    const isPanelPending = useRef(false);
    const panelTransition = 300;

    /* POINTER HANDLER DEFAULTS */
    const isPointerActive = useRef(false);
    const selectedTarget = useRef(null);
    const selectedLastTarget = useRef(null);
    const positionVerticalInitial = useRef(null);

    /* MENU BUTTONS */
    const menuButtonsRef = useRef([]);

    /* POINTER DOWN HANDLER */
    const handlePointerDown = useCallback(
        (event) => {
            if (
                isDisabled ||
                isExamLoading ||
                isExamScoreLoading ||
                isExamScoreInit ||
                isGallupResultLoading ||
                isGibsResultLoading ||
                isBookShutdown
            ) {
                isPointerActive.current = false;
            } else {
                selectedTarget.current = event.target;

                positionVerticalInitial.current =
                    getScreenVerticalPosition(event);

                isPointerActive.current = true;
            }
        },
        [
            isDisabled,
            isExamLoading,
            isExamScoreLoading,
            isExamScoreInit,
            isGallupResultLoading,
            isGibsResultLoading,
            isBookShutdown,
        ]
    );

    const handlePointerMove = useCallback((event) => {
        if (isPointerActive.current) {
            if (isPanelDisabled.current) return;

            const positionVerticalCurrent =
                getScreenVerticalPosition(event) -
                positionVerticalInitial.current;

            if (
                positionVerticalCurrent >= 15 ||
                positionVerticalCurrent <= -15
            ) {
                isPanelDisabled.current = true;
            }
        }
    }, []);

    /* POINTER UP HANDLER */
    const handlePointerUp = useCallback(() => {
        if (isPointerActive.current) {
            if (!isPanelDisabled.current) {
                if (
                    !selectedTarget.current.closest('[data-panel="false"]') &&
                    !isPanelPending.current &&
                    !selectedLastTarget.current?.classList.contains(
                        "ReaderNote__Area"
                    )
                ) {
                    isPanelPending.current = true;

                    if (isPanelOpen) {
                        setIsPanelOpen(false);
                    } else {
                        setIsPanelOpen(true);
                    }
                }
            } else {
                isPanelDisabled.current = false;
            }

            selectedLastTarget.current = selectedTarget.current;

            isPointerActive.current = false;
        }
    }, [isPanelOpen]);

    /* RESET PANEL TIMEOUT FLAG */
    useEffect(() => {
        if (isPanelPending.current) {
            setTimeout(() => {
                isPanelPending.current = false;
            }, panelTransition);
        }
    }, [isPanelOpen]);

    /* ADD CLASS TO BODY */
    useEffect(() => {
        if (isPanelOpen) {
            document.body.classList.add("ReaderPanel--Open");
        } else {
            document.body.classList.remove("ReaderPanel--Open");
        }
    }, [isPanelOpen]);

    /* ADD CLICK LISTENER TO BODY */
    useEffect(() => {
        document.body.addEventListener(
            !isMobile ? "mousedown" : "touchstart",
            handlePointerDown
        );
        document.body.addEventListener(
            !isMobile ? "mousemove" : "touchmove",
            handlePointerMove
        );
        document.body.addEventListener(
            !isMobile ? "mouseup" : "touchend",
            handlePointerUp
        );

        return () => {
            document.body.removeEventListener(
                !isMobile ? "mousedown" : "touchstart",
                handlePointerDown
            );
            document.body.removeEventListener(
                !isMobile ? "mousemove" : "touchmove",
                handlePointerMove
            );
            document.body.removeEventListener(
                !isMobile ? "mouseup" : "touchend",
                handlePointerUp
            );
        };
    }, [isMobile, handlePointerDown, handlePointerMove, handlePointerUp]);

    /* DASHBOARD MAIN ACTIVE STATE */
    const [isDashboardMain, setIsDashboardMain] = useState(false);
    const [isDashboardChanging, setIsDashboardChanging] = useState(false);

    /* DISABLE PANEL EXIT WHEN DASHBOARD IS CHANGING */
    useEffect(() => {
        if (isDashboardChanging) {
            isPanelPending.current = true;
        } else if (isPanelPending.current) {
            isPanelPending.current = false;
        }
    }, [isDashboardChanging]);

    /* DASHBOARD MENU BUTTONS */
    const [chapterButton, setChapterButton] = useState(null);

    useEffect(() => {
        const getRefByKey = (keyName, refArray) => {
            if (Array.isArray(refArray)) {
                for (const ref of refArray) {
                    if (Object.keys(ref)[0] === keyName) {
                        return Object.values(ref)[0];
                    }
                }
            }
        };

        if (isPanelOpen && isDashboardMain) {
            setChapterButton(getRefByKey("chapter", menuButtonsRef.current));
        }
    }, [isPanelOpen, isDashboardMain]);

    /* PAGE CHANGE WITH CHAPTERS MODAL */
    const [chapterIsPending, setChapterIsPending] = useState(false);
    const chapterPendingFlag = useRef(false);
    const chapterChangeFlag = useRef(false);

    /* CHANGE CHAPTER HANDLER */
    const changeChapterHandler = (page) => {
        chapterChangeFlag.current = true;

        setPage(page);
    };

    /* SET CHAPTER PENDING STATE */
    useEffect(() => {
        if (chapterChangeFlag.current) {
            if (!chapterIsPending) {
                chapterPendingFlag.current = true;

                setChapterIsPending(true);
            }

            if (!isChapterLoading && chapterIsPending) {
                setTimeout(() => {
                    setIsPanelOpen(false);
                }, 500);
            }
        }
    }, [page, isChapterLoading, chapterIsPending]);

    /* CLEAR CHAPTER PENDING STATE */
    useEffect(() => {
        if (!isPanelOpen && chapterPendingFlag.current) {
            chapterPendingFlag.current = false;

            setTimeout(() => {
                chapterChangeFlag.current = false;

                setChapterIsPending(false);
            }, panelTransition);
        }
    }, [isPanelOpen]);

    return (
        <>
            <CSSTransition
                nodeRef={panelRef}
                in={isPanelOpen}
                classNames={"ReaderFade"}
                timeout={panelTransition}
                unmountOnExit
                appear
            >
                <div className="ReaderPanel" ref={panelRef}>
                    <ReaderTopbar title={title} shutdown={shutdown} />
                    <ReaderDashboard
                        ref={menuButtonsRef}
                        pages={pages}
                        chapters={chaptersData}
                        isMain={(state) => setIsDashboardMain(state)}
                        isChanging={(state) => setIsDashboardChanging(state)}
                        isGallup={isGallup}
                        isGibs={isGibs}
                        isFinished={isFinished}
                    />
                    {!isGallup && !isGibs && (
                        <ReaderChapters
                            page={page}
                            chapters={chaptersData}
                            triggerElement={chapterButton}
                            isAvailable={isDashboardMain}
                            isLoading={chapterIsPending}
                            onClick={(page) => changeChapterHandler(page)}
                        />
                    )}
                </div>
            </CSSTransition>
        </>
    );
};

export default ReaderPanel;
