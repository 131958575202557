import LearningBar from "./LearningBar";

import yellow_doodle from "../../../../assets/images/dashboard/score-doodles/yellow.svg";
import red_doodle from "../../../../assets/images/dashboard/score-doodles/red.svg";
import teal_doodle from "../../../../assets/images/dashboard/score-doodles/teal.svg";

const getScoreDoodle = (int) => {
    if (typeof int !== "number" || !int) {
        return yellow_doodle;
    }

    if (int >= 75) {
        return yellow_doodle;
    }

    if (int < 75 && int >= 30) {
        return red_doodle;
    }

    if (int < 30) {
        return teal_doodle;
    }
};

const LearningPanel = ({ data, onClick }) => {
    return (
        <>
            <button className="LearningPanel" type="button" onClick={onClick}>
                <span className="LearningPanel__Main">
                    <span className="LearningPanel__Title">
                        <span className="LearningPanel__Doodle">
                            <img
                                src={getScoreDoodle(data[0].score_pct)}
                                alt=""
                            />
                        </span>
                        {data[0].subject_name ?? data[0].chapter_title}
                    </span>
                    {data.map((element, index) => {
                        return (
                            <LearningBar
                                key={"LearningBar_" + element.idExamAnswer}
                                caption={
                                    index === 0
                                        ? "Ostatni wynik z testu: " +
                                          element.score_pct +
                                          "%"
                                        : "Próba "+ (data.length - index) +": " + element.score_pct + "%"
                                }
                                percent={element.score_pct}
                                range
                            />
                        );
                    })}
                </span>
                <span className="LearningPanel__Aside">
                    <span className="LearningPanel__Subject">
                        <span
                            className="LearningPanel__Thumbnail"
                            style={{
                                backgroundImage:
                                    "url(" + data[0].subject_img + ")",
                            }}
                        />
                    </span>
                    <span className="LearningPanel__Button">Wróć do testu</span>
                </span>
            </button>
        </>
    );
};

export default LearningPanel;
