const StyleIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="22"
            viewBox="0 0 21 22"
        >
            <text
                id="aA"
                transform="translate(0 16)"
                fontSize="16"
                fontFamily="Nunito"
                fontWeight="700"
            >
                <tspan x="0" y="0">
                    aA
                </tspan>
            </text>
        </svg>
    );
};

export default StyleIcon;
