const RouteTitle = ({title, isBackward, isAnimating}) => {
    return (
        <>
            <div className="RouteTitle">
                <div
                    className={
                        "RouteTitle__Track" +
                        (isBackward ? " _inverse" : "") +
                        (isAnimating ? " _animating" : "")
                    }
                >
                    {isAnimating && (
                        <>
                            <div className="RouteTitle__Item _prev">
                                <div className="RouteTitle__Text">
                                    {title.prev}
                                </div>
                            </div>
                        </>
                    )}
                    <div
                        className={
                            "RouteTitle__Item" +
                            (isAnimating ? " _next" : "")
                        }
                    >
                        <div className="RouteTitle__Text">
                            {title.next}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RouteTitle;