const setClassByColor = (color) => {
    switch (color) {
        case "light-blue":
            return " _color--lightBlue";
        case "teal":
            return " _color--teal";
        case "orange":
            return " _color--orange";
        case "green":
            return " _color--green";
        default:
            return "";
    }
};

const ReaderButton = ({
    className,
    children,
    color,
    size,
    isDimmed,
    isDisabled,
    onClick,
}) => {
    return (
        <button
            className={
                "ReaderButton" +
                (className ? ' ' + className : '') +
                (size === "large" ? " _size--large" : "") +
                setClassByColor(color) +
                (isDimmed ? " _is--dimmed" : "")
            }
            type="button"
            data-panel="false"
            disabled={isDisabled}
            onClick={onClick}
        >
            {children}
        </button>
    );
};

export default ReaderButton;
