import { useState, useRef, useEffect, useCallback, useContext } from "react";
import { useMutation, useQuery } from "react-query";
import { useHistory, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";

import { StoreContext } from "../../context/Store";

import useLibraryData from "../../hooks/useLibraryData";
import useCheckSubscription from "../../hooks/useCheckSubscription";

import pathPrev from "../../functions/pathPrev";
import routeLoadingState from "../../functions/routeLoadingState";

import DashboardContainer from "../../components/containers/dashboard/DashboardContainer";
import ShelfBar from "../../components/containers/dashboard/shelf/ShelfBar";
import ShelfList from "../../components/containers/dashboard/shelf/ShelfList";

import modalDoodleImage_1 from "../../assets/images/dashboard/modal-doodles/book.svg";
import modalDoodleImage_2 from "../../assets/images/dashboard/modal-doodles/book_2.svg";
import SubscriptionError from "../../components/containers/dashboard/SubscriptionError";

const MyShelf = ({ routeMatches, routeSetters, modalSetters }) => {
    const { setRouteData, setRouteIsLoading } = routeSetters;
    const {
        setModalData,
        setModalActive,
        setModalApiResponse,
        setModalApiLoading,
    } = modalSetters;

    const history = useHistory();
    const location = useLocation();
    const cookies = useCookies(["idRank"])[0];

    const { libraryStore, librarySave } = useLibraryData();
    const {
        isSubscriptionActive,
        isSubscriptionFetched,
        isSubscriptionLoading,
    } = useCheckSubscription();

    /* DROPDOWN ACTIVE */
    const [stateDropdownActive, setStateDropdownActive] = useState(false);

    /* DATA LOADING */
    const [dataLoading, setDataLoading] = useState(false);

    /* DATA CHANGE */
    const [dataChange, setDataChange] = useState(false);

    /* DATA RESET */
    const [dataReset, setDataReset] = useState(false);
    const dataResetFlag = useRef(false);
    const [dataResetExecute, setDataResetExecute] = useState(false);

    /* BOOK REMOVE EXECUTE */
    const idBookRemove = useRef(null);
    const [bookRemoveExecute, setBookRemoveExecute] = useState(false);

    /* PAGE */
    const page = useRef({
        number: 1,
        isFirst: true,
    });

    /* FILTERS */
    const filterSelected = useRef(libraryStore("bookshelf", "filter") ?? "all");

    /* STATES */
    const stateSelected = useRef(libraryStore("bookshelf", "state") ?? "");

    const endpointProgress = (progress) => {
        switch (progress) {
            case "in_progress":
                return "&status=in_progress";
            case "finished":
                return "&status=finished";
            case "deleted":
                return "&status=deleted";
            default:
                return "";
        }
    };

    const endpointAddress = (filter) => {
        switch (filter) {
            case "all":
                return `/my-shelf?page=${page.current.number}${endpointProgress(
                    stateSelected.current
                )}`;
            case "audio":
                return `/my-shelf?type=audio&page=${
                    page.current.number
                }${endpointProgress(stateSelected.current)}`;
            case "multi":
                return `/my-shelf?type=multi&page=${
                    page.current.number
                }${endpointProgress(stateSelected.current)}`;
            default:
                return `/my-shelf?page=${page.current.number}${endpointProgress(
                    ""
                )}`;
        }
    };

    const { api } = useContext(StoreContext);

    const {
        data: shelfData,
        isFetched: shelfIsFetched,
        isRefetching: shelfIsRefetching,
        isLoading: shelfIsLoading,
        refetch: shelfRefetch,
        remove: shelfRemove,
    } = useQuery(
        "myshelf",
        () => api.get(endpointAddress(filterSelected.current)),
        {
            staleTime: Infinity,
            retry: false,
            refetchOnWindowFocus: false,
        }
    );

    const { mutate: bookRemove } = useMutation(
        (id) =>
            api.post(`/my-shelf/delete`, {
                idMultibook: id,
            }),
        {
            onMutate: () => setModalApiLoading(true),
            onSuccess: () => {
                setModalApiResponse(true);

                setBookRemoveExecute(true);
            },
        }
    );

    /* DATA CHANGE */
    useEffect(() => {
        if (dataChange) {
            if (page.current.isFirst && !dataResetFlag.current) {
                setDataLoading(true);

                shelfRefetch();
            } else {
                setDataReset(true);
            }

            setDataChange(false);
        }
    }, [dataChange, shelfRefetch]);

    /* DATA RESET */
    useEffect(() => {
        if (dataReset) {
            page.current = {
                number: 1,
                isFirst: true,
            };

            /* EXECUTE RESET */
            setDataResetExecute(true);
            setDataReset(false);
        }
    }, [dataReset]);

    /* DATA RESET EXECUTE */
    useEffect(() => {
        if (dataResetExecute) {
            /* RESET ALL DATA TYPES AND RUN REFETCH */
            setDataResetExecute(false);

            dataResetFlag.current = false;

            setDataLoading(true);

            shelfRefetch();
        }
    }, [dataResetExecute, shelfRefetch]);

    /* DISABLE LOADING AFTER REFETCH */
    useEffect(() => {
        if (!shelfIsRefetching && dataLoading) {
            setDataLoading(false);
        }
    }, [shelfIsRefetching, dataLoading]);

    /* FILTER CHANGE HANDLER */
    const filterChangeHandler = (filter) => {
        filterSelected.current = filter;

        librarySave("bookshelf", ["filter", filter]);

        setDataChange(true);
    };

    /* SWITCH CHANGE HANDLER */
    const switchChangeHandler = (state) => {
        stateSelected.current = state;

        librarySave("bookshelf", ["state", state]);

        setStateDropdownActive(false);
        setDataChange(true);
    };

    /* NEXT PAGE HANDLER */
    const nextPageHandler = useCallback((pageNumber) => {
        page.current = {
            number: pageNumber,
            isFirst: pageNumber > 1 ? false : true,
        };

        shelfRefetch();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    /* RESET DATA FLAG HANDLER */
    const setDataResetFlag = useCallback(() => {
        dataResetFlag.current = true;
    }, []);

    /* REMOVE SHELF ON UNMOUNT */
    useEffect(() => {
        return () => {
            shelfRemove();
        };
    }, [shelfRemove]);

    /* ROUTE LOADING */
    const routeIsLoading = routeLoadingState({
        fetched: [shelfIsFetched, isSubscriptionFetched],
        loading: [shelfIsLoading, isSubscriptionLoading],
    });

    /* ROUTE SETTERS */
    useEffect(() => {
        const routeData = {
            title: "MOJA PÓŁKA",
            action: isSubscriptionActive
                ? {
                      type: "button",
                      props: {
                          text: "ULUBIONE",
                          icon: "heart",
                          onClick: () =>
                              history.push(
                                  `${pathPrev(
                                      location.pathname,
                                      "last"
                                  )}/library/favorites`
                              ),
                      },
                  }
                : undefined,
        };

        if (routeMatches) {
            setRouteIsLoading(routeIsLoading);
            setRouteData(routeData);
        }
    }, [
        routeIsLoading,
        routeMatches,
        setRouteIsLoading,
        setRouteData,
        isSubscriptionActive,
    ]);

    /* MODAL DATA */
    const modalData = useRef({});

    useEffect(() => {
        modalData.current = {
            data: [
                {
                    title: {
                        obj: "",
                    },
                    img: {
                        obj: modalDoodleImage_1,
                        size: "219px",
                    },
                    action: {
                        obj: [
                            {
                                text: "Tak, chcę usunąć",
                                important: true,
                                onClick: undefined,
                            },
                            {
                                text: "Nie, nie chcę usuwać",
                                onClick: () => setModalActive(false),
                            },
                        ],
                        key: "shelfCardDelete",
                    },
                },
                {
                    title: {
                        obj: "",
                        isCenter: true,
                    },
                    img: {
                        obj: modalDoodleImage_2,
                        size: "219px",
                    },
                    action: {
                        obj: {
                            text: "Przejdź dalej",
                        },
                    },
                },
            ],
        };
    }, [setModalActive]);

    const modalOpenHandler = (id, title) => {
        modalData.current.data[0].title.obj = (
            <p>Czy na pewno chcesz usunąć smartbooka „{title}” ?</p>
        );
        modalData.current.data[0].action.obj[0].onClick = () => bookRemove(id);
        modalData.current.data[1].title.obj = (
            <p>
                Smartbook „{title}”
                <br />
                został usunięty
            </p>
        );
        idBookRemove.current = id;

        setModalData(modalData.current);
    };

    return (
        <>
            <DashboardContainer className="library">
                {!routeIsLoading &&
                    (isSubscriptionActive ? (
                        <>
                            <ShelfBar
                                filters={[
                                    {
                                        name: "WSZYSTKO",
                                        filter: "all",
                                    },
                                    {
                                        name: "SMARTBOOKI",
                                        filter: "multi",
                                    },
                                    {
                                        name: "AUDIOBOOKI",
                                        filter: "audio",
                                    },
                                ]}
                                filterSelected={filterSelected.current}
                                onClickFilter={(filter) =>
                                    filterChangeHandler(filter)
                                }
                                states={[
                                    {
                                        name: "W trakcie",
                                        state: "in_progress",
                                    },
                                    {
                                        name: "Zakończone",
                                        state: "finished",
                                    },
                                    {
                                        name: "Usunięte",
                                        state: "deleted",
                                    },
                                ]}
                                stateSelected={stateSelected.current}
                                stateDropdown={{
                                    dropdownActive: stateDropdownActive,
                                    setDropdownActive: setStateDropdownActive,
                                }}
                                onClickSwitch={(state) =>
                                    switchChangeHandler(state)
                                }
                            />
                            <ShelfList
                                data={shelfData.data}
                                dataResetExecute={dataResetExecute}
                                setDataResetFlag={setDataResetFlag}
                                nextPageHandler={nextPageHandler}
                                bookRemove={{
                                    idBookRemove: idBookRemove.current,
                                    bookRemoveExecute: bookRemoveExecute,
                                    setBookRemoveExecute: setBookRemoveExecute,
                                }}
                                isLoading={dataLoading}
                                onRequestDelete={(id, title) =>
                                    modalOpenHandler(id, title)
                                }
                            />
                        </>
                    ) : (
                        <SubscriptionError rank={parseInt(cookies.idRank)} />
                    ))}
            </DashboardContainer>
        </>
    );
};

export default MyShelf;
