import checkIfNextCharIsValid from "./checkIfNextCharIsValid";

const getPhrasesOccurrences = (string, phrase) => {
    let count = 0;
    let position = string.indexOf(phrase);

    while (position > -1) {
        if (
            checkIfNextCharIsValid(string.charAt(position + phrase.length)) &&
            (string.charAt(position - 1) === " " ||
                string.charAt(position - 1 === ""))
        ) {
            count++;
        }

        position = string.indexOf(phrase, ++position);
    }

    return count;
};

export default getPhrasesOccurrences;
