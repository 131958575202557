import { useState, useEffect, useRef, useContext } from "react";
import parse from "html-react-parser";

import { ChapterContext } from "../context/ChapterStore";
import { ExamContext } from "../context/ExamStore";
import { ScoreContext } from "../context/ScoreStore";

import getExamProgress from "../functions/getExamProgress";
import getExamAnswers from "../functions/getExamAnswers";
import parseFlashcardContent from "../functions/flashcard/parseFlashcardContent";
import parseMathTag from "../functions/parseMathTag";
import parseMathTagConnection from "../functions/parseMathTagConnection";

import ReaderQuizify from "../utils/ReaderQuizify";
import ReaderUser from "../utils/ReaderUser";

const setIsChosenByUser = (answers, content) => {
    let isChosen = false;

    if (answers?.length > 0) {
        for (const answer of answers) {
            if (answer.content === content) {
                isChosen = true;
            }
        }
    }

    return isChosen;
};

const getChoiceCorrectAnswers = (answers) => {
    if (answers === "") return [];

    return answers?.reduce((result, element) => {
        if (element.isCorrect === "true") {
            result.push(element);
        }

        return result;
    }, []);
};

const ReaderChoice = ({
    ids,
    title,
    content,
    examContent,
    flash,
    answersSolved,
    setExamProgress,
    userAvatar,
    isLoading,
    isExam,
    isGallup,
    isGibs,
    isAnswered,
}) => {
    const { getChapterData } = useContext(ChapterContext);

    /* CHOICE INITIAL ANSWERS */
    const initialChoiceCorrectAnswers = useRef(
        getChoiceCorrectAnswers(content)
    );

    /* GET CHOICE STORED DONE STATE */
    const choiceIsDoneStored =
        !isExam &&
        initialChoiceCorrectAnswers.current.length !== 0 &&
        initialChoiceCorrectAnswers.current.length ===
            getChapterData(ids, "choice")?.length;

    /* CHOICE CURRENT ANSWERS */
    const [choiceCorrectAnswers, setChoiceCorrectAnswers] = useState([]);

    /* CHOICE DONE STATE */
    const [choiceIsDone, setChoiceIsDone] = useState(false);

    /* CHOICE TIMEOUT STATE */
    const [choiceTimeout, setChoiceTimeout] = useState(false);

    /* SET CHOICE DONE STATE IF EVERY CORRECT ANSWER IS SELECTED */
    useEffect(() => {
        if (
            initialChoiceCorrectAnswers.current.length !== 0 &&
            initialChoiceCorrectAnswers.current.length ===
                choiceCorrectAnswers.length &&
            !choiceIsDone
        ) {
            setChoiceIsDone(true);
        }
    }, [choiceCorrectAnswers, choiceIsDone]);

    return (
        <>
            <div
                className={
                    "ReaderChoice" +
                    (isGallup || isGibs ? " _color--secondary" : "")
                }
            >
                {title ? (
                    <div className="ReaderChoice__Title">
                        {parseFlashcardContent(
                            parse(parseMathTagConnection(title)),
                            flash
                        ).map((object) => {
                            return object;
                        })}
                    </div>
                ) : (
                    ""
                )}
                {content?.length > 1 ? (
                    <div className="ReaderChoice__List">
                        {content.map((object, index) => {
                            return (
                                <ReaderChoiceButton
                                    key={"choice_" + index}
                                    ids={ids}
                                    data={{
                                        content: object.content,
                                        isCorrect: object.isCorrect,
                                    }}
                                    examContent={examContent}
                                    timeout={{
                                        choiceTimeout: choiceTimeout,
                                        setChoiceTimeout: setChoiceTimeout,
                                    }}
                                    setExamProgress={setExamProgress}
                                    setChoiceCorrectAnswers={
                                        setChoiceCorrectAnswers
                                    }
                                    userAvatar={userAvatar}
                                    isLoading={isLoading}
                                    isDone={choiceIsDone}
                                    isExam={isExam}
                                    isGibs={isGibs}
                                    isAnswered={isAnswered}
                                    isChosen={
                                        isAnswered
                                            ? setIsChosenByUser(
                                                  getExamAnswers(
                                                      ids.idContent,
                                                      answersSolved
                                                  ),
                                                  object.content
                                              )
                                            : false
                                    }
                                />
                            );
                        })}
                    </div>
                ) : (
                    <div className="ReaderChoice_Error">
                        Nie można wyświetlić komponentu wyboru z powodu braku
                        lub jednej zadeklarowanej opcji.
                    </div>
                )}
                {choiceIsDone || choiceIsDoneStored ? (
                    <ReaderQuizify content="Gratulacje! Udzieliłeś/aś dobrej odpowiedzi" />
                ) : (
                    ""
                )}
            </div>
        </>
    );
};

const ReaderChoiceButton = ({
    ids,
    data,
    examContent,
    timeout,
    setChoiceCorrectAnswers,
    setExamProgress,
    userAvatar,
    isLoading,
    isDone,
    isExam,
    isGibs,
    isAnswered,
    isChosen,
}) => {
    const { content, isCorrect } = data;
    const { choiceTimeout, setChoiceTimeout } = timeout;

    const { setChapterData, getChapterData, getFullChapterData } =
        useContext(ChapterContext);
    const {
        setExamPagesStore,
        isGallupResultLoading,
        isGallupResultDone,
        isGibsResultLoading,
        isGibsResultDone,
    } = useContext(ExamContext);
    const { isExamScoreLoading, isExamScoreInit } = useContext(ScoreContext);

    /* DEFAULT STATES - EXAM */
    const [isSelected, setIsSelected] = useState(
        isExam && !isAnswered ? getChapterData(ids, "choice", data) : false
    );

    /* DEFAULT STATES - BASIC */
    const [responseSuccess, setResponseSuccess] = useState(false);
    const [responseError, setResponseError] = useState(false);

    /* SELECT HANDLER - EXAM */
    const choiceSelectExamHandler = () => {
        setChapterData(data, ids, "choice", isSelected);

        /* GET EXAM PROGRESS */
        const examProgress = getExamProgress(
            getFullChapterData(),
            examContent,
            false,
            isGibs
        );

        /* STORE EXAM PROGRESS AND SET NEW STATE */
        setExamPagesStore(ids.idChapter, examProgress, "progress");
        setExamProgress(examProgress);

        isSelected ? setIsSelected(false) : setIsSelected(true);
    };

    /* SUCCESS RESPONSE HANDLER - BASIC */
    const responseSuccessHandler = () => {
        setChapterData(data, ids, "choice");

        setResponseSuccess(true);
        setChoiceCorrectAnswers((prevAnswers) => [...prevAnswers, data]);
    };

    /* ERROR RESPONSE HANDLER - BASIC */
    const responseErrorHandler = () => {
        setResponseError(true);
        setChoiceTimeout(true);
    };

    /* CHOICE SELECT HANDLER */
    const choiceSelectHandler = () => {
        if (isExam) {
            choiceSelectExamHandler();
        } else {
            isCorrect === "true"
                ? responseSuccessHandler()
                : responseErrorHandler();
        }
    };

    /* ENABLE NEXT CHOICE AFTER TIMEOUT - BASIC */
    useEffect(() => {
        if (responseError && !isExam) {
            setTimeout(() => {
                setResponseError(false);
                setChoiceTimeout(false);
            }, 1000);
        }
    }, [responseError, setChoiceTimeout, isExam]);

    /* GET IF STORED CHOICE EXIST */
    const choiceExistStored = isExam
        ? undefined
        : getChapterData(ids, "choice", data);

    return (
        <button
            className={
                "ReaderChoice__Button" +
                (isSelected ? " _is--selected" : "") +
                (responseSuccess ||
                (!isExam && choiceExistStored) ||
                (isExam && isAnswered && isCorrect === "true")
                    ? " _response--success"
                    : "") +
                (responseError ? " _response--error" : "")
            }
            type="button"
            data-panel="false"
            disabled={
                choiceTimeout ||
                responseSuccess ||
                isLoading ||
                isDone ||
                isAnswered ||
                isExamScoreLoading ||
                isExamScoreInit ||
                isGallupResultLoading ||
                isGallupResultDone ||
                isGibsResultLoading ||
                isGibsResultDone
            }
            onClick={!isAnswered ? choiceSelectHandler : undefined}
        >
            {parseMathTag(parseMathTagConnection(content))}
            {isChosen && <ReaderUser avatar={userAvatar} />}
        </button>
    );
};

export default ReaderChoice;
