import { useEffect } from "react";

const ReaderExit = ({ shutdown }) => {
    const { isBookShutdown, setIsBookShutdown, setFinishBookShutdown } =
        shutdown;

    useEffect(() => {
        if (isBookShutdown) {
            setTimeout(() => {
                setFinishBookShutdown(true);
            }, 1500);
        }
    }, [isBookShutdown, setFinishBookShutdown]);

    return (
        <button
            id="NavigationBook"
            className="ReaderExit"
            type="button"
            onClick={
                !isBookShutdown ? () => setIsBookShutdown(true) : undefined
            }
        >
            Smartbook Close
        </button>
    );
};

export default ReaderExit;
