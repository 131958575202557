import { useState, useEffect, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

import LibraryCarouselHeading from "./LibraryCarouselHeading";
import LibraryBook from "./LibraryBook";

const LibraryCarousel = ({ heading, data }) => {
    /* SWIPER INIT */
    const [swiperInit, setSwiperInit] = useState(false);

    /* SWIPER EDGE INIT */
    const [swiperEdgeInit, setSwiperEdgeInit] = useState(false);
    const swiperEdgeInitFlag = useRef(false);

    /* SWIPER EDGE RESIZE */
    const [swiperEdgeIsResizing, setSwiperEdgeIsResizing] = useState(false);
    const swiperEdgeResizeFlag = useRef(false);

    /* SWIPER EDGE DATA */
    const swiperEdge = useRef(null);
    const swiperEdgeInitialWidth = useRef(0);
    const swiperEdgeOffset = useRef(0);

    /* SWIPER DATA LENGTH */
    const swiperDataLength = useRef(0);

    /* SWIPER DUMMY */
    const [swiperDummyInit, setSwiperDummyInit] = useState(false);
    const swiperDummyWidth = useRef(0);

    /* GET WINDOW WIDTH */
    const getViewportWidth = () => {
        const viewportWidth =
            window.innerWidth || document.documentElement.clientWidth;

        return viewportWidth;
    };

    /* SET SWIPER EDGE INITIAL WIDTH */
    const setSwiperEdgeInitialWidth = (int) => {
        swiperEdgeInitialWidth.current = int;
    };

    const setSwiperEdgeProps = () => {
        /* SWIPER EDGE OFFSET LEFT */
        const edgeLeft =
            document.body.clientWidth < swiperEdge.current.offsetLeft
                ? swiperEdge.current.offsetLeft - document.body.clientWidth
                : swiperEdge.current.offsetLeft;

        /* SET SWIPER EDGE OFFSET */
        swiperEdgeOffset.current =
            (document.body.clientWidth -
                edgeLeft -
                swiperEdgeInitialWidth.current) *
            -1;

        /* SET DUMMY WIDTH */
        swiperDummyWidth.current = (swiperEdgeOffset.current + 10) * -1;
    };

    /* SET EDGE */
    useEffect(() => {
        if (getViewportWidth() < 768) {
            /* INIT */
            if (!swiperEdgeInitFlag.current) {
                /* INIT SWIPER EDGE FLAG */
                swiperEdgeInitFlag.current = true;

                setSwiperEdgeInitialWidth(swiperEdge.current.offsetWidth);
                setSwiperEdgeProps();

                /* INIT SWIPER EDGE */
                setSwiperEdgeInit(true);
            }

            /* RESIZING */
            if (swiperEdgeIsResizing) {
                /* SET SWIPER RESIZING FLAG */
                swiperEdgeResizeFlag.current = true;

                setSwiperEdgeInit(false);
                setSwiperEdgeIsResizing(false);
            }

            /* RESIZE */
            if (!swiperEdgeIsResizing && swiperEdgeResizeFlag.current) {
                swiperEdgeResizeFlag.current = false;

                setSwiperEdgeInitialWidth(swiperEdge.current.offsetWidth);
                setSwiperEdgeProps();

                /* INIT SWIPER EDGE */
                setSwiperEdgeInit(true);
            }
        } else {
            swiperEdgeInitFlag.current = false;

            setSwiperEdgeInit(false);
            setSwiperEdgeIsResizing(false);
        }
    }, [swiperInit, swiperEdgeIsResizing]);

    /* SET DATA LENGTH */
    useEffect(() => {
        if (swiperInit) {
            swiperDataLength.current = data.length;
        }
    }, [data, swiperInit]);

    /* ENABLE SWIPER DUMMY */
    useEffect(() => {
        if (swiperEdgeInit) {
            /* SET DUMMY STATE */
            if (swiperDataLength.current > 2 && swiperDataLength.current < 5) {
                setSwiperDummyInit(true);
            } else {
                setSwiperDummyInit(false);
            }
        } else {
            setSwiperDummyInit(false);
        }
    }, [swiperEdgeInit, data]);

    /* SWIPER EDGE RESIZE */
    useEffect(() => {
        let resizeTimeout;

        const resizeSwiperToEdge = () => {
            clearTimeout(resizeTimeout);

            resizeTimeout = setTimeout(() => {
                setSwiperEdgeIsResizing(true);
            }, 500);
        };

        window.addEventListener("resize", resizeSwiperToEdge);

        return () => {
            window.removeEventListener("resize", resizeSwiperToEdge);
        };
    }, []);

    return (
        <>
            <div className="LibraryCarousel">
                <LibraryCarouselHeading
                    title={heading.title}
                    onClick={heading.onClickFn}
                />
                <div
                    className="LibraryCarousel__Swiper"
                    ref={swiperEdge}
                    style={
                        swiperEdgeInit
                            ? { marginRight: swiperEdgeOffset.current }
                            : undefined
                    }
                >
                    <Swiper
                        spaceBetween={10}
                        loop={data?.length > 4 ? true : false}
                        slidesPerView={"auto"}
                        onSwiper={(swiper) => setSwiperInit(swiper)}
                    >
                        {data?.map((object) => {
                            return (
                                <SwiperSlide
                                    key={"multibook_" + object.idMultibook}
                                >
                                    <LibraryBook
                                        className="_width--fixed"
                                        data={{
                                            id: {
                                                category: object.idCategory,
                                                multibook: object.idMultibook,
                                            },
                                            type: object.type,
                                            cover: object.cover,
                                            title: object.title,
                                            content: object.subtitle,
                                            favorite: object.favorite,
                                        }}
                                    />
                                </SwiperSlide>
                            );
                        })}
                        {swiperDummyInit ? (
                            <SwiperSlide>
                                <div
                                    className="LibraryCarousel__SwiperDummy"
                                    style={{ width: swiperDummyWidth.current }}
                                ></div>
                            </SwiperSlide>
                        ) : (
                            ""
                        )}
                    </Swiper>
                </div>
            </div>
        </>
    );
};

export default LibraryCarousel;
