import { useContext } from "react";

import { SettingsContext } from "../context/SettingsStore";

const setFontSizeClassName = (size) => {
    switch (size) {
        case 1:
            return "";
        case 2:
            return " _font--medium";
        case 3:
            return " _font--large";
        case 4:
            return " _font--extraLarge";
        default:
            return "";
    }
};

const setContentFontWeight = (name) => {
    switch (name) {
        case "default":
            return 600;
        default:
            return "";
    }
};

const setTitleFontWeight = (name) => {
    switch (name) {
        case "default":
            return 900;
        case "algreya":
            return 800;
        default:
            return 700;
    }
};

const ReaderPage = ({ title, children, isExam, isFinished }) => {
    const { fontSize, fontFamily } = useContext(SettingsContext);

    return (
        <>
            <div
                className={
                    "ReaderPage" +
                    setFontSizeClassName(fontSize) +
                    (!title && !isExam ? " _title--missing" : "") +
                    (isExam ? " _type--exam" : "") +
                    (isFinished ? " _is--done" : "")
                }
                style={{
                    fontFamily: fontFamily.family,
                    "--content-fontWeight": setContentFontWeight(
                        fontFamily.name
                    ),
                    "--heading-fontWeight": setTitleFontWeight(fontFamily.name),
                }}
            >
                {title && (
                    <h1 className="ReaderPage__Title">{title.toUpperCase()}</h1>
                )}
                {children}
            </div>
        </>
    );
};

export default ReaderPage;
