import randomNumber from "./randomNumber";

import loader_01 from "../assets/images/dashboard/loaders/doodle-loader_1.svg";
import loader_02 from "../assets/images/dashboard/loaders/doodle-loader_2.svg";
import loader_03 from "../assets/images/dashboard/loaders/doodle-loader_3.svg";
import loader_04 from "../assets/images/dashboard/loaders/doodle-loader_4.svg";

const randomRouteLoader = () => {
    switch (randomNumber(1, 4)) {
        case 1:
            return loader_01;
        case 2:
            return loader_02;
        case 3:
            return loader_03;
        case 4:
            return loader_04;
        default:
            return;
    }
};

export default randomRouteLoader;
