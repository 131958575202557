const getProgressTitle = (type, current, total) => {
    switch (type) {
        case "test":
            return (
                <p>
                    Pytanie {current}/{total}
                </p>
            );
        case "points":
            return (
                <p>
                    {(current && total
                        ? Math.round((current * 100) / total)
                        : 0) + "%"}
                </p>
            );
        default:
            return (
                <p>
                    {current > total
                        ? "Skończony"
                        : "Rozdział " + current + " z " + total}
                </p>
            );
    }
};

const getScopeBackgroundColor = (color) => {
    if (!color) return;

    if (color === "#9AC8C8") {
        return "#D8EFEF";
    } else if (color === "#FF6801") {
        return "#FFD4B7";
    } else if (color === "#FFDD40") {
        return "#FFF1B1";
    } else {
        return;
    }
};

const getProgressScope = (scopes, current, total) => {
    if (!scopes) return;

    const score = current && total ? Math.round((current * 100) / total) : 0;
    const numberOfScopes = parseInt(scopes.numberOfScopes);

    if (numberOfScopes === 3) {
        if (score < 40) {
            return {
                ...scopes.scopes[0].color,
                background: getScopeBackgroundColor(
                    scopes.scopes[0].color.first
                ),
            };
        } else if (score >= 40 && score < 80) {
            return {
                ...scopes.scopes[1].color,
                background: getScopeBackgroundColor(
                    scopes.scopes[1].color.first
                ),
            };
        } else if (score >= 80) {
            return {
                ...scopes.scopes[2].color,
                background: getScopeBackgroundColor(
                    scopes.scopes[2].color.first
                ),
            };
        }
    } else if (numberOfScopes === 2) {
        if (score < 50) {
            return {
                ...scopes.scopes[0].color,
                background: getScopeBackgroundColor(
                    scopes.scopes[0].color.first
                ),
            };
        } else if (score >= 50) {
            return {
                ...scopes.scopes[1].color,
                background: getScopeBackgroundColor(
                    scopes.scopes[1].color.first
                ),
            };
        }
    } else if (numberOfScopes === 1) {
        return {
            ...scopes.scopes[0].color,
            background: getScopeBackgroundColor(scopes.scopes[0].color.first),
        };
    } else {
        return;
    }
};

const ReaderProgress = ({
    className,
    type,
    color,
    numbers = {},
    scopes,
    isLoading,
}) => {
    const { current, total } = numbers;

    const progressScope = getProgressScope(scopes, current, total);

    return (
        <>
            <div
                className={
                    "ReaderProgress" +
                    (color === "secondary" ? " _color--secondary" : "") +
                    (type === "test" ? " _type--test" : "") +
                    (type === "points" ? " _type--points" : "") +
                    (className ? " " + className : "") +
                    (current &&
                    total &&
                    current > total &&
                    type !== "test" &&
                    type !== "points"
                        ? " _is--done"
                        : "") +
                    (progressScope ? " _style--inline" : "")
                }
            >
                <div className="ReaderProgress__Title">
                    {!isLoading
                        ? getProgressTitle(type, current, total)
                        : "Trwa wczytywanie"}
                </div>
                <div
                    className="ReaderProgress__Bar"
                    style={{
                        "--colorBar-bg": progressScope?.background,
                    }}
                >
                    <div
                        className="ReaderProgress__Line"
                        style={{
                            width: !isLoading
                                ? Math.round(current * 100) / total + "%"
                                : "0%",
                            "--colorLine-first": progressScope?.first,
                            "--colorLine-second": progressScope?.second,
                        }}
                    ></div>
                </div>
            </div>
        </>
    );
};

export default ReaderProgress;
