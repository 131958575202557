import Button from "../../../buttons/Button";

const LibraryEmpty = ({ message, onClickFn }) => {
    return (
        <>
            <div className="LibraryEmpty">
                {message ? (
                    <div className="LibraryEmpty__Message">{message}</div>
                ) : (
                    ""
                )}
                <Button
                    classNameWrapper="LibraryEmpty__Button"
                    className="medium"
                    onClickFn={onClickFn}
                >
                    Wyczyść
                </Button>
            </div>
        </>
    );
};

export default LibraryEmpty;
