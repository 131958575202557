import Button from "../Button";

const ButtonRole = ({
    isActive,
    onClickLift,
    roleText,
    roleValue,
    buttonText,
}) => {
    const onClickFn = () => {
        onClickLift({ value: roleValue, text: roleText });
    };

    return (
        <>
            <div className={"role-button" + (isActive ? " is-active" : "")}>
                <div className="role-name" data-icon={roleText.charAt(0)}>
                    <div className="role-text">{roleText}</div>
                </div>
                <div className="role-field">
                    <Button className="small" onClickFn={onClickFn}>
                        {buttonText}
                    </Button>
                </div>
            </div>
        </>
    );
};

export default ButtonRole;
