import Checkbox from "../inputs/Checkbox";

const CheckboxGroup = ({ className, data, dataKey }) => {
    return (
        <>
            {data && data.length > 0 && (
                <div
                    className={
                        "CheckboxGroup" + (className ? " " + className : "")
                    }
                >
                    {data?.map((object, index) => {
                        return (
                            <div
                                key={dataKey + "_" + index}
                                className="CheckboxGroup__Item"
                            >
                                <Checkbox
                                    isChecked={object.isChecked}
                                    onChangeFn={object.onChangeFn}
                                >
                                    {object.text}
                                </Checkbox>
                            </div>
                        );
                    })}
                </div>
            )}
        </>
    );
};

export default CheckboxGroup;
