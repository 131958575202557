import { useContext } from "react";
import { useMutation } from "react-query";

import { StoreContext } from "../context/Store";

const useNotificationRead = () => {
    const { api } = useContext(StoreContext);

    const { mutate: readNotification, isSuccess: isNotificationReadSuccess } =
        useMutation((idNotification) =>
            api.post("/notifications/mark_as_readed", { idNotification })
        );

    return {
        readNotification: (idNotification) => readNotification(idNotification),
        isNotificationReadSuccess
    };
};

export default useNotificationRead;
