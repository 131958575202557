import { useState, useRef, createContext } from "react";

export const ExamContext = createContext();

const setExamPagesObject = (page, type) => {
    switch (type) {
        case "progress":
            return { progress: page };
        case "both":
            return { page: page, progress: page };
        default:
            return { page: page };
    }
};

export const chapterIsExam = (type) => {
    if (
        type === "stage_test" ||
        type === "gallup_test" ||
        type === "gibs_test"
    ) {
        return true;
    } else {
        return false;
    }
};

const ExamProvider = ({ children }) => {
    /* EXAM RE/LOADING STATES */
    const [isExamLoading, setIsExamLoading] = useState(false);
    const [isExamReloading, setIsExamReloading] = useState(false);

    /* EXAM PAGES STORE */
    const examPagesStore = useRef(null);

    /* EXAM GALLUP/GIBS RESULT DATA */
    const examResultStore = useRef(null);

    /* SHARE MARK STORE [STAGE TEST] */
    const markShareStore = useRef(null);

    /* EXAM GALLUP STATES */
    const isGallupResultMount = useRef(false);
    const [isGallupResultLoading, setIsGallupResultLoading] = useState(false);
    const [isGallupResultDone, setIsGallupResultDone] = useState(false);

    /* EXAM GIBS STATES */
    const [isGibsResultLoading, setIsGibsResultLoading] = useState(false);
    const [isGibsResultDone, setIsGibsResultDone] = useState(false);

    /* SET EXAM PAGES TO STORE */
    const setExamPagesStore = (idChapter, page, type) => {
        if (!idChapter) return;

        let examPagesExist = false;
        const examPagesArray = [];
        const examPagesObject = setExamPagesObject(page, type);

        if (examPagesStore.current) {
            for (const examPagesItem of examPagesStore.current) {
                if (examPagesItem.idChapter === idChapter) {
                    examPagesExist = true;

                    examPagesArray.push({
                        ...examPagesItem,
                        ...examPagesObject,
                    });
                } else {
                    examPagesArray.push(examPagesItem);
                }
            }

            if (!examPagesExist) {
                examPagesArray.push({ idChapter, ...examPagesObject });
            }
        } else {
            examPagesArray.push({ idChapter, ...examPagesObject });
        }

        examPagesStore.current = examPagesArray;
    };

    /* GET EXAM PAGES FROM STORE */
    const getExamPagesStore = (idChapter, getProgress) => {
        if (!idChapter || !examPagesStore.current) return;

        for (const examPage of examPagesStore.current) {
            if (examPage.idChapter === idChapter) {
                return getProgress ? examPage.progress : examPage.page;
            }
        }
    };

    /* REMOVE EXAM PAGES FROM STORE */
    const removeExamPagesStore = (idChapter) => {
        if (!examPagesStore.current) return;

        examPagesStore.current = examPagesStore.current.filter(
            (element) => element.idChapter !== idChapter
        );
    };

    /* SET EXAM RESULT TO STORE */
    const setExamResultStore = (data, isGibs) => {
        if (!data) return undefined;

        if (isGibs) {
            examResultStore.current = data.amount;
        } else {
            let scopeIndex = 0;
            const mappedContent = [];

            for (const scope of data.scope.scope.scopes) {
                mappedContent.push({
                    idChapter: data.answers.idChapter,
                    idChapterContent: parseInt(
                        scope.idChapterContent.replace("temp", "")
                    ),
                    type: scope.type,
                    childs: [
                        {
                            idChapter: data.answers.idChapter,
                            idChapterContent: parseInt(
                                data.answers.idExamAnswer.toString() +
                                    scope.category_id.toString()
                            ),
                            type: "points",
                            content: {
                                category: scope.title,
                                title:
                                    "Świetna robota, " + data.user.name + "!",
                            },
                            content2: {
                                total_score: parseInt(
                                    data.score.section[scopeIndex].total_score
                                ),
                                user_score: parseInt(
                                    data.score.section[scopeIndex].user_score
                                ),
                            },
                        },
                        ...scope.childs.map((object) => ({
                            idChapter: data.answers.idChapter,
                            idChapterContent: parseInt(
                                object.idChapterContent.replace("temp", "")
                            ),
                            content: object.content,
                            content2: object.content2,
                            title: object.title,
                            type: object.type,
                        })),
                    ],
                });

                scopeIndex++;
            }

            examResultStore.current = mappedContent;
        }
    };

    /* GET EXAM RESULT FROM STORE */
    const getExamResultStore = () => {
        if (!examResultStore.current) return;

        return examResultStore.current;
    };

    /* SET SHARE MARK TO STORE */
    const setShareMarkStore = (idChapter) => {
        if (!idChapter) return;

        if (!markShareStore.current) {
            markShareStore.current = [idChapter];
        } else {
            let isFound = false;

            for (const mark of markShareStore.current) {
                if (idChapter === mark) {
                    isFound = true;
                }
            }

            if (!isFound) {
                markShareStore.current.push(idChapter);
            }
        }
    };

    /* GET SHARE MARK FROM STORE */
    const getShareMarkStore = (idChapter) => {
        if (!markShareStore.current) return 0;

        let isFound = 0;

        for (const mark of markShareStore.current) {
            if (idChapter === mark) {
                isFound = 1;
            }
        }

        return isFound;
    };

    return (
        <ExamContext.Provider
            value={{
                setExamPagesStore,
                getExamPagesStore,
                removeExamPagesStore,
                setExamResultStore,
                getExamResultStore,
                setShareMarkStore,
                getShareMarkStore,
                isExamLoading,
                setIsExamLoading,
                isExamReloading,
                setIsExamReloading,
                isGallupResultMount,
                isGallupResultLoading,
                setIsGallupResultLoading,
                isGallupResultDone,
                setIsGallupResultDone,
                isGibsResultLoading,
                setIsGibsResultLoading,
                isGibsResultDone,
                setIsGibsResultDone,
            }}
        >
            {children}
        </ExamContext.Provider>
    );
};

export default ExamProvider;
