import React from "react";

import addChildrenIcon from "../../../assets/images/dashboard/nav-tiles/add-children.svg";
import calendarIcon from "../../../assets/images/dashboard/nav-tiles/calendar.svg";
import flashcardIcon from "../../../assets/images/dashboard/nav-tiles/flashcard.svg";
import groupProjectsIcon from "../../../assets/images/dashboard/nav-tiles/group-projects.svg";
import groupsIcon from "../../../assets/images/dashboard/nav-tiles/groups.svg";
import knowYourselfIcon from "../../../assets/images/dashboard/nav-tiles/know-yourself.svg";
import learningProgressIcon from "../../../assets/images/dashboard/nav-tiles/learning-progress.svg";
import libraryIcon from "../../../assets/images/dashboard/nav-tiles/library.svg";
import myAccountIcon from "../../../assets/images/dashboard/nav-tiles/my-account.svg";
import myChildrenIcon from "../../../assets/images/dashboard/nav-tiles/my-children.svg";
import mySchoolsIcon from "../../../assets/images/dashboard/nav-tiles/my-schools.svg";
import myShelfIcon from "../../../assets/images/dashboard/nav-tiles/my-shelf.svg";
import subjectsIcon from "../../../assets/images/dashboard/nav-tiles/subjects.svg";
import subscriptionsIcon from "../../../assets/images/dashboard/nav-tiles/subscriptions.svg";

const getIcon = (type) => {
    switch (type) {
        case "add-children":
            return addChildrenIcon;
        case "calendar":
            return calendarIcon;
        case "flashcard":
            return flashcardIcon;
        case "group-projects":
            return groupProjectsIcon;
        case "groups":
            return groupsIcon;
        case "know-yourself":
            return knowYourselfIcon;
        case "learning-progress":
            return learningProgressIcon;
        case "library":
            return libraryIcon;
        case "my-account":
            return myAccountIcon;
        case "my-children":
            return myChildrenIcon;
        case "my-schools":
            return mySchoolsIcon;
        case "my-shelf":
            return myShelfIcon;
        case "subjects":
            return subjectsIcon;
        case "subscriptions":
            return subscriptionsIcon;
        default:
            return undefined;
    }
};

const NavTiles = ({ keyName, data }) => {
    return (
        <>
            <div className="NavTiles">
                {data?.map((object, index) => {
                    return (
                        <div
                            className="NavTiles__Item"
                            key={keyName + "_" + index}
                        >
                            <button
                                className={
                                    "NavTiles__Button" +
                                    (!object.isActive ? " _is--inactive" : "")
                                }
                                type="button"
                                disabled={object.isDisabled}
                                onClick={object.onClick}
                            >
                                <span className="NavTiles__Icon">
                                    {getIcon(object.icon) ? (
                                        <img
                                            className="NavTiles__Img"
                                            src={getIcon(object.icon)}
                                            alt=""
                                        />
                                    ) : (
                                        ""
                                    )}
                                </span>
                                <span className="NavTiles__Title">
                                    {object.text}
                                </span>
                            </button>
                        </div>
                    );
                })}
            </div>
        </>
    );
};

export default NavTiles;
