import { useRef, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useCookies } from "react-cookie";

import SettingsProvider from "./context/SettingsStore";
import ChapterProvider from "./context/ChapterStore";
import ExamProvider from "./context/ExamStore";
import ScoreProvider from "./context/ScoreStore";
import PlayerProvider from "./context/PlayerStore";

import Reader from "./Reader";

const ReaderApp = () => {
    const history = useHistory();
    const params = useParams();

    const [cookies] = useCookies(["active"]);

    if (cookies.active === "undefined") {
        history.replace("/dashboard");
    }

    const idBook = useRef(params.idMultibook);

    // const chatInterval = useRef(null);

    // useEffect(() => {
    //     const chat = document.querySelector("#chat-application");

    //     if (chat && !chatInterval.current) {
    //         chat.style.display = "none";

    //         chatInterval.current = setInterval(()=>{
    //             chat.style.display = "none";
    //         }, 2000);
    //     }

    //     return () => {
    //         if(chatInterval.current) {
    //             clearInterval(chatInterval.current);

    //             chatInterval.current = null;

    //             chat.style.display = "block";
    //         }
    //     }
    // }, []);

    return (
        <SettingsProvider>
            <ChapterProvider>
                <ExamProvider>
                    <ScoreProvider>
                        <PlayerProvider>
                            <Reader idBook={idBook.current} />
                        </PlayerProvider>
                    </ScoreProvider>
                </ExamProvider>
            </ChapterProvider>
        </SettingsProvider>
    );
};

export default ReaderApp;
