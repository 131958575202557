import { useRef, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import useCheckSubscription from "../../../hooks/useCheckSubscription";

import pathSlice from "../../../functions/pathSlice";
import routeLoadingState from "../../../functions/routeLoadingState";

import DashboardContainer from "../../../components/containers/dashboard/DashboardContainer";
import NavTiles from "../../../components/containers/dashboard/NavTiles";

import modalDoodleImage from "../../../assets/images/dashboard/how2doodle-subscription.svg";

const Home = ({ routeMatches, routeSetters, modalSetters }) => {
    const { setRouteData, setRouteIsLoading } = routeSetters;
    const { setModalData } = modalSetters;

    const history = useHistory();
    const location = useLocation();

    const {
        isSubscriptionActive,
        isSubscriptionFetched,
        isSubscriptionLoading,
    } = useCheckSubscription();

    /* ROUTE LOADING */
    const isRouteLoading = routeLoadingState({
        fetched: [isSubscriptionFetched],
        loading: [isSubscriptionLoading],
    });

    /* MODAL SUBSCRIPTION DATA */
    const modalSubscriptionData = useRef({});

    useEffect(() => {
        if (!isRouteLoading) {
            modalSubscriptionData.current = {
                data: [
                    {
                        title: {
                            obj: (
                                <p>
                                    Twój abonament jest nieaktywny. Poinformuj o
                                    tym rodzica, abyś w pełni mógł cieszyć się
                                    światem How2 Edu.
                                </p>
                            ),
                        },
                        img: {
                            obj: modalDoodleImage,
                            size: "262px",
                        },
                        action: {
                            obj: {
                                text: "Wróć",
                            },
                        },
                    },
                ],
            };
        }
    }, [isRouteLoading]);

    /* ROUTE SETTERS */
    useEffect(() => {
        const routeData = {};

        if (routeMatches) {
            setRouteIsLoading(isRouteLoading);
            setRouteData(routeData);
        }
    }, [routeMatches, setRouteIsLoading, isRouteLoading, setRouteData]);

    return (
        <DashboardContainer className="children">
            <NavTiles
                keyName="dashboardChildren"
                data={[
                    {
                        text: "Moje konto",
                        icon: "my-account",
                        isActive: true,
                        onClick: () =>
                            history.push(
                                `${pathSlice(location.pathname)}/account`
                            ),
                    },
                    {
                        text: "Kalendarz",
                        icon: "calendar",
                        isActive: true,
                        onClick: () =>
                            history.push(
                                `${pathSlice(location.pathname)}/calendar`
                            ),
                    },
                    {
                        text: "Poznaj siebie",
                        icon: "know-yourself",
                        isActive: isSubscriptionActive,
                        onClick: isSubscriptionActive ? () =>
                            history.push(
                                `${pathSlice(
                                    location.pathname
                                )}/library/category-1`
                            ) : () => setModalData(modalSubscriptionData.current),
                    },
                    {
                        text: "Biblioteka",
                        icon: "library",
                        isActive: isSubscriptionActive,
                        onClick: isSubscriptionActive
                            ? () =>
                                  history.push(
                                      `${pathSlice(location.pathname)}/library`
                                  )
                            : () => setModalData(modalSubscriptionData.current),
                    },
                    {
                        text: "Postępy w nauce",
                        icon: "learning-progress",
                        isActive: isSubscriptionActive,
                        onClick: isSubscriptionActive
                            ? () =>
                                  history.push(
                                      `${pathSlice(
                                          location.pathname
                                      )}/learning-progress`
                                  )
                            : () => setModalData(modalSubscriptionData.current),
                    },
                    {
                        text: "Moja półka",
                        icon: "my-shelf",
                        isActive: isSubscriptionActive,
                        onClick: isSubscriptionActive
                            ? () =>
                                  history.push(
                                      `${pathSlice(
                                          location.pathname
                                      )}/mybookshelf`
                                  )
                            : () => setModalData(modalSubscriptionData.current),
                    },
                    {
                        text: "Grupy",
                        icon: "groups",
                        isActive: true,
                        onClick: () =>
                            history.push(
                                `${pathSlice(location.pathname)}/groups`
                            ),
                    },
                ]}
            />
        </DashboardContainer>
    );
};

export default Home;
