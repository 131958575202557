import pathSlice from "./pathSlice";

const pathPrev = (url, step) => {
    let urlSlice = pathSlice(url);
    let stepBackward =
        typeof step === "number" ? (step - 1 <= 0 ? 0 : step - 1) : step;
    let slashIndexes = [];

    for (let i = 0; i < urlSlice.length; i++) {
        if (urlSlice[i] === "/" && i !== 0) {
            slashIndexes.push(i);
        }
    }

    slashIndexes.sort((a, b) => b - a);

    if (stepBackward >= slashIndexes.length || stepBackward === "last") {
        if (slashIndexes.length > 1) {
            stepBackward = slashIndexes.length - 1;
        } else {
            stepBackward = 0;
        }
    }

    if (typeof stepBackward === "string" && stepBackward !== "last") {
        stepBackward = 0;
    }

    return urlSlice.substr(0, slashIndexes[stepBackward]);
};

export default pathPrev;
