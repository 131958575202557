import { useEffect, useRef, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useMutation, useQuery } from "react-query";

import { StoreContext } from "../../../context/Store";

import useActionLoading from "../../../hooks/useActionLoading";

import routeLoadingState from "../../../functions/routeLoadingState";
import pathSlice from "../../../functions/pathSlice";
import pathPrev from "../../../functions/pathPrev";

import Button from "../../../components/buttons/Button";

import DashboardContainer from "../../../components/containers/dashboard/DashboardContainer";

import modalDoodleImage_1 from "../../../assets/images/dashboard/modal-doodles/happy.svg";
import modalDoodleImage_2 from "../../../assets/images/dashboard/modal-doodles/questionmark_2.svg";
import modalDoodleImage_3 from "../../../assets/images/dashboard/modal-doodles/sad_3.svg";

const ChildrenList = ({ routeMatches, routeSetters, modalSetters }) => {
    const { setRouteData, setRouteIsLoading } = routeSetters;
    const {
        setModalData,
        setModalActive,
        setModalApiResponse,
        setModalApiLoading,
    } = modalSetters;

    const { isActionLoading, setIsActionLoading } = useActionLoading();

    const history = useHistory();
    const location = useLocation();

    const { api } = useContext(StoreContext);

    /* GET CHILDREN LIST */
    const {
        data: childListData,
        isFetched: childListIsFetched,
        isRefetching: childListIsRefetching,
        isLoading: childListIsLoading,
        isStale: childListIsStale,
        refetch: childListRefetch,
    } = useQuery(
        ["childList", { pending: true }],
        () => api.get(`/account/childList?pending=true`),
        {
            staleTime: Infinity,
            retry: false,
            refetchOnWindowFocus: false,
        }
    );

    /* ACCEPT CHILD */
    const { mutate: acceptChildMutate } = useMutation(
        (data) => api.post(`/account/child/accept`, data),
        {
            onMutate: () => setIsActionLoading(true),
            onSuccess: () => {
                childListRefetch();

                setModalData(modalChildrenAcceptData.current);

                setIsActionLoading(false);
            },
            onError: () => setIsActionLoading(false),
        }
    );

    /* DECLINE CHILD */
    const { mutate: declineChildMutate } = useMutation(
        (data) => api.post(`/account/child/decline`, data),
        {
            onMutate: () => setModalApiLoading(true),
            onSuccess: () => {
                childListRefetch();

                setModalApiResponse(true);
            },
            onError: () => alert("*Placeholder* Error with sending data"),
        }
    );

    /* ROUTE LOADING */
    const routeIsLoading = routeLoadingState({
        fetched: [childListIsFetched],
        loading: [childListIsLoading],
        refetching: [childListIsRefetching && childListIsStale],
    });

    /* ROUTE SETTERS */
    useEffect(() => {
        const routeData = {
            title: "MOJE DZIECKO",
        };

        if (routeMatches) {
            setRouteIsLoading(routeIsLoading);
            setRouteData(routeData);
        }
    }, [routeIsLoading, routeMatches, setRouteIsLoading, setRouteData]);

    /* MODAL CHILDREN DATA */
    const modalChildrenAcceptData = useRef({});
    const modalChildrenDeclineData = useRef({});

    useEffect(() => {
        if (!routeIsLoading) {
            modalChildrenAcceptData.current = {
                data: [
                    {
                        title: {
                            obj: undefined,
                            isCenter: true,
                        },
                        img: {
                            obj: modalDoodleImage_1,
                            size: "238px",
                        },
                        action: {
                            obj: {
                                text: "Przejdź dalej",
                            },
                        },
                    },
                ],
            };

            modalChildrenDeclineData.current = {
                data: [
                    {
                        title: {
                            obj: undefined,
                        },
                        img: {
                            obj: modalDoodleImage_2,
                            size: "185px",
                        },
                        action: {
                            obj: [
                                {
                                    text: "Tak, chcę usunąć",
                                    important: true,
                                    onClick: undefined,
                                },
                                {
                                    text: "Nie, nie chcę usuwać",
                                    onClick: () => setModalActive(false),
                                },
                            ],
                            key: "childrenDecline",
                        },
                    },
                    {
                        title: {
                            obj: undefined,
                            isCenter: true,
                        },
                        img: {
                            obj: modalDoodleImage_3,
                            size: "219px",
                        },
                        action: {
                            obj: {
                                text: "Przejdź dalej",
                            },
                        },
                    },
                ],
            };
        }
    }, [
        routeIsLoading,
        setModalActive,
        setModalApiLoading,
        setModalApiResponse,
    ]);

    return (
        <>
            <DashboardContainer className="childrens">
                {!routeIsLoading && (
                    <>
                        {childListData.data.childs.map((child) => (
                            <Button
                                key={`child_${child.idUser}`}
                                className="creamy"
                                action="link"
                                thumbnail={child.avatar}
                                onClickFn={() =>
                                    history.push(
                                        `${pathSlice(location.pathname)}/${
                                            child.idUser
                                        }`
                                    )
                                }
                                disableDimm={child.pending ? false : true}
                                isDisabled={child.pending || isActionLoading}
                                choices={child.pending}
                                isChoiceDisabled={isActionLoading}
                                acceptOnClickFn={() => {
                                    modalChildrenAcceptData.current.data[0].title.obj =
                                        (
                                            <p>
                                                Dziecko {child.name}
                                                <br />
                                                zostało dodane
                                            </p>
                                        );

                                    acceptChildMutate({
                                        idChild: child.idUser,
                                    });
                                }}
                                declineOnClickFn={() => {
                                    modalChildrenDeclineData.current.data[0].title.obj =
                                        (
                                            <p>
                                                Czy na pewno chcesz usunąć
                                                <br />
                                                dziecko {child.name}?
                                            </p>
                                        );

                                    modalChildrenDeclineData.current.data[0].action.obj[0].onClick =
                                        () =>
                                            declineChildMutate({
                                                idChild: child.idUser,
                                            });

                                    modalChildrenDeclineData.current.data[1].title.obj =
                                        (
                                            <p>
                                                Dziecko {child.name}
                                                <br />
                                                zostało usunięte
                                            </p>
                                        );

                                    setModalData(
                                        modalChildrenDeclineData.current
                                    );
                                }}
                            >
                                {child.name}
                            </Button>
                        ))}
                        <Button
                            className="creamy"
                            action="add"
                            disableDimm={true}
                            isDisabled={isActionLoading}
                            onClickFn={() =>
                                history.push(
                                    `${pathPrev(
                                        location.pathname,
                                        1
                                    )}/add-children`
                                )
                            }
                        >
                            Dodaj dziecko
                        </Button>
                    </>
                )}
            </DashboardContainer>
        </>
    );
};

export default ChildrenList;
