import { useState, useEffect, useRef, useContext } from "react";
import { useCookies } from "react-cookie";
import parse from "html-react-parser";

import { ExamContext } from "../context/ExamStore";
import { ScoreContext } from "../context/ScoreStore";

import useComponentTransition from "../hooks/useComponentTransition";
import useClearExamAnswers from "../hooks/useClearExamAnswers";
import useFinishExam from "../hooks/useFinishExam";
import useShareExam from "../hooks/useShareExam";
import useShareMark from "../hooks/useShareMark";

import getRandomInt from "../functions/getRandomInt";

import { examPageScrollTime, examPageScrollTop } from "../utils/ReaderPager";
import ReaderPoints from "../components/ReaderPoints";
import ReaderButton from "../buttons/ReaderButton";
import ReaderButtonGroup from "../utils/ReaderButtonGroup";
import ReaderLoader from "../utils/ReaderLoader";
import ReaderError from "../utils/ReaderError";

const getScoreText = (scopes, score, getSecondText) => {
    if (!scopes) return "";

    const numberOfScopes = parseInt(scopes.numberOfScopes);

    if (numberOfScopes === 3) {
        if (score < 40) {
            return getSecondText
                ? scopes.scopes[0].text2
                : scopes.scopes[0].text;
        } else if (score >= 40 && score < 80) {
            return getSecondText
                ? scopes.scopes[1].text2
                : scopes.scopes[1].text;
        } else if (score >= 80) {
            return getSecondText
                ? scopes.scopes[2].text2
                : scopes.scopes[2].text;
        }
    } else if (numberOfScopes === 2) {
        if (score < 50) {
            return getSecondText
                ? scopes.scopes[0].text2
                : scopes.scopes[0].text;
        } else if (score >= 50) {
            return getSecondText
                ? scopes.scopes[1].text2
                : scopes.scopes[1].text;
        }
    } else if (numberOfScopes === 1) {
        return getSecondText ? scopes.scopes[0].text2 : scopes.scopes[0].text;
    } else {
        return "";
    }
};

const getScoreLoadingText = (type, idRank) => {
    switch (type) {
        case "correct":
            return "Trwa czyszczenie udzielonych odpowiedzi.";
        case "answers":
            return "Trwa generowanie i porównywanie prawidłowych odpowiedzi.";
        case "finish":
            return (
                "Trwa kończenie testu" +
                (idRank !== 3 ? " i przechodzenie na kolejny rozdział" : "") +
                "."
            );
        case "share":
            return "Trwa udostępnianie wyniku i przechodzenie na kolejny rozdział.";
        case "close":
            return "Trwa przechodzenie na kolejny rozdział.";
        default:
            return "Trwa wczytywanie.";
    }
};

const getScoreErrorText = (type) => {
    switch (type) {
        case "correct":
            return "Wystąpił błąd podczas czyszczenia udzielonych odpowiedzi.";
        case "answers":
            return "Wystąpił błąd podczas generowania i porównywania prawidłowych odpowiedzi.";
        case "finish":
            return "Wystąpił błąd podczas kończenia testu.";
        case "share":
            return "Wystąpił błąd podczas udostępniania wyniku.";
        case "close":
            return "Wystąpił błąd podczas przechodzenia na kolejny rozdział.";
        default:
            return "Wystąpił błąd.";
    }
};

const hideExamScore = (callback) => {
    examPageScrollTop(
        examPageScrollTime,
        typeof callback === "function"
            ? setTimeout(() => {
                  callback();
              }, getRandomInt(1000, 2000))
            : undefined
    );
};

const ReaderScore = ({
    title,
    pages,
    pager,
    chapterRefetch,
    shutdown,
    setIsExamUpdating,
    isChapterLoading,
    isChapterRefetching,
    isChapterSuccess,
}) => {
    const { show, hide, isMounted, entering, entered, exited } =
        useComponentTransition(300);

    const { page, setPage, pagesTotal } = pages;
    const { isPagerDisabled } = pager;
    const { isBookShutdown, setIsBookShutdown, setFinishBookShutdown } =
        shutdown;

    const { setShareMarkStore, removeExamPagesStore } = useContext(ExamContext);
    const {
        idChapter,
        examTotalPages,
        setExamScoreMount,
        setIsExamScoreInit,
        isExamScoreInit,
        isExamScoreLoading,
        setIsExamScoreLoading,
        isExamAnswersStale,
        setIsExamAnswersStale,
        examResultData,
        examResultRemove,
        setExamPage,
        setExamProgress,
        isAnswered,
    } = useContext(ScoreContext);

    /* COOKIE HOOK */
    const cookies = useCookies(["idRank"])[0];

    /* EXAM ANSWERS CLEAR HOOK */
    const {
        examAnswersClear,
        isExamAnswersClearSuccess,
        isExamAnswersClearError,
    } = useClearExamAnswers(idChapter);

    /* EXAM RESULT FINISH HOOK */
    const { examFinish, isExamFinishSuccess, isExamFinishError } =
        useFinishExam(idChapter);

    /* EXAM RESULT SHARE HOOK */
    const { examShare, isExamShareSuccess, isExamShareError } =
        useShareExam(idChapter);

    /* EXAM RESULT SHARE MARK HOOK */
    const { shareMark, isShareMarkSuccess, isShareMarkError } = useShareMark();

    /* EXAM SCORE REQUEST DEFAULTS */
    const [examScoreRequest, setExamScoreRequest] = useState(null);
    const [examScoreError, setExamScoreError] = useState(null);
    const [examScoreErrorCount, setExamScoreErrorCount] = useState(0);

    const [isExamScoreFinishPending, setIsExamScoreFinishPending] =
        useState(false);
    const [isExamScoreFinishDone, setIsExamScoreFinishDone] = useState(false);

    /* EXAM SCORE CORRECT REQUEST */
    const examScoreCorrectRequest = () => {
        setExamScoreRequest("correct");

        examAnswersClear();
    };

    useEffect(() => {
        if (examScoreRequest === "correct" && isExamAnswersClearSuccess) {
            if (examTotalPages > 1) {
                setExamProgress(1);
                setExamPage(1);

                hideExamScore(() => setIsExamScoreInit(false));
            } else {
                setIsExamAnswersStale(true);
            }
        }
    }, [
        examScoreRequest,
        isExamAnswersClearSuccess,
        examTotalPages,
        setExamPage,
        setExamProgress,
        setIsExamScoreInit,
        setIsExamAnswersStale,
    ]);

    /* EXAM CORRECT REQUEST - SINGLE PAGE FINISH HANDLER */
    useEffect(() => {
        if (examScoreRequest === "correct" && isExamAnswersStale) {
            setIsExamAnswersStale(false);

            isPagerDisabled.current = false;

            hideExamScore(() => setIsExamScoreInit(false));
        }
    }, [
        examScoreRequest,
        isExamAnswersStale,
        isPagerDisabled,
        setIsExamAnswersStale,
        setIsExamScoreInit,
    ]);

    /* EXAM CORRECT REQUEST - ERROR HANDLER */
    useEffect(() => {
        if (examScoreRequest === "correct" && isExamAnswersClearError) {
            setExamScoreError("correct");
        }
    }, [examScoreRequest, isExamAnswersClearError]);

    /* MOUNT SCORE COMPONENT */
    useEffect(() => {
        if (!isMounted && isExamScoreInit) {
            if (isAnswered) {
                setIsExamScoreFinishDone(true);
            }

            show();
        } else if (isMounted && !isExamScoreInit) {
            hide();
        }
    }, [isMounted, isExamScoreInit, isAnswered, show, hide]);

    /* DISABLE SCORE LOADING STATE WHEN COMPONENT ENTERED */
    useEffect(() => {
        if (entered && isExamScoreLoading) {
            setIsExamScoreLoading(false);
        }
    }, [entered, isExamScoreLoading, setIsExamScoreLoading]);

    /* EXAM SCORE FINISH REQUEST */
    const examScoreFinishRequest = () => {
        setExamScoreRequest("finish");

        examFinish();
    };

    useEffect(() => {
        if (
            examScoreRequest === "finish" &&
            isExamFinishSuccess &&
            !isExamScoreClosing.current
        ) {
            if (parseInt(cookies.idRank) !== 3) {
                chapterRefetch();

                setTimeout(() => {
                    isExamScoreClosing.current = true;

                    setPage((prevPage) => prevPage + 1);
                }, getRandomInt(100, 300));
            } else {
                setIsExamScoreFinishPending(true);

                setTimeout(() => {
                    setIsExamScoreFinishDone(true);
                }, getRandomInt(600, 1200));
            }
        }
    }, [
        examScoreRequest,
        isExamFinishSuccess,
        chapterRefetch,
        setPage,
        cookies.idRank,
    ]);

    useEffect(() => {
        if (isExamScoreFinishPending && isExamScoreFinishDone) {
            setIsExamScoreFinishPending(false);

            setTimeout(() => {
                setExamScoreRequest(null);
            }, 300);
        }
    }, [isExamScoreFinishPending, isExamScoreFinishDone]);

    useEffect(() => {
        if (examScoreRequest === "finish" && isExamFinishError) {
            setExamScoreError("finish");
        }
    }, [examScoreRequest, isExamFinishError]);

    /* EXAM SCORE CLOSE REQUEST */
    const isExamScoreClosing = useRef(false);

    const examScoreCloseRequest = () => {
        setExamScoreRequest("close");

        shareMark(examResultData.answers.idExamAnswer);
    };

    useEffect(() => {
        if (examScoreRequest === "close" && isShareMarkError) {
            setExamScoreError("close");
        }
    }, [examScoreRequest, isShareMarkError]);

    useEffect(() => {
        if (
            examScoreRequest === "close" &&
            isShareMarkSuccess &&
            !isExamScoreClosing.current
        ) {
            setShareMarkStore(idChapter);

            chapterRefetch();

            setTimeout(() => {
                isExamScoreClosing.current = true;

                setPage((prevPage) => prevPage + 1);
            }, getRandomInt(100, 300));
        }
    }, [
        chapterRefetch,
        examScoreRequest,
        setShareMarkStore,
        idChapter,
        isShareMarkSuccess,
        setPage,
    ]);

    /* EXAM SCORE SHARE REQUEST */
    const examScoreShareRequest = () => {
        setExamScoreRequest("share");

        examShare();
    };

    useEffect(() => {
        if (examScoreRequest === "share" && isExamShareSuccess) {
            shareMark(examResultData.answers.idExamAnswer);
        }
    }, [examScoreRequest, isExamShareSuccess, shareMark, examResultData]);

    useEffect(() => {
        if (
            (examScoreRequest === "share" && isExamShareError) ||
            (examScoreRequest === "share" && isShareMarkError)
        ) {
            setExamScoreError("share");
        }
    }, [examScoreRequest, isExamShareError, isShareMarkError]);

    useEffect(() => {
        if (
            examScoreRequest === "share" &&
            isShareMarkSuccess &&
            !isExamScoreClosing.current
        ) {
            setShareMarkStore(idChapter);

            chapterRefetch();

            setTimeout(() => {
                isExamScoreClosing.current = true;

                setPage((prevPage) => prevPage + 1);
            }, getRandomInt(100, 300));
        }
    }, [
        examScoreRequest,
        isShareMarkSuccess,
        setShareMarkStore,
        idChapter,
        chapterRefetch,
        setPage,
    ]);

    useEffect(() => {
        if (
            ((examScoreRequest === "close" ||
                examScoreRequest === "share" ||
                examScoreRequest === "finish") &&
                !isChapterLoading &&
                !isChapterRefetching &&
                isChapterSuccess &&
                isExamScoreClosing.current) ||
            ((examScoreRequest === "close" ||
                examScoreRequest === "share" ||
                examScoreRequest === "finish") &&
                isExamScoreClosing.current &&
                page > pagesTotal)
        ) {
            setTimeout(() => {
                setIsExamScoreInit(false);
            }, getRandomInt(1000, 2000));
        }
    }, [
        page,
        pagesTotal,
        examScoreRequest,
        isChapterLoading,
        isChapterRefetching,
        isChapterSuccess,
        setIsExamScoreInit,
    ]);

    /* EXAM SCORE ANSWERS REQUEST */
    const isExamScoreAnswersPending = useRef(false);

    const examScoreAnswersRequest = () => {
        setExamScoreRequest("answers");

        examFinish();
    };

    useEffect(() => {
        if (
            examScoreRequest === "answers" &&
            isExamFinishSuccess &&
            !isExamScoreAnswersPending.current
        ) {
            isExamScoreAnswersPending.current = true;

            isPagerDisabled.current = false;

            removeExamPagesStore(idChapter);

            setIsExamUpdating(true);
        }
    }, [
        examScoreRequest,
        isExamFinishSuccess,
        isPagerDisabled,
        removeExamPagesStore,
        idChapter,
        setIsExamUpdating,
    ]);

    useEffect(() => {
        if (examScoreRequest === "answers" && isExamFinishError) {
            setExamScoreError("answers");
        }
    }, [examScoreRequest, isExamFinishError]);

    /* RESET DATA WHEN COMPONENT EXITED */
    useEffect(() => {
        if (!isMounted && exited) {
            setExamScoreMount(null);

            examResultRemove();

            isExamScoreAnswersPending.current = false;
            isExamScoreClosing.current = false;

            setExamScoreRequest(null);
            setExamScoreErrorCount(0);
            setIsExamScoreFinishDone(false);
        }
    }, [isMounted, exited, examResultRemove, setExamScoreMount]);

    /* HANDLE REQUEST ERROR CLICK */
    const handleErrorClick = () => {
        if (examScoreErrorCount >= 2) {
            setIsBookShutdown(true);
        } else {
            setExamScoreRequest(null);
        }
    };

    /* HANDLE REQUEST ERROR EXIT */
    const handleErrorUnmount = () => {
        setExamScoreError(null);

        setExamScoreErrorCount((prevCount) => prevCount + 1);
    };

    /* FINISH BOOK SHUTDOWN */
    useEffect(() => {
        if (isBookShutdown) {
            setTimeout(() => {
                setFinishBookShutdown(true);
            }, 1500);
        }
    }, [isBookShutdown, setFinishBookShutdown]);

    return (
        <>
            {isMounted ? (
                <div
                    className={
                        "ReaderScore" +
                        (entering || entered ? " _is--open" : "") +
                        (examScoreRequest ? " _is--disabled" : "")
                    }
                    data-panel="false"
                >
                    <div className="ReaderScore__Heading">{title}</div>
                    <div
                        className={
                            "ReaderScore__Body" +
                            (isExamScoreFinishPending ? " _is--pending" : "") +
                            (isExamScoreFinishDone ? " _is--done" : "")
                        }
                    >
                        {!isExamScoreFinishDone ? (
                            <>
                                <ReaderPoints
                                    title={
                                        "Dobra robota, " +
                                        examResultData.user.name +
                                        "!"
                                    }
                                    text={parse(
                                        getScoreText(
                                            examResultData.scope?.scope,
                                            examResultData.answers.score_pct
                                        )
                                    )}
                                    numbers={{
                                        current: parseInt(
                                            examResultData.score.userScore
                                        ),
                                        total: examResultData.score.totalScore,
                                        percent: examResultData.score.percent,
                                    }}
                                    scopes={examResultData.scope?.scope}
                                />
                                <ReaderButtonGroup
                                    buttons={[
                                        {
                                            name: "Popraw",
                                            color: "teal",
                                            isDisabled: examScoreRequest,
                                            onClick: examScoreCorrectRequest,
                                        },
                                        {
                                            name: "Sprawdź odpowiedzi i zakończ",
                                            color: "light-blue",
                                            isDisabled: examScoreRequest,
                                            onClick: examScoreAnswersRequest,
                                        },
                                        {
                                            name: "Zakończ",
                                            color: "orange",
                                            isDisabled: examScoreRequest,
                                            onClick: examScoreFinishRequest,
                                        },
                                    ]}
                                    keyName="ReaderScore"
                                />
                            </>
                        ) : (
                            <>
                                <ReaderPoints
                                    title={
                                        examResultData.user.name +
                                        ", Twój wynik:"
                                    }
                                    text={parse(
                                        getScoreText(
                                            examResultData.scope?.scope,
                                            examResultData.answers.score_pct,
                                            true
                                        )
                                    )}
                                    numbers={{
                                        current: parseInt(
                                            examResultData.score.userScore
                                        ),
                                        total: examResultData.score.totalScore,
                                        percent: examResultData.score.percent,
                                    }}
                                    scopes={examResultData.scope?.scope}
                                    isContentSplit={true}
                                />
                                <ReaderButtonGroup
                                    buttons={[
                                        {
                                            name: "Udostępnij wynik teraz",
                                            color: "light-blue",
                                            isDisabled: examScoreRequest,
                                            onClick: examScoreShareRequest,
                                        },
                                        {
                                            name: "Przejdź dalej",
                                            color: "orange",
                                            isDisabled: examScoreRequest,
                                            onClick: examScoreCloseRequest,
                                        },
                                    ]}
                                    keyName="ReaderScore"
                                />
                            </>
                        )}
                    </div>
                    <ReaderScoreRequest
                        data={examScoreRequest}
                        error={examScoreError}
                        isErrorCritical={examScoreErrorCount >= 2}
                        onErrorClick={handleErrorClick}
                        onErrorUnmount={handleErrorUnmount}
                    />
                </div>
            ) : (
                ""
            )}
        </>
    );
};

const ReaderScoreRequest = ({
    data,
    error,
    isErrorCritical,
    onErrorClick,
    onErrorUnmount,
}) => {
    const { show, hide, isMounted, entering, entered, exited } =
        useComponentTransition(300);

    /* COOKIE HOOK */
    const cookies = useCookies(["idRank"])[0];

    /* REQUEST TYPE */
    const requestType = useRef(null);

    /* MOUNT RESULTS MODAL */
    useEffect(() => {
        if (!isMounted && data) {
            requestType.current = data;

            show();
        } else if (isMounted && !data) {
            hide();
        }
    }, [isMounted, data, show, hide]);

    useEffect(() => {
        if (exited && error) {
            onErrorUnmount();
        }
    }, [exited, error, onErrorUnmount]);

    return isMounted ? (
        <div
            className={
                "ReaderScore__Request" +
                (entering || entered ? " _is--open" : "")
            }
        >
            <div className="ReaderScore__RequestCard">
                <div className="ReaderScore__RequestLoader">
                    {!error ? <ReaderLoader /> : <ReaderError />}
                </div>
                <div className="ReaderScore__RequestContent">
                    {!error ? (
                        <>
                            <p>
                                {getScoreLoadingText(
                                    requestType.current,
                                    parseInt(cookies.idRank)
                                )}
                            </p>
                            <p>Prosimy o cierpliwość.</p>
                        </>
                    ) : (
                        <>
                            <p>{getScoreErrorText(error)}</p>
                            <p>Przepraszamy.</p>
                        </>
                    )}
                </div>
                {error && (
                    <div className="ReaderScore__RequestRedirect">
                        <ReaderButton onClick={onErrorClick}>
                            {!isErrorCritical ? "Zamknij" : "Wyjdź"}
                        </ReaderButton>
                    </div>
                )}
            </div>
        </div>
    ) : (
        ""
    );
};

export default ReaderScore;
