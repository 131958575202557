import ReaderLoader from "../utils/ReaderLoader";

const ReaderRedirect = ({
    text,
    color,
    large,
    bordered,
    inverted,
    isDisabled,
    isDimmed,
    isLoading,
    onClick,
}) => {
    return (
        <>
            <button
                className={
                    "ReaderRedirect" +
                    (inverted ? " _direction--inverted" : "") +
                    (color === "secondary" ? " _color--secondary" : "") +
                    (large ? " _size--large" : "") +
                    (isDimmed ? " _is--dimmed" : "") +
                    (isLoading ? " _is--loading" : "")
                }
                type="button"
                data-panel="false"
                disabled={isDisabled}
                onClick={onClick}
            >
                {text && !large ? (
                    <span className="ReaderRedirect__Text">{text}</span>
                ) : (
                    ""
                )}
                <span
                    className={
                        "ReaderRedirect__Arrow" +
                        (!text && bordered ? " _bordered" : "")
                    }
                >
                    {isLoading ? (
                        <ReaderLoader
                            color={color}
                            size={large ? "medium" : "small"}
                        />
                    ) : (
                        <svg
                            className="ReaderRedirect__Svg"
                            xmlns="http://www.w3.org/2000/svg"
                            width="35.367"
                            height="35.367"
                            viewBox="0 0 35.367 35.367"
                        >
                            <g
                                id="button-rignt-01"
                                transform="translate(-48.379 -92.472)"
                            >
                                <path
                                    id="Path_1603"
                                    dataname="Path 1603"
                                    d="M17.684,0A17.684,17.684,0,1,1,0,17.684,17.684,17.684,0,0,1,17.684,0Z"
                                    transform="translate(48.379 92.472)"
                                    fill="none"
                                />
                                <path
                                    id="Path_50"
                                    dataname="Path 50"
                                    d="M0,0,9.523,9.524,0,19.047"
                                    transform="translate(62.328 100.632)"
                                    fill="none"
                                    stroke="#fff"
                                    strokeLinecap="round"
                                    strokeWidth="3"
                                />
                            </g>
                        </svg>
                    )}
                </span>
            </button>
        </>
    );
};

export default ReaderRedirect;
