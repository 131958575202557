const getChapter = (chapters, page, getId) => {
    let chapterObject = undefined;

    if (chapters) {
        for (const chapter of chapters) {
            if (parseInt(chapter.totalOrder) === parseInt(page)) {
                chapterObject = chapter;
            } else if (chapter.child?.length > 0) {
                for (const subChapter of chapter.child) {
                    if (parseInt(subChapter.totalOrder) === parseInt(page)) {
                        chapterObject = subChapter;
                    }
                }
            }
        }
    }

    return chapterObject
        ? getId
            ? chapterObject.idChapter
            : chapterObject
        : undefined;
};

export default getChapter;
