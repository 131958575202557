import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useCookies } from "react-cookie";

import useAccount from "../../../hooks/useAccount";
import useLearningProgressDetails from "../../../hooks/useLearningProgressDetails";

import { getCookieTime } from "../../../App";
import routeLoadingState from "../../../functions/routeLoadingState";

import Frame from "../../../components/containers/dashboard/Frame";

import LearningCard from "../../../components/containers/dashboard/learning-progress/LearningCard";
import LearningPanel from "../../../components/containers/dashboard/learning-progress/LearningPanel";

const ChildrenLearningProgressDetails = ({ routeMatches, routeSetters }) => {
    const { setRouteData, setRouteIsLoading } = routeSetters;

    const history = useHistory();
    const params = useParams();
    const setCookie = useCookies(["chapterRedirect"])[1];

    const {
        accountData,
        isAccountFetched,
        isAccountRefetching,
        isAccountLoading,
    } = useAccount();
    const {
        learningDetailsData,
        isLearningDetailsFetched,
        isLearningDetailsRefetching,
        isLearningDetailsLoading,
        removeLearningDetails,
    } = useLearningProgressDetails(
        accountData?.data.user.idUser,
        params?.idMultibook
    );

    const handleExamRedirect = (idMultibook, idChapter) => {
        setCookie(
            "chapterRedirect",
            { idMultibook, idChapter },
            {
                path: "/",
                expires: getCookieTime(),
            }
        );

        history.push(`/multibook-${idMultibook}`);
    };

    /* ROUTE LOADING */
    const isRouteLoading = routeLoadingState({
        fetched: [isAccountFetched, isLearningDetailsFetched],
        loading: [isAccountLoading, isLearningDetailsRefetching],
        refetching: [isAccountRefetching, isLearningDetailsLoading],
    });

    /* ROUTE SETTERS */
    useEffect(() => {
        const routeData = {
            title: "SZCZEGÓŁY POSTĘPÓW NAUKI",
        };

        if (routeMatches) {
            setRouteIsLoading(isRouteLoading);
            setRouteData(routeData);
        }
    }, [routeMatches, setRouteData, isRouteLoading, setRouteIsLoading]);

    /* REMOVE LEARNING DETAILS DATA ON UNMOUNT */
    useEffect(() => {
        return () => {
            removeLearningDetails();
        };
    }, [removeLearningDetails]);

    return (
        <>
            <Frame className="_bg--creamy" width={370}>
                {!isRouteLoading && (
                    <>
                        {learningDetailsData.data[0].multibook.length > 0 && (
                            <LearningCard
                                type={
                                    learningDetailsData.data[0].multibook.type
                                }
                                title={
                                    learningDetailsData.data[0].multibook.title
                                }
                                content={
                                    learningDetailsData.data[0].multibook
                                        .subtitle
                                }
                                thumbnail={
                                    learningDetailsData.data[0].multibook.cover
                                }
                                progressBar={{
                                    chapter: {
                                        isDone:
                                            learningDetailsData.data[0]
                                                .multibook.progress >=
                                            learningDetailsData.data[0]
                                                .multibook.total,
                                        caption:
                                            "Rozdział " +
                                            learningDetailsData.data[0]
                                                .multibook.progress +
                                            " z " +
                                            learningDetailsData.data[0]
                                                .multibook.total,
                                        percent:
                                            (learningDetailsData.data[0]
                                                .multibook.progress *
                                                100) /
                                            learningDetailsData.data[0]
                                                .multibook.total,
                                    },
                                }}
                                isDetailed={true}
                                onClick={() =>
                                    history.push(
                                        `/multibook-${learningDetailsData.data[0].multibook.idMultibook}`
                                    )
                                }
                            />
                        )}
                        {learningDetailsData.data[0].chapters.length > 0 &&
                            learningDetailsData.data[0].chapters.map(
                                (element) => {
                                    return (
                                        <LearningPanel
                                            key={
                                                "LearningCard_" +
                                                element[0].idMultibook +
                                                "--exam-" +
                                                element[0].idChapter
                                            }
                                            data={element}
                                            onClick={() =>
                                                handleExamRedirect(
                                                    element[0].idMultibook,
                                                    element[0].idChapter
                                                )
                                            }
                                        />
                                    );
                                }
                            )}
                    </>
                )}
            </Frame>
        </>
    );
};

export default ChildrenLearningProgressDetails;
