import ReaderButton from "../buttons/ReaderButton";

const ReaderButtonGroup = ({ buttons, keyName }) => {
    return (
        <>
            {buttons?.length > 0 ? (
                <div className="ReaderButtonGroup">
                    {buttons.map((object, index) => {
                        return (
                            <ReaderButton
                                key={keyName + "_" + index}
                                color={object.color}
                                disabled={object.isDisabled}
                                onClick={object.onClick}
                            >
                                {object.name}
                            </ReaderButton>
                        );
                    })}
                </div>
            ) : (
                ""
            )}
        </>
    );
};

export default ReaderButtonGroup;
