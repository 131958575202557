import { useHistory } from "react-router-dom";

import Button from "../../buttons/Button";

const SubscriptionError = ({ rank }) => {
    const history = useHistory();

    return (
        <div className="SubscriptionError">
            <div className="SubscriptionError__Message">
                {rank === 2 ? (
                    <>
                        Abonament Twojego dziecka
                        <br />
                        jest nieaktywny. Wybierz abonament,
                        <br />
                        aby w pełni cieszyć się światem How2 Edu.
                    </>
                ) : (
                    <>
                        Twój abonament jest nieaktywny.
                        <br />
                        Poinformuj o tym rodzica, abyś w pełni
                        <br />
                        mógł cieszyć się światem How2 Edu.
                    </>
                )}
            </div>
            <Button
                classNameWrapper="SubscriptionError__Button"
                className="medium"
                onClickFn={() => history.goBack()}
            >
                Wróć
            </Button>
        </div>
    );
};

export default SubscriptionError;
