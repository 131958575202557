import Button from "../../../buttons/Button";

const LearningEmpty = ({ text, onClick }) => {
    return (
        <div className="LearningEmpty">
            <div className="LearningEmpty__Text">{text}</div>
            <Button
                classNameWrapper="LearningEmpty__Button"
                className="medium"
                onClickFn={onClick}
            >
                Wróć
            </Button>
        </div>
    );
};

export default LearningEmpty;
