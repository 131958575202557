import { useRef, useEffect } from "react";

const InputSelect = ({
    value,
    placeholder,
    query,
    modalLarge,
    isDisabled,
    onChange,
    onClear,
    modalSetters,
}) => {
    const { setModalData, setModalApiResponse, setModalApiLoading } =
        modalSetters;

    /* MODAL SCHOOL DATA */
    const modalData = useRef({});

    useEffect(() => {
        modalData.current = {
            select: true,
            close: true,
            data: [
                {
                    className: modalLarge ? "_font--large" : "",
                    query: query,
                    actionDispatch: {
                        dispatch: onChange,
                    },
                },
            ],
        };
    }, [setModalApiLoading, setModalApiResponse, value, query]);

    return (
        <>
            <div className="InputSelect">
                <button
                    className={
                        "InputSelect__Button" +
                        (value?.[query.dataKey.id] ? " _selected" : "")
                    }
                    type="button"
                    disabled={isDisabled}
                    onClick={() => setModalData(modalData.current)}
                >
                    {value?.[query.dataKey.name] ? (
                        value[query.dataKey.name]
                    ) : (
                        <>
                            {placeholder}
                            <span className="InputSelect__Add">
                                <span className="InputSelect__Icon"></span>
                            </span>
                        </>
                    )}
                </button>
                {value?.[query.dataKey.id] ? (
                    <button
                        className="InputSelect__Clear"
                        disabled={isDisabled}
                        onClick={() =>
                            onClear({
                                [query.dataKey.id]: value?.[query.dataKey.id],
                                [query.dataKey.name]:
                                    value?.[query.dataKey.name],
                            })
                        }
                    ></button>
                ) : (
                    ""
                )}
            </div>
        </>
    );
};

export default InputSelect;
