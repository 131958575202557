import { useState, useRef, useEffect, createContext } from "react";

import useSaveExamAnswers from "../hooks/useSaveExamAnswers";
import useGetExamResult from "../hooks/useGetExamResult";

export const ScoreContext = createContext();

const ScoreProvider = ({ children }) => {
    /* EXAM SCORE FLAGS */
    const isExamScoreMounted = useRef(false);
    const isExamScoreSaving = useRef(false);
    const isExamScoreFinished = useRef(false);

    /* EXAM SCORE DEFAULTS */
    const [examScoreMount, setExamScoreMount] = useState(null);
    const [isExamScoreInit, setIsExamScoreInit] = useState(false);
    const [isExamScoreLoading, setIsExamScoreLoading] = useState(false);

    /* EXAM ANSWERS CLEAR - FOR SINGLE PAGE EXAM */
    const [isExamAnswersStale, setIsExamAnswersStale] = useState(false);

    /* EXAM ANSWERS SAVE HOOK */
    const { examAnswersSave, isExamAnswersSaveSuccess } = useSaveExamAnswers(
        examScoreMount ? examScoreMount.idChapter : undefined
    );

    /* EXAM RESULT HOOK */
    const {
        examResultData,
        examResultRefetch,
        examResultRemove,
        isExamResultLoading,
    } = useGetExamResult(examScoreMount ? examScoreMount.idChapter : undefined);

    /* SET SCORE LOADING ON MOUNT */
    useEffect(() => {
        if (examScoreMount && !isExamScoreMounted.current) {
            isExamScoreMounted.current = true;

            setIsExamScoreLoading(true);
        }
    }, [examScoreMount]);

    /* SAVE EXAM ANSWERS */
    useEffect(() => {
        if (
            isExamScoreLoading &&
            !examScoreMount?.isAnswered &&
            !isExamScoreSaving.current
        ) {
            isExamScoreSaving.current = true;

            examAnswersSave();
        }
    }, [isExamScoreLoading, examScoreMount?.isAnswered, examAnswersSave]);

    /* GET EXAM RESULT */
    useEffect(() => {
        if (
            examScoreMount &&
            ((isExamAnswersSaveSuccess && isExamScoreSaving.current) ||
                (isExamScoreLoading && examScoreMount.isAnswered))
        ) {
            examResultRefetch();
        }
    }, [
        examScoreMount,
        isExamAnswersSaveSuccess,
        isExamScoreLoading,
        examResultRefetch,
    ]);

    /* INIT EXAM SCORE AFTER FETCHING DATA */
    useEffect(() => {
        if (
            examResultData &&
            !isExamResultLoading &&
            !isExamScoreFinished.current
        ) {
            isExamScoreFinished.current = true;

            setIsExamScoreInit(true);
        }
    }, [examResultData, isExamResultLoading]);

    /* CLEAR DATA ON UNMOUNT */
    useEffect(() => {
        if (
            !examScoreMount &&
            isExamScoreMounted.current &&
            isExamScoreSaving.current &&
            isExamScoreFinished.current
        ) {
            isExamScoreMounted.current = false;
            isExamScoreSaving.current = false;
            isExamScoreFinished.current = false;
        }
    }, [examScoreMount]);

    return (
        <ScoreContext.Provider
            value={{
                idChapter: examScoreMount
                    ? examScoreMount.idChapter
                    : undefined,
                examTotalPages: examScoreMount
                    ? examScoreMount.examTotalPages
                    : undefined,
                examScoreMount,
                setExamScoreMount,
                isExamScoreInit,
                setIsExamScoreInit,
                isExamScoreLoading,
                setIsExamScoreLoading,
                isExamAnswersStale,
                setIsExamAnswersStale,
                examResultData,
                examResultRemove,
                setExamPage: examScoreMount
                    ? examScoreMount.setExamPage
                    : undefined,
                setExamProgress: examScoreMount
                    ? examScoreMount.setExamProgress
                    : undefined,
                isAnswered: examScoreMount
                    ? examScoreMount.isAnswered
                    : undefined,
            }}
        >
            {children}
        </ScoreContext.Provider>
    );
};

export default ScoreProvider;
