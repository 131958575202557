import { useState, useRef, useEffect } from "react";
import { useCookies } from "react-cookie";

const ModalSearch = ({
    backendSearch,
    value,
    debounce,
    disabled,
    reset,
    queryKey,
    onChange,
    onSearch,
}) => {
    const [cookies] = useCookies();

    const [searchValue, setSearchValue] = useState(
        cookies[queryKey + "_cookie"] ? cookies[queryKey + "_cookie"] : ""
    );
    const searchDebounce = useRef(null);
    const searchDebounceFlag = useRef(true);

    /* SEARCH PROGRESS BAR */
    const searchProgressInterval = useRef(null);
    const searchProgressFadeTimeout = useRef(null);
    const searchProgressResetTimeout = useRef(null);

    const [searchProgress, setSearchProgress] = useState(false);
    const searchBarRef = useRef(null);
    const searchProgressValue = useRef(0);
    const searchProgressSteps = debounce
        ? 100 / ((debounce - 200) / 100)
        : 100 / ((1500 - 200) / 100);

    /* BACKEND SEARCH RESET */
    useEffect(() => {
        if (reset) {
            setSearchProgress(false);

            searchDebounceFlag.current = true;

            setSearchValue("");
        }
    }, [reset]);

    /* BACKEND SEARCH HANDLER */
    useEffect(() => {
        if (backendSearch) {
            if (typeof onChange === "function") {
                onChange(searchValue);
            }

            clearTimeout(searchDebounce.current);
            clearInterval(searchProgressInterval.current);

            if (!searchDebounceFlag.current && !disabled) {
                setSearchProgress(true);

                searchProgressValue.current = 0;
                searchBarRef.current.style.width = 0;

                searchProgressInterval.current = setInterval(() => {
                    if (searchProgressValue.current < 100) {
                        searchProgressValue.current += searchProgressSteps;

                        searchBarRef.current.style.width =
                            searchProgressValue.current + "%";
                    } else {
                        clearInterval(searchProgressInterval.current);

                        backendSearchProgressFinish();
                    }
                }, 100);

                /* DEBOUNCE AND EXECUTE AFTER TIMEOUT */
                searchDebounce.current = setTimeout(
                    () => {
                        onSearch();
                    },
                    debounce ? debounce : 1500
                );
            } else if (searchValue.length > 1) {
                searchDebounceFlag.current = false;
            }
        }
    }, [
        backendSearch,
        debounce,
        searchValue,
        searchProgressSteps,
        disabled,
        onChange,
        onSearch,
    ]);

    /* ENTER KEY HANDLER */
    const onEnterKeyHandler = (e) => {
        const enterKey = e.charCode === 13 || e.keyCode === 13;

        if (enterKey) {
            clearTimeout(searchDebounce.current);
            clearInterval(searchProgressInterval.current);

            if (!disabled) {
                backendSearchProgressFinish();

                onSearch();
            }
        }
    };

    /* BACKEND SEARCH BUTTON HANDLER */
    const backendSearchButtonHandler = () => {
        clearTimeout(searchDebounce.current);
        clearInterval(searchProgressInterval.current);

        if (!disabled) {
            backendSearchProgressFinish();

            onSearch();
        }
    };

    /* BACKEND SEARCH PROGRESS FINISH */
    const backendSearchProgressFinish = () => {
        searchBarRef.current.style.width = "100%";

        clearTimeout(searchProgressFadeTimeout.current);
        clearTimeout(searchProgressResetTimeout.current);

        searchProgressFadeTimeout.current = setTimeout(() => {
            setSearchProgress(false);

            searchProgressResetTimeout.current = setTimeout(() => {
                searchBarRef.current.style.width = 0;
            }, 200);
        }, 200);
    };

    return (
        <>
            <div className="ModalSearch">
                <div
                    className={
                        "ModalSearch__Progress" +
                        (searchProgress ? " _visible" : "")
                    }
                >
                    <div className="ModalSearch__Bar" ref={searchBarRef}></div>
                </div>
                <input
                    className="ModalSearch__Input"
                    type="text"
                    value={backendSearch ? searchValue : value}
                    placeholder="Wyszukaj"
                    onChange={
                        backendSearch
                            ? (e) => setSearchValue(e.currentTarget.value)
                            : (e) => onChange(e.currentTarget.value)
                    }
                    onKeyPress={
                        backendSearch ? (e) => onEnterKeyHandler(e) : undefined
                    }
                />
                <button
                    className="ModalSearch__Button"
                    type="button"
                    disabled={backendSearch ? false : true}
                    onClick={
                        backendSearch
                            ? () => backendSearchButtonHandler()
                            : undefined
                    }
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        className="ModalSearch__Svg"
                        width="20.069"
                        height="19.364"
                        viewBox="0 0 20.069 19.364"
                    >
                        <defs>
                            <clipPath id="modalSearchIconClipPath">
                                <rect
                                    id="modalSearchIconRect_1"
                                    dataname="modalSearchIconRect 1"
                                    width="20.069"
                                    height="19.364"
                                    fill="none"
                                />
                            </clipPath>
                        </defs>
                        <g
                            id="modalSearchIconGroup_1"
                            dataname="modalSearchIconGroup 1"
                            clipPath="url(#modalSearchIconClipPath)"
                        >
                            <path
                                id="modalSearchIconPath_1"
                                dataname="modalSearchIconPath 1"
                                d="M7.906,15.257A7.78,7.78,0,0,1,0,7.628,7.78,7.78,0,0,1,7.906,0a7.78,7.78,0,0,1,7.906,7.628,7.78,7.78,0,0,1-7.906,7.628M7.906.8A6.961,6.961,0,0,0,.832,7.628a6.961,6.961,0,0,0,7.074,6.825A6.961,6.961,0,0,0,14.98,7.628,6.961,6.961,0,0,0,7.906.8"
                                transform="translate(0 0)"
                                fill="#414043"
                            />
                            <path
                                id="modalSearchIconPath_2"
                                dataname="modalSearchIconPath 2"
                                d="M37.6,37.756a.423.423,0,0,1-.294-.118l-6.45-6.223a.391.391,0,0,1,0-.568.426.426,0,0,1,.588,0l6.45,6.223a.391.391,0,0,1,0,.568.423.423,0,0,1-.294.118"
                                transform="translate(-17.943 -18.392)"
                                fill="#414043"
                            />
                        </g>
                    </svg>
                </button>
            </div>
        </>
    );
};

export default ModalSearch;
