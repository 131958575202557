const Loader = ({ className, type }) => {
    const loaderType = (type) => {
        switch (type) {
            case "ring":
                return (
                    <span
                        className={
                            "Loader__Ring" + (className ? " " + className : "")
                        }
                    >
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </span>
                );
            case "ripple":
                return (
                    <span
                        className={
                            "Loader__Ripple" +
                            (className ? " " + className : "")
                        }
                    >
                        <span></span>
                        <span></span>
                    </span>
                );
            default:
                return (
                    <span
                        className={
                            "Loader__Ellipsis" +
                            (className ? " " + className : "")
                        }
                    >
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </span>
                );
        }
    };

    return loaderType(type);
};

export default Loader;
