import React, { useEffect, useRef } from "react";

import Loader from "../containers/Loader";
import Lottie from "lottie-react";
const ModalCardTitle = ({ data }) => {
  return <div className="ModalCard__Title">{data.title.obj}</div>;
};

const actionFinishHandler = (onFinish, actionDispatch) => {
  onFinish();

  if (typeof actionDispatch === "function") {
    setTimeout(() => {
      actionDispatch();
    }, 300);
  }
};

const ModalCardThumbnail = ({ data }) => {
  return data.img.lottie ? (
    <div
      className="ModalCard__Thumbnail"
      style={{ height: data.img.size, marginTop: data.img.marginTop }}
    >
      <Lottie {...data.img.lottieSettings} height={210} width={325} />
    </div>
  ) : (
    <div
      className="ModalCard__Thumbnail"
      style={{ height: data.img.size, marginTop: data.img.marginTop }}
    >
      <img
        className="ModalCard__Img"
        src={data.img.obj}
        alt=""
        style={{ maxHeight: data.img.size }}
      />
    </div>
  );
};

const ModalCardButton = ({
  props,
  autoDispatchTimeout,
  isLast,
  isLoading,
  onFinish,
}) => {
  return (
    <button
      style={props.style}
      className={"ModalCard__Button" + (props.important ? " _color--red" : "")}
      type="button"
      disabled={isLast ? false : isLoading}
      onClick={
        isLast
          ? () => {
              clearTimeout(autoDispatchTimeout.current);

              actionFinishHandler(onFinish, props.onClick);
            }
          : props.onClick
      }
    >
      {isLoading && props.important ? <Loader type="ellipsis" /> : props.text}
    </button>
  );
};
const ModalCardWrapper = ({ condition, wrapper, children }) => {
  return condition ? wrapper(children) : children;
};

const ModalCard = ({ data, isLast, isLoading, onFinish }) => {
  const autoDispatchTimeout = useRef(null);
  const isAutoDispatchPending = useRef(false);

  /* AUTO DISPATCH FUNCTION */
  useEffect(() => {
    if (data.action.auto && !isAutoDispatchPending.current) {
      isAutoDispatchPending.current = true;

      autoDispatchTimeout.current = setTimeout(
        () => {
          if (Array.isArray(data.action.obj)) {
            actionFinishHandler(onFinish, data.action.obj[0].onClick);
          } else {
            actionFinishHandler(onFinish, data.action.obj.onClick);
          }
        },
        data.action.autoTimeout ? data.action.autoTimeout : 2000
      );
    }
  }, [data, onFinish]);

  return (
    <>
      <div
        className={
          "ModalCard" +
          (data.title.isCenter ? " _center" : "") +
          (data.special === "accountDelete" ? " _special--accountDelete" : "")
        }
      >
        <ModalCardWrapper
          condition={data.special === "accountDelete"}
          wrapper={(children) => (
            <div className="ModalCard__Special">{children}</div>
          )}
        >
          {data.title.isCenter ? (
            <>
              <ModalCardThumbnail data={data} />
              <ModalCardTitle data={data} />
            </>
          ) : (
            <>
              <ModalCardTitle data={data} />
              <ModalCardThumbnail data={data} />
            </>
          )}
          {data.component ? data.component : null}
        </ModalCardWrapper>

        <div className="ModalCard__Footer">
          {Array.isArray(data.action.obj) ? (
            data.action.obj.map((props, index) => {
              return (
                <ModalCardButton
                  key={data.action.key + "_Button_" + index}
                  props={props}
                  autoDispatchTimeout={autoDispatchTimeout}
                  isLast={isLast}
                  isLoading={isLoading}
                  onFinish={onFinish}
                />
              );
            })
          ) : (
            <ModalCardButton
              props={data.action.obj}
              autoDispatchTimeout={autoDispatchTimeout}
              isLast={isLast}
              isLoading={isLoading}
              onFinish={onFinish}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default React.memo(ModalCard);
