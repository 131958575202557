import { useHistory, useLocation } from "react-router-dom";

import pathPrev from "../../../../functions/pathPrev";

import Button from "../../../buttons/Button";

import doodleImage_1 from "../../../../assets/images/dashboard/modal-doodles/book.svg";

const ShelfEmpty = () => {
    const history = useHistory();
    const location = useLocation();

    return (
        <>
            <div className="ShelfEmpty">
                <div className="ShelfEmpty__Title">
                    Nie masz jeszcze dodanych materiałów.
                </div>
                <div className="ShelfEmpty__Banner">
                    <img
                        className="ShelfEmpty__Img"
                        src={doodleImage_1}
                        alt=""
                    />
                </div>
                <div className="ShelfEmpty__Button">
                    <Button
                        className="medium-2"
                        onClickFn={() =>
                            history.push(
                                `${pathPrev(location.pathname, 2)}/library`
                            )
                        }
                    >
                        Przejdź do biblioteki
                    </Button>
                </div>
            </div>
        </>
    );
};

export default ShelfEmpty;
