import { Fragment } from "react";

import ReaderMath from "../components/ReaderMath";

const parseMathTag = (string) => {
    if (!string || typeof string !== "string") return "";

    const startTag = "[math]";
    const endTag = "[/math]";

    let startTagPosition = string.indexOf(startTag);
    let endTagPosition = string.indexOf(endTag);

    let slicePosition = 0;

    const contentMap = [];

    while (startTagPosition > -1 && endTagPosition > -1) {
        if (string.slice(slicePosition, startTagPosition) !== " ") {
            contentMap.push({
                type: "text",
                string: string.slice(slicePosition, startTagPosition),
            });
        }

        contentMap.push({
            type: "math",
            string: string.slice(
                startTagPosition + startTag.length,
                endTagPosition
            ),
        });

        slicePosition = endTagPosition + endTag.length;

        startTagPosition = string.indexOf(startTag, ++startTagPosition);
        endTagPosition = string.indexOf(endTag, ++endTagPosition);

        if (startTagPosition === -1 && string.slice(slicePosition) !== " ") {
            contentMap.push({
                type: "text",
                string: string.slice(slicePosition),
            });
        }
    }

    const componentsMap = contentMap.map((object, index) => {
        if (object.type === "text") {
            return <Fragment key={index}>{object.string}</Fragment>;
        } else if (object.type === "math") {
            return (
                <ReaderMath key={index} content={object.string} inline={true} />
            );
        } else {
            return "";
        }
    });

    return contentMap.length > 0 ? componentsMap : string;
};

export default parseMathTag;
