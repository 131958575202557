import { useContext, useState, useEffect } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";

import { StoreContext } from "../../../context/Store";

import useActionLoading from "../../../hooks/useActionLoading";

import validator from "../../../functions/validator.js";
import addChildErrorHandler from "../../../functions/errorHandlers/addChildErrorHandler";
import scrollTo from "../../../functions/scrollTo";

import Input from "../../inputs/Input";
import InputMasked from "../../inputs/InputMasked";
import InputSelect from "../../inputs/InputSelect";
import Button from "../../buttons/Button";
import Checkbox from "../../inputs/Checkbox";
import ButtonReadMore from "../../buttons/ButtonReadMore";

import DashboardContainer from "../../containers/dashboard/DashboardContainer";

import SigningFormContainer from "../../containers/signing/SigningFormContainer";
import SigningRulesContainer from "../../containers/signing/SigningRulesContainer";
import ButtonRuleSigning from "../../buttons/signing/ButtonRuleSigning";

const RegisterChildrenForm = ({ routeMatches, routeSetters, modalSetters }) => {
    const { setRouteData } = routeSetters;

    const { isActionLoading, setIsActionLoading } = useActionLoading();

    const history = useHistory();

    const queryClient = useQueryClient();
    const { api } = useContext(StoreContext);

    const { mutate: handleAddChild } = useMutation(
        () =>
            api.post(`/account/addChild`, {
                email: inputValue.email,
                name: inputValue.name,
                surname: inputValue.surname,
                zipCode: inputValue.zipCode,
                phone: inputValue.phone,
                birthdate: inputValue.birthdate,
                idSchool: inputValue.school.idSchool,
                class: inputValue.class,
                agreement_1: valueAgreements.agreement_1,
                agreement_2: valueAgreements.agreement_2,
            }),
        {
            onMutate: () => setIsActionLoading(true),
            onSuccess: () => {
                queryClient.invalidateQueries("childList");
                history.push(`/successful-add-children`, {
                    childName: inputValue.name,
                });
            },
            onError: (error) => {
                addChildErrorHandler(
                    error.response.status,
                    error.response.data.errors,
                    () => {
                        setInputValidator({
                            ...inputValidator,
                            email: {
                                response: "error",
                                errorMessage:
                                    "Konto o podanym adresie e-mail już istnieje",
                            },
                        });
                        scrollTo("#email");
                    }
                );

                setIsActionLoading(false);
            },
        }
    )

    /* BIRTHMAXDATE CURRENT - 1 */
    const birthMaxDate = new Date(
        new Date().setFullYear(new Date().getFullYear() - 1)
    ).getFullYear();

    /* INPUT VALUES */
    const [inputValue, setInputValue] = useState({
        email: "",
        name: "",
        surname: "",
        zipCode: "",
        phone: "",
        birthdate: "",
        school: { idSchool: "", name: "" },
        class: "",
    });

    /* INPUT VALIDATORS */
    const [inputValidator, setInputValidator] = useState({
        email: {
            response: "",
            errorMessage: "",
        },
        name: {
            response: "",
            errorMessage: "",
        },
        surname: {
            response: "",
            errorMessage: "",
        },
        phone: {
            response: "",
            errorMessage: "",
        },
        zipCode: {
            response: "",
            errorMessage: "",
        },
        birthdate: {
            response: "",
            errorMessage: "",
        },
    });

    /* REGISTER AGREEMENTS CHECKBOXES */
    const [valueAgreements, setValueAgreements] = useState({
        agreement_1: false,
        agreement_2: false,
    });

    /* REGISTER AGREEMENTS VALIDATION */
    const [valueAgreementsValidation, setValueAgreementsValidation] = useState({
        agreement_1: false,
    });

    /* EMAIL VALIDATION */
    const emailOnChangeHandler = (e) => {
        if (e.length > 0) {
            validator("email", e)
                ? setInputValidator({
                      ...inputValidator,
                      email: { response: "success", errorMessage: "" },
                  })
                : setInputValidator({
                      ...inputValidator,
                      email: {
                          response: "error",
                          errorMessage: "Nieprawidłowy adres e-mail",
                      },
                  });
        } else {
            setInputValidator({
                ...inputValidator,
                email: {
                    response: "",
                    errorMessage: "Nieprawidłowy adres e-mail",
                },
            });
        }

        setInputValue({
            ...inputValue,
            email: e,
        });
    };

    /* INPUT ON CHANGE HANDLER */
    const inputOnChangeHandler = (e, key, minLength) => {
        setInputValue({ ...inputValue, [key]: e });

        if (minLength) {
            if (minLength <= e.length) {
                setInputValidator({
                    ...inputValidator,
                    [key]: {
                        response: "success",
                        errorMessage: "",
                    },
                });
            } else {
                if (e.length === 0) {
                    setInputValidator({
                        ...inputValidator,
                        [key]: {
                            response: "",
                            errorMessage: "",
                        },
                    });
                } else {
                    setInputValidator({
                        ...inputValidator,
                        [key]: {
                            response: "error",
                            errorMessage: "",
                        },
                    });
                }
            }
        } else {
            setInputValidator({
                ...inputValidator,
                [key]: {
                    response: "success",
                    errorMessage: "",
                },
            });
        }
    };

    /* INPUT MASKED ON CHANGE HANDLER */
    const inputMaskedOnChangeHandler = (e, key) => {
        setInputValue({ ...inputValue, [key]: e.value });

        setInputValidator({
            ...inputValidator,
            [key]: {
                response: e.response,
                errorMessage: "",
            },
        });
    };

    /* ENABLE SUBMIT IF VALIDATION IS CORRECT */
    const formSubmitDisable =
        inputValue.email &&
        inputValidator.email.response === "success" &&
        inputValue.name &&
        inputValidator.name.response === "success" &&
        inputValue.surname &&
        inputValidator.surname.response === "success" &&
        inputValue.zipCode &&
        inputValidator.zipCode.response === "success" &&
        inputValue.phone &&
        inputValue.birthdate &&
        inputValidator.birthdate.response === "success"
            ? false
            : true;

    const registerButtonOnClick = () => {
        if (!valueAgreements.agreement_1) {
            setValueAgreementsValidation({ agreement_1: true });
            scrollTo("#requiredAgreement1");
            return;
        }

        handleAddChild();
    };

    /* ROUTE SETTERS */
    useEffect(() => {
        const routeData = {
            title: "DODAJ DZIECKO",
        };

        if (routeMatches) {
            setRouteData(routeData);
        }
    }, [routeMatches, setRouteData]);

    return (
        <>
            <DashboardContainer className="form">
                <SigningFormContainer classNameWrapper="childrens">
                    <Input
                        id={"email"}
                        key="Input"
                        type="email"
                        placeholder="wpisz e-mail dziecka"
                        isDisabled={isActionLoading}
                        isValid={{
                            response: inputValidator.email.response,
                            text: "Nieprawidłowy adres e-mail",
                        }}
                        onChangeFn={(e) =>
                            emailOnChangeHandler(e.currentTarget.value)
                        }
                    />
                    <Input
                        key="Input"
                        type="text"
                        placeholder="wpisz imię dziecka"
                        isDisabled={isActionLoading}
                        isValid={{
                            response: inputValidator.name.response,
                        }}
                        onChangeFn={(e) =>
                            inputOnChangeHandler(
                                e.currentTarget.value,
                                "name",
                                3
                            )
                        }
                    />
                    <Input
                        key="Input"
                        type="text"
                        placeholder="wpisz nazwisko dziecka"
                        isDisabled={isActionLoading}
                        isValid={{
                            response: inputValidator.surname.response,
                        }}
                        onChangeFn={(e) =>
                            inputOnChangeHandler(
                                e.currentTarget.value,
                                "surname",
                                3
                            )
                        }
                    />
                    <InputMasked
                        key="InputMasked"
                        value={inputValue.zipCode}
                        placeholder="wpisz kod pocztowy"
                        placeholderFocus="00-000"
                        fields={[{ rows: 2 }, { rows: 3 }]}
                        separator="-"
                        isDisabled={isActionLoading}
                        isValid={inputValidator.zipCode.response}
                        onChangeFn={(e) =>
                            inputMaskedOnChangeHandler(e, "zipCode")
                        }
                    />
                    <Input
                        key="Input"
                        type="tel"
                        value={inputValue.phone}
                        maxLength={12}
                        placeholder="podaj numer telefonu dziecka"
                        classNameWrapper={inputValue.phone ? " is-filled" : ""}
                        isDisabled={isActionLoading}
                        isValid={{
                            response: inputValidator.phone.response,
                        }}
                        onChangeFn={(e) =>
                            inputOnChangeHandler(
                                e.currentTarget.value,
                                "phone",
                                9
                            )
                        }
                    />
                    <InputMasked
                        key="InputMasked"
                        value={inputValue.birthdate}
                        placeholder="dodaj datę urodzenia"
                        placeholderFocus="dd-mm-rrrr"
                        fields={[
                            { rows: 2, min: 1, max: 31 },
                            { rows: 2, min: 1, max: 12 },
                            { rows: 4, min: 1900, max: birthMaxDate },
                        ]}
                        separator="-"
                        isDisabled={isActionLoading}
                        isValid={inputValidator.birthdate.response}
                        onChangeFn={(e) =>
                            inputMaskedOnChangeHandler(e, "birthdate")
                        }
                    />
                    <InputSelect
                        key="InputSelect"
                        value={inputValue.school}
                        placeholder="wybierz szkołę"
                        query={{
                            url: "/school_list?search=",
                            queryKey: "addChildrenSchoolList",
                            selectedData: [inputValue.school],
                            dataKey: {
                                id: "idSchool",
                                name: "name",
                            },
                            backendSearch: true,
                        }}
                        isDisabled={isActionLoading}
                        onChange={(data) =>
                            setInputValue({
                                ...inputValue,
                                school: data,
                            })
                        }
                        onClear={() =>
                            setInputValue({
                                ...inputValue,
                                school: {},
                            })
                        }
                        modalSetters={modalSetters}
                    />
                    <Input
                        key="Input"
                        type="text"
                        value={inputValue.class}
                        maxLength={5}
                        placeholder="wpisz klasę"
                        placeholderFocus="1A"
                        classNameWrapper={inputValue.class ? " is-filled" : ""}
                        isDisabled={isActionLoading}
                        onChangeFn={(e) =>
                            setInputValue({
                                ...inputValue,
                                class: e.currentTarget.value.toUpperCase(),
                            })
                        }
                    />
                    <Button
                        key="Button"
                        isDisabled={formSubmitDisable || isActionLoading}
                        isLoading={isActionLoading}
                        onClickFn={registerButtonOnClick}
                    >
                        Zarejestruj
                    </Button>
                </SigningFormContainer>
                <SigningRulesContainer
                    text="Klikając „Zaloguj się” akceptujesz regulamin"
                    separator="i"
                >
                    <ButtonRuleSigning>How2 Edu</ButtonRuleSigning>
                    <ButtonRuleSigning>how2edu.app</ButtonRuleSigning>
                </SigningRulesContainer>
                {valueAgreementsValidation.agreement_1 && (
                    <SigningRulesContainer errorMessage="* Zapoznaj się z Polityką Prywatności" />
                )}
                <SigningFormContainer
                    classNameWrapper="signing"
                    classNameGroup="agreements"
                >
                    <Checkbox
                        id="requiredAgreement1"
                        isChecked={valueAgreements.agreement_1}
                        isInvalid={valueAgreementsValidation.agreement_1}
                        isDisabled={isActionLoading}
                        onChangeFn={(e) => {
                            setValueAgreements({
                                ...valueAgreements,
                                agreement_1: e,
                            });
                            setValueAgreementsValidation({
                                agreement_1: false,
                            });
                        }}
                    >
                        * Zapoznałem/zapoznałam się z{" "}
                        <Button className="inline">Polityką Prywatności</Button>{" "}
                        How2 Edu. Administratorem Twoich danych osobowych Jest
                        How2 sp. z o.o. z siedzibą w Lublinie. Twoje dane
                        Osobowe będą przetwarzane w celu rejestracji i obsługi{" "}
                        <ButtonReadMore text="pokaż więcej...">
                            lorem ipsum jest przykładowym tekstem wypełniającym.
                        </ButtonReadMore>
                    </Checkbox>
                    <Checkbox
                        isChecked={valueAgreements.agreement_2}
                        isDisabled={isActionLoading}
                        onChangeFn={(e) =>
                            setValueAgreements({
                                ...valueAgreements,
                                agreement_2: e,
                            })
                        }
                    >
                        Chcę otrzymywać od How2 Edu na wskazany przeze mnie
                        Adres e-mail, a także za pośrednictwem SMS/MMS,
                        Informacje marketingowe dotyczące aplikacji How2 Edu
                        Oraz partnerów biznesowych How2 sp. z o.o. Zgodę można{" "}
                        <ButtonReadMore text="pokaż więcej...">
                            lorem ipsum dolor sit amet consectetur adipisicing
                            elit. Molestiae quis tempora facilis ab ex magni
                            aliquam voluptates fugit id obcaecati.
                        </ButtonReadMore>
                    </Checkbox>
                </SigningFormContainer>
            </DashboardContainer>
        </>
    );
};

export default RegisterChildrenForm;
