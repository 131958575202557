import { useState, useRef, useEffect, useContext } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";

import { StoreContext } from "../../../context/Store";

import useActionLoading from "../../../hooks/useActionLoading";

import routeLoadingState from "../../../functions/routeLoadingState";

import Button from "../../../components/buttons/Button";

import DashboardContainer from "../../../components/containers/dashboard/DashboardContainer";
import AvatarPicker from "../../../components/containers/dashboard/children-avatar/AvatarPicker";

const ChildrenAccountAvatar = ({ routeMatches, routeSetters }) => {
    const { setRouteData, setRouteIsLoading } = routeSetters;

    const { isActionLoading, setIsActionLoading } = useActionLoading();

    const history = useHistory();

    /* AVATAR STATES */
    const avatarIsSet = useRef(false);
    const [avatar, setAvatar] = useState(null);
    const initialAvatar = useRef(null);
    const idAvatar = useRef(null);

    const queryClient = useQueryClient();
    const { api } = useContext(StoreContext);

    /* GET ACCOUNT DATA */
    const {
        data: accountData,
        isFetched: accountIsFetched,
        isLoading: accountIsLoading,
    } = useQuery("account", () => api.get(`/account`), {
        staleTime: Infinity,
        retry: false,
        refetchOnWindowFocus: false,
    });

    useEffect(() => {
        if (!accountIsLoading && !avatarIsSet.current) {
            avatarIsSet.current = true;

            initialAvatar.current = accountData.data.user.avatar;
            setAvatar(accountData.data.user.avatar);
        }
    }, [accountIsLoading, accountData]);

    /* GET AVATARS DATA */
    const {
        data: avatarsData,
        isFetched: avatarsIsFetched,
        isLoading: avatarsIsLoading,
        isRefetching: avatarsIsRefetching,
    } = useQuery("childrenAvatars", () => api.get("/avatars"), {
        staleTime: Infinity,
        retry: false,
        refetchOnWindowFocus: false,
    });

    /* SAVE AVATAR LOADING */
    const [saveAvatarLoading, setSaveAvatarLoading] = useState(false);

    /* SAVE AVATAR */
    const { mutate: saveAvatarMutate } = useMutation(
        () =>
            api.post("/avatar", {
                avatar: idAvatar.current,
            }),
        {
            onMutate: () => setIsActionLoading(true),
            onSuccess: (data) => {
                queryClient.invalidateQueries("account");

                history.goBack();
            },
            onError: (error) => setIsActionLoading(false),
        }
    );

    /* ROUTE LOADING */
    const routeIsLoading = routeLoadingState({
        fetched: [avatarsIsFetched, accountIsFetched],
        loading: [avatarsIsLoading, accountIsLoading],
        refetching: [avatarsIsRefetching],
    });

    /* ROUTE SETTERS */
    useEffect(() => {
        const routeData = {
            title: "EDYCJA AVATARA",
        };

        if (routeMatches) {
            setRouteIsLoading(routeIsLoading);
            setRouteData(routeData);
        }
    }, [routeIsLoading, routeMatches, setRouteIsLoading, setRouteData]);

    return (
        <>
            <DashboardContainer className="select-avatar">
                {!routeIsLoading && (
                    <>
                        <AvatarPicker
                            avatars={avatarsData?.data}
                            currentAvatar={{
                                avatar: avatar,
                                setAvatar: setAvatar,
                            }}
                            isDisabled={isActionLoading}
                            onAvatarSelect={(id) => (idAvatar.current = id)}
                        />
                        <div className="AvatarPicker__ButtonGroup">
                            <Button
                                className="medium-2 disabled-alt"
                                isLoading={isActionLoading}
                                isDisabled={avatar === initialAvatar.current || isActionLoading}
                                onClickFn={saveAvatarMutate}
                            >
                                Zapisz
                            </Button>
                            <Button
                                className="medium-2 gray disabled-alt"
                                isDisabled={isActionLoading}
                                onClickFn={() => history.goBack()}
                            >
                                Anuluj
                            </Button>
                        </div>
                    </>
                )}
            </DashboardContainer>
        </>
    );
};

export default ChildrenAccountAvatar;
