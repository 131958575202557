import parse from "html-react-parser";

import parseFlashcardContent from "../functions/flashcard/parseFlashcardContent";
import parseMathTagConnection from "../functions/parseMathTagConnection";

const ReaderText = ({ content, flash, isLast }) => {
    return (
        <>
            <div className={"ReaderText" + (isLast ? " _is--last" : "")}>
                {parseFlashcardContent(
                    parse(parseMathTagConnection(content)),
                    flash
                ).map((object) => {
                    return object;
                })}
            </div>
        </>
    );
};

export default ReaderText;
