const ButtonBackwards = ({ id, className, isDisabled, onClickFn }) => {
    return (
        <button
            id={id}
            className={"ButtonBackwards" + (className ? " " + className : "")}
            type="button"
            disabled={isDisabled}
            onClick={onClickFn}
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                className="ButtonBackwards__Svg"
                width="26"
                height="26"
                viewBox="0 0 26 26"
            >
                <g
                    id="Group_432"
                    dataname="Group 432"
                    transform="translate(-45 -89.093)"
                >
                    <circle
                        id="Ellipse_27"
                        className="ButtonBackwards__Circle"
                        dataname="Ellipse 27"
                        cx="13"
                        cy="13"
                        r="13"
                        transform="translate(45 89.093)"
                        fill="#0c6ffa"
                    />
                    <path
                        id="Path_50"
                        className="ButtonBackwards__Arrow"
                        dataname="Path 50"
                        d="M0,0,5.853,5.853,0,11.7"
                        transform="translate(60.386 107.988) rotate(180)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeWidth="2"
                    />
                </g>
            </svg>
        </button>
    );
};

export default ButtonBackwards;
