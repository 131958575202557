import { useState, useEffect, useContext } from "react";
import { useMutation } from "react-query";
import { useCookies } from "react-cookie";

import { StoreContext } from "../../context/Store";

import useLogout from "../../hooks/useLogout";
import useActionLoading from "../../hooks/useActionLoading";

import validator from "../../functions/validator";
import editPasswordHandler from "../../functions/errorHandlers/editPasswordHandler";

import Input from "../../components/inputs/Input";
import Checkbox from "../../components/inputs/Checkbox";
import Button from "../../components/buttons/Button";

import DashboardContainer from "../../components/containers/dashboard/DashboardContainer";
import SigningFormContainer from "../../components/containers/signing/SigningFormContainer";
import SigningFormPasswordContainer from "../../components/containers/signing/SigningFormPasswordContainer";

const PasswordEdit = ({ routeMatches, routeSetters }) => {
    const { setRouteData } = routeSetters;

    const { isActionLoading, setIsActionLoading } = useActionLoading();

    const [cookies] = useCookies(["idRank"]);

    const returnEndpointAddress = () => {
        if (Number(cookies.idRank) === 2) {
            return "/account/change_password";
        }
        if (Number(cookies.idRank) === 4) {
            return "/teacher/change_password";
        }
        return "/account/change_password";
    };

    const { api } = useContext(StoreContext);

    const logout = useLogout();

    const { mutate: handleChangePassword } = useMutation(
        () =>
            api.post(returnEndpointAddress(), {
                oldPassword: passwordValue.old,
                password: passwordValue.origin,
                password2: passwordValue.repeated,
            }),
        {
            onMutate: () => setIsActionLoading(true),
            onSuccess: () => {
                setIsActionLoading(false);

                logout("/remind-password-finish");
            },
            onError: (error) => {
                editPasswordHandler(
                    error.response.status,
                    error.response.data,
                    () =>
                        setOldPasswordValidator({
                            state: "error",
                            stateText: "Nieprawidłowe hasło",
                        })
                );
            },
        }
    );

    /* PASSWORD VALUES & VALIDATORS */
    const [passwordValue, setPasswordValue] = useState({
        old: "",
        origin: "",
        repeated: "",
    });
    const [oldPasswordValidator, setOldPasswordValidator] = useState({
        response: "",
        errorMessage: "",
    });
    const [passwordValidator, setPasswordValidator] = useState({
        uppercase: false,
        lowercase: false,
        length: false,
        special: false,
    });
    const [passwordComparator, setPasswordComparator] = useState({
        origin: "",
        repeated: "",
    });

    /* PASSWORD COMPARISION */
    useEffect(() => {
        if (
            passwordValue.origin.length > 0 ||
            passwordValue.repeated.length > 0
        ) {
            if (
                passwordValidator.uppercase &&
                passwordValidator.lowercase &&
                passwordValidator.length &&
                passwordValidator.special
            ) {
                if (passwordValue.repeated.length === 0) {
                    setPasswordComparator({
                        origin: "success",
                        repeated: "",
                    });
                } else {
                    if (passwordValue.origin === passwordValue.repeated) {
                        setPasswordComparator({
                            origin: "success",
                            repeated: "success",
                        });
                    } else {
                        setPasswordComparator({
                            origin: "error",
                            repeated: "error",
                        });
                    }
                }
            } else {
                setPasswordComparator((prevState) => ({
                    origin: "error",
                    repeated: prevState.repeated,
                }));
            }
        } else {
            if (
                passwordValue.origin.length === 0 &&
                passwordValue.repeated.length === 0
            ) {
                setPasswordComparator({
                    origin: "",
                    repeated: "",
                });
            }
        }
    }, [passwordValue, passwordValidator]);

    /* PASSWORD VALIDATION */
    useEffect(() => {
        const passwordObject = {
            lowercase: validator("lowercase", passwordValue.origin),
            uppercase: validator("uppercase", passwordValue.origin),
            length: validator("length", passwordValue.origin),
            special: validator("special", passwordValue.origin),
        };

        setPasswordValidator(passwordObject);
    }, [passwordValue.origin]);

    /* ON ENTER KEY */
    const onEnterKeyHandler = (e) => {
        const enterKey = e.charCode === 13 || e.keyCode === 13;

        if (!formSubmitDisable && enterKey) {
            e.currentTarget.blur();
            handleChangePassword();
        }
    };

    /* OLD PASSWORD ON CHANGE HANDLER */
    const oldPasswordOnChangeHandler = (e) => {
        if (e.length > 0) {
            if (e.length > 7) {
                setOldPasswordValidator({
                    response: "success",
                    errorMessage: "",
                });
            } else {
                setOldPasswordValidator({
                    response: "error",
                    errorMessage: "",
                });
            }
        } else {
            setOldPasswordValidator({
                response: "",
                errorMessage: "",
            });
        }

        setPasswordValue({ ...passwordValue, old: e });
    };

    /* ENABLE SUBMIT IF VALIDATION IS CORRENT */
    const formSubmitDisable =
        passwordValidator.uppercase &&
        passwordValidator.lowercase &&
        passwordValidator.length &&
        passwordValidator.special &&
        oldPasswordValidator.response === "success" &&
        passwordComparator.origin === "success" &&
        passwordComparator.repeated === "success"
            ? false
            : true;

    /* ROUTE SETTERS */
    useEffect(() => {
        const routeData = {
            title: "EDYTUJ HASŁO",
        };

        if (routeMatches) {
            setRouteData(routeData);
        }
    }, [setRouteData, routeMatches]);

    return (
        <>
            <DashboardContainer className="password-edit">
                <SigningFormContainer classNameWrapper="signing">
                    <Input
                        key="Input"
                        type="password"
                        placeholder="wpisz stare hasło"
                        value={passwordValue.old}
                        isDisabled={isActionLoading}
                        isValid={{
                            response: oldPasswordValidator.response,
                            text: oldPasswordValidator.errorMessage,
                        }}
                        onChangeFn={(e) =>
                            oldPasswordOnChangeHandler(e.currentTarget.value)
                        }
                        onKeyPressFn={(e) => onEnterKeyHandler(e)}
                    />
                    <Input
                        key="Input"
                        type="password"
                        placeholder="wpisz nowe hasło"
                        value={passwordValue.origin}
                        isDisabled={isActionLoading}
                        isValid={{ response: passwordComparator.origin }}
                        onChangeFn={(e) =>
                            setPasswordValue({
                                ...passwordValue,
                                origin: e.currentTarget.value,
                            })
                        }
                        onKeyPressFn={(e) => onEnterKeyHandler(e)}
                    />
                    <Input
                        key="Input"
                        type="password"
                        placeholder="powtórz nowe hasło"
                        value={passwordValue.repeated}
                        isDisabled={isActionLoading}
                        isValid={{
                            response: passwordComparator.repeated,
                            text: "Niezgodne hasła",
                        }}
                        onChangeFn={(e) =>
                            setPasswordValue({
                                ...passwordValue,
                                repeated: e.currentTarget.value,
                            })
                        }
                        onKeyPressFn={(e) => onEnterKeyHandler(e)}
                    />
                    <SigningFormPasswordContainer text="Hasło powinno zawierać">
                        <Checkbox
                            isDisabled={true}
                            isChecked={passwordValidator.uppercase}
                        >
                            wielką literę
                        </Checkbox>
                        <Checkbox
                            isDisabled={true}
                            isChecked={passwordValidator.length}
                        >
                            od 8 do 50 znaków
                        </Checkbox>
                        <Checkbox
                            isDisabled={true}
                            isChecked={passwordValidator.lowercase}
                        >
                            małą literę
                        </Checkbox>
                        <Checkbox
                            isDisabled={true}
                            isChecked={passwordValidator.special}
                        >
                            cyfrę lub znak specjalny
                        </Checkbox>
                    </SigningFormPasswordContainer>
                    <Button
                        key="Button"
                        isLoading={isActionLoading}
                        isDisabled={formSubmitDisable || isActionLoading}
                        onClickFn={() => handleChangePassword()}
                    >
                        Zmień hasło
                    </Button>
                </SigningFormContainer>
            </DashboardContainer>
        </>
    );
};

export default PasswordEdit;
