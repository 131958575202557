import { useRef } from "react";

const routeMenuIcon = (type) => {
    switch (type) {
        case "add":
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="RouteMenu__Svg"
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                >
                    <g
                        id="Group_11"
                        dataname="Group 11"
                        transform="translate(-250.5 -477)"
                    >
                        <line
                            id="Line_2"
                            className="RouteMenu__Line"
                            dataname="Line 2"
                            x2="13"
                            transform="translate(251.5 484.5)"
                            fill="none"
                            stroke="#2e2a26"
                            strokeLinecap="round"
                            strokeWidth="2"
                        />
                        <line
                            id="Line_3"
                            className="RouteMenu__Line"
                            data-name="Line 3"
                            y2="13"
                            transform="translate(258 478)"
                            fill="none"
                            stroke="#2e2a26"
                            strokeLinecap="round"
                            strokeWidth="2"
                        />
                    </g>
                </svg>
            );

        case "remove":
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    className="RouteMenu__Svg"
                    width="11"
                    height="11"
                    viewBox="0 0 11 11"
                >
                    <defs>
                        <clipPath id="clip-path">
                            <rect
                                id="Rectangle_1265"
                                className="RouteMenu__Rect"
                                dataname="Rectangle 1265"
                                width="11"
                                height="11"
                                fill="#2e2a26"
                                stroke="#2e2a26"
                                strokeWidth="2"
                            />
                        </clipPath>
                    </defs>
                    <g
                        id="Group_722"
                        dataname="Group 722"
                        transform="translate(-83 5)"
                    >
                        <g
                            id="Group_633"
                            dataname="Group 633"
                            transform="translate(83 -5)"
                            clipPath="url(#clip-path)"
                        >
                            <line
                                id="Line_63"
                                className="RouteMenu__Line"
                                dataname="Line 63"
                                y1="9"
                                x2="9"
                                transform="translate(1 1)"
                                fill="none"
                                stroke="#2e2a26"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                            />
                            <line
                                id="Line_64"
                                className="RouteMenu__Line"
                                dataname="Line 64"
                                x2="9"
                                y2="9"
                                transform="translate(1 1)"
                                fill="none"
                                stroke="#2e2a26"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                            />
                        </g>
                    </g>
                </svg>
            );
        case "edit":
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="RouteMenu__Svg"
                    width="16.205"
                    height="17.338"
                    viewBox="0 0 16.205 17.338"
                >
                    <g
                        id="Group_977"
                        dataname="Group 977"
                        transform="translate(-327.14 -202.488)"
                    >
                        <path
                            id="Path_1547"
                            className="RouteMenu__Path"
                            dataname="Path 1547"
                            d="M0,0H5.15V13.3L2.639,16.627,0,13.3Z"
                            transform="translate(338.754 203.192) rotate(41)"
                            fill="none"
                            stroke="#2e2a26"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1"
                        />
                        <rect
                            id="Rectangle_1413"
                            className="RouteMenu__Path"
                            dataname="Rectangle 1413"
                            width="5.226"
                            height="2.09"
                            transform="translate(338.588 203.193) rotate(40)"
                            fill="#2e2a26"
                        />
                        <path
                            id="Path_1548"
                            className="RouteMenu__Path"
                            dataname="Path 1548"
                            d="M-17442.988-10465.057v2.423a10.4,10.4,0,0,0,1.994-.382C-17441.1-10463.131-17442.988-10465.057-17442.988-10465.057Z"
                            transform="translate(17773.098 10679.812)"
                            fill="#2e2a26"
                        />
                    </g>
                </svg>
            );
        default:
            return undefined;
    }
};

const RouteMenu = ({ data, dropdown, isDisabled }) => {
    const clickDispatchTimeout = useRef(null);

    const dropdownClickHandler = (clickDispatch) => {
        if (dropdown.state) {
            dropdown.setState(false);

            clearTimeout(clickDispatchTimeout.current);

            clickDispatchTimeout.current = setTimeout(() => {
                clickDispatch();
            }, 300);
        }
    };

    return (
        <>
            <div className="RouteMenu">
                <button
                    className="RouteMenu__Toggle"
                    type="button"
                    disabled={isDisabled}
                    onClick={
                        dropdown.state
                            ? () => dropdown.setState(false)
                            : () => dropdown.setState(true)
                    }
                >
                    <span className="RouteMenu__Dash"></span>
                    <span className="RouteMenu__Dash"></span>
                    <span className="RouteMenu__Dash"></span>
                </button>
                <div
                    className={
                        "RouteMenu__Dropdown" +
                        (dropdown.state ? " _active" : "")
                    }
                >
                    {data?.props?.map((object, index) => {
                        return (
                            <button
                                key={data.key + "_" + index}
                                className="RouteMenu__Button"
                                type="button"
                                disabled={object.disabled}
                                onClick={() =>
                                    dropdownClickHandler(object.onClick)
                                }
                            >
                                {object.text}
                                <span className="RouteMenu__Icon">
                                    {routeMenuIcon(object.icon)}
                                </span>
                            </button>
                        );
                    })}
                </div>
            </div>
        </>
    );
};

export default RouteMenu;
