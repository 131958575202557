import { useState, useEffect, useContext, useRef } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useHistory, useParams } from "react-router-dom";

import { StoreContext } from "../../../context/Store";

import useActionLoading from "../../../hooks/useActionLoading";

import DashboardContainer from "../../../components/containers/dashboard/DashboardContainer";
import SchoolGroupForm from "../../../components/forms/dashboard/SchoolGroupForm";

import modalDoodleImage_1 from "../../../assets/images/dashboard/modal-doodles/happy_2.svg";

const SchoolGroupAdd = ({ routeMatches, routeSetters, modalSetters }) => {
    const { setRouteData } = routeSetters;
    const { setModalData } = modalSetters;

    const {isActionLoading, setIsActionLoading} = useActionLoading();

    const history = useHistory();
    const params = useParams();

    const queryClient = useQueryClient();
    const { api } = useContext(StoreContext);

    const idSchoolExist = useRef(params?.idSchool ? params.idSchool : null);
    const idSchoolCache = useRef(
        idSchoolExist.current !== null && idSchoolExist.current
    );
    /* INPUT VALUE */
    const [inputValue, setInputValue] = useState({
        name: "",
        tags: [],
        description: "",
    });

    /* INPUT VALIDATOR */
    const [inputValidator, setInputValidator] = useState({
        name: {
            response: "",
        },
        tags: {
            response: "",
        },
        description: {
            response: "",
        },
    });

    /* ON CHANGE HANDLER */
    const onChangeHandler = (e) => {
        setInputValue({ ...inputValue, ...e.value });
        setInputValidator({ ...inputValidator, ...e.validator });
    };

    /* ADD GROUP */
    const { mutate: addSchoolGroupMutate } = useMutation(
        ["schoolGroups", idSchoolCache.current],
        () =>
            api.post(`/teacher/add_group`, {
                idSchool: idSchoolCache.current,
                name: inputValue.name,
                tags: parseTags(inputValue.tags),
                description: inputValue.description,
            }),
        {
            onMutate: () => setIsActionLoading(true),
            onSuccess: () => {
                queryClient.invalidateQueries([
                    "schoolGroups",
                    idSchoolCache.current,
                ]);

                setModalData(modalData.current);
            },
            onError: () => setIsActionLoading(false),
        }
    );

    /* PARSE TAGS OBJECTS TO STRING */
    const parseTags = (array) => {
        let str = "";

        for (let i = 0; i < array.length; i++) {
            str += array[i].idTag;

            if (i !== array.length - 1) {
                str += ",";
            }
        }

        return str;
    };

    /* MODAL DATA */
    const modalData = useRef({});

    useEffect(() => {
        modalData.current = {
            data: [
                {
                    title: {
                        obj: (
                            <p>
                                {inputValue.name}
                                <br />
                                została dodana
                            </p>
                        ),
                        isCenter: true,
                    },
                    img: {
                        obj: modalDoodleImage_1,
                        size: "219px",
                    },
                    action: {
                        obj: {
                            text: "Przejdź dalej",
                            onClick: () => history.goBack(),
                        },
                    },
                },
            ],
        };
    }, [inputValue.name]);

    /* ROUTE SETTERS */
    useEffect(() => {
        const routeData = {
            title: "DODAJ GRUPĘ",
        };

        if (routeMatches) {
            setRouteData(routeData);
        }
    }, [routeMatches, setRouteData]);

    return (
        <>
            <DashboardContainer>
                <SchoolGroupForm
                    value={inputValue}
                    validator={inputValidator}
                    modalSetters={modalSetters}
                    isDisabled={isActionLoading}
                    onChangeFn={(e) => onChangeHandler(e)}
                    onSubmitFn={addSchoolGroupMutate}
                />
            </DashboardContainer>
        </>
    );
};

export default SchoolGroupAdd;
