import ButtonBackwards from "../../buttons/ButtonBackwards";

const DoodleContent = ({ wrapperClass, children, isDisabled, onBackFn }) => {
    return (
        <>
            <div className={wrapperClass + "-doodleContent text-center"}>
                {onBackFn && (
                    <ButtonBackwards isDisabled={isDisabled} onClickFn={onBackFn}/>
                )}
                <div className={wrapperClass + "-doodleContent-wrapper"}>
                    {children}
                </div>
            </div>
        </>
    );
};

export default DoodleContent;
