import { useRef, useEffect, useContext } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHistory, useLocation } from "react-router-dom";

import { StoreContext } from "../../../context/Store";

import pathSlice from "../../../functions/pathSlice";
import routeLoadingState from "../../../functions/routeLoadingState";

import DashboardContainer from "../../../components/containers/dashboard/DashboardContainer";
import SchoolPanels from "../../../components/containers/dashboard/schools/SchoolPanels";

import modalDoodleImage_1 from "../../../assets/images/dashboard/modal-doodles/happy_3.svg";

const Schools = ({ routeMatches, routeSetters, modalSetters }) => {
    const { setRouteData, setRouteIsLoading } = routeSetters;
    const {
        setModalData,
        setModalActive,
        setModalApiResponse,
        setModalApiLoading,
    } = modalSetters;

    const history = useHistory();
    const location = useLocation();

    const { api } = useContext(StoreContext);
    const queryClient = useQueryClient();

    /* GET TEACHER SCHOOLS */
    const {
        data: teacherSchoolsData,
        isFetched: teacherSchoolsIsFetched,
        isLoading: teacherSchoolsIsLoading,
        isRefetching: teacherSchoolsIsRefetching,
        isStale: teacherSchoolsIsStale,
        refetch: teacherSchoolsRefetch
    } = useQuery(
        ["teacherSchools", "schools"],
        () => api.get(`/teacher/schools`),
        {
            staleTime: Infinity,
            retry: false,
            refetchOnWindowFocus: false,
        }
    );

    /* ADD TEACHER SCHOOL */
    const { mutate: addSchoolMutate } = useMutation(
        (data) => api.post(`/teacher/add_school`, data),
        {
            onMutate: () => setModalApiLoading(true),
            onSuccess: () => {
                queryClient.invalidateQueries(["teacherSchools", "schoolsGroup"])
                teacherSchoolsRefetch();

                setModalApiResponse(true);
            },
            onError: () => alert("*Placeholder* Error with sending data"),
        }
    );

    /* ROUTE LOADING */
    const routeIsLoading = routeLoadingState({
        fetched: [teacherSchoolsIsFetched],
        loading: [teacherSchoolsIsLoading],
        refetching: [teacherSchoolsIsRefetching && teacherSchoolsIsStale]
    });

    /* ROUTE SETTERS */
    useEffect(() => {
        const routeData = {
            title: "MOJE SZKOŁY",
        };

        if (routeMatches) {
            setRouteIsLoading(routeIsLoading);
            setRouteData(routeData);
        }
    }, [routeIsLoading, routeMatches, setRouteIsLoading, setRouteData]);

    /* MODAL SCHOOL DATA */
    const modalSchoolAddData = useRef({});

    useEffect(() => {
        if (!routeIsLoading) {
            modalSchoolAddData.current = {
                select: true,
                close: true,
                data: [
                    {
                        query: {
                            url: "/school_list?search=",
                            queryKey: "modalSchoolListTeacher",
                            selectedData: teacherSchoolsData.data,
                            dataKey: {
                                id: "idSchool",
                                name: "name",
                            },
                            backendSearch: true,
                        },
                        actionDispatch: {
                            dispatch: addSchoolMutate
                        },
                    },
                    {
                        title: {
                            obj: (
                                <p>
                                    [school_inject]
                                    <br />
                                    została dodana
                                </p>
                            ),
                            injectKey: "[school_inject]",
                            isCenter: true,
                        },
                        img: {
                            obj: modalDoodleImage_1,
                            size: "245px",
                        },
                        action: {
                            obj: {
                                text: "Przejdź dalej",
                            },
                        },
                    },
                ],
            };
        }
    }, [
        routeIsLoading,
        setModalActive,
        setModalApiLoading,
        setModalApiResponse,
        addSchoolMutate,
        teacherSchoolsData
    ]);

    return (
        <>
            <DashboardContainer className="subjects">
                {!routeIsLoading && (
                    <>
                        <SchoolPanels
                            text={{
                                action: "Moje grupy",
                                alert: (
                                    <p>
                                        Osiągnąłeś maksymalną liczbę szkół.
                                        <br />
                                        Nie możesz dodać kolejnej.
                                    </p>
                                ),
                                button: "Dodaj szkołę",
                            }}
                            max={5}
                            data={teacherSchoolsData.data}
                            keyName="teacherSchools"
                            isRefetching={teacherSchoolsIsRefetching}
                            onClick={{
                                panel: (id) => history.push(`${pathSlice(location.pathname)}/school-${id}`),
                                button: () => setModalData(modalSchoolAddData.current),
                            }}
                        />
                    </>
                )}
            </DashboardContainer>
        </>
    );
};

export default Schools;
