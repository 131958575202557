import AccountDataItem from "./AccountDataItem";

const AccountData = ({ className, data, dataKey }) => {
    return (
        <>
            {data && data.length > 0 && (
                <div
                    className={
                        "AccountData" + (className ? " " + className : "")
                    }
                >
                    {data?.map((object, index) => {
                        return (
                            <AccountDataItem
                                key={dataKey + "_" + index}
                                title={object.title}
                                text={object.text}
                                vertical={object.vertical}
                                button={object.button}
                                children={object.children}
                            />
                        );
                    })}
                </div>
            )}
        </>
    );
};

export default AccountData;
