import getChapter from "../../functions/getChapter";

import ReaderProgress from "../../utils/ReaderProgress";
import ReaderMenu from "./ReaderMenu";

const ReaderNavigation = ({
    pages,
    chapters,
    menu,
    isGallup,
    isGibs,
    isFinished,
}) => {
    const { page, pagesTotal } = pages;

    const chapter = getChapter(chapters, page);

    return (
        <>
            <div className="ReaderNavigation">
                <div className="ReaderNavigation__Title">
                    {!isFinished
                        ? !isGallup
                            ? !isGibs
                                ? chapter
                                    ? chapter.title !== ""
                                        ? chapter.title
                                        : "Rozdział " + page
                                    : "Trwa ładowanie..."
                                : "Test Gibsa"
                            : "Test Gallupa"
                        : "Zakończenie"}
                </div>
                {!isGallup && !isGibs && (
                    <ReaderProgress
                        color={isGallup ? "secondary" : ""}
                        numbers={{ current: page, total: pagesTotal }}
                    />
                )}
                <ReaderMenu data={menu} />
            </div>
        </>
    );
};

export default ReaderNavigation;
