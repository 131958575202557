const BeginningText = ({ className, userRank, list }) => {
    const paragraph = () => {
        switch (userRank) {
            case 2:
                return (
                    <p>Wysłaliśmy do Ciebie e-mail z kodem aktywacyjnym.</p>
                );
            case 3:
                return (
                    <p>
                        Wysłaliśmy do Twojego rodzica e-mail z prośbą o
                        rejestrację w aplikacji oraz potwierdzenie Twoich
                        danych.
                    </p>
                );
            case 4:
                return (
                    <p>
                        Wysłaliśmy do Ciebie e-mail
                        <br />z <strong>kodem aktywacyjnym</strong> oraz na
                        poniższe adresy e-mail wysłaliśmy wiadomość z{" "}
                        <strong>linkiem potwierdzającym Twoją tożsamość</strong>
                    </p>
                );
            default:
                return undefined;
        }
    };

    return (
        <div className={"beginning-text" + (className ? " " + className : "")}>
            {paragraph()}
            {Array.isArray(list) && list.length > 0 && (
                <ul>
                    {list.map((object) => {
                        return <li key={object}>{object}</li>;
                    })}
                </ul>
            )}
        </div>
    );
};

export default BeginningText;
