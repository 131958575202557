import Button from "../buttons/Button";

import doodleImage_1 from "../../assets/images/dashboard/modal-doodles/sad.svg";

const RouteAccess = ({ caption, button }) => {
    return (
        <div className="RouteAccess">
            <div className="RouteAccess__Doodle">
                <div className="RouteAccess__Banner">
                    <img
                        className="RouteAccess__Img"
                        src={doodleImage_1}
                        alt=""
                    />
                </div>
                <div className="RouteAccess__Caption">
                    {caption ? caption : "NO ACCESS"}
                </div>
                <div className="RouteAccess__Button">
                    <Button
                        className="medium-2"
                        onClickFn={button?.onClick ? button.onClick : undefined}
                    >
                        {button?.text ? button.text : "GO BACK"}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default RouteAccess;
