const ShelfSwitch = ({ text, state, stateSelected, onClick }) => {
    const switchToggleHandler = () => {
        if (stateSelected === state) {
            onClick("");
        } else {
            onClick(state);
        }
    };

    return (
        <>
            <div className="ShelfSwitch">
                <button
                    className={
                        "ShelfSwitch__Checker" +
                        (stateSelected === state ? " _checked" : "")
                    }
                    type="button"
                    onClick={() => switchToggleHandler()}
                >
                    <span className="ShelfSwitch__Text">{text}</span>
                    <span className="ShelfSwitch__Slide">
                        <span className="ShelfSwitch__Ball"></span>
                    </span>
                </button>
            </div>
        </>
    );
};

export default ShelfSwitch;
