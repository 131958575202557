const parentHasActiveSubscription = (data) => {
    // mock data
    // const data = [
    //     {
    //         idUser: 6,
    //         name: "Adam",
    //         surname: "Nowak",
    //         subscription: {
    //             id: 2,
    //             type: "pólroczny",
    //             price: "60.00",
    //             active_to: "2022-03-05 15:12:58.0000000",
    //             days_to_end: 23,
    //             status: "waiting",
    //             price_per_month: "12.00",
    //             trialAvailable: 0,
    //         },
    //     },
    //     {
    //         idUser: 11,
    //         name: "Adam",
    //         surname: "Surname",
    //         subscription: {
    //             id: 1,
    //             type: "miesieczny",
    //             price: "9.00",
    //             active_to: "2022-08-02 15:13:47.0000000",
    //             days_to_end: 173,
    //             status: "waiting",
    //             price_per_month: "9.00",
    //             trialAvailable: 0,
    //         },
    //     },
    //     {
    //         idUser: 12,
    //         name: "sdfsf",
    //         surname: "sdfgsdgf",
    //         subscription: {
    //             id: 1,
    //             type: "miesieczny",
    //             price: "9.00",
    //             active_to: "2022-02-11 13:55:25.0000000",
    //             days_to_end: 1,
    //             status: "waiting",
    //             price_per_month: "9.00",
    //             trialAvailable: 0,
    //         },
    //     },
    //     {
    //         idUser: 17,
    //         name: "Marjan",
    //         surname: "DDsdasdsad",
    //         subscription: {
    //             id: 1,
    //             type: "miesieczny",
    //             price: "9.00",
    //             active_to: "2022-02-11 14:15:27.0000000",
    //             days_to_end: 1,
    //             status: "active",
    //             price_per_month: "9.00",
    //             trialAvailable: 0,
    //         },
    //     },
    // ];

    if (!data) {
        return false;
    }

    for (let i = 0; i < data.length; i++) {
        if (
            data[i].subscription.status === "active" ||
            data[i].subscription.status === "expiring"
        ) {
            return true;
        }
    }
    return false;
};

export default parentHasActiveSubscription;
