import React from "react";

import parseFlashcardPhrases from "./parseFlashcardPhrases";
import setObjectKey from "./setObjectKey";

const parseFlashcardObject = (object, flash, indexContent) => {
    let mapL1 = [];
    let indexObject = 0;
    let index = indexContent.toString() + indexObject.toString();

    const objectL1 = object.props.children;

    if (objectL1) {
        if(typeof objectL1 === 'string') {
            mapL1.push(parseFlashcardPhrases(objectL1, flash, index));
        } else {
            if(Array.isArray(objectL1)) {
                for(const elementL1 of objectL1) {
                    if(elementL1) {
                        if(typeof elementL1 === 'string') {
                            mapL1.push(...parseFlashcardPhrases(elementL1, flash, index));
                        } else {
                            let mapL2 = [];
                            const objectL2 = elementL1.props.children;

                            if(objectL2) {
                                if(typeof objectL2 === 'string') {
                                    mapL2.push(React.cloneElement(elementL1, { children: parseFlashcardPhrases(objectL2, flash, index)}))
                                } else {
                                    if(Array.isArray(objectL2)) {
                                        let mapArrayL2 = [];

                                        for(const elementL2 of objectL2) {
                                            if(elementL2) {
                                                if(typeof elementL2 === 'string') {
                                                    mapArrayL2.push(...parseFlashcardPhrases(elementL2, flash, index));
                                                } else {
                                                    let mapL3 = [];
                                                    const objectL3 = elementL2.props.children;

                                                    if(objectL3) {
                                                        if(typeof objectL3 === 'string') {
                                                            mapL3.push(...parseFlashcardPhrases(objectL3, flash, index))
                                                        } else {
                                                            if(Array.isArray(objectL3)) {
                                                                let mapArrayL3 = [];

                                                                for(const elementL3 of objectL3) {
                                                                    if(elementL3) {
                                                                        if(typeof elementL3 === 'string') {
                                                                            mapArrayL3.push(...parseFlashcardPhrases(elementL3, flash, index))
                                                                        } else {
                                                                            let mapL4 = [];
                                                                            const objectL4 = elementL3.props.children;

                                                                            if(objectL4) {
                                                                                if(typeof objectL4 === 'string') {
                                                                                    mapL4.push(...parseFlashcardPhrases(objectL4, flash, index))
                                                                                } else {
                                                                                    if(Array.isArray(objectL4)) {
                                                                                        let mapArrayL4 = [];

                                                                                        for(const elementL4 of objectL4) {
                                                                                            if(elementL4) {
                                                                                                if(typeof elementL4 === 'string') {
                                                                                                    mapArrayL4.push(...parseFlashcardPhrases(elementL4, flash, index))
                                                                                                } else {
                                                                                                    let mapL5 = [];
                                                                                                    const objectL5 = elementL4.props.children;

                                                                                                    if(objectL5) {
                                                                                                        if(typeof objectL5 === 'string') {
                                                                                                            mapL5.push(...parseFlashcardPhrases(objectL5, flash, index))
                                                                                                        } else {
                                                                                                            if(Array.isArray(objectL5)) {
                                                                                                                let mapArrayL5 = [];

                                                                                                                for(const elementL5 of objectL5) {
                                                                                                                    if(elementL5) {
                                                                                                                        if(typeof elementL5 === 'string') {
                                                                                                                            mapArrayL5.push(...parseFlashcardPhrases(elementL5, flash, index))
                                                                                                                        } else {
                                                                                                                            let mapL6 = [];
                                                                                                                            const objectL6 = elementL5.props.children;

                                                                                                                            if(objectL6) {
                                                                                                                                if(typeof objectL6 === 'string') {
                                                                                                                                    mapL6.push(...parseFlashcardPhrases(objectL6, flash, index))
                                                                                                                                } else {
                                                                                                                                    mapL6.push(objectL6)
                                                                                                                                }
                                                                                                                            }

                                                                                                                            mapArrayL5.push(React.cloneElement(elementL5, {children: mapL6}))
                                                                                                                        }
                                                                                                                    }
                                                                                                                }

                                                                                                                mapL5.push(...mapArrayL5)
                                                                                                            } else {
                                                                                                                mapL5.push(objectL5)
                                                                                                            }
                                                                                                        }
                                                                                                    }

                                                                                                    if(elementL4.type === 'br') {
                                                                                                        mapArrayL4.push(elementL4);
                                                                                                    } else {
                                                                                                        mapArrayL4.push(React.cloneElement(elementL4, {children: mapL5}))
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }

                                                                                        mapL4.push(...mapArrayL4)
                                                                                    } else {
                                                                                        mapL4.push(objectL4)
                                                                                    }
                                                                                }
                                                                            }

                                                                            if(elementL3?.type === 'br') {
                                                                                mapArrayL3.push(elementL3)
                                                                            } else {
                                                                                mapArrayL3.push(React.cloneElement(elementL3, {children: mapL4}))
                                                                            }
                                                                        }
                                                                    }
                                                                }

                                                                mapL3.push(...mapArrayL3);
                                                            } else {
                                                                let mapL4 = [];
                                                                const objectL4 = objectL3.props.children;

                                                                if(typeof objectL4 === 'string') {
                                                                    mapL4.push(...parseFlashcardPhrases(objectL4, flash, index))
                                                                } else {
                                                                    let mapArrayL4 = [];

                                                                    if(Array.isArray(objectL4)) {
                                                                        for(const elementL4 of objectL4) {
                                                                            if(typeof elementL4 === 'string') {
                                                                                mapArrayL4.push(...parseFlashcardPhrases(elementL4, flash, index))
                                                                            } else {
                                                                                let mapL5 = [];
                                                                                const objectL5 = elementL4.props.children;

                                                                                if(typeof objectL5 === 'string') {
                                                                                    mapL5.push(...parseFlashcardPhrases(elementL4, flash, index))
                                                                                } else {
                                                                                    if(Array.isArray(objectL5)) {
                                                                                        let mapArrayL5 = [];

                                                                                        for(const elementL5 of objectL5) {
                                                                                            if(typeof elementL5 === 'string') {
                                                                                                mapArrayL5.push(...parseFlashcardPhrases(elementL5, flash, index))
                                                                                            } else {
                                                                                                let mapL6 = [];
                                                                                                const objectL6 = elementL5.props.children;

                                                                                                if(typeof objectL6 === 'string') {
                                                                                                    mapL6.push(...parseFlashcardPhrases(objectL6, flash, index))
                                                                                                } else {
                                                                                                    mapL6.push(elementL5);
                                                                                                }

                                                                                                if(elementL5?.type === 'br') {
                                                                                                    mapArrayL5.push(elementL5)
                                                                                                } else {
                                                                                                    mapArrayL5.push(React.cloneElement(elementL5, { children: mapL6}))
                                                                                                }
                                                                                            }
                                                                                        }

                                                                                        mapL5.push(...mapArrayL5)
                                                                                    } else {
                                                                                        mapL5.push(objectL5)
                                                                                    }
                                                                                }

                                                                                if(elementL4?.type === 'br') {
                                                                                    mapArrayL4.push(elementL4)
                                                                                } else {
                                                                                    mapArrayL4.push(React.cloneElement(elementL4, {children: mapL5}))
                                                                                }
                                                                            }
                                                                        }
                                                                    } else {
                                                                        mapArrayL4.push(objectL4)
                                                                    }

                                                                    mapL4.push(...mapArrayL4)
                                                                }

                                                                if(objectL4?.type === 'br') {
                                                                    mapL3.push(objectL4)
                                                                } else {
                                                                    mapL3.push(React.cloneElement(setObjectKey(objectL3), {children: mapL4}))
                                                                }
                                                                
                                                            }
                                                        }
                                                    }
                                                    
                                                    if(elementL2.type === 'br') {
                                                        mapArrayL2.push(elementL2)
                                                    } else {
                                                        mapArrayL2.push(React.cloneElement(elementL2, { children: mapL3 }))
                                                    }
                                                }
                                            }
                                        }

                                        mapL2.push(React.cloneElement(elementL1, { children: mapArrayL2}))
                                    } else {
                                        let mapL3 = [];
                                        const objectL3 = objectL2.props.children;

                                        if(typeof objectL3 === 'string') {
                                            mapL3.push(React.cloneElement(setObjectKey(objectL2), { children: parseFlashcardPhrases(objectL3, flash, index)}))
                                        } else {
                                            if(Array.isArray(objectL3)) {
                                                mapL3.push(objectL3)
                                            } else {
                                                let mapL4 = [];
                                                const objectL4 = objectL3.props.children;

                                                if(typeof objectL4 === 'string') {
                                                    mapL4.push(React.cloneElement(setObjectKey(objectL3), { children: parseFlashcardPhrases(objectL4, flash, index)}))
                                                } else {
                                                    if(Array.isArray(objectL4)) {
                                                        let mapArrayL4 = [];

                                                        for(const elementL4 of objectL4) {
                                                            if(typeof elementL4 === 'string') {
                                                                mapArrayL4.push(...parseFlashcardPhrases(elementL4, flash, index))
                                                            } else {
                                                                let mapL5 = [];
                                                                const objectL5 = elementL4.props.children;

                                                                if(typeof objectL5 === 'string') {
                                                                    mapL5.push(React.cloneElement(elementL4, { children: parseFlashcardPhrases(objectL5, flash, index)}))
                                                                } else {
                                                                    if(Array.isArray(objectL5)) {
                                                                        mapL5.push(objectL5)
                                                                    } else {
                                                                        let mapL6 = [];
                                                                        const objectL6 = objectL5.props.children;

                                                                        if(typeof objectL6 === 'string') {
                                                                            mapL6.push(...parseFlashcardPhrases(objectL6, flash, index))
                                                                        } else {
                                                                            mapL6.push(objectL6);
                                                                        }

                                                                        mapL5.push(React.cloneElement(setObjectKey(objectL5), {children: mapL6}))
                                                                    }
                                                                }

                                                                mapArrayL4.push(...mapL5);
                                                            }
                                                        }

                                                        mapL4.push(React.cloneElement(setObjectKey(objectL3), { children: mapArrayL4}))
                                                    } else {
                                                        let mapL5 = [];
                                                        const objectL5 = objectL4.props.children;
            
                                                        if(typeof objectL5 === 'string') {
                                                            mapL5.push(React.cloneElement(setObjectKey(objectL4), { children: parseFlashcardPhrases(objectL5, flash, index)}))
                                                        } else {
                                                            mapL5.push(objectL5)
                                                        }
            
                                                        mapL4.push(React.cloneElement(setObjectKey(objectL3), {children: mapL5}))
                                                    }
                                                }
                                                
                                                mapL3.push(React.cloneElement(setObjectKey(objectL2), {children: mapL4}))
                                            }
                                        }

                                        mapL2.push(React.cloneElement(elementL1, {children: mapL3 }))
                                    }
                                }
                            }

                            if(elementL1?.type === 'br') {
                                mapL2.push(elementL1);
                            }
        
                            mapL1.push(...mapL2)
                        }
                    }
                    

                    indexObject++;
                    index = indexContent.toString() + indexObject.toString();
                }
            } else {
                let mapL2 = [];
                const objectL2 = objectL1.props.children;

                if(typeof objectL2 === 'string') {
                    mapL2.push(React.cloneElement(setObjectKey(objectL1), { children: parseFlashcardPhrases(objectL2, flash, index)}))
                } else {
                    if(Array.isArray(objectL2)) {
                        let mapArrayL2 = [];

                        for(const elementL2 of objectL2) {
                            if(typeof elementL2 === 'string') {
                                mapArrayL2.push(...parseFlashcardPhrases(elementL2, flash, index))
                            } else {
                                let mapL3 = [];
                                const objectL3 = elementL2.props.children;

                                if(typeof objectL3 === 'string') {
                                    mapL3.push(React.cloneElement(elementL2, { children: parseFlashcardPhrases(objectL3, flash, index)}))
                                } else {
                                    if(Array.isArray(objectL3)) {
                                        let mapArrayL3 = [];

                                        for(const elementL3 of objectL3) {
                                            if(typeof elementL3 === 'string') {
                                                mapArrayL3.push(...parseFlashcardPhrases(elementL3, flash, index))
                                            } else {
                                                let mapL4 = [];
                                                const objectL4 = elementL3.props.children;

                                                if(typeof objectL4 === 'string') {
                                                    mapL4.push(React.cloneElement(elementL3, { children: parseFlashcardPhrases(objectL4, flash, index)}))
                                                } else {
                                                    if(Array.isArray(objectL4)) {
                                                        let mapArrayL4 = [];
    
                                                        for(const elementL4 of objectL4) {
                                                            if(typeof elementL4 === 'string') {
                                                                mapArrayL4.push(...parseFlashcardPhrases(elementL4, flash, index))
                                                            } else {
                                                                mapArrayL4.push(elementL4)
                                                            }
                                                        }
    
                                                        mapL4.push(React.cloneElement(setObjectKey(objectL1), {children: mapArrayL4}))
                                                    }
                                                }
                                                
                                                mapArrayL3.push(...mapL4)
                                            }
                                        }

                                        mapL3.push(React.cloneElement(setObjectKey(elementL2), {children: mapArrayL3}))
                                    } else {
                                        mapL3.push(objectL3)
                                    }
                                }

                                mapArrayL2.push(...mapL3)
                            }
                        }

                        mapL2.push(React.cloneElement(setObjectKey(objectL1), {children: mapArrayL2}))
                    } else {
                        let mapL3 = [];
                        const objectL3 = objectL2.props.children;

                        if(typeof objectL3 === 'string') {
                            mapL3.push(React.cloneElement(setObjectKey(objectL2), { children: parseFlashcardPhrases(objectL3, flash, index)}))
                        } else {
                            if(Array.isArray(objectL3)) {
                                let mapArrayL3 = [];

                                for(const elementL3 of objectL3) {
                                    if(typeof elementL3 === 'string') {
                                        mapArrayL3.push(...parseFlashcardPhrases(elementL3, flash, index))
                                    } else {
                                        if(Array.isArray(elementL3)) {
                                            mapArrayL3.push(elementL3)
                                        } else {
                                            let mapL4 = [];
                                            const objectL4 = elementL3.props.children;

                                            if(typeof objectL4 === 'string') {
                                                mapL4.push(React.cloneElement(setObjectKey(elementL3), { children: parseFlashcardPhrases(objectL4, flash, index)}))
                                            } else {
                                                mapL4.push(objectL4)
                                            }

                                            mapArrayL3.push(...mapL4)
                                        }
                                    }
                                }

                                mapL3.push(React.cloneElement(setObjectKey(objectL2), {children: mapArrayL3}))
                            } else {
                                let mapL4 = [];
                                const objectL4 = objectL3.props.children;

                                if(typeof objectL4 === 'string') {
                                    mapL4.push(React.cloneElement(setObjectKey(objectL3), { children: parseFlashcardPhrases(objectL4, flash, index)}))
                                } else {
                                    if(Array.isArray(objectL4)) {
                                        let mapArrayL4 = [];

                                        for(const elementL4 of objectL4) {
                                            if(typeof elementL4 === 'string') {
                                                mapArrayL4.push(...parseFlashcardPhrases(elementL4, flash, index))
                                            } else {
                                                if(Array.isArray(elementL4)) {
                                                    mapArrayL4.push(elementL4);
                                                } else {
                                                    let mapL5 = [];
                                                    const objectL5 = elementL4.props.children;

                                                    if(typeof objectL5 === 'string') {
                                                        mapL5.push(React.cloneElement(setObjectKey(elementL4), { children: parseFlashcardPhrases(objectL5, flash, index)}))
                                                    } else {
                                                        mapL5.push(objectL5)
                                                    }

                                                    mapArrayL4.push(...mapL5)
                                                }
                                            }
                                        }

                                        mapL4.push(React.cloneElement(setObjectKey(objectL3), {children: mapArrayL4}))
                                    } else {
                                        let mapL5 = [];
                                        const objectL5 = objectL4.props.children;

                                        if(typeof objectL5 === 'string') {
                                            mapL5.push(React.cloneElement(setObjectKey(objectL4), { children: parseFlashcardPhrases(objectL5, flash, index)}))
                                        } else {
                                            mapL5.push(objectL5)
                                        }

                                        mapL4.push(React.cloneElement(setObjectKey(objectL3), {children: mapL5}))
                                    }
                                }

                                mapL3.push(React.cloneElement(setObjectKey(objectL2), {children: mapL4}))
                            }
                        }

                        mapL2.push(...mapL3);
                    }
                }

                if(objectL1.type === 'br') {
                    mapL2.push(objectL1)
                }

                mapL1.push(React.cloneElement(setObjectKey(objectL1), {children: mapL2}))
            }
        }
    }

    if (mapL1.length > 0) {
        return React.cloneElement(object, { children: mapL1 });
    } else {
        return object;
    }
};

export default parseFlashcardObject;
