const getLoaderSize = (size, isBoolean) => {
    switch (size) {
        case "medium":
            return !isBoolean ? " _size--medium" : false;
        case "small":
            return !isBoolean ? " _size--small" : false;
        default:
            return !isBoolean ? "" : true;
    }
};

const getLoader = (type, color, size) => {
    switch (type) {
        case "ellipsis":
            return (
                <span
                    className={
                        "ReaderLoader__Ellipsis" +
                        (color ? " _color--" + color : "") +
                        getLoaderSize(size)
                    }
                >
                    <span className="ReaderLoader__Circle _circle--1"></span>
                    <span className="ReaderLoader__Circle _circle--2"></span>
                    <span className="ReaderLoader__Circle _circle--3"></span>
                    <span className="ReaderLoader__Circle _circle--4"></span>
                </span>
            );
        default:
            return (
                <span
                    className={
                        "ReaderLoader__Default" +
                        (color ? " _color--" + color : "") +
                        getLoaderSize(size)
                    }
                >
                    <span className="ReaderLoader__Circle _circle--1"></span>
                    <span className="ReaderLoader__Circle _circle--2"></span>
                    <span className="ReaderLoader__Circle _circle--3"></span>
                    <span className="ReaderLoader__Circle _circle--4"></span>
                    <span className="ReaderLoader__Circle _circle--5"></span>
                    <span className="ReaderLoader__Circle _circle--6"></span>
                    <span className="ReaderLoader__Circle _circle--7"></span>
                    <span className="ReaderLoader__Circle _circle--8"></span>
                    {getLoaderSize(size, true) ? (
                        <>
                            <span className="ReaderLoader__Circle _circle--9"></span>
                            <span className="ReaderLoader__Circle _circle--10"></span>
                            <span className="ReaderLoader__Circle _circle--11"></span>
                            <span className="ReaderLoader__Circle _circle--12"></span>
                        </>
                    ) : (
                        ""
                    )}
                </span>
            );
    }
};

const ReaderLoader = ({ type, color, size }) => {
    return getLoader(type, color, size);
};

export default ReaderLoader;
