import ButtonTabSigning from "../../components/buttons/signing/ButtonTabSigning";
import SigningTabsContainer from "../../components/containers/signing/SigningTabsContainer";

import ResetForm from "../../components/forms/signing/ResetForm";

const FirstLoginReset = ({ isFirstLogin }) => {
    return (
        <>
            <div className="app-signing">
                <SigningTabsContainer>
                    <ButtonTabSigning isDisabled isActive={true}>
                        Logowanie
                    </ButtonTabSigning>
                    <ButtonTabSigning isDisabled isActive={false}>
                        Rejestracja
                    </ButtonTabSigning>
                </SigningTabsContainer>
                <ResetForm isFirstLogin={isFirstLogin} />
            </div>
        </>
    );
};

export default FirstLoginReset;
