const ButtonTabSigning = ({ children, isDisabled, isActive, onTabClick }) => {
    return (
        <button
            className={"tab-button" + (isActive ? " active" : "")}
            type="button"
            disabled={isDisabled}
            onClick={onTabClick}
        >
            {children}
        </button>
    );
};

export default ButtonTabSigning;
