const fontsData = [
    {
        fontFamily: "Nunito",
        text: "Domyślna",
        name: "default",
    },
    {
        fontFamily: "Algreya",
        text: "Algreya",
        name: "algreya",
    },
    {
        fontFamily: "Jost",
        text: "Jost",
        name: "jost",
    },
    {
        fontFamily: "Roboto",
        text: "Roboto",
        name: "roboto",
    },
    {
        fontFamily: "EBGaramond",
        text: "Garamond",
        name: "ebgaramond",
    },
];

const ReaderFont = ({ color, fontFamily, setFontFamily }) => {
    return (
        <>
            <div className={"ReaderFont" + (color ? " _color--" + color : "")}>
                {fontsData
                    ? fontsData.map((object) => {
                          return (
                              <div
                                  className="ReaderFont__Item"
                                  key={object.text}
                              >
                                  <button
                                      className={
                                          "ReaderFont__Button" +
                                          (fontFamily.name === object.name
                                              ? " _selected"
                                              : "")
                                      }
                                      type="button"
                                      disabled={fontFamily.name === object.name}
                                      onClick={() =>
                                          setFontFamily({
                                              family: object.fontFamily,
                                              name: object.name,
                                          })
                                      }
                                  >
                                      <span
                                          className="ReaderFont__Preview"
                                          style={{
                                              fontFamily: object.fontFamily,
                                          }}
                                      >
                                          aA
                                      </span>
                                      <span className="ReaderFont__Name">
                                          {object.text}
                                      </span>
                                  </button>
                              </div>
                          );
                      })
                    : ""}
            </div>
        </>
    );
};

export default ReaderFont;
