const loginErrorHandler = (statusCode, responseError, onInvalid) => {
    if (statusCode === 400) {
        if (
            responseError === "INCORRECT_PASSWORD" ||
            responseError.email === "INVALID_EMAIL"
        ) {
            onInvalid();
        }
    }
};

export default loginErrorHandler;
