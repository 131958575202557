import { useRef, useContext } from "react";
import { useMutation } from "react-query";

import { StoreContext } from "../../../context/Store";

const useChapterSavePage = (idBook, page) => {
    const pageLast = useRef(null);

    const { api } = useContext(StoreContext);

    const { mutate: savePage, isSuccess: isSavePageSuccess } = useMutation(() =>
        api.post(`/multibooks/chapter`, {
            idMultibook: idBook,
            page,
        })
    );

    const handleSavePage = () => {
        if (page !== pageLast.current) {
            savePage();

            pageLast.current = page;
        } else {
            pageLast.current = page;
        }
    };

    return { savePage: handleSavePage, isSavePageSuccess };
};

export default useChapterSavePage;
