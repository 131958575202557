import { useState, useRef, useEffect, useContext } from "react";
import { useMutation } from "react-query";
import { useCookies } from "react-cookie";
import { CSSTransition } from "react-transition-group";

import { StoreContext } from "../../../context/Store";

import useLogin from "../../../hooks/useLogin";

import returnArrayOfNestedProperties from "../../../functions/returnArrayOfNestedProperties";
import registerErrorHandler from "../../../functions/errorHandlers/registerErrorHandler";
import scrollTo from "../../../functions/scrollTo";
import validator from "../../../functions/validator";

import Title from "../../headings/Title";
import Input from "../../inputs/Input";
import Button from "../../buttons/Button";
import Checkbox from "../../inputs/Checkbox";
import ButtonReadMore from "../../buttons/ButtonReadMore";

import Modal from "../../modals/Modal";
import ModalHeading from "../../headings/ModalHeading";

import ButtonRole from "../../buttons/signing/ButtonRole";

import SigningFormContainer from "../../containers/signing/SigningFormContainer";
import SigningFormPasswordContainer from "../../containers/signing/SigningFormPasswordContainer";
import SigningRulesContainer from "../../containers/signing/SigningRulesContainer";
import ButtonRuleSigning from "../../buttons/signing/ButtonRuleSigning";
import InputMasked from "../../inputs/InputMasked";
import RepeaterSelect from "../../inputs/RepeaterSelect";
import { getCookieTime } from "../../../App";

const getInputMaskedErrorMessage = (key) => {
    switch (key) {
        case "birthdate":
            return "Nieprawidłowa data urodzenia (DD-MM-YYYY)";
        case "postcode":
            return "Nieprawidłowy kod pocztowy (00-000)";
        default:
            return "";
    }
};

const RegisterForm = ({
    inputData,
    inputValidatorData,
    passwordData,
    passwordValidatorData,
    passwordComparatorData,
    roleData,
    agreementData,
    agreementValidatorData,
    modalParentData,
    modalParentValidatorData,
    inputUpdate,
    inputValidatorUpdate,
    passwordUpdate,
    passwordValidatorUpdate,
    passwordComparatorUpdate,
    roleUpdate,
    agreementUpdate,
    agreementValidatorUpdate,
    modalParentUpdate,
    modalParentValidatorUpdate,
    modalSetters,
    isSigningLoading,
    setIsSigningLoading,
    isSigningChildrenLoading,
    setIsSigningChildrenLoading,
}) => {
    const { api } = useContext(StoreContext);

    const login = useLogin();

    const setCookie = useCookies(["schoolsEmail"])[1];

    const { mutate: register } = useMutation(
        () =>
            api.post(`/register`, {
                email: inputData.email,
                name: inputData.name,
                surname: inputData.surname,
                password: passwordData.origin,
                password2: passwordData.repeated,
                phone: inputData.phone,
                idRank: roleData.value,
                birthdate: inputData.birthdate,
                zipCode: inputData.postcode,
                agreement_1: agreementData.agreement_1,
                agreement_2: agreementData.agreement_2,

                // child fields
                parentEmail: modalParentData.parentEmail,
                idSchool: inputData.studentSchool[0]?.idSchool,
                class: inputData.studentClass,

                // teacher fields
                schools: returnArrayOfNestedProperties(
                    inputData.teacherSchools,
                    "idSchool"
                ),
                subjects: returnArrayOfNestedProperties(
                    inputData.teacherSubjects,
                    "idSubject"
                ),
            }),
        {
            onSuccess: (data) => {
                if (data.data.data.rank.idRanks === 4) {
                    setCookie("schoolsEmail", data.data.data.schools_email, {
                        path: "/",
                        expires: getCookieTime(),
                    });
                }

                login(
                    {
                        token: data.data.data.token,
                        idRank: data.data.data.rank.idRanks,
                        active: data.data.data.active,
                    },
                    "/dashboard"
                );
            },
            onError: (error) => {
                registerErrorHandler(
                    error.response.status,
                    error.response.data.errors,
                    () => {
                        inputValidatorUpdate({
                            ...inputValidatorData,
                            email: {
                                response: "error",
                                errorMessage:
                                    "Konto o podanym adresie e-mail już istnieje",
                            },
                        });
                        if (modalParentRegister) {
                            setModalParentRegister(false);
                        }
                        scrollTo("#email");
                    }
                );

                setIsSigningLoading(false);
            },
        }
    );

    /* INPUT STANDARD ON CHANGE HANDLER */
    const inputOnChangeHandler = (e, key, min, max) => {
        if (e.length > 0) {
            if (min) {
                if (e.length >= min) {
                    inputValidatorUpdate({
                        [key]: {
                            response: "success",
                            errorMessage: "",
                        },
                    });
                } else {
                    inputValidatorUpdate({
                        [key]: {
                            response: "error",
                            errorMessage: "",
                        },
                    });
                }
            } else {
                inputValidatorUpdate({
                    [key]: {
                        response: "success",
                        errorMessage: "",
                    },
                });
            }
        } else {
            inputValidatorUpdate({
                [key]: {
                    response: "",
                    errorMessage: "",
                },
            });
        }

        if (max) {
            if (e.length <= max) {
                inputUpdate({ [key]: e });
            }
        } else {
            inputUpdate({ [key]: e });
        }
    };

    /* BIRTHMAXDATE CURRENT - 1 */
    const birthMaxDate = new Date(
        new Date().setFullYear(new Date().getFullYear() - 1)
    ).getFullYear();

    /* E-MAIL ON CHANGE HANDLER */
    const emailOnChangeHandler = (e) => {
        if (e.currentTarget.value.length > 0) {
            validator("email", e.currentTarget.value)
                ? inputValidatorUpdate({
                      ...inputValidatorData,
                      email: {
                          response: "success",
                          errorMessage: "",
                      },
                  })
                : inputValidatorUpdate({
                      ...inputValidatorData,
                      email: {
                          response: "error",
                          errorMessage: "Nieprawidłowy adres e-mail",
                      },
                  });
        } else {
            inputValidatorUpdate({
                ...inputValidatorData,
                email: {
                    response: "",
                    errorMessage: "",
                },
            });
        }

        if (modalParentData.parentEmail.length > 0) {
            modalParentUpdate({ parentEmail: "" });
            modalParentValidatorUpdate({ response: "", errorMessage: "" });
        }

        inputUpdate({ email: e.currentTarget.value });
    };

    /* PASSWORD VALIDATION */
    useEffect(() => {
        const passwordObject = {
            lowercase: validator("lowercase", passwordData.origin),
            uppercase: validator("uppercase", passwordData.origin),
            length: validator("length", passwordData.origin),
            special: validator("special", passwordData.origin),
        };

        passwordValidatorUpdate(passwordObject);
    }, [passwordData.origin]);

    /* PASSWORD COMPARISION */
    useEffect(() => {
        if (
            passwordData.origin.length > 0 ||
            passwordData.repeated.length > 0
        ) {
            if (
                passwordValidatorData.uppercase &&
                passwordValidatorData.lowercase &&
                passwordValidatorData.length &&
                passwordValidatorData.special
            ) {
                if (passwordData.repeated.length === 0) {
                    passwordComparatorUpdate({
                        origin: "success",
                        repeated: "",
                    });
                } else {
                    if (passwordData.origin === passwordData.repeated) {
                        passwordComparatorUpdate({
                            origin: "success",
                            repeated: "success",
                        });
                    } else {
                        passwordComparatorUpdate({
                            origin: "error",
                            repeated: "error",
                        });
                    }
                }
            } else {
                passwordComparatorUpdate((prevState) => ({
                    origin: "error",
                    repeated: prevState.repeated,
                }));
            }
        } else {
            if (
                passwordData.origin.length === 0 &&
                passwordData.repeated.length === 0
            ) {
                passwordComparatorUpdate({
                    origin: "",
                    repeated: "",
                });
            }
        }
    }, [
        passwordData.origin,
        passwordData.repeated,
        passwordValidatorData.uppercase,
        passwordValidatorData.lowercase,
        passwordValidatorData.length,
        passwordValidatorData.special,
    ]);

    /* INPUT MASKED ON CHANGE HANDLER */
    const inputMaskedOnChangeHandler = (e, key) => {
        inputUpdate({ [key]: e.value });

        inputValidatorUpdate({
            ...inputValidatorData,
            [key]: {
                response: e.response,
                errorMessage: getInputMaskedErrorMessage(key),
            },
        });
    };

    /* ROLE MODAL */
    const [modalRole, setModalRole] = useState(false);
    const modalRoleRef = useRef(null);

    /* ROLE BUTTON HANDLER */
    const roleClickHandler = (obj) => {
        setModalRole(false);
        roleUpdate(obj);
    };

    /* ENABLE SUBMIT IF VALIDATION IS CORRECT */
    const formSubmitDisable =
        inputValidatorData.name.response === "success" &&
        inputValidatorData.surname.response === "success" &&
        inputValidatorData.email.response === "success" &&
        inputValidatorData.phone.response === "success" &&
        passwordValidatorData.uppercase &&
        passwordValidatorData.lowercase &&
        passwordValidatorData.length &&
        passwordValidatorData.special &&
        passwordComparatorData.origin === "success" &&
        passwordComparatorData.repeated === "success" &&
        (roleData.value === 2 ||
            (roleData.value === 3 &&
                inputData.studentSchool.length > 0 &&
                inputValidatorData.studentClass.response === "success") ||
            (roleData.value === 4 &&
                inputData.teacherSchools.length > 0 &&
                inputData.teacherSubjects.length > 0)) &&
        inputValidatorData.postcode.response === "success" &&
        inputValidatorData.birthdate.response === "success"
            ? false
            : true;

    /* MODAL PARENT E-MAIL */
    const [modalParentRegister, setModalParentRegister] = useState(false);
    const modalParentRegisterRef = useRef(null);

    /* MODAL PARENT E-MAIL ON CHANGE HANDLER */
    const emailModalOnChangeHandle = (e) => {
        const email = e.currentTarget.value;

        if (email.length > 0) {
            validator("email", e.currentTarget.value)
                ? inputData.email !== email
                    ? modalParentValidatorUpdate({
                          response: "success",
                          errorMessage: "",
                      })
                    : modalParentValidatorUpdate({
                          response: "error",
                          errorMessage:
                              "Podany e-mail rodzica nie może być taki sam jak Twój",
                      })
                : modalParentValidatorUpdate({
                      response: "error",
                      errorMessage: "Nieprawidłowy adres e-mail",
                  });
        } else {
            modalParentValidatorUpdate({ response: "", errorMessage: "" });
        }

        modalParentUpdate({ parentEmail: e.currentTarget.value });
    };

    /* ON ENTER KEY */
    const onEnterKeyHandler = (e, children) => {
        const enterKey = e.charCode === 13 || e.keyCode === 13;

        if (!formModalParentDisable && enterKey) {
            e.currentTarget.blur();

            if (children) {
                setIsSigningChildrenLoading(true);
            } else {
                setIsSigningLoading(true);
            }

            register();
        }
    };

    /* ENABLE MODAL SUBMIT IF VALIDATION IS CORRECT */
    const formModalParentDisable =
        modalParentValidatorData.response === "success" ? false : true;

    const registerButtonOnClick = () => {
        if (!agreementData.agreement_1) {
            agreementValidatorUpdate({ agreement_1: true });
            scrollTo("#requiredAgreement1");
            return;
        }

        if (roleData.value === 3) {
            setModalParentRegister(true);
            return;
        }

        setIsSigningLoading(true);

        register();
    };

    return (
        <>
            <div className="app-signing-form">
                <Title className="text-center">
                    Zarejestruj się do How2 edu
                </Title>
                <SigningFormContainer classNameWrapper="signing">
                    <Input
                        key="Input"
                        type="text"
                        value={inputData.name}
                        placeholder="wpisz imię"
                        isDisabled={
                            isSigningLoading || isSigningChildrenLoading
                        }
                        isValid={inputValidatorData.name}
                        onChangeFn={(e) =>
                            inputOnChangeHandler(
                                e.currentTarget.value,
                                "name",
                                3,
                                30
                            )
                        }
                    />
                    <Input
                        key="Input"
                        type="text"
                        value={inputData.surname}
                        placeholder="wpisz nazwisko"
                        isDisabled={
                            isSigningLoading || isSigningChildrenLoading
                        }
                        onChangeFn={(e) =>
                            inputOnChangeHandler(
                                e.currentTarget.value,
                                "surname",
                                3,
                                30
                            )
                        }
                        isValid={inputValidatorData.surname}
                    />
                    <Input
                        id="email"
                        key="Input"
                        type="email"
                        value={inputData.email}
                        placeholder="wpisz e-mail"
                        isDisabled={
                            isSigningLoading || isSigningChildrenLoading
                        }
                        isValid={{
                            response: inputValidatorData.email.response,
                            text: inputValidatorData.email.errorMessage,
                        }}
                        onChangeFn={(e) => emailOnChangeHandler(e)}
                    />
                    <SigningFormContainer
                        classNameWrapper="signing"
                        classNameGroup="password"
                    >
                        <Input
                            key="Input"
                            type="password"
                            placeholder="wpisz hasło"
                            value={passwordData.origin}
                            isDisabled={
                                isSigningLoading || isSigningChildrenLoading
                            }
                            isValid={{
                                response: passwordComparatorData.origin,
                            }}
                            onChangeFn={(e) =>
                                passwordUpdate({
                                    origin: e.currentTarget.value,
                                })
                            }
                        />
                        <Input
                            key="Input"
                            type="password"
                            placeholder="powtórz hasło"
                            value={passwordData.repeated}
                            isDisabled={
                                isSigningLoading || isSigningChildrenLoading
                            }
                            isValid={{
                                response: passwordComparatorData.repeated,
                                text: "Niezgodne hasła",
                            }}
                            onChangeFn={(e) =>
                                passwordUpdate({
                                    repeated: e.currentTarget.value,
                                })
                            }
                        />
                        <SigningFormPasswordContainer text="Hasło powinno zawierać">
                            <Checkbox
                                isDisabled={true}
                                isChecked={passwordValidatorData.uppercase}
                            >
                                wielką literę
                            </Checkbox>
                            <Checkbox
                                isDisabled={true}
                                isChecked={passwordValidatorData.length}
                            >
                                od 8 do 50 znaków
                            </Checkbox>
                            <Checkbox
                                isDisabled={true}
                                isChecked={passwordValidatorData.lowercase}
                            >
                                małą literę
                            </Checkbox>
                            <Checkbox
                                isDisabled={true}
                                isChecked={passwordValidatorData.special}
                            >
                                cyfrę lub znak specjalny
                            </Checkbox>
                        </SigningFormPasswordContainer>
                    </SigningFormContainer>
                    <Input
                        key="Input"
                        type="tel"
                        value={inputData.phone}
                        maxLength={12}
                        placeholder="podaj numer telefonu"
                        isDisabled={
                            isSigningLoading || isSigningChildrenLoading
                        }
                        isValid={inputValidatorData.phone}
                        onChangeFn={(e) =>
                            inputOnChangeHandler(
                                e.currentTarget.value,
                                "phone",
                                9,
                                12
                            )
                        }
                    />
                    <InputMasked
                        key="InputMasked"
                        value={inputData.postcode}
                        placeholder="wpisz kod pocztowy"
                        placeholderFocus="00-000"
                        fields={[{ rows: 2 }, { rows: 3 }]}
                        separator="-"
                        errorMessage={inputValidatorData.postcode.errorMessage}
                        isDisabled={
                            isSigningLoading || isSigningChildrenLoading
                        }
                        isValid={inputValidatorData.postcode.response}
                        onChangeFn={(e) =>
                            inputMaskedOnChangeHandler(e, "postcode")
                        }
                    />
                    <InputMasked
                        key="InputMasked"
                        value={inputData.birthdate}
                        placeholder="dodaj datę urodzenia"
                        placeholderFocus="dd-mm-rrrr"
                        fields={[
                            { rows: 2, min: 1, max: 31 },
                            { rows: 2, min: 1, max: 12 },
                            { rows: 4, min: 1900, max: birthMaxDate },
                        ]}
                        separator="-"
                        errorMessage={inputValidatorData.birthdate.errorMessage}
                        isDisabled={
                            isSigningLoading || isSigningChildrenLoading
                        }
                        isValid={inputValidatorData.birthdate.response}
                        onChangeFn={(e) =>
                            inputMaskedOnChangeHandler(e, "birthdate")
                        }
                    />
                    <Button
                        key="Button"
                        className={
                            "input" +
                            (modalRole ? " is-active" : "") +
                            (roleData.value ? " is-active" : "")
                        }
                        isDisabled={
                            isSigningLoading || isSigningChildrenLoading
                        }
                        onClickFn={() => setModalRole(true)}
                    >
                        {roleData.value ? roleData.text : "wybierz rolę"}
                    </Button>
                    {roleData.value === 3 && (
                        <>
                            <SigningFormContainer
                                classNameWrapper="signing"
                                classNameGroup="student"
                            >
                                <RepeaterSelect
                                    key="RepeaterSelect"
                                    placeholder={{
                                        origin: "Wybierz szkołę",
                                    }}
                                    data={inputData.studentSchool}
                                    returnedData={(array) =>
                                        inputUpdate({
                                            studentSchool: array,
                                        })
                                    }
                                    query={{
                                        url: "/school_list?search=",
                                        queryKey: "registerModalSchools",
                                        dataKey: {
                                            id: "idSchool",
                                            name: "name",
                                        },
                                        backendSearch: true,
                                    }}
                                    max={1}
                                    modalSetters={modalSetters}
                                    isDisabled={
                                        isSigningLoading ||
                                        isSigningChildrenLoading
                                    }
                                />
                                <Input
                                    key="Input"
                                    type="text"
                                    value={inputData.studentClass}
                                    maxLength={5}
                                    placeholder="wpisz klasę"
                                    placeholderFocus="1A"
                                    classNameWrapper={
                                        inputData.studentClass
                                            ? " is-filled"
                                            : ""
                                    }
                                    isValid={inputValidatorData.studentClass}
                                    isDisabled={
                                        isSigningLoading ||
                                        isSigningChildrenLoading
                                    }
                                    onChangeFn={(e) =>
                                        inputOnChangeHandler(
                                            e.currentTarget.value.toUpperCase(),
                                            "studentClass",
                                            2,
                                            5
                                        )
                                    }
                                />
                            </SigningFormContainer>
                        </>
                    )}
                    {roleData.value === 4 && (
                        <>
                            <SigningFormContainer
                                classNameWrapper="signing"
                                classNameGroup="teacher"
                            >
                                <RepeaterSelect
                                    key="RepeaterSelect"
                                    heading={{
                                        title: "Wybrane szkoły",
                                        text: "(Możesz wybrać maksymalnie [counter] szkół)",
                                    }}
                                    placeholder={{
                                        origin: "Wybierz szkołę",
                                        repeated: "Dodaj kolejną szkołę",
                                    }}
                                    data={inputData.teacherSchools}
                                    returnedData={(array) =>
                                        inputUpdate({
                                            teacherSchools: array,
                                        })
                                    }
                                    query={{
                                        url: "/school_list?search=",
                                        queryKey: "registerModalSchools",
                                        dataKey: {
                                            id: "idSchool",
                                            name: "name",
                                        },
                                        backendSearch: true,
                                    }}
                                    max={5}
                                    modalSetters={modalSetters}
                                    isDisabled={
                                        isSigningLoading ||
                                        isSigningChildrenLoading
                                    }
                                />
                                <RepeaterSelect
                                    key="RepeaterSelect"
                                    heading={{
                                        title: "Wybrane przedmioty",
                                        text: "Wybierz co najmniej jeden przedmiot",
                                    }}
                                    placeholder={{
                                        origin: "Wybierz swoje przedmioty",
                                    }}
                                    data={inputData.teacherSubjects}
                                    returnedData={(array) =>
                                        inputUpdate({
                                            teacherSubjects: array,
                                        })
                                    }
                                    query={{
                                        url: "/subject_list",
                                        queryKey: "registerModalSubjects",
                                        dataKey: {
                                            id: "idSubject",
                                            name: "name",
                                        },
                                    }}
                                    modalFontLarge
                                    modalSetters={modalSetters}
                                    isDisabled={
                                        isSigningLoading ||
                                        isSigningChildrenLoading
                                    }
                                />
                            </SigningFormContainer>
                        </>
                    )}
                    <Button
                        key="Button"
                        isLoading={isSigningLoading}
                        isDisabled={
                            formSubmitDisable ||
                            isSigningLoading ||
                            isSigningChildrenLoading
                        }
                        onClickFn={registerButtonOnClick}
                    >
                        Zarejestruj się
                    </Button>
                </SigningFormContainer>
                <SigningRulesContainer
                    text="Klikając „Zarejestruj się” akceptujesz regulamin"
                    separator="i"
                >
                    <ButtonRuleSigning>How2 Edu</ButtonRuleSigning>
                    <ButtonRuleSigning>how2edu.app</ButtonRuleSigning>
                </SigningRulesContainer>

                {agreementValidatorData.agreement_1 && (
                    <SigningRulesContainer errorMessage="* Zapoznaj się z Polityką Prywatności" />
                )}

                <SigningFormContainer
                    classNameWrapper="signing"
                    classNameGroup="agreements"
                >
                    <Checkbox
                        id="requiredAgreement1"
                        isChecked={agreementData.agreement_1}
                        onChangeFn={(e) => {
                            agreementUpdate({
                                agreement_1: e,
                            });
                            agreementValidatorUpdate({
                                agreement_1: false,
                            });
                        }}
                        isDisabled={
                            isSigningLoading || isSigningChildrenLoading
                        }
                        isInvalid={agreementValidatorData.agreement_1}
                    >
                        * Zapoznałem/zapoznałam się z{" "}
                        <Button className="inline">Polityką Prywatności</Button>{" "}
                        How2 Edu. Administratorem Twoich danych osobowych Jest
                        How2 sp. z o.o. z siedzibą w Lublinie. Twoje dane
                        Osobowe będą przetwarzane w celu rejestracji i obsługi{" "}
                        <ButtonReadMore text="pokaż więcej...">
                            konta użytkownika w celu umożliwienia dokonania
                            zakupów, w celach marketingowych, w tym profilowania
                            marketingowego, jak i również w celach
                            statystycznych i analitycznych administratora.
                        </ButtonReadMore>
                    </Checkbox>
                    <Checkbox
                        isDisabled={
                            isSigningLoading || isSigningChildrenLoading
                        }
                        isChecked={agreementData.agreement_2}
                        onChangeFn={(e) =>
                            agreementUpdate({
                                agreement_2: e,
                            })
                        }
                    >
                        Chcę otrzymywać od How2 Edu na wskazany przeze mnie
                        Adres e-mail, a także za pośrednictwem SMS/MMS,
                        Informacje marketingowe dotyczące aplikacji How2 Edu
                        Oraz partnerów biznesowych How2 sp. z o.o. Zgodę można{" "}
                        <ButtonReadMore text="pokaż więcej...">
                            lorem ipsum dolor sit amet consectetur adipisicing
                            elit. Molestiae quis tempora facilis ab ex magni
                            aliquam voluptates fugit id obcaecati.
                        </ButtonReadMore>
                    </Checkbox>
                </SigningFormContainer>
            </div>

            {/* PICK ROLE MODAL */}
            <CSSTransition
                nodeRef={modalRoleRef}
                in={modalRole}
                classNames="app-modal"
                timeout={300}
                unmountOnExit
                appear
            >
                <Modal
                    className="modal-roles"
                    ref={modalRoleRef}
                    onCloseClickFn={() => setModalRole(false)}
                >
                    <ModalHeading className="text-center">
                        WYBIERZ ROLĘ
                    </ModalHeading>
                    <div className="role-container">
                        <ButtonRole
                            isActive={roleData.value === 2}
                            roleText="Rodzic"
                            roleValue={2}
                            buttonText="wybierz"
                            onClickLift={(e) => roleClickHandler(e)}
                        />
                        <ButtonRole
                            isActive={roleData.value === 3}
                            roleText="Uczeń"
                            roleValue={3}
                            buttonText="wybierz"
                            onClickLift={(e) => roleClickHandler(e)}
                        />
                        <ButtonRole
                            isActive={roleData.value === 4}
                            roleText="Nauczyciel"
                            roleValue={4}
                            buttonText="wybierz"
                            onClickLift={(e) => roleClickHandler(e)}
                        />
                    </div>
                </Modal>
            </CSSTransition>

            {/* ENTER PARENT'S MAIL */}
            <CSSTransition
                nodeRef={modalParentRegisterRef}
                in={modalParentRegister}
                classNames="app-modal"
                timeout={300}
                unmountOnExit
                appear
            >
                <Modal
                    ref={modalParentRegisterRef}
                    isCloseDisabled={isSigningChildrenLoading}
                    onCloseClickFn={() => setModalParentRegister(false)}
                >
                    <ModalHeading
                        className="text-center"
                        subText="i wyślij link rejestracyjny do rodzica w celu potwierdzenia oraz rejestracji konta"
                    >
                        WPISZ ADRES E-MAIL RODZICA
                    </ModalHeading>
                    <SigningFormContainer
                        classNameWrapper="signing"
                        classNameGroup="modalGroup"
                    >
                        <Input
                            key="Input"
                            type="email"
                            value={modalParentData.parentEmail}
                            placeholder="wpisz e-mail"
                            isDisabled={
                                isSigningLoading || isSigningChildrenLoading
                            }
                            isValid={{
                                response: modalParentValidatorData.response,
                                text: modalParentValidatorData.errorMessage,
                            }}
                            onChangeFn={(e) => emailModalOnChangeHandle(e)}
                            onKeyPressFn={(e) => onEnterKeyHandler(e, true)}
                        />
                        <Button
                            key="Button"
                            isDisabled={
                                formModalParentDisable ||
                                isSigningLoading ||
                                isSigningChildrenLoading
                            }
                            isLoading={isSigningChildrenLoading}
                            onClickFn={() => {
                                setIsSigningChildrenLoading(true);

                                register();
                            }}
                        >
                            Zarejestruj się
                        </Button>
                    </SigningFormContainer>
                </Modal>
            </CSSTransition>
        </>
    );
};

export default RegisterForm;
