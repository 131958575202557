import { useEffect } from "react";
import { useHistory } from "react-router-dom";

import useChildList from "../../../hooks/useChildList";

import routeLoadingState from "../../../functions/routeLoadingState";

import Title from "../../../components/headings/Title";
import TextSmall from "../../../components/containers/TextSmall";
import Button from "../../../components/buttons/Button";

import DashboardContainer from "../../../components/containers/dashboard/DashboardContainer";
import Subscriber from "../../../components/containers/dashboard/subscriptions/Subscriber";

export const getStoreUrl = () => {
    const url = window.location.href;
    const doubleSlashIndex = url.indexOf("//");
    const dotIndex = url.indexOf(".");

    if (doubleSlashIndex !== -1 && dotIndex !== -1) {
        const env = url.substring(doubleSlashIndex + 2, dotIndex);

        return "http://" + (env !== "app" ? env : "") + "store.how2edu.pl";
    } else {
        return "http://devstore.how2edu.pl";
    }
};

const returnDate = (child) => {
    if (child.subscription.status === "waiting") {
        return undefined;
    }
    if (
        child.subscription.status === "active" ||
        child.subscription.status === "expiring" ||
        child.subscription.status === "inactive" //inactive can still return valid date OR null
    ) {
        return child.subscription.active_to?.substring(0, 10);
    }
};

const Subscriptions = ({ routeMatches, routeSetters }) => {
    const { setRouteData, setRouteIsLoading } = routeSetters;

    const history = useHistory();

    const { childList, isChildListFetched, isChildListLoading } =
        useChildList();

    /* ROUTE LOADING */
    const routeIsLoading = routeLoadingState({
        fetched: [isChildListFetched],
        loading: [isChildListLoading],
    });

    /* ROUTE SETTERS */
    useEffect(() => {
        const routeData = {
            title: "ABONAMENTY",
        };

        if (routeMatches) {
            setRouteIsLoading(routeIsLoading);
            setRouteData(routeData);
        }
    }, [routeIsLoading, routeMatches, setRouteIsLoading, setRouteData]);

    return (
        <>
            <DashboardContainer className="subscriptions">
                {!routeIsLoading &&
                    (childList.length > 0 ? (
                        <>
                            <Title className="text-center medium" type={2}>
                                Status
                            </Title>
                            <div className="dashboard-subscribers">
                                {childList.map((child) => (
                                    <Subscriber
                                        key={`childSubscription_${child.idUser}`}
                                        avatar={child.avatar}
                                        status={child.subscription.status}
                                        date={returnDate(child)}
                                    >
                                        {child.name}
                                    </Subscriber>
                                ))}
                            </div>
                            <div className="dashbooard-subscription-info text-center">
                                <TextSmall>
                                    <p>
                                        Aby zarządzać abonamentami
                                        <br />
                                        wejdź na swoje konto na how2edu.pl
                                    </p>
                                </TextSmall>
                                <Button
                                    classNameWrapper="subscription-button"
                                    className="medium"
                                    onClickFn={() =>
                                        window.open(getStoreUrl(), "_blank")
                                    }
                                >
                                    Dowiedz sie więcej
                                </Button>
                            </div>
                        </>
                    ) : (
                        <>
                            <TextSmall className="larger text-center">
                                Nie masz jeszcze zarejestrowanego dziecka.
                            </TextSmall>
                            <div className="subscriptions-add">
                                <Button
                                    action="add"
                                    onClickFn={() =>
                                        history.push("/dashboard/form")
                                    }
                                >
                                    Dodaj dziecko
                                </Button>
                            </div>
                        </>
                    ))}
            </DashboardContainer>
        </>
    );
};

export default Subscriptions;
