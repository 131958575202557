import { useState, useEffect, useRef, useContext } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useParams, useHistory } from "react-router-dom";

import { StoreContext } from "../../../context/Store";

import useActionLoading from "../../../hooks/useActionLoading";

import checkIfIdOccurs from "../../../functions/checkIfIdOccurs";
import routeLoadingState from "../../../functions/routeLoadingState";
import getSchoolName from "../../../functions/getSchoolName";

import Title from "../../../components/headings/Title";
import Input from "../../../components/inputs/Input";

import DashboardContainer from "../../../components/containers/dashboard/DashboardContainer";
import DashboardGroup from "../../../components/containers/dashboard/DashboardGroup";
import StudentsListContainer from "../../../components/containers/dashboard/students-list/StudentsListContainer";

import modalDoodleImage_1 from "../../../assets/images/dashboard/modal-doodles/happy.svg";

const SchoolGroupStudentAdd = ({
    routeMatches,
    routeSetters,
    modalSetters,
}) => {
    const { setRouteData, setRouteIsLoading } = routeSetters;
    const { setModalData } = modalSetters;

    const {isActionLoading, setIsActionLoading} = useActionLoading();

    const params = useParams();
    const history = useHistory();

    const { api } = useContext(StoreContext);
    const queryClient = useQueryClient();

    /* PARAMS ID REF */
    const idSchoolRef = useRef(params.idSchool);
    const idGroupRef = useRef(params.idGroup);

    /* ALREADY ADDED STUDENTS */
    const studentsExistInGroup = useRef([]);

    /* SELECTED CURRENTLY STUDENTS */
    const [studentsSelected, setStudentsSelected] = useState([]);

    /* SEARCH VALUES */
    const [searchValues, setSearchValues] = useState({
        name: "",
        schoolClass: "",
    });

    /* GET TEACHER SCHOOLS */
    const {
        data: teacherSchoolsData,
        isFetched: teacherSchoolsIsFetched,
        isLoading: teacherSchoolsIsLoading,
    } = useQuery(
        ["teacherSchools", idSchoolRef.current],
        () =>
            api.get(`/teacher/schools`, {
                idSchool: idSchoolRef.current,
            }),
        {
            staleTime: Infinity,
            retry: false,
            refetchOnWindowFocus: false,
        }
    );

    /* GET SCHOOL GROUP */
    const {
        data: schoolGroupData,
        isFetched: schoolGroupIsFetched,
        isLoading: schoolGroupIsLoading,
    } = useQuery(
        ["schoolGroup", idGroupRef.current],
        () => api.post(`/teacher/group`, { idGroup: idGroupRef.current }),
        {
            staleTime: Infinity,
            retry: false,
            refetchOnWindowFocus: false,
        }
    );

    /* GET STUDENTS PER SCHOOL */
    const {
        data: schoolStudentsData,
        isFetched: schoolStudentsIsFetched,
        isLoading: schoolStudentsIsLoading,
    } = useQuery(
        ["schoolStudents", idSchoolRef.current],
        () => api.get(`/school/get_students?idSchool=${idSchoolRef.current}`),
        {
            staleTime: Infinity,
            retry: false,
            refetchOnWindowFocus: false,
        }
    );

    /* FILL ALREADY ADDED STUDENTS */
    useEffect(() => {
        if (!schoolGroupIsLoading && !schoolStudentsIsLoading) {
            const students = schoolGroupData.data.students;

            if (students.length > 0) {
                for (const student of students) {
                    studentsExistInGroup.current.push(student.idUser);
                }
            }
        }
    }, [
        schoolGroupIsLoading,
        schoolStudentsIsLoading,
        schoolGroupData?.data.students,
    ]);

    /* ADD STUDENTS TO GROUP */
    const { mutate: addStudentsToGroupMutate } = useMutation(
        () =>
            api.post(`/teacher/add_student`, {
                users: studentsSelected,
                idGroup: idGroupRef.current,
            }),
        {
            onMutate: () => setIsActionLoading(true),
            onSuccess: () => {
                queryClient.invalidateQueries([
                    "schoolGroup",
                    idGroupRef.current,
                ]);

                setModalData(modalData.current);
            },
            onError: () => setIsActionLoading(false),
        }
    );

    /* ON CHANGE HANDLER */
    const onChangeHandler = (e) => {
        if (checkIfIdOccurs(e, studentsSelected)) {
            setStudentsSelected(studentsSelected.filter((item) => item !== e));
        } else {
            setStudentsSelected([...studentsSelected, e]);
        }
    };

    /* ROUTE LOADING */
    const routeIsLoading = routeLoadingState({
        fetched: [
            teacherSchoolsIsFetched,
            schoolGroupIsFetched,
            schoolStudentsIsFetched,
        ],
        loading: [
            teacherSchoolsIsLoading,
            schoolGroupIsLoading,
            schoolStudentsIsLoading,
        ],
    });

    /* ROUTE SETTERS */
    useEffect(() => {
        const routeData = {
            title: "DODAJ UCZNIA",
        };

        if (routeMatches) {
            setRouteIsLoading(routeIsLoading);
            setRouteData(routeData);
        }
    }, [routeIsLoading, routeMatches, setRouteIsLoading, setRouteData]);

    /* MODAL DATA */
    const modalData = useRef({});

    useEffect(() => {
        if (!routeIsLoading) {
            modalData.current = {
                data: [
                    {
                        title: {
                            obj: (
                                <p>
                                    Uczniowie zostali
                                    <br />
                                    dodani do
                                    <br />
                                    {schoolGroupData.data.name}
                                </p>
                            ),
                            isCenter: true,
                        },
                        img: {
                            obj: modalDoodleImage_1,
                            size: "238px",
                        },
                        action: {
                            obj: {
                                text: "Przejdź dalej",
                                onClick: () => history.goBack(),
                            },
                        },
                    },
                ],
            };
        }
    }, [routeIsLoading, schoolGroupData?.data.name]);

    return (
        <>
            <DashboardContainer className="student-add">
                {!routeIsLoading && (
                    <>
                        <div className="dashboard-student-search">
                            <Input
                                placeholder="wpisz imię i nazwisko ucznia"
                                isDisabled={isActionLoading}
                                onChangeFn={(e) =>
                                    setSearchValues({
                                        ...searchValues,
                                        name: e.currentTarget.value,
                                    })
                                }
                            />
                            <Input
                                placeholder="wpisz nazwę klasy ucznia"
                                placeholderFocus="1C"
                                isDisabled={isActionLoading}
                                onChangeFn={(e) =>
                                    setSearchValues({
                                        ...searchValues,
                                        schoolClass: e.currentTarget.value,
                                    })
                                }
                            />
                        </div>
                        <DashboardGroup className="students-list">
                            <Title className="medium text-center">
                                UCZNIOWIE W SZKOLE{" "}
                                <span className="sub-title">
                                    {getSchoolName(
                                        teacherSchoolsData.data,
                                        idSchoolRef.current
                                    )}
                                </span>
                            </Title>
                            <StudentsListContainer
                                studentsData={schoolStudentsData}
                                studentsSelected={studentsSelected}
                                studentsExistInGroup={
                                    studentsExistInGroup.current
                                }
                                searchValues={searchValues}
                                submitDisabled={
                                    studentsSelected.length > 0 ? false : true
                                }
                                isDisabled={isActionLoading}
                                onChangeFn={(e) => onChangeHandler(e)}
                                onSubmitFn={addStudentsToGroupMutate}
                            />
                        </DashboardGroup>
                    </>
                )}
            </DashboardContainer>
        </>
    );
};

export default SchoolGroupStudentAdd;
