import { useState, useRef, useEffect } from "react";
import { Router, Route, useHistory, useRouteMatch } from "react-router-dom";
import { CSSTransition } from "react-transition-group";

import Topbar from "../../components/containers/dashboard/Topbar";
import RouteDelay from "../../components/route/RouteDelay";
import RouteNavigation from "../../components/route/navigation/RouteNavigation";
import Navigation from "../../components/containers/dashboard/Navigation";
import ModalTransition from "../../components/modal-bodies/ModalTransition";
import ModalAction from "../../components/modal-bodies/ModalAction";

import useComponentWillMount from "../../hooks/useComponentWillMount";

import pathSlice from "../../functions/pathSlice";

import Home from "./children/Home";

import ChildrenAccount from "./children/ChildrenAccount";
import ChildrenAccountEdit from "./children/ChildrenAccountEdit";
import ChildrenAccountAvatar from "./children/ChildrenAccountAvatar";

import Library from "./Library";
import LibraryCategory from "./LibraryCategory";
import LibraryFavorites from "./LibraryFavorites";

import ChildrenLearningProgress from "./children/ChildrenLearningProgress";
import ChildrenLearningProgressDetails from "./children/ChildrenLearningProgressDetails";

import MyShelf from "./MyShelf";

import PasswordEdit from "./PasswordEdit";
import ChildrenCalendar from "./children/ChildrenCalendar";
import ChildrenMoodCheck from "./ChildrenMoodCheck";
import ChildrenGroups from "./children/ChildrenGroups";
import ChildrenGroupsDetails from "./children/ChildrenGroupsDetails";

import Notifications from "./Notifications";

const DashboardChildren = () => {
    const { path } = useRouteMatch();
    const history = useHistory();

    /* ROUTE LOADING STATE */
    const [routeIsLoading, setRouteIsLoading] = useState(false);

    /* ROUTE BACKWARD STATE */
    const [routeIsBackward, setRouteIsBackward] = useState(false);

    /* ROUTE NAVIGATION DATA */
    const [routeData, setRouteData] = useState();

    /* ROUTE DELAY TIMEOUT */
    const routeTimeout = 500;

    /* ROUTE TRANSITION CLASSNAME */
    const routeClassNames = routeIsBackward
        ? routeIsLoading
            ? "SlideRightIn"
            : "SlideRight"
        : routeIsLoading
        ? "SlideLeftIn"
        : "SlideLeft";

    /* ROUTE REFS */
    const routeHomeRef = useRef(null);
    const routeAccountRef = useRef(null);
    const routeAccountEditRef = useRef(null);
    const routeAccountAvatarRef = useRef(null);
    const routePasswordEditRef = useRef(null);
    const routeChildrenCalendarRef = useRef(null);
    const routeLibraryRef = useRef(null);
    const routeLibraryCategoryRef = useRef(null);
    const routeLibraryFavoritesRef = useRef(null);
    const routeLearningProgressRef = useRef(null);
    const routeLearningDetailsRef = useRef(null);
    const routeMyShelfRef = useRef(null);
    const routeChildrenGroups = useRef(null);
    const routeChildrenGroupsDetails = useRef(null);
    const routeNotifications = useRef(null);

    /* ROUTE PREV URL */
    const routePrevPath = useRef(null);

    /* CHECK IF ROUTE IS BACKWARD */
    useComponentWillMount(() => {
        history.listen((location, action) => {
            const routeNextPath = pathSlice(location.pathname);
            const routePrevPathLength = routePrevPath.current
                ? routePrevPath.current.substr(1).split("/").length
                : 0;
            const routeNextPathLength = routeNextPath
                .substr(1)
                .split("/").length;

            if (action === "POP" || routePrevPathLength > routeNextPathLength) {
                setRouteIsBackward(true);
            } else {
                setRouteIsBackward(false);
            }

            routePrevPath.current = pathSlice(location.pathname);
        });
    });

    /* MODAL ACTIVE */
    const [modalActive, setModalActive] = useState(false);

    /* MODAL DATA */
    const [modalData, setModalData] = useState({});
    const modalDataClear = useRef(false);

    /* MODAL API */
    const [modalApiResponse, setModalApiResponse] = useState(false);
    const [modalApiLoading, setModalApiLoading] = useState(false);
    const [modalApiRefetching, setModalApiRefetching] = useState(false);
    const modalApiClear = useRef(false);

    /* MODAL OPEN */
    useEffect(() => {
        if (modalData.data && !modalDataClear.current) {
            modalDataClear.current = true;

            setModalActive(true);
        }
    }, [modalData]);

    const routes = [
        {
            ref: routeHomeRef,
            path: path,
            children: <Home />,
        },
        {
            ref: routeAccountRef,
            path: `${path}/account`,
            children: <ChildrenAccount />,
        },
        {
            ref: routeAccountEditRef,
            path: `${path}/account/edit-account`,
            children: <ChildrenAccountEdit />,
        },
        {
            ref: routeAccountAvatarRef,
            path: `${path}/account/select-avatar`,
            children: <ChildrenAccountAvatar />,
        },
        {
            ref: routePasswordEditRef,
            path: `${path}/account/edit-password`,
            children: <PasswordEdit />,
        },
        {
            ref: routeChildrenCalendarRef,
            path: `${path}/calendar`,
            children: <ChildrenCalendar />,
        },
        {
            ref: routeLibraryRef,
            path: `${path}/library`,
            children: <Library />,
        },
        {
            ref: routeLibraryCategoryRef,
            path: `${path}/library/category-:idCategory`,
            children: <LibraryCategory />,
        },
        {
            ref: routeLibraryFavoritesRef,
            path: `${path}/library/favorites`,
            children: <LibraryFavorites />,
        },
        {
            ref: routeLearningProgressRef,
            path: `${path}/learning-progress`,
            children: <ChildrenLearningProgress />,
        },
        {
            ref: routeLearningDetailsRef,
            path: `${path}/learning-progress/details-:idMultibook`,
            children: <ChildrenLearningProgressDetails />,
        },
        {
            ref: routeMyShelfRef,
            path: `${path}/mybookshelf`,
            children: <MyShelf />,
        },
        {
            ref: routeChildrenGroups,
            path: `${path}/groups`,
            children: <ChildrenGroups />,
        },
        {
            ref: routeChildrenGroupsDetails,
            path: `${path}/groups/group-:idGroup`,
            children: <ChildrenGroupsDetails />,
        },
        {
            ref: routeNotifications,
            path: `${path}/notifications`,
            children: <Notifications />,
        },
    ];

    return (
        <>
            <Topbar
                modalSetters={{
                    setModalData: setModalData,
                    setModalActive: setModalActive,
                    setModalApiResponse: setModalApiResponse,
                    setModalApiLoading: setModalApiLoading,
                }}
            />
            <div className="RouteGroup" id="modal_portal">
                <RouteNavigation
                    routeData={routeData}
                    routeTimeout={routeTimeout}
                    routeIsLoading={routeIsLoading}
                    routeIsBackward={routeIsBackward}
                />
                {routes?.length > 0 ? (
                    <Router history={history}>
                        {routes.map((route) => {
                            return (
                                <Route key={route.path} exact path={route.path}>
                                    {({ match }) => (
                                        <CSSTransition
                                            nodeRef={route.ref}
                                            in={match !== null}
                                            classNames={routeClassNames}
                                            timeout={500}
                                            unmountOnExit
                                            appear
                                        >
                                            <RouteDelay
                                                ref={route.ref}
                                                routeMatch={{
                                                    match: match,
                                                    path: route.path,
                                                }}
                                                routeTimeout={routeTimeout}
                                                routeIsLoading={routeIsLoading}
                                                routeIsBackward={
                                                    routeIsBackward
                                                }
                                                children={route.children}
                                                routeSetters={{
                                                    setRouteData: setRouteData,
                                                    setRouteIsLoading:
                                                        setRouteIsLoading,
                                                }}
                                                modalSetters={{
                                                    setModalData: setModalData,
                                                    setModalActive:
                                                        setModalActive,
                                                    setModalApiResponse:
                                                        setModalApiResponse,
                                                    setModalApiLoading:
                                                        setModalApiLoading,
                                                }}
                                            />
                                        </CSSTransition>
                                    )}
                                </Route>
                            );
                        })}
                    </Router>
                ) : (
                    ""
                )}
            </div>
            <Navigation
                navs={[
                    {
                        text: "Home",
                        icon: "home",
                        onClick: () => history.push(path),
                    },
                    {
                        type: "my-shelf",
                        text: "Moja półka",
                        icon: "my-shelf",
                        onClick: () => history.push(`${path}/mybookshelf`),
                    },
                    {
                        type: "notifications",
                        text: "Powiadomienia",
                        icon: "notifications",
                        onClick: () => history.push(`${path}/notifications`),
                    },
                    {
                        text: "Kalendarz",
                        icon: "calendar",
                        onClick: () => history.push(`${path}/calendar`),
                    },
                ]}
            />
            <ChildrenMoodCheck
                modal={{
                    setModalData: setModalData,
                    setModalActive: setModalActive,
                    setModalApiResponse: setModalApiResponse,
                    setModalApiLoading: setModalApiLoading,
                }}
            />
            ;
            <ModalTransition
                isActive={modalActive}
                children={
                    <ModalAction
                        data={modalData.data}
                        response={modalApiResponse}
                        select={modalData.select}
                        isLoading={modalApiLoading}
                        isRefetching={setModalApiRefetching}
                        onFinish={() => {
                            if (modalApiLoading || modalApiResponse) {
                                modalApiClear.current = true;
                            }

                            setModalActive(false);
                        }}
                    />
                }
                isLoading={modalApiLoading}
                isRefetching={modalApiRefetching}
                onExited={() => {
                    modalDataClear.current = false;

                    setModalData({});

                    if (modalApiClear.current) {
                        modalApiClear.current = false;

                        setModalApiResponse(false);
                        setModalApiLoading(false);
                    }
                }}
                onClose={
                    modalData.close ? () => setModalActive(false) : undefined
                }
            />
        </>
    );
};

export default DashboardChildren;
