import Title from "../components/headings/Title";
import Button from "../components/buttons/Button";

import { useHistory } from "react-router-dom";

import DoodleBanner from "../components/containers/doodle/DoodleBanner";
import DoodleContent from "../components/containers/doodle/DoodleContent";
import doodles from "../assets/images/remindpassword/how2doodles_2.png";

const RemindPasswordFinish = () => {
    const history = useHistory();

    return (
        <div className="app-remindPassword-wrapper">
            <DoodleContent wrapperClass="remindPassword">
                <Title className="text-center">
                    Twoje hasło
                    <br />
                    zostało zmienione
                </Title>
                <Button
                    classNameWrapper="remindPassword-loginButton"
                    className="inverse hover-yellow"
                    onClickFn={() =>
                        history.push("/signing-form", { tab: "login" })
                    }
                >
                    Zaloguj się
                </Button>
            </DoodleContent>
            <DoodleBanner
                wrapperClass="remindPassword"
                additionalClass="success"
                imgs={doodles}
            />
        </div>
    );
};

export default RemindPasswordFinish;
