import { createPortal } from "react-dom";
import { useState, useContext } from "react";
import { useMutation, useQueryClient } from "react-query";

import { StoreContext } from "./../../../context/Store";
// import { enIE } from "date-fns/locale";
import compareAsc from "date-fns/compareAsc";
import parseISO from "date-fns/parseISO";
import hoursToMinutes from "date-fns/hoursToMinutes";

const CalendarModal = ({
  isModalActiveHandler,
  id,
  setTitle,
  setStart,
  setIsModalActive,
  setDesc,
  setEnd,
  changeEvent,
  title,
  start,
  end,
  desc,
  setIsDeleted,
  setStartTimeCalendar,
  setEndTimeCalendar,
  startTimeCalendar,
  endTimeCalendar,
  incorrect,
  errorContent,
  setEndTimeCalendar1,
  setStartTimeCalendar1,
  setIncorrect,
  editing,
  setEditing,
}) => {
  const { api } = useContext(StoreContext);
  const [eventTitle, setEventTitle] = useState("");
  const queryClient = useQueryClient();

  const [noteContent, setNoteContent] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startTime, setStartTime] = useState(startTimeCalendar);
  const [endTime, setEndTime] = useState(endTimeCalendar);
  const [isnotValid, setIsnotValid] = useState(false);
  const [errorResponse, setErrorResponse] = useState(false);
  const [responseContent, setResponseContent] = useState(
    "Nieprawidłowo wprowadzone dane!"
  );

  const [isSend, setIsSend] = useState(false);

  const addEvent = useMutation(
    () =>
      api.post(`/calendar/add`, {
        title: eventTitle,
        date_from: startDate.replace("T", " ").concat(" " + startTime),
        date_to: endDate.replace("T", " ").concat(" " + endTime),
        description: noteContent,
      }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setIsSend(true);
        // setIsModalActive(false);
        // isModalActiveHandler();

        queryClient.invalidateQueries("calendar");
      },
      onError: (error) => {
        return error.response.statusText === "Not Found"
          ? delayErrorResponse()
          : "";
      },
    }
  );

  const first = () => {
    if (id) {
      setStartTimeCalendar(startTime);
      setEndTimeCalendar(endTime);
    }
  };

  const delayErrorResponse = () => {
    setErrorResponse(true);
    setIsSend(true);
  };

  const eventTitleHandler = (e) => {
    setEventTitle(e.target.value);
  };

  const noteContentHandler = (e) => {
    setNoteContent(e.target.value);
  };

  const startDateHandler = (e) => {
    setStartDate(e.target.value);

    if (endDate !== "") {
      if (
        compareAsc(parseISO(e.target.value), parseISO(endDate.slice(0, 10))) ===
        1
      ) {
        setIsnotValid(true);
        setErrorResponse(true);
        setResponseContent("Nieprawidłowa Data");
      } else {
        setIsnotValid(false);
        setErrorResponse(false);
      }

      if (
        compareAsc(parseISO(e.target.value), parseISO(endDate.slice(0, 10))) ===
        0
      ) {
        if (
          hoursToMinutes(startTime.slice(0, 2)) +
            Number(startTime.slice(3, 5)) >
          hoursToMinutes(endTime.slice(0, 2)) + Number(endTime.slice(3, 5))
        ) {
          setIsnotValid(true);
          setErrorResponse(true);
          setResponseContent("Nieprawidłowa  godzina");
        }
      }
    }
    if (e.target.value === "") {
      setIsnotValid(true);
      setErrorResponse(true);
      setResponseContent("Nieprawidłowa Data");
    }
  };

  const endDateHandler = (e) => {
    setEndDate(e.target.value);

    if (startDate !== "") {
      if (
        compareAsc(
          parseISO(startDate.slice(0, 10)),
          parseISO(e.target.value)
        ) === 1
      ) {
        setIsnotValid(true);
        setErrorResponse(true);
        setResponseContent("Nieprawidłowa Data");
      } else {
        setIsnotValid(false);
        setErrorResponse(false);
      }

      if (
        compareAsc(
          parseISO(startDate.slice(0, 10)),
          parseISO(e.target.value)
        ) === 0
      ) {
        if (startTime !== undefined && endTime !== undefined) {
          if (
            hoursToMinutes(startTime.slice(0, 2)) +
              Number(startTime.slice(3, 5)) >
            hoursToMinutes(endTime.slice(0, 2)) + Number(endTime.slice(3, 5))
          ) {
            setIsnotValid(true);
            setErrorResponse(true);
            setResponseContent("Nieprawidłowa  godzina");
          }
        }
      }
    }
    if (e.target.value === "") {
      setIsnotValid(true);
      setErrorResponse(true);
      setResponseContent("Nieprawidłowa Data");
    }
  };

  const startTimeHandler = (e) => {
    setStartTime(e.target.value);

    if (endTime?.length === 5 || endTimeCalendar?.length === 5) {
      if (
        compareAsc(
          parseISO(startDate.slice(0, 10)),
          parseISO(endDate.slice(0, 10))
        ) === 1
      ) {
        setIsnotValid(true);
        setErrorResponse(true);
        setResponseContent("Nieprawidłowa Data");
      } else {
        setIsnotValid(false);
        setErrorResponse(false);
      }

      if (
        compareAsc(
          parseISO(startDate.slice(0, 10)),
          parseISO(endDate.slice(0, 10))
        ) === 0
      ) {
        if (
          hoursToMinutes(e.target.value.slice(0, 2)) +
            Number(e.target.value.slice(3, 5)) >
          hoursToMinutes(endTime.slice(0, 2)) + Number(endTime.slice(3, 5))
        ) {
          setIsnotValid(true);
          setErrorResponse(true);
          setResponseContent("Nieprawidłowa  godzina");
        }
      }
    }
    if (e.target.value === "") {
      setIsnotValid(true);
      setErrorResponse(true);
      setResponseContent("Nieprawidłowa  godzina");
    }
    if (editing) {
      setStartTimeCalendar1(e);
    }
  };

  const endTimeHandler = (e) => {
    setEndTime(e.target.value);

    if (e.target.value === "") {
      setIsnotValid(true);
    }
    if (
      compareAsc(
        parseISO(startDate.slice(0, 10)),
        parseISO(endDate.slice(0, 10))
      ) === 1
    ) {
      setIsnotValid(true);
      setErrorResponse(true);
      setResponseContent("Nieprawidłowa Data");
    } else {
      setIsnotValid(false);
      setErrorResponse(false);
    }

    if (
      compareAsc(
        parseISO(startDate.slice(0, 10)),
        parseISO(endDate.slice(0, 10))
      ) === 0
    ) {
      if (e.target.value < startTime) {
        setIsnotValid(true);
        setErrorResponse(true);
        setResponseContent("Nieprawidłowa  godzina");
      }
    }
    if (e.target.value === "") {
      setIsnotValid(true);
      setErrorResponse(true);
      setResponseContent("Nieprawidłowa  godzina");
    }
    if (editing) {
      setEndTimeCalendar1(e);
    }
  };

  const returnMutation = () => {
    return !incorrect
      ? id
        ? changeEvent.mutate()
        : addEvent.mutate()
      : setErrorResponse(true);
  };

  return createPortal(
    <div className="rbg-modal">
      <div className="rbg-modal-content" style={{ position: "relative" }}>
        <div style={{ position: "absolute", right: "23px", top: "15px" }}>
          {first()}
          {id ? (
            <button
              className="rgb-close"
              onClick={() => {
                setEditing(false);
                setIsModalActive(false);
              }}
              style={{ fontSize: "25px", fontWeight: 600 }}
            >
              &times;
            </button>
          ) : (
            <button
              className="rgb-close"
              onClick={() => isModalActiveHandler()}
              style={{ fontSize: "25px", fontWeight: 600 }}
            >
              &times;
            </button>
          )}
        </div>
        <h3 className="rbg-modal-newEvent">
          {id ? "Edytuj wydarzenie" : "Nowe wydarzenie"}
        </h3>
        <div className="rbg-modal-body">
          <div className="rbg-modal-input">
            <input
              className="modal-input"
              placeholder="Nazwa wydarzenia"
              onChange={id ? setTitle : eventTitleHandler}
              value={title}
            ></input>
          </div>
          <div className="rbg-modal-double_input">
            <div className="rbg-modal-double-inputs">
              <div className="rbg-modal-input_div1">
                <p style={{ fontSize: "12px", marginRight: "78px" }}>
                  Początek
                </p>
                <input
                  style={{
                    marginRight: "5px",

                    display: "flex",
                    alignItems: "center",
                  }}
                  className="rbg-modal-double_input_second"
                  type="date"
                  onChange={id ? setStart : startDateHandler}
                  value={start?.slice(0, 10) ? start.slice(0, 10) : startDate}
                ></input>
                <input
                  type="time"
                  className="rbg-modal-double_input_time"
                  onChange={startTimeHandler}
                  value={startTime}
                ></input>
              </div>
              <div className="rbg-modal-input_div2">
                <div>
                  <p style={{ fontSize: "12px", marginRight: "90px" }}>
                    Koniec
                  </p>
                </div>
                <input
                  style={{ marginRight: "5px" }}
                  className="rbg-modal-double_input_second"
                  type="date"
                  onChange={id ? setEnd : endDateHandler}
                  value={end?.slice(0, 10) ? end.slice(0, 10) : endDate}
                ></input>
                <input
                  type="time"
                  className="rbg-modal-double_input_time"
                  onChange={endTimeHandler}
                  value={endTime}
                ></input>
              </div>
            </div>
          </div>
          <div className="rbg-modal-text">
            <textarea
              onChange={id ? setDesc : noteContentHandler}
              className="rbg-modal-text-area"
              placeholder="Notatki"
              value={desc}
            ></textarea>
          </div>
          {errorResponse || isnotValid || incorrect ? (
            <div className="rbg-alert">
              {incorrect ? errorContent : responseContent}
            </div>
          ) : null}
          <div className="rbg-modal-buttons">
            {id ? (
              <button
                className="rbg-modal-button2"
                style={{
                  marginBottom: "15px",
                  marginTop: "15px",
                  opacity:
                    changeEvent.isLoading || addEvent.isLoading
                      ? "50%"
                      : "100%",
                }}
                onClick={() =>
                  !isnotValid ? returnMutation() : setIsSend(true)
                }
                disabled={id ? changeEvent.isLoading : addEvent.isLoading}
              >
                Zapisz wydarzenie
              </button>
            ) : (
              <button
                className="rbg-modal-button1"
                style={{
                  marginBottom: "15px",
                  marginTop: "15px",
                  opacity:
                    changeEvent?.isLoading || addEvent?.isLoading
                      ? "50%"
                      : "100%",
                }}
                onClick={() =>
                  !isnotValid ? returnMutation() : setIsSend(true)
                }
                disabled={id ? changeEvent.isLoading : addEvent.isLoading}
              >
                Zapisz wydarzenie
              </button>
            )}
            <button
              className="rbg-modal-button2"
              onClick={() => {
                id ? setIsModalActive(false) : isModalActiveHandler();
                setIsnotValid(false);
                setErrorResponse(false);
                setIncorrect(false);
              }}
              disabled={id ? changeEvent.isLoading : addEvent.isLoading}
              style={{
                opacity:
                  changeEvent?.isLoading || addEvent.isLoading ? "50%" : "100%",
              }}
            >
              Zamknij
            </button>
            {id ? (
              <button
                className="rbg-modal-button1"
                style={{
                  marginTop: "15px",
                  opacity:
                    changeEvent.isLoading || addEvent.isLoading
                      ? "50%"
                      : "100%",
                }}
                onClick={() => {
                  setIsDeleted(true);
                  setIsModalActive(false);
                  setIsnotValid(false);
                  setErrorResponse(false);
                  setIncorrect(false);
                }}
              >
                Usuń wydarzenie
              </button>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>,
    document.getElementById("modal_portal")
  );
};

export default CalendarModal;
