import Title from "../../../components/headings/Title";
import Button from "../../../components/buttons/Button";

import { useHistory, useLocation } from "react-router-dom";

import DoodleBanner from "../../../components/containers/doodle/DoodleBanner";
import DoodleContent from "../../../components/containers/doodle/DoodleContent";
import doodles from "../../../assets/images/remindpassword/how2doodles_2.png";

const SuccessfulAddChildren = () => {
    const { state } = useLocation();
    const history = useHistory();

    return (
        <div className="app-remindPassword-wrapper">
            <DoodleContent wrapperClass="remindPassword">
                <Title className="text-center">
                    {`${state?.childName} dostanie od nas e-mail z danymi do logowania`}
                </Title>
                <Button
                    classNameWrapper="remindPassword-loginButton"
                    className="inverse hover-yellow"
                    onClickFn={() => history.push("/dashboard")}
                >
                    Przejdź dalej
                </Button>
            </DoodleContent>
            <DoodleBanner
                wrapperClass="remindPassword"
                additionalClass="success"
                imgs={doodles}
            />
        </div>
    );
};

export default SuccessfulAddChildren;
