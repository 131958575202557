import { useContext } from "react";
import { useMutation } from "react-query";

import { StoreContext } from "../../../context/Store";

const useShareMark = () => {
    const { api } = useContext(StoreContext);

    const {
        mutate: shareMark,
        isSuccess: isShareMarkSuccess,
        isError: isShareMarkError,
    } = useMutation((idExamAnswer) =>
        api.post("/exams/mark_display_share", { idExamAnswer })
    );

    return {
        shareMark,
        isShareMarkSuccess,
        isShareMarkError,
    };
};

export default useShareMark;
