const IconFavorite = ({ isActive }) => {
    const icon = (string) => {
        switch (string) {
            case "Y":
                return (
                    <svg
                        className="Favorite__Svg"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        width="15.156"
                        height="13.32"
                        viewBox="0 0 15.156 13.32"
                    >
                        <defs>
                            <clipPath id="clip-path">
                                <rect
                                    id="Rectangle_1375"
                                    dataname="Rectangle 1375"
                                    width="15.156"
                                    height="13.32"
                                    transform="translate(0 0)"
                                    fill="#0c6ffa"
                                />
                            </clipPath>
                        </defs>
                        <g
                            id="Group_908"
                            data-name="Group 908"
                            transform="translate(0 0)"
                        >
                            <g
                                id="Group_890"
                                dataname="Group 890"
                                transform="translate(0 0)"
                                clipPath="url(#clip-path)"
                            >
                                <path
                                    id="Path_1522"
                                    dataname="Path 1522"
                                    d="M11.261,2.818A4.785,4.785,0,0,1,10.417,5.4,16.139,16.139,0,0,1,6.253,9.553c-.622.46-.626.456-1.26,0A17.7,17.7,0,0,1,1.416,6.22a5.736,5.736,0,0,1-1.4-3.055A2.869,2.869,0,0,1,1.528.328,2.679,2.679,0,0,1,4.655.758a9.676,9.676,0,0,1,.789.779c.142.156.225.14.362,0A10.883,10.883,0,0,1,6.67.7,2.584,2.584,0,0,1,9.64.3a2.78,2.78,0,0,1,1.621,2.521"
                                    transform="translate(1.948 1.712)"
                                    fill="#0c6ffa"
                                />
                            </g>
                        </g>
                    </svg>
                );
            case "N":
                return (
                    <svg
                        className="Favorite__Svg"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        width="15.156"
                        height="13.32"
                        viewBox="0 0 15.156 13.32"
                    >
                        <defs>
                            <clipPath id="clip-path">
                                <rect
                                    id="Rectangle_1375"
                                    dataname="Rectangle 1375"
                                    width="15.156"
                                    height="13.32"
                                    transform="translate(0 0)"
                                    fill="none"
                                    stroke="#2e2a26"
                                    strokeWidth="0.5"
                                />
                            </clipPath>
                        </defs>
                        <g
                            id="Group_908"
                            dataname="Group 908"
                            transform="translate(0 0)"
                        >
                            <g
                                id="Group_890"
                                dataname="Group 890"
                                transform="translate(0 0)"
                                clipPath="url(#clip-path)"
                            >
                                <path
                                    id="Path_1522"
                                    dataname="Path 1522"
                                    d="M11.261,2.818A4.785,4.785,0,0,1,10.417,5.4,16.139,16.139,0,0,1,6.253,9.553c-.622.46-.626.456-1.26,0A17.7,17.7,0,0,1,1.416,6.22a5.736,5.736,0,0,1-1.4-3.055A2.869,2.869,0,0,1,1.528.328,2.679,2.679,0,0,1,4.655.758a9.676,9.676,0,0,1,.789.779c.142.156.225.14.362,0A10.883,10.883,0,0,1,6.67.7,2.584,2.584,0,0,1,9.64.3a2.78,2.78,0,0,1,1.621,2.521"
                                    transform="translate(1.948 1.712)"
                                    fill="none"
                                    stroke="#2e2a26"
                                    strokeWidth="0.5"
                                />
                            </g>
                        </g>
                    </svg>
                );
            default:
                return false;
        }
    };

    return icon(isActive);
};

export default IconFavorite;
