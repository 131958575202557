import parse from "html-react-parser";

import parseFlashcardContent from "../functions/flashcard/parseFlashcardContent";
import parseMathTagConnection from "../functions/parseMathTagConnection";

import doodleImage from "../assets/images/components/fact/doodle-red.svg";

const ReaderFact = ({ content, flash, isGallup, isGibs }) => {
    return (
        <>
            <div
                className={
                    "ReaderFact" +
                    (isGallup || isGibs ? " _color--secondary" : "")
                }
            >
                <div className="ReaderFact__Edge"></div>
                <div className="ReaderFact__Title">Ciekawostka:</div>
                <div className="ReaderFact__Content">
                    {parseFlashcardContent(
                        parse(parseMathTagConnection(content)),
                        flash
                    ).map((object) => {
                        return object;
                    })}
                </div>
                <div className="ReaderFact__Doodle">
                    <img className="ReaderFact__Img" src={doodleImage} alt="" />
                </div>
            </div>
        </>
    );
};

export default ReaderFact;
