import { useHistory } from "react-router-dom";

import DashboardContainer from "../../components/containers/dashboard/DashboardContainer";

import LibraryGroup from "../../components/containers/dashboard/library/LibraryGroup";

const LibraryFavorites = ({
    routeMatches,
    routeSetters,
}) => {
    const { setRouteData, setRouteIsLoading } = routeSetters;

    const history = useHistory();

    return (
        <>
            <DashboardContainer className="library">
                <LibraryGroup
                    shelfData={{
                        title: "ULUBIONE",
                        page: "favorite",
                        action: {
                            type: "button",
                            props: {
                                text: "ULUBIONE",
                                icon: "heart",
                                onClick: () => history.goBack(),
                            },
                        },
                    }}
                    routePass={{
                        routeMatches: routeMatches,
                        setRouteData: setRouteData,
                        setRouteIsLoading: setRouteIsLoading,
                    }}
                />
            </DashboardContainer>
        </>
    );
};

export default LibraryFavorites;
