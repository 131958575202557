import LearningBar from "./LearningBar";

import IconMultibook from "../../../icons/IconMultibook";

const LearningCard = ({
    type,
    thumbnail,
    title,
    content,
    progressBar,
    isDetailed,
    onClick,
}) => {
    return (
        <>
            <button
                className="LearningCard"
                type="button"
                disabled={typeof onClick !== "function"}
                onClick={onClick}
            >
                <span className="LearningCard__Aside">
                    <span
                        className="LearningCard__Thumbnail"
                        style={{ backgroundImage: "url(" + thumbnail + ")" }}
                    ></span>
                    {typeof onClick === "function" && (
                        <span className="LearningCard__Button">
                            {isDetailed ? "Kontynuuj" : "Więcej info"}
                        </span>
                    )}
                </span>
                <span className="LearningCard__Main">
                    <span className="LearningCard__Title">
                        <span className="LearningCard__Icon _icon--title">
                            <IconMultibook type={type} />
                        </span>
                        {title}
                    </span>
                    <span className="LearningCard__Content">{content}</span>
                    {progressBar?.chapter || progressBar?.test ? (
                        <span className="LearningCard__Progress">
                            {progressBar?.chapter ? (
                                <LearningBar
                                    caption={progressBar.chapter?.caption}
                                    percent={progressBar.chapter?.percent}
                                    isDone={progressBar.chapter?.isDone}
                                />
                            ) : (
                                ""
                            )}
                            {progressBar?.test ? (
                                <LearningBar
                                    caption={progressBar.test?.caption}
                                    percent={progressBar.test?.percent}
                                    range
                                />
                            ) : (
                                ""
                            )}
                        </span>
                    ) : (
                        ""
                    )}
                </span>
            </button>
        </>
    );
};

export default LearningCard;
