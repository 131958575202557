import { useState, createContext } from 'react';

export const LibraryContext = createContext();

const LibraryProvider = ({children}) => {
    const [data, setData] = useState(null);

    return (
        <LibraryContext.Provider value={{data, setData}}>
            {children}
        </LibraryContext.Provider>
    )
}

export default LibraryProvider;