import { useState, useRef, useEffect, useContext } from "react";

import { ChapterContext } from "../context/ChapterStore";
import { ExamContext } from "../context/ExamStore";

import useComponentTransition from "../hooks/useComponentTransition";

import getExamProgress from "../functions/getExamProgress";

const ReaderMood = ({ ids, title, content, examContent, setExamProgress }) => {
    const { setChapterData, getChapterData, getFullChapterData } =
        useContext(ChapterContext);
    const { setExamPagesStore, isGibsResultLoading, isGibsResultDone } =
        useContext(ExamContext);

    /* GET STORED MOOD */
    const moodSelectedStored = getChapterData(ids, "mood");

    /* MOOD DEFAULT STATES */
    const [moodSelected, setMoodSelected] = useState(
        moodSelectedStored ?? null
    );
    const indexMood = useRef(1);
    const isMoodPending = useRef(false);

    /* HANDLE MOOD SELECT */
    const handleMoodSelect = (id) => {
        if (!isMoodPending.current) {
            isMoodPending.current = true;

            setChapterData(id, ids, "mood");

            setMoodSelected(id);

            /* GET EXAM PROGRESS */
            const examProgress = getExamProgress(
                getFullChapterData(),
                examContent,
                false,
                true
            );

            /* STORE EXAM PROGRESS AND SET NEW STATE */
            setExamPagesStore(ids.idChapter, examProgress, "progress");
            setExamProgress(examProgress);

            setTimeout(() => {
                isMoodPending.current = false;
            }, 350);
        }
    };

    return (
        <>
            <div className="ReaderMood">
                {title && (
                    <div
                        className="ReaderMood__Title"
                        dangerouslySetInnerHTML={{ __html: title }}
                    ></div>
                )}
                <div className="ReaderMood__List">
                    {content.map((object, index) => (
                        <div className="ReaderMood__Item" key={object.id}>
                            <ReaderAvatar
                                name={object.name}
                                avatar={object.avatar}
                                index={indexMood}
                                isDisabled={
                                    moodSelected === object.id ||
                                    isGibsResultLoading ||
                                    isGibsResultDone
                                }
                                isRight={
                                    (index + 1) % 4 === 0 ||
                                    (index + 1 + 1) % 4 === 0
                                }
                                isSelected={moodSelected === object.id}
                                onClick={() => handleMoodSelect(object.id)}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

const ReaderAvatar = ({
    name,
    avatar,
    index,
    isDisabled,
    isRight,
    isSelected,
    onClick,
}) => {
    const { show, hide, isMounted, entering, entered } = useComponentTransition(
        300,
        isSelected
    );

    const indexTooltip = useRef(index.current);

    useEffect(() => {
        if (!isMounted && isSelected) {
            show();

            indexTooltip.current = index.current;
            index.current = index.current + 1;
        } else if (isMounted && !isSelected) {
            hide();
        }
    }, [isMounted, isSelected, show, hide, index]);

    return (
        <div className="ReaderAvatar">
            <button
                className={
                    "ReaderAvatar__Button" +
                    (isSelected ? " _is--selected" : "")
                }
                type="button"
                disabled={isDisabled}
                data-panel="false"
                onClick={onClick}
            >
                <span className="ReaderAvatar__Icon">
                    <img src={avatar} alt="" />
                </span>
            </button>
            {isMounted && (
                <div
                    className={
                        "ReaderAvatar__Tooltip" +
                        (isRight ? " _is--right" : "") +
                        (entering || entered ? " _is--open" : "")
                    }
                    style={{
                        zIndex: indexTooltip.current,
                    }}
                >
                    {name}
                </div>
            )}
        </div>
    );
};

export default ReaderMood;
