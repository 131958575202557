import { useState, useContext } from "react";
import { useQuery, useMutation } from "react-query";
import DashboardContainer from "../../../components/containers/dashboard/DashboardContainer";
import { useEffect } from "react";
import routeLoadingState from "../../../functions/routeLoadingState";
import { StoreContext } from "./../../../context/Store";
import TextSmall from "../../../components/containers/TextSmall";
import Button from "../../../components/buttons/Button";
import pathSlice from "../../../functions/pathSlice";
import { useHistory, useLocation, useParams } from "react-router-dom";

const ChildrenGroups = ({ routeMatches, routeSetters }) => {
  const { setRouteData, setRouteIsLoading } = routeSetters;
  const { api } = useContext(StoreContext);
  const [groups, setGroups] = useState("");
  const history = useHistory();
  const location = useLocation();
  const params = useParams();

  const { data, isFetched, isRefetching, isLoading } = useQuery(
    "groups",
    () => api.get(`/student/groups`),
    {
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setGroups(data.data);
      },
      onError: (error) => {},
    }
  );

  /* ROUTE LOADING */
  const routeIsLoading = routeLoadingState({
    fetched: [isFetched],
    loading: [isLoading],
    refetching: [isRefetching], //isRefetching
  });

  /* ROUTE SETTERS */
  useEffect(() => {
    const routeData = {
      title: "Moje grupy",
    };

    if (routeMatches) {
      setRouteIsLoading(routeIsLoading);
      setRouteData(routeData);
    }
  }, [routeIsLoading, routeMatches, setRouteIsLoading, setRouteData]);

  return (
    <div>
      <DashboardContainer className="children-groups">
        {groups.length > 0 ? (
          groups?.map((object) => {
            return (
              <div style={{ marginTop: "15px" }}>
                <Button
                  key={"schoolGroup_" + object.idGroup}
                  className="creamy hover-red"
                  action="link"
                  onClickFn={() =>
                    history.push(
                      `${pathSlice(location.pathname)}/group-${object.idGroup}`
                    )
                  }
                >
                  {object.name}
                </Button>
              </div>
            );
          })
        ) : (
          <>
            <TextSmall className="noGroupText">
              <div>
                <p className="LackGroupText">
                  Żaden nauczyciel nie dodał Cię jeszcze do swojej grupy.
                </p>
              </div>

              <div
                style={{
                  marginTop: "15px",
                  width: "60%",
                  padding: "20px",
                  marginLeft: "60px",
                  justifyContent: "center",
                }}
              >
                <Button
                  key={"back"}
                  style={{ width: "60%" }}
                  className="creamy hover-red"
                  onClickFn={() => history.push(`${pathSlice("/dashboard")}`)}
                >
                  Wróć
                </Button>
              </div>
            </TextSmall>
          </>
        )}
      </DashboardContainer>
    </div>
  );
};

export default ChildrenGroups;
