const IconTrash = () => {
    return (
        <svg
            id="Group_1734"
            data-name="Group 1734"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="15.002"
            height="14.249"
            viewBox="0 0 15.002 14.249"
        >
            <defs>
                <clipPath id="clip-path">
                    <rect
                        id="Rectangle_2032"
                        dataname="Rectangle 2032"
                        width="15.002"
                        height="14.249"
                        fill="none"
                    />
                </clipPath>
            </defs>
            <g
                id="Group_1733"
                dataname="Group 1733"
                clipPath="url(#clip-path)"
            >
                <path
                    id="Path_2774"
                    dataname="Path 2774"
                    d="M13.243,2.54a6.88,6.88,0,0,1-.914,10.037A7.778,7.778,0,0,1,1.76,11.709,6.88,6.88,0,0,1,2.674,1.672a7.777,7.777,0,0,1,10.569.868"
                    transform="translate(0 0)"
                    fill="#0b6ffa"
                />
                <path
                    id="Path_2775"
                    dataname="Path 2775"
                    d="M38.129,29.368a.2.2,0,0,1-.192.2H31.258a.2.2,0,0,1-.192-.2V29.2a.2.2,0,0,1,.192-.2h6.678a.2.2,0,0,1,.192.2Z"
                    transform="translate(-27.096 -25.302)"
                    fill="#d8e7fe"
                />
                <path
                    id="Path_2776"
                    dataname="Path 2776"
                    d="M50.369,20.114a.826.826,0,0,0-1.651,0l-.516,0a1.343,1.343,0,0,1,2.684,0Z"
                    transform="translate(-42.042 -16.413)"
                    fill="#d8e7fe"
                />
                <path
                    id="Path_2777"
                    dataname="Path 2777"
                    d="M40.127,37.181h-5.94a.175.175,0,0,0-.186.175l.519,6.6c.031.223.08.322.338.322h4.583a.306.306,0,0,0,.353-.322l.519-6.6a.175.175,0,0,0-.186-.175m-4.336,6.5-.055,0a.274.274,0,0,1-.271-.271L35.05,38.13a.274.274,0,0,1,.226-.31l.055,0a.274.274,0,0,1,.271.271l.414,5.284a.274.274,0,0,1-.226.31m1.643-.28a.274.274,0,0,1-.249.291h-.055a.274.274,0,0,1-.249-.291V38.1a.274.274,0,0,1,.249-.291h.055a.274.274,0,0,1,.249.291Zm1.85-5.272-.4,5.285a.274.274,0,0,1-.27.272l-.055,0a.274.274,0,0,1-.227-.309l.4-5.285a.274.274,0,0,1,.27-.272l.055,0a.274.274,0,0,1,.227.309"
                    transform="translate(-29.655 -32.43)"
                    fill="#d8e7fe"
                />
            </g>
        </svg>
    );
};

export default IconTrash;
