const registerErrorHandler = (statusCode, responseError, onEmailTaken) => {
    switch (statusCode) {
        case 400:
            switch (responseError.email) {
                case "EMAIL_TAKEN":
                    onEmailTaken();
                    return;
                default:
                    return;
            }

        default:
            return;
    }
};

export default registerErrorHandler;
