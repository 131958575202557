import { useHistory } from "react-router-dom";

import Button from "../../components/buttons/Button";
import NotifyBlock from "../../components/containers/NotifyBlock";

import crying_doodle from "../../assets/images/dashboard/how2doodle_deletion.svg";

const AccountDelete = () => {
    const history = useHistory();

    return (
        <>
            <NotifyBlock
                img={crying_doodle}
                contentBelow={
                    <Button
                        onClickFn={() => history.push("/")}
                        additionalClass="medium-2"
                    >
                        Wróć do ekranu początkowego
                    </Button>
                }
            >
                Twoje konto zostało usunięte!
            </NotifyBlock>
        </>
    );
};

export default AccountDelete;
