import { useState, useRef, useEffect, useCallback, useContext } from "react";
import { useCookies } from "react-cookie";
import { useQuery } from "react-query";
import { getCookieTime } from "../../App";

import { StoreContext } from "../../context/Store";

import Loader from "../containers/Loader";
import ModalResults from "./ModalResults";

import ModalSearch from "./ModalSearch";

const ModalList = ({
    data,
    itemSelected,
    isLoading,
    isRefetching,
    isLast,
    onFinish,
}) => {
    const { className, query, actionDispatch } = data;

    const [cookies, setCookie, removeCookie] = useCookies([
        query.queryKey + "_cookie",
    ]);

    const { api } = useContext(StoreContext);

    /* FRONT SEARCH */
    const [searchValue, setSearchValue] = useState(
        cookies[query.queryKey + "_cookie"]
            ? cookies[query.queryKey + "_cookie"]
            : ""
    );

    /* BACKEND SEARCH */
    const backendSearchValue = useRef({
        prev: cookies[query.queryKey + "_cookie"]
            ? cookies[query.queryKey + "_cookie"]
            : "",
        next: cookies[query.queryKey + "_cookie"]
            ? cookies[query.queryKey + "_cookie"]
            : "",
    });
    const [backendSearchDisabled, setBackendSearchDisabled] = useState(false);
    const backendSearchResetExecute = useRef(false);
    const backendSearchResetStale = useRef(false);
    const backendSearchRefetchFlag = useRef(false);

    /* GET DATA */
    const {
        data: queryData,
        refetch: queryRefetch,
        isFetched: queryIsFetched,
        isLoading: queryIsLoading,
        isRefetching: queryIsRefetching,
    } = useQuery(
        query.queryKey,
        () =>
            api.get(
                query.url +
                    (query.backendSearch ? backendSearchValue.current.next : "")
            ),
        {
            enabled: false,
            retry: false,
            refetchOnWindowFocus: false,
        }
    );

    /* SET REFETCHING */
    useEffect(() => {
        if (!queryIsFetched || queryIsLoading || queryIsRefetching) {
            isRefetching(true);
        } else {
            isRefetching(false);
        }
    }, [queryIsFetched, queryIsLoading, queryIsRefetching, isRefetching]);

    /* SET BACKEND SEARCH RESET REFETCH FLAG IF DATA === 0 */
    useEffect(() => {
        if (queryData?.data.length === 0 && !backendSearchRefetchFlag.current) {
            backendSearchRefetchFlag.current = true;
        }
    }, [queryData]);

    /* MODAL INIT SETTERS */
    const [modalListInit, setModalListInit] = useState(false);
    const [modalListInitAnimation, setModalListInitAnimation] = useState(false);

    /* MODAL INIT [NO DATA] */
    useEffect(() => {
        if (!queryIsFetched && !queryIsLoading) {
            queryRefetch();
        }

        if (
            (!queryIsFetched && queryIsLoading) ||
            (queryIsRefetching &&
                !backendSearchRefetchFlag.current &&
                !backendSearchResetStale.current)
        ) {
            setModalListInit(true);
        }
    }, [
        queryIsFetched,
        queryIsLoading,
        queryIsRefetching,
        setModalListInit,
        queryRefetch,
    ]);

    /* MODAL INIT ANIMATION */
    useEffect(() => {
        if (modalListInit && queryIsFetched && !queryIsLoading) {
            setTimeout(() => {
                setModalListInitAnimation(true);
            }, 500);
        }
    }, [modalListInit, queryIsFetched, queryIsLoading]);

    /* MODAL INIT ANIMATION CLEAN */
    useEffect(() => {
        if (modalListInitAnimation) {
            setTimeout(() => {
                setModalListInit(false);
                setModalListInitAnimation(false);
            }, 800); /* TRANSITION + DELAY FROM CSS */
        }
    }, [modalListInitAnimation]);

    /* FRONT SEARCH FILTER */
    const queryDataFilter = (data, filter) => {
        return data.filter((element) =>
            element.name.toLowerCase().includes(filter.toLowerCase())
        );
    };

    /* EXCLUDE SELECTED DATA */
    const excludeSelectedData = (apiData, selectedData) => {
        const selectedDataMap = selectedData.map((object) => {
            return object[query.dataKey.id];
        });

        return apiData?.filter(
            (element) => !selectedDataMap.includes(element[query.dataKey.id])
        );
    };

    /* SEARCH BACKEND EXECUTE HANDLER */
    const backendSearchExecuteHandler = useCallback(() => {
        if (
            backendSearchValue.current.prev !== backendSearchValue.current.next
        ) {
            backendSearchValue.current.prev = backendSearchValue.current.next;

            setCookie(
                query.queryKey + "_cookie",
                backendSearchValue.current.next,
                {
                    path: "/",
                    expires: getCookieTime(),
                }
            );

            backendSearchRefetchFlag.current = true;

            queryRefetch();
        }
    }, [queryRefetch, setCookie, query.queryKey]);

    /* SET SERACH VALUE ON CHANGE */
    const searchNextValueHandler = useCallback(
        (value) => {
            setCookie(query.queryKey + "_cookie", value, {
                path: "/",
                expires: getCookieTime(),
            });

            setSearchValue(value);
        },
        [setSearchValue, setCookie, query.queryKey]
    );

    /* SET BACKEND SEARCH VALUE ON CHANGE */
    const backendSearchNextValueHandler = useCallback(
        (value) => {
            backendSearchValue.current = {
                ...backendSearchValue.current,
                next: value,
            };

            /* DISABLE/ENABLE SEARCH BY CURRENT VALUE */
            if (backendSearchValue.current.prev === value) {
                setBackendSearchDisabled(true);

                if (!backendSearchResetExecute.current) {
                    isRefetching(false);
                }
            } else {
                backendSearchResetExecute.current = false;

                setBackendSearchDisabled(false);

                isRefetching(true);
            }
        },
        [isRefetching]
    );

    /* SEARCH VALUE RESET HANDLER */
    const handleSearchReset = (onClose) => {
        removeCookie(query.queryKey + "_cookie", { path: "/" });

        if (query.backendSearch) {
            backendSearchValue.current = {
                prev: "",
                next: "",
            };

            if (!onClose) {
                backendSearchResetExecute.current = true;
            } else {
                backendSearchResetStale.current = true;
            }

            queryRefetch();
        } else {
            if (!onClose) {
                setSearchValue("");
            }
        }
    };

    return (
        <>
            <div
                className={
                    "ModalList" +
                    (className ? " " + className : "") +
                    (modalListInit ? " _init" : "") +
                    (modalListInit && modalListInitAnimation
                        ? " _animating"
                        : "")
                }
            >
                {isLoading || modalListInit ? (
                    <div
                        className={
                            "ModalList__Loader" +
                            (isLoading ? " _loading" : "") +
                            (modalListInit && modalListInitAnimation
                                ? " _animating"
                                : "")
                        }
                    >
                        <Loader type="ring" />
                    </div>
                ) : (
                    ""
                )}
                <div
                    className={
                        "ModalList__Body" +
                        (modalListInit ? " _init" : "") +
                        (modalListInit && modalListInitAnimation
                            ? " _animating"
                            : "")
                    }
                >
                    {queryIsFetched && !queryIsLoading ? (
                        <>
                            <div className="ModalList__Search">
                                <ModalSearch
                                    backendSearch={query.backendSearch}
                                    value={searchValue}
                                    disabled={backendSearchDisabled}
                                    reset={
                                        query.backendSearch &&
                                        queryIsRefetching &&
                                        backendSearchResetExecute.current
                                    }
                                    queryKey={query.queryKey}
                                    onChange={
                                        query.backendSearch
                                            ? backendSearchNextValueHandler
                                            : searchNextValueHandler
                                    }
                                    onSearch={
                                        query.backendSearch
                                            ? backendSearchExecuteHandler
                                            : undefined
                                    }
                                />
                            </div>
                            <div className="ModalList__Results">
                                <ModalResults
                                    data={
                                        query.backendSearch
                                            ? query.selectedData
                                                ? excludeSelectedData(
                                                      queryData.data,
                                                      query.selectedData
                                                  )
                                                : queryData.data
                                            : excludeSelectedData(
                                                  queryDataFilter(
                                                      queryData.data,
                                                      searchValue
                                                  ),
                                                  query.selectedData
                                              )
                                    }
                                    dataKey={query.dataKey}
                                    queryKey={query.queryKey}
                                    itemSelected={itemSelected}
                                    disabled={isLoading}
                                    isRefetching={
                                        queryIsRefetching &&
                                        backendSearchRefetchFlag.current &&
                                        !backendSearchResetStale.current
                                    }
                                    isLast={isLast}
                                    actionDispatch={actionDispatch}
                                    onResetClick={() => handleSearchReset()}
                                    onCloseSearchReset={handleSearchReset}
                                    onFinish={onFinish}
                                />
                            </div>
                        </>
                    ) : (
                        ""
                    )}
                </div>
            </div>
        </>
    );
};

export default ModalList;
