import { useState, useEffect, useContext } from "react";
import { useQuery, useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";

import { StoreContext } from "../../../context/Store";

import Title from "../../headings/Title";
import Input from "../../inputs/Input";
import Checkbox from "../../inputs/Checkbox";
import Button from "../../buttons/Button";

import validator from "../../../functions/validator";

import SigningFormContainer from "../../containers/signing/SigningFormContainer";
import SigningFormPasswordContainer from "../../containers/signing/SigningFormPasswordContainer";
import SigningRulesContainer from "../../containers/signing/SigningRulesContainer";
import ButtonRuleSigning from "../../buttons/signing/ButtonRuleSigning";
import { getCookieTime } from "../../../App";

const ResetForm = ({ codeData, isFirstLogin }) => {
    const history = useHistory();
    const { api } = useContext(StoreContext);

    const setCookie = useCookies(["firstLogin"])[1];

    const { refetch: resetCodeFormRefetch } = useQuery(
        "reset_password_confirm",
        () =>
            api.post(`/reset_password_confirm`, {
                token: codeData.code,
                email: codeData.email,
                password: passwordValue.origin,
                password2: passwordValue.repeated,
            }),
        {
            retry: false,
            enabled: false,
            refetchOnWindowFocus: false,
            onSuccess: () => history.push("/remind-password-finish"),
        }
    );

    /* FIRST TIME LOGIN STATE */
    const [firstLogin] = useState(isFirstLogin ? true : false);

    /* FIRST TIME LOGIN CHANGE PASSWORD */
    const { mutate: firstLoginResetMutate } = useMutation(
        () =>
            api.post(`/account/change_password`, {
                password: passwordValue.origin,
                password2: passwordValue.repeated,
            }),
        {
            onSuccess: (data) => {
                setCookie("firstLogin", 0, {
                    path: "/",
                    expires: getCookieTime(),
                });
            },
            onError: () => alert("*Placeholder* Error with sending data"),
        }
    );

    /* PASSWORD VALUES & VALIDATORS */
    const [passwordValue, setPasswordValue] = useState({
        origin: "",
        repeated: "",
    });
    const [passwordValidator, setPasswordValidator] = useState({
        uppercase: false,
        lowercase: false,
        length: false,
        special: false,
    });
    const [passwordComparator, setPasswordComparator] = useState({
        origin: "",
        repeated: "",
    });

    /* PASSWORD COMPARISION */
    useEffect(() => {
        if (
            passwordValue.origin.length > 0 ||
            passwordValue.repeated.length > 0
        ) {
            if (
                passwordValidator.uppercase &&
                passwordValidator.lowercase &&
                passwordValidator.length &&
                passwordValidator.special
            ) {
                if (passwordValue.repeated.length === 0) {
                    setPasswordComparator({
                        origin: "success",
                        repeated: "",
                    });
                } else {
                    if (passwordValue.origin === passwordValue.repeated) {
                        setPasswordComparator({
                            origin: "success",
                            repeated: "success",
                        });
                    } else {
                        setPasswordComparator({
                            origin: "error",
                            repeated: "error",
                        });
                    }
                }
            } else {
                setPasswordComparator({
                    ...passwordComparator,
                    origin: "error",
                });
            }
        } else {
            if (
                passwordValue.origin.length === 0 &&
                passwordValue.repeated.length === 0
            ) {
                setPasswordComparator({
                    origin: "",
                    repeated: "",
                });
            }
        }
    }, [
        passwordValue.origin,
        passwordValue.repeated,
        passwordValidator.uppercase,
        passwordValidator.lowercase,
        passwordValidator.length,
        passwordValidator.special,
    ]);

    /* PASSWORD VALIDATION */
    useEffect(() => {
        const passwordObject = {
            lowercase: validator("lowercase", passwordValue.origin),
            uppercase: validator("uppercase", passwordValue.origin),
            length: validator("length", passwordValue.origin),
            special: validator("special", passwordValue.origin),
        };

        setPasswordValidator(passwordObject);
    }, [passwordValue.origin]);

    /* ON ENTER KEY */
    const onEnterKeyHandler = (e) => {
        const enterKey = e.charCode === 13 || e.keyCode === 13;

        if (!formSubmitDisable && enterKey) {
            e.currentTarget.blur();
            resetCodeFormRefetch();
        }
    };

    /* ENABLE SUBMIT IF VALIDATION IS CORRENT */
    const formSubmitDisable =
        passwordValidator.uppercase &&
        passwordValidator.lowercase &&
        passwordValidator.length &&
        passwordValidator.special &&
        passwordComparator.origin === "success" &&
        passwordComparator.repeated === "success"
            ? false
            : true;

    return (
        <>
            <div className="app-signing-form">
                <Title className="text-center">
                    {firstLogin ? (
                        <>
                            Twoje pierwsze logowanie
                            <span className="text-small">Zmień hasło:</span>
                        </>
                    ) : (
                        "Zresetuj hasło"
                    )}
                </Title>
                <SigningFormContainer classNameWrapper="signing">
                    <Input
                        key="Input"
                        type="password"
                        placeholder="wpisz nowe hasło"
                        value={passwordValue.origin}
                        onChangeFn={(e) =>
                            setPasswordValue({
                                ...passwordValue,
                                origin: e.currentTarget.value,
                            })
                        }
                        onKeyPressFn={(e) => onEnterKeyHandler(e)}
                        isValid={{ response: passwordComparator.origin }}
                    />
                    <Input
                        key="Input"
                        type="password"
                        placeholder="powtórz nowe hasło"
                        value={passwordValue.repeated}
                        onChangeFn={(e) =>
                            setPasswordValue({
                                ...passwordValue,
                                repeated: e.currentTarget.value,
                            })
                        }
                        onKeyPressFn={(e) => onEnterKeyHandler(e)}
                        isValid={{
                            response: passwordComparator.repeated,
                            text: "Niezgodne hasła",
                        }}
                    />
                    <SigningFormPasswordContainer text="Hasło powinno zawierać">
                        <Checkbox
                            isDisabled={true}
                            isChecked={passwordValidator.uppercase}
                        >
                            wielką literę
                        </Checkbox>
                        <Checkbox
                            isDisabled={true}
                            isChecked={passwordValidator.length}
                        >
                            od 8 do 50 znaków
                        </Checkbox>
                        <Checkbox
                            isDisabled={true}
                            isChecked={passwordValidator.lowercase}
                        >
                            małą literę
                        </Checkbox>
                        <Checkbox
                            isDisabled={true}
                            isChecked={passwordValidator.special}
                        >
                            cyfrę lub znak specjalny
                        </Checkbox>
                    </SigningFormPasswordContainer>
                    {firstLogin ? (
                        <Button
                            key="Button"
                            isDisabled={formSubmitDisable}
                            onClickFn={() => firstLoginResetMutate()}
                        >
                            Zresetuj hasło
                        </Button>
                    ) : (
                        <Button
                            key="Button"
                            isDisabled={formSubmitDisable}
                            onClickFn={() => resetCodeFormRefetch()}
                        >
                            Zresetuj hasło
                        </Button>
                    )}
                </SigningFormContainer>
                <SigningRulesContainer
                    text="Klikając „Zaloguj się” akceptujesz regulamin"
                    separator="i"
                >
                    <ButtonRuleSigning>How2 Edu</ButtonRuleSigning>
                    <ButtonRuleSigning>how2edu.app</ButtonRuleSigning>
                </SigningRulesContainer>
            </div>
        </>
    );
};

export default ResetForm;
