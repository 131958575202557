import checkIfIdOccurs from "../../../../functions/checkIfIdOccurs";
import polishCharsReplace from "../../../../functions/polishCharsReplace";

import Button from "../../../buttons/Button";

import StudentsListItem from "./StudentsListItem";

const StudentsListContainer = ({
    studentsData,
    studentsSelected,
    studentsExistInGroup,
    submitDisabled,
    searchValues,
    isDisabled,
    onChangeFn,
    onSubmitFn,
}) => {
    const searchErrorMessage = 'Brak uczniów';
    
    const searchIsEmpty = (array) => {
        for(const object of array) {
            if(typeof object === 'object') {
                return false;
            }
        }

        return true;
    }

    const searchInList = () => {
        const searchData = studentsData.data.map(object => {
            const name = polishCharsReplace(object.name + ' ' + object.surname).toLowerCase();
            const valueName = polishCharsReplace(searchValues.name).toLowerCase();

            const schoolClass = polishCharsReplace(object.className).toLowerCase();
            const valueSchoolClass = polishCharsReplace(searchValues.schoolClass).toLowerCase();
            
            const foundByName = name.indexOf(valueName) !== -1;
            const foundBySchoolClass = schoolClass.indexOf(valueSchoolClass) !== -1;

            if(valueName.length > 2 && valueSchoolClass.length <= 1) {
                if(foundByName) {
                    if (!checkIfIdOccurs(object.idUser, studentsExistInGroup)) {
                        return (
                            <StudentsListItem
                                key={"student_" + object.idUser}
                                id={object.idUser}
                                name={object.name}
                                surname={object.surname}
                                schoolClass={object.className}
                                isDisabled={isDisabled}
                                isSelected={checkIfIdOccurs(
                                    object.idUser,
                                    studentsSelected
                                )}
                                onClickFn={(e) => onChangeFn(e)}
                            />
                        );
                    } else {
                        return ''
                    }
                } else {
                    return ''
                }
            } else if(valueSchoolClass.length > 1 && valueName.length <= 2) {
                if(foundBySchoolClass) {
                    if (!checkIfIdOccurs(object.idUser, studentsExistInGroup)) {
                        return (
                            <StudentsListItem
                                key={"student_" + object.idUser}
                                id={object.idUser}
                                name={object.name}
                                surname={object.surname}
                                schoolClass={object.className}
                                isDisabled={isDisabled}
                                isSelected={checkIfIdOccurs(
                                    object.idUser,
                                    studentsSelected
                                )}
                                onClickFn={(e) => onChangeFn(e)}
                            />
                        );
                    } else {
                        return ''
                    }
                } else {
                    return '';
                }
            } else if(valueSchoolClass.length > 1 && valueName.length > 2) {
                if(foundByName && foundBySchoolClass) {
                    if (!checkIfIdOccurs(object.idUser, studentsExistInGroup)) {
                        return (
                            <StudentsListItem
                                key={"student_" + object.idUser}
                                id={object.idUser}
                                name={object.name}
                                surname={object.surname}
                                schoolClass={object.className}
                                isDisabled={isDisabled}
                                isSelected={checkIfIdOccurs(
                                    object.idUser,
                                    studentsSelected
                                )}
                                onClickFn={(e) => onChangeFn(e)}
                            />
                        );
                    } else {
                        return ''
                    }
                }
            } else {
                if (!checkIfIdOccurs(object.idUser, studentsExistInGroup)) {
                    return (
                        <StudentsListItem
                            key={"student_" + object.idUser}
                            id={object.idUser}
                            name={object.name}
                            surname={object.surname}
                            schoolClass={object.className}
                            isDisabled={isDisabled}
                            isSelected={checkIfIdOccurs(
                                object.idUser,
                                studentsSelected
                            )}
                            onClickFn={(e) => onChangeFn(e)}
                        />
                    );
                } else {
                    return ''
                }
            }
        })

        return !searchIsEmpty(searchData) ? searchData : <div className="error-message text-center">{searchErrorMessage}</div>;
    }

    return (
        <>
            <div className="dashboard-students-list">
                {searchInList()}
            </div>
            <Button
                className="disabled-alt"
                action="add"
                isLoading={isDisabled}
                isDisabled={isDisabled || submitDisabled}
                onClickFn={onSubmitFn}
            >
                Dołącz uczniów do grupy
            </Button>
        </>
    );
};

export default StudentsListContainer;
