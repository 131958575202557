import { useState, useEffect, useContext } from "react";
import { useQuery } from "react-query";

import { StoreContext } from "../../../context/Store";

import getLoadingState from "../functions/getLoadingState";

const useReaderData = (id) => {
    const { api } = useContext(StoreContext);

    const [isContentValid, setIsContentValid] = useState(false);
    const [isContentError, setIsContentError] = useState(false);
    const [isMissingError, setIsMissingError] = useState(false);
    const [isSubscriptionError, setIsSubscriptionError] = useState(false);

    const {
        data: multibookData,
        isFetched: multibookIsFetched,
        isLoading: multibookIsLoading,
    } = useQuery(
        ["multibookShow", parseInt(id)],
        () =>
            api.post("/multibooks/show", {
                idMultibook: parseInt(id),
            }),
        {
            staleTime: Infinity,
            retry: false,
            refetchOnWindowFocus: false,
            onError: (error) => {
                if (error.response) {
                    if (error.response.status === 404) {
                        setIsMissingError(true);
                    } else {
                        if (
                            error.response.data.message ===
                            "NO_ACTIVE_SUBSCRIPTION"
                        ) {
                            setIsSubscriptionError(true);
                        }
                    }
                } else {
                    if (error.message === "Network Error") {
                        setIsMissingError(true);
                    }
                }
            },
        }
    );

    const dataIsLoading = getLoadingState(
        [multibookIsFetched],
        [multibookIsLoading]
    );

    useEffect(() => {
        if (!dataIsLoading && !isSubscriptionError && !isMissingError) {
            if (
                multibookData.data.errors === "NO_BOOK_CONTENT" ||
                multibookData.data.pages.total === 0
            ) {
                setIsContentError(true);
            } else {
                setIsContentValid(true);
            }
        }
    }, [dataIsLoading, multibookData, isSubscriptionError, isMissingError]);

    const {
        data: chaptersData,
        isFetched: chaptersIsFetched,
        isLoading: chaptersIsLoading,
        refetch: chaptersUpdate,
    } = useQuery(
        ["multibookChapters", parseInt(id)],
        () =>
            api.post(`/multibooks/chapters`, {
                idMultibook: parseInt(id),
            }),
        {
            enabled: isContentValid,
            staleTime: Infinity,
            retry: false,
            refetchOnWindowFocus: false,
        }
    );

    const hookIsLoading = getLoadingState(
        [multibookIsFetched, chaptersIsFetched],
        [multibookIsLoading, chaptersIsLoading]
    );

    return !dataIsLoading
        ? !isMissingError && !isSubscriptionError
            ? !isContentError
                ? !hookIsLoading
                    ? {
                          multibook: {
                              cover: multibookData.data.multibook.cover,
                              title: multibookData.data.multibook.title,
                              subtitle: multibookData.data.multibook.subtitle,
                              type: multibookData.data.multibook.type,
                              isFavorite: multibookData.data.isFavorite,
                          },
                          chapters: {
                              data: chaptersData.data,
                              update: chaptersUpdate,
                          },
                          examType: multibookData.data.type,
                          pages: {
                              initial: parseInt(
                                  multibookData.data.pages.current
                              ),
                              progress: parseInt(
                                  multibookData.data.pages.progress
                              ),
                              total: parseInt(multibookData.data.pages.total),
                              finished: parseInt(
                                  multibookData.data.pages.finished
                              ),
                          },
                          settings: {
                              color: multibookData.data.settings.color,
                              fontSize: multibookData.data.settings.fontSize,
                              fontFamily:
                                  multibookData.data.settings.fontFamily,
                          },
                          isMissingError: false,
                          isSubscriptionActive: true,
                          isLoading: false,
                      }
                    : { isLoading: true }
                : {
                      error: true,
                      multibook: {
                          cover: multibookData.data.multibook.cover,
                          title: multibookData.data.multibook.title,
                          subtitle: multibookData.data.multibook.subtitle,
                          isFavorite: "disabled",
                      },
                      examType: undefined,
                      pages: {
                          initial: 0,
                          total: 0,
                      },
                      isMissingError: false,
                      isSubscriptionActive: true,
                      isLoading: false,
                  }
            : {
                  error: true,
                  examType: undefined,
                  pages: {
                      initial: 0,
                      total: 0,
                  },
                  isMissingError,
                  isSubscriptionActive: false,
                  isLoading: false,
              }
        : { isLoading: true };
};

export default useReaderData;
