import { useRef } from "react";
import { useCookies } from "react-cookie";

import useProtectedRoute from "../../hooks/useProtetedRoute";

import { getCookieTime } from "../../App";

import Beginning from "../Beginning";
import FirstLoginReset from "./FirstLoginReset";

import DashboardParent from "../dashboard/DashboardParent";
import DashboardChildren from "./DashboardChildren";
import DashboardTeacher from "./DashboardTeacher";

import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const Dashboard = () => {
    useProtectedRoute("/", false);

    const lastPath = useRef(null);
    const currentPath = useRef(null);
    const idLibrary = useRef(null);

    const [cookies, setCookie] = useCookies([
        "active",
        "firstLogin",
        "idRank",
        "usePathClone",
    ]);

    const location = useLocation();

    useEffect(() => {
        const path = location.pathname;

        lastPath.current = currentPath.current;
        currentPath.current = path;

        const categoryPath = "/dashboard/library/category-";
        const idLibraryTemp =
            path.indexOf(categoryPath) !== -1
                ? parseInt(path.substring(categoryPath.length))
                : null;

        if (idLibraryTemp) {
            idLibrary.current = idLibraryTemp;
        }

        setCookie(
            "userPath",
            {
                last: lastPath.current,
                current: currentPath.current,
                idLibrary: idLibrary.current,
            },
            { path: "/", expires: getCookieTime() }
        );
    }, [location, setCookie]);

    const isAccountActive = Number(cookies.active) === 1;
    const firstLogin = Number(cookies.firstLogin) === 1;
    const userRank = Number(cookies.idRank);

    return isAccountActive ? (
        !firstLogin ? (
            <>
                {/* Parent */}
                {userRank === 2 && <DashboardParent />}
                {/* Student */}
                {userRank === 3 && <DashboardChildren />}
                {/* Teacher */}
                {userRank === 4 && <DashboardTeacher />}
            </>
        ) : (
            <FirstLoginReset isFirstLogin={firstLogin} />
        )
    ) : (
        <Beginning userRank={userRank} />
    );
};

export default Dashboard;
