const getSchoolName = (array, id) => {
    if (array && id) {
        for (const item of array) {
            if (item?.idSchool === parseInt(id)) {
                return item.name;
            }
        }
    }
};

export default getSchoolName;