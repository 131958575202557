import { useRef, useContext } from "react";
import { useCookies } from "react-cookie";
import { useMutation, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";

import { StoreContext } from "../context/Store";

const useLogout = () => {
    const history = useHistory();
    const removeCookie = useCookies([
        "token",
        "idRank",
        "active",
        "firstLogin",
        "schoolsEmail",
    ])[2];

    const { api, setIsUserLogged } = useContext(StoreContext);

    const queryClient = useQueryClient();

    const redirectPath = useRef(null);
    const redirectState = useRef(null);

    const { mutate: logout } = useMutation(() => api.post(`/logout`), {
        onMutate: () => {
            removeCookie("token", {
                path: "/",
            });
            removeCookie("idRank", {
                path: "/",
            });
            removeCookie("active", {
                path: "/",
            });
            removeCookie("firstLogin", {
                path: "/",
            });
            removeCookie("schoolsEmail", {
                path: "/",
            });

            queryClient.removeQueries("account");

            setIsUserLogged(false);

            if (redirectPath.current) {
                history.push(redirectPath.current, redirectState.current);
            }
        },
    });

    return (path, state) => {
        redirectPath.current = path;
        redirectState.current = state;

        logout();
    };
};

export default useLogout;
