import { useState, useEffect, useRef, useCallback } from "react";

import Loader from "../../Loader";

import ShelfBook from "./ShelfBook";
import ShelfEmpty from "./ShelfEmpty";

const ShelfList = ({
    data,
    dataResetExecute,
    setDataResetFlag,
    nextPageHandler,
    bookRemove,
    isLoading,
    onRequestDelete,
}) => {
    const { idBookRemove, bookRemoveExecute, setBookRemoveExecute } =
        bookRemove;

    /* DATA UPDATE FLAG */
    const dataIsUpdated = useRef(false);

    /* PAGE STATES */
    const [page, setPage] = useState(1);
    const isLastPage = useRef(false);
    const [pageLoading, setPageLoading] = useState(false);

    /* BOOK STATES */
    const [books, setBooks] = useState([]);
    const [lastBook, setLastBook] = useState(null);
    const booksEmpty = useRef(true);
    const [booksLoading, setBooksLoading] = useState(false);

    /* BOOK OBSERVER */
    const bookObserver = useRef(
        new IntersectionObserver((entry) => {
            const inView = entry[0].isIntersecting;

            if (inView && !isLastPage.current && dataIsUpdated.current) {
                setPage((prevPage) => prevPage + 1);
                setPageLoading(true);
            }
        })
    );

    /* RESET BOOKS ON FILTER CHANGE */
    useEffect(() => {
        if (isLoading) {
            setBooks([]);

            dataIsUpdated.current = false;
            booksEmpty.current = true;

            setBooksLoading(true);
        }
    }, [isLoading]);

    /* SET NEW BOOKS IF DATA CHANGES */
    useEffect(() => {
        if (!isLoading) {
            if (data?.length < 11) {
                isLastPage.current = true;

                /* SET RESET FLAG FOR LAST PAGE */
                setDataResetFlag();
            }

            if (!dataIsUpdated.current) {
                if (data) {
                    setBooks((prevBooks) => [...prevBooks, ...data]);

                    dataIsUpdated.current = true;

                    setPageLoading(false);
                }
            }
        }
    }, [data, isLoading, setDataResetFlag]);

    /* SET EMPTY BOOKS */
    useEffect(() => {
        if (dataIsUpdated.current) {
            if (books.length > 0) {
                booksEmpty.current = false;
            } else {
                booksEmpty.current = true;
            }

            setBooksLoading(false);
        }
    }, [books]);

    /* SET NEXT PAGE */
    useEffect(() => {
        if (page > 1) {
            dataIsUpdated.current = false;

            nextPageHandler(page);
        }
    }, [page, nextPageHandler]);

    /* RESET PAGE ON FILTER CHANGE */
    useEffect(() => {
        if (dataResetExecute) {
            isLastPage.current = false;

            setPage(1);
        }
    }, [dataResetExecute]);

    /* OBSERVE LAST BOOK */
    useEffect(() => {
        const currentBook = lastBook;
        const currentObserver = bookObserver.current;

        if (currentBook) {
            currentObserver.observe(currentBook);
        }

        return () => {
            if (currentBook) {
                currentObserver.unobserve(currentBook);
            }
        };
    }, [lastBook]);

    /* BOOK DELETE HANDLER */
    const bookDeleteHandler = useCallback(
        (id) => {
            const newBooks = books.filter((e) => {
                if (e.idMultibook === id) {
                    return false;
                } else {
                    return true;
                }
            });

            setBooks(newBooks);
        },
        [books]
    );

    useEffect(() => {
        if (bookRemoveExecute) {
            bookDeleteHandler(idBookRemove);

            setBookRemoveExecute(false);
        }
    }, [
        bookRemoveExecute,
        bookDeleteHandler,
        idBookRemove,
        setBookRemoveExecute,
    ]);

    return (
        <>
            {isLoading ? (
                <div className="ShelfList__Loader">
                    <Loader type="ring" />
                </div>
            ) : (
                !booksLoading &&
                (booksEmpty.current ? (
                    <ShelfEmpty />
                ) : (
                    <>
                        <div className="ShelfList">
                            {books.map((object, index) => {
                                return (
                                    <div
                                        ref={
                                            index === books.length - 1
                                                ? setLastBook
                                                : undefined
                                        }
                                        className="ShelfList__Item"
                                        key={"multibook_" + object.idMultibook}
                                    >
                                        <ShelfBook
                                            data={{
                                                id: object.idMultibook,
                                                type: object.type,
                                                cover: object.cover,
                                                title: object.title,
                                                content: object.subtitle,
                                                favorite: object.favorite,
                                                pages: {
                                                    current: parseInt(
                                                        object.current
                                                    ),
                                                    progress:
                                                        object.user_visited ??
                                                        object.progress,
                                                    total: object.total,
                                                },
                                            }}
                                            onRequestDelete={onRequestDelete}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                        {pageLoading ? (
                            <div className="ShelfList__Loader _pagination">
                                <Loader className="_size--small" type="ring" />
                            </div>
                        ) : (
                            ""
                        )}
                    </>
                ))
            )}
        </>
    );
};

export default ShelfList;
