import { useRef, useEffect, useContext } from "react";

import { ExamContext } from "../context/ExamStore";

import useSaveExamAnswers from "../hooks/useSaveExamAnswers";
import useGetExamResult from "../hooks/useGetExamResult";
import useFinishExam from "../hooks/useFinishExam";

import ReaderSummary from "../utils/ReaderSummary";
import { examPageScrollTime, examPageScrollTop } from "../utils/ReaderPager";

const ReaderGallup = ({ idChapter, examPage, examProgress, onFinish }) => {
    const {
        setExamResultStore,
        isGallupResultLoading,
        setIsGallupResultLoading,
    } = useContext(ExamContext);

    /* EXAM ANSWERS SAVE HOOK */
    const { examAnswersSave, isExamAnswersSaveSuccess } =
        useSaveExamAnswers(idChapter);

    /* EXAM RESULT HOOK */
    const { examResultData, examResultRefetch, isExamResultLoading } =
        useGetExamResult(idChapter);

    /* EXAM FINISH HOOK */
    const { examFinish, isExamFinishSuccess } = useFinishExam(idChapter);

    /* EXAM GALLUP FINISHING FLAG */
    const isGallupFinishing = useRef(false);

    /* EXAM GALLUP FINISH HANDLER */
    const handleGallupFinish = () => {
        isGallupFinishing.current = true;

        setIsGallupResultLoading(true);

        examAnswersSave();
    };

    /* GET EXAM RESULT */
    useEffect(() => {
        if (isExamAnswersSaveSuccess && isGallupFinishing.current) {
            examResultRefetch();
        }
    }, [isExamAnswersSaveSuccess, examResultRefetch]);

    /* FINISH EXAM */
    useEffect(() => {
        if (
            examResultData &&
            !isExamResultLoading &&
            isGallupFinishing.current
        ) {
            setExamResultStore(examResultData);

            examFinish();
        }
    }, [examResultData, isExamResultLoading, setExamResultStore, examFinish]);

    /* PREPARE TO MOUNT GALLUP RESULT CHAPTER */
    useEffect(() => {
        if (isExamFinishSuccess && isGallupFinishing.current) {
            isGallupFinishing.current = false;

            onFinish();

            examPageScrollTop(examPageScrollTime);
        }
    }, [isExamFinishSuccess, onFinish]);

    return (
        <>
            <ReaderSummary
                title="Zapisz swoje odpowiedzi, aby zobaczyć wynik i sprawdzić co Twój
                wynik oznacza."
                page={examPage}
                progress={examProgress}
                isLoading={isGallupResultLoading}
                onClick={handleGallupFinish}
            />
        </>
    );
};

export default ReaderGallup;
