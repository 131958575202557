import { useRef, useEffect, useContext } from "react";

import { PlayerContext } from "../context/PlayerStore";

import useComponentTransition from "../hooks/useComponentTransition";

import ReaderRedirect from "../buttons/ReaderRedirect";

const ReaderPlayer = ({ color }) => {
    const { show, hide, isMounted, entering, entered, exiting } =
        useComponentTransition(400);

    const { playerData, setPlayerData } = useContext(PlayerContext);
    const playerRef = useRef(false);
    const playerIsActive = useRef(false);

    useEffect(() => {
        if (playerData && !playerIsActive.current) {
            playerIsActive.current = true;

            show();
        } else if (!playerData && playerIsActive.current) {
            playerIsActive.current = false;

            hide();
        }
    }, [playerData, show, hide]);

    useEffect(() => {
        if (entering) {
            playerRef.current.play();
        }
    }, [entering]);

    useEffect(() => {
        if (exiting) {
            playerRef.current.pause();
        }
    }, [exiting]);

    return (
        <>
            {isMounted ? (
                <div
                    className={
                        "ReaderPlayer" + (entering || entered ? " _open" : "")
                    }
                    data-panel="false"
                >
                    <div className="ReaderPlayer__Close">
                        <ReaderRedirect
                            text="Wróć"
                            color={color}
                            inverted
                            onClick={() => setPlayerData(null)}
                        />
                    </div>
                    <video
                        className="ReaderPlayer__Video"
                        controls={false}
                        ref={playerRef}
                    >
                        <source src={playerData} type="video/mp4" />
                    </video>
                </div>
            ) : (
                ""
            )}
        </>
    );
};

export default ReaderPlayer;
