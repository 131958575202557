import SchoolPanel from "./SchoolPanel";
import Button from "../../../buttons/Button";
import Loader from "../../Loader";

const SchoolPanels = ({ text, max, data, keyName, isRefetching, onClick }) => {
  const SchoolPanelsButton = () => {
    return (
      <div className="SchoolPanels__Button">
        <Button
          action="add"
          onClickFn={onClick?.button ? onClick.button : undefined}
        >
          {text?.button ? text.button : "Undefined"}
        </Button>
      </div>
    );
  };

  return (
    <>
      <div className="SchoolPanels">
        {isRefetching ? (
          <div className="SchoolPanels__Loader">
            <Loader type="ring" />
          </div>
        ) : (
          <>
            {data?.length > 0 ? (
              <div className="SchoolPanels__Group">
                {data.map((object) => {
                  return (
                    <SchoolPanel
                      key={keyName + "_" + object.idSchool}
                      id={object.idSchool}
                      text={{
                        title: object.name,
                        action: text?.action,
                      }}
                      disabled={
                        object.active !== undefined
                          ? object.active === 1
                            ? false
                            : true
                          : false
                      }
                      onClick={onClick?.panel ? onClick.panel : undefined}
                    />
                  );
                })}
              </div>
            ) : (
              ""
            )}
            {data ? (
              data.length < max ? (
                <SchoolPanelsButton />
              ) : (
                <div className="SchoolPanels__Alert">
                  {text?.alert ? text.alert : "Alert text is undefined"}
                </div>
              )
            ) : (
              <SchoolPanelsButton />
            )}
          </>
        )}
      </div>
    </>
  );
};

export default SchoolPanels;
