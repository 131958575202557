import { useContext } from "react";
import { useMutation } from "react-query";

import { StoreContext } from "../../../context/Store";

const useShareExam = (idChapter) => {
    const { api } = useContext(StoreContext);

    const {
        mutate: examShare,
        isSuccess: isExamShareSuccess,
        isError: isExamShareError,
    } = useMutation(() =>
        api.post("/exams/mark_visible_for_parent", { idChapter })
    );

    return {
        examShare,
        isExamShareSuccess,
        isExamShareError,
    };
};

export default useShareExam;
