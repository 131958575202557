import { useState } from "react";

const Input = ({
    id,
    type,
    placeholder,
    placeholderFocus,
    value,
    maxLength,
    classNameWrapper,
    classNameControl,
    isValid,
    isDisabled,
    onChangeFn,
    onFocusFn,
    onKeyPressFn,
}) => {
    const [placeholderFocusVisible, setPlaceholderFocusVisible] =
        useState(false);

    const numTest = (e) => {
        return (/[a-zA-Z!@#$%^&*)(=_\-|\\}\]{["':;?/>.<,]/g.test(e.key) &&
            e.key !== "Backspace" &&
            e.key !== "ArrowLeft" &&
            e.key !== "ArrowRight" &&
            e.key !== "Tab") ||
            e.key === " "
            ? true
            : false;
    };

    const onChangeHandler = (e) => {
        const val = e.currentTarget.value;

        if (val.length <= maxLength) {
            onChangeFn(e);
        }
    };

    const onKeyHandler = (e) => {
        if (numTest(e)) {
            e.preventDefault();

            return false;
        }
    };

    return (
        <>
            <div
                className={
                    "form-control-wrapper" +
                    (classNameWrapper ? " " + classNameWrapper : "") +
                    (isValid && isValid.response === "error" ? " error" : "") +
                    (isValid && isValid.response === "success"
                        ? " success"
                        : "")
                }
            >
                <input
                    id={id}
                    className={
                        "form-control" +
                        (classNameControl ? " " + classNameControl : "") +
                        (placeholderFocusVisible ? " focus-placeholder" : "")
                    }
                    type={type}
                    placeholder={
                        placeholderFocusVisible ? placeholderFocus : placeholder
                    }
                    value={value}
                    disabled={isDisabled}
                    onChange={
                        maxLength
                            ? (e) => onChangeHandler(e)
                            : onChangeFn !== undefined
                            ? (e) => onChangeFn(e)
                            : undefined
                    }
                    onFocus={
                        placeholderFocus
                            ? () => setPlaceholderFocusVisible(true)
                            : onFocusFn !== undefined
                            ? (e) => onFocusFn(e)
                            : undefined
                    }
                    onBlur={
                        placeholderFocus
                            ? () => setPlaceholderFocusVisible(false)
                            : undefined
                    }
                    onKeyPress={
                        onKeyPressFn !== undefined
                            ? (e) => onKeyPressFn(e)
                            : undefined
                    }
                    onKeyUp={
                        type === "tel" ? (e) => onKeyHandler(e) : undefined
                    }
                    onKeyDown={
                        type === "tel" ? (e) => onKeyHandler(e) : undefined
                    }
                    onClick={(e) => e.preventDefault()}
                />
            </div>
            {isValid && isValid.response === "error" && isValid.text ? (
                <p className="form-control-message text-center">
                    {isValid.text}
                </p>
            ) : (
                ""
            )}
        </>
    );
};

export default Input;
