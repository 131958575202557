const getLoadingState = (isFetched, isLoading) => {
    let fetchedState = true;
    let loadingState = false;

    if (isFetched && Array.isArray(isFetched)) {
        for (const data of isFetched) {
            if (!data) {
                fetchedState = false;
                break;
            }
        }
    }

    if (isLoading && Array.isArray(isLoading)) {
        for (const data of isLoading) {
            if (data) {
                loadingState = true;
                break;
            }
        }
    }

    return fetchedState ? (loadingState ? true : false) : true;
};

export default getLoadingState;
