import "katex/dist/katex.min.css";
import { BlockMath, InlineMath } from "react-katex";

const ReaderMath = ({ content, inline }) => {
    return content ? (
        inline ? (
            <span className="ReaderMathInline"><InlineMath math={content} /></span>
        ) : (
            <div className="ReaderMath">
                <BlockMath math={content} />
            </div>
        )
    ) : (
        ""
    );
};

export default ReaderMath;
