import { useRef, useEffect, useContext } from "react";

import { ExamContext } from "../context/ExamStore";

import useFinishExam from "../hooks/useFinishExam";
import useGetExamResult from "../hooks/useGetExamResult";
import useSaveExamAnswers from "../hooks/useSaveExamAnswers";

import ReaderSummary from "../utils/ReaderSummary";

const ReaderGibs = ({ idChapter, examPage, examProgress, onFinish }) => {
    const { setExamResultStore, isGibsResultLoading, setIsGibsResultLoading } =
        useContext(ExamContext);

    /* EXAM ANSWERS SAVE HOOK */
    const { examAnswersSave, isExamAnswersSaveSuccess } =
        useSaveExamAnswers(idChapter);

    /* EXAM RESULT HOOK */
    const { examResultData, examResultRefetch, isExamResultLoading } =
        useGetExamResult(idChapter);

    /* EXAM FINISH HOOK */
    const { examFinish, isExamFinishSuccess } = useFinishExam(idChapter);

    /* EXAM GIBS FINISHING FLAG */
    const isGibsFinishing = useRef(false);

    /* EXAM GIBS FINISH HANDLER */
    const handleGibsFinish = () => {
        isGibsFinishing.current = true;

        setIsGibsResultLoading(true);

        examAnswersSave();
    };

    /* GET EXAM RESULT */
    useEffect(() => {
        if (isExamAnswersSaveSuccess && isGibsFinishing.current) {
            examResultRefetch();
        }
    }, [isExamAnswersSaveSuccess, examResultRefetch]);

    /* FINISH EXAM */
    useEffect(() => {
        if (examResultData && !isExamResultLoading && isGibsFinishing.current) {
            setExamResultStore(examResultData, true);

            examFinish();
        }
    }, [examResultData, isExamResultLoading, setExamResultStore, examFinish]);

    /* PREPARE TO MOUNT FINAL PAGE WITH GIBS RESULT */
    useEffect(() => {
        if (isExamFinishSuccess && isGibsFinishing.current) {
            isGibsFinishing.current = false;

            onFinish();
        }
    }, [isExamFinishSuccess, onFinish]);
    
    return (
        <>
            <ReaderSummary
                className="_is--gibs"
                page={examPage}
                progress={examProgress}
                isLoading={isGibsResultLoading}
                onClick={handleGibsFinish}
            />
        </>
    );
};

export default ReaderGibs;
