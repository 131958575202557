import { useContext } from "react";
import { useQuery } from "react-query";

import { StoreContext } from "../context/Store";

const useCheckSubscription = () => {
    const { api } = useContext(StoreContext);

    const {
        data: subscriptionData,
        isFetched: isSubscriptionFetched,
        isLoading: isSubscriptionLoading,
    } = useQuery("subscription-status", () => api.get(`/account/check_subscription`), {
        staleTime: Infinity,
        retry: false,
        refetchOnWindowFocus: false,
    });

    return {
        isSubscriptionActive: subscriptionData?.data.is_active,
        isSubscriptionFetched,
        isSubscriptionLoading
    }
}

export default useCheckSubscription;