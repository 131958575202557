import { useState } from 'react';

const ReadMoreButton = ({text, children}) => {
    const [readState, setReadState] = useState(false);

    return (
        <>
            <span className="readmore">
                {
                    readState
                    ? <span className="readmore-text">{children}</span>
                    : <button className="readmore-button" onClick={() => setReadState(true)}>{text}</button>
                }
            </span>
        </>
    )
}

export default ReadMoreButton;