import { useContext } from "react";
import { useQuery } from "react-query";

import { StoreContext } from "./context/Store";

const Authorization = ({ children }) => {
    const { api, setIsUserLogged } = useContext(StoreContext);

    const { data: token, isLoading: tokenValidateIsLoading } =
        useQuery("tokenValidate", () => api.get(`/token/validate`), {
            retry: false,
            refetchOnWindowFocus: false,
            onSuccess: () => setIsUserLogged(true),
            onError: () => setIsUserLogged(false),
        });

    return tokenValidateIsLoading ? (
        ''
    ) : (
        children
    );
};

export default Authorization;
