import { useState, useRef, useEffect, useContext } from "react";
import { useCookies } from "react-cookie";

import { SettingsContext } from "./context/SettingsStore";

import useReaderData from "./hooks/useReaderData";

import ReaderLoading from "./ReaderLoading";
import ReaderCover from "./ReaderCover";
import ReaderBook from "./ReaderBook";
import ReaderPanel from "./panels/ReaderPanel";
import ReaderPlayer from "./modals/ReaderPlayer";
import ReaderExit from "./utils/ReaderExit";

import "./assets/css/theme-default.css";
import "./assets/css/theme-dark.css";

const setThemeColorClassName = (theme) => {
    switch (theme) {
        case "dark":
            return " _theme--dark";
        default:
            return "";
    }
};

const getPageRedirect = (idBook, chapterRedirect, chapters) => {
    if (!chapters || !chapterRedirect) return;

    for (const chapter of chapters) {
        if (
            parseInt(idBook) === parseInt(chapterRedirect.idMultibook) &&
            chapter.idChapter === parseInt(chapterRedirect.idChapter)
        ) {
            return chapter.order;
        }
    }
};

const Reader = ({ idBook }) => {
    const {
        multibook,
        chapters,
        examType,
        pages,
        settings,
        error,
        isMissingError,
        isSubscriptionActive,
        isLoading: isReaderLoading,
    } = useReaderData(idBook);

    /* GET COOKIES */
    const cookies = useCookies(["chapterRedirect"])[0];
    const removeCookie = useCookies(["chapterRedirect"])[2];

    /* MULTIBOOK DEFAULTS */
    const isBookInit = useRef(false);
    const [isBookMount, setIsBookMount] = useState(false);

    const isCoverFirstInit = useRef(true);
    const isCoverSkipped = useRef(true);

    /* MULTIBOOK INIT PAGES */
    const isPagesSet = useRef(false);

    const [page, setPage] = useState(0);
    const isPageRedirecting = useRef(false);

    const [pagerDirection, setPagerDirection] = useState(null);
    const isPagerDisabled = useRef(false);

    useEffect(() => {
        if (pages && !isPagesSet.current) {
            isPagesSet.current = true;

            if (pages.initial > 0 || pages.progress > 0) {
                setIsBookMount(true);
            } else {
                isCoverSkipped.current = false;
            }

            const chapterRedirect = cookies.chapterRedirect;
            const pageRedirect = !error
                ? getPageRedirect(idBook, chapterRedirect, chapters.data)
                : undefined;

            if (pageRedirect) {
                isPageRedirecting.current = true;

                setPage(pageRedirect);
            } else {
                if (chapterRedirect) {
                    removeCookie("chapterRedirect", { path: "/" });
                }

                if (pages.finished === 1) {
                    setPage(pages.total + 1);
                } else {
                    if (pages.initial > pages.total) {
                        setPage(1);
                    } else {
                        setPage(pages.initial === 0 ? 1 : pages.initial);
                    }
                }
            }
        }
    }, [pages, error, cookies, idBook, chapters, removeCookie]);

    /* MULTIBOOK COVER ENTER ANIMATION */
    const [isBookPending, setIsBookPending] = useState(false);
    const [isBookReady, setIsBookReady] = useState(false);

    const trackRef = useRef(null);

    const [isInitialChapterLoading, setIsInitialChapterLoading] =
        useState(true);

    useEffect(() => {
        if (
            isBookMount &&
            !isInitialChapterLoading &&
            !isBookPending &&
            !isBookReady
        ) {
            if (isCoverSkipped.current) {
                isCoverFirstInit.current = false;

                setIsBookReady(true);
            } else {
                setIsBookPending(true);
            }
        }
    }, [isBookMount, isBookPending, isBookReady, isInitialChapterLoading]);

    useEffect(() => {
        if (isBookPending && !isBookInit.current) {
            const trackTime =
                parseFloat(
                    window
                        .getComputedStyle(trackRef.current)
                        .getPropertyValue("animation-duration")
                ) * 1000;

            isBookInit.current = true;

            setTimeout(() => {
                setIsBookReady(true);

                setPagerDirection(null);
            }, trackTime);
        }
    }, [isBookPending]);

    useEffect(() => {
        if (isBookPending && isBookReady) {
            isCoverFirstInit.current = false;

            setIsBookPending(false);
        }
    }, [isBookPending, isBookReady]);

    /* MULTIBOOK COVER RETURN ANIMATION */
    const [isCoverPending, setIsCoverPending] = useState(false);
    const [isCoverReady, setIsCoverReady] = useState(false);

    useEffect(() => {
        if (isCoverPending) {
            const trackTime =
                parseFloat(
                    window
                        .getComputedStyle(trackRef.current)
                        .getPropertyValue("animation-duration")
                ) * 1000;

            isCoverSkipped.current = false;

            setTimeout(() => {
                setIsCoverReady(true);
            }, trackTime);
        }
    }, [isCoverPending]);

    useEffect(() => {
        if (isCoverReady && isBookMount) {
            isBookInit.current = false;

            setIsBookMount(false);
            setIsBookPending(false);
            setIsBookReady(false);
        }

        if (isCoverReady && !isBookMount && !isBookPending && !isBookReady) {
            isPagerDisabled.current = false;

            setIsCoverPending(false);
            setIsCoverReady(false);

            setPagerDirection(null);
        }
    }, [isCoverReady, isBookMount, isBookPending, isBookReady]);

    /* MULTIBOOK SHUTDOWN */
    const [isBookShutdown, setIsBookShutdown] = useState(false);
    const [finishBookShutdown, setFinishBookShutdown] = useState(false);

    /* MULTIBOOK SETTINGS */
    const settingsIsSet = useRef(false);

    const { themeColor, setThemeColor, setFontSize, setFontFamily } =
        useContext(SettingsContext);

    useEffect(() => {
        if (settings && !settingsIsSet.current) {
            settingsIsSet.current = true;

            setThemeColor(settings.color);
            setFontSize(settings.fontSize);
            setFontFamily({
                family: settings.fontFamily.family,
                name: settings.fontFamily.name,
            });
        }
    }, [settings, setThemeColor, setFontSize, setFontFamily]);

    return (
        <>
            {!isReaderLoading && !finishBookShutdown ? (
                <div className={"Reader" + setThemeColorClassName(themeColor)}>
                    <div
                        className={
                            "Reader__Track" +
                            (isBookPending && !isBookReady
                                ? " _animating"
                                : "") +
                            (isCoverPending && !isCoverReady
                                ? " _animating-reverse"
                                : "")
                        }
                        ref={trackRef}
                    >
                        {(!isBookReady && !isCoverSkipped.current) ||
                        isCoverPending ? (
                            <div className="Reader__Content">
                                <ReaderCover
                                    data={multibook}
                                    bookMount={{
                                        isBookMount: isBookMount,
                                        setIsBookMount: setIsBookMount,
                                    }}
                                    error={error}
                                    shutdown={{
                                        isBookShutdown,
                                        setIsBookShutdown,
                                        finishBookShutdown,
                                        setFinishBookShutdown,
                                    }}
                                    isMissingError={isMissingError}
                                    isSubscriptionActive={isSubscriptionActive}
                                    isFirstInit={isCoverFirstInit}
                                    isPending={isCoverPending || isCoverReady}
                                    isLoading={isInitialChapterLoading}
                                    isGallup={examType === "gallup_test"}
                                    isGibs={examType === "gibs_test"}
                                />
                            </div>
                        ) : (
                            ""
                        )}
                        {isBookMount ? (
                            <div className="Reader__Content">
                                <ReaderBook
                                    idBook={idBook}
                                    type={multibook.type}
                                    title={multibook.title}
                                    pages={{
                                        page,
                                        setPage,
                                        pagesProgress: pages.progress,
                                        pagesTotal: pages.total,
                                    }}
                                    chapters={chapters}
                                    pager={{
                                        isPagerDisabled,
                                        pagerDirection,
                                        setPagerDirection,
                                    }}
                                    initialChapterLoading={{
                                        isInitialChapterLoading,
                                        setIsInitialChapterLoading,
                                    }}
                                    cover={{
                                        isCoverPending,
                                        setIsCoverPending,
                                    }}
                                    shutdown={{
                                        isBookShutdown,
                                        setIsBookShutdown,
                                        finishBookShutdown,
                                        setFinishBookShutdown,
                                    }}
                                    isDisabled={
                                        !isBookMount ||
                                        isBookPending ||
                                        isCoverPending ||
                                        isCoverReady
                                    }
                                    isGallup={examType === "gallup_test"}
                                    isGibs={examType === "gibs_test"}
                                    isFinished={
                                        pages.finished === 1 &&
                                        !isPageRedirecting.current
                                    }
                                />
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                    {!error ? (
                        <>
                            <ReaderPanel
                                title={multibook.title}
                                chapters={chapters}
                                pages={{
                                    page,
                                    setPage,
                                    pagesTotal: pages.total,
                                }}
                                shutdown={{
                                    isBookShutdown,
                                    setIsBookShutdown,
                                    setFinishBookShutdown,
                                }}
                                isDisabled={
                                    !isBookMount ||
                                    isBookPending ||
                                    isCoverPending ||
                                    isCoverReady
                                }
                                isGallup={examType === "gallup_test"}
                                isGibs={examType === "gibs_test"}
                                isFinished={page > pages.total}
                            />
                            <ReaderPlayer
                                color={
                                    examType === "gallup_test" ||
                                    examType === "gibs_test"
                                        ? "secondary"
                                        : ""
                                }
                            />
                        </>
                    ) : (
                        ""
                    )}
                    <ReaderExit
                        shutdown={{
                            isBookShutdown,
                            setIsBookShutdown,
                            finishBookShutdown,
                            setFinishBookShutdown,
                        }}
                    />
                </div>
            ) : (
                ""
            )}
            <ReaderLoading
                theme={themeColor}
                isLoading={
                    isReaderLoading ||
                    (isInitialChapterLoading && isCoverSkipped.current)
                }
                isShutdown={isBookShutdown}
                isShutting={finishBookShutdown}
                isGallup={examType === "gallup_test"}
                isGibs={examType === "gibs_test"}
            />
        </>
    );
};

export default Reader;
