import { useState, createContext } from "react";

export const ActionLoadingContext = createContext();

const ActionLoadingProvider = ({ children }) => {
    const [isActionLoading, setIsActionLoading] = useState(false);

    return (
        <ActionLoadingContext.Provider
            value={{ isActionLoading, setIsActionLoading }}
        >
            {children}
        </ActionLoadingContext.Provider>
    );
};

export default ActionLoadingProvider;
