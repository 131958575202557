import { useState, useRef, useEffect, useCallback } from "react";
import { CSSTransition } from "react-transition-group";

import ReaderRedirect from "../buttons/ReaderRedirect";
import ReaderLoader from "../utils/ReaderLoader";

import ArrowIcon from "../icons/ArrowIcon";

const ReaderChapters = ({
    page,
    chapters,
    triggerElement,
    isAvailable,
    isLoading,
    onClick,
}) => {
    /* CHAPTERS */
    const [chaptersIsActive, setChaptersIsActive] = useState(false);
    const chaptersRef = useRef(null);

    const chaptersToggleFlag = useRef(false);
    const chaptersTransitionTimeout = useRef(300);

    /* TOGGLE CHAPTERS */
    const toggleChapters = useCallback(() => {
        if (!chaptersToggleFlag.current) {
            chaptersToggleFlag.current = true;

            if (chaptersIsActive) {
                setChaptersIsActive(false);
            } else {
                setChaptersIsActive(true);
            }
        }
    }, [chaptersIsActive]);

    /* BIND CLICK TO TARGET BUTTON */
    useEffect(() => {
        if (isAvailable) {
            triggerElement?.addEventListener("click", toggleChapters);
        }

        return () => {
            triggerElement?.removeEventListener("click", toggleChapters);
        };
    }, [triggerElement, isAvailable, toggleChapters]);

    /* RESET CHAPTERS TIMEOUT FLAG */
    useEffect(() => {
        if (chaptersToggleFlag.current) {
            setTimeout(() => {
                chaptersToggleFlag.current = false;
            }, chaptersTransitionTimeout.current);
        }
    }, [chaptersIsActive]);

    return (
        <CSSTransition
            nodeRef={chaptersRef}
            in={chaptersIsActive}
            classNames={"ReaderFade"}
            timeout={chaptersTransitionTimeout.current}
            unmountOnExit
            appear
        >
            <div
                className="ReaderChapters"
                data-panel="false"
                ref={chaptersRef}
            >
                <div className="ReaderChapters__Header">
                    <div className="ReaderChapters__Close">
                        <ReaderRedirect
                            inverted
                            disabled={isLoading}
                            onClick={() => toggleChapters()}
                        />
                    </div>
                    <div className="ReaderChapters__Title">Rozdziały</div>
                </div>
                <div className="ReaderChapters__Menu">
                    {chapters.map((object, index) => {
                        return (
                            <div
                                className="ReaderChapters__Item"
                                key={"multibook_1_" + object.idChapter}
                            >
                                <button
                                    className={
                                        "ReaderChapters__Button" +
                                        (parseInt(object.totalOrder) === page
                                            ? " _active"
                                            : "")
                                    }
                                    type="button"
                                    disabled={
                                        parseInt(object.totalOrder) === page ||
                                        isLoading ||
                                        object.disabled
                                    }
                                    onClick={() =>
                                        onClick(parseInt(object.totalOrder))
                                    }
                                >
                                    {object.title
                                        ? object.title
                                        : "ROZDZIAŁ " + (index + 1)}
                                    <span
                                        className={
                                            "ReaderChapters__Icon" +
                                            (isLoading ? " _icon--loading" : "")
                                        }
                                    >
                                        {isLoading &&
                                        parseInt(object.totalOrder) === page ? (
                                            <ReaderLoader size="small" />
                                        ) : (
                                            <ArrowIcon direction="right" />
                                        )}
                                    </span>
                                </button>
                                {object.child?.length > 0 ? (
                                    <div className="ReaderChapters__SubMenu">
                                        {object.child.map(
                                            (childObject, index) => {
                                                return (
                                                    <div
                                                        className="ReaderChapters__Item"
                                                        key={
                                                            "multibook_1_" +
                                                            childObject.idChapter
                                                        }
                                                    >
                                                        <button
                                                            className={
                                                                "ReaderChapters__Button" +
                                                                (parseInt(
                                                                    childObject.totalOrder
                                                                ) === page
                                                                    ? " _active"
                                                                    : "")
                                                            }
                                                            type="button"
                                                            disabled={
                                                                parseInt(
                                                                    childObject.totalOrder
                                                                ) === page ||
                                                                isLoading ||
                                                                object.disabled
                                                            }
                                                            onClick={() =>
                                                                onClick(
                                                                    parseInt(
                                                                        childObject.totalOrder
                                                                    )
                                                                )
                                                            }
                                                        >
                                                            {childObject.title
                                                                ? childObject.title
                                                                : "PODROZDZIAŁ " +
                                                                  (index + 1)}
                                                            <span
                                                                className={
                                                                    "ReaderChapters__Icon" +
                                                                    (isLoading
                                                                        ? " _icon--loading"
                                                                        : "")
                                                                }
                                                            >
                                                                {isLoading &&
                                                                parseInt(
                                                                    childObject.totalOrder
                                                                ) === page ? (
                                                                    <ReaderLoader size="small" />
                                                                ) : (
                                                                    <ArrowIcon direction="right" />
                                                                )}
                                                            </span>
                                                        </button>
                                                    </div>
                                                );
                                            }
                                        )}
                                    </div>
                                ) : (
                                    ""
                                )}
                            </div>
                        );
                    })}
                </div>
            </div>
        </CSSTransition>
    );
};

export default ReaderChapters;
