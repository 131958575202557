import { useContext, useState } from "react";
import { useMutation } from "react-query";
import { useCookies } from "react-cookie";
import { useHistory } from "react-router-dom";

import { StoreContext } from "../../../context/Store";

import useLogin from "../../../hooks/useLogin";
import useLogout from "../../../hooks/useLogout";

import activateAccountErrorHandler from "../../../functions/errorHandlers/activateAccountErrorHandler";

import Title from "../../headings/Title";
import Input from "../../inputs/Input";
import Button from "../../buttons/Button";

import DoodleContent from "../../containers/doodle/DoodleContent";
import DoodleBanner from "../../containers/doodle/DoodleBanner";

import doodles from "../../../assets/images/remindpassword/how2doodles.svg";

const ActivateAccountForm = () => {
    const history = useHistory();

    const cookies = useCookies(["idRank", "schoolsEmail"])[0];
    const removeCookie = useCookies(["idRank", "schoolsEmail"])[2];

    const { api } = useContext(StoreContext);

    const login = useLogin();
    const logout = useLogout();

    const [isActivationLoading, setIsActivationLoading] = useState(false);
    const [isResendLoading, setIsResetLoading] = useState(false);

    // activateAccount
    const { mutate: accountActivationMutate } = useMutation(
        () =>
            api.post(`/account/activate`, {
                token: inputValue,
            }),
        {
            onMutate: () => setIsActivationLoading(true),
            onSuccess: (data) => {
                removeCookie("schoolsEmail", {
                    path: "/",
                });

                login(
                    {
                        token: data.data.token,
                        idRank: cookies.idRank,
                        isAccountActive: 1,
                    },
                    "/dashboard"
                );
            },
            onError: (error) => {
                activateAccountErrorHandler(
                    error.response.status,
                    error.response.data.errors,
                    (text) =>
                        setInputValidator({
                            response: "error",
                            text: text,
                        })
                );

                setIsActivationLoading(false);
            },
        }
    );

    // resend activation email
    const { mutate: accountActivationResendMutate } = useMutation(
        () => api.get(`/account/activate/resend`),
        {
            onMutate: () => setIsResetLoading(true),
            onSuccess: () => history.push("/dashboard"),
        }
    );

    const [inputValue, setInputValue] = useState("");
    const [inputValidator, setInputValidator] = useState({
        response: "",
        text: "",
    });

    // onChange handler
    const codeOnChangeHandler = (e) => {
        if (e.length > 0) {
            if (e.length >= 4) {
                setInputValidator({
                    response: "success",
                    text: "",
                });
            } else {
                setInputValidator({
                    response: "error",
                    text: "",
                });
            }
        } else {
            setInputValidator({
                response: "",
                text: "",
            });
        }

        if (e.length <= 4) {
            setInputValue(e);
        }
    };

    const onEnterKeyHandler = (e) => {
        const enterKey = e.charCode === 13 || e.keyCode === 13;

        if (!formSubmitDisable && enterKey) {
            e.currentTarget.blur();
            accountActivationMutate();
        }
    };

    const formSubmitDisable =
        inputValidator.response === "success" ? false : true;

    return (
        <>
            <div className="app-activateAccount">
                <div className="app-activateAccount-wrapper">
                    <DoodleContent
                        wrapperClass="activateAccount"
                        isDisabled={isActivationLoading || isResendLoading}
                        onBackFn={() => logout("/")}
                    >
                        <Title className="medium text-center">
                            Wpisz kod, który został
                            <br />
                            wysłany na Twój adres
                            <br />
                            e-mail
                        </Title>
                        <div className="activateAccount-form">
                            <Input
                                type="text"
                                placeholder="wpisz kod"
                                classNameControl="font-thick"
                                value={inputValue}
                                isDisabled={
                                    isActivationLoading || isResendLoading
                                }
                                isValid={inputValidator}
                                onChangeFn={(e) =>
                                    codeOnChangeHandler(e.currentTarget.value)
                                }
                                onKeyPressFn={(e) => onEnterKeyHandler(e)}
                            />
                            <Button
                                classNameWrapper="activateAccount-button"
                                className="medium"
                                isDisabled={
                                    formSubmitDisable ||
                                    isActivationLoading ||
                                    isResendLoading
                                }
                                isLoading={isActivationLoading}
                                onClickFn={() => accountActivationMutate()}
                            >
                                Wyślij
                            </Button>
                        </div>
                        <div className="activateAccount-form resend">
                            <div className="text-center">
                                <strong>Twój kod wygasł lub nie działa?</strong>
                            </div>
                            <Button
                                classNameWrapper="activateAccount-button resend"
                                className="medium gray hover-red"
                                isLoading={isResendLoading}
                                isDisabled={
                                    isActivationLoading || isResendLoading
                                }
                                onClickFn={() =>
                                    accountActivationResendMutate()
                                }
                            >
                                Wyślij ponownie
                            </Button>
                        </div>
                    </DoodleContent>
                    <DoodleBanner
                        wrapperClass="activateAccount"
                        imgs={doodles}
                    />
                </div>
            </div>
        </>
    );
};

export default ActivateAccountForm;
