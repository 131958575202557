import { useContext } from "react";
import { SettingsContext } from "../context/SettingsStore";

const getIcon = (theme, color, isFavorite) => {
    switch (isFavorite) {
        case true:
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width="32.955"
                    height="28.962"
                    viewBox="0 0 32.955 28.962"
                >
                    <defs>
                        <clipPath id="heartFilledClipPath">
                            <rect
                                id="heartFilledRectangle_1"
                                dataname="heartFilledRectangle 1"
                                width="32.955"
                                height="28.962"
                                transform="translate(0 0)"
                                fill="none"
                                stroke="#2e2a26"
                                strokeWidth="1"
                            />
                        </clipPath>
                    </defs>
                    <g id="heartFilledGroup_1" transform="translate(0 0)">
                        <g
                            id="heartFilledGroup_2"
                            dataname="heartFilledGroup 2"
                            transform="translate(0 0)"
                            clipPath="url(#heartFilledClipPath)"
                        >
                            <path
                                id="heartFilledPath_1"
                                dataname="heartFilledPath 1"
                                d="M24.485,6.127a10.4,10.4,0,0,1-1.834,5.6,35.093,35.093,0,0,1-9.055,9.04c-1.352,1-1.361.992-2.739-.009a38.482,38.482,0,0,1-7.778-7.238A12.472,12.472,0,0,1,.025,6.882,6.239,6.239,0,0,1,3.323.714a5.826,5.826,0,0,1,6.8.934,21.04,21.04,0,0,1,1.716,1.695c.309.339.488.3.787-.007A23.664,23.664,0,0,1,14.5,1.521,5.618,5.618,0,0,1,20.961.647a6.046,6.046,0,0,1,3.524,5.481"
                                transform="translate(4.235 3.722)"
                                fill={
                                    color === "secondary"
                                        ? theme === "dark"
                                            ? "#3daf6b"
                                            : "#85d6a6"
                                        : "#0c6ffa"
                                }
                                stroke={
                                    color === "secondary"
                                        ? theme === "dark"
                                            ? "#3daf6b"
                                            : "#85d6a6"
                                        : "#0c6ffa"
                                }
                                strokeWidth="1"
                            />
                        </g>
                    </g>
                </svg>
            );
        case false:
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width="32.955"
                    height="28.962"
                    viewBox="0 0 32.955 28.962"
                >
                    <defs>
                        <clipPath id="heartClipPath">
                            <rect
                                id="heartRectangle_1"
                                dataname="heartRectangle 1"
                                width="32.955"
                                height="28.962"
                                transform="translate(0 0)"
                                fill="none"
                                stroke="#2e2a26"
                                strokeWidth="1"
                            />
                        </clipPath>
                    </defs>
                    <g id="heartGroup_1" transform="translate(0 0)">
                        <g
                            id="heartGroup_2"
                            dataname="heartGroup 2"
                            transform="translate(0 0)"
                            clipPath="url(#heartClipPath)"
                        >
                            <path
                                id="heartPath_1"
                                dataname="heartPath 1"
                                d="M24.485,6.127a10.4,10.4,0,0,1-1.834,5.6,35.093,35.093,0,0,1-9.055,9.04c-1.352,1-1.361.992-2.739-.009a38.482,38.482,0,0,1-7.778-7.238A12.472,12.472,0,0,1,.025,6.882,6.239,6.239,0,0,1,3.323.714a5.826,5.826,0,0,1,6.8.934,21.04,21.04,0,0,1,1.716,1.695c.309.339.488.3.787-.007A23.664,23.664,0,0,1,14.5,1.521,5.618,5.618,0,0,1,20.961.647a6.046,6.046,0,0,1,3.524,5.481"
                                transform="translate(4.235 3.722)"
                                fill="none"
                                stroke="#2e2a26"
                                strokeWidth="1"
                            />
                        </g>
                    </g>
                </svg>
            );
        default:
            return "";
    }
};

const FavoriteIcon = ({ color, isFavorite }) => {
    const { themeColor } = useContext(SettingsContext);

    return getIcon(themeColor, color, isFavorite);
};

export default FavoriteIcon;
